import { useState } from "react";
import { Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import DashboardSidebarRight from "./DashboardSidebarRight";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 80;
const APP_BAR_DESKTOP = 80;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme, pin }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE ,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: pin ? theme.spacing(43) : theme.spacing(6),
  },
  transition: theme.transitions.create("padding-right", {
    duration: theme.transitions.duration.standard,
  }),
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [drawerHidden, setDrawerHidden] = useState(
    localStorage.getItem("drawerHidden") === "true" || false
  );
  const [pinTask, setPinTask] = useState(false);
  const [pinHistory, setPinHistory] = useState(false);

  localStorage.setItem("drawerHidden", drawerHidden);

  return (
    <RootStyle>
      <DashboardNavbar
        onOpenSidebar={() => setOpen(true)}
        drawerHidden={drawerHidden}
        updateDrawerHidden={() => setDrawerHidden(!drawerHidden)}
      />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
        drawerHidden={drawerHidden}
      />
      <DashboardSidebarRight
        isOpenSidebarRight={open}
        onCloseSidebarRight={() => setOpen(false)}
        onPinTaskSidebar={() => setPinTask(!pinTask)}
        onpinHistorySidebar={() => setPinHistory(!pinHistory)}
        pinTask={pinTask}
        pinHistory={pinHistory}
      />

      <MainStyle pin={pinTask || pinHistory}>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
