import React from "react";
import { useState, useEffect } from "react";

import {
  Card,
  Grid,
  Stack,
  Container,
  Typography,
  CircularProgress,
  TablePagination,
} from "@mui/material";

import { dataFetch } from "../../utils/data-fetch";
import { useAuth } from "../../contexts/AuthContext";

import { getDateStringFullSync } from "src/utils/date-time-helper";

export default function AllTasks({ counter }) {
  const { getAccessToken, activeFranchise } = useAuth();

  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = async () => {
    let url = `/api/mgrtasks/mgract/query?status=pending&skip=${
      page * 10
    }&limit=10`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [activeFranchise, page, counter]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div sx={{ pt: 2, pb: 2 }}>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h5" gutterBottom>
            All Tasks
          </Typography>

          {loadingData === true && <CircularProgress color="secondary" />}
        </Stack>

        {customerData.length > 0 ? (
          <Grid container spacing={2}>
            {customerData.map((data, index) => {
              return (
                <Grid key={data._id} item xs={12} sm={12} md={12}>
                  <Card
                    sx={{
                      px: 2,
                      py: 2,
                    }}
                  >
                    <Container>
                      <Stack direction={"column"}>
                        <Typography variant="subtitle" noWrap mb={2}>
                          {data.name}{" "}
                          {data?.account &&
                            `(${
                              data?.account?.name
                            } - ${data?.account?.entity?.toUpperCase()})`}
                        </Typography>
                        {data.details && (
                          <Typography variant="subtitle2" noWrap>
                            {data?.details}
                          </Typography>
                        )}
                        {data.due_data > 0 && (
                          <Typography variant="subtitle2" noWrap>
                            {getDateStringFullSync(data.due_date)}
                          </Typography>
                        )}
                      </Stack>
                    </Container>
                  </Card>
                </Grid>
              );
            })}
            <Grid key="pagination" item xs={12} sm={12} md={12}>
              <TablePagination
                component="div"
                count={-1}
                rowsPerPage={10}
                rowsPerPageOptions={[10]}
                page={page}
                onPageChange={handleChangePage}
              />
            </Grid>
          </Grid>
        ) : (
          <Typography variant="body2" align="center" sx={{ mt: 3, mb: 2 }}>
            No Data found
          </Typography>
        )}
      </Container>
    </div>
  );
}
