import React from "react";

import {
  Card,
  Table,
  Stack,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
} from "@mui/material";


const TABLE_HEAD = [
  { id: "name", label: "Name", alignRIght: false },
  { id: "phone", label: "Phone", alignRIght: false },
];

export default function ListManager({
  title,
  managers,
  loadingData,
  onManagerClick,
}) {
  return (
    <Card title="Manager List" sx={{ pt: 2, pb: 2 }}>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>

          {loadingData === true && <CircularProgress color="secondary" />}
        </Stack>

        {managers.length > 0 ? (
          <div>
            <TableContainer sx={{ minHeight: 200 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRIght ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {managers.map((item) => {
                    return (
                      <TableRow
                        hover
                        onClick={() => onManagerClick(item)}
                        style={{
                          backgroundColor:
                            item.active === false ? "#ddd" : "white",
                        }}
                      >
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.phone}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <Typography variant="body2" align="center" sx={{ mt: 3, mb: 2 }}>
            No Data found
          </Typography>
        )}
      </Container>
    </Card>
  );
}
