import { useState, useEffect } from "react";

import { Grid } from "@mui/material";
import ReferralCard from "./info/ReferralCard";
import GetReferredCustomer from "./info/GetReferredCustomer";
import SupportTicketCard from "./SupportTicketCard";
import CallHistoryCard from "./CallHistoryCard";

export default function CustomerCallHistorySection({ customer }) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <SupportTicketCard customer={customer} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <CallHistoryCard customer={customer} />
        </Grid>
      </Grid>
    </>
  );
}
