import {
  Container,
  Typography,
  Stack,
  Box,
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  Button,
  Grid,
  CircularProgress,
  Switch,
} from "@mui/material";

import { forwardRef, useEffect, useState } from "react";
import {
  AddCircleRounded,
  CloseRounded,
  RefreshRounded,
  VisibilityRounded,
} from "@mui/icons-material";

import { dataFetch } from "src/utils/data-fetch";
import { useAuth } from "src/contexts/AuthContext";
import { useSnackbar } from "src/contexts/SnackbarContext";

import { getDateStringFullWithTimeSync } from "src/utils/date-time-helper";
import AddAppBannerModal from "./AddAppBanner";
import Label from "src/pages/crm/Label";
import BannerDetails from "./det/BannerDetails";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AppBannersPage({ handleClose, drawerOpen }) {
  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [loadingData, setLoadingData] = useState(false);
  const [allData, setAllData] = useState([]);

  const [inactiveData, setInactiveData] = useState(false);

  const fetchData = async () => {
    setLoadingData(true);

    let url = `/api/banners/mgract/banners/query?sort=_id&dir=d&skip=0&limit=20`;
    if (inactiveData === false) {
      url += "&active=true";
    }

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setAllData(ret.data);
    } else {
      showSnackbar(ret.message || "Failed to fetch data", "error");
    }

    setLoadingData(false);
  };

  useEffect(() => {
    fetchData();
  }, [inactiveData]);

  const [addBannerModalState, setAddBannerModalState] = useState(false);

  const [bannerDetailsModal, setBannerDetailsModal] = useState(null);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      scroll="body"
      open={drawerOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            Banners
          </Typography>

          <Stack direction={"row"} spacing={1} edge="end">
            <Stack direction="row" spacing={1} alignItems={"center"}>
              Inactive
              <Switch
                checked={inactiveData}
                color="white"
                onChange={() => setInactiveData(!inactiveData)}
              />
            </Stack>

            {(loadingData === true && (
              <CircularProgress color="white" sx={{ mx: 2 }} />
            )) || (
              <Button variant="contained" color="white" onClick={fetchData}>
                <RefreshRounded />
              </Button>
            )}

            <Button
              variant="contained"
              color="white"
              startIcon={<AddCircleRounded />}
              onClick={() => setAddBannerModalState(true)}
            >
              Add Banner
            </Button>

            <Button variant="contained" color="error" onClick={handleClose}>
              <CloseRounded />
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>

      <Container sx={{ minHeight: 600, py: 2 }}>
        <Grid container spacing={2}>
          {allData.map((item, index) => (
            <Grid item xs={12} md={4} key={item._id}>
              <Box
                sx={{
                  position: "relative",
                  bgcolor: "background.paper",
                  borderRadius: 2,
                  boxShadow: 3,
                  overflow: "hidden",
                }}
                onClick={() => {
                  setBannerDetailsModal(item);
                }}
              >
                <Stack direction="column">
                  <Box
                    component="img"
                    src={item.image_url}
                    alt={`image-${index}`}
                    sx={{ width: "100%", objectFit: "cover" }}
                  />

                  <Stack
                    direction="column"
                    sx={{
                      p: 2,
                      background: item.active ? "white" : "lightgray",
                    }}
                    spacing={1}
                  >
                    <Typography variant="body1" color="textPrimary">
                      <b>{item?.title || "No Title"}</b>
                    </Typography>

                    {item?.description && (
                      <Typography variant="body2" color="textSecondary">
                        Description available
                      </Typography>
                    )}

                    <Typography
                      variant="body1"
                      color={
                        item.live_time > Date.now() ? "error" : "textPrimary"
                      }
                    >
                      <b>Live:</b>{" "}
                      {getDateStringFullWithTimeSync(item.live_time)}
                    </Typography>

                    <Typography variant="body1" color="textPrimary">
                      <Label
                        color={item.active ? "success" : "error"}
                        variant="filled"
                      >
                        {item.active ? "Active" : "Inactive"}
                      </Label>
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>

      {addBannerModalState && (
        <AddAppBannerModal
          drawerOpen={addBannerModalState}
          handleM1Close={() => {
            setAddBannerModalState(false);
            fetchData();
          }}
        />
      )}

      {bannerDetailsModal && (
        <BannerDetails
          drawerOpen={bannerDetailsModal !== null}
          handleClose={() => {
            setBannerDetailsModal(null);
            fetchData();
          }}
          banner={bannerDetailsModal}
        />
      )}
    </Dialog>
  );
}
