import { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CircularProgress,
  Stack,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";
import { dataFetch } from "../../utils/data-fetch";

import Page from "../../components/Page";

export default function Centers() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const { getAccessToken, activeFranchise } = useAuth();

  const initialLoad = async () => {
    setLoadingData(true);
    const ret = await dataFetch(
      `/api/collectioncenters/mgract/query`,
      await getAccessToken(),
      activeFranchise
    );

    setData(ret.data);
    setLoadingData(false);
  };

  useEffect(() => {
    initialLoad();
  }, [activeFranchise]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page title="Collection Centers">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Collection Centers
          </Typography>
        </Stack>

        {loadingData === true ? (
          <CircularProgress color="secondary" />
        ) : (
          <Grid container spacing={3}>
            {data.map((cart, index) => {
              return (
                <Grid
                  key={cart._id}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  onClick={() =>
                    navigate("details", {
                      state: {
                        cart: cart,
                      },
                    })
                  }
                >
                  <Card sx={{ px: 2, py: 2 }}>
                    <Container>
                      <CT data={cart} />
                    </Container>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Container>
    </Page>
  );
}

const CT = ({ data }) => {
  return (
    <Stack direction={"column"}>
      <Typography variant="subtitle" noWrap mb={2}>
        {data.name} ({data.handler})
      </Typography>
      <Typography variant="subtitle2" noWrap>
        Phone: {data.phone ? data.phone : ""}
      </Typography>
      <Typography variant="subtitle2" noWrap>
        Balance: ₹ {data.balance}
      </Typography>
      <Typography variant="body2" noWrap>
        Active: {data.active === true ? "Yes" : "No"}
      </Typography>
    </Stack>
  );
};
