import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch, dataPatch } from "src/utils/data-fetch";

import {
  Container,
  Typography,
  CircularProgress,
  Stack,
  Grid,
  TextField,
  IconButton,
} from "@mui/material";
import Markdown from "react-markdown";
import { useSnackbar } from "src/contexts/SnackbarContext";
import { LoadingButton } from "@mui/lab";
import { SaveRounded } from "@mui/icons-material";

export default function ProductDetailsSection({ product }) {
  const [initialData, setInitialData] = useState("");
  const [customerData, setCustomerData] = useState("");

  const [loadingData, setLoadingData] = useState(false);

  const [inactiveData, setInactiveData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const fetchData = async () => {
    setLoadingData(true);

    let url = `/api/products/mgract/product/${product._id}/details`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setInitialData(ret.data);
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  const updateData = async () => {
    setLoadingData(true);

    let url = `/api/products/mgract/product/${product._id}/details`;

    const ret = await dataPatch(
      url,
      await getAccessToken(),
      {
        details: customerData,
      },
      activeFranchise
    );
    if (ret.success === true) {
      showSnackbar("Data saved successfully", "success");
      fetchData();
    } else {
      showSnackbar(ret.message || "Failed to save data", "error");
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    fetchData();
  }, [product]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <Stack direction={"column"} spacing={1}>
            <Stack
              direction={"row"}
              spacing={1}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography variant="h5" gutterBottom>
                Markdown Data
              </Typography>

              {(loadingData && <CircularProgress />) || (
                <IconButton
                  variant="contained"
                  color="black"
                  onClick={updateData}
                  disabled={loadingData || initialData === customerData}
                >
                  <SaveRounded />
                </IconButton>
              )}
            </Stack>

            <TextField
              fullWidth
              multiline
              disabled={loadingData}
              value={customerData}
              onChange={(e) => setCustomerData(e.target.value)}
              inputProps={{ style: { fontFamily: "monospace" } }} // font size of input text
            />

            <LoadingButton
              variant="contained"
              color="black"
              onClick={updateData}
              disabled={loadingData || initialData === customerData}
              loading={loadingData}
            >
              Save
            </LoadingButton>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Stack direction={"column"} spacing={1}>
            <Typography variant="h5" gutterBottom>
              Preview
            </Typography>

            <Markdown children={customerData} />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}
