import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { Grid } from "@mui/material";

export default function Animation({ count = 9 }) {
  return (
    <Grid container spacing={3}>
      {Array.from(new Array(count)).map((_, i) => (
        <Grid key={i} item xs={12} sm={6} md={4}>
          <Box sx={{ width: 210, margin: 1 }}>
            <Skeleton variant="rectangular" width={210} height={118} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
