import { Card, Box, CircularProgress, Stack } from "@mui/material";

import { ChevronRightRounded } from "@mui/icons-material";

import { useAuth } from "src/contexts/AuthContext";

import { useEffect, useState } from "react";
import { dataFetch } from "src/utils/data-fetch";
import CustomerDetailsPage from "../home/det/CustomerDetailsPage";

export default function UserPopup({ dataId }) {
  const { getAccessToken, activeFranchise } = useAuth();
  const [showCustomerDetails, setShowCustomerDetails] = useState(false);

  const [detail, setDetail] = useState();
  const [loading, setLoading] = useState(false);

  const loadCurrentData = async () => {
    setLoading(true);

    let url = `/api/users/mgract/user/${dataId}?extended=true`;
    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setDetail(ret.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (dataId) loadCurrentData();
  }, [dataId]);

  return (
    <div style={{ padding: 2, minWidth: "300px" }}>
      {loading === true && <CircularProgress />}
      {loading === false && detail && (
        <div>
          <h2 class="firstHeading">
            <Stack direction="row" alignItems="center">
              <font color={detail.active === true ? "black" : "gray"}>
                {detail.name}
              </font>

              <Box sx={{ flexGrow: 1 }} />
              <ChevronRightRounded
                onClick={() => {
                  setShowCustomerDetails(true);
                }}
              />
            </Stack>
          </h2>
          <font color="blue">{detail.phone}</font>

          <div style={{ marginTop: "10px" }}>
            <b>
              Balance: ₹{" "}
              <font
                color={
                  detail.wallet.balance > 0
                    ? "green"
                    : detail.wallet.balance < 0
                    ? "red"
                    : "blue"
                }
              >
                {detail.wallet.balance.toFixed(2)}
              </font>
            </b>
            {"   "}(Limit: ₹ {detail.wallet.minus_limit.toFixed(2)}){" "}
          </div>

          <div style={{ marginTop: "10px", maxWidth: 400 }}>
            <b>Address: </b>
            <font color="blue">{detail.address.detail}</font>
          </div>

          {detail.plan ? (
            <div style={{ marginTop: "10px" }}>
              <b>Plan: ({detail.plan.status === true ? "ON" : "OFF"})</b>
              <font color="blue">
                {detail.plan.milk > 0 && (
                  <>
                    <br></br>
                    {detail.plan.milk} Lt. per day
                  </>
                )}
                {detail.expected_time && detail.expected_time.exact > 0 && (
                  <>
                    {" Around "}
                    {Math.floor(detail.expected_time.exact / 1000 / 3600)}:
                    {Math.floor(
                      ((detail.expected_time.exact / 1000) % 3600) / 60
                    )}
                  </>
                )}
                <br></br>on {detail.plan.days.toString()}
              </font>
            </div>
          ) : (
            detail.expected_time &&
            detail.expected_time.exact > 0 && (
              <div style={{ marginTop: "10px" }}>
                <b>Time:</b>
                <font color="blue">
                  {" Around "}
                  {Math.floor(detail.expected_time.exact / 1000 / 3600)}:
                  {Math.floor(
                    ((detail.expected_time.exact / 1000) % 3600) / 60
                  )}
                </font>
              </div>
            )
          )}

          {detail.cart && (
            <div style={{ marginTop: "10px" }}>
              <b>Cart: </b>
              <font color="blue">
                {detail.cart["name"]} ({detail.cart["phone"]})
              </font>
            </div>
          )}

          {detail.wallet && (
            <div style={{ marginTop: "10px" }}>
              <b>Balance: </b>
              <font color="green">
                ₹ {detail.wallet["balance"]} (Limit: ₹{" "}
                {detail.wallet["minus_limit"]})
              </font>
            </div>
          )}

          {detail.franchisee && (
            <div style={{ marginTop: "10px" }}>
              <b>Franchisee: </b>
              <font color="blue">
                {detail.franchisee["name"]} ({detail.franchisee["phone"]})
              </font>
            </div>
          )}
        </div>
      )}

      <CustomerDetailsPage
        customer={detail}
        handleClose={() => setShowCustomerDetails(false)}
        drawerOpen={showCustomerDetails}
      />
    </div>
  );
}
