import { CloseRounded } from "@mui/icons-material";
import {
  AppBar,
  Button,
  Container,
  Dialog,
  Slide,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { forwardRef, useState, useRef } from "react";
import { useAuth } from "src/contexts/AuthContext";

import L from "leaflet";

import {
  TileLayer,
  MapContainer,
  useMapEvent,
  Marker,
  FeatureGroup,
} from "react-leaflet";

import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";

import LeafletControlGeocoder from "../pages/apartment/addapt/LeafletControlGeocoder";
import { dataFetch } from "./data-fetch-cro";
import { useSnackbar } from "src/contexts/SnackbarContext";
import AddUserAddress from "./add-user-address";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const aptIcon = (link, text) => {
  const icon = new L.DivIcon({
    html:
      `<img src=${link} style="width: 30px; height:30px;"/>` +
      `<span>${text || "Apt"}</span>`,
    iconSize: new L.Point(35, 35),
    bgPos: new L.Point(0, 0),
    popupAnchor: [0, -30],
  });

  return icon;
};

export default function AddUserLocation({
  drawerOpen,
  handleClose,
  data,
  onUserAdded,
}) {
  const [error, setError] = useState();

  const { getAccessToken } = useAuth();
  const { showSnackbar } = useSnackbar();

  const mapRef = useRef(null);

  const [markerPos, setMarkerPos] = useState(null);

  const [nearbyApts, setNearbyApts] = useState([]);

  const loadNearby = async (lat, lng) => {
    let url = `/api/cro/mgract/apts/nearby`;
    url += `?lat=${lat}&lng=${lng}`;

    const ret = await dataFetch(url, await getAccessToken());

    if (ret.success === true) {
      setNearbyApts(ret.data);
    } else {
      showSnackbar(ret.message || "Failed to fetch data", "error");
    }
  };

  const [addressModalState, setAddressModalState] = useState(false);
  const [selectedApt, setSelectedApt] = useState(null);
  const [selectedPos, setSelectedPos] = useState(null);

  const EventHandler = () => {
    useMapEvent("load", (e) => {
      const center = mapRef.current.getCenter();
      loadNearby(center.lat, center.lng);
    });

    useMapEvent("moveend", (e) => {
      const center = mapRef.current.getCenter();
      loadNearby(center.lat, center.lng);
    });

    useMapEvent("dblclick", (e) => {
      setMarkerPos(e.latlng);
      setSelectedPos(e.latlng);
      setSelectedApt(null);
      setAddressModalState(true);
    });

    return null;
  };

  const onAptClick = (e, apt) => {
    setSelectedPos(null)
    setSelectedApt(apt);
    setAddressModalState(true);
  };

  return (
    <Dialog
      fullScreen
      scroll="body"
      open={drawerOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            Select Location
          </Typography>
          <Button variant="contained" color="error" onClick={handleClose}>
            <CloseRounded />
          </Button>
        </Toolbar>
      </AppBar>

      <Container>
        <Stack direction="column" spacing={1}>
          <div>
            <MapContainer
              center={[12.90152691, 77.69154668]}
              zoom={17}
              style={{ height: "90vh", width: "100%" }}
              ref={mapRef}
            >
              <LeafletControlGeocoder
                onLocationSelect={(latlng, name) => {
                  // onCoordinateChange([latlng.lng, latlng.lat]);
                  // onAddressUpdate(name);
                }}
              />
              <EventHandler />
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

              {markerPos && (
                <Marker
                  position={markerPos}
                  riseOnHover={true}
                  title="Selected Position"
                />
              )}

              <FeatureGroup>
                {nearbyApts.map((apt) => {
                  const pos = {
                    lat: apt.location.coordinates[1],
                    lng: apt.location.coordinates[0],
                  };
                  return (
                    <Marker
                      key={apt.id}
                      position={pos}
                      riseOnHover={true}
                      title={apt.name}
                      icon={aptIcon(
                        apt.image_url || "/static/noapt.svg",
                        apt.name
                      )}
                      eventHandlers={{ click: (e) => onAptClick(e, apt) }}
                    />
                  );
                })}
              </FeatureGroup>
            </MapContainer>
          </div>
        </Stack>
      </Container>

      {addressModalState && (
        <AddUserAddress
          drawerOpen={addressModalState}
          handleClose={() => {
            setAddressModalState(false);
          }}
          data={data}
          selectedApt={selectedApt}
          selectedPos={selectedPos}
          onUserAdded={() => {
            setAddressModalState(false);
            onUserAdded();
          }}
        />
      )}
    </Dialog>
  );
}
