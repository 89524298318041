import {
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  TableRow,
  IconButton,
  CircularProgress,
  Tooltip,
  Drawer,
} from "@mui/material";

import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch, dataPost } from "src/utils/data-fetch";
import Label from "./Label";

import {
  getDayStartAndEndSync,
  getTimeStringFullSync,
} from "src/utils/date-time-helper";

import CustomerDetailsPage from "../home/det/CustomerDetailsPage";

import { RefreshRounded } from "@mui/icons-material";

import { DateSelector } from "src/utils/date-selector";
import OfferSearch from "./OfferSearch";
import { useSnackbar } from "src/contexts/SnackbarContext";

const TABLE_HEAD = [
  { id: "index", label: "Id", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "onbs", label: "Onboarding", alignRight: false },
  { id: "cart", label: "Cart", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "transacted_by", label: "Transacted By", alignRight: false },
  { id: "date_time", label: "Date & Time", alignRight: false },
  { id: "offer", label: "Offer", alignRight: false },
];

export default function NewRechargeSection() {
  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [newRecharges, setNewRecharges] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [selectDate, setSelectDate] = useState(Date.now());

  const loadData = async () => {
    setLoadingData(true);

    const dts = getDayStartAndEndSync(selectDate);

    let url = `/api/wallet/mgract/transactions/recharge?date_gt=${dts.start}&date_lt=${dts.end}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setNewRecharges(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, [activeFranchise, selectDate]);

  const [currCust, setCurrCust] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleCustSelected = (CustData) => {
    setCurrCust(CustData);
    setDrawerOpen(true);
  };

  let tot = newRecharges.reduce((acc, item) => {
    if (item.status === 1) {
      return acc + item.amount;
    }
  }, 0);

  const [searchOfferState, setSearchOfferState] = useState(false);
  const [selectedTxn, setSelectedTxn] = useState(null);

  const onSearchOfferReturn = async (dt) => {
    setSearchOfferState(false);
    setLoadingData(true);

    if (!dt || !dt._id || !selectedTxn) {
      return;
    }

    const po = {
      offer_id: dt._id,
      txn_id: selectedTxn._id,
    };

    let url = `/api/offers/mgract/reapplyoffer`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      po,
      activeFranchise
    );

    if (ret.success === true) {
      showSnackbar("Offer applied", "success");
      loadData();
    } else {
      showSnackbar(ret.message || "Failed to apply offer!", "error");
    }

    setLoadingData(false);
  };

  return (
    <div>
      <Card>
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            mt={2}
          >
            <Stack direction="row" alignItems={"center"}>
              <Typography variant="h5">New Recharges</Typography>
              <span>&nbsp;&nbsp; (₹ {tot?.toFixed()})</span>
            </Stack>
            <Stack direction="row" alignItems={"center"}>
              <DateSelector value={selectDate} onChange={setSelectDate} />
              {(loadingData === true && (
                <CircularProgress color="secondary" />
              )) || (
                <IconButton
                  onClick={() => {
                    loadData();
                  }}
                >
                  <RefreshRounded />
                </IconButton>
              )}
            </Stack>
          </Stack>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <TableCell key={headCell.id}>{headCell.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {newRecharges.map((item, index) => (
                  <TableRow
                    key={item._id}
                    style={{
                      backgroundColor:
                        item.deleted === "true" ? "#E0E0E0" : "inherit",
                    }}
                    hover
                    onClick={() => {
                      handleCustSelected(item.user);
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <b>{item.user.name}</b>
                    </TableCell>
                    <TableCell>
                      <Label
                        variant="filled"
                        color={
                          item?.user?.onboarding_status === "completed"
                            ? "success"
                            : item?.user?.onboarding_status === "later"
                            ? "warning"
                            : item?.user?.onboarding_status === "not-interested"
                            ? "info"
                            : item?.user?.onboarding_status === "duplicate"
                            ? "secondary"
                            : item?.user?.onboarding_status === "ongoing"
                            ? "primary"
                            : "error"
                        }
                      >
                        <b>
                          {item?.user?.onboarding_status?.toUpperCase() || "-"}
                        </b>
                      </Label>
                    </TableCell>

                    <TableCell>
                      <Label
                        color={item?.user?.cart ? "success" : "error"}
                        variant="ghost"
                      >
                        {item?.user?.cart ? "ASSIGNED" : "NONE"}
                      </Label>
                    </TableCell>
                    <TableCell>₹ {item?.amount || 0}</TableCell>
                    {(item.transacted_by && (
                      <TableCell>
                        <Label color="info" variant="ghost">
                          CASH @ {item?.transacted_by?.name}
                        </Label>
                      </TableCell>
                    )) || (
                      <TableCell>
                        <Label color="success" variant="ghost">
                          ONLINE
                        </Label>
                      </TableCell>
                    )}

                    <TableCell>
                      {getTimeStringFullSync(
                        parseInt(item?._id.substring(0, 8), 16) * 1000
                      )}
                    </TableCell>

                    <Tooltip title={item?.offer_id?.description}>
                      <TableCell
                        onClick={(e) => {
                          if (!item?.offer_id) {
                            e.stopPropagation();
                            setSelectedTxn(item);
                            setSearchOfferState(true);
                          }
                        }}
                      >
                        <Label
                          variant="outlined"
                          color={
                            item.offer_status === "pending"
                              ? "warning"
                              : item.offer_status === "true"
                              ? "success"
                              : "error"
                          }
                        >
                          {item?.offer_id?.promocode || "-"}
                        </Label>
                      </TableCell>
                    </Tooltip>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Card>

      <CustomerDetailsPage
        customer={currCust}
        handleClose={() => setDrawerOpen(false)}
        drawerOpen={drawerOpen}
      />

      {searchOfferState && (
        <Drawer
          open={searchOfferState}
          onClose={() => setSearchOfferState(false)}
          sx={{ zIndex: 1300 }}
          anchor="right"
          PaperProps={{
            sx: { width: "40%" },
          }}
        >
          <div style={{ padding: "10px" }}>
            <OfferSearch onDataSelected={onSearchOfferReturn} />
          </div>
        </Drawer>
      )}
    </div>
  );
}
