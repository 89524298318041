import {
  Container,
  Typography,
  Stack,
  MenuItem,
  Tab,
  TextField,
  Grid,
  Drawer,
} from "@mui/material";
import { useAuth } from "src/contexts/AuthContext";

import Page from "src/components/Page";
import { useState } from "react";

import AgentCallHistorySection from "./AgentCallHistorySection";

import { TabContext, TabList, TabPanel } from "@mui/lab";

import CustomerDetailsPage from "../home/det/CustomerDetailsPage";
import AllCalls from "./AllCalls";
import ManagerSearch from "../manager/ManagerSearch";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Calls() {
  const { currentManager } = useAuth();

  const [selectMgr, setSelectMgr] = useState(
    localStorage.getItem("crm_select_mgr") || currentManager._id
  );
  const [selectMgrList, setSelectMgrList] = useState({
    all: "ALL",
    [currentManager._id]: "My",
    none: "None",
    other: "Other",
  });

  const [managerSearchDrawerState, setManagerSearchDrawerState] =
    useState(false);

  const handleMgrChange = (event) => {
    if (event.target.value === "other") {
      setManagerSearchDrawerState(true);
    } else {
      setSelectMgr(event.target.value);
      localStorage.setItem("crm_select_mgr", event.target.value);
    }
  };

  const managerSearchDrawerReturn = (acc) => {
    setSelectMgrList({ ...selectMgrList, [acc._id]: acc.name });
    setSelectMgr(acc._id);
    setManagerSearchDrawerState(false);
  };

  const [currentMenu, setCurrentMenu] = useState(
    localStorage.getItem("calls_default_menu") || "ch"
  );

  const handleTabChange = (event, newValue) => {
    setCurrentMenu(newValue);
    localStorage.setItem("calls_default_menu", newValue);
  };

  const [custDrawerOpen, setCustDrawerOpen] = useState(false);
  const [selectedCust, setSelectedCust] = useState(null);

  const handleCustomerOpen = (custData) => {
    setSelectedCust(custData);
    setCustDrawerOpen(true);
  };

  return (
    <Page title="CRM Dashboard">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography variant="h4">Calls Dashboard</Typography>
          <Stack direction="row" spacing={2}>
            <TextField
              select
              id="mgr-select"
              value={selectMgr}
              onChange={(e) => {
                handleMgrChange(e);
              }}
              label="Mgr"
              SelectProps={{
                style: { color: selectMgr === "all" ? "black" : "red" },
              }}
            >
              {Object.keys(selectMgrList).map((mgr) => (
                <MenuItem value={mgr}>{selectMgrList[mgr]}</MenuItem>
              ))}
            </TextField>
          </Stack>
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <TabContext value={currentMenu}>
              <TabList
                onChange={handleTabChange}
                variant="scrollable"
                textColor="primary"
                indicatorColor="primary"
              >
                <Tab label="My Calls" value="ch" {...a11yProps(0)} />
                <Tab label="All Calls" value="all" {...a11yProps(1)} />
              </TabList>

              <TabPanel value="ch">
                <AgentCallHistorySection />
              </TabPanel>

              <TabPanel value="all">
                <AllCalls selectMgr={selectMgr} />
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Container>

      <Drawer
        anchor={"right"}
        open={managerSearchDrawerState}
        onClose={() => setManagerSearchDrawerState(false)}
        sx={{ zIndex: 1300, p: 3 }}
      >
        <ManagerSearch onAccSelected={managerSearchDrawerReturn} />
      </Drawer>

      <CustomerDetailsPage
        customer={selectedCust}
        handleClose={() => setCustDrawerOpen(false)}
        drawerOpen={custDrawerOpen}
      />
    </Page>
  );
}
