import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";
import { styled } from "@mui/material/styles";

import {
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  CircularProgress,
  Stack,
  Modal,
  Box,
  Tooltip,
  Button,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import {
  getDateStringFullWithTimeSync,
  getDayStart,
  getDayEnd,
  getDayStartAndEndSync,
} from "src/utils/date-time-helper";

import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { MobileDatePicker } from "@mui/x-date-pickers";
import InternalTxnDetailsModalAdmin from "./InternalTxnDetailsModalAdmin";
import AddTxnModal from "./AddTxnModal";
import {
  DownloadRounded,
  RefreshRounded,
  SearchRounded,
} from "@mui/icons-material";
import { exportExcel } from "src/utils/excel-export-utils";
import MakeFullScreen from "src/components/MakeFullScreen";
import InternalAccDetailsPage from "../details/InternalAccDetailsPage";
import Label from "src/pages/crm/Label";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "amount", label: "Amount", alignRight: false },
  { id: "entity", label: "Entity", alignRight: false },
  { id: "from", label: "CR", alignRight: false },
  { id: "to", label: "DR", alignRight: false },
  { id: "date", label: "Date", alignRight: false },
  { id: "method", label: "Method", alignRight: false },
  { id: "center", label: "Center", alignRight: false },
  { id: "comments", label: "Comments", alignRight: false },
];

export default function DayBookPageAdmin({ open, onClose }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedFr, setSelectedFr] = useState("all");
  const [search, setSearch] = useState("");

  const [startDate, setStartDate] = useState(getDayStart(Date.now()));
  const [endDate, setEndDate] = useState(getDayEnd(Date.now()));

  const { getAccessToken, activeFranchise, allFranchisees } = useAuth();

  let timer = "";

  const handleChangeQuery = (event) => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      setSearch(event.target.value);
    }, 1000);
  };

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/internalaccs/coreact/txns/query?sort=date&dir=d&date_gt=${startDate}&date_lt=${endDate}`;

    if (search && search.length > 0) url += `&comments=${search}`;

    if (selectedCategory !== "all") url += `&entity=${selectedCategory}`;

    if (selectedFr && selectedFr !== "all") url += `&franchisee=${selectedFr}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    handleSearch();
  }, [startDate, endDate, selectedFr, selectedCategory, search]); // eslint-disable-line react-hooks/exhaustive-deps

  const [txnDetailsModal, setTxnDetailsModal] = useState(null);
  const [addTxnModalOpen, setAddTxnModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(false);

  const onDownloadExcel = () => {
    exportExcel("TxnsCatPageAdmin", customerData, "Txns" + Date.now());
  };

  return (
    <MakeFullScreen
      title="DayBook"
      open={open}
      onClose={onClose}
      header={
        <Button
          variant="contained"
          color="white"
          onClick={() => setAddTxnModalOpen(true)}
        >
          Add Txn
        </Button>
      }
    >
      <>
        <Container sx={{ minHeight: 800 }}>
          <Stack direction="row" alignItems="center" mb={1} mt={2} spacing={1}>
            <SearchStyle
              placeholder="Search Comments"
              onChange={handleChangeQuery}
              startAdornment={
                <InputAdornment position="start">
                  <Box sx={{ color: "text.disabled" }}>
                    <SearchRounded />
                  </Box>
                </InputAdornment>
              }
            />

            <Select
              id="expense-select"
              value={selectedCategory}
              onChange={(event) => setSelectedCategory(event.target.value)}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"cashflow"}>Cash Flow</MenuItem>
              <MenuItem value={"farmerpay"}>Farmer Pay</MenuItem>
              <MenuItem value={"dispensein"}>Dispense Cash</MenuItem>
              <MenuItem value={"bill"}>Purchase Bill</MenuItem>
              <MenuItem value={"salebill"}>Sale Bill</MenuItem>
              <MenuItem value={"salary"}>Salary</MenuItem>
              <MenuItem value={"payroll"}>Payroll</MenuItem>
              <MenuItem value={"expense"}>Expense</MenuItem>
              <MenuItem value={"writeoff"}>Write Off</MenuItem>
            </Select>

            <Select
              id="fr-select"
              value={selectedFr}
              onChange={(event) => setSelectedFr(event.target.value)}
            >
              <MenuItem value={"all"}>All</MenuItem>
              {allFranchisees.map((item) => (
                <MenuItem value={item._id}>{item.name}</MenuItem>
              ))}
            </Select>

            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={"en-in"}
            >
              <MobileDatePicker
                value={moment(startDate)}
                onChange={(newValue) => {
                  if (newValue) {
                    const d1 = getDayStartAndEndSync(newValue.valueOf());
                    if (startDate !== d1.start) {
                      setStartDate(d1.start);
                      setEndDate(d1.end);
                    }
                  }
                }}
                closeOnSelect={true}
                format="DD/MM/YYYY"
                loading={loadingData}
              />
            </LocalizationProvider>

            <b>-</b>

            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={"en-in"}
            >
              <MobileDatePicker
                value={moment(endDate)}
                onChange={(newValue) => {
                  if (newValue) {
                    const d1 = getDayEnd(newValue.valueOf());
                    if (endDate !== d1) setEndDate(d1);
                  }
                }}
                closeOnSelect={true}
                format="DD/MM/YYYY"
                loading={loadingData}
              />
            </LocalizationProvider>

            {customerData.length > 0 && (
              <IconButton onClick={() => onDownloadExcel()}>
                <DownloadRounded />
              </IconButton>
            )}

            {(loadingData === true && (
              <CircularProgress color="secondary" />
            )) || (
              <IconButton onClick={() => handleSearch()} sx={{ m: 0 }}>
                <RefreshRounded />
              </IconButton>
            )}
          </Stack>

          {customerData.length > 0 && (
            <div>
              <TableContainer sx={{ minHeight: 100 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.alignRight ? "right" : "left"}
                          sortDirection={false}
                        >
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {customerData.map((item) => {
                      const color =
                        item.status === "success"
                          ? "white"
                          : [
                              "pending",
                              "processing",
                              "initiated",
                              "approved",
                            ].includes(item.status)
                          ? "lightcyan"
                          : "lightsalmon";
                      return (
                        <TableRow
                          hover
                          onClick={() => setTxnDetailsModal(item._id)}
                          style={{ backgroundColor: color }}
                        >
                          <TableCell>
                            <b>₹ {item.amount.toFixed(1)}</b>
                          </TableCell>
                          <TableCell>
                            <Label color="primary" variant="outlined">
                              {item?.entity?.toUpperCase() || "-"}
                            </Label>
                          </TableCell>
                          <TableCell
                            sx={{ color: "success.dark" }}
                            onClick={(e) => {
                              setSelectedAccount(item?.from);
                              e.stopPropagation();
                            }}
                          >
                            {item.from ? item.from.name : "-"}
                          </TableCell>
                          <TableCell
                            sx={{ color: "error.main" }}
                            onClick={(e) => {
                              setSelectedAccount(item?.to);
                              e.stopPropagation();
                            }}
                          >
                            {item.to ? item.to.name : "-"}
                          </TableCell>
                          <TableCell>
                            {getDateStringFullWithTimeSync(item.date)}
                          </TableCell>
                          <TableCell>
                            {item.method && item.method.toUpperCase()}
                          </TableCell>
                          <TableCell>
                            {(item?.franchisee?.name && (
                              <Label color="primary">
                                {item.franchisee.name}
                              </Label>
                            )) ||
                              "-"}
                          </TableCell>
                          <Tooltip title={item.comments || ""} arrow>
                            <TableCell>
                              {item.comments
                                ? item.comments.substring(0, 20)
                                : "-"}
                            </TableCell>
                          </Tooltip>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}

          {customerData.length === 0 && loadingData === false && (
            <div>
              <Typography gutterBottom align="center" variant="subtitle1">
                Not found
              </Typography>
              <Typography variant="body2" align="center" sx={{ mb: 4 }}>
                No results here.
              </Typography>
            </div>
          )}
        </Container>

        {txnDetailsModal && (
          <InternalTxnDetailsModalAdmin
            handleM1Close={() => setTxnDetailsModal(null)}
            poutId={txnDetailsModal}
            drawerOpen={txnDetailsModal ? true : false}
          />
        )}

        <Modal open={addTxnModalOpen} onClose={() => setAddTxnModalOpen(false)}>
          <AddTxnModal
            handleM1Close={() => setAddTxnModalOpen(false)}
            duplicateData={{ date: startDate + 10 * 60 * 60 * 1000 }}
          />
        </Modal>

        {selectedAccount && (
          <InternalAccDetailsPage
            inpAcc={selectedAccount}
            open={true}
            onClose={() => setSelectedAccount(null)}
          />
        )}
      </>
    </MakeFullScreen>
  );
}
