import {
  Grid,
  Container,
  Typography,
  Stack,
  Button,
  Divider,
} from "@mui/material";

import Page from "../../components/Page";
import ProductCategory from "./ProductCategory";
import ProductList from "./ProductList";
import { useState } from "react";
import ProductDetailsPage from "./det/ProductDetailsPage";

export default function Products() {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productModalState, setProductModalState] = useState(false);

  return (
    <Page title="Products Dashboard">
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} lg={3}>
            <ProductCategory onItemSelected={(i) => setSelectedCategory(i)} />
          </Grid>
          <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
          <Grid item xs={12} md={9} lg={9}>
            <ProductList
              category={selectedCategory}
              onItemSelected={(i) => {
                setSelectedProduct(i);
                setProductModalState(true);
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <ProductDetailsPage
        product={selectedProduct}
        handleClose={() => setProductModalState(false)}
        drawerOpen={productModalState}
      />
    </Page>
  );
}
