import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import {
  Container,
  Card,
  Alert,
  Grid,
  Stack,
  Typography,
  CircularProgress,
  TableRow,
  TableCell,
  TableBody,
  Drawer,
} from "@mui/material";

import Page from "src/components/Page";
import { dataFetch } from "src/utils/data-fetch";
import { useLocation } from "react-router-dom";
import { getDateStringFullWithTimeSync } from "src/utils/date-time-helper";
import WalletSaleSection from "../milkcart/det/WalletSaleSection";
import WalletRechargeSection from "../milkcart/det/WalletRechargeSection";
import OnlineMoneyDrawer from "./OnlineMoneyDrawer";

export default function DispenseDetailsPage({ dispense }) {
  const location = useLocation();
  let account = location?.state?.account;
  if (!account) account = dispense;
  const [error, setError] = useState();

  const [currentData, setCurrentData] = useState();
  const [loadingData, setLoadingData] = useState();

  const [walletSale, setWalletSale] = useState();
  const [walletRecharge, setWalletRecharge] = useState();
  const [onlineMoney, setOnlineMoney] = useState();

  const { getAccessToken, activeFranchise } = useAuth();

  const loadData = async () => {
    setLoadingData(true);
    let url = `/api/dispenses/mgract/calculation/dispense/${account._id}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCurrentData(ret.data);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, []);
  console.log(currentData);

  const [searchDrawerState, setSearchDrawerState] = useState(null);

  const drawerSearchInit = (type) => {
    setSearchDrawerState(type);
  };

  const drawerReturnSearch = (acc) => {
    console.log(acc);
    if (searchDrawerState === "wallletSale") {
      setWalletSale(acc);
    } else if (searchDrawerState === "walletRecharge") {
      setWalletRecharge(acc);
    } else if (searchDrawerState === "onlineMoney") {
      setOnlineMoney(acc);

      setSearchDrawerState(null);
    }
  };
  return (
    <Page>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography variant="h4">
            {(currentData && currentData.name) || "Calculation"}
          </Typography>
        </Stack>

        {currentData && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={10} md={12} lg={12}>
                  <Card sx={{ p: 3 }}>
                    {loadingData === true && <CircularProgress />}
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <b>Date :</b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {getDateStringFullWithTimeSync(currentData.date)}
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Cart :</b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {currentData?.cart ? currentData?.cart?.name : "-"}
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Session :</b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {currentData?.session === 0
                              ? "MOR"
                              : currentData?.session === 1
                              ? "EVE"
                              : "M+E"}
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Area :</b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {currentData?.area ? currentData?.area?.name : "-"}
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Driver :</b>
                        </TableCell>
                        <TableCell>
                          {" "}
                          <b>
                            {currentData?.driver
                              ? currentData?.driver.name
                              : "-"}
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Type :</b>
                        </TableCell>
                        <TableCell>
                          <b>{currentData?.milk_type === 0 ? "COW" : "BUFF"}</b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Card>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Card sx={{ p: 3 }}>
                    {loadingData === true && <CircularProgress />}
                    <TableBody>
                      <b>CASH DETAILS</b>
                      <TableRow>
                        <TableCell>
                          <b>500 & 200:</b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {" "}
                            {currentData?.cash_details[0]?.den || 0} *
                            {currentData?.cash_details[0]?.qty || 0}+
                            {currentData?.cash_details[1]?.den || 0} *
                            {currentData?.cash_details[1]?.qty || 0}
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>100 & 50:</b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {" "}
                            +{currentData?.cash_details[2]?.den || 0} *
                            {currentData?.cash_details[2]?.qty || 0}+
                            {currentData?.cash_details[3]?.den || 0} *
                            {currentData?.cash_details[3]?.qty || 0}
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>20 & 10:</b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {" "}
                            +{currentData?.cash_details[4]?.den || 0} *
                            {currentData?.cash_details[4]?.qty || 0}+
                            {currentData?.cash_details[5]?.den || 0} *
                            {currentData?.cash_details[5]?.qty || 0}
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>5 & 2:</b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {" "}
                            +{currentData?.cash_details[6]?.den || 0} *
                            {currentData?.cash_details[6]?.qty || 0}+
                            {currentData?.cash_details[7]?.den || 0} *
                            {currentData?.cash_details[7]?.qty || 0}
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>1:</b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {" "}
                            +{currentData?.cash_details[8]?.den || 0} *
                            {currentData?.cash_details[8]?.qty || 0}
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Total:</b>
                        </TableCell>
                        <TableCell>
                          <b>
                            ₹
                            {(currentData?.cash_details[0]?.den || 0) *
                              (currentData?.cash_details[0]?.qty || 0) +
                              (currentData?.cash_details[1]?.den || 0) *
                                (currentData?.cash_details[1]?.qty || 0) +
                              (currentData?.cash_details[2]?.den || 0) *
                                (currentData?.cash_details[2]?.qty || 0) +
                              (currentData?.cash_details[3]?.den || 0) *
                                (currentData?.cash_details[3]?.qty || 0) +
                              (currentData?.cash_details[4]?.den || 0) *
                                (currentData?.cash_details[4]?.qty || 0) +
                              (currentData?.cash_details[5]?.den || 0) *
                                (currentData?.cash_details[5]?.qty || 0) +
                              (currentData?.cash_details[6]?.den || 0) *
                                (currentData?.cash_details[6]?.qty || 0) +
                              (currentData?.cash_details[7]?.den || 0) *
                                (currentData?.cash_details[7]?.qty || 0) +
                              (currentData?.cash_details[8]?.den || 0) *
                                (currentData?.cash_details[8]?.qty || 0)}
                          </b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Card sx={{ p: 3 }}>
                    {loadingData === true && <CircularProgress />}
                    <TableBody>
                      <TableRow onClick={() => drawerSearchInit("walletSale")}>
                        <TableCell>
                          <b>Wallet Sale :</b>
                        </TableCell>
                        <TableCell>
                          <b>₹ {currentData?.wallet_sale || "0"}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        onClick={() => drawerSearchInit("walletRecharge")}
                      >
                        <TableCell>
                          <b>Recharge:</b>
                        </TableCell>
                        <TableCell>
                          <b>₹ {currentData?.wallet_recharge || "0"}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow onClick={() => drawerSearchInit("onlineMoney")}>
                        <TableCell>
                          <b>Online Money:</b>
                        </TableCell>
                        <TableCell>
                          <b>₹ {currentData?.online_money || "0"}</b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Card>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Grid spacing={2}>
                    <Card sx={{ p: 3 }}>
                      {loadingData === true && <CircularProgress />}
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <b>Milk Sent :</b>
                          </TableCell>
                          <TableCell>
                            <b>{currentData?.milk_sent || "0"} Lt.</b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <b>Milk Return :</b>
                          </TableCell>
                          <TableCell>
                            <b>{currentData?.milk_return || "0"} Lt.</b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <b>Sale Quantity :</b>
                          </TableCell>
                          <TableCell>
                            <b>{currentData?.sale_quantity || "0"} Lt.</b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <b>Milk Rate :</b>
                          </TableCell>
                          <TableCell>
                            <b>₹ {currentData?.milk_rate || "-"}</b>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Card sx={{ p: 3 }}>
                    <TableBody>
                      <b>{"MILK OUTS"}</b>
                      <TableRow>
                        <TableCell>
                          <b>Dispense :</b>
                        </TableCell>
                        <TableCell>
                          <b>{currentData?.dispense || "0"} Lt.</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Int :</b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {currentData?.int ? currentData?.int : "0"} times
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Power :</b>
                        </TableCell>
                        <TableCell>
                          <b>{currentData?.power ? currentData?.power : "-"}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Calib :</b>
                        </TableCell>
                        <TableCell>
                          <b>{currentData?.calib ? currentData?.calib : "-"}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>updated :</b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {" "}
                            {getDateStringFullWithTimeSync(currentData?.date)
                              ? getDateStringFullWithTimeSync(currentData?.date)
                              : "-"}
                          </b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Container>
      <Drawer
        anchor={"right"}
        open={searchDrawerState ? true : false}
        onClose={() => setSearchDrawerState(null)}
        sx={{ zIndex: 1300, p: 3 }}
      >
        {searchDrawerState === "walletSale" && (
          <WalletSaleSection cart={currentData.cart} date1={currentData.date} />
        )}
        {searchDrawerState === "walletRecharge" && (
          <WalletRechargeSection
            cart={currentData.cart}
            date1={currentData.date}
          />
        )}
        {searchDrawerState === "onlineMoney" && (
          <OnlineMoneyDrawer cart={currentData.cart} date1={currentData.date} />
        )}
      </Drawer>
    </Page>
  );
}
