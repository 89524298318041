import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Stack,
  Tooltip,
} from "@mui/material";
import { getDateStringFullWithTimeSync } from "src/utils/date-time-helper";

const TABLE_HEAD = [
  { id: "datetime", label: "Date & Time", alignRight: false },
  { id: "ticketnumber", label: "Ticket Number", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "category", label: "Category", alignRight: false },
];

export default function SupportTicketCard({ customer, defaultList = 10 }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const loadData = async () => {
    setLoadingData(true);

    let url = `/api/users/mgract/user/${customer._id}/referral`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, [customer, page]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h5" gutterBottom>
            Support Ticket
          </Typography>
        </Stack>

        <TableContainer sx={{ minHeight: 100 }}>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.alignRight ? "right" : "left"}
                    sortDirection={false}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {customerData.map((item) => {
                return (
                  <TableRow>
                    <TableCell>
                      {getDateStringFullWithTimeSync(item?.date)}
                    </TableCell>
                    <TableCell>{item?.name}</TableCell>
                    <TableCell>{item?.ticket_number}</TableCell>
                    <TableCell>{item?.category}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={-1}
          rowsPerPage={defaultList}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[defaultList]}
        />
      </Container>
    </Card>
  );
}
