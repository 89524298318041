import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch, dataPost } from "src/utils/data-fetch";

import {
  Card,
  Typography,
  Box,
  Grid,
  Select,
  Stack,
  CircularProgress,
  TableBody,
  InputAdornment,
  MenuItem,
  TableRow,
  TableCell,
  IconButton,
  Modal,
} from "@mui/material";

import { getDateStringFullWithMonthSync } from "src/utils/date-time-helper";

export default function AdminEmployeeProfile({ employee }) {
  const [doc, setDoc] = useState();
  const [fr, setFr] = useState("");
  const [loadingData1, setLoadingData1] = useState();
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise, allFranchisees } = useAuth();

  const loadData = async () => {
    setLoadingData(true);
    let url = `/api/employees/coreact/employee/${employee.employee}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setDoc(ret.data);
      setFr(ret?.data?.franchisee?._id);
      console.log(ret.data);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, [employee]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateStatusInServer = async (status) => {
    console.log(status);
    setLoadingData1(true);

    let url = `/api/employees/coreact/employee/${employee.employee}/fr`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      { franchisee: status },
      activeFranchise
    );
    if (ret.success === true) {
      setFr(status);
    } else {
      alert(ret.message);
    }
    setLoadingData1(false);
  }; // eslint-disable-line react-hooks/exhaustive-deps

  const handleFrChange = (event) => {
    updateStatusInServer(event.target.value);
  };
  return (
    <>
      <Card>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: 3, mx: 3 }}
        >
          <Typography variant="h5" gutterBottom>
            Details
          </Typography>

          {loadingData === true && <CircularProgress />}
        </Stack>

        {doc && (
          <Grid container columnSpacing={1} sx={{ pl: 2, pr: 2 }}>
            <Grid item xs={12} md={4} lg={4}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Name:</b>
                  </TableCell>
                  <TableCell>{doc.employee_det.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>UIN:</b>
                  </TableCell>
                  <TableCell>{doc.employee_det.uin}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Phone:</b>
                  </TableCell>
                  <TableCell>{doc.phone}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Alt Ph:</b>
                  </TableCell>
                  <TableCell>{doc.employee_det.alt_phone}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Address:</b>
                  </TableCell>
                  <TableCell>{doc.employee_det.address}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Roles:</b>
                  </TableCell>
                  <TableCell>
                    {JSON.stringify(doc.employee_det.roles)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Joining:</b>
                  </TableCell>
                  <TableCell>
                    {getDateStringFullWithMonthSync(doc.employee_det.join_date)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>DOB:</b>
                  </TableCell>
                  <TableCell>
                    {getDateStringFullWithMonthSync(doc.employee_det.dob)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>TO:</b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Status:</b>
                  </TableCell>
                  <TableCell>
                    {doc?.employee_det?.status?.toUpperCase()}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Base:</b>
                  </TableCell>
                  <TableCell>₹ {doc.employee_det.base_salary}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Allowance:</b>
                  </TableCell>
                  <TableCell>₹ {doc.employee_det.allowance}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Incentive:</b>
                  </TableCell>
                  <TableCell>₹ {doc.employee_det.var_salary} pL</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Product:</b>
                  </TableCell>
                  <TableCell>
                    Bonus{" "}
                    {doc.employee_det.product_bonus === true
                      ? "Active"
                      : "Inactive"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Leaves:</b>
                  </TableCell>
                  <TableCell>{doc.employee_det.leaves_pm} per month</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Tshirt:</b>
                  </TableCell>
                  <TableCell>{doc.employee_det.tshirt_size}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Blood Grp:</b>
                  </TableCell>
                  <TableCell>{doc.employee_det.blood_group}</TableCell>
                </TableRow>
              </TableBody>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Card sx={{ p: 2 }}>
                <Stack direction="row" alignItems="center" spacing={3}>
                  <Select
                    id="franchisee-select"
                    value={fr}
                    onChange={handleFrChange}
                    placeholder="-"
                    startAdornment={
                      <InputAdornment position="start">
                        <Box sx={{ color: "text.disabled" }}>Franchisee</Box>
                      </InputAdornment>
                    }
                  >
                    {allFranchisees.map((item) => (
                      <MenuItem value={item._id}>{item.name}</MenuItem>
                    ))}
                  </Select>

                  {loadingData1 === true && <CircularProgress />}
                </Stack>
              </Card>
            </Grid>
          </Grid>
        )}

        <Stack direction="row">
          <Box sx={{ flexGrow: 1 }} />
        </Stack>
      </Card>
    </>
  );
}
