import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";
import Page from "src/components/Page";

import {
  Stack,
  Typography,
  Grid,
  Container,
  Card,
  CardActionArea,
} from "@mui/material";

import { dataFetch } from "src/utils/data-fetch";
import ExpensesDetailsDrawer from "./ExpensesDetailsDrawer";

import { getMonthStartAndEndSync } from "src/utils/date-time-helper";

import { MonthSelector } from "src/utils/date-selector";
import ExpenseHistoryPaginated from "./ExpenseHistoryPaginated";
import PendingExpenseApproval from "./PendingExpenseApproval";

export default function Expenses() {
  const { getAccessToken, activeFranchise } = useAuth();

  const [selectedMonth, setSelectedMonth] = useState(Date.now());
  const { start, end } = getMonthStartAndEndSync(selectedMonth);

  const [expenses, setExpenses] = useState([]);

  const initialLoad = async () => {
    let url = `/api/expenses/expenses/mgract/summary?date_gt=${start}&date_lt=${end}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setExpenses(ret.data);
    } else {
      setExpenses([]);
    }
  };

  useEffect(() => {
    initialLoad();
  }, [selectedMonth, activeFranchise]);

  const [selectedExpense, setSelectedExpense] = useState(null);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  return (
    <Page title="Expense Dashboard">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="h5" gutterBottom>
            Expense Dashboard
          </Typography>

          <MonthSelector value={selectedMonth} onChange={setSelectedMonth} />
        </Stack>

        <Grid container spacing={3}>
          {expenses.map((expense) => (
            <ExpenseCategoryCard
              data={expense}
              onClick={() => {
                setSelectedExpense(expense);
                setDrawerOpen(true);
              }}
            />
          ))}

          <Grid item xs={12} md={12} lg={12}>
            <PendingExpenseApproval />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <ExpenseHistoryPaginated />
          </Grid>
        </Grid>

        {selectedExpense != null && (
          <ExpensesDetailsDrawer
            open={isDrawerOpen}
            onClose={() => setDrawerOpen(false)}
            expense={selectedExpense}
            selectedMonth={selectedMonth}
            updateMonth={setSelectedMonth}
          />
        )}
      </Container>
    </Page>
  );
}

const ExpenseCategoryCard = ({ data, onClick }) => {
  return (
    <Grid item key={data._id} xs={6} sm={4} md={4}>
      <Card sx={{ boxShadow: 3 }}>
        <CardActionArea onClick={onClick} sx={{ p: 2 }}>
          <Stack direction={"column"}>
            <Typography variant="h5" color={"primary"} mb={2}>
              {data?.category || "Expense"}
            </Typography>
            <Typography variant="h6" color={"black"}>
              ₹ {data.total.toFixed(1)}
            </Typography>
          </Stack>
        </CardActionArea>
      </Card>
    </Grid>
  );
};
