import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Dialog,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";
import {
  AddCommentRounded,
  CloseRounded,
  CorporateFareRounded,
  EmailRounded,
  LocationCityRounded,
  PercentRounded,
  PersonRounded,
  PhoneRounded,
} from "@mui/icons-material";

import { forwardRef } from "react";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddPartyModal({ drawerOpen, handleM1Close }) {
  const [error, setError] = useState();

  const { getAccessToken, activeFranchise } = useAuth();

  const approveNow = async (values) => {
    const ret = await dataPost(
      `/api/extrasales/mgract/users/add`,
      await getAccessToken(),
      {
        name: values.name,
        address: values.address,
        phone: `+91${values.phone}`,
        owner: values.owner,
        poc: values.poc,
        poc_phone: `+91${values.poc_phone}`,
        comments: values.comments,
        email: values.email,
        gst: values.gst,
      },
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    address: Yup.string().required("Address is required"),
    phone: Yup.number()
      .required("Enter 10 digit number")
      .max(9999999999)
      .min(1111111111),
    owner: Yup.string().required("Owner name is required"),
    poc: Yup.string().required("Point of contact name is required"),
    poc_phone: Yup.number()
      .required("Enter 10 digit number")
      .max(9999999999)
      .min(1111111111),
    comments: Yup.string().optional(),
    email: Yup.string().optional(),
    gst: Yup.number().optional(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      phone: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="body"
      open={drawerOpen}
      onClose={handleM1Close}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            Add Party
          </Typography>
          <IconButton edge="end" color="black" onClick={handleM1Close}>
            <CloseRounded />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Stack direction="column" spacing={2} p={2}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextField
                autoComplete="off"
                fullWidth
                type="text"
                label="Party Name"
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                InputProps={{
                  startAdornment: <CorporateFareRounded />,
                }}
              />

              <TextField
                fullWidth
                multiline
                type="text"
                label="Address"
                {...getFieldProps("address")}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
                InputProps={{
                  startAdornment: <LocationCityRounded />,
                }}
              />

              <TextField
                fullWidth
                type="text"
                label="Owner Name"
                {...getFieldProps("owner")}
                error={Boolean(touched.owner && errors.owner)}
                helperText={touched.owner && errors.owner}
                InputProps={{
                  startAdornment: <PersonRounded />,
                }}
              />

              <TextField
                fullWidth
                type="phone"
                label="Owner Phone"
                {...getFieldProps("phone")}
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone && errors.phone}
                InputProps={{
                  startAdornment: <PhoneRounded />,
                }}
              />

              <TextField
                fullWidth
                type="text"
                label="POC Name"
                {...getFieldProps("poc")}
                error={Boolean(touched.poc && errors.poc)}
                helperText={touched.poc && errors.poc}
                InputProps={{
                  startAdornment: <PersonRounded />,
                }}
              />
              <TextField
                fullWidth
                type="phone"
                label="POC Phone"
                {...getFieldProps("poc_phone")}
                error={Boolean(touched.poc_phone && errors.poc_phone)}
                helperText={touched.poc_phone && errors.poc_phone}
                InputProps={{
                  startAdornment: <PhoneRounded />,
                }}
              />

              <TextField
                fullWidth
                type="email"
                label="Email"
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                InputProps={{
                  startAdornment: <EmailRounded />,
                }}
              />

              <TextField
                fullWidth
                type="text"
                label="Comments"
                {...getFieldProps("comments")}
                error={Boolean(touched.comments && errors.comments)}
                helperText={touched.comments && errors.comments}
                InputProps={{
                  startAdornment: <AddCommentRounded />,
                }}
              />

              <TextField
                fullWidth
                type="text"
                label="GST"
                {...getFieldProps("gst")}
                error={Boolean(touched.gst && errors.gst)}
                helperText={touched.gst && errors.gst}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>
      </Stack>

      {error && (
        <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Dialog>
  );
}
