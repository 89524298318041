import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import Select from "@mui/material/Select";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Grid,
  Box,
  InputAdornment,
  MenuItem,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddDispenserModal({
  handleM1Close,
  duplicateData,
  manager,
}) {
  const [error, setError] = useState();

  const { getAccessToken, allFranchisees } = useAuth();

  const addDispenser = async (values) => {
    const ret = await dataPost(
      `/api/milkdispenser/coreact/add`,
      await getAccessToken(),
      {
        franchisee: values.franchisee,
        ssid: values.ssid,
        category: values.category,
        pass: values.pass,
        calib_pass: values.calib_pass,
        mac: values.mac,
      }
    );
    if (ret.success === true) {
      alert("Dispenser added");
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    franchisee: Yup.string().optional(),
    ssid: Yup.string().required("ssid is required"),
    category: Yup.string().required("Enter category!"),
    pass: Yup.string().optional(),
    calib_pass: Yup.string().optional(),
    mac: Yup.string().optional(),
  });
  const formik = useFormik({
    initialValues: {
      franchisee:
        (duplicateData &&
          duplicateData.franchisee &&
          duplicateData.franchisee._id) ||
        undefined,

      ssid: "",
      category: "",
      pass: "",
      calib_pass: "",
      mac: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await addDispenser(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Add Dispenser
          </Typography>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Stack spacing={3}></Stack>
          </Grid>
        </Grid>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={3}>
                  <Select
                    id="fr-select"
                    {...getFieldProps("franchisee")}
                    MenuProps={MenuProps}
                    startAdornment={
                      <InputAdornment position="start">
                        <Box sx={{ color: "text.disabled" }}>Franchise</Box>
                      </InputAdornment>
                    }
                  >
                    {allFranchisees.map((item) => (
                      <MenuItem value={item._id}>{item.name}</MenuItem>
                    ))}
                  </Select>

                  <TextField
                    fullWidth
                    type="text"
                    label="Category"
                    {...getFieldProps("category")}
                    error={Boolean(touched.category && errors.category)}
                    helperText={touched.category && errors.category}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="Calib_Pass"
                    {...getFieldProps("calib_pass")}
                    error={Boolean(touched.calib_pass && errors.calib_pass)}
                    helperText={touched.calib_pass && errors.calib_pass}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    type="text"
                    label="SSID"
                    {...getFieldProps("ssid")}
                    error={Boolean(touched.ssid && errors.ssid)}
                    helperText={touched.ssid && errors.ssid}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Pass"
                    {...getFieldProps("pass")}
                    error={Boolean(touched.pass && errors.pass)}
                    helperText={touched.pass && errors.pass}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Mac"
                    {...getFieldProps("mac")}
                    error={Boolean(touched.mac && errors.mac)}
                    helperText={touched.mac && errors.mac}
                  />
                </Stack>
              </Grid>
            </Grid>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
