import axios from "axios";

export const rootUrl = "https://api.milkvilla.in";
// export const rootUrl = "http://192.168.31.63:5000";

export async function dataFetch(subUrl, token, fr) {
  try {
    const ret = await axios.get(rootUrl + subUrl, {
      headers: {
        Authorization: token,
        cart: fr,
      },
    });

    if (ret.status === 200) {
      if (ret.data.code === 1000) {
        return {
          success: true,
          data: ret.data.data,
        };
      } else {
        return {
          success: false,
          message: ret.data.message,
          code: ret.data.code,
        };
      }
    } else {
      return {
        success: false,
        message: "Load failed!",
        code: ret.status,
      };
    }
  } catch (e) {
    return {
      success: false,
      message: e?.message || "Load failed!",
      code: e?.code || 0,
    };
  }
}

export async function dataPost(subUrl, token, po, fr) {
  try {
    const ret = await axios.post(rootUrl + subUrl, po, {
      headers: {
        Authorization: token,
        cart: fr,
      },
    });

    if (ret.status === 200) {
      if (ret.data.code === 1000) {
        return {
          success: true,
          data: ret.data.data,
          message: ret.data.message,
        };
      } else {
        return {
          success: false,
          message: ret.data.message,
          code: ret.data.code,
        };
      }
    } else {
      return {
        success: false,
        message: "Load failed!",
        code: ret.status,
      };
    }
  } catch (e) {
    return {
      success: false,
      message: e?.message || "Load failed!",
      code: e?.code || 0,
    };
  }
}

export async function dataPatch(subUrl, token, po, fr) {
  try {
    const ret = await axios.patch(rootUrl + subUrl, po, {
      headers: {
        Authorization: token,
        cart: fr,
      },
    });

    if (ret.status === 200) {
      if (ret.data.code === 1000) {
        console.log(ret);

        return {
          success: true,
          data: ret.data.data,
          message: ret.data.message,
        };
      } else {
        return {
          success: false,
          message: ret.data.message,
          code: ret.data.code,
        };
      }
    } else {
      return {
        success: false,
        message: "Load failed!",
        code: ret.status,
      };
    }
  } catch (e) {
    return {
      success: false,
      message: e?.message || "Load failed!",
      code: e?.code || 0,
    };
  }
}

export async function dataDelete(subUrl, token, fr) {
  try {
    const ret = await axios.delete(rootUrl + subUrl, {
      headers: {
        Authorization: token,
        cart: fr,
      },
    });

    if (ret.status === 200) {
      if (ret.data.code === 1000) {
        return {
          success: true,
          data: ret.data.data,
          message: ret.data.message,
        };
      } else {
        return {
          success: false,
          message: ret.data.message,
          code: ret.data.code,
        };
      }
    } else {
      return {
        success: false,
        message: "Load failed!",
        code: ret.status,
      };
    }
  } catch (e) {
    return {
      success: false,
      message: e?.message || "Load failed!",
      code: e?.code || 0,
    };
  }
}
