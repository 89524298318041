import { useState } from "react";
import { useLocation } from "react-router-dom";

import { Container, Grid, Tab } from "@mui/material";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Page from "src/components/Page";
import EmployeeInfoCard from "./EmployeeProfileSection";
import AllDocumentSection from "./doc/AllDocumentSection";
import EmployeeTxnsPaginated from "./EmployeeTxnsPaginated";
import BankAccountSection from "./BankAccountSection";
import EmployeeCartSection from "./EmployeeCartSection";

import EmployeeTimeline from "./EmployeeTimeline";

import EmployeeDailySummaryCard from "./EmployeeDailySummaryCard";
import EmployeeMonthlySummaryCard from "./EmployeeMonthlySummaryCard";
import { EmployeeAttnSection } from "./EmployeeAttnSection";
import EmployeeSalaryStructureCard from "./EmployeeSalaryStructureCard";
import { EmployeeChangelog } from "./EmployeeChangelog";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EmployeeDetailsPage({ incEmployee }) {
  const location = useLocation();

  let employee = location.state?.employee;
  if (!employee) {
    employee = incEmployee;
  }

  const [currentMenu, setCurrentMenu] = useState("general");

  const handleTabChange = (event, newValue) => {
    setCurrentMenu(newValue);
  };

  return (
    <Page title="Employee Dashboard" sx={{ minHeight: "800px" }}>
      <Container>
        <TabContext value={currentMenu}>
          <TabList onChange={handleTabChange} variant="scrollable">
            <Tab label="Monthly Summary" value="msummary" {...a11yProps(0)} />
            <Tab label="Daily Summary" value="dsummary" {...a11yProps(1)} />
            <Tab label="Profile" value="general" {...a11yProps(2)} />
            <Tab label="Salary" value="salary" {...a11yProps(3)} />
            <Tab label="Docs" value="docs" {...a11yProps(4)} />
            <Tab label="Account" value="account" {...a11yProps(5)} />
            <Tab label="Txns" value="txns" {...a11yProps(6)} />
            <Tab label="Dispense" value="hisaab" {...a11yProps(7)} />
            <Tab label="Timeline" value="timeline" {...a11yProps(8)} />
            <Tab label="Attn" value="attendance" {...a11yProps(9)} />
            <Tab label="Change log" value="changelog" {...a11yProps(10)} />
          </TabList>

          <TabPanel value="general">
            <EmployeeInfoCard employee={employee} />
          </TabPanel>
          <TabPanel value="docs">
            <AllDocumentSection employee={employee} />
          </TabPanel>
          <TabPanel value="account">
            <BankAccountSection employee={employee} />
          </TabPanel>
          <TabPanel value="salary">
            <EmployeeSalaryStructureCard employee={employee} />
          </TabPanel>
          <TabPanel value="dsummary">
            <EmployeeDailySummaryCard employee={employee} />
          </TabPanel>
          <TabPanel value="msummary">
            <EmployeeMonthlySummaryCard employee={employee} />
          </TabPanel>
          <TabPanel value="txns">
            <EmployeeTxnsPaginated employee={employee} />
          </TabPanel>
          <TabPanel value="hisaab">
            <EmployeeCartSection employee={employee.employee} />
          </TabPanel>
          <TabPanel value="timeline">
            <EmployeeTimeline employee={employee.employee} />
          </TabPanel>
          <TabPanel value="attendance">
            <EmployeeAttnSection employee={employee.employee} />
          </TabPanel>
          <TabPanel value="changelog">
            <EmployeeChangelog employee={employee.employee} />
          </TabPanel>
        </TabContext>
        <Grid container spacing={3}>
          <Grid item xs={3} md={3} lg={3}></Grid>
        </Grid>
      </Container>
    </Page>
  );
}
