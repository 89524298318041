import React, { useContext, useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
const { io } = require("socket.io-client");

const SupportSocketContext = React.createContext();
export function useSupportSocket() {
  return useContext(SupportSocketContext);
}

const { rootUrl } = require("../utils/data-fetch-support");

export function SupportSocketProvider({ children }) {
  const [socket, setSocket] = useState(null);

  const [agent, setAgent] = useState(null);

  const [conversations, setConversations] = useState({});

  //newmsg realtime data, {conv_id: id, data: {convData}, msg: {msgData}}
  const [newMsg, setNewMsg] = useState({});

  //{convId: {id: msg1, id2: msg2, id3: msg3}}
  const [messages, setMessages] = useState({});

  const { getAccessToken, activeFranchise } = useAuth();

  async function getSocket() {
    if (socket != null) {
      socket.close();
    }
    const newSocket = io.connect(rootUrl + "/supportsocket", {
      query: {
        token: await getAccessToken(),
      },
    });
    setSocket(newSocket);
    return () => newSocket.close();
  }

  useEffect(() => {
    getSocket();
  }, [activeFranchise]);

  if (socket != null) {
    socket.on("connect", () => {
      console.log(socket.id, "connected");
    });

    socket.on("conversations", (data) => {
      //initial load all conversations, or on refresh conversations
      //Replace all conversations
      //data format {convId: {convData}, convId2: {conv2Data}}

      setConversations(data);
      console.log("conversations", data);
    });

    socket.on("conversation", (data) => {
      //on new conversation assign
      //update conversation map or add this new conversation
      //data format {conv_id: id, data: {convData}}
      setConversations((prev) => {
        return { ...prev, [data.conv_id]: data.data };
      });
    });

    socket.on("messages", (data) => {
      //emit socket.getMessages for a conversation id (on conversation click), and this callback is returned
      //format: {conv_id: id, messages: {msgId1: {msgData}, msgId2: {msgData}}}
      let exst = messages[data.conv_id] || {};
      exst = { ...exst, ...data.messages };

      setMessages((prev) => ({ ...prev, [data.conv_id]: exst }));
    });

    socket.on("agent", (data) => {
      console.log("agent", agent)
      setAgent(data);
    });

    socket.on("disconnect", () => {
      console.log(socket.id, "disconnected");
    });
  }

  const fetchMessages = (convId) => {
    socket?.emit("get_messages", {
      c: convId,
    });
  };

  const sendTextMsg = (msg, convId) => {
    socket?.emit("new_message", {
      c: convId,
      msg: msg,
      e: "text",
    });
  };

  const sendReadReceipt = (msgId) => {
    socket?.emit("readreceipt", {
      m: msgId,
    });
  };

  const closeChat = (convId) => {
    socket?.emit("closechat", {
      c: convId,
    });
  };

  const value = {
    socket,
    conversations,
    newMsg,
    messages,
    fetchMessages,
    sendTextMsg,
    sendReadReceipt,
    closeChat,
    agent,
  };

  return (
    <SupportSocketContext.Provider value={value}>
      {children}
    </SupportSocketContext.Provider>
  );
}
