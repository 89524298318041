import {
  AddRounded,
  ControlPointDuplicateRounded,
  EditRounded,
} from "@mui/icons-material";

import {
  Avatar,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Modal,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import MakeFullScreen from "src/components/MakeFullScreen";

import { dataFetch, dataPost } from "src/utils/data-fetch";
import { useAuth } from "src/contexts/AuthContext";
import { useSnackbar } from "src/contexts/SnackbarContext";
import AddItem from "./AddItem";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import UploadItemImage from "./UploadItemImage";
import Label from "src/pages/crm/Label";
import Vendors, { SingleVendorItem } from "../vendor/Vendors";
import AddVendorToItem from "./AddVendorToItem";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ItemsDetails({ open, onClose, item }) {
  const [currentMenu, setCurrentMenu] = useState("profile");

  const handleTabChange = (event, newValue) => {
    setCurrentMenu(newValue);
  };

  const [currentData, setCurrentData] = useState(item);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/inventory/mgract/item/${item?._id}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setCurrentData(ret.data);
    } else {
      showSnackbar(ret.message, "error");
    }

    setLoadingData(false);
  };

  useEffect(() => {
    handleSearch();
  }, []);

  const [uploadImageModal, setUploadImageModal] = useState(false);
  const [addItemModalState, setAddItemModalState] = useState(false);

  const closeUploadImageModal = () => {
    setUploadImageModal(false);
    handleSearch();
  };

  return (
    <MakeFullScreen
      title={
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <Card sx={{ px: 2, py: 1, backgroundColor: "success.main" }}>
            {currentData?.name}
          </Card>
          <span>ITEM DETAILS</span>
        </Stack>
      }
      open={open}
      onClose={onClose}
      header={
        <Button
          variant="contained"
          color="white"
          onClick={() => setAddItemModalState(true)}
        >
          <ControlPointDuplicateRounded />
        </Button>
      }
    >
      <Container sx={{ minHeight: 600 }}>
        <TabContext value={currentMenu}>
          <TabList onChange={handleTabChange}>
            <Tab label="Details" value="profile" {...a11yProps(0)} />
            <Tab label="Logs" value="logs" {...a11yProps(2)} />
          </TabList>

          <TabPanel value="profile">
            <ItemDetailsSection
              currentData={currentData}
              onUpload={() => setUploadImageModal(true)}
              reload={handleSearch}
            />
          </TabPanel>
          <TabPanel value="towers">
            {/* <AptTowersSection apt={apartment} /> */}
          </TabPanel>
        </TabContext>

        {addItemModalState && (
          <AddItem
            open={addItemModalState}
            onClose={() => {
              setAddItemModalState(false);
              handleSearch();
            }}
            duplicate={{
              ...currentData,
              category: { name: currentData.category },
            }}
          />
        )}

        {uploadImageModal && (
          <Modal open={uploadImageModal} onClose={closeUploadImageModal}>
            <UploadItemImage
              handleM1Close={closeUploadImageModal}
              aptId={currentData._id}
            />
          </Modal>
        )}
      </Container>
    </MakeFullScreen>
  );
}

const ItemDetailsSection = ({ currentData, onUpload, reload }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} lg={4}>
        <Stack direction="column" spacing={2}>
          <Card sx={{ p: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 2 }}
            >
              <Typography variant="h6">Stock</Typography>
              <Typography variant="h6">
                {currentData?.stock} {currentData?.unit}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Status</Typography>

              <Label
                variant="outlined"
                color={
                  currentData?.status === "Out of Stock"
                    ? "error"
                    : currentData?.status === "Low Stock"
                    ? "warning"
                    : "success"
                }
              >
                {currentData?.status || "Unknown"}
              </Label>
            </Stack>
          </Card>

          <Card>
            <Stack direction="column" alignItems="center" spacing={1}>
              <div>
                <img
                  src={currentData?.image || "/static/icon2.png"}
                  alt="Item Image"
                  style={{ maxHeight: "400px", objectFit: "cover" }}
                />
              </div>

              <IconButton
                onClick={onUpload}
                sx={{
                  color: "primary.main",
                  position: "absolute",
                  bottom: "5px",
                  right: "5px",
                }}
              >
                <Avatar sx={{ backgroundColor: "primary.main" }}>
                  <EditRounded />
                </Avatar>
              </IconButton>
            </Stack>
          </Card>

          <Card sx={{ p: 2 }}>
            <Stack direction="column" alignItems="start" spacing={1}>
              <b>Details</b>
              <Typography variant="body1">
                {currentData?.details || "-"}
              </Typography>
            </Stack>
          </Card>
        </Stack>
      </Grid>

      <Grid item xs={12} md={8} lg={8}>
        <Stack direction="column" spacing={2}>
          {currentData && <ItemDetailsCard doc={currentData} />}
          {currentData && (
            <VendorDetailsCard doc={currentData} reload={reload} />
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

const ItemDetailsCard = ({ doc, reload }) => {
  return (
    <Card sx={{ px: 3, py: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" gutterBottom>
          Details
        </Typography>

        <Button
          variant="outlined"
          color="error"
          startIcon={<EditRounded />}
          // onClick={openModalFn}
        >
          Edit
        </Button>
      </Stack>

      {doc && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={6}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Category:</b>
                  </TableCell>
                  <TableCell>{doc?.category}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Department:</b>
                  </TableCell>
                  <TableCell>{doc?.department?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Unit Price:</b>
                  </TableCell>
                  <TableCell>{doc.unit_price}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Units:</b>
                  </TableCell>
                  <TableCell>{doc.unit}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Perishable:</b>
                  </TableCell>
                  <TableCell>
                    {doc?.perishable === true ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Min Stock:</b>
                  </TableCell>
                  <TableCell>{doc?.min_stock}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Location:</b>
                  </TableCell>
                  <TableCell>{doc?.location}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>{doc?.comments || "-"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};

const VendorDetailsCard = ({ doc, reload }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modal2Open, setModal2Open] = useState(null);

  const addVendorToItem = async (vendor) => {
    setModalOpen(false);
    setModal2Open(vendor);
  };

  return (
    <>
      <Card sx={{ px: 3, py: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5" gutterBottom>
            Vendors
          </Typography>

          <Button
            variant="outlined"
            color="error"
            startIcon={<AddRounded />}
            sx={{ mr: 1 }}
            onClick={() => setModalOpen(true)}
          >
            Add
          </Button>
        </Stack>
        <Stack sx={{ mt: 1 }}>
          <TableContainer>
            <Table>
              <TableBody>
                {doc?.vendors?.map((item) => {
                  return <SingleVendor item={item} onClick={() => {}} />;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Card>

      {modalOpen && (
        <Vendors
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onSelected={addVendorToItem}
        />
      )}

      {modal2Open && (
        <AddVendorToItem
          open={true}
          onClose={() => {
            setModal2Open(null);
            reload();
          }}
          item={doc}
          vendor={modal2Open}
        />
      )}
    </>
  );
};

const SingleVendor = ({ item, onClick }) => {
  return (
    <Tooltip title={item?.comments}>
      <TableRow
        sx={{
          cursor: "pointer",
          backgroundColor: item?.vid?.active === false ? "#ddd" : "#fff",
        }}
        hover
        onClick={onClick}
      >
        <TableCell style={{ width: "1px", whiteSpace: "nowrap" }}>
          <Card
            sx={{
              minWidth: "100px",
              maxWidth: "100px",
              height: "80px",
              boxShadow: 2,
            }}
          >
            <img
              alt="Vendor Image"
              src={item?.vid?.image || "/static/icon2.png"}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Card>
        </TableCell>
        <TableCell>
          <Typography variant="h6">{item?.vid?.name}</Typography>
          {item?.vid?.code}
        </TableCell>
        <TableCell>
          Rate
          <br />
          <Typography variant="h6">₹ {item?.rate}</Typography>
        </TableCell>
        <TableCell>
          Lead Time
          <br />
          <Typography variant="h6">
            {item?.lead_time} {item?.lead_time <= 1 ? "day" : "days"}
          </Typography>
        </TableCell>
      </TableRow>
    </Tooltip>
  );
};
