import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch, dataPost } from "src/utils/data-fetch";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { EditRounded } from "@mui/icons-material";

import {
  Card,
  Typography,
  Grid,
  Stack,
  CircularProgress,
  Avatar,
  Modal,
  Switch,
  IconButton,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Box,
  Table,
} from "@mui/material";

import ToffCodeModal from "./ToffCodeModal";
import UploadAgreementPdf from "./doc/UploadAgreementPdf";
import EditOtherDetailModal from "./EditOtherDetails";
import EditEmployeeOfficialDetailModal from "./EditEmployeeOfficialModal";
import EditEmployeePersonalDetailModal from "./EditEmployeeModal";

import { getDateStringFullWithMonthSync } from "src/utils/date-time-helper";

export default function EmployeeProfileSection({ employee }) {
  const [currentData, setCurrentData] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [loadingData2, setLoadingData2] = useState(false);

  const [status, setStatus] = useState(employee?.status || "-");

  const { getAccessToken, activeFranchise } = useAuth();

  const loadData = async () => {
    setLoadingData2(true);
    let url = `/api/employees/mgract/v2/employee/${employee.employee}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCurrentData(ret.data);
      setStatus(ret.data.employee_det.status);
    }
    setLoadingData2(false);
  };

  useEffect(() => {
    setLoadingData2(true);
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateStatusInServer = async (status) => {
    setLoadingData(true);

    let url = `/api/employees/mgract/v2/employee/${employee.employee}/status`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      { status: status },
      activeFranchise
    );
    if (ret.success === true) {
      setStatus(status);
      console.log(ret);
    } else {
      alert(ret.message);
    }
    setLoadingData(false);
  };

  const handleStatusChange = (e) => {
    updateStatusInServer(e.target.value);
  };

  const [loadingData3, setLoadingData3] = useState(false);
  const [docStatus, setDocStatus] = useState(employee?.doc_status || false);

  const updateDocStatus = async (status) => {
    setLoadingData3(true);

    let url = `/api/employees/mgract/v2/employee/${employee.employee}/documents`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      { status: status },
      activeFranchise
    );
    if (ret.success === true) {
      setDocStatus(status);
    } else {
      alert(ret.message);
    }
    setLoadingData3(false);
  };

  const [toffCodeModal, setToffCodeModal] = useState(false);
  const closeToffModalFn = () => {
    setToffCodeModal(false);
    loadData();
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Card sx={{ px: 2, py: 2 }}>
                <Stack direction="column" alignItems="center" pt={5}>
                  <Avatar
                    sx={{
                      width: "200px",
                      height: "200px",
                    }}
                  >
                    <img
                      src={currentData?.employee_det?.image || employee?.image}
                      alt="Employee"
                    />
                  </Avatar>

                  <Typography variant="h6" sx={{ mt: 1 }}>
                    {currentData?.employee_det?.name || employee.name}
                  </Typography>

                  <Typography variant="h7" sx={{ mb: 3, mt: 1 }}>
                    {currentData?.employee_det?.payroll_type?.toUpperCase() ||
                      "-"}
                  </Typography>

                  <Select
                    id="status-select"
                    value={status}
                    onChange={handleStatusChange}
                    placeholder="-"
                    size="small"
                  >
                    <MenuItem value={"-"}>-</MenuItem>
                    <MenuItem value={"training"}>Training</MenuItem>
                    <MenuItem value={"active"}>Active</MenuItem>
                    <MenuItem value={"inactive"}>Inactive</MenuItem>
                    <MenuItem value={"pending"}>Pending</MenuItem>
                    <MenuItem value={"onleave"}>On Leave</MenuItem>
                  </Select>

                  {loadingData === true && <CircularProgress />}
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Card sx={{ p: 2 }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <Typography variant="h6">Doc enabled</Typography>
                  {loadingData3 === true && <CircularProgress />}

                  <Switch
                    label="Doc Enabled"
                    checked={!docStatus}
                    disabled={loadingData3 === true || loadingData2 === true}
                    onChange={(event) => updateDocStatus(!event.target.checked)}
                  />
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Card sx={{ p: 2 }}>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="h6">Toff Code</Typography>

                  <Stack direction="row" alignItems={"center"} spacing={2}>
                    <Typography variant="subtitle1">
                      {currentData?.employee_det?.toff_code || "-"}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        setToffCodeModal(true);
                      }}
                    >
                      <EditRounded />
                    </IconButton>
                  </Stack>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <EmployeeDetailsCard
            loading={loadingData2}
            doc={currentData}
            reload={loadData}
          />
        </Grid>
      </Grid>

      <Modal open={toffCodeModal} onClose={closeToffModalFn}>
        <ToffCodeModal
          handleM1Close={closeToffModalFn}
          employee={currentData}
        />
      </Modal>
    </>
  );
}

const EmployeeDetailsCard = ({ loading, doc, reload }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [officialDetailModal, setOfficialDetailModal] = useState(false);
  const [otherDetailModal, setOtherDetailModal] = useState(false);

  const closeOtherDetailsModalFn = () => {
    setOtherDetailModal(false);
    reload();
  };

  const openOtherDetailsModalFn = async () => {
    setOtherDetailModal(true);
  };

  const closeOfficialDetailModalFn = () => {
    setOfficialDetailModal(false);
    reload();
  };

  const openOfficialDetailModalFn = async () => {
    setOfficialDetailModal(true);
  };

  const closeModalFn = () => {
    setModalOpen(false);
    reload();
  };

  const openModalFn = async () => {
    setModalOpen(true);
  };

  const [uploadAgreementModal, setUploadAgreementModal] = useState(false);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <Card sx={{ px: 2, pt: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 1 }}
            >
              <Typography variant="h5" gutterBottom>
                Official Details
              </Typography>

              {(loading === true && <CircularProgress />) || (
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<EditRounded />}
                  onClick={openOfficialDetailModalFn}
                >
                  Edit
                </Button>
              )}
            </Stack>

            {doc && (
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>UIN:</b>
                  </TableCell>
                  <TableCell>{doc?.employee_det?.uin}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Designation:</b>
                  </TableCell>
                  <TableCell>{doc.employee_det?.designation || "--"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Manager:</b>
                  </TableCell>
                  <TableCell>
                    {doc.employee_det?.reporting_manager?.name || "--"}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>Department:</b>
                  </TableCell>
                  <TableCell>
                    {doc?.employee_det?.department?.name || "--"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Roles:</b>
                  </TableCell>
                  <TableCell>
                    {JSON.stringify(doc?.employee_det?.roles)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Agreement:</b>
                  </TableCell>
                  <TableCell>
                    {(doc?.employee_det?.signed_agreement && (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() =>
                          window.open(
                            doc.employee_det.signed_agreement,
                            "_blank"
                          )
                        }
                      >
                        Download
                      </Button>
                    )) || (
                      <Button
                        color="error"
                        variant="contained"
                        onClick={() => setUploadAgreementModal(true)}
                      >
                        Upload
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            )}

            <Stack direction="row">
              <Box sx={{ flexGrow: 1 }} />
            </Stack>
          </Card>

          <br />

          <Card sx={{ px: 2, pt: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 1 }}
            >
              <Typography variant="h5" gutterBottom>
                Other Details
              </Typography>

              {(loading && <CircularProgress />) || (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<EditRounded />}
                  onClick={openOtherDetailsModalFn}
                  disabled={loading}
                >
                  Edit
                </Button>
              )}
            </Stack>

            {/* Personal Details Table */}
            {doc && (
              <Stack direction="column" spacing={1}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <b>Tshirt:</b>
                      </TableCell>
                      <TableCell>{doc?.employee_det?.tshirt_size}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Blood Grp:</b>
                      </TableCell>
                      <TableCell>{doc?.employee_det?.blood_group}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Joining:</b>
                      </TableCell>
                      <TableCell>
                        {(doc?.employee_det?.join_date &&
                          getDateStringFullWithMonthSync(
                            doc.employee_det.join_date
                          )) ||
                          "--"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Leaves:</b>
                      </TableCell>
                      <TableCell>
                        {doc?.employee_det?.leaves_pm} per month
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Stack>
            )}
          </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <Card sx={{ px: 2, pt: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 1 }}
            >
              <Typography variant="h5" gutterBottom>
                Personal Details
              </Typography>

              {(loading && <CircularProgress />) || (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<EditRounded />}
                  onClick={openModalFn}
                  disabled={loading}
                >
                  Edit
                </Button>
              )}
            </Stack>

            {/* Personal Details Table */}
            {doc && (
              <Stack direction="column" spacing={1}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <b>Phone:</b>
                      </TableCell>
                      <TableCell>{doc?.phone || "--"}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Alt Ph:</b>
                      </TableCell>
                      <TableCell>
                        {doc?.employee_det?.alt_phone || "--"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Address:</b>
                      </TableCell>
                      <TableCell>
                        {doc?.employee_det?.address || "--"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Personal Email:</b>
                      </TableCell>
                      <TableCell>
                        {doc?.employee_det?.personal_email || "--"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Email:</b>
                      </TableCell>
                      <TableCell>{doc?.employee_det?.email || "--"}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>DOB:</b>
                      </TableCell>
                      <TableCell>
                        {(doc?.employee_det?.dob &&
                          getDateStringFullWithMonthSync(
                            doc?.employee_det?.dob
                          )) ||
                          "--"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Stack>
            )}
          </Card>
        </Grid>
      </Grid>

      {modalOpen && (
        <Modal open={modalOpen} onClose={closeModalFn}>
          <EditEmployeePersonalDetailModal
            handleM1Close={closeModalFn}
            employee={doc}
          />
        </Modal>
      )}

      {officialDetailModal && (
        <Modal open={officialDetailModal} onClose={closeOfficialDetailModalFn}>
          <EditEmployeeOfficialDetailModal
            handleM1Close={closeOfficialDetailModalFn}
            employee={doc}
          />
        </Modal>
      )}
      {otherDetailModal && (
        <Modal open={otherDetailModal} onClose={closeOtherDetailsModalFn}>
          <EditOtherDetailModal
            handleM1Close={closeOtherDetailsModalFn}
            employee={doc}
          />
        </Modal>
      )}

      {uploadAgreementModal && (
        <Modal
          open={uploadAgreementModal}
          onClose={() => setUploadAgreementModal(false)}
        >
          <UploadAgreementPdf
            handleM1Close={() => {
              setUploadAgreementModal(false);
              reload();
            }}
            empId={doc?._id}
          />
        </Modal>
      )}
    </>
  );
};
