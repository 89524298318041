import {
  Container,
  Typography,
  Stack,
  Box,
  InputAdornment,
  OutlinedInput,
  CircularProgress,
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  TablePagination,
  IconButton,
  Tooltip,
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  Button,
  Modal,
} from "@mui/material";

import { forwardRef, useEffect, useState } from "react";
import {
  AddCircleRounded,
  CloseRounded,
  RefreshRounded,
  SearchRounded,
} from "@mui/icons-material";

import { styled } from "@mui/material/styles";
import { dataFetch } from "src/utils/data-fetch";
import { useAuth } from "src/contexts/AuthContext";
import { useSnackbar } from "src/contexts/SnackbarContext";
import AddSalaryComponent from "./AddSalaryCompModal";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "category", label: "Category", alignRight: false },
  { id: "value", label: "Value", alignRight: true },
];

export default function SalaryComponentsPage({ handleClose, drawerOpen }) {
  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [loadingData, setLoadingData] = useState(false);
  const [allData, setAllData] = useState([]);

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);

  let timer = "";

  const handleChangeQuery = (event) => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      setAllData([]);
      setPage(0);
      setSearch(event.target.value);
    }, 1000);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchData = async () => {
    setLoadingData(true);

    let url = `/api/salary/mgract/query?sort=_id&dir=d&skip=${
      page * 10
    }&limit=10`;

    if (search && search.length > 0) {
      url += `&search=${search}`;
    }

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setAllData(ret.data);
      console.log("data", ret.data);
    } else {
      showSnackbar(ret.message || "Failed to fetch data", "error");
    }

    setLoadingData(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, search]);

  const [addSalaryModalState, setAddSalaryModalState] = useState(false);
  const [selectedDept, setSelectedDept] = useState(null);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      scroll="body"
      open={drawerOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            Salary Components
          </Typography>

          <Stack direction={"row"} spacing={1} edge="end">
            <Button
              variant="contained"
              color="white"
              startIcon={<AddCircleRounded />}
              onClick={() => setAddSalaryModalState(true)}
            >
              Add Salary Comp
            </Button>

            <Button variant="contained" color="error" onClick={handleClose}>
              <CloseRounded />
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>

      <Container sx={{ minHeight: "500px" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          spacing={2}
        >
          <SearchStyle
            placeholder="Search Salary Components..."
            onChange={handleChangeQuery}
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>
                  <SearchRounded />
                </Box>
              </InputAdornment>
            }
          />

          {(loadingData === true && <CircularProgress color="secondary" />) || (
            <IconButton onClick={fetchData}>
              <RefreshRounded />
            </IconButton>
          )}
        </Stack>

        <TableContainer sx={{ minHeight: 200, boxShadow: 3, borderRadius: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.alignRight ? "right" : "left"}
                    sx={{
                      backgroundColor: "primary.light",
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    <Typography variant="body1" color="whitesmoke">
                      {headCell.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {allData.map((item) => (
                <Tooltip key={item._id} title={item?.description || ""} arrow>
                  <TableRow
                    hover
                    onClick={() => setSelectedDept(item)}
                    sx={{
                      cursor: "pointer",
                      backgroundColor:
                        item.active === false ? "grey.200" : "white",
                      "&:hover": {
                        backgroundColor: "grey.100",
                      },
                      transition: "background-color 0.3s ease",
                    }}
                  >
                    <TableCell sx={{ fontWeight: "bold" }}>
                      {item?.name || "--"}
                    </TableCell>
                    <TableCell>{item?.category || "--"}</TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        maxWidth: 200,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item?.value || "--"}
                    </TableCell>
                  </TableRow>
                </Tooltip>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={-1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
          page={page}
          onPageChange={handleChangePage}
        />
      </Container>

      {addSalaryModalState && (
        <Modal
          open={addSalaryModalState}
          onClose={() => {
            setAddSalaryModalState(false);
            fetchData();
          }}
        >
          <AddSalaryComponent
            handleM1Close={() => {
              setAddSalaryModalState(false);
              fetchData();
            }}
          />
        </Modal>
      )}

      {/* {selectedDept && (
        <DeptmentDetailsPage
          dept={selectedDept}
          handleClose={() => setSelectedDept(null)}
          drawerOpen={selectedDept !== null}
        />
      )} */}
    </Dialog>
  );
}
