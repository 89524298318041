import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";

import { dataFetch } from "../../utils/data-fetch";

import {
  Card,
  Container,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";

import {
  getDateStringFullWithMonthSync,
} from "src/utils/date-time-helper";

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "uin", label: "UIN", alignRight: false },
  { id: "dob", label: "DOB", alignRight: false },
  { id: "days", label: "Days", alignRight: false },
];

export default function BirthdaysModal({ onEmpSelected, handleClose }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/employees/mgract/upcomingbirthdays`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [activeFranchise]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card
      sx={{
        px: 1,
        py: 3,
        minWidth: "600px",
        height: "fit-content",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
      }}
    >
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 1 }}
        >
          <Typography variant="h4">Birthdays</Typography>
          {loadingData && <CircularProgress />}
        </Stack>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.alignRight ? "right" : "left"}
                    sortDirection={false}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {customerData.length > 0 && (
              <TableBody>
                {customerData.map((item) => {
                  return (
                    <TableRow onClick={() => onEmpSelected(item)}>
                      <TableCell>
                        <b>{item?.name || "-"}</b>
                      </TableCell>
                      <TableCell>{item?.uin || "-"}</TableCell>
                      <TableCell>
                        {getDateStringFullWithMonthSync(item.dob)}
                      </TableCell>
                      <TableCell>
                        {item.days === 0 ? "TODAY" : `${item.days} days`}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {customerData.length === 0 && loadingData === false && (
          <Typography mt={3} textAlign={"center"}>
            No upcoming birthdays
          </Typography>
        )}
      </Container>
    </Card>
  );
}
