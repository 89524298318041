import {
  Grid,
  Container,
  Typography,
  Stack,
  Button,
  Modal,
  Box,
} from "@mui/material";

import Page from "src/components/Page";

import { useNavigate } from "react-router-dom";
import { useState } from "react";

import AdminManagerSearch from "./AdminManagerSearch";
import AddAdminManager from "./AddAdminManager";

export default function AdminManagers() {
  const navigate = useNavigate();

  const [addModal, setAddModal] = useState(false);

  const closeAddModal = () => {
    setAddModal(false);
  };

  return (
    <Page title="Managers Dashboard">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography variant="h4">Managers Dashboard</Typography>

          <Button variant="contained" onClick={() => setAddModal(true)}>
            Add Manager
          </Button>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <AdminManagerSearch />
          </Grid>
        </Grid>
      </Container>
      <Modal open={addModal} onClose={closeAddModal}>
        <AddAdminManager handleM1Close={closeAddModal} />
      </Modal>
    </Page>
  );
}
