import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Box,
  OutlinedInput,
  InputAdornment,
  Drawer,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";

import { MobileDateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import AccSearch from "./AccSearch";

export default function AddTaskModal({ handleM1Close }) {
  const [error, setError] = useState();

  const { getAccessToken, activeFranchise } = useAuth();

  const [selectedFrom, setSelectedFrom] = useState({});

  const [selectedDate, setSelectedDate] = useState(Date.now());

  const saveNow = async (values) => {
    const ret = await dataPost(
      `/api/mgrtasks/mgract/add`,
      await getAccessToken(),
      {
        name: values.name,
        details: values.details,
        account: selectedFrom._id,
      },
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    name: Yup.string().required("Heading is required"),
    details: Yup.string().optional(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      details: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await saveNow(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const [searchDrawerState, setSearchDrawerState] = useState(false);

  const drawerSearchInit = () => {
    setSearchDrawerState(true);
  };

  const drawerReturnSearch = (acc) => {
    setSelectedFrom(acc);
    setSearchDrawerState(false);
  };

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Add Task
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                type="text"
                label="Heading"
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />

              <TextField
                fullWidth
                type="text"
                label="Details"
                {...getFieldProps("details")}
                error={Boolean(touched.details && errors.details)}
                helperText={touched.details && errors.details}
              />

              <OutlinedInput
                fullWidth
                type="text"
                value={(selectedFrom && selectedFrom.name) || ""}
                onClick={() => drawerSearchInit("from")}
                startAdornment={
                  <InputAdornment position="start">
                    <Box sx={{ color: "text.disabled" }}>From</Box>
                  </InputAdornment>
                }
              />

              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={"en-in"}
              >
                <MobileDateTimePicker
                  value={moment(selectedDate)}
                  onAccept={(newValue) => {
                    if (newValue) {
                      setSelectedDate(newValue.valueOf());
                    }
                  }}
                  closeOnSelect={false}
                  format="DD-MM-YYYY hh:mm:ss A"
                />
              </LocalizationProvider>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              ADD
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>

      <Drawer
        anchor={"right"}
        open={searchDrawerState}
        onClose={() => setSearchDrawerState(false)}
        sx={{ zIndex: 1300, p: 3 }}
      >
        <AccSearch onAccSelected={drawerReturnSearch} />
      </Drawer>
    </div>
  );
}
