import {
  Container,
  Typography,
  Stack,
  Tab,
  IconButton,
} from "@mui/material";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Page from "src/components/Page";

import { useState } from "react";
import { MaintenanceReportSection } from "./MaintenanceReportSection";

import { MobileDatePicker } from "@mui/x-date-pickers";

import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from "@mui/icons-material";
import { TWENTY4HOURS } from "src/utils/date-time-helper";
import { CleanlinessReportSection } from "./CleanlinessReportSection";
import { MilkCartsReportSection } from "./MilkCartsReportSection";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Reports() {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentMenu, setCurrentMenu] = useState("cart");

  const [startDate, setStartDate] = useState(Date.now());

  const handleTabChange = (event, newValue) => {
    setCurrentMenu(newValue);
  };

  return (
    <Page title="Reports Dashboard">
      <Container>
        <TabContext value={currentMenu}>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="h4">Reports Dashboard</Typography>
            <TabList onChange={handleTabChange}>
              <Tab label="Cart" value="cart" {...a11yProps(0)} />
              <Tab label="Maintenance" value="mt" {...a11yProps(1)} />
              <Tab label="Cleanliness" value="cl" {...a11yProps(2)} />
            </TabList>

            <Stack direction={"row"} alignItems={"center"}>
              <IconButton
                sx={{ display: "inline-flex", mr: 1 }}
                onClick={() => {
                  setStartDate(startDate - TWENTY4HOURS);
                }}
              >
                <KeyboardArrowLeftRounded />
              </IconButton>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={"en-in"}
              >
                <MobileDatePicker
                  value={moment(startDate)}
                  onAccept={(newValue) => {
                    if (newValue) {
                      const d1 = newValue.valueOf();
                      if (startDate !== d1) setStartDate(d1);
                    }
                  }}
                  sx={{ maxWidth: "130px" }}
                  closeOnSelect
                  disableFuture
                  showDaysOutsideCurrentMonth
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
              <IconButton
                sx={{ display: "inline-flex", ml: 1 }}
                onClick={() => {
                  setStartDate(startDate + TWENTY4HOURS);
                }}
              >
                <KeyboardArrowRightRounded />
              </IconButton>
            </Stack>
          </Stack>
          <TabPanel value="cart">
            <MilkCartsReportSection currentDate={startDate} />
          </TabPanel>
          <TabPanel value="mt">
            <MaintenanceReportSection currentDate={startDate} />
          </TabPanel>
          <TabPanel value="cl">
            <CleanlinessReportSection currentDate={startDate} />
          </TabPanel>
        </TabContext>
      </Container>
    </Page>
  );
}
