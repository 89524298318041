import { Grid, Container, Typography } from "@mui/material";

import Page from "src/components/Page";
import PendingLeaveApprovals from "./PendingLeaveAprovals";
import ApprovalLeave from "./ApprovalLeave";
import EmployeeAttnCalendar from "./EmployeeAttnCalendar";

export default function EmployeeAttn() {
  return (
    <Page title="Attendance Dashboard">
      <Container>
        <Grid container mb={4} spacing={4}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="h4">Attendance Dashboard</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <PendingLeaveApprovals />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <ApprovalLeave />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <EmployeeAttnCalendar />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
