import { useEffect, useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  OutlinedInput,
  InputAdornment,
  Box,
  IconButton,
  MenuItem,
  Select,
  CircularProgress,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataFetch, dataPatch, dataPost } from "src/utils/data-fetch";
import {
  AddRounded,
  CancelRounded,
  PauseCircleRounded,
  PlayCircleRounded,
  RemoveRounded,
} from "@mui/icons-material";
import { DateSelector } from "src/utils/date-selector";

import { getDateStringFullWithTimeSync } from "src/utils/date-time-helper";

import { useSnackbar } from "src/contexts/SnackbarContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ModifySubModal({ handleM1Close, subId }) {
  const [error, setError] = useState();

  const [loadingInitial, setLoadingInitial] = useState(true);
  const [currentSub, setCurrentSub] = useState(null);

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [selectedQty, setSelectedQty] = useState(1);
  const [weeklyDays, setWeeklyDays] = useState([]);
  const [slot, setSlot] = useState("1");
  const [message, setMessage] = useState("");
  const [activityLog, setActivityLog] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(Date.now());

  const initialLoad = async () => {
    setLoadingInitial(true);

    const ret = await dataFetch(
      `/api/products/mgract/subscriptions/sub/${subId}`,
      await getAccessToken(),
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);

      setActivityLog(ret.data.activity_log);
      console.log(activityLog);

      setCurrentSub(ret.data);
      setSelectedQty(ret.data.quantity);
      setSlot(ret.data.slot);
      setWeeklyDays(ret.data.weekly_days);
      setMessage(ret.data.message);
      setSelectedStartDate(ret.data.start_date);
    } else {
      showSnackbar(ret.message + " - " + ret.code, "error");
    }

    setLoadingInitial(false);
  };

  useEffect(() => {
    initialLoad();
  }, [subId]); // eslint-disable-line react-hooks/exhaustive-deps

  const [loadingData, setLoadingData] = useState(false);

  const approveNow = async (values) => {
    setError(null);

    if (currentSub.frequency === "weekly" && weeklyDays.length === 0) {
      setError("Please select atleast one day for weekly frequency");
      return;
    }

    if (selectedQty <= 0) {
      setError("Quantity should be greater than 0");
      return;
    }

    setLoadingData(true);

    let up = {
      quantity: selectedQty,
      slot: slot,
      weekly_days: weeklyDays,
      message: message,
    };

    if (selectedStartDate !== currentSub.start_date) {
      up.start_date = selectedStartDate;
    }

    const ret = await dataPost(
      `/api/products/mgract/subscriptions/sub/${subId}`,
      await getAccessToken(),
      up,
      activeFranchise
    );
    if (ret.success === true) {
      showSnackbar(ret.message || "Subscription modified", "success");
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }

    setLoadingData(false);
  };

  const pauseResumeSub = async (cancel = false) => {
    setLoadingInitial(true);

    let url = `/api/products/mgract/subscriptions/sub/${subId}`;

    if (cancel === true) {
      url += "?cancel=true";
    }

    const ret = await dataPatch(
      url,
      await getAccessToken(),
      {},
      activeFranchise
    );

    if (ret.success === true) {
      showSnackbar(ret.message || "Subscription modified", "success");
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }

    setLoadingInitial(false);
  };

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Modify Subscription
          </Typography>

          <Stack direction="row" spacing={2}>
            {["paused", "active"].includes(currentSub?.status) && (
              <Button
                variant="contained"
                color={"error"}
                startIcon={<CancelRounded />}
                onClick={() => pauseResumeSub(true)}
              >
                CANCEL
              </Button>
            )}
            {["paused", "active"].includes(currentSub?.status) && (
              <Button
                variant="contained"
                color={currentSub?.status === "paused" ? "success" : "warning"}
                startIcon={
                  currentSub?.status === "paused" ? (
                    <PlayCircleRounded />
                  ) : (
                    <PauseCircleRounded />
                  )
                }
                onClick={pauseResumeSub}
              >
                {currentSub?.status === "paused" ? "Resume" : "Pause"}
              </Button>
            )}
          </Stack>
        </Stack>

        {loadingInitial && <CircularProgress />}

        {loadingInitial === false && currentSub && (
          <Stack spacing={3}>
            <OutlinedInput
              fullWidth
              type="text"
              value={currentSub?.user?.name || ""}
              startAdornment={
                <InputAdornment position="start">
                  <Box sx={{ color: "text.disabled" }}>Customer</Box>
                </InputAdornment>
              }
            />

            <OutlinedInput
              fullWidth
              type="text"
              value={currentSub?.product?.name || ""}
              startAdornment={
                <InputAdornment position="start">
                  <Box sx={{ color: "text.disabled" }}>Product</Box>
                </InputAdornment>
              }
            />

            <Stack direction={"row"} spacing={3} alignItems={"center"}>
              {currentSub?.frequency === "weekly" && (
                <Select
                  id="weekly-days-select"
                  multiple
                  MenuProps={MenuProps}
                  value={weeklyDays}
                  onChange={(e) => setWeeklyDays(e.target.value)}
                >
                  <MenuItem value={"MON"}>MON</MenuItem>
                  <MenuItem value={"TUE"}>TUE</MenuItem>
                  <MenuItem value={"WED"}>WED</MenuItem>
                  <MenuItem value={"THU"}>THU</MenuItem>
                  <MenuItem value={"FRI"}>FRI</MenuItem>
                  <MenuItem value={"SAT"}>SAT</MenuItem>
                  <MenuItem value={"SUN"}>SUN</MenuItem>
                </Select>
              )}

              <Stack direction={"row"} spacing={3} alignItems={"center"}>
                <Box sx={{ color: "black.main" }}>Quantity:</Box>
                <IconButton
                  onClick={() => {
                    let qt = currentSub?.product?.quantum || 1;
                    if (selectedQty > qt) {
                      setSelectedQty(selectedQty - qt);
                    }
                  }}
                  color="black"
                >
                  <RemoveRounded />
                </IconButton>

                <Typography variant="h5">{selectedQty?.toFixed(1)}</Typography>

                <IconButton
                  onClick={() => {
                    let qt = currentSub?.product?.quantum || 1;
                    setSelectedQty(selectedQty + qt);
                  }}
                  color="black"
                >
                  <AddRounded />
                </IconButton>
              </Stack>
            </Stack>

            <Stack direction={"row"} spacing={3} alignItems={"center"}>
              <TextField
                select
                label="Slot"
                sx={{ minWidth: 150 }}
                value={slot}
                onChange={(e) => setSlot(e.target.value)}
              >
                <MenuItem value="1">5AM - 7AM</MenuItem>
                <MenuItem value="2">7AM - 9AM</MenuItem>
                <MenuItem value="3">9AM - 11AM</MenuItem>
                <MenuItem value="4">5PM - 7PM</MenuItem>
                <MenuItem value="5">7PM - 9PM</MenuItem>
              </TextField>

              <DateSelector
                value={selectedStartDate}
                onChange={setSelectedStartDate}
                title={"Change Start Date"}
                displayFormat={"DD MMM YYYY"}
              />
            </Stack>

            <TextField
              fullWidth
              type="text"
              label="Instructions"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loadingData}
              sx={{ mt: 3 }}
              onClick={approveNow}
              disabled={!["paused", "active"].includes(currentSub?.status)}
            >
              Save
            </LoadingButton>

            {activityLog?.length > 0 && (
              <ActivityLogTable activity={activityLog} />
            )}
          </Stack>
        )}

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}

const ActivityLogTable = ({ activity }) => {
  return (
    <Container>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Time Stamp</b>
            </TableCell>
            <TableCell align="right">
              <b>Comment</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activity.map((item, index) => (
            <TableRow key={index}>
              <TableCell>
                <b>{getDateStringFullWithTimeSync(item.ts)}</b>
              </TableCell>
              <TableCell align="right">{item.comments}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};
