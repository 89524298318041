import { useState } from "react";
import { useLocation } from "react-router-dom";


import {
  Container,
  Typography,
  Box,
  Grid,
  Tab,
} from "@mui/material";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Page from "src/components/Page";
import FarmerProfileSection from "./FarmerProfileSection";
import { FarmerMilkHistory } from "./FarmerMilkHistory";
import FarmerTxnsPaginated from "./FarmerTxnsPaginated";
import FarmerAccountSection from "./FarmerAccountSection";
import FarmerOrders from "./FarmerOrders";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function FarmerDetailsPage({}) {
  const location = useLocation();
  const farmer = location.state.farmer;

  const [currentMenu, setCurrentMenu] = useState("general");

  const handleTabChange = (event, newValue) => {
    setCurrentMenu(newValue);
  };

  return (
    <Page title="Farmer Dashboard">
      <Container>
        <Grid container mb={4} spacing={4}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="h4">{farmer.name}</Typography>
          </Grid>
        </Grid>

        <TabContext value={currentMenu}>
          <Box>
            <TabList onChange={handleTabChange}>
              <Tab label="Profile" value="general" {...a11yProps(0)} />
              <Tab label="Account" value="account" {...a11yProps(1)} />
              <Tab label="Milk History" value="summary" {...a11yProps(2)} />
              <Tab label="Txns" value="txns" {...a11yProps(3)} />
              <Tab label="Orders" value="orders" {...a11yProps(4)} />
            </TabList>
          </Box>
          <TabPanel value="general">
            <FarmerProfileSection farmer={farmer} />
          </TabPanel>

          <TabPanel value="account">
            {
              <Grid container spacing={3}>
                <Grid item xs={12} md={9} lg={9}>
                  <FarmerAccountSection farmer={farmer} />
                </Grid>

                {/*  <Grid item xs={6} md={3} lg={3}>
                <Stack direction="column" spacing={2}>
                  <EmployeeBalanceCard employee={farmer} />

                  <Card
                    sx={{
                      p: 2,
                      backgroundColor: "primary.main",
                      color: "white",
                    }}
                    onClick={openPayoutModal}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="h6">Payout</Typography>
                      <AddIcon />
                    </Stack>
                  </Card>
                </Stack>
              </Grid> */}
              </Grid>
            }
          </TabPanel>
          <TabPanel value="summary">
            {<FarmerMilkHistory farmer={farmer} />}
          </TabPanel>
          <TabPanel value="txns">
            {<FarmerTxnsPaginated farmer={farmer} />}
          </TabPanel>
          <TabPanel value="orders">{<FarmerOrders farmer={farmer} />}</TabPanel>
        </TabContext>

        <Grid container spacing={3}>
          <Grid item xs={3} md={3} lg={3}></Grid>
        </Grid>
      </Container>
    </Page>
  );
}
