import { LoadingButton } from "@mui/lab";
import {
  Container,
  Card,
  Typography,
  TextField,
  Stack,
  Grid,
  InputAdornment,
} from "@mui/material";
import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataPost } from "src/utils/data-fetch";

export default function AddNewPaymentModal({ party, handleM1Close }) {
  const { getAccessToken, activeFranchise } = useAuth();
  const [error, setError] = useState();
  const [amount, setAmount] = useState();
  const [comments, setComments] = useState();

  const postData = async () => {
    const ret = await dataPost(
      `/api/extrasales/mgract/txns/newpayment`,
      await getAccessToken(),
      {
        user: party._id,
        amount: amount,
        comments: comments,
      },
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      handleM1Close();
    } else {
      setError(ret.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Are you sure you want to add this payment?");
    postData();
  };

  return (
    <Container
      sx={{
        px: 4,
        py: 4,
        width: 600,
        height: "fit-content",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Card sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom sx={{ color: "red" }}>
          Add Only If Manager has received the Payment through cash/account
        </Typography>

        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Amount"
                variant="outlined"
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                margin="normal"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Comments"
                variant="outlined"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                margin="normal"
              />
            </Grid>

            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{ mt: 2 }}
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Container>
  );
}
