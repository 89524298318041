import { Alert, Snackbar } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";

const SnackbarContext = React.createContext();
export function useSnackbar() {
  return useContext(SnackbarContext);
}

export function SnackbarProvider({ children }) {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState("Message");
  const [color, setColor] = React.useState("white");
  const [duration, setDuration] = React.useState(5000);

  const [key, setKey] = useState(Date.now());

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const showSnackbar = (title, color, duration) => {
    setTitle(title);

    if (color) setColor(color);
    else setColor("white");

    if (duration) setDuration(duration);
    else setDuration(5000);

    setKey(Date.now());
    setOpen(true);
  };

  const hideSnackbars = () => {
    setOpen(false);
  };

  const value = {
    showSnackbar,
    hideSnackbars,
  };

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <Snackbar open={open} autoHideDuration={duration} onClose={handleClose} key={key}>
        <Alert
          onClose={handleClose}
          severity={color}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {title}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
}
