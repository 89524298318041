import {
  Card,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Grid,
  Stack,
  TableHead,
  Table,
  IconButton,
} from "@mui/material";

import { Edit as EditIcon, FileCopy } from "@mui/icons-material";

import { useEffect, useState } from "react";
import { getDateStringFullSync } from "src/utils/date-time-helper";

import { dataFetch } from "src/utils/data-fetch";
import { useAuth } from "src/contexts/AuthContext";
import EditBannerModal from "./EditBanner";
import { useSnackbar } from "src/contexts/SnackbarContext";

export default function BannerProfile({ banner }) {
  const [currentData, setCurrentData] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const loadData = async () => {
    setLoadingData(true);
    const response = await dataFetch(
      `/api/banners/mgract/banner/${banner._id}`,
      await getAccessToken(),
      activeFranchise
    );

    if (response.success === true) {
      setCurrentData(response.data);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const copyToClipboard = () => {
    const textField = document.createElement("textarea");
    textField.innerText =
      currentData?.image_url ||
      "https://milkvilla.in/images/milkvilla_text.svg";
    navigator.clipboard.writeText(textField.innerText);
    alert("Copied to clipboard");
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Stack direction={"column"} spacing={2}>
            <Card>
              <Stack direction="column" alignItems="center" spacing={2} pb={2}>
                <div>
                  <img
                    src={
                      (currentData && currentData?.image_url) ||
                      "https://milkvilla.in/images/milkvilla_text.svg"
                    }
                    alt="Banner"
                  />
                </div>

                <Typography variant="h6">
                  {currentData?.title || "-"}
                </Typography>
              </Stack>
              <IconButton
                sx={{ position: "absolute", bottom: 8, right: 8 }}
                onClick={copyToClipboard}
              >
                <FileCopy />
              </IconButton>
            </Card>
          </Stack>
        </Grid>

        <Grid item xs={12} md={8} lg={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              {banner && (
                <BannerDetailsCard
                  doc={currentData || banner}
                  reload={loadData}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const BannerDetailsCard = ({ doc, reload }) => {
  const [editBannerModalState, setEditBannerModalState] = useState(false);

  const { showSnackbar } = useSnackbar();

  return (
    <>
      <Card sx={{ px: 3, py: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5" gutterBottom>
            Details
          </Typography>

          <Button
            variant="outlined"
            color="error"
            startIcon={<EditIcon />}
            onClick={() => setEditBannerModalState(true)}
          >
            Edit
          </Button>
        </Stack>

        {doc && (
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={6}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>Body:</b>
                    </TableCell>
                    <TableCell>{doc?.description}</TableCell>
                  </TableRow>
                  <TableRow
                    onClick={() => {
                      navigator.clipboard.writeText(doc?.link);
                      showSnackbar("Link copied to clipboard", "success");
                    }}
                  >
                    <TableCell>
                      <b>Link:</b>
                    </TableCell>
                    <TableCell>{doc?.link?.substring(0, 30)}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <b>Action Text:</b>
                    </TableCell>
                    <TableCell>{doc?.link_text || "--"}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <b>Live:</b>
                    </TableCell>
                    <TableCell>
                      {getDateStringFullSync(doc?.live_time)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <EventTable actions={doc?.actions || []} />
            </Grid>
          </Grid>
        )}
      </Card>

      {editBannerModalState && (
        <EditBannerModal
          drawerOpen={editBannerModalState}
          handleM1Close={() => {
            setEditBannerModalState(false);
            reload();
          }}
          banner={doc}
        />
      )}
    </>
  );
};

const EventTable = ({ actions }) => {
  const uniqueViews = [];
  const eventCounts = actions.reduce((acc, action) => {
    const eventType = action.event;

    if (!uniqueViews.includes(action.user)) {
      uniqueViews.push(action.user);
    }

    if (!acc[eventType]) {
      acc[eventType] = 1;
    } else {
      acc[eventType]++;
    }
    return acc;
  }, {});

  const viewsByDate = actions.reverse().reduce((acc, view) => {
    if (view.event === "view") {
      const viewDate = getDateStringFullSync(view.ts);
      acc[viewDate] = (acc[viewDate] || 0) + 1;
    }
    return acc;
  }, {});

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <b>Action</b>
          </TableCell>
          <TableCell align="right">
            <b>Count</b>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(eventCounts).map(([eventType, count]) => (
          <TableRow key={eventType}>
            <TableCell>{eventType}</TableCell>
            <TableCell align="right">{count}</TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell>
            <b>Unique Views</b>
          </TableCell>
          <TableCell align="right">
            <b>{uniqueViews.length}</b>
          </TableCell>
        </TableRow>
      </TableBody>

      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell align={"right"}>Views</TableCell>
        </TableRow>
      </TableHead>

      {Object.keys(viewsByDate).map((date) => (
        <TableRow key={date}>
          <TableCell>{date}</TableCell>
          <TableCell align={"right"}>{viewsByDate[date]}</TableCell>
        </TableRow>
      ))}
    </Table>
  );
};
