import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";

import { dataFetch } from "../../utils/data-fetch";

import {
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
  RefreshRounded,
} from "@mui/icons-material";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Stack,
  IconButton,
  Modal,
} from "@mui/material";

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

import {
  TWENTY4HOURS,
  getDayStart,
  getTimeString,
  getTimeStringFullSync,
} from "src/utils/date-time-helper";
import SaleTxnDetails from "./SaleTxnDetails";
import BulkOrderDetails from "./BulkOrderDetails";
import { PartyDetailsModal } from "./PartyDetailsModal";
import { DateSelector } from "src/utils/date-selector";

const TABLE_HEAD = [
  { id: "time", label: "Time", alignRight: false },
  { id: "entity", label: "Entity", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "party", label: "Party", alignRight: false },
];

export default function TodayTxns({ onDataSelected }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [startDate, setStartDate] = useState(getDayStart(Date.now()));

  const [page, setPage] = useState(0);
  const [txnModal, setTxnModal] = useState(false);
  const [txns, setTxns] = useState(null);

  const handleTxnModal = (txn) => {
    setTxnModal(true);
    setTxns(txn);
  };

  const { getAccessToken, activeFranchise } = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/extrasales/mgract/txns/query?skip=${
      page * 5
    }&limit=${5}&date_gt=${startDate}&date_lt=${startDate + TWENTY4HOURS}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
      console.log("txns", ret.data);
    }

    setLoadingData(false);
  };

  const totalLength = page * 5 + customerData.length;

  const [currData, setCurrData] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handlePartySelected = (d) => {
    setCurrData(d);
    setDrawerOpen(true);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [page, activeFranchise, startDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          mt={2}
          spacing={2}
        >
          <Typography variant="h4">Txns</Typography>

          <DateSelector value={startDate} onChange={setStartDate} />
        </Stack>
        <div>
          <TableContainer sx={{ minHeight: 100 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.alignRight ? "right" : "left"}
                      sortDirection={false}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {customerData.map((item) => {
                  return (
                    <TableRow
                      hover
                      onClick={() => handleTxnModal(item)}
                      style={{
                        backgroundColor:
                          item.status === "inactive" ? "#ddd" : "white",
                      }}
                    >
                      <TableCell>
                        <b>{getTimeString(item?.date)}</b>
                      </TableCell>
                      <TableCell>
                        {item?.entity === "salebill"
                          ? "Sale"
                          : "Payment" || "-"}
                      </TableCell>
                      <TableCell>
                        {item?.amount ? `${item.amount} ₹` : "-"}
                      </TableCell>

                      <TableCell
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePartySelected(item.to);
                        }}
                      >
                        {item?.to?.name || item?.from?.name || "-"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={totalLength < (page + 1) * 5 ? totalLength : -1}
            rowsPerPage={5}
            rowsPerPageOptions={[5]}
            page={page}
            onPageChange={handleChangePage}
          />
        </div>
      </Container>

      <Modal open={txnModal} onClose={() => setTxnModal(false)}>
        <SaleTxnDetails txn={txns} onClose={() => setTxnModal(false)} />
      </Modal>

      <PartyDetailsModal
        party={currData}
        handleClose={() => setDrawerOpen(false)}
        drawerOpen={drawerOpen}
      />
    </Card>
  );
}
