import { Box, Button, Modal, TextField, Typography, Card, Stack } from "@mui/material";
import { useState } from "react";

export default function OtopValidationModal({ open, handleClose, handleOtpSubmit }) {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (otp.trim() === "") {
      setError("OTP is required");
      return;
    }

    try {
      setError("");
      setLoading(true);
      await handleOtpSubmit(otp);
    } catch (err) {
      setError("Invalid OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="otp-verification-title"
      aria-describedby="otp-verification-description"
    >
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 400,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
        }}
      >
        <Typography id="otp-verification-title" variant="h6" component="h2" mb={2}>
          OTP Verification
        </Typography>
        
        <Typography variant="body2" color="text.secondary" mb={3}>
          Please enter the OTP sent to your phone number.
        </Typography>
        
        <Stack spacing={2}>
          <TextField
            label="Enter OTP"
            variant="outlined"
            fullWidth
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            error={Boolean(error)}
            helperText={error}
            inputProps={{ maxLength: 6 }} // Limiting the OTP length to 6 digits
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
            fullWidth
          >
            {loading ? "Submitting..." : "Submit"}
          </Button>
        </Stack>

        <Button
          onClick={handleClose}
          color="secondary"
          sx={{ mt: 2 }}
          fullWidth
        >
          Cancel
        </Button>
      </Card>
    </Modal>
  );
}
