import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Paper,
  Typography,
  Box,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Backspace, Phone } from "@mui/icons-material";
import { useAuth } from "src/contexts/AuthContext";
import { dataPost } from "src/utils/data-fetch";

const DialPad = ({ handleM1Close }) => {
  const [input, setInput] = useState("");
  const { getAccessToken, activeFranchise } = useAuth();
  const [loadingData, setLoadingData] = useState(false);

  const handleButtonClick = (value) => {
    setInput((prev) => prev + value);
  };

  const handleDelete = () => {
    setInput((prev) => prev.slice(0, -1));
  };

  const handleCall = async () => {
    setLoadingData(true);
    console.log("Call", input);

    let url = `/api/salesflags/mgract/manualcall`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      { phone: input },
      activeFranchise
    );

    if (ret.success === true) {
      handleM1Close();
    } else {
      alert(ret.message || "Failed to assign");
    }

    setLoadingData(false);
  };

  const handleKeyDown = (event) => {
    const { key } = event;
    if (/^[0-9*#]$/.test(key)) {
      handleButtonClick(key);
    } else if (key === "Backspace") {
      handleDelete();
    } else if (key === "Enter") {
      handleCall();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Paper
      elevation={3}
      sx={{
        px: 4,
        py: 4,
        minWidth: 500,
        height: "fit-content",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
      }}
    >
      <Typography variant="h5" align="center" gutterBottom>
        Dial Pad
      </Typography>
      <Typography variant="subtitle1" align="center" gutterBottom>
        {input || "Enter 10 digit number"}
      </Typography>

      {(loadingData === true && <CircularProgress />) || (
        <>
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: 200 }}>
              {["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"].map(
                (value) => (
                  <Grid item xs={4} key={value}>
                    <Button
                      variant="contained"
                      sx={{ width: "100%", height: 60 }}
                      onClick={() => handleButtonClick(value)}
                    >
                      {value}
                    </Button>
                  </Grid>
                )
              )}
            </Grid>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
            <IconButton color="primary" onClick={handleCall}>
              <Phone />
            </IconButton>
            <IconButton color="secondary" onClick={handleDelete}>
              <Backspace />
            </IconButton>
          </Box>
        </>
      )}
    </Paper>
  );
};

export default DialPad;
