import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Box,
  InputAdornment,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";

export default function AddGuardModal({ handleM1Close, apt }) {
  const [error, setError] = useState();

  const { getAccessToken, activeFranchise } = useAuth();

  const addGuardData = async (values) => {
    const ret = await dataPost(
      `/api/apartments/mgract/appt/${apt._id}/guards`,
      await getAccessToken(),
      {
        name: values.name,
        phone: values.phone,
        time: values.time,
      },
      activeFranchise
    );
    if (ret.success === true) {
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    name: Yup.string().required("Select name"),
    phone: Yup.string(),
    time: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      time: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await addGuardData(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Card
      sx={{
        px: 4,
        py: 4,
        minWidth: 800,
        height: "fit-content",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Typography variant="h5" gutterBottom>
          Add Tower
        </Typography>
      </Stack>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3} direction={"column"}>
            <TextField
              fullWidth
              {...getFieldProps("name")}
              helperText={touched.name && errors.name}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box sx={{ color: "text.disabled" }}>Name</Box>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              {...getFieldProps("phone")}
              helperText={touched.phone && errors.phone}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box sx={{ color: "text.disabled" }}>Phone</Box>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              {...getFieldProps("time")}
              helperText={touched.time && errors.time}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box sx={{ color: "text.disabled" }}>Timing</Box>
                  </InputAdornment>
                ),
              }}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>

      {error && (
        <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Card>
  );
}
