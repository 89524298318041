import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";
import {
  getDateStringFullWithTimeSync,
  getDayStartAndEndSync,
} from "src/utils/date-time-helper";
import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  Stack,
  Modal,
  Tooltip,
  IconButton,
  MenuItem,
  CircularProgress,
  TextField,
} from "@mui/material";

import CallDetails from "./CallDetails";
import CustomerDetailsPage from "../home/det/CustomerDetailsPage";
import { MessageRounded, RefreshRounded } from "@mui/icons-material";
import CommentModal from "../quick-access/CommentModal";
import { DateSelector } from "src/utils/date-selector";

const TABLE_HEAD = [
  { id: "datetime", label: "Date & Time", alignRight: false },
  { id: "name", label: "Customer", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "category", label: "Category", alignRight: false },
  { id: "comments", label: "Comment", alignRight: false },
];

export default function AgentCallHistorySection() {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [commentModalState, setCommentModalState] = useState(false);
  const [selectedCall, setSelectedCall] = useState(null);

  const { getAccessToken, activeFranchise } = useAuth();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [selectedDate, setSelectedDate] = useState(Date.now());
  const [category, setCategory] = useState("all");
  const [status, setStatus] = useState("all");

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };

  const handleChangeStatus = (event) => {
    if (event.target.value === "overdue") {
      setSelectedDate(null);
    }
    setStatus(event.target.value);
  };

  const handleChangeDate = (newValue) => {
    if (newValue) {
      if (status === "overdue") {
        setStatus("all");
      }
      setSelectedDate(newValue.valueOf());
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const loadData = async () => {
    setLoadingData(true);

    let url = `/api/salesflags/mgract/replies/query/my?skip=${
      page * rowsPerPage
    }&limit=${rowsPerPage}&sort=_id&dir=d`;

    if (category !== "all") {
      url += `&entity=${category}`;
    }

    if (status !== "all") {
      if (status === "overdue") {
        url += `&pending=true`;
      } else {
        url += `&status=${status}`;
      }
    }

    if (selectedDate) {
      const dts = getDayStartAndEndSync(selectedDate);
      url += `&date_gt=${dts.start}&date_lt=${dts.end}`;
    } else {
      url += `&date_lt=${Date.now()}`;
    }

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  const [currCall, setCurrentCall] = useState(null);
  const [callModalState, setCallModalState] = useState(false);

  const handleEntrySelected = (CustData) => {
    setCurrentCall(CustData);
    setCallModalState(true);
  };

  const handleCommentSelected = (callData) => {
    setSelectedCall(callData);
    setCommentModalState(true);
  };

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, [page, rowsPerPage, category, status, selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const [selectedCust, setSelectedCust] = useState(null);
  const [custDrawerOpen, setCustDrawerOpen] = useState(false);

  const handleCustomerOpen = (custData) => {
    setSelectedCust(custData);
    setCustDrawerOpen(true);
  };

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h5" gutterBottom>
            Call History
          </Typography>
          <Stack direction="row" alignItems="center" spacing={2}>
            <DateSelector value={selectedDate} onChange={handleChangeDate} />

            <TextField
              select
              id="cat-select"
              value={category}
              onChange={handleChangeCategory}
              label="Category"
            >
              <MenuItem value="all">ALL</MenuItem>
              <MenuItem value="onboarding">Onboarding</MenuItem>
              <MenuItem value="crm">CRM</MenuItem>
              <MenuItem value="enquiry">Enquiry</MenuItem>
            </TextField>

            <TextField
              select
              id="status-select"
              value={status}
              onChange={handleChangeStatus}
              label="Status"
            >
              <MenuItem value="all">ALL</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="missed">Missed</MenuItem>
              <MenuItem value="live">Live</MenuItem>
              <MenuItem value="overdue">Overdue</MenuItem>
            </TextField>

            {(loadingData === true && (
              <CircularProgress color="secondary" />
            )) || (
              <IconButton
                onClick={() => {
                  if (page === 0) loadData();
                  else setPage(0);
                }}
              >
                <RefreshRounded />
              </IconButton>
            )}
          </Stack>
        </Stack>

        <TableContainer sx={{ minHeight: 100 }}>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.alignRight ? "right" : "left"}
                    sortDirection={false}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {customerData.map((item) => {
                return (
                  <TableRow onClick={() => handleEntrySelected(item)}>
                    <TableCell>
                      {getDateStringFullWithTimeSync(item?.date)}
                    </TableCell>
                    <TableCell
                      onClick={(e) => {
                        handleCustomerOpen({
                          _id: item.user,
                          name: item.name,
                        });
                        e.stopPropagation();
                      }}
                    >
                      {item?.name}
                    </TableCell>
                    <TableCell>{item?.call_details?.status || "-"}</TableCell>
                    <TableCell>{item?.entity}</TableCell>
                    <Tooltip title={item?.comments || ""}>
                      <TableCell>
                        {(item?.comments &&
                          item?.comments?.substring(0, 20)) || (
                          <IconButton
                            color="error"
                            onClick={(e) => {
                              handleCommentSelected(item);
                              e.stopPropagation();
                            }}
                          >
                            <MessageRounded />
                          </IconButton>
                        )}
                      </TableCell>
                    </Tooltip>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={-1}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
          rowsPerPageOptions={[10, 20, 50]}
          page={page}
          onPageChange={handleChangePage}
        />
      </Container>

      <Modal open={callModalState} onClose={() => setCallModalState(false)}>
        <CallDetails
          callDet={currCall}
          handleClose={() => setCallModalState(false)}
        />
      </Modal>

      <CustomerDetailsPage
        customer={selectedCust}
        handleClose={() => setCustDrawerOpen(false)}
        drawerOpen={custDrawerOpen}
      />

      <Modal
        open={commentModalState}
        onClose={() => setCommentModalState(false)}
      >
        <CommentModal
          details={selectedCall}
          handleClose={() => {
            setCommentModalState(false);
            loadData();
          }}
        />
      </Modal>
    </Card>
  );
}
