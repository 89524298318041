import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Drawer,
  OutlinedInput,
  InputAdornment,
  Box,
  Container,
  Switch,
  MenuItem,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";

import { useSnackbar } from "src/contexts/SnackbarContext";
import MakeFullScreen from "src/components/MakeFullScreen";
import DeptSearch from "../../employee/dept/DeptSearch";
import CategorySearch from "../CategorySearch";

export default function AddItem({ open, onClose, duplicate }) {
  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [deptDrawerState, setDeptDrawerState] = useState(false);
  const [selectDept, setSelectDept] = useState(duplicate?.department || null);

  const [categoryDrawerState, setCategoryDrawerState] = useState(false);
  const [selectCategory, setSelectCategory] = useState(
    duplicate?.category || null
  );

  const approveNow = async (values) => {
    if (!selectCategory || !selectCategory.name) {
      showSnackbar("Please select a category.", "error");
      return;
    }

    if (!selectDept || !selectDept._id) {
      showSnackbar("Please select a department.", "error");
      return;
    }

    const upd = {
      name: values.name,
      category: selectCategory.name,
      department: selectDept._id,
      unit: values.unit,
      unit_price: values.unit_price,
      min_stock: values.min_stock,
      perishable: values.perishable,
    };

    if (values.location.length > 0) {
      upd.location = values.location;
    }
    if (values.comments.length > 0) {
      upd.comments = values.comments;
    }
    if (values.details.length > 0) {
      upd.details = values.details;
    }

    const ret = await dataPost(
      `/api/inventory/mgract/items/add`,
      await getAccessToken(),
      upd,
      activeFranchise
    );
    if (ret.success === true) {
      showSnackbar(ret.message || "Item added.", "success");
      onClose();
    } else {
      showSnackbar(ret.message + " - " + ret.code, "error");
    }
  };

  const DataSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    details: Yup.string(),
    unit: Yup.string().required("Unit is required"),
    unit_price: Yup.number().required("Unit Price is required"),
    min_stock: Yup.number().required("Min Stock is required"),
    perishable: Yup.boolean(),
    location: Yup.string(),
    comments: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: duplicate?.name || "",
      details: duplicate?.details || "",
      unit: duplicate?.unit || "Pc",
      unit_price: duplicate?.unit_price || "",
      min_stock: 0,
      perishable: false,
      location: duplicate?.location || "",
      comments: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  return (
    <MakeFullScreen
      title="Add Item"
      open={open}
      onClose={onClose}
      maxWidth={"md"}
    >
      <Container sx={{ px: 2, pb: 2 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <OutlinedInput
                fullWidth
                type="text"
                value={selectCategory?.name || "None Selected"}
                onClick={() => setCategoryDrawerState(true)}
                startAdornment={
                  <InputAdornment position="start">
                    <Box sx={{ color: "text.disabled" }}>Category</Box>
                  </InputAdornment>
                }
              />

              <OutlinedInput
                fullWidth
                type="text"
                value={selectDept?.name || "None Selected"}
                onClick={() => setDeptDrawerState(true)}
                startAdornment={
                  <InputAdornment position="start">
                    <Box sx={{ color: "text.disabled" }}>Department</Box>
                  </InputAdornment>
                }
              />

              <Stack spacing={2} direction={"row"}>
                <TextField
                  fullWidth
                  type="text"
                  label="Name"
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  label="PERISHABLE"
                  value={getFieldProps("perishable").value ? "Yes" : "No"}
                  onChange={(e) => {
                    if (e.target.value?.includes("y")) {
                      setFieldValue("perishable", true);
                    } else if (e.target.value?.includes("n")) {
                      setFieldValue("perishable", false);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Switch
                          checked={getFieldProps("perishable").value}
                          onChange={(e, c) => setFieldValue("perishable", c)}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>

              <TextField
                fullWidth
                type="text"
                label="Details"
                {...getFieldProps("details")}
                error={Boolean(touched.details && errors.details)}
                helperText={touched.details && errors.details}
              />

              <Stack spacing={2} direction={"row"}>
                <TextField
                  fullWidth
                  type="number"
                  label="Unit Price"
                  {...getFieldProps("unit_price")}
                  error={Boolean(touched.unit_price && errors.unit_price)}
                  helperText={touched.unit_price && errors.unit_price}
                />

                <TextField
                  fullWidth
                  select
                  type="text"
                  label="Unit"
                  {...getFieldProps("unit")}
                  error={Boolean(touched.unit && errors.unit)}
                  helperText={touched.unit && errors.unit}
                >
                  <MenuItem value="Pc">Pc</MenuItem>
                  <MenuItem value="Kg">Kg</MenuItem>
                  <MenuItem value="Gram">Gram</MenuItem>
                  <MenuItem value="Lt">Lt</MenuItem>
                  <MenuItem value="mL">mL</MenuItem>
                  <MenuItem value="Mtr">Mtr</MenuItem>
                  <MenuItem value="Set">Set</MenuItem>
                  <MenuItem value="Box">Box</MenuItem>
                  <MenuItem value="Pkt">Pkt</MenuItem>
                  <MenuItem value="Dz">Dozen</MenuItem>
                  <MenuItem value="Pair">Pair</MenuItem>
                  <MenuItem value="Roll">Roll</MenuItem>
                  <MenuItem value="Bundle">Bundle</MenuItem>
                  <MenuItem value="Bag">Bag</MenuItem>
                </TextField>
              </Stack>
              <Stack spacing={2} direction={"row"}>
                <TextField
                  fullWidth
                  type="number"
                  label="Min Stock"
                  {...getFieldProps("min_stock")}
                  error={Boolean(touched.min_stock && errors.min_stock)}
                  helperText={touched.min_stock && errors.min_stock}
                />
                <TextField
                  fullWidth
                  type="text"
                  label="Location"
                  {...getFieldProps("location")}
                  error={Boolean(touched.location && errors.location)}
                  helperText={touched.location && errors.location}
                />
              </Stack>

              <TextField
                fullWidth
                multiline
                type="text"
                label="Comments"
                {...getFieldProps("comments")}
                error={Boolean(touched.comments && errors.comments)}
                helperText={touched.comments && errors.comments}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              ADD NEW ITEM
            </LoadingButton>
          </Form>
        </FormikProvider>

        <Drawer
          anchor={"right"}
          open={deptDrawerState}
          onClose={() => setDeptDrawerState(false)}
          sx={{ zIndex: 1300 }}
          PaperProps={{
            sx: { width: "30%" },
          }}
        >
          <DeptSearch
            onDeptSelected={(dept) => {
              setDeptDrawerState(false);
              setSelectDept(dept);
            }}
          />
        </Drawer>
        <Drawer
          anchor={"right"}
          open={categoryDrawerState}
          onClose={() => setCategoryDrawerState(false)}
          sx={{ zIndex: 1300 }}
          PaperProps={{
            sx: { width: "30%" },
          }}
        >
          <CategorySearch
            onCategorySelected={(dept) => {
              setCategoryDrawerState(false);
              setSelectCategory(dept);
            }}
          />
        </Drawer>
      </Container>
    </MakeFullScreen>
  );
}
