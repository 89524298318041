import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  Modal,
  OutlinedInput,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch, dataPost } from "src/utils/data-fetch";
import PaymentTable from "./PaymentTable";
import { SearchRounded } from "@mui/icons-material";
import { DateSelector } from "src/utils/date-selector";

import { useConfirm } from "material-ui-confirm";
import { getDateStringFullSync } from "src/utils/date-time-helper";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function MatchPaymentModal({ account, handleM1Close }) {
  const { getAccessToken, activeFranchise } = useAuth();
  const confirm = useConfirm();

  const [error, setError] = useState();

  const [amount, setAmount] = useState();
  const [utr, setUtr] = useState();
  const [date, setDate] = useState(Date.now());

  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const loadData = async () => {
    if ((!utr || utr.length === 0) && (!amount || amount.length === 0)) {
      setError("Enter UTR or Amount");
      return;
    }

    setLoadingData(true);
    setError(null);

    let url = `/api/extrasales/mgract/txns/utrsearch?date=${date}`;

    if (amount && parseFloat(amount) > 0) {
      url += `&amount=${amount}`;
    }
    if (utr && utr.length > 0) {
      url += `&utr=${utr}`;
    }

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success) {
      if (ret.data.length === 0) {
        setError("No data found!");
      }
      setData(ret.data);
    } else {
      setError(ret.message);
    }

    setLoadingData(false);
  };

  const matchData = async (payment) => {
    setLoadingData(true);
    setError(null);

    const ret = await dataPost(
      "/api/extrasales/mgract/txns/utrsearch",
      await getAccessToken(),
      {
        txn_id: payment._id,
        account: account,
      },
      activeFranchise
    );

    if (ret.success === true) {
      handleM1Close();
    } else {
      setError(ret.message);
    }

    setLoadingData(false);
  };

  return (
    <Container
      sx={{
        px: 4,
        py: 2,
        width: 900,
        height: "fit-content",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Card sx={{ minHeight: "400px", p: 2 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Match Payment
        </Typography>

        <Stack direction="row" spacing={2} mt={1} alignItems={"center"}>
          <SearchStyle
            placeholder="Enter UTR or select amount and date"
            onChange={(e) => setUtr(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>
                  <SearchRounded />
                </Box>
              </InputAdornment>
            }
          />

          <TextField
            label="Amount"
            variant="outlined"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">₹</InputAdornment>
              ),
            }}
          />

          <DateSelector value={date} onChange={setDate} />

          {(loadingData === true && (
            <Button
              size="large"
              type="submit"
              variant="contained"
              color="primary"
            >
              <CircularProgress sx={{ color: "white.main" }} />
            </Button>
          )) || (
            <Button
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => loadData()}
            >
              <SearchRounded />
            </Button>
          )}
        </Stack>

        {data && data.length > 0 && (
          <TableContainer sx={{ mt: 2 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Entity</TableCell>
                  <TableCell>Comments</TableCell>
                  <TableCell>Method</TableCell>
                  <TableCell align="right">UTR</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow
                    key={row._id}
                    hover
                    onClick={() => {
                      confirm(
                        "Are you sure you want to match this payment?"
                      ).then(() => {
                        matchData(row);
                      });
                    }}
                  >
                    <TableCell>
                      <b>{getDateStringFullSync(row?.date)}</b>
                    </TableCell>
                    <TableCell>₹ {row?.amount}</TableCell>
                    <TableCell>{row?.entity}</TableCell>
                    <TableCell>{row?.comments}</TableCell>
                    <TableCell>{row?.method?.toUpperCase()}</TableCell>
                    <TableCell align="right">{row?.utr}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </Container>
  );
}
