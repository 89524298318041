import React, { useContext, useState, useEffect } from "react";
import { dataFetch } from "../utils/data-fetch";
import { useAuth } from "./AuthContext";

import { rootUrl } from "../utils/data-fetch";

const { io } = require("socket.io-client");

const SocketContext = React.createContext();
export function useSocket() {
  return useContext(SocketContext);
}

export function SocketProvider({ children }) {
  const [socket, setSocket] = useState(null);
  const [users, setUsers] = useState({});
  const [cartData, setCartData] = useState({});
  const [franchiseeData, setFranchiseeData] = useState({});
  const [milkData, setMilkData] = useState([]);
  const [apartmentData, setApartmentData] = useState([]);
  const [loadingUser, setLoadingUser] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [sfData, setSfData] = useState([]);

  const { getAccessToken, activeFranchise } = useAuth();

  async function getSocket() {
    if (socket != null) {
      socket.close();
    }
    const newSocket = io.connect(rootUrl + "/dashboardsocket", {
      query: {
        token: await getAccessToken(),
        franchisee: activeFranchise,
      },
    });
    setSocket(newSocket);
    return () => newSocket.close();
  }

  //const currentManager = currentUser?.manager;

  function handleUserUpdate(data) {
    if (data.type === "milk_update") {
      setMilkData([...milkData, data.milk]);
    } else if (data.type === "user_update") {
        //TODO
    }
  }

  // function handleSFUpdate(data) {
  //   if (data.type == "init") {
  //     setSfData(data.data);
  //   } else if (data.type == "add") {
  //     const doc = data.data;
  //     if (doc.status == "assigned" && doc.manager == currentManager._id) {
  //       setSfData([...sfData, doc]);
  //     }
  //   } else if (data.type == "update") {
  //     const doc = data.data;

  //     let updated = false;
  //     let updL = sfData.map((item) => {
  //       if (item._id == doc._id) {
  //         updated = true;
  //         return doc;
  //       } else return item;
  //     });

  //     if (updated === true) {
  //       setSfData(updL);
  //     } else if (
  //       doc.status == "assigned" &&
  //       doc.manager == currentManager._id
  //     ) {
  //       setSfData([...sfData, doc]);
  //     }
  //   }
  // }

  if (socket != null) {
    socket.on("connect", () => {
      console.log(socket.id, "connected");
      socket.emit("getinitialdata", []);
    });

    socket.on("initialdata", (data) => {
      console.log(data);
      setUsers(data["users"]);
      setMilkData(data["milks"]);
      setCartData(data["carts"]);
      setFranchiseeData(data["franchisees"]);
      setApartmentData(data["apartments"]);
    });

    socket.on("userupdate", (data) => {
      handleUserUpdate(data);
    });

    socket.on("salesflags", (data) => {
      //handleSFUpdate(data);
    });

    socket.on("disconnect", () => {
      console.log("disconnected"); // false
    });
  }

  useEffect(() => {
    getSocket();
  }, [activeFranchise]);

  async function loadUserDetails(id) {
    setLoadingUser(true);
    setCurrentUser(null);

    const ret = await dataFetch(
      `/api/users/mgract/user/${id}?extended=true`,
      await getAccessToken()
    );

    if (ret.success === true) {
      setCurrentUser(ret.data);
    } else {
      setCurrentUser(null);
    }

    setLoadingUser(false);
  }

  async function getUserDetails(id) {
    if (users[id])
    setCurrentUser(users[id]);
    else loadUserDetails(id);
  }

  const value = {
    currentUser,
    loadingUser,
    socket,
    users,
    cartData,
    franchiseeData,
    milkData,
    apartmentData,
    loadUserDetails,
    getUserDetails,
  };

  return (
    <SocketContext.Provider value={value}> {children} </SocketContext.Provider>
  );
}
