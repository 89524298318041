import {
  Grid,
  Container,
  Typography,
  Stack,
  Button,
  Modal,
  Card,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import Page from "../../components/Page";

import EmployeeSearch from "./EmployeeSearch";
import AddEmployeeModal from "./add/AddEmployeeModal";

import { useState } from "react";
import PendingEmployeePayroll from "./payroll/PendingEmployeePayroll";
import { ArrowRightRounded } from "@mui/icons-material";
import BirthdaysModal from "./BirthdaysModal";
import DepartmentsPage from "./dept/DepartmentsPage";
import SalaryComponentsPage from "./salary/SalaryComponentsPage";
import { SalaryCalculator } from "./salary/SalaryCalculator";
import MakeFullScreen from "src/components/MakeFullScreen";
import EmployeeDetailsPage from "./det/EmployeeDetailsPage";
import TopCard from "./TopCard";

export default function Employees() {
  const [modalOpen, setModalOpen] = useState(false);
  const [addEmployeeDrawerOpen, setAddEmployeeDrawerOpen] = useState(false);

  const closeEmployeeModal = () => {
    setAddEmployeeDrawerOpen(false);
  };

  const addEmployeeClicked = () => {
    setAddEmployeeDrawerOpen(true);
  };

  const navigate = useNavigate();

  const [currEmp, setCurrEmp] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [departmentDrawerOpen, setDepartmentDrawerOpen] = useState(false);
  const [salaryDrawerOpen, setSalaryDrawerOpen] = useState(false);
  const [salaryCalculatorOpen, setSalaryCalculatorOpen] = useState(false);

  const handleDepartmentDrawer = () => {
    setDepartmentDrawerOpen(true);
  };

  const handleSalaryDrawer = () => {
    setSalaryDrawerOpen(true);
  };

  const handleSalaryCalculator = () => {
    setSalaryCalculatorOpen(true);
  };

  const handleEmpSelected = (empData) => {
    setCurrEmp(empData);
    setDrawerOpen(true);
  };

  const [birthdayModalOpen, setBirthdayModalOpen] = useState(false);

  return (
    <Page title="Employee / Contractor Dashboard">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography variant="h4">Employee / Contractor Dashboard</Typography>

          <Button variant="contained" onClick={addEmployeeClicked}>
            Add Contractor / Employee
          </Button>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={6} md={3} lg={3}>
            <TopCard data="Payroll" onClick={() => navigate("payroll")} />
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <TopCard data="Attendance" onClick={() => navigate("attendance")} />
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <TopCard
              data="Birthdays"
              onClick={() => setBirthdayModalOpen(true)}
            />
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <TopCard data="Complaints" onClick={() => navigate("complaints")} />
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <TopCard data="Hierarchy" />
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <TopCard
              data="Departments"
              onClick={() => handleDepartmentDrawer()}
            />
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <TopCard
              data="Salary Components"
              onClick={() => handleSalaryDrawer()}
            />
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <TopCard
              data="Salary calculator"
              onClick={() => handleSalaryCalculator()}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <EmployeeSearch onEmpSelected={handleEmpSelected} />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <PendingEmployeePayroll onEmpSelected={handleEmpSelected} />
          </Grid>
        </Grid>
      </Container>

      <MakeFullScreen
        open={addEmployeeDrawerOpen}
        onClose={closeEmployeeModal}
        maxWidth={"md"}
        title={"Add Employee / Contractor"}
      >
        <AddEmployeeModal
          handleClose={(data) => {
            closeEmployeeModal();
            handleEmpSelected(data);
          }}
        />
      </MakeFullScreen>

      <Modal
        open={birthdayModalOpen}
        onClose={() => setBirthdayModalOpen(false)}
      >
        <BirthdaysModal
          handleClose={() => setBirthdayModalOpen(false)}
          onEmpSelected={handleEmpSelected}
        />
      </Modal>

      {drawerOpen && (
        <MakeFullScreen
          onClose={() => setDrawerOpen(false)}
          open={drawerOpen}
          title={currEmp?.name}
        >
          <EmployeeDetailsPage incEmployee={currEmp} />
        </MakeFullScreen>
      )}

      {departmentDrawerOpen && (
        <DepartmentsPage
          drawerOpen={departmentDrawerOpen}
          handleClose={() => setDepartmentDrawerOpen(false)}
        />
      )}

      <SalaryComponentsPage
        drawerOpen={salaryDrawerOpen}
        handleClose={() => setSalaryDrawerOpen(false)}
      />

      <SalaryCalculator
        drawerOpen={salaryCalculatorOpen}
        handleClose={() => setSalaryCalculatorOpen(false)}
      />
    </Page>
  );
}
