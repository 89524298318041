import { styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(3, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.info.lighter,
}));

export default function TopCard({ data, text, color, onClick }) {
  return (
    <RootStyle style={{backgroundColor: color}} onClick={onClick}>
      <Typography variant="h4">{data}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {text}
      </Typography>
    </RootStyle>
  );
}
