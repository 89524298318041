import { Line } from "react-chartjs-2";
import { getDateStringCustom } from "src/utils/date-time-helper";
import Chart from "chart.js/auto";

let labels = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

const MonthChartComponent = ({ prevData, data, prevDate, date }) => {
  let data1 = [];
  let data2 = [];

  if (prevData && prevData.dispense) {
    const d1 = getDateStringCustom(prevDate, "MM-YYYY");
    data1 = labels.map((l) => {
      return prevData?.dispense[`${l}-${d1}`] || 0;
    });
  }

  if (data && data.dispense) {
    const d2 = getDateStringCustom(date, "MM-YYYY");
    data2 = labels.map((l) => {
      return data?.dispense[`${l}-${d2}`] || 0;
    });
  }

  const graph = {
    labels: labels,
    datasets: [
      {
        label: getDateStringCustom(prevDate, "MMMYY"),
        data: data1,
        fill: false,
        borderColor: "rgb(192, 75, 192)",
        tension: 0.2,
      },
      {
        label: getDateStringCustom(date, "MMMYY"),
        data: data2,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.2,
      },
    ],
  };

  return (
    <div>
      <Line data={graph} />
    </div>
  );
};

export default MonthChartComponent;
