import { Grid, Container, Typography, Stack, Button } from "@mui/material";

import Page from "src/components/Page";

import { useEffect, useState } from "react";
import TopCard from "./TopCard";
import {
  AddShoppingCartRounded,
  DevicesRounded,
  FactoryRounded,
  SearchRounded,
} from "@mui/icons-material";
import Items from "./item/Items";
import Vendors from "./vendor/Vendors";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";
import { useSnackbar } from "src/contexts/SnackbarContext";
import ItemsCatWise from "./item/ItemsCatWise";
import { lightBkgColors } from "src/utils/constants";

export default function Inventory() {
  const [itemsModalState, setItemsModalState] = useState(false);
  const [itemsCatModalState, setItemsCatModalState] = useState(null);
  const [vendorModalState, setVendorModalState] = useState(false);

  const [allData, setAllData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const fetchData = async () => {
    setLoadingData(true);

    let url = `/api/inventory/mgract/categories`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setAllData(ret.data);
    } else {
      showSnackbar(ret.message || "Load failed!", "error");
    }

    setLoadingData(false);
  };

  useEffect(() => {
    fetchData();
  }, [activeFranchise]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page title="Inventory Dashboard">
      <Container sx={{ pt: 2 }}>
        <Grid container spacing={3} mb={4}>
          {allData
            ?.sort((a, b) => a?.name?.localeCompare(b?.name))
            ?.map((item, index) => (
              <Grid item xs={6} md={4} lg={4}>
                <TopCard
                  data={
                    <>
                      {item.code}
                      <br />
                      {item.name}
                    </>
                  }
                  height={150}
                  color={
                    item?.color || lightBkgColors[index % lightBkgColors.length]
                  }
                  onClick={() => setItemsCatModalState(item)}
                />
              </Grid>
            ))}
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={3}>
            <TopCard
              icon={<SearchRounded />}
              data="Inventory"
              height={200}
              color={"info.light"}
              onClick={() => setItemsModalState(true)}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TopCard
              icon={<FactoryRounded />}
              data="Vendors"
              height={200}
              color={"success.light"}
              onClick={() => setVendorModalState(true)}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TopCard
              icon={<DevicesRounded />}
              data="Assets"
              height={200}
              color={"error.light"}
              // onClick={() => setBirthdayModalOpen(true)}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TopCard
              icon={<AddShoppingCartRounded />}
              data="Purchase"
              height={200}
              color={"secondary.light"}
              // onClick={() => setItemsModalState(true)}
            />
          </Grid>
        </Grid>
      </Container>

      {itemsModalState && (
        <Items
          open={itemsModalState}
          onClose={() => setItemsModalState(false)}
        />
      )}

      {vendorModalState && (
        <Vendors
          open={vendorModalState}
          onClose={() => setVendorModalState(false)}
        />
      )}

      {itemsCatModalState && (
        <ItemsCatWise
          open={true}
          onClose={() => setItemsCatModalState(null)}
          category={itemsCatModalState}
        />
      )}
    </Page>
  );
}
