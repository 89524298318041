import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import Select from "@mui/material/Select";

import { MobileDateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Grid,
  Box,
  InputAdornment,
  MenuItem,
  Drawer,
  OutlinedInput,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";

import InternalAccSearch from "../ledgers/InternalAccSearch";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddBillModal({ handleM1Close, duplicateData }) {
  const [error, setError] = useState();

  const [selectedFrom, setSelectedFrom] = useState(
    (duplicateData && duplicateData.from) || {}
  );
  const [selectedTo, setSelectedTo] = useState(
    (duplicateData && duplicateData.to) || {}
  );
  const [selectedDate, setSelectedDate] = useState(
    (duplicateData && duplicateData.date) || Date.now()
  );

  const { getAccessToken, activeFranchise, allFranchisees } = useAuth();

  const approveNow = async (values) => {
    const ret = await dataPost(
      `/api/internalaccs/coreact/txns/addbill`,
      await getAccessToken(),
      {
        franchisee: values.franchisee,
        from: (selectedFrom && selectedFrom._id) || undefined,
        to: (selectedTo && selectedTo._id) || undefined,
        bill_category: values.bill_category,
        date: selectedDate,
        comments: values.comments.length > 0 ? values.comments : undefined,
        invoice_no: values.invoice_no,
        name: values.name,
        quantity: values.quantity,
        rate: values.rate,
        gst_type: values.gst_type,
        gst: values.gst,
        unit: values.unit,
      }
    );
    if (ret.success === true) {
      alert("Bill added");
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    franchisee: Yup.string().required(),
    entity: Yup.string().required("Entity is required"),
    bill_category: Yup.string().required("Bill category is required"),
    comments: Yup.string().optional(),
    invoice_no: Yup.string().required("Invoice Number is required!"),
    name: Yup.string().required("Item name is required!"),
    quantity: Yup.number().required("Quantity is required!"),
    rate: Yup.number().required("Rate is required!"),
    gst_type: Yup.string().required("GST type is required"),
    gst: Yup.number().required("GST % is required!"),
    unit: Yup.string().required("Unit is required!"),
  });

  const formik = useFormik({
    initialValues: {
      franchisee:
        (duplicateData &&
          duplicateData.franchisee &&
          duplicateData.franchisee._id) ||
        undefined,
      entity: "bill",
      bill_category: "asset",
      comments: "",
      invoice_no: "",
      name: "",
      quantity: "",
      rate: "",
      gst_type: "igst",
      gst: "0",
      unit: "Nos",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const [searchDrawerState, setSearchDrawerState] = useState(null);

  const drawerSearchInit = (type) => {
    setSearchDrawerState(type);
  };

  const drawerReturnSearch = (acc) => {
    console.log(acc);
    if (searchDrawerState === "from") {
      setSelectedFrom(acc);
    } else if (searchDrawerState === "to") {
      setSelectedTo(acc);
    }
    setSearchDrawerState(null);
  };

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Add Bill
          </Typography>

          <RadioGroup row {...getFieldProps("gst_type")}>
            <FormControlLabel value="none" control={<Radio />} label="No GST" />
            <FormControlLabel value="igst" control={<Radio />} label="IGST" />
            <FormControlLabel
              value="csgst"
              control={<Radio />}
              label="CGST/SGST"
            />
          </RadioGroup>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={3}>
                  <Select
                    id="fr-select"
                    {...getFieldProps("franchisee")}
                    MenuProps={MenuProps}
                    startAdornment={
                      <InputAdornment position="start">
                        <Box sx={{ color: "text.disabled" }}>Franchise</Box>
                      </InputAdornment>
                    }
                  >
                    {allFranchisees.map((item) => (
                      <MenuItem value={item._id}>{item.name}</MenuItem>
                    ))}
                  </Select>

                  <OutlinedInput
                    fullWidth
                    type="text"
                    value={(selectedFrom && selectedFrom.name) || ""}
                    onClick={() => drawerSearchInit("from")}
                    startAdornment={
                      <InputAdornment position="start">
                        <Box sx={{ color: "text.disabled" }}>From</Box>
                      </InputAdornment>
                    }
                  />

                  <OutlinedInput
                    fullWidth
                    type="text"
                    value={(selectedTo && selectedTo.name) || ""}
                    onClick={() => drawerSearchInit("to")}
                    startAdornment={
                      <InputAdornment position="start">
                        <Box sx={{ color: "text.disabled" }}>To</Box>
                      </InputAdornment>
                    }
                  />
                  <Select
                    id="entity-select"
                    label="Entity"
                    {...getFieldProps("entity")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={"bill"}>Purchase Bill</MenuItem>
                  </Select>

                  <Select
                    id="category-select"
                    label="Bill Category"
                    {...getFieldProps("bill_category")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={"asset"}>Asset</MenuItem>
                    <MenuItem value={"expense"}>Expense</MenuItem>
                  </Select>

                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale={"en-in"}
                  >
                    <MobileDateTimePicker
                      value={moment(selectedDate)}
                      onAccept={(newValue) => {
                        if (newValue) {
                          setSelectedDate(newValue.valueOf());
                        }
                      }}
                      closeOnSelect={false}
                      format="DD-MM-YYYY hh:mm:ss A"
                    />
                  </LocalizationProvider>

                  <TextField
                    fullWidth
                    type="text"
                    label="Comments"
                    {...getFieldProps("comments")}
                    error={Boolean(touched.comments && errors.comments)}
                    helperText={touched.comments && errors.comments}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Invoice No."
                    {...getFieldProps("invoice_no")}
                    error={Boolean(touched.invoice_no && errors.invoice_no)}
                    helperText={touched.invoice_no && errors.invoice_no}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Item Name"
                    {...getFieldProps("name")}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />

                  <TextField
                    fullWidth
                    type="number"
                    label="Quantity"
                    {...getFieldProps("quantity")}
                    error={Boolean(touched.quantity && errors.quantity)}
                    helperText={touched.quantity && errors.quantity}
                  />

                  <TextField
                    fullWidth
                    type="number"
                    label="Rate (w/o tax if GST applicable)"
                    {...getFieldProps("rate")}
                    error={Boolean(touched.rate && errors.rate)}
                    helperText={touched.rate && errors.rate}
                  />

                  <TextField
                    fullWidth
                    type="number"
                    label={
                      formik.values.gst_type === "igst"
                        ? "IGST %"
                        : formik.values.gst_type === "csgst"
                        ? "CGST + SGST %"
                        : "-"
                    }
                    {...getFieldProps("gst")}
                    error={Boolean(touched.gst && errors.gst)}
                    helperText={touched.gst && errors.gst}
                  />
                  <TextField
                    fullWidth
                    disabled
                    type="number"
                    label="Amount"
                    value={
                      (formik.values.quantity || 0) *
                      (formik.values.rate || 0) *
                      (1 + (formik.values.gst || 0) / 100)
                    }
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Units (KG, LTR, Pcs, Nos, etc.)"
                    {...getFieldProps("unit")}
                    error={Boolean(touched.unit && errors.unit)}
                    helperText={touched.unit && errors.unit}
                  />
                </Stack>
              </Grid>
            </Grid>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>

      <Drawer
        anchor={"right"}
        open={searchDrawerState ? true : false}
        onClose={() => setSearchDrawerState(null)}
        sx={{ zIndex: 1300, p: 3 }}
      >
        {(searchDrawerState === "from" || searchDrawerState === "to") && (
          <InternalAccSearch
            onAccSelected={drawerReturnSearch}
            initFr={getFieldProps("franchisee").value}
          />
        )}
      </Drawer>
    </div>
  );
}
