import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  OutlinedInput,
  InputAdornment,
  Box,
  Drawer,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPatch, dataPost } from "src/utils/data-fetch";
import ProductSearch from "src/pages/products/ProductSearch";
import { AddRounded, RemoveRounded } from "@mui/icons-material";
import { DateSelector } from "src/utils/date-selector";
import {
  getDayStartAndEndSync,
  TWENTY4HOURS,
} from "src/utils/date-time-helper";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function BuyOnceModal({ handleM1Close, customer }) {
  const [error, setError] = useState();

  const { getAccessToken, activeFranchise } = useAuth();

  const [loading, setLoading] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedQty, setSelectedQty] = useState(1);
  const [slot, setSlot] = useState("1");
  const [message, setMessage] = useState("");
  const [selectedDate, setSelectedDate] = useState(Date.now());

  const [searchDrawerState, setSearchDrawerState] = useState(null);

  const drawerSearchInit = (type) => {
    setSearchDrawerState(type);
  };

  const drawerReturnSearch = (acc) => {
    console.log(acc);
    if (searchDrawerState === "product") {
      setSelectedProduct(acc);
      setSelectedQty(acc.quantum || 1);
    } else if (searchDrawerState === "to") {
    }
    setSearchDrawerState(null);
  };

  const approveNow = async (values) => {
    setError(null);

    if (!selectedProduct) {
      setError("Please select a product");
      return;
    }

    if (!customer) {
      setError("Please select a customer");
      return;
    }

    if (selectedDate < getDayStartAndEndSync(Date.now()).start) {
      setError("Date not allowed in past");
      return;
    }

    if (selectedQty <= 0) {
      setError("Quantity should be greater than 0");
      return;
    }

    setLoading(true);

    let up = {
      user: customer._id,
      quantity: selectedQty,
      date: selectedDate,
      slot: slot,
    };

    if (message && message.length > 0) {
      up.comments = message;
    }

    const ret = await dataPost(
      `/api/products/mgract/product/${selectedProduct._id}/addtodelivery`,
      await getAccessToken(),
      up,
      activeFranchise
    );
    if (ret.success === true) {
      alert("Subscription added");
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }

    setLoading(false);
  };

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Add Individual Order
          </Typography>
        </Stack>

        <Stack spacing={3}>
          <OutlinedInput
            fullWidth
            type="text"
            value={(customer && customer.name) || ""}
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>Customer</Box>
              </InputAdornment>
            }
          />

          <OutlinedInput
            fullWidth
            type="text"
            value={(selectedProduct && selectedProduct.name) || ""}
            onClick={() => drawerSearchInit("product")}
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>Product</Box>
              </InputAdornment>
            }
          />

          <Stack direction={"row"} spacing={3} alignItems={"center"}>
            <TextField
              select
              label="Slot"
              sx={{ minWidth: 150 }}
              value={slot}
              onChange={(e) => setSlot(e.target.value)}
            >
              <MenuItem value="1">5AM - 7AM</MenuItem>
              <MenuItem value="2">7AM - 9AM</MenuItem>
              <MenuItem value="3">9AM - 11AM</MenuItem>
              <MenuItem value="4">5PM - 7PM</MenuItem>
              <MenuItem value="5">7PM - 9PM</MenuItem>
            </TextField>

            <DateSelector
              value={selectedDate}
              onChange={setSelectedDate}
              title={"Select Delivery Date"}
              displayFormat={"DD MMM YYYY"}
              minDate={Date.now()}
              maxDate={Date.now() + 10 * TWENTY4HOURS}
            />

            {selectedProduct && (
              <Stack direction={"row"} spacing={3} alignItems={"center"}>
                <Box sx={{ color: "black.main" }}>Quantity</Box>
                <IconButton
                  onClick={() => {
                    let qt = selectedProduct?.quantum || 1;
                    if (selectedQty > qt) {
                      setSelectedQty(selectedQty - qt);
                    }
                  }}
                  color="black"
                >
                  <RemoveRounded />
                </IconButton>

                <Typography variant="h5">{selectedQty?.toFixed(1)}</Typography>

                <IconButton
                  onClick={() => {
                    let qt = selectedProduct?.quantum || 1;
                    setSelectedQty(selectedQty + qt);
                  }}
                  color="black"
                >
                  <AddRounded />
                </IconButton>
              </Stack>
            )}
          </Stack>

          <TextField
            fullWidth
            type="text"
            label="Instructions"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            onClick={approveNow}
            sx={{ mt: 3 }}
          >
            Save
          </LoadingButton>
        </Stack>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>

      <Drawer
        anchor={"right"}
        open={searchDrawerState ? true : false}
        onClose={() => setSearchDrawerState(null)}
        sx={{ zIndex: 1300, p: 3 }}
      >
        {searchDrawerState === "product" && (
          <ProductSearch onItemSelected={drawerReturnSearch} />
        )}
      </Drawer>
    </div>
  );
}
