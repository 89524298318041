import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { styled } from "@mui/material/styles";

import {
  DoneRounded as DoneIcon,
  CloseRounded as CloseIcon,
} from "@mui/icons-material";

import {
  Card,
  Alert,
  Stack,
  Typography,
  IconButton,
  Avatar,
  TableBody,
  TableCell,
  TableRow,
  Box,
} from "@mui/material";

import { dataPost } from "src/utils/data-fetch";

export default function ApproveAccPage({ handleM1Close, fa }) {
  const [error, setError] = useState();

  const { getAccessToken, activeFranchise } = useAuth();

  const approveNow = async () => {
    let url = `/api/onlinepayouts/mgract/fa/${fa._id}/approve`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      {},
      activeFranchise
    );
    if (ret.success === true) {
      alert(ret.message);
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const rejectNow = async () => {
    let url = `/api/onlinepayouts/mgract/fa/${fa._id}/reject`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      {},
      activeFranchise
    );
    if (ret.success === true) {
      alert(ret.message);
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const ProductImgStyle = styled("img")({
    width: "70%",
    height: "100%",
  });

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Approve Account
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ mb: 2 }}
          spacing={2}
        >
          <ProductImgStyle alt={"Passbook Image"} src={fa.image} />

          <TableBody>
            <TableRow hover>
              <TableCell>Verification:</TableCell>
              <TableCell>
                <b>
                  {fa.verified == 0
                    ? "Pending"
                    : fa.verified == 1
                    ? "Done"
                    : "Rejected"}
                </b>
              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell>For:</TableCell>
              <TableCell>
                <b>{fa.table}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Name:</TableCell>
              <TableCell>
                {(fa.farmer && fa.farmer.name) ||
                  (fa.vendor && fa.vendor.name) ||
                  "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Phone:</TableCell>
              <TableCell>
                {(fa.farmer && fa.farmer.phone) ||
                  (fa.vendor && fa.vendor.phone) ||
                  "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Active:</TableCell>
              <TableCell>
                {(fa.active === true && "Active") || "Inactive"}
              </TableCell>
            </TableRow>
            {fa.account_type === "bank_account" && (
              <>
                <TableRow hover>
                  <TableCell>Acc Number:</TableCell>
                  <TableCell>{fa.bank_account.account_number}</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell>IFSC:</TableCell>
                  <TableCell>{fa.bank_account.ifsc}</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell>Beneficiary:</TableCell>
                  <TableCell>{fa.bank_account.name}</TableCell>
                </TableRow>
              </>
            )}
            {fa.account_type === "vpa" && (
              <TableBody>
                <TableRow hover>
                  <TableCell>VPA:</TableCell>
                  <TableCell>{fa.vpa}</TableCell>
                </TableRow>
              </TableBody>
            )}
          </TableBody>
        </Stack>

        <Stack direction={"row"}>
          <Box flexGrow={1} />
          <IconButton onClick={() => rejectNow()}>
            <Avatar
              variant="circular"
              sx={{ backgroundColor: "error.main", color: "white" }}
            >
              <CloseIcon />
            </Avatar>
          </IconButton>
          <IconButton onClick={() => approveNow()}>
            <Avatar
              variant="rounded"
              sx={{
                backgroundColor: "primary.main",
                color: "white",
              }}
            >
              <DoneIcon />
            </Avatar>
          </IconButton>
        </Stack>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
