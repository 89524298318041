import { Card, Box, CircularProgress, Stack } from "@mui/material";

import {
  ChevronRightRounded,
  CallMadeRounded,
  CallReceivedRounded,
} from "@mui/icons-material";

import { useAuth } from "src/contexts/AuthContext";

import Popover from "@mui/material/Popover";
import { useEffect, useState } from "react";
import { dataFetch } from "src/utils/data-fetch";
import {
  getDateStringCWM2,
  getDateStringFullWithTimeSync,
} from "src/utils/date-time-helper";

export default function CustomerCallsPopover({
  dataId,
  anchor,
  handleUmClose,
}) {
  const { getAccessToken, activeFranchise } = useAuth();

  const [detail, setDetail] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadCurrentData = async () => {
    setLoading(true);

    let url = `/api/salesflags/mgract/replies/query?user=${dataId}&category=call&sort=date&dir=d&limit=5`;
    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setDetail(ret.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (dataId) loadCurrentData();
  }, [dataId]);

  return (
    <Popover
      id="customer-calls-popover"
      sx={{
        pointerEvents: "none",
      }}
      open={Boolean(anchor)}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={() => handleUmClose}
      hideBackdrop={false}
    >
      <Card sx={{ p: 2 }}>
        {loading === true && <CircularProgress />}
        {loading === false && detail.length > 0 && (
          <div>
            {detail.map((item) => {
              return (
                <Card sx={{ my: 1, p: 2 }}>
                  <Stack direction="column">
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      maxWidth={500}
                    >
                      {(item.action === "user" && <CallReceivedRounded />) || (
                        <CallMadeRounded />
                      )}
                      <b>{(item.entity && item.entity.toUpperCase()) || ""}</b>
                      {" - "}
                      {getDateStringCWM2(item.date)}
                    </Stack>
                    <span>Agent: {item.agent && item.agent.name}</span>
                    <span>{item.comments && item.comments}</span>
                  </Stack>
                </Card>
              );
            })}
          </div>
        )}
      </Card>
    </Popover>
  );
}
