import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import { Card, Alert, Stack, TextField, Typography, MenuItem } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPatch } from "src/utils/data-fetch";
import {
  AddCommentRounded,
  CorporateFareRounded,
  EmailRounded,
  LocationCityRounded,
  PersonRounded,
  PhoneRounded,
} from "@mui/icons-material";

export default function EditPartyModal({ handleM1Close, party }) {
  const [error, setError] = useState();

  const { getAccessToken, activeFranchise } = useAuth();

  const approveNow = async (values) => {
    let up = {
      name: values.name,
      email: values.email,
      address: values.address,
      owner: values.owner,
      poc: values.poc,
      poc_phone: values.poc_phone,
      gst: values.gst,
      comments: values.comments,
      active: values.active,
    };

    console.log(up);

    const ret = await dataPatch(
      `/api/extrasales/mgract/users/user/${party?._id}/update`,
      await getAccessToken(),
      up,
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    name: Yup.string().optional("Name is optional"),
    email: Yup.string().email("Enter a valid email"),
    address: Yup.string().optional("Address is optional"),
    owner: Yup.string().optional("Owner is optional"),
    poc: Yup.string().optional("POC is optional"),
    poc_phone: Yup.number()
      .optional("Enter 10 digit number")
      .max(9999999999)
      .min(1111111111),
    gst: Yup.string().optional("GST is optional"),
    active: Yup.boolean().optional("Active is optional"),
  });

  const formik = useFormik({
    initialValues: {
      name: party?.name,
      email: party?.email,
      address: party?.bulksale?.address,
      owner: party?.bulksale?.owner,
      poc: party?.bulksale?.poc,
      poc_phone: party?.bulksale?.poc_phone,
      gst: party?.gst,
      comments: party?.comments,
      active: party?.active === "Active" ? true : false,
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          maxHeight: "90vh",
          top: "50%",
          left: "50%",
          overflowY: "auto",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Update Party
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                type="text"
                label="Name"
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                InputProps={{
                  startAdornment: <CorporateFareRounded />,
                }}
              />

              <TextField
                fullWidth
                type="email"
                label="Email"
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                InputProps={{
                  startAdornment: <EmailRounded />,
                }}
              />

              <TextField
                fullWidth
                type="text"
                label="Address"
                {...getFieldProps("address")}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
                InputProps={{
                  startAdornment: <LocationCityRounded />,
                }}
              />

              <TextField
                fullWidth
                type="text"
                label="Owner"
                {...getFieldProps("owner")}
                error={Boolean(touched.owner && errors.owner)}
                helperText={touched.owner && errors.owner}
                InputProps={{
                  startAdornment: <PersonRounded />,
                }}
              />

              <TextField
                fullWidth
                type="text"
                label="Poc"
                {...getFieldProps("poc")}
                error={Boolean(touched.poc && errors.poc)}
                helperText={touched.poc && errors.poc}
                InputProps={{
                  startAdornment: <PersonRounded />,
                }}
              />

              <TextField
                fullWidth
                type="text"
                label="POC Phone"
                {...getFieldProps("poc_phone")}
                error={Boolean(touched.poc_phone && errors.poc_phone)}
                helperText={touched.poc_phone && errors.poc_phone}
                InputProps={{
                  startAdornment: <PhoneRounded />,
                }}
              />

              <TextField
                fullWidth
                type="text"
                label="GST"
                {...getFieldProps("gst")}
                error={Boolean(touched.gst && errors.gst)}
                helperText={touched.gst && errors.gst}
              />

              <TextField
                fullWidth
                type="text"
                label="Comments"
                {...getFieldProps("comments")}
                error={Boolean(touched.comments && errors.comments)}
                helperText={touched.comments && errors.comments}
                InputProps={{
                  startAdornment: <AddCommentRounded />,
                }}
              />

              <TextField
                fullWidth
                select
                label="Active"
                {...getFieldProps("active")}
                error={Boolean(touched.active && errors.active)}
                helperText={touched.active && errors.active}
              >
                <MenuItem value="true">True</MenuItem>
                <MenuItem value="false">False</MenuItem>
              </TextField>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
