import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";
import { getDateStringFullWithTimeSync } from "src/utils/date-time-helper";
import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  Stack,
  Modal,
} from "@mui/material";
import CallDetails from "src/pages/calls/CallDetails";

const TABLE_HEAD = [
  { id: "datetime", label: "Date & Time", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "type", label: "Type", alignRight: false },
];

export default function CallHistoryCard({ customer, defaultList = 10 }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [selectedCall, setSelectedCall] = useState(null);
  const [selCallModalState, setSelCallModalState] = useState(false);

  const [page, setPage] = useState(0);

  const { getAccessToken, activeFranchise } = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleCallDetailsClose = () => {
    setSelCallModalState(false);
    loadData();
  };

  const handleCallDetailsSelected = (callData) => {
    setSelectedCall(callData);
    setSelCallModalState(true);
  };

  const loadData = async () => {
    setLoadingData(true);

    let url = `/api/salesflags/mgract/replies/query?user=${customer._id}&skip=${
      page * 10
    }&limit=${10}&sort=_id&dir=d`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setCustomerData(ret.data);
      console.log(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, [page, customer]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Card>
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            mt={2}
          >
            <Typography variant="h5" gutterBottom>
              Call History
            </Typography>
          </Stack>
          <Card>
            <Container>
              <TableContainer sx={{ minHeight: 100 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.alignRight ? "right" : "left"}
                          sortDirection={false}
                        >
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {customerData.map((item) => {
                      return (
                        <TableRow
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{ mb: 2 }}
                          onClick={() => handleCallDetailsSelected(item)}
                        >
                          <TableCell>
                            {getDateStringFullWithTimeSync(item?.date)}
                          </TableCell>
                          <TableCell>{item?.name}</TableCell>
                          <TableCell>{item?.call_details?.status}</TableCell>
                          <TableCell>
                            {item?.action === "user" ? "INCOMING" : "OUTGOING"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>
          </Card>

          <TablePagination
            component="div"
            count={-1}
            rowsPerPage={defaultList}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[defaultList]}
          />
        </Container>
      </Card>
      <Modal open={selCallModalState} onClose={handleCallDetailsClose}>
        <CallDetails
          callDet={selectedCall}
          handleClose={handleCallDetailsClose}
        />
      </Modal>
    </>
  );
}
