import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Stack,
  Modal,
  IconButton,
} from "@mui/material";

import { ChevronRightRounded, RefreshRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const TABLE_HEAD = [
  { id: "table", label: "Table", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "phone", label: "Phone", alignRight: false },
  { id: "category", label: "Category", alignRight: false },
];

export default function ApprovalLeave() {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [page, setPage] = useState(0);

  const { getAccessToken, activeFranchise } = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = async () => {
    setLoadingData(true);
    let url = `/api/onlinepayouts/mgract/fas/pending?skip=${
      page * 5
    }&limit=${5}&sort=_id&dir=a`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    // setLoadingData(true);
    // handleSearch();
  }, [page, activeFranchise]); // eslint-disable-line react-hooks/exhaustive-deps

  const [selectedAcc, setSelectedAcc] = useState(null);

  const handleEntryClick = (fa) => {
    setSelectedAcc(fa);
  };

  const closeApproveModal = () => {
    setSelectedAcc(null);
    handleSearch();
  };

  return (
    <Card title="Confrim Leave Approval">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h5" gutterBottom>
            Confirm Leave Approval
          </Typography>

          {loadingData === true && <CircularProgress color="secondary" />}

          <IconButton onClick={handleSearch}>
            <RefreshRounded />
          </IconButton>
        </Stack>
        <div>
          <TableContainer sx={{ minHeight: 100 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.alignRight ? "right" : "left"}
                      sortDirection={false}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {customerData.length > 0 && (
                <TableBody>
                  {customerData.map((item) => {
                    return (
                      <TableRow hover onClick={() => handleEntryClick(item)}>
                        <TableCell>
                          <b>{item.table && item.table.toUpperCase()}</b>
                        </TableCell>
                        <TableCell
                          onClick={(e) => {
                            if (item.table == "farmer") {
                            }
                          }}
                        >
                          {(item.farmer && item.farmer.name) ||
                            (item.vendor && item.vendor.name) ||
                            "-"}
                        </TableCell>
                        <TableCell>
                          {(item.farmer && item.farmer.phone) ||
                            (item.vendor && item.vendor.phone) ||
                            "-"}
                        </TableCell>
                        <TableCell>
                          {item.account_type && item.account_type.toUpperCase()}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={-1}
            rowsPerPage={5}
            page={page}
            rowsPerPageOptions={[5]}
            onPageChange={handleChangePage}
          />
        </div>
      </Container>
    </Card>
  );
}
