import { useEffect, useState } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";
import {
  getDateStringFullWithTimeSync,
  getMonthStartAndEndSync,
} from "src/utils/date-time-helper";

import {
  CircularProgress,
  Container,
  Drawer,
  Stack,
  Typography,
} from "@mui/material";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
} from "@mui/material";
import { MonthSelector } from "src/utils/date-selector";

export default function ExpensesDetailsDrawer({
  open,
  onClose,
  expense,
  selectedMonth,
  updateMonth,
}) {
  const { getAccessToken, activeFranchise } = useAuth();

  const [allData, setAllData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const intialLoad = async () => {
    setLoadingData(true);

    const { start, end } = getMonthStartAndEndSync(selectedMonth);

    const url = `/api/expenses/expenses/mgract/query?date_gt=${start}&date_lt=${end}&category=${expense._id}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setAllData(ret.data);
    } else {
      setAllData([]);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    intialLoad();
  }, [expense, activeFranchise, selectedMonth]);

  let tot = 0;
  allData.forEach((e) => (tot += e.amount));

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Container sx={{ py: 2, px: 1 }}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h6" component="div" sx={{ p: 2 }}>
            {expense.category} (₹ {tot})
          </Typography>

          {loadingData && <CircularProgress />}

          <MonthSelector value={selectedMonth} onChange={updateMonth} />
        </Stack>
        <Table sx={{ minWidth: 650 }}>
          <TableContainer component={Paper}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Amount</b>
                </TableCell>
                <TableCell>
                  <b>Method</b>
                </TableCell>
                <TableCell>
                  <b>Date</b>
                </TableCell>
                <TableCell>
                  <b>Comment</b>
                </TableCell>
                <TableCell>
                  <b>Status</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <b>₹ {item?.amount}</b>
                  </TableCell>
                  <TableCell>{item?.method?.toUpperCase()}</TableCell>
                  <TableCell>
                    {getDateStringFullWithTimeSync(item.date)}
                  </TableCell>
                  <TableCell>{item?.comments}</TableCell>
                  <TableCell
                    style={{
                      color: item?.status === "success" ? "green" : "red",
                    }}
                  >
                    <b>{item?.status?.toUpperCase()}</b>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>
        </Table>
      </Container>
    </Drawer>
  );
}
