import { useState, forwardRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
  Container,
  Typography,
  Box,
  Grid,
  Tab,
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Stack,
  Modal,
  Button,
} from "@mui/material";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Page from "src/components/Page";
import CustomerProfileSection from "./info/CustomerProfileSection";
import { CustomerMilkHistorySection } from "./CustomerMilkHistorySection";
import CustomerTxnsPaginated from "./CustomerTxnsPaginated";
import CustomerOrders from "./CustomerOrders";
import CustomerReferralSection from "./info/CustomerReferralSection";
import CustomerCalendarSection from "./CustomerCalendarSection";
import ExportCustomerDataSection from "./ExportCustomerDataSection";
import CustomerCallHistorySection from "./CustomerCallHistroySection";
import {
  AddCircleRounded,
  CloseRounded,
  FreeBreakfastRounded,
  SubscriptionsRounded,
} from "@mui/icons-material";
import { SubDateWiseSection } from "./newsub/SubDateWiseSection";
import { SubscriptionListSection } from "./newsub/SubscriptionListSection";
import AddSubModal from "./newsub/AddSubModal";
import BuyOnceModal from "./newsub/BuyOnceModal";
import ClaimSampleModal from "./newsub/ClaimSampleModal";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomerDetailsPage({
  customer,
  handleClose,
  drawerOpen,
}) {
  const [currentMenu, setCurrentMenu] = useState("general");

  const handleTabChange = (event, newValue) => {
    setCurrentMenu(newValue);
  };

  // const _handleKeyDown = (ev) => {
  //   if (ev.key === "1") {
  //     setCurrentMenu("general");
  //   } else if (ev.key === "2") {
  //     setCurrentMenu("schedule");
  //   } else if (ev.key === "3") {
  //     setCurrentMenu("subscriptions");
  //   } else if (ev.key === "4") {
  //     setCurrentMenu("milkhistory");
  //   } else if (ev.key === "5") {
  //     setCurrentMenu("calendar");
  //   } else if (ev.key === "6") {
  //     setCurrentMenu("txns");
  //   } else if (ev.key === "7") {
  //     setCurrentMenu("orders");
  //   } else if (ev.key === "8") {
  //     setCurrentMenu("referrals");
  //   } else if (ev.key === "9") {
  //     setCurrentMenu("export");
  //   } else if (ev.key === "0") {
  //     setCurrentMenu("callhistory");
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("keydown", _handleKeyDown);

  //   return () => {
  //     document.removeEventListener("keydown", _handleKeyDown);
  //   };
  // }, []);

  const [addSubModalState, setAddSubModalState] = useState(false);
  const [addOrderModalState, setAddOrderModalState] = useState(false);
  const [sampleModalState, setSampleModalState] = useState(false);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      scroll="body"
      open={drawerOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            {customer?.name}
          </Typography>

          <Stack direction={"row"} spacing={1} edge="end">
            {["subscriptions", "schedule"].includes(currentMenu) && (
              <Button
                variant="contained"
                color="white"
                startIcon={<FreeBreakfastRounded />}
                onClick={() => setSampleModalState(true)}
              >
                Claim Sample
              </Button>
            )}
            {["subscriptions", "schedule"].includes(currentMenu) && (
              <Button
                variant="contained"
                color="white"
                startIcon={<SubscriptionsRounded />}
                onClick={() => setAddSubModalState(true)}
              >
                Add Sub
              </Button>
            )}
            {["subscriptions", "schedule"].includes(currentMenu) && (
              <Button
                variant="contained"
                color="white"
                startIcon={<AddCircleRounded />}
                onClick={() => setAddOrderModalState(true)}
              >
                Add Delivery
              </Button>
            )}
            <Button variant="contained" color="error" onClick={handleClose}>
              <CloseRounded />
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>

      <Container sx={{ minHeight: "800px" }}>
        <TabContext value={currentMenu}>
          <Box>
            <TabList onChange={handleTabChange}>
              <Tab label="Profile" value="general" {...a11yProps(0)} />
              <Tab label="Schedule" value="schedule" {...a11yProps(1)} />
              <Tab
                label="Subscriptions"
                value="subscriptions"
                {...a11yProps(2)}
              />
              <Tab label="Milk History" value="milkhistory" {...a11yProps(3)} />
              <Tab label="Calendar" value="calendar" {...a11yProps(4)} />
              <Tab label="Txns" value="txns" {...a11yProps(5)} />
              <Tab label="Orders" value="orders" {...a11yProps(6)} />
              <Tab label="Referrals" value="referrals" {...a11yProps(7)} />
              <Tab label="Export" value="export" {...a11yProps(8)} />
              <Tab label="Call History" value="callhistory" {...a11yProps(9)} />
            </TabList>
          </Box>
          <TabPanel value="general">
            {<CustomerProfileSection customer={customer} />}
          </TabPanel>
          <TabPanel value="schedule">
            {<SubDateWiseSection customer={customer} />}
          </TabPanel>
          <TabPanel value="subscriptions">
            {<SubscriptionListSection customer={customer} />}
          </TabPanel>

          <TabPanel value="milkhistory">
            {<CustomerMilkHistorySection customer={customer} />}
          </TabPanel>
          <TabPanel value="calendar">
            {<CustomerCalendarSection customer={customer} />}
          </TabPanel>
          <TabPanel value="txns">
            {<CustomerTxnsPaginated customer={customer} />}
          </TabPanel>
          <TabPanel value="orders">
            {<CustomerOrders customer={customer} />}
          </TabPanel>
          <TabPanel value="referrals">
            {<CustomerReferralSection customer={customer} />}
          </TabPanel>
          <TabPanel value="export">
            {<ExportCustomerDataSection customer={customer} />}
          </TabPanel>
          <TabPanel value="callhistory">
            {<CustomerCallHistorySection customer={customer} />}
          </TabPanel>
        </TabContext>

        <Grid container spacing={3}>
          <Grid item xs={3} md={3} lg={3}></Grid>
        </Grid>
      </Container>

      <Modal open={addSubModalState} onClose={() => setAddSubModalState(false)}>
        <AddSubModal
          handleM1Close={() => setAddSubModalState(false)}
          customer={customer}
        />
      </Modal>

      <Modal open={sampleModalState} onClose={() => setSampleModalState(false)}>
        <ClaimSampleModal
          handleM1Close={() => setSampleModalState(false)}
          customer={customer}
        />
      </Modal>

      <Modal
        open={addOrderModalState}
        onClose={() => setAddOrderModalState(false)}
      >
        <BuyOnceModal
          handleM1Close={() => setAddOrderModalState(false)}
          customer={customer}
        />
      </Modal>
    </Dialog>
  );
}
