import { Line } from "react-chartjs-2";
import { getDateStringCustom, parseDateStringCustom, parseDateStringFull } from "src/utils/date-time-helper";
import Chart from "chart.js/auto";

let labels = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

export default function WeekChartComponent({ prevData, data, prevDate, date }) {
  let data1 = [];
  let data2 = [];

  if (prevData && prevData.dispense) {

    let mapped = {};
    Object.keys(prevData?.dispense)?.forEach((key) => {
      const day = getDateStringCustom(parseDateStringCustom(key,"DD-MM-YYYY"), "ddd")?.toUpperCase();
      mapped[day] = prevData?.dispense[key];
    });

    data1 = labels.map((l) => {
      return mapped[l] || 0;
    });
  }

  if (data && data.dispense) {
    let mapped = {};
    Object.keys(data?.dispense)?.forEach((key) => {
      const day = getDateStringCustom(parseDateStringCustom(key,"DD-MM-YYYY"), "ddd")?.toUpperCase();
      mapped[day] = data?.dispense[key];
    });

    data2 = labels.map((l) => {
      return mapped[l] || 0;
    });
  }

  const graph = {
    labels: labels,
    datasets: [
      {
        label: getDateStringCustom(prevDate, "[Week:] DD MMM"),
        data: data1,
        fill: false,
        borderColor: "rgb(192, 75, 192)",
        tension: 0.2,
      },
      {
        label: getDateStringCustom(date, "[Week:] DD MMM"),
        data: data2,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.2,
      },
    ],
  };

  return (
    <div>
      <Line data={graph} />
    </div>
  );
}
