import React from "react";
import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";

import {
  Card,
  Container,
  Typography,
  CircularProgress,
  Stack,
  CardActionArea,
  Grid,
  CardMedia,
  Divider,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  Button,
  IconButton,
  TextField,
  MenuItem,
  Tooltip,
  Modal,
  Checkbox,
} from "@mui/material";

import {
  getDateStringCustom,
  getDateStringFullWithSlash,
  getDayStartAndEndSync,
  TWENTY4HOURS,
} from "src/utils/date-time-helper";

import Label from "src/pages/crm/Label";
import {
  EditRounded,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
  RefreshRounded,
  UndoRounded,
} from "@mui/icons-material";
import CustomerDetailsPage from "src/pages/home/det/CustomerDetailsPage";
import ModifySubDModal from "src/pages/home/det/newsub/ModifySubDModal";

const TABLE_HEAD = [
  { id: "index", label: "ID", alignRight: false },
  { id: "customer", label: "Customer", alignRight: false },
  { id: "product", label: "Product", alignRight: false },
  { id: "quantity", label: "Quantity", alignRight: false },
  { id: "type", label: "Type", alignRight: false },
  { id: "slot", label: "Slot", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "comments", label: "Comments", alignRight: false },
];

export const MilkCartsScheduleSection = ({ cart }) => {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const [selectedSlot, setSelectedSlot] = useState("all");
  const [selectedStatus, setSelectedStatus] = useState("all");

  let today = Date.now();
  let todayString = getDateStringFullWithSlash(today);

  let [selectedDate, setSelectedDate] = useState(today);
  let selectedDateStr = getDateStringFullWithSlash(selectedDate);

  const [dateListStart, setDateListStart] = useState(today - 2 * TWENTY4HOURS);
  let dates = [];

  for (let i = 0; i < 5; i++) {
    let d = dateListStart + i * TWENTY4HOURS;
    dates.push({
      date: getDateStringFullWithSlash(d),
      day: getDateStringCustom(d, "ddd")?.toUpperCase(),
      millis: d,
    });
  }

  const [selectProduct, setSelectProduct] = useState("all");
  const [productData, setProductData] = useState([]);

  const getProducts = async () => {
    let url = `/api/products/mgract/query?sort=name&dir=a`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setProductData(ret.data);
    }
  };

  useEffect(() => {
    getProducts();
  }, [activeFranchise]);

  const loadData = async () => {
    setLoadingData(true);

    await new Promise((resolve) => setTimeout(resolve, 200));

    let url = `/api/products/mgract/cart/${cart._id}/schedule?date=${selectedDate}`;

    if (selectedSlot !== "all") {
      if (selectedSlot === "m") {
        url += `&slots=0,1,2,3`;
      } else if (selectedSlot === "e") {
        url += `&slots=4,5`;
      } else {
        url += `&slot=${selectedSlot}`;
      }
    }

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCheckedRows([]);
      setCustomerData(ret.data);
    } else {
      alert(ret.message);
      setCustomerData([]);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    loadData();
  }, [selectedDate, selectedSlot]); // eslint-disable-line react-hooks/exhaustive-deps

  let showData =
    selectedStatus === "all" && selectProduct === "all"
      ? customerData
      : selectedStatus === "all"
      ? customerData.filter((d) => d?.product._id === selectProduct)
      : selectProduct === "all"
      ? customerData.filter((d) => d?.status === selectedStatus)
      : customerData.filter(
          (d) =>
            d?.status === selectedStatus && d?.product._id === selectProduct
        );

  let productSummary = {};
  let statusSummary = {};
  showData.forEach((data) => {
    if (productSummary[data.product_string]) {
      productSummary[data.product_string]["q"] += data?.quantity || 0;
    } else {
      productSummary[data.product_string] = {
        q: data?.quantity || 0,
        u: data?.product?.price_unit?.substring(1) || "units",
      };
    }

    if (statusSummary[data.status]) {
      statusSummary[data.status].qty += data?.quantity || 0;
      statusSummary[data.status].order += 1;
    } else {
      statusSummary[data.status] = {
        order: 1,
        qty: data?.quantity || 0,
      };
    }
  });

  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [modifyModalState, setModifyModalState] = useState(null);

  const todayStart = getDayStartAndEndSync(Date.now()).start;

  const [checkedRows, setCheckedRows] = useState([]);

  return (
    <Container>
      <Card
        sx={{ mb: 2, boxShadow: 0, pl: 0, pr: 1, py: 2 }}
        variant="outlined"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <IconButton
            size="large"
            color="black"
            onClick={() => setDateListStart((prev) => prev - TWENTY4HOURS)}
          >
            <KeyboardArrowLeftRounded />
          </IconButton>
          {dates.map((singleDay, index) => (
            <CardDate
              singleDay={singleDay}
              selectedDay={selectedDateStr}
              selectNew={(d) => {
                setSelectedDate(d);
              }}
              todayString={todayString}
            />
          ))}
          <IconButton
            size="large"
            color="black"
            onClick={() => setDateListStart((prev) => prev + TWENTY4HOURS)}
          >
            <KeyboardArrowRightRounded />
          </IconButton>

          <TextField
            select
            id="prd-select"
            value={selectProduct}
            onChange={(e) => {
              setSelectProduct(e.target.value);
            }}
            label="Prd"
            SelectProps={{
              style: { color: selectProduct === "all" ? "black" : "red" },
            }}
          >
            <MenuItem value={"all"}>ALL</MenuItem>
            {productData.map((prd) => (
              <MenuItem value={prd._id}>{prd.name}</MenuItem>
            ))}
          </TextField>

          <TextField
            select
            id="app-select"
            value={selectedSlot}
            onChange={(e) => {
              setSelectedSlot(e.target.value);
            }}
            label="Slot"
          >
            <MenuItem value={"all"}>ALL</MenuItem>
            <MenuItem value={"m"}>MOR</MenuItem>
            <MenuItem value={"e"}>EVE</MenuItem>
            <MenuItem value={"1"}>5AM - 7AM</MenuItem>
            <MenuItem value={"2"}>7AM - 9AM</MenuItem>
            <MenuItem value={"3"}>9AM - 11AM</MenuItem>
            <MenuItem value={"4"}>5PM - 7PM</MenuItem>
            <MenuItem value={"5"}>7PM - 9PM</MenuItem>
          </TextField>

          <TextField
            select
            id="status-select"
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.target.value);
            }}
            label="Status"
          >
            <MenuItem value={"all"}>ALL</MenuItem>
            <MenuItem value={"pending"}>Pending</MenuItem>
            <MenuItem value={"delivered"}>Delivered</MenuItem>
            <MenuItem value={"paused"}>Paused</MenuItem>
            <MenuItem value={"unavailable"}>Unavailable</MenuItem>
            <MenuItem value={"lowbalance"}>Low Balance</MenuItem>
            <MenuItem value={"refused"}>Refused</MenuItem>
            <MenuItem value={"cancelled"}>Cancelled</MenuItem>
          </TextField>

          {(loadingData === true && <CircularProgress color="secondary" />) || (
            <IconButton
              onClick={() => {
                loadData();
              }}
            >
              <RefreshRounded />
            </IconButton>
          )}
        </Stack>
      </Card>

      {showData.length !== 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CardProductSummary productSummary={productSummary} />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardStatusSummary
              statusSummary={statusSummary}
              setStatus={setSelectedStatus}
            />
          </Grid>
        </Grid>
      )}

      <TableContainer sx={{ minHeight: 100, mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={
                    showData?.length > 0 &&
                    checkedRows?.length === showData?.length
                  }
                  onClick={(e) => {
                    if (e.target.checked === true) {
                      setCheckedRows(showData.map((item) => item._id));
                    } else {
                      setCheckedRows([]);
                    }
                  }}
                />
              </TableCell>
              {TABLE_HEAD.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.alignRight ? "right" : "left"}
                  sortDirection={false}
                >
                  <b>{headCell.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {showData?.length > 0 &&
              showData.map((data, index) => (
                <TableRow key={index}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={data?._id && checkedRows.includes(data._id)}
                      onChange={(e) => {
                        if (data._id) {
                          if (e.target.checked === true) {
                            setCheckedRows([...checkedRows, data._id]);
                          } else {
                            setCheckedRows(
                              checkedRows.filter((id) => id !== data._id)
                            );
                          }
                        }
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </TableCell>

                  <TableCell>{index + 1}</TableCell>
                  <TableCell onClick={() => setSelectedCustomer(data.user)}>
                    {data?.user?.name}
                  </TableCell>
                  <TableCell>{data?.product_string}</TableCell>
                  <TableCell>
                    {data.quantity}{" "}
                    {data?.product?.price_unit?.substring(1) || "Kg"}
                  </TableCell>
                  <TableCell>
                    <Label
                      variant="filled"
                      color={(data.subscription && "success") || "info"}
                    >
                      {(data.subscription && "SUB") || "ORDER"}
                    </Label>
                  </TableCell>
                  <TableCell>
                    {data?.slot === 1
                      ? "5AM - 7AM"
                      : data?.slot === 2
                      ? "7AM - 9AM"
                      : data?.slot === 3
                      ? "9AM - 11AM"
                      : data?.slot === 4
                      ? "5PM - 7PM"
                      : data?.slot === 5
                      ? "7PM - 9PM"
                      : "ANYTIME"}
                  </TableCell>
                  <TableCell>
                    <Label
                      variant="filled"
                      color={
                        data.status === "pending"
                          ? "warning"
                          : data.status === "delivered"
                          ? "success"
                          : data.status === "paused"
                          ? "info"
                          : "error"
                      }
                    >
                      {data.status?.toUpperCase()}
                    </Label>
                  </TableCell>

                  <Tooltip title={data.comments} arrow placement="right">
                    <TableCell>
                      {data?.comments?.substring(0, 8) || "-"}
                    </TableCell>
                  </Tooltip>

                  {["pending", "paused"].includes(data?.status) &&
                    data.date >= todayStart && (
                      <TableCell>
                        <Button
                          sx={{
                            boxShadow: 1,
                            alignItems: "center",
                            borderColor: "error.main",
                          }}
                          variant="outlined"
                          color={"error"}
                          onClick={(e) => {
                            e.stopPropagation();
                            setModifyModalState(data);
                          }}
                        >
                          <EditRounded />
                        </Button>
                      </TableCell>
                    )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedCustomer && (
        <CustomerDetailsPage
          customer={selectedCustomer}
          drawerOpen={selectedCustomer != null}
          handleClose={() => setSelectedCustomer(null)}
        />
      )}

      {modifyModalState && (
        <Modal open={true} onClose={() => setModifyModalState(null)}>
          <ModifySubDModal
            currentSub={modifyModalState}
            handleM1Close={() => {
              setModifyModalState(null);
              loadData();
            }}
          />
        </Modal>
      )}
    </Container>
  );
};

const CardDate = ({ singleDay, selectedDay, selectNew, todayString }) => {
  return (
    <Card
      sx={{
        backgroundColor:
          selectedDay === singleDay?.date ? "primary.main" : "#fff",
        color: selectedDay === singleDay?.date ? "#fff" : "#000",
        flexGrow: 1,
        boxShadow: 2,
      }}
    >
      <CardActionArea sx={{ p: 1 }} onClick={() => selectNew(singleDay.millis)}>
        <Typography gutterBottom align="center" variant="subtitle1">
          <b>{singleDay?.date?.substring(0, 5)}</b>
        </Typography>
        <Typography variant="body2" align="center">
          {singleDay?.date === todayString ? (
            <span style={{ color: "red" }}>
              <b>TODAY</b>
            </span>
          ) : (
            singleDay?.day
          )}
        </Typography>
      </CardActionArea>
    </Card>
  );
};

const CardProductSummary = ({ productSummary }) => {
  return (
    <Card sx={{ mt: 1, mb: 1, p: 1, boxShadow: 0 }} variant="outlined">
      {Object.keys(productSummary).map((key) => (
        <TableRow key={key} hover>
          <TableCell sx={{ borderBottom: "none" }}>
            <b>{key}</b>
          </TableCell>
          <TableCell sx={{ borderBottom: "none" }}>
            {productSummary[key]["q"]} {productSummary[key]["u"]}
          </TableCell>
        </TableRow>
      ))}
    </Card>
  );
};

const CardStatusSummary = ({ statusSummary, setStatus }) => {
  return (
    <Card sx={{ mt: 1, mb: 1, p: 1, boxShadow: 0 }} variant="outlined">
      {Object.keys(statusSummary).map((key) => (
        <TableRow key={key} hover>
          <TableCell
            sx={{ borderBottom: "none" }}
            onClick={() => setStatus(key)}
          >
            <Label
              variant="filled"
              color={
                key === "pending"
                  ? "warning"
                  : key === "delivered"
                  ? "success"
                  : key === "paused"
                  ? "info"
                  : "error"
              }
            >
              {key?.toUpperCase()}
            </Label>
          </TableCell>
          <TableCell sx={{ borderBottom: "none" }}>
            {statusSummary[key].order} Orders ({statusSummary[key].qty} Units)
          </TableCell>
        </TableRow>
      ))}

      <IconButton
        sx={{ position: "absolute", right: "10px", top: "10px" }}
        onClick={() => setStatus("all")}
      >
        <UndoRounded />
      </IconButton>
    </Card>
  );
};
