import { CalendarViewDay } from "@mui/icons-material";
import { Button, Card, Stack, Typography } from "@mui/material";
import { useSnackbar } from "src/contexts/SnackbarContext";

const { useState } = require("react");
const { useAuth } = require("src/contexts/AuthContext");
const { dataPatch } = require("src/utils/data-fetch");
const { DateSelector } = require("src/utils/date-selector");

export const ModifySubDDateModal = ({ subd, handleM1Close }) => {
  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(subd.date);

  const modifySubscription = async () => {
    setLoading(true);

    const url = `/api/products/mgract/subdelivery/${subd._id}/date`;
    const ret = await dataPatch(
      url,
      await getAccessToken(),
      { date: date },
      activeFranchise
    );

    if (ret.success === true) {
      showSnackbar(ret.message || "Subscription modified successfully", "success");
      handleM1Close();
    } else {
      showSnackbar(ret.message || "Failed to modify subscription", "error");
    }

    setLoading(false);
  };

  return (
    <Card
      sx={{
        width: "100%",
        maxWidth: "450px",
        padding: "24px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 9999,
        boxShadow: 24,
        borderRadius: 2,
      }}
    >
      <Typography variant="h5" gutterBottom align="center" sx={{ mb: 3 }}>
        Modify Delivery Date
      </Typography>

      <Stack spacing={3}>
        <DateSelector
          value={date}
          onChange={setDate}
          sx={{
            border: "1px solid lightgray",
            padding: "8px",
            borderRadius: "8px",
          }}
        />

        <Button
          variant="contained"
          color="primary"
          startIcon={<CalendarViewDay />}
          sx={{
            padding: "12px",
            fontSize: "16px",
            borderRadius: "8px",
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
          onClick={modifySubscription}
        >
          Change Date
        </Button>
      </Stack>
    </Card>
  );
};
