import PropTypes from "prop-types";

// material
import { styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, Card } from "@mui/material";

import { useAuth } from "../../contexts/AuthContext";

// ----------------------------------------------------------------------

const APPBAR_DESKTOP = 40;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: "white",
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_DESKTOP,
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({}) {
  const { currentManager, activeFranchise, handleFranchiseChange } = useAuth();

  return (
    <RootStyle>
      <ToolbarStyle>
        <Box
          sx={{
            overflow: "auto",
            scrollbarWidth: "none",
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {currentManager.access &&
              currentManager.access.map((item) => {
                return (
                  <Box>
                    <Card
                      variant="outlined"
                      sx={{
                        boxShadow: "none",
                        py: 1,
                        px: 2,
                        background:
                          item.fr._id === activeFranchise ? "#003f5c" : "white",
                        color:
                          item.fr._id === activeFranchise
                            ? "white.main"
                            : "black.main",
                        fontSize: "75%",
                      }}
                      onClick={(event) => {
                        handleFranchiseChange(item.fr._id);
                      }}
                    >
                      {item.fr.name.includes(" ")
                        ? item.fr.name.split(" ")
                        : item.fr.name.includes("-")
                        ? item.fr.name.split("-").join("_")
                        : item.fr.name}
                    </Card>
                  </Box>
                );
              })}
          </Stack>
        </Box>
      </ToolbarStyle>
    </RootStyle>
  );
}
