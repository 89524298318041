import { Card, Box, CircularProgress } from "@mui/material";

import { useAuth } from "src/contexts/AuthContext";

import Popover from "@mui/material/Popover";
import { useEffect, useState } from "react";
import { dataFetch } from "src/utils/data-fetch";

export default function FarmerDetailPopover({ dataId, anchor, handleUmClose }) {
  const { getAccessToken, activeFranchise } = useAuth();

  const [detail, setDetail] = useState();
  const [loading, setLoading] = useState(false);

  const loadCurrentData = async () => {
    setLoading(true);

    let url = `/api/farmers/mgract/farmer/${dataId}`;
    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setDetail(ret.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (dataId) loadCurrentData();
  }, [dataId]);

  return (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: "none",
      }}
      open={Boolean(anchor)}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={() => handleUmClose}
      hideBackdrop={false}
    >
      <Card sx={{ p: 2 }}>
        {loading === true && <CircularProgress />}
        {loading === false && detail && (
          <div>
            <h2 class="firstHeading">
              <font color={detail.active === true ? "black" : "gray"}>
                {detail.name}
                <Box sx={{ flexGrow: 1 }} />
              </font>
            </h2>
            <font color="blue">{detail.phone}</font>

            <div style={{ marginTop: "10px" }}>
              <b>
                Balance: ₹{" "}
                <font
                  color={
                    detail.wallet.balance > 0
                      ? "green"
                      : detail.wallet.balance < 0
                      ? "red"
                      : "blue"
                  }
                >
                  {detail.wallet.balance.toFixed(2)}
                </font>
              </b>
              {"   "}(Limit: ₹ {detail.credit_limit.toFixed(2)})
            </div>

            <div style={{ marginTop: "10px" }}>
              <b>Address: </b>
              <font color="blue">{detail.address_det}</font>
            </div>

            {detail.center && (
              <div style={{ marginTop: "10px" }}>
                <b>Center: </b>
                <font color="blue">
                  {detail.center["name"]} ({detail.center["phone"]})
                </font>
              </div>
            )}

            {detail.franchisee && (
              <div style={{ marginTop: "10px" }}>
                <b>Franchisee: </b>
                <font color="blue">
                  {detail.franchisee["name"]} ({detail.franchisee["phone"]})
                </font>
              </div>
            )}
          </div>
        )}
      </Card>
    </Popover>
  );
}
