import { useState } from "react";

import { Edit as EditIcon } from "@mui/icons-material";

import {
  Card,
  Typography,
  Box,
  Grid,
  Stack,
  CircularProgress,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Modal,
} from "@mui/material";

import { getDateStringFullWithMonthSync } from "src/utils/date-time-helper";
import EditCustomerModal from "../home/det/info/EditCustomerModal";
import EditPartyModal from "./EditPartyInfo";

export default function PartyInfoCard({ party, loadingData, reload }) {
  const [modalOpen, setModalOpen] = useState(false);
  
  const closeModalFn = () => {
    setModalOpen(false);
    reload();
  };

  const openModalFn = async () => {
    setModalOpen(true);
  };

  return (
    <>
      <Card>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: 3, mx: 3 }}
        >
          <Typography variant="h5" gutterBottom>
            Details
          </Typography>

          {loadingData === true && <CircularProgress />}
          <Button
            variant="outlined"
            color="error"
            startIcon={<EditIcon />}
            sx={{ mr: 1 }}
            onClick={openModalFn}
          >
            Edit
          </Button>
        </Stack>

        {party && (
          <Grid container columnSpacing={1} sx={{ pl: 2, pr: 2 }}>
            <Grid item xs={12} md={6} lg={6}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Name:</b>
                  </TableCell>
                  <TableCell>{party?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Owner:</b>
                  </TableCell>
                  <TableCell>{party?.bulksale?.owner || "-"}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>Address:</b>
                  </TableCell>
                  <TableCell>
                    {party?.bulksale && party?.bulksale?.address}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>Phone:</b>
                  </TableCell>
                  <TableCell>{party?.phone || "-"}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>GST:</b>
                  </TableCell>
                  <TableCell>
                    {party?.gst || "-"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Created By:</b>
                  </TableCell>
                  <TableCell>
                    {(party?.created_by && party?.created_by?.name) || "SELF"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Created On:</b>
                  </TableCell>
                  <TableCell>
                    {getDateStringFullWithMonthSync(
                      parseInt(party._id.substring(0, 8), 16) * 1000
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>POC Name:</b>
                  </TableCell>
                  <TableCell>{party?.bulksale?.poc || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>POC Number:</b>
                  </TableCell>
                  <TableCell>{party?.bulksale?.poc_phone}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Email:</b>
                  </TableCell>
                  <TableCell>{party?.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Active:</b>
                  </TableCell>
                  <TableCell>{party?.active}</TableCell>
                </TableRow>
              </TableBody>
            </Grid>
          </Grid>
        )}

        <Stack direction="row">
          <Box sx={{ flexGrow: 1 }} />
        </Stack>
      </Card>
      <Modal open={modalOpen} onClose={closeModalFn}>
        <EditPartyModal handleM1Close={closeModalFn} party={party} />
      </Modal>
    </>
  );
}
