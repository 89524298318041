import { Bar, Line } from "react-chartjs-2";
import { getDateStringCustom } from "src/utils/date-time-helper";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function CartMilkoutChartComp({ data }) {
  const labels = Object.keys(data).sort();

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 3,
    plugins: {
      legend: {
        display:false
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Milk sold per hour",
        },
      },
    },
  };

  const graph = {
    labels: labels,
    datasets: [
      {
        label: "Milk",
        data: labels.map((key) => data[key] / 1000),
        backgroundColor: "rgba(225, 173, 1, 1)",
      },
    ],
  };

  return (
    <div>
      <Bar data={graph} options={options} />
    </div>
  );
}
