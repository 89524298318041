import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import { Card, Alert, Stack, TextField, Typography } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";

import { useSnackbar } from "src/contexts/SnackbarContext";

function randomString(length, chars) {
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

export default function AddLink({ handleM1Close }) {
  const [error, setError] = useState();

  const [loading, setLoading] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const approveNow = async (values) => {
    setLoading(true);

    const upd = {
      key: values.key,
      target: values.target,
    };

    if (values.description && values.description.length > 0) {
      upd.description = values.description;
    }
    if (values.campaign && values.campaign.length > 0) {
      upd.campaign = values.campaign;
    }

    const ret = await dataPost(
      `/api/campaigns/mgract/shortlinks/add`,
      await getAccessToken(),
      upd,
      activeFranchise
    );
    if (ret.success === true) {
      showSnackbar(ret.message || "Link added.", "success");
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }

    setLoading(false);
  };

  const DataSchema = Yup.object().shape({
    key: Yup.string()
      .required("Key is required")
      .min(3, "Minimum 3 characters"),
    target: Yup.string().required("Target is required"),
    description: Yup.string(),
    campaign: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      key: randomString(3, "123456789abcdefghjkmnpqrstuvwxyz"),
      target: "",
      description: "",
      campaign: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Add Short Link Tracker
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                type="text"
                label="Short Key"
                {...getFieldProps("key")}
                error={Boolean(touched.key && errors.key)}
                helperText={touched.key && errors.key}
              />

              <TextField
                fullWidth
                type="text"
                label="Target URL"
                {...getFieldProps("target")}
                error={Boolean(touched.target && errors.target)}
                helperText={touched.target && errors.target}
              />

              <TextField
                fullWidth
                type="text"
                label="Campaign Title (optional)"
                {...getFieldProps("campaign")}
                error={Boolean(touched.campaign && errors.campaign)}
                helperText={touched.campaign && errors.campaign}
              />

              <TextField
                fullWidth
                type="text"
                multiline
                label="Campaign description (optional)"
                {...getFieldProps("description")}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
