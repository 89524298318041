import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import { Card, Alert, Stack, TextField, Typography, Grid } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPatch } from "src/utils/data-fetch";

export default function EditManagerModal({ handleM1Close, apartment }) {
  const [error, setError] = useState(null); // Initialize error as null

  const { getAccessToken, activeFranchise } = useAuth();

  const updateData = async (values) => {
    try {
      const up = {
        manager: {
          name: values.name,
          phone: values.phone,
        },
      };

      const response = await dataPatch(
        `/api/apartments/mgract/appt/${apartment._id}`,
        await getAccessToken(),
        up,
        activeFranchise
      );

      if (response.success) {
        handleM1Close();
      } else {
        setError(response.message + " - " + response.code);
      }
    } catch (error) {}
  };

  const DataSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone: Yup.string().required("Phone number is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: apartment?.manager?.name || "",
      phone: apartment?.manager?.phone|| "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await updateData(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Card
        sx={{
          p: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h4" align="center">
            Edit Manager Details
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  label="Name"
                  {...getFieldProps("name")}
                  helperText={touched?.name && errors.name}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  {...getFieldProps("phone")}
                  helperText={touched?.phone && errors.phone}
                />
              </Grid>
            </Grid>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
