import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "src/contexts/AuthContext";

import { styled } from "@mui/material/styles";
import { dataFetch } from "src/utils/data-fetch";

import { SearchRounded } from "@mui/icons-material";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import {
  Card,
  Container,
  Table,
  Box,
  OutlinedInput,
  InputAdornment,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  CircularProgress,
  Stack,
} from "@mui/material";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "uin", label: "UIN", alignRight: true },
];

export default function EmpSearch({ onEmpSelected }) {
  const [field, setField] = useState("name");
  const [mgrData, setMgrData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [selectedField, setSelectedField] = useState("name");

  const { getAccessToken, activeFranchise } = useAuth();
  let timer = "";

  const handleChangeQuery = (event) => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      setMgrData([]);
      setPage(0);
      setSearch(event.target.value);
    }, 1000);
  };

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/employees/mgract/v2/query?status=active&sort=name&dir=a`;

    if (search && search.length > 0)
      url += `&search=${search}&field=${selectedField}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setMgrData(ret.data);
      console.log("employee detailss", ret.data);
    } else {
      console.log("error in fetching data");
    }

    setLoadingData(false);
  };

  useEffect(() => {
    handleSearch();
  }, [search, activeFranchise, selectedField]);

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
        mt={2}
        spacing={2}
      >
        <SearchStyle
          placeholder="Search Employee..."
          onChange={handleChangeQuery}
          startAdornment={
            <InputAdornment position="start">
              <Box sx={{ color: "text.disabled" }}>
                <SearchRounded />
              </Box>
            </InputAdornment>
          }
          endAdornment={
            loadingData === true && <CircularProgress color="secondary" />
          }
          sx={{ mt: 4, mb: 4 }}
          autoFocus={true}
        />

        <Select
          id="field-select"
          value={field}
          label="Search In"
          onChange={(e) => setField(e.target.value)}
        >
          <MenuItem value={"name"}>Name</MenuItem>
          <MenuItem value={"phone"}>Phone</MenuItem>
        </Select>
      </Stack>
      {mgrData.length > 0 && (
        <div>
          <TableContainer
            sx={{
              minHeight: 100,
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.alignRight ? "right" : "left"}
                      sortDirection={false}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow
                  hover
                  onClick={() => onEmpSelected({ _id: null })}
                  style={{
                    backgroundColor: "#ddd",
                  }}
                >
                  <TableCell>
                    <b>{"Remove Assigned Emp"}</b>
                  </TableCell>
                  <TableCell>{"-"}</TableCell>
                </TableRow>
                {mgrData.map((item) => {
                  return (
                    <TableRow
                      hover
                      onClick={() => onEmpSelected(item)}
                      style={{
                        backgroundColor:
                          item.active === false ? "#ddd" : "white",
                      }}
                    >
                      <TableCell>
                        <b>{item ? item.name : ""}</b>
                      </TableCell>
                      <TableCell>{item && item.uin ? item.uin : "-"}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </Container>
  );
}
