import {
  AppBar,
  Container,
  Dialog,
  IconButton,
  Slide,
  Stack,
  Tab,
  Toolbar,
  Typography,
} from "@mui/material";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState, forwardRef } from "react";

import { CloseRounded } from "@mui/icons-material";
import CampaignProfile from "./CampaignProfile";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CampaignDetailsPage({
  campaign,
  handleClose,
  drawerOpen,
}) {
  const [currentMenu, setCurrentMenu] = useState("profile");

  const handleTabChange = (event, newValue) => {
    setCurrentMenu(newValue);
  };

  const [custDetails, setCustDetails] = useState(null);
  const [custDetailsState, setCustDetailsState] = useState(false);

  return (
    <Dialog
      fullScreen
      open={drawerOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            {campaign?.name}
          </Typography>
          <IconButton edge="end" color="black" onClick={handleClose}>
            <CloseRounded />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Container>
        <TabContext value={currentMenu}>
          <TabList onChange={handleTabChange}>
            <Tab label="Profile" value="profile" {...a11yProps(0)} />
          </TabList>

          <TabPanel value="profile">
            <CampaignProfile campaign={campaign} />
          </TabPanel>
        </TabContext>
      </Container>
    </Dialog>
  );
}
