import {
  Grid,
  Container,
  Button,
  Tab,
  Box,
  Modal,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Card,
  Stack,
  Typography,
  Table,
  Tooltip,
  Avatar,
} from "@mui/material";

import { useEffect, useState } from "react";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InternalTxnsByAccount from "./InternalTxnsByAccount";
import AddTxnModal from "../txns/AddTxnModal";
import InternalTxnsByAccountPaginated from "./InternalTxnsByAccountPaginated";
import AccBalanceCard from "./AccBalanceCard";
import ExportLedgerSection from "./ExportLedgerSection";
import MakeFullScreen from "src/components/MakeFullScreen";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";
import { useSnackbar } from "src/contexts/SnackbarContext";
import { getDateStringFullWithTimeSync } from "src/utils/date-time-helper";
import Label from "src/pages/crm/Label";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function InternalAccDetailsPage({ inpAcc, open, onClose }) {
  const [currentMenu, setCurrentMenu] = useState("general");

  const handleTabChange = (event, newValue) => {
    setCurrentMenu(newValue);
  };

  const [addTxnModalOpen, setAddTxnModalOpen] = useState(null);

  const addTxnClicked = (c) => {
    setAddTxnModalOpen(c);
  };

  const closeAddTxnModal = () => {
    setAddTxnModalOpen(null);
  };

  const [currentData, setCurrentData] = useState();
  const [loadingData, setLoadingData] = useState();

  const { getAccessToken } = useAuth();
  const { showSnackbar } = useSnackbar();

  const load = async (values) => {
    setLoadingData(true);

    const ret = await dataFetch(
      `/api/internalaccs/coreact/acc/${inpAcc._id}`,
      await getAccessToken()
    );
    if (ret.success === true) {
      setCurrentData(ret.data);
    } else {
      showSnackbar(ret.message + " - " + ret.code, "error");
    }
    setLoadingData(false);
  };

  useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [prefillDate, setPrefillDate] = useState(Date.now());

  return (
    <MakeFullScreen
      title={
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <Card sx={{ px: 2, py: 1, backgroundColor: "success.main" }}>
            {inpAcc?.name}
          </Card>
          <span>{inpAcc?.entity?.toUpperCase() || "ACCOUNT"} DETAILS</span>
        </Stack>
      }
      open={open}
      onClose={onClose}
      header={[
        <Button
          variant="contained"
          color="white"
          onClick={() => addTxnClicked("cr")}
        >
          Add Txn CR
        </Button>,
        <Button
          variant="contained"
          color="white"
          onClick={() => addTxnClicked("dr")}
        >
          Add Txn DR
        </Button>,
      ]}
    >
      <Container sx={{ minHeight: 800 }}>
        <TabContext value={currentMenu}>
          <Box>
            <TabList onChange={handleTabChange}>
              <Tab label="Profile" value="general" {...a11yProps(0)} />
              <Tab label="Txns By Date" value="txns" {...a11yProps(1)} />
              <Tab label="Txns List" value="txns2" {...a11yProps(2)} />
            </TabList>
          </Box>
          <TabPanel value="general">
            <InternalAccDetailsCard
              currentData={currentData}
              loadingData={loadingData}
            />
          </TabPanel>

          <TabPanel value="txns">
            <InternalTxnsByAccount
              account={inpAcc}
              setPrefillDate={setPrefillDate}
            />
          </TabPanel>

          <TabPanel value="txns2">
            <InternalTxnsByAccountPaginated account={inpAcc} />
          </TabPanel>
        </TabContext>
        {addTxnModalOpen === "cr" && (
          <Modal open={addTxnModalOpen} onClose={closeAddTxnModal}>
            <AddTxnModal
              handleM1Close={closeAddTxnModal}
              duplicateData={{
                from: inpAcc,
                franchisee: inpAcc.franchisee,
                date: prefillDate + 10 * 60 * 60 * 1000,
              }}
            />
          </Modal>
        )}
        {addTxnModalOpen === "dr" && (
          <Modal open={addTxnModalOpen} onClose={closeAddTxnModal}>
            <AddTxnModal
              handleM1Close={closeAddTxnModal}
              duplicateData={{
                to: inpAcc,
                franchisee: inpAcc.franchisee,
                date: prefillDate + 10 * 60 * 60 * 1000,
              }}
            />
          </Modal>
        )}
      </Container>
    </MakeFullScreen>
  );
}

function InternalAccDetailsCard({ handleM1Close, currentData, loadingData }) {
  if (loadingData === true) {
    return <CircularProgress />;
  } else if (!currentData) {
    return <Typography variant="h5">No Data Found</Typography>;
  } else
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Stack direction={"column"} spacing={1}>
            <BasicDetailsCard
              currentData={currentData}
              loadingData={loadingData}
            />
            {!["ledger", "asset", "expense"].includes(currentData?.entity) && (
              <ContactDetailsCard
                currentData={currentData}
                loadingData={loadingData}
              />
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Stack direction={"column"} spacing={1}>
            {["ledger", "asset", "expense"].includes(currentData?.entity) && (
              <ContactDetailsCard
                currentData={currentData}
                loadingData={loadingData}
              />
            )}
            {currentData?.entity === "employee" && (
              <EmployeeDetailsCard
                currentData={currentData}
                loadingData={loadingData}
              />
            )}
            {currentData?.entity === "center" && (
              <CenterDetailsCard
                currentData={currentData}
                loadingData={loadingData}
              />
            )}
            {currentData?.entity === "manager" && (
              <ManagerLimitsCard
                currentData={currentData}
                loadingData={loadingData}
              />
            )}
            {currentData?.entity === "bulksale" && (
              <B2BDetailsCard
                currentData={currentData}
                loadingData={loadingData}
              />
            )}
            {(currentData?.bank_account?.acc_no ||
              currentData?.bank_account?.upi) && (
              <BankAccountCard
                currentData={currentData}
                loadingData={loadingData}
              />
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Stack direction={"column"} spacing={2}>
            <AccBalanceCard accId={currentData._id} />
            <ExportLedgerSection accId={currentData._id} />
            <ComplianceDetailsCard
              currentData={currentData}
              loadingData={loadingData}
            />
          </Stack>
        </Grid>
      </Grid>
    );
}

function BasicDetailsCard({ currentData, loadingData }) {
  return (
    <Card
      sx={{
        px: 2,
        pt: 2,
        backgroundColor: currentData?.active === true ? "#fff" : "#eee",
        boxShadow: 2,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" gutterBottom>
          Basic Details
        </Typography>
      </Stack>

      <Table>
        <TableRow>
          <TableCell>
            <b>Code:</b>
          </TableCell>
          <TableCell>
            {(currentData?.ext_code && (
              <Label color="primary" variant="filled">
                {currentData.ext_code}
              </Label>
            )) ||
              "-"}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Group:</b>
          </TableCell>
          <TableCell>
            {(currentData?.acc_group && (
              <Label color="primary" variant="outlined">
                {currentData.acc_group}
              </Label>
            )) ||
              "-"}
          </TableCell>
        </TableRow>
        {(currentData?.under && (
          <TableRow>
            <TableCell>
              <b>Under:</b>
            </TableCell>
            <TableCell sx={{ color: "error.main" }}>
              {currentData?.under?.name} ({currentData?.under?.ext_code})
            </TableCell>
          </TableRow>
        )) || (
          <TableRow>
            <TableCell>
              <b>Type:</b>
            </TableCell>
            <TableCell>Primary / Main</TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell>
            <b>Name:</b>
          </TableCell>
          <TableCell>
            <b>{currentData?.name}</b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Entity:</b>
          </TableCell>
          <TableCell>
            <Label color="primary" variant="outlined">
              {currentData?.entity?.toUpperCase() || "-"}
            </Label>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Center:</b>
          </TableCell>
          <TableCell>
            <Label color="primary" variant="ghost">
              {currentData?.franchisee?.name || "-"}
            </Label>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>
            <b>Active:</b>
          </TableCell>
          <TableCell>{currentData?.active === true ? "Yes" : "No"}</TableCell>
        </TableRow>
      </Table>
    </Card>
  );
}

function ContactDetailsCard({ currentData, loadingData }) {
  return (
    <Card sx={{ px: 2, pt: 2, boxShadow: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" gutterBottom>
          Other Details
        </Typography>
      </Stack>

      <Table>
        <TableRow>
          <TableCell>
            <b>Phone:</b>
          </TableCell>
          <TableCell>{currentData?.phone || "-"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Email:</b>
          </TableCell>
          <TableCell>{currentData?.email || "-"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Public:</b>
          </TableCell>
          <TableCell>{currentData?.public === true ? "YES" : "NO"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Created By:</b>
          </TableCell>
          <TableCell>{currentData?.created_by?.name || "-"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Created On:</b>
          </TableCell>
          <TableCell>
            {getDateStringFullWithTimeSync(
              parseInt(currentData?._id.substring(0, 8), 16) * 1000
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Comments:</b>
          </TableCell>
          <TableCell>{currentData?.comments || "-"}</TableCell>
        </TableRow>
      </Table>
    </Card>
  );
}

function ManagerLimitsCard({ currentData, loadingData }) {
  return (
    <Card sx={{ px: 2, pt: 2, boxShadow: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" gutterBottom>
          Manager Details
        </Typography>
      </Stack>

      <Table>
        <TableRow>
          <TableCell>
            <b>Daily Limit:</b>
          </TableCell>
          <TableCell>₹ {currentData?.manager?.max_daily_txn || "0"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Per Txn Limit:</b>
          </TableCell>
          <TableCell>₹ {currentData?.manager?.max_txn || "0"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Calling Reg:</b>
          </TableCell>
          <TableCell>
            {currentData?.manager?.cd_reg === true ? "YES" : "NO"}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Category:</b>
          </TableCell>
          <TableCell>{currentData?.manager?.category?.toUpperCase()}</TableCell>
        </TableRow>
      </Table>
    </Card>
  );
}

function BankAccountCard({ currentData, loadingData }) {
  return (
    <Card sx={{ px: 2, pt: 2, boxShadow: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" gutterBottom>
          Bank Details
        </Typography>
      </Stack>

      <Table>
        <TableRow>
          <TableCell>
            <b>Acc Name:</b>
          </TableCell>
          <TableCell>{currentData?.bank_account?.acc_name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Acc Number:</b>
          </TableCell>
          <TableCell>{currentData?.bank_account?.acc_no}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>IFSC:</b>
          </TableCell>
          <TableCell>{currentData?.bank_account?.ifsc}</TableCell>
        </TableRow>
        {currentData?.bank_account?.upi && (
          <TableRow>
            <TableCell>
              <b>UPI:</b>
            </TableCell>
            <TableCell>{currentData?.bank_account?.upi}</TableCell>
          </TableRow>
        )}
        {currentData?.bank_account?.bank && (
          <TableRow>
            <TableCell>
              <b>Bank:</b>
            </TableCell>
            <TableCell>
              {currentData?.bank_account?.bank}{" "}
              {currentData?.bank_account?.branch}
            </TableCell>
          </TableRow>
        )}
      </Table>
    </Card>
  );
}

function EmployeeDetailsCard({ currentData, loadingData }) {
  return (
    <Card sx={{ boxShadow: 2 }}>
      <img
        src={currentData?.employee_det?.image}
        alt="Employee"
        height={"300px"}
        width={"100%"}
        style={{ objectFit: "cover" }}
      />

      <Table sx={{ px: 2 }}>
        <TableRow>
          <TableCell>
            <b>Payroll Type:</b>
          </TableCell>
          <TableCell>
            {currentData?.employee_det?.payroll_type?.toUpperCase()}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>UIN:</b>
          </TableCell>
          <TableCell>{currentData?.employee_det?.uin}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Address:</b>
          </TableCell>
          <Tooltip title={currentData?.employee_det?.address}>
            <TableCell>{currentData?.employee_det?.address}</TableCell>
          </Tooltip>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Email:</b>
          </TableCell>
          <TableCell>
            {currentData?.employee_det?.email ||
              currentData?.employee_det?.personal_email}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Designation:</b>
          </TableCell>
          <TableCell>{currentData?.employee_det?.designation} </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Status:</b>
          </TableCell>
          <TableCell>
            {currentData?.employee_det?.status?.toUpperCase()}
          </TableCell>
        </TableRow>
      </Table>
    </Card>
  );
}

function ComplianceDetailsCard({ currentData, loadingData }) {
  return (
    <Card sx={{ px: 2, pt: 2, boxShadow: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" gutterBottom>
          Compliance Details
        </Typography>
      </Stack>

      <TableBody>
        <TableRow>
          <TableCell>
            <b>GST:</b>
          </TableCell>
          <TableCell>{currentData?.gst || "-"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>GST Verified:</b>
          </TableCell>
          <TableCell>{currentData?.gst_verified ? "Yes" : "No"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>PAN:</b>
          </TableCell>
          <TableCell>{currentData?.pan || "-"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>MSME Type:</b>
          </TableCell>
          <TableCell>{currentData?.msme_reg || "None"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>MSME No:</b>
          </TableCell>
          <TableCell>{currentData?.udyam || "-"}</TableCell>
        </TableRow>
      </TableBody>
    </Card>
  );
}

function B2BDetailsCard({ currentData, loadingData }) {
  return (
    <Card sx={{ px: 2, pt: 2, boxShadow: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" gutterBottom>
          B2B Customer Details
        </Typography>
      </Stack>

      <TableBody>
        <TableRow>
          <TableCell>
            <b>Address:</b>
          </TableCell>
          <TableCell>{currentData?.bulksale?.address || "-"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Owner:</b>
          </TableCell>
          <TableCell>{currentData?.bulksale?.owner || "-"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>POC:</b>
          </TableCell>
          <TableCell>{currentData?.bulksale?.poc || "-"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>POC Phone:</b>
          </TableCell>
          <TableCell>{currentData?.bulksale?.poc_phone || "-"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Login Phone:</b>
          </TableCell>
          <TableCell>{currentData?.phone || "-"}</TableCell>
        </TableRow>
      </TableBody>
    </Card>
  );
}

function CenterDetailsCard({ currentData, loadingData }) {
  return (
    <Card sx={{ px: 2, pt: 2, boxShadow: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" gutterBottom>
          Collection Center Details
        </Typography>
      </Stack>

      <TableBody>
        <TableRow>
          <TableCell>
            <b>Handler:</b>
          </TableCell>
          <TableCell>{currentData?.center?.handler || "-"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Owned:</b>
          </TableCell>
          <TableCell>
            {currentData?.center?.official === true ? "YES" : "NO"}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Fixed Rate:</b>
          </TableCell>
          <TableCell>
            {currentData?.center?.fixed_rate
              ? `₹ ${currentData?.center?.fixed_rate}`
              : "NO"}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Extra Rate:</b>
          </TableCell>
          <TableCell>
            {currentData?.center?.extra_rate
              ? `₹ ${currentData?.center?.extra_rate}`
              : "NO"}
          </TableCell>
        </TableRow>
      </TableBody>
    </Card>
  );
}
