import {
  Container,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableHead,
  TableContainer,
} from "@mui/material";

import { useEffect, useState } from "react";

import MakeFullScreen from "src/components/MakeFullScreen";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";
import { useSnackbar } from "src/contexts/SnackbarContext";

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "code", label: "Code", alignRight: false },
];

export default function AccountGroups({ open, onClose }) {
  const [currentData, setCurrentData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const { getAccessToken } = useAuth();
  const { showSnackbar } = useSnackbar();

  const load = async (values) => {
    setLoadingData(true);

    const ret = await dataFetch(
      `/api/internalaccs/coreact/groups/query`,
      await getAccessToken()
    );
    if (ret.success === true) {
      setCurrentData(ret.data);
    } else {
      showSnackbar(ret.message + " - " + ret.code, "error");
    }
    setLoadingData(false);
  };

  useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MakeFullScreen title={"Account Groups"} open={open} onClose={onClose}>
      <Container sx={{ minHeight: 600 }}>
        <TableContainer sx={{ minHeight: 100 }}>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.alignRight ? "right" : "left"}
                    sortDirection={false}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {currentData.map((item) => {
                return (
                  <TableRow hover>
                    <TableCell>{item?.name || "--"}</TableCell>
                    <TableCell>{item?.ext_code || "--"}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </MakeFullScreen>
  );
}
