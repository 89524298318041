import {
  Card,
  Typography,
  Stack,
  CircularProgress,
  Avatar,
  IconButton,
} from "@mui/material";
import Label from "../crm/Label";
import { RefreshRounded } from "@mui/icons-material";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";
import { useEffect, useState } from "react";

export default function PartyImageCard({ party, loadingData }) {
  const { getAccessToken, activeFranchise } = useAuth();

  const [data, setData] = useState(party?.balance || 0);
  const [dataLoading, setDataLoading] = useState(false);

  const recalculateBalance = async () => {
    setDataLoading(true);

    const url = `/api/extrasales/mgract/txns/recalculate/${party._id}`;

    const balance = await dataFetch(
      url,
      await getAccessToken(),
      activeFranchise
    );

    if (balance.success === true) {
      setData(balance.data);
    }

    setDataLoading(false);
  };

  useEffect(() => {
    setData(party?.balance || 0);
  }, [party]);

  return (
    <>
      <Card sx={{ pl: 2, pr: 2, minHeight: "350px" }}>
        <Stack direction="column" alignItems="center" pt={5}>
          <Avatar
            sx={{
              width: "200px",
              height: "200px",
            }}
          >
            {party && party.image && <img src={party.image} alt="party" />}
          </Avatar>

          <Typography variant="h6" sx={{ mt: 3 }}>
            {(party && party.name) || "-"}
          </Typography>

          <Stack direction="row" alignItems="center">
            <Typography variant="body1" sx={{ mt: 3 }}>
              Balance:
            </Typography>

            <Typography variant="body1" sx={{ mt: 3, ml: 2 }}>
              <Label color={data >= 0 ? "success" : "error"} sx={{ p: 2 }}>
                ₹ {data}
              </Label>
            </Typography>
          </Stack>

          {loadingData === true && <CircularProgress />}
        </Stack>

        {dataLoading === true ? (
          <CircularProgress
            sx={{
              position: "absolute",
              bottom: "1px",
              right: "8px",
            }}
          />
        ) : (
          <IconButton
            sx={{
              position: "absolute",
              bottom: "1px",
              right: "8px",
            }}
            onClick={recalculateBalance}
          >
            <RefreshRounded />
          </IconButton>
        )}
      </Card>
    </>
  );
}
