import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import {
  EditRounded as EditIcon,
} from "@mui/icons-material";

import {
  Card,
  Alert,
  Stack,
  Typography,
  Grid,
  CircularProgress,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";

import { dataFetch } from "src/utils/data-fetch";
import {
  getDateStringFullSync,
  getTimeStringFullSync,
} from "src/utils/date-time-helper";

export default function InternalTxnDetailsModal({ handleM1Close, poutId }) {
  const [error, setError] = useState();
  const [currentData, setCurrentData] = useState();
  const [loadingData, setLoadingData] = useState();

  const { getAccessToken, activeFranchise } = useAuth();

  const load = async () => {
    setLoadingData(true);

    const ret = await dataFetch(
      `/api/internalaccs/mgract/txn/${poutId}`,
      await getAccessToken(),
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      setCurrentData(ret.data);
    } else {
      console.log(ret.message);
      setError(ret.message + " - " + ret.code);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          width: "60%",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Txn Details
          </Typography>

          {currentData && currentData.image && (
            <Button onClick={() => window.open(currentData.image, "_blank")}>
              View Image
            </Button>
          )}
        </Stack>

        {(loadingData === true && <CircularProgress />) ||
          (currentData && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>Status:</b>
                    </TableCell>
                    <TableCell>
                      <b>
                        {currentData.status && currentData.status.toUpperCase()}
                      </b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>From:</b>
                    </TableCell>
                    <TableCell>
                      {currentData.from && currentData.from.name} (
                      {currentData.from && currentData.from.entity})
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>To:</b>
                    </TableCell>
                    <TableCell>
                      {currentData.to && currentData.to.name} (
                      {currentData.to && currentData.to.entity})
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Entity:</b>
                    </TableCell>
                    <TableCell>{currentData.entity.toUpperCase()}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Amount:</b>
                    </TableCell>
                    <TableCell>₹ {currentData.amount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Method:</b>
                    </TableCell>
                    <TableCell>
                      {currentData.method && currentData.method.toUpperCase()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Comments:</b>
                    </TableCell>
                    <TableCell>
                      {currentData.comments ? currentData.comments : "-"}
                    </TableCell>
                    <TableCell>{<EditIcon />}</TableCell>
                  </TableRow>

                  {currentData.fund_account &&
                    currentData.fund_account.number && (
                      <>
                        <TableRow>
                          <TableCell>
                            <b>Acc No:</b>
                          </TableCell>
                          <TableCell>
                            {currentData.fund_account.number}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <b>IFSC:</b>
                          </TableCell>
                          <TableCell>{currentData.fund_account.ifsc}</TableCell>
                        </TableRow>
                      </>
                    )}
                </TableBody>
              </Grid>

              {currentData.timeline && currentData.timeline.length > 0 && (
                <Grid item xs={12} md={6} lg={6}>
                  <Timeline position="alternate">
                    {currentData.timeline.map((item) => (
                      <TimelineItem>
                        <TimelineOppositeContent color="text.secondary">
                          {getDateStringFullSync(item.ts)}
                          <br />
                          {getTimeStringFullSync(item.ts)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot color="success" />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          {item.status.toUpperCase()}
                          <br />
                          {(item.by && item.by.name) || "Auto"}
                          <br />
                          {item.by && item.by.entity}
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                </Grid>
              )}
            </Grid>
          ))}

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
