import { useState } from "react";

import { Edit as EditIcon } from "@mui/icons-material";

import {
  Card,
  Typography,
  Box,
  Grid,
  Stack,
  CircularProgress,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Modal,
} from "@mui/material";

import {
  getDateStringFullWithMonthSync,
  getDayStartAndEndSync,
  getTimeStringFullSync,
} from "src/utils/date-time-helper";
import EditCustomerModal from "./EditCustomerModal";

export default function CustomerInfoCard({ customer, loadingData, reload }) {
  const [modalOpen, setModalOpen] = useState(false);

  const closeModalFn = () => {
    setModalOpen(false);
    reload();
  };

  const openModalFn = async () => {
    setModalOpen(true);
  };

  return (
    <>
      <Card>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: 3, mx: 3 }}
        >
          <Typography variant="h5" gutterBottom>
            Details
          </Typography>

          {loadingData === true && <CircularProgress />}
          <Button
            variant="outlined"
            color="error"
            startIcon={<EditIcon />}
            sx={{ mr: 1 }}
            onClick={openModalFn}
          >
            Edit
          </Button>
        </Stack>

        {customer && (
          <Grid container columnSpacing={1} sx={{ pl: 2, pr: 2 }}>
            <Grid item xs={12} md={6} lg={6}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Name:</b>
                  </TableCell>
                  <TableCell>{customer?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Code:</b>
                  </TableCell>
                  <TableCell>{customer?.code || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Phone:</b>
                  </TableCell>
                  <TableCell>{customer?.phone || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Address:</b>
                  </TableCell>
                  <TableCell>
                    {customer?.address && customer?.address?.detail}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>Cart:</b>
                  </TableCell>
                  <TableCell>{customer?.cart?.name || "-"}</TableCell>
                </TableRow>
              </TableBody>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Created By:</b>
                  </TableCell>
                  <TableCell>
                    {(customer?.created_by && customer?.created_by?.name) ||
                      "SELF"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Created On:</b>
                  </TableCell>
                  <TableCell>
                    {getDateStringFullWithMonthSync(
                      parseInt(customer._id.substring(0, 8), 16) * 1000
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>Expected Time:</b>
                  </TableCell>
                  <TableCell>
                    {(customer.expected_time &&
                      customer.expected_time.exact > 0 &&
                      getTimeStringFullSync(
                        getDayStartAndEndSync(Date.now()).start +
                          customer?.expected_time?.exact
                      )) ||
                      "-"}
                  </TableCell>
                </TableRow>
                {customer.wallet && (
                  <TableRow>
                    <TableCell>
                      <b>Balance:</b>
                    </TableCell>
                    <TableCell
                      style={{
                        color: customer?.wallet?.balance >= 0 ? "black" : "red",
                      }}
                    >
                      <b>₹ {customer?.wallet?.balance}</b>
                    </TableCell>
                  </TableRow>
                )}

                <TableRow>
                  <TableCell>
                    <b>Flat:</b>
                  </TableCell>
                  <TableCell>{customer?.address?.flat}</TableCell>
                </TableRow>
              </TableBody>
            </Grid>
          </Grid>
        )}

        <Stack direction="row">
          <Box sx={{ flexGrow: 1 }} />
        </Stack>
      </Card>
      <Modal open={modalOpen} onClose={closeModalFn}>
        <EditCustomerModal handleM1Close={closeModalFn} customer={customer} />
      </Modal>
    </>
  );
}
