import { Grid, Container, Typography } from "@mui/material";

import Page from "../../components/Page";

import CustomerSearch from "./CustomerSearch";
import NegBalanceCustomers from "./NegBalanceCustomers";
import { useNavigate } from "react-router-dom";
import CustomerDetailsPage from "./det/CustomerDetailsPage";
import { useState } from "react";

export default function Home() {

  const [selectedCust, setSelectedCust] = useState(null);
  const [detailsDrawerState, setDetailsDrawerState] = useState(false);

  const openCustomerDetailsPage = (customer) => {
    setSelectedCust(customer);
    setDetailsDrawerState(true);
  };

  return (
    <Page title="Milkvilla Dashboard">
      <Container>
        <Grid container mb={4} spacing={4}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h4">Customers Live View</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={8}>
            <CustomerSearch onUserClick={openCustomerDetailsPage} />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <NegBalanceCustomers onUserClick={openCustomerDetailsPage} />
          </Grid>
        </Grid>

        {/* <UserDetailsModal
          isOpen={userModalOpen}
          handleUmClose={handleUmClose}
          detail={currentUser}
          loading={loadingUser}
        /> */}
      </Container>

      <CustomerDetailsPage
        customer={selectedCust}
        handleClose={() => setDetailsDrawerState(false)}
        drawerOpen={detailsDrawerState}
      />
    </Page>
  );
}
