import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";

import { dataFetch } from "../../utils/data-fetch";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  CircularProgress,
  Stack,
  Tooltip,
} from "@mui/material";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import {
  getDateStringFullWithTimeSync,
  getDayStart,
  getDayEnd,
} from "src/utils/date-time-helper";

import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Page from "src/components/Page";
import { MobileDatePicker } from "@mui/x-date-pickers";

const TABLE_HEAD = [
  { id: "amount", label: "Amount", alignRight: false },
  { id: "entity", label: "Entity", alignRight: false },
  { id: "from", label: "From", alignRight: false },
  { id: "to", label: "To", alignRight: false },
  { id: "date", label: "Date", alignRight: false },
  { id: "method", label: "Method", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "comments", label: "Comments", alignRight: false },
];

export default function ExpenseCatPage() {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [categoriesData, setCategoriesData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();

  const [startDate, setStartDate] = useState(getDayStart(Date.now()));
  const [endDate, setEndDate] = useState(getDayEnd(Date.now()));

  const { getAccessToken, activeFranchise } = useAuth();

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/internalaccs/mgract/txns/query?sort=date&dir=d&date_gt=${startDate}&date_lt=${endDate}`;

    if (selectedCategory) url += `&to=${selectedCategory}`;
    else {
      setLoadingData(false);
      return;
    }

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    handleSearch();
  }, [startDate, endDate, activeFranchise, selectedCategory]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadCategories();
  }, [activeFranchise]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadCategories = async () => {
    let url = `/api/managers/mgract/expenseaccounts`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCategoriesData(ret.data);
    }
  };

  // const [selectedPout, setSelectedPout] = useState();
  // const handleEntryClick = (poutId) => {
  //   setSelectedPout(poutId);
  //   openPayoutModal();
  // };

  // const [payoutModalOpen, setPayoutModalOpen] = useState(false);

  // const closePayoutModal = () => {
  //   setPayoutModalOpen(false);
  // };

  // const openPayoutModal = async () => {
  //   setPayoutModalOpen(true);
  // };

  let tot = 0;
  customerData.forEach((dt) => {
    tot += dt.amount;
  });

  return (
    <Page title="Expense Cat">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography variant="h4">Expense Categorized</Typography>
          <Typography variant="h5">{tot > 0 && `Total: ₹ ${tot}`}</Typography>
        </Stack>

        <Card sx={{ px: 3, py: 1 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            mt={2}
            spacing={2}
          >
            <Select
              id="expense-select"
              value={selectedCategory}
              onChange={(event) => setSelectedCategory(event.target.value)}
            >
              <MenuItem value={""}>-</MenuItem>
              {categoriesData.map((item) => (
                <MenuItem value={item._id}>{item.name}</MenuItem>
              ))}
            </Select>

            {loadingData === true && <CircularProgress color="secondary" />}

            <Stack
              justifyContent={"flex-end"}
              direction={"row"}
              spacing={2}
              alignItems={"center"}
            >
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={"en-in"}
              >
                <MobileDatePicker
                  value={moment(startDate)}
                  onChange={(newValue) => {
                    if (newValue) {
                      const d1 = getDayStart(newValue.valueOf());
                      if (startDate !== d1) setStartDate(d1);
                    }
                  }}
                  closeOnSelect={true}
                  format="DD/MM/YYYY"
                  loading={loadingData}
                />
              </LocalizationProvider>

              <b>-</b>

              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={"en-in"}
              >
                <MobileDatePicker
                  value={moment(endDate)}
                  onChange={(newValue) => {
                    if (newValue) {
                      const d1 = getDayEnd(newValue.valueOf());
                      if (endDate !== d1) setEndDate(d1);
                    }
                  }}
                  closeOnSelect={true}
                  format="DD/MM/YYYY"
                  loading={loadingData}
                />
              </LocalizationProvider>
            </Stack>
          </Stack>

          {customerData.length > 0 && (
            <div>
              <TableContainer sx={{ minHeight: 100 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.alignRight ? "right" : "left"}
                          sortDirection={false}
                        >
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {customerData.map((item) => {
                      const color =
                        item.status === "success"
                          ? "white"
                          : [
                              "pending",
                              "processing",
                              "initiated",
                              "approved",
                            ].includes(item.status)
                          ? "lightcyan"
                          : "lightsalmon";
                      return (
                        <TableRow
                          hover
                          // onClick={() => handleEntryClick(item._id)}
                          style={{ backgroundColor: color }}
                        >
                          <TableCell>
                            <b>₹ {item.amount.toFixed(1)}</b>
                          </TableCell>
                          <TableCell>{item.entity}</TableCell>
                          <TableCell>
                            {item.from ? item.from.name : "-"}
                          </TableCell>
                          <TableCell>{item.to ? item.to.name : "-"}</TableCell>
                          <TableCell>
                            {getDateStringFullWithTimeSync(item.date)}
                          </TableCell>
                          <TableCell>
                            {item.method && item.method.toUpperCase()}
                          </TableCell>
                          <TableCell>
                            {(item.status ?? " - ").toUpperCase()}
                          </TableCell>
                          <Tooltip title={item.comments || ""} arrow>
                            <TableCell>
                              {item.comments
                                ? item.comments.substring(0, 20)
                                : " - "}
                            </TableCell>
                          </Tooltip>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}

          {customerData.length === 0 && loadingData === false && (
            <div>
              <Typography gutterBottom align="center" variant="subtitle1">
                Not found
              </Typography>
              <Typography variant="body2" align="center" sx={{ mb: 4 }}>
                No results here.
              </Typography>
            </div>
          )}
        </Card>
      </Container>

      {/* <Modal open={payoutModalOpen} onClose={closePayoutModal}>
        <ApprovePayoutModal
          handleM1Close={closePayoutModal}
          poutId={selectedPout}
        />
      </Modal> */}
    </Page>
  );
}
