import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { FileUploader } from "react-drag-drop-files";
import FormData from "form-data";
import axios from "axios";

import { FormikProvider, Form, useFormik } from "formik";

import { Card, Stack, Typography, Grid } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "src/contexts/SnackbarContext";

const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];

const { rootUrl } = require("src/utils/data-fetch");

export default function UploadItemImage({ handleM1Close, aptId }) {
  const [file, setFile] = useState(null);

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const uploadNow = async (event) => {
    var formData = new FormData();

    formData.append("upload", file, "itemimage.png");

    try {
      const url = `${rootUrl}/api/inventory/mgract/item/${aptId}/image`;

      const ret = await axios.post(url, formData, {
        headers: {
          Authorization: await getAccessToken(),
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          franchisee: activeFranchise,
        },
      });

      if (ret.status === 200) {
        if (ret.data.code === 1000) {
          showSnackbar("Upload Success", "success");
          handleM1Close();
        } else {
          showSnackbar(ret.data.message + ret.data.code, "error");
        }
      } else {
        showSnackbar(ret.statusText || "Upload failed!", "error");
      }
    } catch (e) {
      showSnackbar(e?.message || "Upload failed!", "error");
      console.log(e);
    }
  };

  const handleChange = (file) => {
    setFile(file);
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      await uploadNow(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 200,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Upload Image
          </Typography>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Stack spacing={3}></Stack>
          </Grid>
        </Grid>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <FileUploader
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                  multiple={false}
                />
              </Grid>
            </Grid>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Upload
            </LoadingButton>
          </Form>
        </FormikProvider>
      </Card>
    </div>
  );
}
