import { useState } from "react";

import {
  Container,
  Typography,
  Stack,
  Modal,
  Grid,
  Button,
} from "@mui/material";

import Page from "src/components/Page";
import LedgerListCard from "./LedgerListCard";
import AddLedgerModal from "./AddLedgerModal";
import RecalculateLedgers from "./RecalculateLedgers";
import InternalAccDetailsPage from "../details/InternalAccDetailsPage";

export default function LedgersPageAdmin() {
  const [addLedgerModalOpen, setAddLedgerModalOpen] = useState(false);

  const [ledgerDetailsPageState, setLedgerDetailsPageState] = useState(null);

  return (
    <Page title="Ledgers">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          mb={4}
          justifyContent={"space-between"}
        >
          <Typography variant="h4">Ledgers</Typography>
          <Button
            variant="contained"
            onClick={() => setAddLedgerModalOpen(true)}
          >
            Add Ledger
          </Button>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <LedgerListCard
              onAccSelected={(i) => setLedgerDetailsPageState(i)}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <RecalculateLedgers />
          </Grid>
        </Grid>
      </Container>

      {addLedgerModalOpen && (
        <AddLedgerModal
          onClose={() => setAddLedgerModalOpen(false)}
          open={true}
        />
      )}

      {ledgerDetailsPageState && (
        <InternalAccDetailsPage
          open={true}
          onClose={() => setLedgerDetailsPageState(null)}
          inpAcc={ledgerDetailsPageState}
        />
      )}
    </Page>
  );
}
