import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import {
  Card,
  Stack,
  TextField,
  Typography,
  Grid,
  MenuItem,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch-support";
import { useSnackbar } from "src/contexts/SnackbarContext";

export default function AddSupportAgentModal({ handleM1Close, manager }) {
  const { getAccessToken } = useAuth();
  const { showSnackbar } = useSnackbar();

  const payNow = async (values) => {
    const ret = await dataPost(`/agents/mgract/add`, await getAccessToken(), {
      main_agent_id: manager._id,
      priority: values.priority,
      access: values.access,
    });
    if (ret.success === true) {
      showSnackbar(ret.message || "Data saved successfully", "success");
      handleM1Close();
    } else {
      showSnackbar(ret.message || "Failed to save data", "error");
    }
  };

  const DataSchema = Yup.object().shape({
    priority: Yup.string().required("Enter priority!"),
    access: Yup.string().required("Enter access!"),
  });

  const formik = useFormik({
    initialValues: {
      priority: "high",
      access: "normal",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await payNow(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 400,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            {manager.name} - Add as support agent
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  select
                  fullWidth
                  label="Priority"
                  {...getFieldProps("priority")}
                  error={Boolean(touched.priority && errors.priority)}
                  helperText={touched.priority && errors.priority}
                >
                  <MenuItem value="high">High</MenuItem>
                  <MenuItem value="low">Low</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  select
                  fullWidth
                  label="Access"
                  {...getFieldProps("access")}
                  error={Boolean(touched.access && errors.access)}
                  helperText={touched.access && errors.access}
                >
                  <MenuItem value="normal">Agent</MenuItem>
                  <MenuItem value="manager">Manager</MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              ADD
            </LoadingButton>
          </Form>
        </FormikProvider>
      </Card>
    </div>
  );
}
