import React from "react";
import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { dataFetch } from "../../utils/data-fetch";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
  TableHead,
  Grid,
} from "@mui/material";

import {
  getDayStartAndEndSync,
  getTimeString,
} from "src/utils/date-time-helper";

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "driver", label: "Driver", alignRight: false },
  { id: "start", label: "Start Time", alignRight: false },
  { id: "stay", label: "Stay (Hr)", alignRight: true },
  { id: "sale", label: "Sale (Ltr)", alignRight: true },
  { id: "density", label: "Density (LPH)", alignRight: true },
];

export const MilkCartsReportSection = ({ currentDate }) => {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const handleSearch = async () => {
    let url = `/api/milkcarts/mgract/mgrreport?date=${currentDate}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [activeFranchise, currentDate]); // eslint-disable-line react-hooks/exhaustive-deps

  let mData = [];
  let eData = [];
  let mSum = 0;
  let eSum = 0;

  customerData.forEach((item) => {
    let workM = item.workM / (60 * 60 * 1000.0);
    let workE = item.workE / (60 * 60 * 1000.0);

    if (item.saleM > 0) {
      mData.push({
        name: item.name,
        driver: item.driver,
        sale: item.saleM,
        start: item.saleStartM,
        work: workM,
        density: workM > 0 ? item.saleM / workM : 0,
      });
    }

    if (item.saleE > 0) {
      eData.push({
        name: item.name,
        driver: item.driver,
        sale: item.saleE,
        start: item.saleStartE,
        work: workE,
        density: workE > 0 ? item.saleE / workE : 0,
      });
    }

    mSum += item.saleM;
    eSum += item.saleE;
  });

  const times = getDayStartAndEndSync(currentDate);
  const AM6 = times.start + 6 * 60 * 60 * 1000;
  const PM5 = times.start + 17 * 60 * 60 * 1000;

  if (loadingData === true) {
    return <CircularProgress />;
  } else
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={9}>
          <Card title="Morning">
            <Container>
              {(mData.length > 0 && (
                <TableContainer sx={{ minHeight: 100 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {TABLE_HEAD.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.alignRight ? "right" : "left"}
                            sortDirection={false}
                          >
                            <b>{headCell.label}</b>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {mData.map((item) => {
                        return (
                          <TableRow hover>
                            <TableCell>
                              <b>{item.name.substring(0, 6) || "-"}</b>
                            </TableCell>
                            <TableCell>{item?.driver}</TableCell>
                            <TableCell
                              style={{
                                backgroundColor:
                                  item.start < AM6
                                    ? "aquamarine"
                                    : "lightsalmon",
                                borderRight: "1px solid black",
                              }}
                            >
                              {getTimeString(item.start)}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                backgroundColor:
                                  item.work > 4.5
                                    ? "aquamarine"
                                    : "lightsalmon",
                                borderRight: "1px solid black",
                              }}
                            >
                              {item.work.toFixed(1)} Hr
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                backgroundColor:
                                  item.sale > 100
                                    ? "aquamarine"
                                    : "lightsalmon",
                                borderRight: "1px solid black",
                              }}
                            >
                              {item.sale.toFixed(1)} Lt
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                backgroundColor:
                                  item.density > 20
                                    ? "aquamarine"
                                    : "lightsalmon",
                              }}
                            >
                              {item.density.toFixed(1)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell align="right">
                          {mSum?.toFixed(1)} Lt
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )) || (
                <Typography textAlign={"center"} padding={3}>
                  No Morning data
                </Typography>
              )}
            </Container>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={9}>
          <Card title="Evening">
            <Container>
              {(eData.length > 0 && (
                <TableContainer sx={{ minHeight: 100 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {TABLE_HEAD.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.alignRight ? "right" : "left"}
                            sortDirection={false}
                          >
                            <b>{headCell.label}</b>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {eData.map((item) => {
                        return (
                          <TableRow hover>
                            <TableCell>
                              <b>{item.name.substring(0, 6) || "-"}</b>
                            </TableCell>
                            <TableCell>{item?.driver}</TableCell>
                            <TableCell
                              style={{
                                backgroundColor:
                                  item.start < PM5
                                    ? "aquamarine"
                                    : "lightsalmon",
                                borderRight: "1px solid black",
                              }}
                            >
                              {getTimeString(item.start)}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                backgroundColor:
                                  item.work > 3 ? "aquamarine" : "lightsalmon",
                                borderRight: "1px solid black",
                              }}
                            >
                              {item.work.toFixed(1)} Hr
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                backgroundColor:
                                  item.sale > 40 ? "aquamarine" : "lightsalmon",
                                borderRight: "1px solid black",
                              }}
                            >
                              {item.sale.toFixed(1)} Lt
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                backgroundColor:
                                  item.density > 20
                                    ? "aquamarine"
                                    : "lightsalmon",
                              }}
                            >
                              {item.density.toFixed(1)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell align="right">
                          {eSum?.toFixed(1)} Lt
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )) || (
                <Typography textAlign={"center"} padding={3}>
                  No Evening data
                </Typography>
              )}
            </Container>
          </Card>
        </Grid>
      </Grid>
    );
};
