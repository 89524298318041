import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Stack,
  Modal,
  Tooltip,
  Drawer,
  IconButton,
  Avatar,
} from "@mui/material";

import {
  getDateStringFullSync,
  getDateStringFullWithTimeSync,
  getTimeStringFullSync,
} from "src/utils/date-time-helper";
import InternalTxnDetailsModalAdmin from "./InternalTxnDetailsModalAdmin";
import {
  ControlPointDuplicateRounded,
  RefreshRounded,
} from "@mui/icons-material";
import AddTxnModal from "./AddTxnModal";
import InternalAccDetailsPage from "../details/InternalAccDetailsPage";
import Label from "src/pages/crm/Label";
import { stringToColor, txnIcon } from "src/utils/constants";

const TABLE_HEAD = [
  { id: "im", label: "", align: "left" },
  { id: "date", label: "Date", align: "left" },
  { id: "amount", label: "Amount", align: "left" },
  { id: "from", label: "Txn", align: "left" },
  { id: "center", label: "Plant", align: "center" },
  { id: "comments", label: "Narration", align: "center" },
  { id: "act", label: "", align: "right" },
];

export default function InternalTxnPaginated() {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [page, setPage] = useState(0);

  const { getAccessToken, activeFranchise } = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = async () => {
    setLoadingData(true);
    let url = `/api/internalaccs/coreact/txns/query?skip=${
      page * 10
    }&limit=${10}&sort=_id&dir=d`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    handleSearch();
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  const [payoutModalOpen, setPayoutModalOpen] = useState(null);

  const closePayoutModal = () => {
    setPayoutModalOpen(null);
  };

  const openPayoutModal = async (id) => {
    setPayoutModalOpen(id);
  };

  const duplicateTxnClicked = () => {
    setDuplicateTxnModalOpen(true);
  };

  const [duplicateTxnModalOpen, setDuplicateTxnModalOpen] = useState(false);
  const [duplicateData, setDuplicateData] = useState();

  const closeDuplicateTxnModal = () => {
    setDuplicateTxnModalOpen(false);
  };

  const [accDrawerState, setAccDrawerState] = useState(false);
  const [accDrawerData, setAccDrawerData] = useState(null);

  const onAccClick = (acc) => {
    if (acc) {
      setAccDrawerData(acc);
      setAccDrawerState(true);
    }
  };

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h5" gutterBottom>
            Cash Flow
          </Typography>

          {(loadingData === true && <CircularProgress color="secondary" />) || (
            <IconButton onClick={() => handleSearch()}>
              <RefreshRounded />
            </IconButton>
          )}
        </Stack>
        {customerData.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {customerData.map((item) => {
                    const color =
                      item.status === "success"
                        ? "white"
                        : [
                            "pending",
                            "processing",
                            "initiated",
                            "approved",
                          ].includes(item.status)
                        ? "lightcyan"
                        : "lightsalmon";
                    return (
                      <TableRow
                        hover
                        onClick={() => openPayoutModal(item._id)}
                        style={{ backgroundColor: color }}
                      >
                        <TableCell
                          style={{ width: "1px", whiteSpace: "nowrap" }}
                          align="left"
                        >
                          <Avatar
                            variant="rounded"
                            sx={{ bgcolor: stringToColor(`${item?.amount}`) }}
                          >
                            {txnIcon(item?.entity)}
                          </Avatar>
                        </TableCell>
                        <TableCell sx={{ color: "primary.dark" }} align="left">
                          <b>{getDateStringFullSync(item.date)}</b>
                          <br />
                          <small>{getTimeStringFullSync(item.date)}</small>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="h6">
                            ₹ {item.amount?.toLocaleString("en-IN")}
                          </Typography>
                          <Label color="primary" variant="ghost">
                            {item?.entity?.toUpperCase() || "-"}
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          CR:{" "}
                          <span
                            style={{ color: "green" }}
                            onClick={(e) => {
                              onAccClick(item?.from);
                              e.stopPropagation();
                            }}
                          >
                            {item.from ? item.from.name : "-"}
                          </span>
                          <br />
                          DR:{" "}
                          <span
                            style={{ color: "red" }}
                            onClick={(e) => {
                              onAccClick(item?.to);
                              e.stopPropagation();
                            }}
                          >
                            {item.to ? item.to.name : "-"}
                          </span>
                        </TableCell>

                        <TableCell align="center">
                          {(item?.franchisee?.name && (
                            <Label color="primary">
                              {item.franchisee.name}
                            </Label>
                          )) ||
                            "-"}
                        </TableCell>
                        <Tooltip
                          title={item.comments || ""}
                          arrow
                          align="center"
                        >
                          <TableCell>
                            {item.comments
                              ? item.comments.substring(0, 15)
                              : "-"}
                          </TableCell>
                        </Tooltip>

                        <TableCell
                          onClick={(e) => {
                            e.stopPropagation();
                            setDuplicateData(item);
                            duplicateTxnClicked();
                          }}
                        >
                          <ControlPointDuplicateRounded />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              component="div"
              count={-1}
              rowsPerPage={10}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[10]}
            />
          </div>
        )}

        {customerData.length === 0 && loadingData === false && (
          <div>
            <Typography gutterBottom align="center" variant="subtitle1">
              Not found
            </Typography>
            <Typography variant="body2" align="center" sx={{ mb: 4 }}>
              No results here.
            </Typography>
          </div>
        )}
      </Container>

      <InternalTxnDetailsModalAdmin
        handleM1Close={closePayoutModal}
        poutId={payoutModalOpen}
        drawerOpen={payoutModalOpen ? true : false}
      />

      <Modal open={duplicateTxnModalOpen} onClose={closeDuplicateTxnModal}>
        <AddTxnModal
          handleM1Close={closeDuplicateTxnModal}
          duplicateData={duplicateData}
        />
      </Modal>

      {accDrawerState === true && (
        <InternalAccDetailsPage
          inpAcc={accDrawerData}
          open={accDrawerState}
          onClose={() => setAccDrawerState(false)}
        />
      )}
    </Card>
  );
}
