import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import {
  Stack,
  TextField,
  OutlinedInput,
  InputAdornment,
  Box,
  Container,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";

import { useSnackbar } from "src/contexts/SnackbarContext";
import MakeFullScreen from "src/components/MakeFullScreen";

export default function AddVendorToItem({ open, onClose, vendor, item }) {
  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const approveNow = async (values) => {
    if (!vendor) {
      showSnackbar("Please select a vendor.", "error");
      return;
    }

    if (!item) {
      showSnackbar("Please select an item.", "error");
      return;
    }

    const ret = await dataPost(
      `/api/inventory/mgract/item/${item._id}/vendor`,
      await getAccessToken(),
      {
        vid: vendor._id,
        rate: values.rate,
        lead_time: values.lead_time,
        comments: values.comments,
      },
      activeFranchise
    );
    if (ret.success === true) {
      showSnackbar(ret.message || "Data added.", "success");
      onClose();
    } else {
      showSnackbar(ret.message + " - " + ret.code, "error");
    }
  };

  const DataSchema = Yup.object().shape({
    rate: Yup.number().required("Rate is required"),
    lead_time: Yup.number().required("Lead Time is required"),
    comments: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      rate: "",
      lead_time: "",
      comments: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <MakeFullScreen
      title="Add Vendor to Item"
      open={open}
      onClose={onClose}
      maxWidth={"md"}
    >
      <Container sx={{ px: 2, pb: 2 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <OutlinedInput
                fullWidth
                type="text"
                value={`${item?.name} (${item?.code})` || "None Selected"}
                startAdornment={
                  <InputAdornment position="start">
                    <Box sx={{ color: "text.disabled" }}>Item</Box>
                  </InputAdornment>
                }
              />

              <OutlinedInput
                fullWidth
                type="text"
                value={`${vendor?.name} (${vendor?.code})` || "None Selected"}
                startAdornment={
                  <InputAdornment position="start">
                    <Box sx={{ color: "text.disabled" }}>Vendor</Box>
                  </InputAdornment>
                }
              />

              <TextField
                fullWidth
                type="number"
                label="Rate"
                {...getFieldProps("rate")}
                error={Boolean(touched.rate && errors.rate)}
                helperText={touched.rate && errors.rate}
              />

              <TextField
                fullWidth
                type="number"
                label="Lead Time (Days)"
                {...getFieldProps("lead_time")}
                error={Boolean(touched.lead_time && errors.lead_time)}
                helperText={touched.lead_time && errors.lead_time}
              />

              <TextField
                fullWidth
                multiline
                type="text"
                label="Comments"
                {...getFieldProps("comments")}
                error={Boolean(touched.comments && errors.comments)}
                helperText={touched.comments && errors.comments}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              ADD
            </LoadingButton>
          </Form>
        </FormikProvider>
      </Container>
    </MakeFullScreen>
  );
}
