import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";

import { UploadRounded } from "@mui/icons-material";

import {
  Card,
  Container,
  Typography,
  Stack,
  CardActionArea,
  CircularProgress,
} from "@mui/material";

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}

export default function ExportLedgerSection({ accId }) {
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, allFranchisees } = useAuth();

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/internalaccs/coreact/acc/${accId}/export`;

    const ret = await dataFetch(url, await getAccessToken());
    if (ret.success === true) {
      await timeout(5000);
      window.open(ret.data);
    } else {
      window.alert(ret.message || "Export failed!");
    }

    setLoadingData(false);
  };

  return (
    <Card sx={{ boxShadow: 2 }}>
      <CardActionArea onClick={handleSearch}>
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            mt={2}
          >
            <Typography variant="h5" gutterBottom>
              Export
            </Typography>

            {loadingData && <CircularProgress />}

            <UploadRounded />
          </Stack>
        </Container>
      </CardActionArea>
    </Card>
  );
}
