import {
  Stack,
  Typography,
  TextField,
  Grid,
  Container,
  Card,
} from "@mui/material";

import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect, useState } from "react";
import Page from "src/components/Page";
import { dataFetch } from "src/utils/data-fetch";
import moment from "moment";
import { useAuth } from "src/contexts/AuthContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Margin } from "@mui/icons-material";

export default function MilkflowDashboard() {
  const [selectedDate, setSelectedDate] = useState(moment(Date.now()));
  const [milkflow, setMilkflow] = useState();

  const { getAccessToken, activeFranchisee } = useAuth();
  console.log(selectedDate);

  const initialLoad = async () => {
    const ret = await dataFetch(
      `/api/milkflow/mgract/flow/bydate?date=${selectedDate.valueOf()}`,
      await getAccessToken(),
      activeFranchisee
    );

    if (ret.success) {
      setMilkflow(ret.data);
    }
  };

  const load = async () => {
    const ret = await dataFetch(
      `/api/milkflow/mgract/flow/bydate?date=${selectedDate.valueOf()}`,
      await getAccessToken(),
      activeFranchisee
    );

    if (ret.success) {
      setMilkflow(ret.data);
      console.log(ret.data);
    }
  };

  useEffect(() => {
    initialLoad();
  }, [activeFranchisee]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    load();
    console.log("change");
  }, [selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page title="Milk Flow">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 3, mb: 2, mx: 3 }}
      >
        <Typography variant="h5" gutterBottom>
          Milkflow Summary
        </Typography>
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale={"en-in"}
        >
          <DesktopDatePicker
            views={["year", "month", "day"]}
            value={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Stack>

      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  {milkflow && (
                    <TableHead>
                      <TableRow>
                        <TableCell>Net Today</TableCell>
                        <TableCell align="right">
                          {milkflow.net_today ? milkflow.net_today : "--"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  )}
                </Table>
              </TableContainer>
            </Card>
          </Grid>

          <Grid item xs={12}>
          <Stack direction={"row"} spacing={2}>
            <Card sx={{p:2}}>
            <Typography variant="h5" mb={2} gutterBottom> Milkflow Out </Typography>
            <TableContainer component={Paper} maxWidth={false}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  {milkflow && (
                    <TableHead>
                      <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                        <TableCell align="right">Fat</TableCell>
                        <TableCell align="right">SNF</TableCell>
                        <TableCell align="right">Value</TableCell>
                      </TableRow>
                    </TableHead>
                  )}
                  {milkflow && (
                    <TableBody>
                      <TableRow>
                        <TableCell>Center Collection</TableCell>
                        <TableCell align="right">
                          {milkflow.center_coll.qty
                            ? milkflow.center_coll.qty
                            : "--"}
                        </TableCell>
                        <TableCell align="right">
                          {milkflow.center_coll.fat
                            ? milkflow.center_coll.fat
                            : "--"}
                        </TableCell>
                        <TableCell align="right">
                          {milkflow.center_coll.snf
                            ? milkflow.center_coll.snf
                            : "--"}
                        </TableCell>
                        <TableCell align="right">
                          {milkflow.center_coll.value
                            ? milkflow.center_coll.value
                            : "--"}
                        </TableCell>
                      </TableRow>
                      {milkflow.farmer_coll && 
                        <TableRow>
                        <TableCell>Farmer Collection</TableCell>
                        <TableCell align="right">
                          {milkflow.farmer_coll.qty
                            ? milkflow.farmer_coll.qty
                            : "--"}
                        </TableCell>
                        <TableCell align="right">
                          {milkflow.farmer_coll.fat
                            ? milkflow.farmer_coll.fat
                            : "--"}
                        </TableCell>
                        <TableCell align="right">
                          {milkflow.farmer_coll.snf
                            ? milkflow.farmer_coll.snf    
                            : "--"}
                        </TableCell>
                        <TableCell align="right">
                          {milkflow.farmer_coll.value
                            ? milkflow.farmer_coll.value  
                            : "--"}
                        </TableCell>
                      </TableRow>}
                      {
                        milkflow.transport.length > 0 && (
                          milkflow.transport.map((row) => (
                            row.flow_type === "in" && (
                            <TableRow>
                              <TableCell>Transport</TableCell>
                              <TableCell align="right">
                              {row.qty ? row.qty : "--"}
                            </TableCell>
                            <TableCell align="right">
                              {row.fat ? row.fat : "--"}
                            </TableCell>
                            <TableCell align="right">
                              {row.snf ? row.snf : "--"}
                            </TableCell>
                            <TableCell align="right">
                              {row.value ? row.value : "--"}
                            </TableCell>
                            </TableRow>
                            )
                          ))
                        )
                      }

                      {
                        milkflow.error.length > 0 && (
                          milkflow.error.map((row) => (
                            milkflow.error.value > 0 && (
                            <TableRow>
                              <TableCell>Error {row.category}</TableCell>
                              <TableCell align="right">
                                {row.qty ? row.qty : "--"}
                              </TableCell>
                              <TableCell align="right">
                                {row.fat ? row.fat : "--"}
                              </TableCell>
                              <TableCell align="right">
                                {row.snf ? row.snf : "--"}
                              </TableCell>
                              <TableCell align="right">
                                {row.value ? row.value : "--"}
                              </TableCell>
                            </TableRow>
                            )
                          ))
                        )
                      }
                    
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Card>

            <Card sx={{p:2}}>
            <Typography mb={2} variant="h5" gutterBottom> Milkflow In </Typography>
            <TableContainer component={Paper} maxWidth={false}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  {milkflow && (
                    <TableHead>
                      <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                        <TableCell align="right">Fat</TableCell>
                        <TableCell align="right">SNF</TableCell>
                        <TableCell align="right">Value</TableCell>
                      </TableRow>
                    </TableHead>
                  )}

                  {milkflow && (
                    <TableBody>
                      <TableRow>
                        <TableCell>Cart Sale</TableCell>
                        <TableCell align="right">
                          {milkflow.cart_sale.qty
                            ? milkflow.cart_sale.qty
                            : "--"}
                        </TableCell>
                        <TableCell align="right">
                          {milkflow.cart_sale.fat
                            ? milkflow.cart_sale.fat
                            : "--"}
                        </TableCell>
                        <TableCell align="right">
                          {milkflow.cart_sale.snf
                            ? milkflow.cart_sale.snf
                            : "--"}
                        </TableCell>
                        <TableCell align="right">
                          {milkflow.cart_sale.value
                            ? milkflow.cart_sale.value
                            : "--"}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Bulk Sale</TableCell>
                        <TableCell align="right">
                          {milkflow.bulk_sale.qty
                            ? milkflow.bulk_sale.qty
                            : "--"}
                        </TableCell>
                        <TableCell align="right">
                          {milkflow.bulk_sale.fat
                            ? milkflow.bulk_sale.fat
                            : "--"}
                        </TableCell>
                        <TableCell align="right">
                          {milkflow.bulk_sale.snf
                            ? milkflow.bulk_sale.snf
                            : "--"}
                        </TableCell>
                        <TableCell align="right">
                          {milkflow.bulk_sale.value
                            ? milkflow.bulk_sale.value
                            : "--"}
                        </TableCell>
                      </TableRow>

                      {
                        milkflow.transport.length > 0 && (
                          milkflow.transport.map((row) => (
                            row.flow_type === "out" && (
                            <TableRow>
                              <TableCell>Transport</TableCell>
                              <TableCell align="right">
                              {row.qty ? row.qty : "--"}
                            </TableCell>
                            <TableCell align="right">
                              {row.fat ? row.fat : "--"}
                            </TableCell>
                            <TableCell align="right">
                              {row.snf ? row.snf : "--"}
                            </TableCell>
                            <TableCell align="right">
                              {row.value ? row.value : "--"}
                            </TableCell>
                            </TableRow>
                            )
                          ))
                        )
                      }

                      {
                        milkflow.error.length < 0 && (
                          milkflow.error.map((row) => (
                            milkflow.error.value > 0 && (
                            <TableRow>
                              <TableCell>Error {row.category}</TableCell>
                              <TableCell align="right">
                                {row.qty ? row.qty : "--"}
                              </TableCell>
                              <TableCell align="right">
                                {row.fat ? row.fat : "--"}
                              </TableCell>
                              <TableCell align="right">
                                {row.snf ? row.snf : "--"}
                              </TableCell>
                              <TableCell align="right">
                                {row.value ? row.value : "--"}
                              </TableCell>
                            </TableRow>
                            )
                          ))
                        )
                      }

                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Card>
          </Stack>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

