import React, { useRef, useState } from "react";
import {
  TileLayer,
  Polygon,
  MapContainer,
  FeatureGroup,
  useMapEvent,
  Marker,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import L from "leaflet";
import LeafletControlGeocoder from "../addapt/LeafletControlGeocoder";

const AddZoneSelectMap = ({ onCoordinateChange, polygon1 }) => {
  const mapRef = useRef(null);

   const [polygon, setPolygon] = useState(null);
   const [markerPos, setMarkerPos] = useState(null);

  const handlePolygonCreated = (e) => {
    console.log("called");
    const {layer} = e;
    setPolygon(layer);

    let op = [];
    let centerCoords = [];

    if (layer) {
      layer.getLatLngs()[0].map((latLng) => {
        op.push([latLng.lng, latLng.lat]);
      });
      const c = layer.getCenter();
      centerCoords = [c.lng, c.lat];
    }

    onCoordinateChange(op, centerCoords);
  };

  
  const EventHandler = () => {
    useMapEvent("dblclick", (e) => {
      setMarkerPos(e.latlng);
    });

    return null;
  };

  return (
    <div>
      <MapContainer
        center={ [12.90152691, 77.69154668]}
        zoom={15}
        style={{ height: "90vh", width: "100%" }}
        ref={mapRef}
      >
        <LeafletControlGeocoder onLocationSelect={(latlng, name) => {}} />
        <EventHandler />
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <FeatureGroup>
          {polygon && (
            <Polygon positions={polygon.getLatLngs()[0]} color="red" />
          )}

          {polygon1 && (
            <Polygon positions={polygon1.getLatLngs()[0]} color="red" />
          )}

          {markerPos && (
            <Marker position={markerPos} riseOnHover={true} title="Test" />
          )}

          <EditControl
            position="topright"
            onCreated={handlePolygonCreated}
            onDeleted={() => handlePolygonCreated({layer: null, center: null})}
            draw={{
              rectangle: false,
              circle: false,
              circlemarker: false,
              marker: false,
              polyline: false,
              polygon: true,
            }}
            edit={{ edit: false }}
          />
        </FeatureGroup>
      </MapContainer>
    </div>
  );
};

export default AddZoneSelectMap;

let areaMark;
const TextMarker = ({ polygon }) => {
  areaMark?.remove();

  if (polygon) {
    let ar = L.GeometryUtil.geodesicArea(polygon.getLatLngs()[0]);
    ar = (ar / 1000000).toFixed(3) + " sq. Km";

    var text = L.DomUtil.create("div");
    text.style.background = "white";
    text.style.width = "fit-content";
    text.id = "info_text";
    text.innerHTML = `<strong>${ar}</strong>`;

    areaMark = L.marker(polygon.getCenter(), {
      icon: L.divIcon({ html: text }),
    }).addTo(polygon._map);
  }
};
