import React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import { Container, Box, Tab } from "@mui/material";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Page from "src/components/Page";
import { MilkCartsInfoCard } from "./MilkCartsInfoCard";
import { MilkCartsCustomers } from "./MilkCartsCustomers";
import { MilkCartsLocation } from "./MilkCartsLocation";
import { MilkCartsHisaab } from "./MilkCartsHisaab";
import { MilkCartsCustomersWithMilk } from "./MilkCartsCustomersWithMilk";
import CartMilkOuts from "./CartMilkOuts";
import WalletRechargeSection from "./WalletRechargeSection";
import WalletSaleSection from "./WalletSaleSection";
import MilkCartTimeline from "./MilkCartTimeline";
import { MilkCartsScheduleSection } from "./MilkCartScheduleSection";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MilkCartDetails({ incCart }) {
  const location = useLocation();
  let cart = location.state?.cart;

  if (!cart) {
    cart = incCart;
  }

  const [currentMenu, setCurrentMenu] = useState(
    localStorage.getItem("milkcart_details_menu") || "schedule"
  );

  const handleTabChange = (event, newValue) => {
    setCurrentMenu(newValue);
    localStorage.setItem("milkcart_details_menu", newValue);
  };

  return (
    <Page title="MilkCart Dashboard" sx={{ minHeight: "800px" }}>
      <Container>
        <TabContext value={currentMenu}>
          <Box>
            <TabList onChange={handleTabChange} variant="scrollable">
              <Tab label="Profile" value="general" {...a11yProps(0)} />
              <Tab label="Milk Dispense" value="dispense" {...a11yProps(1)} />
              <Tab label="Schedule" value="schedule" {...a11yProps(2)} />
              <Tab label="Customer Milk" value="cm" {...a11yProps(3)} />
              <Tab label="Customers" value="customers" {...a11yProps(4)} />
              <Tab label="Location" value="location" {...a11yProps(5)} />
              <Tab label="Hisaab" value="hisaab" {...a11yProps(6)} />
              <Tab label="Recharge" value="recharge" {...a11yProps(7)} />
              <Tab label="Wallet Sale" value="ws" {...a11yProps(8)} />
              <Tab label="Timeline" value="timeline" {...a11yProps(19)} />
            </TabList>
          </Box>
          <TabPanel value="general">
            <MilkCartsInfoCard cart={cart} />
          </TabPanel>
          <TabPanel value="dispense">
            <CartMilkOuts cart={cart} />
          </TabPanel>
          <TabPanel value="schedule">
            <MilkCartsScheduleSection cart={cart} />
          </TabPanel>
          <TabPanel value="cm">
            <MilkCartsCustomersWithMilk cart={cart} />
          </TabPanel>
          <TabPanel value="customers">
            <MilkCartsCustomers cart={cart} />
          </TabPanel>
          <TabPanel value="location">
            <MilkCartsLocation cart={cart} />
          </TabPanel>
          <TabPanel value="hisaab">
            <MilkCartsHisaab cart={cart} />
          </TabPanel>
          <TabPanel value="recharge">
            <WalletRechargeSection cart={cart} />
          </TabPanel>
          <TabPanel value="ws">
            <WalletSaleSection cart={cart} />
          </TabPanel>
          <TabPanel value="timeline">
            <MilkCartTimeline cart={cart} />
          </TabPanel>
        </TabContext>
      </Container>
    </Page>
  );
}
