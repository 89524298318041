import React, { useState, useEffect } from "react";
import { Typography, Card, Grid } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { dataFetch } from "../../utils/data-fetch";
import MonthChartComponent from "src/components/month-chart-component"; // Assuming 'MyChartComponent' is exported as default from 'src/components/chart-component'
import { getMonthStartAndEndSync } from "src/utils/date-time-helper";

export default function MilkCartsMonthlySection({ m1, m2 }) {
  const mse1 = getMonthStartAndEndSync(m1);
  const mse2 = getMonthStartAndEndSync(m2);

  let prevStartDate = mse1.start;
  let prevEndDate = mse1.end;
  let startDate = mse2.start;
  let endDate = mse2.end;

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);

  const [loadingData, setLoadingData] = useState(true);
  const [len, setLen] = useState(0);

  const { getAccessToken, activeFranchise } = useAuth();

  const month1Load = async () => {
    setLoadingData(true);

    const url = `/api/milkcarts/mgract/getdispense?start=${prevStartDate}&end=${prevEndDate}&type=monthly`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret) {
      setData1(ret.data);
    } else {
      setData1([]);
    }

    if (ret.data.length > 0) {
      setLen(ret.data.length);
    }

    setLoadingData(false);
  };

  const month2Load = async () => {
    setLoadingData(true);

    const url = `/api/milkcarts/mgract/getdispense?start=${startDate}&end=${endDate}&type=monthly`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret) {
      setData2(ret.data);
    } else {
      setData2([]);
    }

    if (ret.data.length > 0) {
      setLen(ret.data.length);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    month1Load();
  }, [activeFranchise, m1]);

  useEffect(() => {
    month2Load();
  }, [activeFranchise, m2]);

  return (
    <Grid container spacing={3} mt={1}>
      {Array.from({ length: len }, (_, i) => (
        <Grid item xs={12} md={12} lg={6}>
          <Card sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>
              {data2[i]?.name} ({data2[i]?.driver_name})
            </Typography>

            <MonthChartComponent
              prevData={data1[i]}
              data={data2[i]}
              prevDate={m1}
              date={m2}
            />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
