import {
  CallMadeOutlined,
  CallReceivedOutlined,
  EditRounded,
} from "@mui/icons-material";

import {
  Card,
  Stack,
  Box,
  IconButton,
  TextField,
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Menu,
} from "@mui/material";

import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataPost } from "src/utils/data-fetch";

import { getDateStringFullWithTimeSync2 } from "src/utils/date-time-helper";

import Label from "../crm/Label";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";

export default function CommentModal({ details, handleClose }) {
  const [comment, setComment] = useState(details?.comment);
  const [callAgain, setCallAgain] = useState(null);
  const [callAgainValue, setCallAgainValue] = useState(0);

  const { getAccessToken, activeFranchise } = useAuth();

  const [callStatus, setCallStatus] = useState("call connected");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, status) => {
    setCallStatus(status);
    setAnchorEl(null);
  };

  const handleCloseStatus = () => {
    setAnchorEl(null);
  };

  const handleCallAgain = (event) => {
    const value = event.target.value;
    if (value === "null") setCallAgain(0);
    else if (value === "1") {
      setCallAgain("1");
      setCallAgainValue(Date.now() + 86400000);
    } else if (value === "7") {
      setCallAgain("7");
      setCallAgainValue(Date.now() + 604800000);
    } else if (value === "15") {
      setCallAgain("15");
      setCallAgainValue(Date.now() + 1296000000);
    } else if (value === "custom") {
      setCallAgain("custom");
    }
  };

  const updateComment = async () => {
    const url = `/api/salesflags/mgract/sfr/${details._id}/comments`;

    const up = {
      comments: `${callStatus}`,
      call_again: callAgainValue || 0,
      call_status: callStatus,
    };

    if (comment && comment.length > 0) {
      up.comments = `${callStatus} - ${comment}`;
    }

    if (comment === "") {
      return alert("Comment cannot be empty");
    }
    const ret = await dataPost(
      url,
      await getAccessToken(),
      up,
      activeFranchise
    );

    if (ret.success === true) {
      handleClose();
    } else {
      console.log(ret.message);
      alert("Failed to update comment");
    }
  };

  return (
    <Card
      sx={{
        px: 4,
        py: 4,
        minWidth: 800,
        height: "fit-content",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="column" justifyContent="space-between">
          <Stack direction="row">
            {details?.action === "user" ? (
              <CallReceivedOutlined sx={{ mr: 1, width: 20, height: 20 }} />
            ) : (
              <CallMadeOutlined sx={{ mr: 1, width: 20, height: 20 }} />
            )}

            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              <Box component="span" sx={{ mr: 1 }}>
                {details?.entity}
              </Box>
              -
              <Box component="span" sx={{ ml: 1 }}>
                {getDateStringFullWithTimeSync2(details?.date)}
              </Box>
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="start">
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{ mr: 2 }}
            >
              {details?.name} ({details?.phone})
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Card sx={{ mt: 2, p: 2, width: 400 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body1">
            <b>Status</b>
          </Typography>

          <Stack direction="row" alignItems="center" spacing={1}>
            <Label
              variant="filled"
              color={
                callStatus === "not connected"
                  ? "warning"
                  : callStatus === "dialed by mistake"
                  ? "default"
                  : callStatus === "missed by customer"
                  ? "secondary"
                  : callStatus === "rejected by customer"
                  ? "warning"
                  : callStatus === "picked up then disconnected"
                  ? "info"
                  : callStatus === "network issue"
                  ? "warning"
                  : callStatus === "call again later"
                  ? "success"
                  : callStatus === "call connected"
                  ? "success"
                  : callStatus === "not interested"
                  ? "error"
                  : "success"
              }
            >
              <b>{callStatus?.toUpperCase() || "-"}</b>
            </Label>

            <IconButton
              color="primary"
              sx={{ backgroundColor: "white" }}
              onClick={handleClick}
            >
              <EditRounded />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleCloseStatus}>
              <MenuItem
                onClick={(event) =>
                  handleMenuItemClick(event, "call connected")
                }
              >
                Call Connected
              </MenuItem>
              <MenuItem
                onClick={(event) =>
                  handleMenuItemClick(event, "call again later")
                }
              >
                Call Again Later
              </MenuItem>
              <MenuItem
                onClick={(event) =>
                  handleMenuItemClick(event, "picked up then disconnected")
                }
              >
                Picked up then Disconnected
              </MenuItem>
              <MenuItem
                onClick={(event) => handleMenuItemClick(event, "not connected")}
              >
                Call not Connected
              </MenuItem>
              <MenuItem
                onClick={(event) => handleMenuItemClick(event, "network issue")}
              >
                Network Issue
              </MenuItem>
              <MenuItem
                onClick={(event) =>
                  handleMenuItemClick(event, "dialed by mistake")
                }
              >
                Dialed by Mistake
              </MenuItem>
              <MenuItem
                onClick={(event) =>
                  handleMenuItemClick(event, "dnp")
                }
              >
                Did not Pick Up
              </MenuItem>
              <MenuItem
                onClick={(event) =>
                  handleMenuItemClick(event, "not interested")
                }
              >
                Not Interested
              </MenuItem>
            </Menu>
          </Stack>
        </Stack>
      </Card>

      <Card sx={{ mt: 2, p: 2 }}>
        <Typography variant="body1" color="text.info" gutterBottom>
          Add a comment
        </Typography>
        <TextField
          label="Write a comment"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Card>

      <Card sx={{ mt: 2, p: 2 }}>
        <Typography variant="body1" color="text.info" gutterBottom>
          Call Again in (Optional)
        </Typography>
        <RadioGroup
          row
          aria-label="call-again"
          name="call-again"
          value={callAgain}
          onChange={handleCallAgain}
        >
          <FormControlLabel value="null" control={<Radio />} label="No" />
          <FormControlLabel value="1" control={<Radio />} label="1 day" />
          <FormControlLabel value="7" control={<Radio />} label="7 days" />
          <FormControlLabel value="15" control={<Radio />} label="15 days" />
          <FormControlLabel value="custom" control={<Radio />} label="Custom" />
        </RadioGroup>
        {callAgain === "custom" && (
          <Box sx={{ mt: 2 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDateTimePicker
                value={moment(callAgainValue || Date.now())}
                onAccept={(newValue) => {
                  if (newValue) {
                    setCallAgainValue(newValue.valueOf());
                  }
                }}
                closeOnSelect={false}
                format="DD-MM-YYYY hh:mm A"
              />
            </LocalizationProvider>
          </Box>
        )}
      </Card>

      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        onClick={updateComment}
      >
        Save
      </Button>
    </Card>
  );
}
