import {
  Container,
  Typography,
  Stack,
  Box,
  InputAdornment,
  OutlinedInput,
  CircularProgress,
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Tooltip,
  Slide,
  Button,
  Modal,
} from "@mui/material";

import { forwardRef, useEffect, useState } from "react";
import {
  AddCircleRounded,
  RefreshRounded,
  SearchRounded,
} from "@mui/icons-material";

import { styled } from "@mui/material/styles";
import { dataFetch } from "src/utils/data-fetch";
import { useAuth } from "src/contexts/AuthContext";
import { useSnackbar } from "src/contexts/SnackbarContext";

import AddDept from "./AddDept";
import DeptmentDetailsPage from "./DeptDetailsPage";
import MakeFullScreen from "src/components/MakeFullScreen";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TABLE_HEAD = [
  { id: "title", label: "Name", alignRight: false },
  { id: "description", label: "Description", alignRight: true },
];

export default function DepartmentsPage({ handleClose, drawerOpen }) {
  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [loadingData, setLoadingData] = useState(false);
  const [allData, setAllData] = useState([]);

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);

  let timer = "";

  const handleChangeQuery = (event) => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      setAllData([]);
      setPage(0);
      setSearch(event.target.value);
    }, 300);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchData = async () => {
    setLoadingData(true);

    let url = `/api/departments/mgract/query`;

    if (search && search.length > 0) {
      url += `&search=${search}`;
    }

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setAllData(ret.data);
      console.log(ret.data);
    } else {
      showSnackbar(ret.message || "Failed to fetch data", "error");
    }

    setLoadingData(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, search]);

  const [addDeptModalState, setAddDeptModalState] = useState(false);
  const [selectedDept, setSelectedDept] = useState(null);

  const d = {};
  allData.forEach((item) => {
    if (!item?.parent) {
      if (!d[item._id]) {
        d[item._id] = {
          children: [],
          data: item,
        };
      } else {
        d[item._id].data = item;
      }
    }
  });

  allData.forEach((item) => {
    if (item?.parent) {
      d[item.parent._id].children.push(item);
    }
  });

  return (
    <MakeFullScreen
      open={drawerOpen}
      onClose={handleClose}
      title={"DEPARTMENTS"}
      maxWidth={"sm"}
      header={
        <Button
          variant="contained"
          color="white"
          startIcon={<AddCircleRounded />}
          onClick={() => setAddDeptModalState(true)}
        >
          Add Dept
        </Button>
      }
    >
      <Container sx={{ minHeight: "500px" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          spacing={2}
        >
          <SearchStyle
            placeholder="Search Depts..."
            onChange={handleChangeQuery}
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>
                  <SearchRounded />
                </Box>
              </InputAdornment>
            }
          />

          {(loadingData === true && <CircularProgress color="secondary" />) || (
            <IconButton onClick={fetchData}>
              <RefreshRounded />
            </IconButton>
          )}
        </Stack>

        <TableContainer sx={{ minHeight: 200, borderRadius: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.alignRight ? "right" : "left"}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {Object.values(d)
                .sort((a, b) => a?.name?.localCompare(b?.name))
                .map((item) => (
                  <>
                    <SingleItem
                      item={item.data}
                      onClick={() => setSelectedDept(item.data)}
                    />
                    {item.children.map((child) => (
                      <SingleItem
                        item={child}
                        onClick={() => setSelectedDept(child)}
                      />
                    ))}
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>

      {addDeptModalState && (
        <Modal
          open={addDeptModalState}
          onClose={() => {
            setAddDeptModalState(false);
            fetchData();
          }}
        >
          <AddDept
            handleM1Close={() => {
              setAddDeptModalState(false);
              fetchData();
            }}
          />
        </Modal>
      )}
      {selectedDept && (
        <DeptmentDetailsPage
          dept={selectedDept}
          handleClose={() => setSelectedDept(null)}
          drawerOpen={selectedDept !== null}
        />
      )}
    </MakeFullScreen>
  );
}

const SingleItem = ({ item, onClick }) => {
  return (
    <Tooltip key={item._id} title={item?.description || ""} arrow>
      <TableRow
        hover
        onClick={onClick}
        sx={{
          cursor: "pointer",
          backgroundColor: item.active === false ? "grey.200" : "white",
          "&:hover": {
            backgroundColor: "grey.100",
          },
          transition: "background-color 0.3s ease",
        }}
      >
        <TableCell>
          {(!item.parent && (
            <Typography variant="h5">{item?.name || ""}</Typography>
          )) ||
            item?.name}
        </TableCell>
        <TableCell
          align="right"
          sx={{
            maxWidth: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {item?.description?.substring(0, 30) || ""}
        </TableCell>
      </TableRow>
    </Tooltip>
  );
};
