import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";
import { useSnackbar } from "src/contexts/SnackbarContext";

import {
  Card,
  Container,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  CircularProgress,
} from "@mui/material";

const TABLE_HEAD = [
  { id: "code", label: "Code", alignRight: false },
  { id: "details", label: "Details", alignRight: true },
];

export default function OfferSearch({ onDataSelected }) {
  const [cartData, setCartData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/offers/mgract/query`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCartData(ret.data);
    } else {
      showSnackbar(ret.message || "Failed to fetch data!", "error");
    }

    setLoadingData(false);
  };

  useEffect(() => {
    handleSearch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <Container>
        {loadingData && <CircularProgress />}
        {cartData.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {cartData.map((item) => {
                    return (
                      <TableRow hover onClick={() => onDataSelected(item)}>
                        <TableCell>
                          <b>{item?.promocode || "-"}</b>
                        </TableCell>
                        <TableCell>{item?.description || "-"}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </Container>
    </Card>
  );
}
