import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import {
  Alert,
  Stack,
  TextField,
  Typography,
  AppBar,
  Toolbar,
  Slide,
  Dialog,
  Button,
  Switch,
  IconButton,
  Modal,
  MenuItem,
  InputAdornment,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";
import {
  AddCircleOutline,
  CloseRounded,
  RemoveCircleOutline,
  UploadRounded,
} from "@mui/icons-material";

import { forwardRef } from "react";
import UploadNotifImage from "./UploadNotifImage";
import { TWENTY4HOURS } from "src/utils/date-time-helper";
import { DateSelector } from "src/utils/date-selector";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddInAppMsgModal({
  drawerOpen,
  notification,
  handleM1Close,
}) {
  const [error, setError] = useState();
  const [times, setTimes] = useState(10);
  const { getAccessToken, activeFranchise } = useAuth();
  const minInterval = notification?.min_interval / (60 * 60 * 1000) || 1;

  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState(Date.now() + TWENTY4HOURS);
  const [inAppType, setInAppType] = useState(
    notification?.inapp_type || "full"
  );
  const [interval, setInterval] = useState(minInterval || 1);

  const approveNow = async (values) => {
    const payload = {
      title: values.title,
      body: values.body,
      inapp_type: inAppType,
      times: times,
      start: startDate,
      end: endDate,
      min_interval: values.min_interval * 60 * 60 * 1000,
    };

    if (imageAvailable) {
      payload.imageUrl = values.imageUrl;
    }

    if (actionButtonAvailable && values?.link?.length > 0) {
      payload.link = values.link;
      payload.link_text = values.link_text || "View";
    }

    try {
      const ret = await dataPost(
        `/api/sendnotification/mgract/newinapp`,
        await getAccessToken(),
        payload,
        activeFranchise
      );

      if (ret.success === true) {
        console.log("Success:", ret.data);
        formik.resetForm();
        handleM1Close();
      } else {
        setError(ret.message + " - " + ret.code);
        console.error("API Error:", ret.message + " - " + ret.code); // Debugging
      }
    } catch (err) {
      setError("Unexpected error: " + err.message);
      console.error("Unexpected error:", err); // Debugging
    }
  };

  const DataSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    body: Yup.string().required("Body is required"),
    link: Yup.string().optional(),
    link_text: Yup.string().optional(),
    imageUrl: Yup.string().optional(),
    times: Yup.number().required("Times is required"),
    min_interval: Yup.number().required("Min Interval is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: notification?.title || "",
      body: notification?.body || "",
      link: notification?.link || "",
      link_text: notification?.link_text || "",
      imageUrl: notification?.imageUrl || "",
      inapp_type: notification?.inapp_type || inAppType,
      times: notification?.times || times,
      min_interval: interval,
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      console.log("Submitting form...", values); // Debugging
      try {
        await approveNow(values);
      } catch (error) {
        console.error("Submission error:", error); // Debugging
      }
    },
  });

  const handleIncrement = () => {
    setTimes(times + 1);
  };

  const handleDecrement = () => {
    setTimes(Math.max(1, times - 1));
  };

  const handleIntervalIncrement = () => {
    setInterval(interval + 1);
  };

  const handleIntervalDecrement = () => {
    setInterval(Math.max(1, interval - 1));
  };

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const [actionButtonAvailable, setActionButtonAvailable] = useState(false);
  const [imageAvailable, setImageAvailable] = useState(
    notification?.imageUrl ? true : false
  );

  const [uploadModalState, setUploadModalState] = useState(false);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="body"
      open={drawerOpen}
      onClose={handleM1Close}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            Add InApp Msg
          </Typography>
          <Button variant="contained" color="error" onClick={handleM1Close}>
            <CloseRounded />
          </Button>
        </Toolbar>
      </AppBar>

      <Stack direction="column" spacing={2} p={2}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextField
                autoComplete="off"
                fullWidth
                type="text"
                label="Title"
                {...getFieldProps("title")}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />

              <TextField
                fullWidth
                multiline
                type="text"
                label="Body"
                {...getFieldProps("body")}
                error={Boolean(touched.body && errors.body)}
                helperText={touched.body && errors.body}
              />

              <Stack direction="row" spacing={2} alignItems={"center"}>
                Action Button?
                <Switch
                  checked={actionButtonAvailable}
                  onChange={() =>
                    setActionButtonAvailable(!actionButtonAvailable)
                  }
                />
                {actionButtonAvailable === true && (
                  <TextField
                    fullWidth
                    type="text"
                    label="Link Text"
                    {...getFieldProps("link_text")}
                    error={Boolean(touched.link_text && errors.link_text)}
                    helperText={touched.link_text && errors.link_text}
                  />
                )}
              </Stack>
              {actionButtonAvailable === true && (
                <TextField
                  fullWidth
                  multiline
                  type="link"
                  label="Link"
                  {...getFieldProps("link")}
                  error={Boolean(touched.link && errors.link)}
                  helperText={touched.link && errors.link}
                />
              )}

              <Stack direction="row" spacing={2} alignItems={"center"}>
                Image?
                <Switch
                  checked={imageAvailable}
                  onChange={() => setImageAvailable(!imageAvailable)}
                />
                {imageAvailable === true && (
                  <>
                    <TextField
                      fullWidth
                      type="text"
                      label="Image URL or upload"
                      {...getFieldProps("imageUrl")}
                      error={Boolean(touched.imageUrl && errors.imageUrl)}
                      helperText={touched.imageUrl && errors.imageUrl}
                    />
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setUploadModalState(true);
                      }}
                    >
                      <UploadRounded />
                    </IconButton>
                  </>
                )}
              </Stack>

              <TextField
                fullWidth
                select
                label="In app message type"
                value={inAppType}
                onChange={(e) => setInAppType(e.target.value)}
              >
                <MenuItem value={"full"}>Full</MenuItem>
                <MenuItem value={"top"}>Top</MenuItem>
                <MenuItem value={"banner"}>Banner</MenuItem>
              </TextField>

              <TextField
                fullWidth
                type="number"
                label="Show how many times?"
                value={times}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={handleDecrement}>
                        <RemoveCircleOutline />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleIncrement}>
                        <AddCircleOutline />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.times && errors.times)}
                helperText={touched.times && errors.times}
              />

              <Stack
                direction="row"
                spacing={2}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <DateSelector
                  value={startDate}
                  onChange={setStartDate}
                  title="Start Date"
                />

                <DateSelector
                  value={endDate}
                  onChange={setEndDate}
                  title="End Date"
                />
              </Stack>

              <TextField
                fullWidth
                type="number"
                label="Time Interval"
                value={interval}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={handleIntervalDecrement}>
                        <RemoveCircleOutline />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <span style={{ marginRight: 8 }}>{interval} Hours</span>{" "}
                      <IconButton onClick={handleIntervalIncrement}>
                        <AddCircleOutline />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.times && errors.times)}
                helperText={touched.times && errors.times}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>
      </Stack>

      {error && (
        <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      <Modal open={uploadModalState} onClose={() => setUploadModalState(false)}>
        <UploadNotifImage
          handleM1Close={() => setUploadModalState(false)}
          returnUrl={(url) => setFieldValue("imageUrl", url)}
        />
      </Modal>
    </Dialog>
  );
}
