import { useState, useRef } from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
  Switch,
  OutlinedInput,
  TextField,
} from "@mui/material";

import "https://cdnjs.cloudflare.com/ajax/libs/Leaflet.awesome-markers/2.0.2/leaflet.awesome-markers.js";

import Page from "../../components/Page";
import { useSocket } from "../../contexts/SocketContext";

import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  Marker,
  Polygon,
  Tooltip,
  Popup,
  useMapEvent,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-draw/dist/leaflet.draw.css";

import { EditControl } from "react-leaflet-draw";
import AddZoneModal from "../apartment/addzone/AddZoneModal";
import UserPopup from "./UserPopup";
import ApartmentPopup from "./ApartmentPopup";
import { styled } from "@mui/material/styles";
import CustomerSearch from "../home/CustomerSearch";
import LeafletControlGeocoder from "../apartment/addapt/LeafletControlGeocoder";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function AdminMap() {
  const { users, apartmentData, cartData } = useSocket();

  const [zoneEnabled, setZoneEnabled] = useState(false);
  const [apartmentEnabled, setApartmentEnabled] = useState(false);
  const [customerEnabled, setCustomerEnabled] = useState(false);
  const [inActiveCust, setInActiveCust] = useState(false);
  const [cartEnabled, setCartEnabled] = useState(true);
  const [selectedCart, setSelectedCart] = useState("all");
  const [selectedCartName, setSelectedCartName] = useState("All");

  const handleCartChange = (event) => {
    setSelectedCart(event.target.value);
    if (event.target.value === "all") {
      setSelectedCartName("All");
    }
    if (event.target.value === "none") {
      setSelectedCartName("None");
    }
    if (event.target.value !== "all" && event.target.value !== "none") {
      setSelectedCartName(cartData[event.target.value].name);
    }
  };

  return (
    <Page title="Map">
      <Stack direction={"column"} flex={1} flexDirection={"column"}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={0.5}
          spacing={1}
          px={3}
        >
          <Stack direction={"row"} spacing={1}>
            <Stack direction="row" alignItems="center">
              <Typography variant="button">Zones</Typography>
              <Switch
                color="warning"
                checked={zoneEnabled}
                onChange={() => setZoneEnabled(!zoneEnabled)}
              />
            </Stack>

            <Stack direction="row" alignItems="center">
              <Typography variant="button">Apartments</Typography>
              <Switch
                color="warning"
                checked={apartmentEnabled}
                onChange={() => setApartmentEnabled(!apartmentEnabled)}
              />
            </Stack>

            <Stack direction="row" alignItems="center">
              <Typography variant="button">Customers</Typography>
              <Switch
                color="warning"
                checked={customerEnabled}
                onChange={() => setCustomerEnabled(!customerEnabled)}
              />
            </Stack>

            <Stack direction="row" alignItems="center">
              <Typography variant="button">Inactive Customers</Typography>
              <Switch
                color="warning"
                checked={inActiveCust}
                onChange={() => setInActiveCust(!inActiveCust)}
              />
            </Stack>
          </Stack>
          <TextField
            select
            id="cart-select"
            label="Cart"
            value={selectedCart}
            onChange={handleCartChange}
            sx={{ minWidth: 100 }}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="none">
              <em>None</em>
            </MenuItem>

            {Object.keys(cartData).map((cart) => (
              <MenuItem key={cart} value={cart}>
                {cartData[cart].name}
              </MenuItem>
            ))}
          </TextField>
        </Stack>

        <div style={{ flexGrow: 1 }} id="map-wrapper">
          <SimpleMap
            users={Object.values(users)}
            zones={Object.values(apartmentData)}
            customerEnabled={customerEnabled}
            inActiveCust={inActiveCust}
            zoneEnabled={zoneEnabled}
            apartmentEnabled={apartmentEnabled}
            selectedCart={selectedCart}
            cartName={selectedCartName}
          />
        </div>
      </Stack>

      {/* <UserDetailsModal
        isOpen={userModalOpen}
        handleUmClose={handleUmClose}
        detail={currentUser}
        loading={loadingUser}
        cart_data={cartData}
        franchisee_data={franchiseeData}
      /> */}
    </Page>
  );
}

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png",
});

let polyline;

const SimpleMap = ({
  users,
  customerEnabled,
  inActiveCust,
  zones,
  zoneEnabled,
  apartmentEnabled,
  selectedCart,
  cartName,
}) => {
  // const icon1 = new L.Icon.Default;
  const icon2 = new L.Icon({
    iconUrl:
      "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png",
    iconRetinaUrl:
      "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png",
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(20, 25),
    popupAnchor: [0, -30],
  });

  const icon1 = new L.Icon({
    iconUrl:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-grey.png",
    iconRetinaUrl:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-grey.png",
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(20, 25),
    popupAnchor: [0, -30],
  });

  const aptIcon = (link) => {
    const icon = new L.Icon({
      iconUrl: link,
      iconRetinaUrl: link,
      iconAnchor: null,
      popupAnchor: null,
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(35, 35),
      popupAnchor: [0, -30],
    });

    return icon;
  };

  const [polygon, setPolygon] = useState(null);
  const [markerPos, setMarkerPos] = useState(null);
  const [addZoneModalState, setAddZoneModalState] = useState(false);

  const handlePolygonCreated = (e) => {
    const { layer } = e;
    setPolygon(layer);

    let op = [];
    let centerCoords = [];

    if (layer) {
      layer.getLatLngs()[0].forEach((latLng) => {
        op.push([latLng.lng, latLng.lat]);
      });
      const c = layer.getCenter();
      centerCoords = [c.lng, c.lat];
    }

    setAddZoneModalState(true);
  };

  const EventHandler = () => {
    useMapEvent("dblclick", (e) => {
      setMarkerPos(e.latlng);
    });

    return null;
  };

  const mapRef = useRef(null);

  return (
    <div style={{ height: "85vh", display: "flex", flexDirection: "column" }}>
      <MapContainer
        center={[12.90152691, 77.69154668]}
        zoom={15}
        ref={mapRef}
        style={{ height: "100vh", width: "100%", flexGrow: 1 }}
      >
        <EventHandler />

        <LeafletControlGeocoder onLocationSelect={(latlng, name) => {}} />

        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <FeatureGroup>
          <EditControl
            position="topright"
            onCreated={handlePolygonCreated}
            onDeleted={() =>
              handlePolygonCreated({ layer: null, center: null })
            }
            draw={{
              rectangle: false,
              circle: true,
              circlemarker: false,
              marker: false,
              polyline: false,
              polygon: true,
            }}
            edit={{ edit: false }}
          />
        </FeatureGroup>

        <FeatureGroup>
          {zones.map((zone) => {
            if (zone?.category != "zone" && selectedCart === "all") {
              return (
                apartmentEnabled && (
                  <Marker
                    position={{
                      lat: zone?.location?.coordinates[1],
                      lng: zone?.location?.coordinates[0],
                    }}
                    key={zone._id}
                    icon={aptIcon(
                      zone?.image_url ||
                        "https://milkvilla.in/images/check-tick-icon-14178.png"
                    )}
                  >
                    <Tooltip direction="top">{zone?.name}</Tooltip>
                    <Popup minWidth={"500px"}>
                      <ApartmentPopup dataId={zone?._id} />
                    </Popup>
                  </Marker>
                )
              );
            } else if (
              zone?.category != "zone" &&
              selectedCart === zone?.cart
            ) {
              return (
                apartmentEnabled && (
                  <Marker
                    position={{
                      lat: zone?.location?.coordinates[1],
                      lng: zone?.location?.coordinates[0],
                    }}
                    key={zone._id}
                    icon={aptIcon(
                      zone?.image_url ||
                        "https://milkvilla.in/images/mv_new_logo.jpg"
                    )}
                  >
                    <Tooltip direction="top">{zone?.name}</Tooltip>
                    <Popup minWidth={"500px"}>
                      <ApartmentPopup dataId={zone?._id} />
                    </Popup>
                  </Marker>
                )
              );
            }
          })}
        </FeatureGroup>

        <FeatureGroup>
          {zones.map((zone) => {
            if (
              zoneEnabled &&
              zone?.category === "zone" &&
              selectedCart === "all" &&
              zone?.geometry
            ) {
              const coords = [];

              zone.geometry.coordinates[0].forEach((coord) => {
                coords.push({ lat: coord[1], lng: coord[0] });
              });
              return <Polygon positions={coords} color="blue" key={zone._id} />;
            } else if (
              zoneEnabled &&
              zone?.category === "zone" &&
              selectedCart === zone?.cart &&
              zone?.geometry
            ) {
              const coords = [];

              zone.geometry.coordinates[0].forEach((coord) => {
                coords.push({ lat: coord[1], lng: coord[0] });
              });
              return <Polygon positions={coords} color="blue" key={zone._id} />;
            } else return <></>;
          })}
        </FeatureGroup>

        <FeatureGroup>
          {customerEnabled &&
            users.map((user) => {
              if (
                user?.address &&
                user?.address?.lat &&
                user?.address?.lng &&
                user?.cart === selectedCart
              ) {
                if (user?.active === true) {
                  return (
                    <Marker
                      position={{
                        lng: user.address.lng,
                        lat: user.address.lat,
                      }}
                      key={user._id}
                      icon={icon2}
                    >
                      <Popup minWidth={"500px"}>
                        <UserPopup dataId={user?._id} />
                      </Popup>
                      <Tooltip direction="top">{user?.name}</Tooltip>
                    </Marker>
                  );
                } else if (inActiveCust === true) {
                  return (
                    <Marker
                      position={{
                        lng: user.address.lng,
                        lat: user.address.lat,
                      }}
                      key={user._id}
                      icon={icon1}
                    >
                      <Popup minWidth={"500px"}>
                        <UserPopup dataId={user?._id} />
                      </Popup>

                      <Tooltip direction="top">{user?.name}</Tooltip>
                    </Marker>
                  );
                } else {
                  return <></>;
                }
              } else if (
                user?.address &&
                user?.address?.lat &&
                user?.address?.lng &&
                selectedCart === "all"
              ) {
                if (user?.active === true) {
                  return (
                    <Marker
                      position={{
                        lng: user.address.lng,
                        lat: user.address.lat,
                      }}
                      key={user._id}
                      icon={icon2}
                    >
                      <Popup>
                        <UserPopup dataId={user?._id} />
                      </Popup>
                      <Tooltip direction="top">{user?.name}</Tooltip>
                    </Marker>
                  );
                } else if (inActiveCust === true) {
                  return (
                    <Marker
                      position={{
                        lng: user.address.lng,
                        lat: user.address.lat,
                      }}
                      key={user._id}
                      icon={icon1}
                    >
                      <Popup minWidth={"500px"}>
                        <UserPopup dataId={user?._id} />
                      </Popup>
                      <Tooltip direction="top">{user?.name}</Tooltip>
                    </Marker>
                  );
                }
              }
            })}
        </FeatureGroup>

        <Summary
          users={users}
          zones={zones}
          selectedCart={selectedCart}
          cartName={cartName}
        />
      </MapContainer>

      <AddZoneModal
        handleM1Close={() => setAddZoneModalState(false)}
        drawerOpen={addZoneModalState}
        polygon={polygon}
      />
    </div>
  );
};

const Summary = ({ users, zones, selectedCart, cartName }) => {
  let apartmentsCount = 0;
  let usersCount = 0;
  let zonesCount = 0;

  if (selectedCart === "all") {
    apartmentsCount = zones?.filter(
      (zone) => zone?.category !== "zone"
    )?.length;
    usersCount = users?.length;
    zonesCount = zones?.filter((zone) => zone?.category === "zone")?.length;
  } else {
    apartmentsCount = zones?.filter(
      (zone) => zone?.category !== "zone" && zone?.cart === selectedCart
    )?.length;
    usersCount = users?.filter((user) => user?.cart === selectedCart)?.length;
    zonesCount = zones?.filter(
      (zone) => zone?.category === "zone" && zone?.cart === selectedCart
    )?.length;
  }

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        position: "absolute",
        bottom: 30,
        right: 10,
        zIndex: 1000,
      }}
    >
      <Typography variant="h6">{cartName} Summary</Typography>
      <Typography variant="body1">Apartments: {apartmentsCount}</Typography>
      <Typography variant="body1">Users: {usersCount}</Typography>
      <Typography variant="body1">Zones: {zonesCount}</Typography>
    </Paper>
  );
};
