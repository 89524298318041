import { useEffect } from "react";

import { Stack, Container, Typography, Grid } from "@mui/material";
import Page from "../../components/Page";

import DispenseToday from "./TodayDispense";

import { useAuth } from "src/contexts/AuthContext";

export default function Dispense() {
  const { activeFranchise } = useAuth();

  const initialLoad = async () => {
    // const ret = await dataFetch(
    //   `/api/dispenses/adminact/stats/bydate/${startDate}/${
    //     startDate + TWENTY4HOURS
    //   }`,
    //   await getAccessToken()
    // );
    // if (ret.success === true) {
    //   setStatsData(ret.data);
    //   setStatsMessage("");
    // } else {
    //   setStatsMessage(ret.message + "/n" + ret.code);
    // }
    // setLoadingStats(false);
  };

  useEffect(() => {
    initialLoad();
  }, [activeFranchise]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page title="Dispense">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Dispense
          </Typography>
        </Stack>

        <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={6} md={3}>
            {loadingStats === true ? (
              <TopCards data={"Loading..."} text={"Milk Sale"} />
            ) : (
              <TopCards
                data={statsData.total_milk_sale.toFixed(1) + " Lt"}
                text={"Milk Sale"}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {loadingStats === true ? (
              <TopCards data={"Loading..."} text={"Cash In"} />
            ) : (
              <TopCards
                data={"₹" + statsData.total_cash_in.toFixed(0)}
                text={"Cash In"}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {loadingStats === true ? (
              <TopCards data={"Loading..."} text={"Milk Error"} />
            ) : (
              <TopCards
                data={"₹" + statsData.total_milk_error.toFixed(1)}
                text={"Milk Error"}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {loadingStats === true ? (
              <TopCards data={"Loading..."} text={"Sale Error"} />
            ) : (
              <TopCards
                data={"₹" + statsData.total_sale_error.toFixed(1)}
                text={"Sale Error"}
              />
            )}
          </Grid> */}

          <Grid item xs={12} sm={12} md={12}>
            <DispenseToday />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
