import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";
import { InputAdornment, MenuItem, Select } from "@mui/material";
import {
  Alert,
  Stack,
  TextField,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Dialog,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";
import { CloseRounded } from "@mui/icons-material";

import { forwardRef } from "react";
import { useSnackbar } from "src/contexts/SnackbarContext";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddBulkProductModal({ modalOpen, handleClose }) {
  const [error, setError] = useState();

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const approveNow = async (values) => {
    const ret = await dataPost(
      `/api/products/mgract/b2b/add`,
      await getAccessToken(),
      {
        name: values.name,
        bio: values.bio,
        price: values.price,
        stock_type: values.stock_type,
        gst_rate: values.gst_per,
        hsn_code: values.hsn_code,
        weight: values.weight,
        quantum: values.quantum,
        offer_text: values.offer_text,
        order_type: values.order_type,
      },
      activeFranchise
    );
    if (ret.success === true) {
      showSnackbar("Product added. View in B2B dashboard", "success");
      handleClose();
    } else {
      showSnackbar(ret.message || "Failed to add product", "error");
    }
  };

  const DataSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    bio: Yup.string().required("Bio is required"),
    price: Yup.number().required("Price is required"),
    order_type: Yup.number().required("Order Type is required"),
    stock_type: Yup.number().required("Stock type is required"),
    gst_app: Yup.boolean().required("GST Applicable is required"),
    gst_per: Yup.number()
      .when("gst_app", {
        is: (val) => val === 1,
        then: (schema) => schema.required("GST Percentage is required"),
        otherwise: (schema) => schema,
      })
      .max(30)
      .min(0),
    hsn_code: Yup.number().required("HSN Code is required"),
    weight: Yup.number().when("stock_type", {
      is: (val) => val === 0,
      then: (schema) => schema.required("Weight is required"),
      otherwise: (schema) => schema,
    }),
    quantum: Yup.number().required("Quantum is required"),
    offer_text: Yup.string().optional(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      bio: "",
      price: 0,
      order_type: 0,
      stock_type: "",
      gst_app: "",
      gst_per: 0,
      hsn_code: "",
      weight: 0,
      quantum: 1,
      offer_text: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="body"
      open={modalOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            Add Product For Bulk sale
          </Typography>
          <IconButton edge="end" color="black" onClick={handleClose}>
            <CloseRounded />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Stack direction="column" spacing={2} p={2}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextField
                autoComplete="off"
                fullWidth
                type="text"
                label="Product Name"
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
              <TextField
                autoComplete="off"
                fullWidth
                type="text"
                label="Bio"
                {...getFieldProps("bio")}
                error={Boolean(touched.bio && errors.bio)}
                helperText={touched.bio && errors.bio}
              />

            <TextField
                autoComplete="off"
                fullWidth
                type="text"
                label="Default Price"
                {...getFieldProps("price")}
                error={Boolean(touched.price && errors.price)}
                helperText={touched.price && errors.price}
              />

              <TextField
                fullWidth
                select
                label="GST Applicable"
                {...getFieldProps("gst_app")}
                error={Boolean(touched.gst_app && errors.gst_app)}
                helperText={touched.gst_app && errors.gst_app}
              >
                <MenuItem value="0">No</MenuItem>
                <MenuItem value="1">Yes</MenuItem>
              </TextField>

              {formik.values.gst_app === "1" && (
                <TextField
                  fullWidth
                  type="number"
                  label="GST Percentage"
                  {...getFieldProps("gst_per")}
                  error={Boolean(touched.gst_per && errors.gst_per)}
                  helperText={touched.gst_per && errors.gst_per}
                />
              )}

              <TextField
                fullWidth
                type="number"
                label="HSN Code"
                {...getFieldProps("hsn_code")}
                error={Boolean(touched.hsn_code && errors.hsn_code)}
                helperText={touched.hsn_code && errors.hsn_code}
              />

              <TextField
                fullWidth
                select
                label="Stock Type"
                {...getFieldProps("stock_type")}
                error={Boolean(touched.stock_type && errors.stock_type)}
                helperText={touched.stock_type && errors.stock_type}
              >
                <MenuItem value="0">Countable</MenuItem>
                <MenuItem value="1">Uncountable</MenuItem>
              </TextField>

              <TextField
                fullWidth
                select
                label="Order Type"
                {...getFieldProps("order_type")}
                error={Boolean(touched.order_type && errors.order_type)}
                helperText={touched.order_type && errors.order_type}
              >
                <MenuItem value="0">Normal</MenuItem>
                <MenuItem value="1">Subscription</MenuItem>
                <MenuItem value="2">Both</MenuItem>
              </TextField>

              {formik.values.stock_type === "0" && (
                <TextField
                  fullWidth
                  type="number"
                  label="Weight"
                  {...getFieldProps("wieght")}
                  error={Boolean(touched.weight && errors.weight)}
                  helperText={touched.weight && errors.weight}
                />
              )}

              <TextField
                fullWidth
                type="number"
                label="Quantum"
                {...getFieldProps("quantum")}
                error={Boolean(touched.quantum && errors.quantum)}
                helperText={touched.quantum && errors.quantum}
              />

              <TextField
                fullWidth
                type="text"
                label="Offer Text"
                {...getFieldProps("offer_text")}
                error={Boolean(touched.offer_text && errors.offer_text)}
                helperText={touched.offer_text && errors.offer_text}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>
      </Stack>

      {error && (
        <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Dialog>
  );
}
