import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";

import Label from "src/pages/employee/det/Label";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Stack,
  Tooltip,
} from "@mui/material";

const TABLE_HEAD = [
  { id: "account number", label: "Account Number", alignRight: false },
  { id: "ifsc", label: "IFSC", alignRight: false },
  { id: "beneficiary", label: "Beneficiary", alignRight: false },
];

export default function FarmerAccountSection({ farmer, defaultList = 10 }) {
  const [doc, setDoc] = useState({});
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const loadData = async () => {
    let url = `/api/onlinepayouts/mgract/fas/query?farmer=${farmer._id}&skip=${
      page * defaultList
    }&limit=${defaultList}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setDoc(ret.data);
      console.log(ret.data);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, [page, farmer]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h5" gutterBottom>
            Bank Account Details
          </Typography>

          {loadingData === true && <CircularProgress color="secondary" />}
        </Stack>

        {doc.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {doc.map((item) => {
                    return (
                      <TableRow>
                        <TableCell>
                          {item?.bank_account?.account_number}
                        </TableCell>
                        <TableCell>
                          <b> {item.ifsc}</b>
                        </TableCell>
                        <TableCell>
                          <b>{item.name}</b>{" "}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={-1}
              rowsPerPage={defaultList}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[defaultList]}
            />
          </div>
        )}
      </Container>
    </Card>
  );
}
