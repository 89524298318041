import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import {
  Stack,
  Typography,
  Card,
  CircularProgress,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tooltip,
} from "@mui/material";

import { dataFetch } from "src/utils/data-fetch";

import {
  getDayStartAndEndSync,
  getTimeStringFullSync,
} from "src/utils/date-time-helper";

import { DateSelector } from "src/utils/date-selector";

const TABLE_HEAD = [
  { id: "amount", label: "Amount", alignRight: false },
  { id: "category", label: "Category", alignRight: false },
  { id: "by", label: "By", alignRight: false },
  { id: "method", label: "Method", alignRight: false },
  { id: "time", label: "Time", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "comments", label: "Comments", alignRight: false },
];

export default function ExpenseHistoryPaginated() {
  const { getAccessToken, activeFranchise } = useAuth();

  const [selectedDate, setSelectedDate] = useState(Date.now());
  const { start, end } = getDayStartAndEndSync(selectedDate);

  const [detailExpense, setDetailExpense] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const load = async () => {
    setLoadingData(true);

    let url = `/api/expenses/expenses/mgract/query?date_gt=${start}&date_lt=${end}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setDetailExpense(ret.data);
      console.log("load", ret.data);
    } else {
      setDetailExpense([]);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    load();
  }, [activeFranchise, selectedDate]);

  return (
    <Card sx={{ pb: 3, pt: 1, px: 2, boxShadow: 3 }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography sx={{ my: 2 }} variant="h5">
          Expense History
        </Typography>

        {loadingData && <CircularProgress />}

        <DateSelector value={selectedDate} onChange={setSelectedDate} />
      </Stack>

      <TableContainer sx={{ minHeight: 100 }}>
        <Table>
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.alignRight ? "right" : "left"}
                  sortDirection={false}
                >
                  <b>{headCell.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {detailExpense.map((item, index) => {
              const color =
                item.status === "success"
                  ? "white"
                  : ["pending", "processing", "initiated", "approved"].includes(
                      item.status
                    )
                  ? "lightcyan"
                  : "lightsalmon";
              return (
                <TableRow key={index} hover style={{ backgroundColor: color }}>
                  <TableCell>
                    <b>₹ {item?.amount?.toFixed(2) || 0}</b>
                  </TableCell>
                  <TableCell sx={{ color: "primary.main" }}>
                    <b>{item?.to?.name}</b>
                  </TableCell>
                  <TableCell sx={{ color: "red" }}>
                    {item?.from?.name}
                  </TableCell>
                  <TableCell>{item?.method?.toUpperCase()}</TableCell>
                  <TableCell>{getTimeStringFullSync(item?.date)}</TableCell>
                  <TableCell>{item?.status?.toUpperCase()}</TableCell>
                  <Tooltip title={item?.comments} arrow>
                    <TableCell>{item?.comments?.substring(0, 15)}</TableCell>
                  </Tooltip>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {detailExpense.length === 0 && (
        <Typography textAlign={"center"}>No data found!</Typography>
      )}
    </Card>
  );
}
