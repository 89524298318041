import { Container, Typography, Stack, TextField } from "@mui/material";
import Page from "src/components/Page";

import ComplaintsList from "./ComplaintsList";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useState } from "react";
import moment from "moment";
import { getMonthStartAndEndSync } from "src/utils/date-time-helper";

export default function EmployeeComplaintsPage() {
  const [selectedMonth, setSelectedMonth] = useState(moment(Date.now()));
  const { start, end } = getMonthStartAndEndSync(selectedMonth);

  return (
    <Page title="Complaints">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography variant="h4">Complaints</Typography>

          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale={"en-in"}
          >
            <DesktopDatePicker
              views={["year", "month"]}
              value={selectedMonth}
              onChange={(date) => setSelectedMonth(date)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Stack>
        <div>
          <ComplaintsList startDate={start} endDate={end} />
        </div>
      </Container>
    </Page>
  );
}
