import { useState, useEffect } from "react";
import { useAuth } from "../../../contexts/AuthContext";

import { dataFetch } from "../../../utils/data-fetch";

import { MobileDatePicker } from "@mui/x-date-pickers";

import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ReactSpeedometer from "react-d3-speedometer";

import {
  Card,
  Alert,
  Typography,
  Stack,
  Grid,
  Box,
  CircularProgress,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  LinearProgress,
} from "@mui/material";

import { TWENTY4HOURS } from "src/utils/date-time-helper";
import {
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from "@mui/icons-material";

export default function EmployeeMonthlySummaryCard({
  employee,
  current = true,
}) {
  const [error, setError] = useState();
  const [doc, setDoc] = useState();
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const [currentDate, setCurrentDate] = useState(
    (current === true && Date.now()) || Date.now() - TWENTY4HOURS * 30
  );

  const loadData = async () => {
    let url = `/api/employees/mgract/employee/${employee.employee}/summary?date=${currentDate}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      console.log(ret.data);
      setDoc(ret.data);
    } else {
      setDoc(null);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, [currentDate, employee]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h4">{"Monthly Summary"}</Typography>

        <Stack direction={"row"} alignItems={"center"}>
          <IconButton
            sx={{ display: "inline-flex" }}
            onClick={() => {
              const newD = moment(currentDate).subtract(1, "month").valueOf();
              setCurrentDate(newD);
            }}
          >
            <KeyboardArrowLeftRounded />
          </IconButton>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale={"en-in"}
          >
            <MobileDatePicker
              views={["year", "month"]}
              value={moment(currentDate)}
              onAccept={(newValue) => {
                if (newValue) {
                  const d1 = newValue.valueOf();
                  if (currentDate !== d1) setCurrentDate(d1);
                }
              }}
              sx={{ maxWidth: "130px" }}
              closeOnSelect={true}
              format="MMM YYYY"
              loading={loadingData}
            />
          </LocalizationProvider>
          <IconButton
            sx={{ display: "inline-flex" }}
            onClick={() => {
              const newD = moment(currentDate).add(1, "month").valueOf();
              setCurrentDate(newD);
            }}
          >
            <KeyboardArrowRightRounded />
          </IconButton>
        </Stack>
      </Stack>

      {loadingData === true && <CircularProgress sx={{ mb: 3, mx: 3 }} />}
      {loadingData === false && doc && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Card sx={{ p: 3 }}>
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <ReactSpeedometer
                      maxValue={240}
                      value={Math.round(doc.work_hours)}
                      needleColor="black"
                      startColor="red"
                      forceRender={true}
                      endColor="green"
                      height={200}
                      textColor="black"
                      customSegmentStops={[0, 50, 100, 150, 200, 240]}
                      segmentColors={["red", "red", "red", "yellow", "green"]}
                    />

                    <Typography variant="h7">EFF WORK HOURS</Typography>
                    <Typography variant="h6">
                      {doc?.work_hours?.toFixed(1)} / 200 Hrs
                    </Typography>
                  </Stack>
                </Card>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <MorningEveningStats
                  text="MORNING STATS"
                  data1={doc.timely_attn_m}
                  data2={doc.late_attn_m}
                  data3={doc.timely_sale_start_m}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <MorningEveningStats
                  text="EVENING STATS"
                  data1={doc.timely_attn_e}
                  data2={doc.late_attn_e}
                  data3={doc.timely_sale_start_e}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <OtherStatsCard
                  data1={doc.cleanliness_score}
                  data2={doc.maintenance_score}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <SaleDetailsCard
                  data1={"₹" + doc.sales?.total_sale?.toFixed(1)}
                  data2={"₹" + doc.sales?.total_lend?.toFixed(1)}
                  data3={"₹" + doc.sales?.total_recover?.toFixed(1)}
                  data4={
                    "₹" +
                    (doc.sales?.total_lend - doc.sales?.total_recover)?.toFixed(
                      1
                    )
                  }
                  data5={"₹" + doc.sales?.total_error?.toFixed(1)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <SalaryCard
                  data1={"₹ " + doc.base_salary?.toFixed(1)}
                  data2={"₹ " + doc.var_salary?.toFixed(1)}
                  data3={"₹ " + doc.allowance?.toFixed(1)}
                  data4={"₹ " + doc.hisaab_deduct?.toFixed(1)}
                  data5={"₹ " + doc.penalty?.toFixed(1)}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <BonusCard
                  data1={"₹ " + doc.product_bonus?.toFixed(1)}
                  data2={"₹ " + doc.cleanliness_bonus?.toFixed(1)}
                  data3={"₹ " + doc.maintenance_bonus?.toFixed(1)}
                  data4={"₹ " + doc.ewh_bonus?.toFixed(1)}
                  data5={"₹ " + doc.attendance_bonus?.toFixed(1)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

const SalaryCard = ({ data1, data2, data3, data4, data5 }) => {
  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h6">SALARY DETAILS</Typography>
      <TableRow>
        <TableCell>
          <b>Base</b>
        </TableCell>
        <TableCell>
          <b>{data1 || "-"}</b>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <b>Sale Incentive</b>
        </TableCell>
        <TableCell>
          <b>{data2 || "-"}</b>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <b>Allowance</b>
        </TableCell>
        <TableCell>
          <b> {data3 || "-"} </b>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <b>Deduction</b>
        </TableCell>
        <TableCell>
          <b>{data4 || "-"}</b>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <b>Penalty</b>
        </TableCell>
        <TableCell>
          <b> {data5 || "-"}</b>
        </TableCell>
      </TableRow>
    </Card>
  );
};

const BonusCard = ({ data1, data2, data3, data4, data5 }) => {
  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h6">BONUSES</Typography>
      <TableRow>
        <TableCell>
          <b>Product Sale</b>
        </TableCell>
        <TableCell>
          <b>{data1 || "-"}</b>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <b>Cleanliness</b>
        </TableCell>
        <TableCell>
          <b>{data2 || "-"}</b>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <b>Maintenance</b>
        </TableCell>
        <TableCell>
          <b>{data3 || "-"}</b>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <b>Work Hour</b>
        </TableCell>
        <TableCell>
          <b>{data4 || "-"}</b>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <b>Attendance</b>
        </TableCell>
        <TableCell>
          <b>{data5 || "-"}</b>
        </TableCell>
      </TableRow>
    </Card>
  );
};

const SaleDetailsCard = ({ data1, data2, data3, data4, data5 }) => {
  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h6">SALE DETAILS</Typography>

      <TableBody>
        <TableRow>
          <TableCell>
            <b>Milk Sale</b>
          </TableCell>
          <TableCell>{data1}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Udhaar</b>
          </TableCell>
          <TableCell>{data2}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Wasooli</b>
          </TableCell>
          <TableCell>{data3}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Net Udhaar</b>
          </TableCell>
          <TableCell>{data4}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Milk Hisaab</b>
          </TableCell>
          <TableCell>{data5}</TableCell>
        </TableRow>
      </TableBody>
    </Card>
  );
};

const MorningEveningStats = ({ text, data1, data2, data3 }) => {
  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h6">{text}</Typography>

      <TableBody>
        <TableRow>
          <TableCell>
            <b>On Time Attn</b>
          </TableCell>
          <TableCell>{data1}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Late Attn</b>
          </TableCell>
          <TableCell>{data2}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Timely Del Start</b>
          </TableCell>
          <TableCell>{data3}</TableCell>
        </TableRow>
      </TableBody>
    </Card>
  );
};

const OtherStatsCard = ({ data1, data2 }) => {
  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h6">OTHER STATS</Typography>

      <TableBody>
        <TableRow>
          <TableCell>
            <b>Hygiene Score</b>
          </TableCell>
          <TableCell>{data1}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Repair Expense</b>
          </TableCell>
          <TableCell>{data2}</TableCell>
        </TableRow>
      </TableBody>
    </Card>
  );
};
