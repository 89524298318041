import { useState } from "react";
import { useLocation } from "react-router-dom";


import {
  Container,
  Typography,
  Box,
  Grid,
  Tab,
} from "@mui/material";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Page from "src/components/Page";
import ManagerProfileSection from "./ManagerProfileSection";
import ManagerTransactions from "./ManagerTransactions";
import { ManagerHisaabSection } from "./ManagerHisaabSection";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ManagerDetailsPage() {
  const location = useLocation();
  const manager = location.state.manager;

  const [currentMenu, setCurrentMenu] = useState("general");

  const handleTabChange = (event, newValue) => {
    setCurrentMenu(newValue);
  };

  return (
    <Page title="Manager Dashboard">
      <Container>
        <Grid container mb={4} spacing={4}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="h4">{manager.name}</Typography>
          </Grid>
        </Grid>

        <TabContext value={currentMenu}>
          <Box>
            <TabList onChange={handleTabChange}>
              <Tab label="Profile" value="general" {...a11yProps(0)} />
              <Tab label="Transactions" value="txn" {...a11yProps(1)} />
              <Tab label="Hisaab" value="hisaab" {...a11yProps(3)} />
            </TabList>
          </Box>
          <TabPanel value="general">
            <ManagerProfileSection manager={manager} />
          </TabPanel>
          <TabPanel value="txn">
            <ManagerTransactions manager={manager} />
          </TabPanel>
          <TabPanel value="hisaab">
            <ManagerHisaabSection manager={manager} />
          </TabPanel>
        </TabContext>
        <Grid container spacing={3}>
          <Grid item xs={3} md={3} lg={3}></Grid>
        </Grid>
      </Container>
    </Page>
  );
}
