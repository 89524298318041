import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import {
  Stack,
  TextField,
  Drawer,
  OutlinedInput,
  InputAdornment,
  Box,
  Container,
  Switch,
  Divider,
  Typography,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";

import { useSnackbar } from "src/contexts/SnackbarContext";
import MakeFullScreen from "src/components/MakeFullScreen";
import CategorySearch from "../CategorySearch";
import EmpSearch from "./EmpSearch";

export default function IssueItems({
  open,
  onClose,
  duplicate,
  category,
  checkedItems,
}) {
  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [categoryDrawerState, setCategoryDrawerState] = useState(false);
  const [selectCategory, setSelectCategory] = useState(category);

  const [empDrawerState, setEmpDrawerState] = useState(false);
  const [selectEmp, setSelectEmp] = useState(duplicate?.to || null);

  const [selectedItems, setSelectedItems] = useState({ ...checkedItems });

  const approveNow = async (values) => {
    if (!selectCategory || !selectCategory.name) {
      showSnackbar("Please select a category.", "error");
      return;
    }

    const upd = {
      name: values.name,
      category: selectCategory.name,
      to: selectEmp?.employee,
      unit: values.unit,
      unit_price: values.unit_price,
      min_stock: values.min_stock,
      perishable: values.perishable,
    };

    if (values.location.length > 0) {
      upd.location = values.location;
    }
    if (values.comments.length > 0) {
      upd.comments = values.comments;
    }
    if (values.details.length > 0) {
      upd.details = values.details;
    }

    const ret = await dataPost(
      `/api/inventory/mgract/items/add`,
      await getAccessToken(),
      upd,
      activeFranchise
    );
    if (ret.success === true) {
      showSnackbar(ret.message || "Item added.", "success");
      onClose();
    } else {
      showSnackbar(ret.message + " - " + ret.code, "error");
    }
  };

  const DataSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    details: Yup.string(),
    unit: Yup.string().required("Unit is required"),
    unit_price: Yup.number().required("Unit Price is required"),
    min_stock: Yup.number().required("Min Stock is required"),
    perishable: Yup.boolean(),
    location: Yup.string(),
    comments: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: duplicate?.name || "",
      details: duplicate?.details || "",
      unit: duplicate?.unit || "",
      unit_price: duplicate?.unit_price || "",
      min_stock: 0,
      perishable: false,
      location: duplicate?.location || "",
      comments: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  return (
    <MakeFullScreen
      title="Issue Items"
      open={open}
      onClose={onClose}
      maxWidth={"md"}
    >
      <Container sx={{ px: 2, pb: 2 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <OutlinedInput
                fullWidth
                type="text"
                value={
                  (selectCategory &&
                    `${selectCategory?.name} (${selectCategory?.code})`) ||
                  "None Selected"
                }
                onClick={() => setCategoryDrawerState(true)}
                startAdornment={
                  <InputAdornment position="start">
                    <Box sx={{ color: "text.disabled" }}>Category</Box>
                  </InputAdornment>
                }
              />

              <OutlinedInput
                fullWidth
                type="text"
                value={
                  (selectEmp && `${selectEmp?.name} (${selectEmp?.uin})`) ||
                  "None Selected"
                }
                onClick={() => setEmpDrawerState(true)}
                startAdornment={
                  <InputAdornment position="start">
                    <Box sx={{ color: "text.disabled" }}>Issue To</Box>
                  </InputAdornment>
                }
              />

              <Typography variant="h6" gutterBottom>
                Items
              </Typography>

              <Stack spacing={2} direction={"column"}>
                {Object.keys(selectedItems).map((k) => {
                  const item = selectedItems[k];

                  return (
                    <Stack spacing={2} direction={"row"} key={k}>
                      <TextField
                        fullWidth
                        type="text"
                        label="Name"
                        value={`${item.name} (${item.code})`}
                        disabled
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#000000",
                          },
                        }}
                      />
                      <TextField
                        label="Quantity"
                        value={item?.quantity}
                        type="number"
                        onChange={(e) => {
                          const val = e.target.value;
                          setSelectedItems({
                            ...selectedItems,
                            [k]: { ...item, quantity: val },
                          });
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {item.unit}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                  );
                })}
              </Stack>

              <TextField
                fullWidth
                multiline
                type="text"
                label="Comments"
                {...getFieldProps("comments")}
                error={Boolean(touched.comments && errors.comments)}
                helperText={touched.comments && errors.comments}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              ISSUE NOW
            </LoadingButton>
          </Form>
        </FormikProvider>

        <Drawer
          anchor={"right"}
          open={empDrawerState}
          onClose={() => setEmpDrawerState(false)}
          sx={{ zIndex: 1300 }}
          PaperProps={{
            sx: { width: "30%" },
          }}
        >
          <EmpSearch
            onEmpSelected={(dept) => {
              setEmpDrawerState(false);
              setSelectEmp(dept);
            }}
          />
        </Drawer>
        <Drawer
          anchor={"right"}
          open={categoryDrawerState}
          onClose={() => setCategoryDrawerState(false)}
          sx={{ zIndex: 1300 }}
          PaperProps={{
            sx: { width: "30%" },
          }}
        >
          <CategorySearch
            onCategorySelected={(dept) => {
              setCategoryDrawerState(false);
              setSelectCategory(dept);
            }}
          />
        </Drawer>
      </Container>
    </MakeFullScreen>
  );
}
