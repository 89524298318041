import React, { useState, useEffect } from "react";
import { useFormik, Form, FormikProvider, FieldArray } from "formik";
import * as Yup from "yup";
import {
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Stack,
  TextField,
  MenuItem,
  Button,
  Alert,
  InputAdornment,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch, dataPost } from "src/utils/data-fetch";
import MakeFullScreen from "src/components/MakeFullScreen";

const DataSchema = Yup.object().shape({
  product: Yup.string().required("Product is required"),
  default_rate: Yup.number().required("Default Rate is required"),
  conditions: Yup.array()
    .of(
      Yup.object().shape({
        quantity_min: Yup.number()
          .required("Min Quantity is required")
          .test(
            "is-greater-than-previous-max",
            "Min Quantity must be greater than the Max Quantity of the previous condition",
            function (value) {
              const { path, options } = this;
              console.log(path.split(/[.[\]]+/)[1]);
              const index = parseInt(path.split(/[.[\]]+/)[1], 10);

              if (index > 0) {
                const previousMaxQty =
                  options.context.conditions[index - 1].quantity_max;
                console.log(previousMaxQty);
                return value > previousMaxQty;
              }
              return true;
            }
          ),
        quantity_max: Yup.number()
          .required("Max Quantity is required")
          .moreThan(
            Yup.ref("quantity_min"),
            "Max Quantity must be greater than Min Quantity"
          ),
        rate: Yup.number().required("Rate is required"),
      })
    )
    .required()
    .min(1, "At least one deal condition is required"),
});

export default function AddNewDealModal({ party, handleM1Close }) {
  const { getAccessToken, activeFranchise } = useAuth();
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    getAllProducts();
  }, []);

  const getAllProducts = async () => {
    const url = `/api/products/mgract/b2b/query`;
    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success) {
      setProducts(ret.data);
    } else {
      console.log(ret.message);
    }
  };

  const AddDeals = async (values) => {
    console.log(values);

    const ret = await dataPost(
      `/api/extrasales/mgract/user/${party._id}/adddeal`,
      await getAccessToken(),
      values,
      activeFranchise
    );

    if (ret.success) {
      console.log(ret.data);

      handleM1Close();
    } else {
      setError(ret.message);
      console.log(ret.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      product: "",
      default_rate: "",
      conditions: [{ quantity_min: "", quantity_max: "", rate: "" }],
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      AddDeals(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, values } =
    formik;

  return (
    <MakeFullScreen
      open={true}
      onClose={handleM1Close}
      title={`Add Deals (${party.name})`}
      maxWidth={"sm"}
    >
      <Stack direction="column" spacing={2} p={2}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                select
                label="Product"
                {...getFieldProps("product")}
                error={Boolean(touched.product && errors.product)}
                helperText={touched.product && errors.product}
              >
                {products.map((product) => (
                  <MenuItem key={product._id} value={product._id}>
                    {product.name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                type="number"
                label="Default Rate"
                {...getFieldProps("default_rate")}
                error={Boolean(touched.default_rate && errors.default_rate)}
                helperText={touched.default_rate && errors.default_rate}
              />

              <FieldArray
                name="conditions"
                render={(arrayHelpers) => (
                  <div>
                    {values.conditions.map((_, index) => (
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        key={index}
                        spacing={2}
                        alignItems="center"
                        sx={{ mb: 2 }}
                      >
                        <TextField
                          label="Min Qty"
                          type="number"
                          {...getFieldProps(`conditions.${index}.quantity_min`)}
                          error={Boolean(
                            touched.conditions?.[index]?.quantity_min &&
                              errors.conditions?.[index]?.quantity_min
                          )}
                          helperText={
                            touched.conditions?.[index]?.quantity_min &&
                            errors.conditions?.[index]?.quantity_min
                          }
                          fullWidth
                        />

                        <TextField
                          label="Max Qty"
                          type="number"
                          {...getFieldProps(`conditions.${index}.quantity_max`)}
                          error={Boolean(
                            touched.conditions?.[index]?.quantity_max &&
                              errors.conditions?.[index]?.quantity_max
                          )}
                          helperText={
                            touched.conditions?.[index]?.quantity_max &&
                            errors.conditions?.[index]?.quantity_max
                          }
                          fullWidth
                        />

                        <TextField
                          label="Rate"
                          type="number"
                          {...getFieldProps(`conditions.${index}.rate`)}
                          error={Boolean(
                            touched.conditions?.[index]?.rate &&
                              errors.conditions?.[index]?.rate
                          )}
                          helperText={
                            touched.conditions?.[index]?.rate &&
                            errors.conditions?.[index]?.rate
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                ₹/kg
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                        />
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => arrayHelpers.remove(index)}
                          disabled={values.conditions.length === 1}
                        >
                          <CloseRounded />
                        </Button>
                      </Stack>
                    ))}

                    <Button
                      variant="contained"
                      sx={{ mt: 2 }}
                      onClick={() =>
                        arrayHelpers.push({
                          quantity_min: "",
                          quantity_max: "",
                          rate: "",
                        })
                      }
                    >
                      Add Another Deal
                    </Button>
                  </div>
                )}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>
      </Stack>

      {error && (
        <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </MakeFullScreen>
  );
}
