import React from "react";
import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";

import { Card, Grid, Typography, CircularProgress } from "@mui/material";

import {
  getDayStartAndEndSync,
  getTimeStringFullSync,
} from "src/utils/date-time-helper";

export const MaintenanceReportSection = ({ currentDate }) => {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const handleSearch = async () => {
    setLoadingData(true);
    const a = getDayStartAndEndSync(currentDate || Date.now());

    let url = `/api/carttimeline/mgract/maintenances/query?&date_gt=${a.start}&date_lt=${a.end}&sort=date&dir=d`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
      console.log(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [activeFranchise, currentDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loadingData === true ? (
        <CircularProgress />
      ) : customerData.length === 0 ? (
        <Typography>No data</Typography>
      ) : null}
      <Grid container>
        {customerData.length > 0 &&
          customerData.map((item) => {
            return (
              <Grid item xs={12} md={6} lg={6}>
                <Card sx={{ px: 2, mx: 2, my: 1, py: 1 }}>
                  <Grid container spacing={0.5}>
                    <Grid item xs={6} md={6} lg={6}>
                      <b>{getTimeStringFullSync(item.date)}</b>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} textAlign={"right"}>
                      {item?.maintenance?.status?.toUpperCase() ?? "COMPLETED"}
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} color={"navy"}>
                      <b>{item?.cart?.name ?? "No Cart"}</b> (
                      {item?.employee?.name ?? "No Employee"})
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} color={"navy"}>
                      {item?.vehicle?.name ?? "No Vehicle"}
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} color={"red"}>
                      Problem:{" "}
                      {item?.maintenance?.description ?? "No Description"}
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} color={"black"}>
                      {item?.maintenance?.technician ?? "No Technician"} - Cost:
                      ₹ {item?.maintenance?.cost ?? " 0"}
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} color={"darkgreen"}>
                      Parts: {item?.maintenance?.parts ?? "No parts used"}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};
