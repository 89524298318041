import {
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataDelete, dataFetch } from "src/utils/data-fetch";
import { AddRounded, DeleteRounded, RefreshRounded } from "@mui/icons-material";
import AddNewDealModal from "./AddDealsModal";

const TABLE_HEAD = [
  { id: "product_name", label: "Product Name", alignRight: false },
  { id: "conditions", label: "Conditions", alignRight: false },
];

const CONDITION_TABLE_HEAD = [
  { id: "min_qty", label: "Min Qty", alignRight: false },
  { id: "max_qty", label: "Max Qty", alignRight: false },
  { id: "rate", label: "Rate", alignRight: false },
];

export default function B2bDealDetails({ party }) {
  const [loadingData, setLoadingData] = useState(false);
  const [deals, setDeals] = useState([]);
  const { getAccessToken, activeFranchise } = useAuth();
  const [openDelteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState(null);

  const handleClose = () => {
    setOpenDeleteDialog(false);
  };

  const loadData = async () => {
    setLoadingData(true);
    let url = `/api/extrasales/mgract/user/${party._id}/deals`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setDeals(ret.data);
    }
    setLoadingData(false);
  };


  const deleteDeal = async (dealId) => {
    setLoadingData(true);
    let url = `/api/extrasales/mgract/deals/deal/${dealId}`;

    const ret = await dataDelete(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      loadData();
      handleClose();
    }
    setLoadingData(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const [addDealModalState, setAddDealModalState] = useState(false);

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
        mt={2}
      >
        <Typography variant="h5" gutterBottom>
          B2B Deals - {party?.name || "No Name"}
        </Typography>
        <Stack direction="row">
          <IconButton onClick={() => setAddDealModalState(true)}>
            <AddRounded />
          </IconButton>
          {loadingData ? (
            <CircularProgress color="secondary" />
          ) : (
            <IconButton onClick={loadData}>
              <RefreshRounded />
            </IconButton>
          )}
        </Stack>
      </Stack>

      {deals.length > 0 ? (
        <TableContainer sx={{ minHeight: 100 }}>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.alignRight ? "right" : "left"}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {deals.map((deal) => (
                <TableRow key={deal._id}>
                  <TableCell>
                    <Typography variant="h6">{deal?.product?.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {CONDITION_TABLE_HEAD.map((headCell) => (
                              <TableCell
                                key={headCell.id}
                                align={headCell.alignRight ? "right" : "left"}
                              >
                                {headCell.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {deal.conditions.map((condition, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {condition?.quantity_min} KG
                              </TableCell>
                              <TableCell>
                                {condition?.quantity_max} KG
                              </TableCell>
                              <TableCell>
                                <b>₹ {condition?.rate} /-</b>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      padding: "0",
                      width: "50px",
                      verticalAlign: "middle",
                    }}
                  >
                    <DeleteRounded
                      sx={{ cursor: "pointer", color: "red" }} // Optional styles for the icon
                      onClick={() => {
                        setSelectedDeal(deal);
                        setOpenDeleteDialog(true);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        !loadingData && (
          <Typography align="center" sx={{ mt: 4, mb: 4 }}>
            No deals available for this product.
          </Typography>
        )
      )}

      {addDealModalState && (
        <AddNewDealModal
          party={party}
          handleM1Close={() => setAddDealModalState(false)}
        />
      )}

      <Dialog open={openDelteDialog} onClose={handleClose}>
        <DialogTitle>Delete Deal</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this deal for{" "}
            <strong>{selectedDeal?.product?.name}</strong>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => deleteDeal(selectedDeal?._id)} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
