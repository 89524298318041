import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";
import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";
import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Switch,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { dataPatch, dataPost } from "src/utils/data-fetch";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

export default function AddInventoryModal({ handleM1Close, productId }) {
  const [error, setError] = useState();
  const [selectedDate, setSelectedDate] = useState(moment());

  const { getAccessToken, activeFranchise } = useAuth();

  const approveNow = async (values) => {
    let up = {
      comments: values.comments,
      quantity: values.quantity,
      price: values.price,
      category: values.category,
      timestamp: values.timestamp.valueOf(),
    };

    const ret = await dataPost(
      `/api/products/mgract/product/${productId}/inventory`,
      await getAccessToken(),
      up,
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    comments: Yup.string().optional(),
    quantity: Yup.number().required("Quantity is required"),
    price: Yup.number().required("Price is required"),
    category: Yup.number().required("Category is required"),
    timestamp: Yup.date().required("Timestamp is required"),
  });

  const formik = useFormik({
    initialValues: {
      comments: "",
      quantity: 0,
      price: 0,
      category: false, // false for "Remove", true for "Add"
      timestamp: selectedDate,
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Add Inventory
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                label="Comments"
                {...getFieldProps("comments")}
                error={Boolean(touched.comments && errors.comments)}
                helperText={touched.comments && errors.comments}
              />

              <TextField
                fullWidth
                label="Quantity"
                {...getFieldProps("quantity")}
                error={Boolean(touched.quantity && errors.quantity)}
                helperText={touched.quantity && errors.quantity}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">kg</InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                label="Price"
                {...getFieldProps("price")}
                error={Boolean(touched.price && errors.price)}
                helperText={touched.price && errors.price}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
              />

              <RadioGroup
                row
                {...getFieldProps("category")}
                value={formik.values.category}
                onChange={(e) =>
                  formik.setFieldValue("category", e.target.value)
                }
              >
                <FormControlLabel value="0" control={<Radio />} label="Add" />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Remove"
                />
              </RadioGroup>

              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileDateTimePicker
                  label="Timestamp"
                  value={formik.values.timestamp}
                  onChange={(newValue) => {
                    setSelectedDate(newValue);
                    formik.setFieldValue("timestamp", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={Boolean(touched.timestamp && errors.timestamp)}
                      helperText={touched.timestamp && errors.timestamp}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
