import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCvrFSEbTYtPBuPP_taxgb7KTkCglULh2Q",
  authDomain: "my-business-grobo.firebaseapp.com",
  databaseURL: "https://my-business-grobo.firebaseio.com",
  projectId: "my-business-grobo",
  storageBucket: "my-business-grobo.appspot.com",
  messagingSenderId: "469397203661",
  appId: "1:469397203661:web:27469fd01715573653d784",
  measurementId: "G-QXBCWZKZX6",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;
