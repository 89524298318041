import {
  Grid,
  Container,
  Typography,
  Stack,
  Button,
  Modal,
  Box,
} from "@mui/material";

import Page from "src/components/Page";

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AdminVehicleSearch from "./AdminVehiclesSearch";
import AddVehicleModal from "src/pages/vehicle/AddVehicleModal";

export default function AdminVehicles() {
  const navigate = useNavigate();
  const [vehicleModalOpen, setVehicleModal] = useState(false);

  const closeVehicleModal = () => {
    setVehicleModal(false);
  };

  return (
    <Page title="Vehicle Dashboard">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography variant="h4">Vehicles Dashboard</Typography>
          <Stack direction="row">
            <Button variant="contained" onClick={() => setVehicleModal(true)}>
              Add Vehicles
            </Button>
          </Stack>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <AdminVehicleSearch />
          </Grid>
        </Grid>
      </Container>
      <Modal open={vehicleModalOpen} onClose={closeVehicleModal}>
        <AddVehicleModal handleM1Close={closeVehicleModal} />
      </Modal>
    </Page>
  );
}
