import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import {
  Card,
  Alert,
  Grid,
  Stack,
  Typography,
  MenuItem,
  Select,
  CircularProgress,
  TableRow,
  TableCell,
  TableBody,
  InputAdornment,
  Box,
} from "@mui/material";

import { dataFetch, dataPost } from "src/utils/data-fetch";

export default function DispensersDetailsCard({ dispenser }) {
  const [error, setError] = useState();

  const [currentData, setCurrentData] = useState();
  const [loadingData, setLoadingData] = useState();
  const [loadingData1, setLoadingData1] = useState();

  const { getAccessToken, activeFranchise, allFranchisees } = useAuth();
  const [fr, setFr] = useState("");

  const load = async (values) => {
    setLoadingData(true);
    setLoadingData1(true);

    const ret = await dataFetch(
      `/api/milkdispenser/coreact/dispenser/${dispenser}`,
      await getAccessToken()
    );
    if (ret.success === true) {
      console.log(ret.data);
      setCurrentData(ret.data);
      setFr(ret?.data?.franchisee?._id);
    } else {
      console.log(ret.message);
      setError(ret.message + " - " + ret.code);
    }
    setLoadingData(false);
    setLoadingData1(false);
  };

  useEffect(() => {
    load();
  }, []);

  const updateStatusInServer = async (status) => {
    console.log(status);
    setLoadingData1(true);

    let url = `/api/milkdispenser/coreact/dispenser/${dispenser}/fr`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      { franchisee: status },
      activeFranchise
    );
    if (ret.success === true) {
      setFr(status);
    } else {
      alert(ret.message);
    }
    setLoadingData1(false);
  }; // eslint-disable-line react-hooks/exhaustive-deps

  const handleFrChange = (event) => {
    updateStatusInServer(event.target.value);
  };

  return (
    <div>
      <Grid container columnSpacing={1} sx={{ pl: 2, pr: 2 }}>
        <Grid item xs={12} md={8} lg={8}>
          <Card sx={{ p: 3 }}>
            {(loadingData === true && <CircularProgress />) ||
              (currentData && (
                <Grid container columnSpacing={1} sx={{ pl: 2, pr: 2 }}>
                  <Grid item xs={12} md={6} lg={6}>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <b>SSID:</b>
                        </TableCell>
                        <TableCell>
                          <b>{currentData.ssid}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Category:</b>
                        </TableCell>
                        <TableCell>{currentData.category || "-"}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Pass:</b>
                        </TableCell>
                        <TableCell>{currentData.pass || "-"}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <b>Calib_Pass:</b>
                        </TableCell>
                        <TableCell>{currentData.calib_pass}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <b>Mac:</b>
                        </TableCell>
                        <TableCell>{currentData.mac}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Grid>
                </Grid>
              ))}

            {error && (
              <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
          </Card>
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          <Card sx={{ p: 2 }}>
            <Stack direction="row" alignItems="center" spacing={3}>
              <Select
                id="franchisee-select"
                value={fr}
                onChange={handleFrChange}
                placeholder="-"
                startAdornment={
                  <InputAdornment position="start">
                    <Box sx={{ color: "text.disabled" }}>Franchisee</Box>
                  </InputAdornment>
                }
              >
                {allFranchisees.map((item) => (
                  <MenuItem value={item._id}>{item.name}</MenuItem>
                ))}
              </Select>

              {loadingData1 === true && <CircularProgress />}
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
