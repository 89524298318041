import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { styled } from "@mui/material/styles";
import { dataFetch } from "src/utils/data-fetch";
import { useSnackbar } from "src/contexts/SnackbarContext";

import { SearchRounded } from "@mui/icons-material";

import {
  Container,
  Table,
  Box,
  OutlinedInput,
  InputAdornment,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "description", label: "Description", alignRight: true },
];

export default function DeptSearch({ onDeptSelected }) {
  const [deptData, setDeptData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/departments/mgract/all`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setDeptData(ret.data);
    } else {
      showSnackbar(ret.message || "Load failed!", "error");
    }

    setLoadingData(false);
  };

  useEffect(() => {
    handleSearch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const d = {};
  deptData.forEach((item) => {
    if (!item?.parent) {
      if (!d[item._id]) {
        d[item._id] = {
          children: [],
          data: item,
        };
      } else {
        d[item._id].data = item;
      }
    }
  });

  deptData.forEach((item) => {
    if (item?.parent) {
      d[item.parent._id].children.push(item);
    }
  });

  return (
    <Container>
      {deptData.length > 0 && (
        <div>
          <TableContainer sx={{ minHeight: 100 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.alignRight ? "right" : "left"}
                      sortDirection={false}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow
                  hover
                  onClick={() => onDeptSelected({ _id: null })}
                  style={{
                    backgroundColor: "#ddd",
                  }}
                >
                  <TableCell>
                    <b>Remove Assigned Dept</b>
                  </TableCell>
                  <TableCell>{"-"}</TableCell>
                </TableRow>
                {Object.values(d)
                  .sort((a, b) => a?.name?.localCompare(b?.name))
                  .map((item) => (
                    <>
                      <SingleItem
                        item={item.data}
                        onClick={() => onDeptSelected(item.data)}
                      />
                      {item.children.map((child) => (
                        <SingleItem
                          item={child}
                          onClick={() => onDeptSelected(child)}
                        />
                      ))}
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </Container>
  );
}

const SingleItem = ({ item, onClick }) => {
  return (
    <Tooltip key={item._id} title={item?.description || ""} arrow>
      <TableRow
        hover
        onClick={onClick}
        sx={{
          cursor: "pointer",
          backgroundColor: item.active === false ? "grey.200" : "white",
          "&:hover": {
            backgroundColor: "grey.100",
          },
          transition: "background-color 0.3s ease",
        }}
      >
        <TableCell>
          {(!item.parent && (
            <Typography variant="h5">{item?.name || ""}</Typography>
          )) ||
            item?.name}
        </TableCell>
        <TableCell
          align="right"
          sx={{
            maxWidth: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {item?.description?.substring(0, 30) || "-"}
        </TableCell>
      </TableRow>
    </Tooltip>
  );
};
