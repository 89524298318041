import {
  Grid,
  Container,
  Typography,
  Stack,
  Button,
  Modal,
  Box,
} from "@mui/material";

import Page from "src/components/Page";

import AddFranchiseeModal from "./AddFranchiseeModal";
import { useState } from "react";
import FranchiseeList from "./FranchiseeList";
import { useNavigate } from "react-router-dom";

export default function AdminFranchisee() {
  const navigate = useNavigate();

  const openFrDetailsPage = (item) => {
    navigate("details", { state: { franchisee: item } });
  };

  const [frModalOpen, setFrModal] = useState(false);

  const closeFrModal = () => {
    setFrModal(false);
  };
  return (
    <Page title="Franchisee Dashboard">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography variant="h4">Franchisee Dashboard</Typography>
          <Button variant="contained" onClick={() => setFrModal(true)}>
            Add Franchisee
          </Button>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <FranchiseeList onFranchiseeClick={openFrDetailsPage} />
          </Grid>
        </Grid>
      </Container>
      <Modal open={frModalOpen} onClose={closeFrModal}>
        <AddFranchiseeModal handleM1Close={closeFrModal} />
      </Modal>
    </Page>
  );
}
