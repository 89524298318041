import { Grid } from "@mui/material";
import ReferralCard from "./ReferralCard";
import GetReferredCustomer from "./GetReferredCustomer";

export default function CustomerReferralSection({ customer }) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <ReferralCard customer={customer} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <GetReferredCustomer customer={customer} />
        </Grid>
      </Grid>
    </>
  );
}
