import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";

// material
import { Stack, TextField, Alert } from "@mui/material";

import { LoadingButton } from "@mui/lab";

// ----------------------------------------------------------------------
import { dataFetch } from "src/utils/data-fetch";

import { useAuth } from "src/contexts/AuthContext";

export default function LoginForm2() {
  const navigate = useNavigate();
  const [error, setError] = useState();

  const { linkLogin } = useAuth();

  const LoginSchema = Yup.object().shape({
    link: Yup.string().url().required("Login link is required"),
  });

  const loadTokenFromLink = async (link) => {
    let shortLink = link.substring(link.lastIndexOf("/") + 1);

    let url = `/api/auth/getlogintoken/${shortLink}`;

    const ret = await dataFetch(url);
    console.log(ret)
    if (ret.success === true) {
      return ret.data;
    } else {
      setError(ret.message);
      return null;
    }
  };

  const formik = useFormik({
    initialValues: {
      link: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      try {
        setError(null);

        let rr = await loadTokenFromLink(values.link);

        if (rr) {
          await linkLogin(rr);
          navigate("/dashboard", { replace: true });
        }
      } catch (error) {
        console.log(error);
        setError(error.code);
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              type="text"
              label="Login Link"
              {...getFieldProps("link")}
              error={Boolean(touched.link && errors.link)}
              helperText={touched.link && errors.link}
            />
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ mt: 3 }}
          >
            Login
          </LoadingButton>
        </Form>
      </FormikProvider>

      {error && (
        <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </div>
  );
}
