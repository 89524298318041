import { useEffect, useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import Select from "@mui/material/Select";

import { useConfirm } from "material-ui-confirm";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Grid,
  Box,
  InputAdornment,
  MenuItem,
  Container,
  OutlinedInput,
  Drawer,
  Divider,
  Switch,
  CircularProgress,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataFetch, dataPost } from "src/utils/data-fetch";
import MakeFullScreen from "src/components/MakeFullScreen";
import { useSnackbar } from "src/contexts/SnackbarContext";
import InternalAccSearch from "./InternalAccSearch";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddLedgerModal({ handleClose, duplicateData }) {
  const [loadingData, setLoadingData] = useState(false);

  const [loadingCode, setLoadingCode] = useState(false);
  const [probableCode, setProbableCode] = useState(null);

  const { getAccessToken, allFranchisees } = useAuth();
  const { showSnackbar } = useSnackbar();
  const confirm = useConfirm();

  //fetch account groups
  const [accGroups, setAccGroups] = useState([]);
  useEffect(() => {
    const fetchAccGroups = async () => {
      const ret = await dataFetch(
        `/api/internalaccs/coreact/groups/query`,
        await getAccessToken()
      );
      if (ret.success === true) {
        setAccGroups(ret.data);
      } else {
        showSnackbar(ret.message || "Failed to fetch acc groups", "error");
      }
    };
    fetchAccGroups();
  }, []);

  //variables
  const [selectedUnder, setSelectedUnder] = useState(null);
  const [ledgerCat, setLedgerCat] = useState(null);
  const [selectedFranchisee, setSelectedFranchisee] = useState("none");
  const [selectedAccGroup, setSelectedAccGroup] = useState("Current Assets");
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [gstEnabled, setGstEnabled] = useState(false);
  const [panEnabled, setPanEnabled] = useState(false);
  const [msmeEnabled, setMsmeEnabled] = useState(false);
  const [isPublic, setPublic] = useState(false);
  const [manualCode, setManualCode] = useState(false);

  const [name, setName] = useState(duplicateData?.name || "");
  const [phone, setPhone] = useState(duplicateData?.phone || "");
  const [email, setEmail] = useState(duplicateData?.email || "");
  const [address, setAddress] = useState(duplicateData?.address || "");
  const [comments, setComments] = useState(duplicateData?.comments || "");
  const [gst, setGst] = useState(duplicateData?.gst || "");
  const [pan, setPan] = useState(duplicateData?.pan || "");
  const [msme_reg, setMsmeReg] = useState(duplicateData?.msme_reg || "micro");
  const [udyam, setUdyam] = useState(duplicateData?.udyam || "");

  //validator
  const validateData = () => {
    if (!ledgerCat) {
      showSnackbar("Please select a category", "warning");
      return false;
    }

    if (ledgerCat === "sub" && !selectedUnder) {
      showSnackbar("Please select a main account", "warning");
      return false;
    }

    if (!selectedAccGroup) {
      showSnackbar("Please select an account group", "warning");
      return false;
    }

    if (!selectedEntity) {
      showSnackbar("Please select an entity", "warning");
      return false;
    }

    if (!name || name.length < 3) {
      showSnackbar("Please enter a valid name", "warning");
      return false;
    }

    if (phone && phone.length !== 10) {
      showSnackbar("Please enter a valid phone number", "warning");
      return false;
    }

    if (email && email.length < 3) {
      showSnackbar("Please enter a valid email", "warning");
      return false;
    }

    if (gstEnabled && gst.length !== 15) {
      showSnackbar("Please enter a valid GST number", "warning");
      return false;
    }

    if (panEnabled && pan.length !== 10) {
      showSnackbar("Please enter a valid PAN number", "warning");
      return false;
    }

    if (msmeEnabled) {
      if (!msme_reg) {
        showSnackbar("Please select MSME registration type", "warning");
        return false;
      } else if (udyam.length !== 19) {
        showSnackbar("Please enter a valid Udyam number", "warning");
        return false;
      }
    }

    return true;
  };

  const approveNow = async () => {
    const up = {
      name: name,
      public: isPublic,
      ext_code: probableCode,
    };
    if (phone.length > 0) up.phone = `+91${phone}`;
    if (email.length > 0) up.email = email;
    if (address.length > 0) up.address = address;
    if (comments.length > 0) up.comments = comments;
    if (gstEnabled && gst.length > 0) up.gst = gst;
    if (panEnabled && pan.length > 0) up.pan = pan;
    if (msmeEnabled) {
      up.msme_reg = msme_reg;
      up.udyam = udyam;
    }
    up.ledger_cat = ledgerCat;
    up.acc_group = selectedAccGroup;
    up.entity = selectedEntity;
    if (selectedFranchisee && selectedFranchisee !== "none")
      up.franchisee = selectedFranchisee;
    if (selectedUnder) up.under = selectedUnder._id;

    const ret = await dataPost(
      `/api/internalaccs/coreact/accs/addledger`,
      await getAccessToken(),
      up
    );
    if (ret.success === true) {
      showSnackbar(ret.message || "Ledger added", "success");
      handleClose();
    } else {
      showSnackbar(ret.message + " - " + ret.code, "error");
    }
  };

  //on select main account
  const [accSearchDrawerState, setAccSearchDrawerState] = useState(false);
  const onAccSelected = (acc) => {
    setAccSearchDrawerState(false);
    if (acc.under) {
      showSnackbar("Please select a main account", "error");
    } else {
      setSelectedUnder(acc);
      setSelectedAccGroup(acc?.acc_group || "Current Assets");
      setSelectedEntity(acc?.entity);
      setSelectedFranchisee(acc?.franchisee?._id);
    }
  };

  //fetch next probable code
  const getNextLedgerCode = async () => {
    if (
      !selectedAccGroup ||
      !ledgerCat ||
      (ledgerCat === "sub" && !selectedUnder)
    ) {
      setProbableCode(null);
      return;
    }

    setLoadingCode(true);

    const ret = await dataFetch(
      `/api/internalaccs/coreact/accs/nextcode?group=${selectedAccGroup}&cat=${ledgerCat}&under=${selectedUnder?._id}`,
      await getAccessToken()
    );
    if (ret.success === true) {
      setProbableCode(ret.data);
    } else {
      showSnackbar(ret.message || "Failed to fetch code", "error");
    }
    setLoadingCode(false);
  };

  useEffect(() => {
    getNextLedgerCode();
  }, [selectedAccGroup, ledgerCat, selectedUnder]);

  return (
    <MakeFullScreen
      open={true}
      onClose={handleClose}
      title={`Add Ledger (${probableCode || "-"})`}
      maxWidth={"md"}
    >
      <Container sx={{ px: 2, pb: 2 }}>
        <Typography variant="h6" mb={3} color={"red"}>
          Employee / Manager / Customer / Farmer should not be added from here!
        </Typography>

        <Stack spacing={2} direction={"row"} mb={2}>
          <TextField
            fullWidth
            select
            label="Category"
            id="cat-select"
            value={ledgerCat}
            onChange={(e) => setLedgerCat(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>Category</Box>
              </InputAdornment>
            }
          >
            <MenuItem value={"main"}>Main</MenuItem>
            <MenuItem value={"sub"}>Sub</MenuItem>
          </TextField>

          {ledgerCat === "sub" && (
            <OutlinedInput
              fullWidth
              value={
                (selectedUnder &&
                  `${selectedUnder.name} (${selectedUnder.entity})`) ||
                "-"
              }
              onClick={() => setAccSearchDrawerState(true)}
              startAdornment={
                <InputAdornment variant="filled" sx={{ mr: 2 }}>
                  Under
                </InputAdornment>
              }
              color="success"
              sx={{ color: "green" }}
            />
          )}
        </Stack>

        {(ledgerCat === "main" || (ledgerCat === "sub" && selectedUnder)) && (
          <>
            <Stack spacing={2} direction={"row"} mb={2}>
              <TextField
                select
                fullWidth
                id="fr-select"
                label="Cost Center"
                value={selectedFranchisee}
                onChange={(e) => setSelectedFranchisee(e.target.value)}
              >
                <MenuItem value={"none"}>None</MenuItem>
                {allFranchisees.map((item) => (
                  <MenuItem value={item._id}>{item.name}</MenuItem>
                ))}
              </TextField>
              <TextField
                select
                fullWidth
                id="group-select"
                label="Account Group"
                value={selectedAccGroup}
                onChange={(e) => setSelectedAccGroup(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <Box sx={{ color: "text.disabled" }}>Account Group</Box>
                  </InputAdornment>
                }
                disabled={ledgerCat === "sub"}
              >
                {accGroups.map((item) => (
                  <MenuItem value={item.name}>{item.name}</MenuItem>
                ))}
              </TextField>

              <TextField
                select
                fullWidth
                label="Entity"
                id="entity-select"
                value={selectedEntity}
                onChange={(e) => setSelectedEntity(e.target.value)}
                MenuProps={MenuProps}
                startAdornment={
                  <InputAdornment position="start">
                    <Box sx={{ color: "text.disabled" }}>Entity</Box>
                  </InputAdornment>
                }
              >
                <MenuItem value={"ledger"}>Ledger</MenuItem>
                <MenuItem value={"asset"}>Asset</MenuItem>
                <MenuItem value={"expense"}>Expense</MenuItem>
                <MenuItem value={"income"}>Income</MenuItem>
                <MenuItem value={"capital"}>Capital</MenuItem>
              </TextField>
            </Stack>

            <Stack spacing={2} direction={"row"} mb={2} alignItems={"center"}>
              <TextField
                label="SET CODE MANUALLY"
                value={manualCode ? "Yes" : "No"}
                onChange={(e) => {
                  if (e.target.value?.includes("y")) {
                    setManualCode(true);
                  } else if (e.target.value?.includes("n")) {
                    setManualCode(false);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Switch
                        checked={manualCode}
                        onChange={(e, c) => setManualCode(c)}
                      />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                type="text"
                value={probableCode}
                onChange={(e) => setProbableCode(e.target.value?.trim())}
                inputProps={{ maxLength: 10 }}
                disabled={!manualCode}
                InputProps={{
                  endAdornment: loadingCode && (
                    <CircularProgress color="secondary" />
                  ),
                }}
              />
            </Stack>
          </>
        )}

        {(ledgerCat === "main" || (ledgerCat === "sub" && selectedUnder)) &&
          selectedAccGroup && (
            <>
              <Divider sx={{ my: 4, bgcolor: "black.light" }} />

              <Stack spacing={2} direction={"row"} mb={2}>
                <TextField
                  fullWidth
                  type="text"
                  label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Stack>

              <Stack spacing={2} direction={"row"} mb={2}>
                <TextField
                  fullWidth
                  type="number"
                  label="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  inputProps={{ maxLength: 10 }}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Stack>
              <Stack spacing={2} direction={"row"} mb={2}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  type="text"
                  label="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Stack>

              <Stack spacing={2} direction={"row"} mb={2} alignItems={"center"}>
                <TextField
                  label="PUBLIC LEDGER"
                  value={isPublic ? "Yes" : "No"}
                  onChange={(e) => {
                    if (e.target.value?.includes("y")) {
                      setPublic(true);
                    } else if (e.target.value?.includes("n")) {
                      setPublic(false);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Switch
                          checked={isPublic}
                          onChange={(e, c) => setPublic(c)}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  fullWidth
                  multiline
                  type="text"
                  label="Comments (optional)"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                />
              </Stack>

              <Stack spacing={2} direction={"row"} mb={2} alignItems={"center"}>
                <TextField
                  label="GST ENABLED"
                  value={gstEnabled ? "Yes" : "No"}
                  onChange={(e) => {
                    if (e.target.value?.includes("y")) {
                      setGstEnabled(true);
                    } else if (e.target.value?.includes("n")) {
                      setGstEnabled(false);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Switch
                          checked={gstEnabled}
                          onChange={(e, c) => setGstEnabled(c)}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                {gstEnabled && (
                  <TextField
                    fullWidth
                    type="text"
                    label="GST Number (15 digits)"
                    value={gst}
                    onChange={(e) => setGst(e.target.value?.trim())}
                    inputProps={{ maxLength: 15 }}
                  />
                )}
              </Stack>
              <Stack spacing={2} direction={"row"} mb={2} alignItems={"center"}>
                <TextField
                  label="PAN ENABLED"
                  value={panEnabled ? "Yes" : "No"}
                  onChange={(e) => {
                    if (e.target.value?.includes("y")) {
                      setPanEnabled(true);
                    } else if (e.target.value?.includes("n")) {
                      setPanEnabled(false);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Switch
                          checked={panEnabled}
                          onChange={(e, c) => setPanEnabled(c)}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                {panEnabled && (
                  <TextField
                    fullWidth
                    type="text"
                    label="PAN Number (10 digits)"
                    value={pan}
                    onChange={(e) => setPan(e.target.value?.trim())}
                    inputProps={{ maxLength: 10 }}
                  />
                )}
              </Stack>

              <Stack spacing={2} direction={"row"} mb={2} alignItems={"center"}>
                <TextField
                  label="MSME ENABLED"
                  value={msmeEnabled ? "Yes" : "No"}
                  onChange={(e) => {
                    if (e.target.value?.includes("y")) {
                      setMsmeEnabled(true);
                    } else if (e.target.value?.includes("n")) {
                      setMsmeEnabled(false);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Switch
                          checked={msmeEnabled}
                          onChange={(e, c) => setMsmeEnabled(c)}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                {msmeEnabled && (
                  <Select
                    id="msme-select"
                    value={msme_reg}
                    onChange={(e) => setMsmeReg(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <Box sx={{ color: "text.disabled" }}>Reg</Box>
                      </InputAdornment>
                    }
                  >
                    <MenuItem value={"micro"}>Micro</MenuItem>
                    <MenuItem value={"small"}>Small</MenuItem>
                    <MenuItem value={"medium"}>Medium</MenuItem>
                  </Select>
                )}

                {msmeEnabled && (
                  <TextField
                    fullWidth
                    type="text"
                    label="Udyam Number (19 digits)"
                    value={udyam}
                    onChange={(e) => setUdyam(e.target.value?.trim())}
                  />
                )}
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                variant="contained"
                loading={loadingData}
                sx={{ mt: 3 }}
                onClick={() => {
                  if (validateData()) {
                    confirm({
                      description: "Adding Ledger... Please confirm!",
                      confirmationButtonProps: { autoFocus: true },
                    })
                      .then(() => {
                        approveNow();
                      })
                      .catch(() => {});
                  }
                }}
              >
                Save
              </LoadingButton>
            </>
          )}

        {accSearchDrawerState && (
          <Drawer
            anchor={"right"}
            open={true}
            onClose={() => setAccSearchDrawerState(false)}
            sx={{ zIndex: 1300, p: 3 }}
            PaperProps={{
              sx: { width: "50%" },
            }}
          >
            <InternalAccSearch onAccSelected={onAccSelected} />
          </Drawer>
        )}
      </Container>
    </MakeFullScreen>
  );
}
