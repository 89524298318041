import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";
import { getDateStringFullWithMonthSync, getDateStringFullWithTimeSync } from "src/utils/date-time-helper";

export const EmployeeChangelog = ({employee}) => {
  const [currentData, setCurrentData] = useState();
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const loadData = async () => {
    setLoadingData(true);
    let url = `/api/employees/mgract/v2/employee/${employee}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCurrentData(ret.data);
      console.log(ret.data);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, []);

  return (
    <Card sx={{ flex: 1, p: 3, minHeight: "400px" }}>
      <Typography variant="h5" gutterBottom>
        Change Logs
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Manager</b>
            </TableCell>
            <TableCell>
              <b>Comments</b>
            </TableCell>
            <TableCell align="right">
              <b>Timestamp</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentData?.employee_det?.changelog?.length > 0 ? (
            currentData.employee_det.changelog.map((log, index) => (
              <TableRow key={index}>
                <TableCell>{log?.acc?.name || "N/A"}</TableCell>
                <TableCell>{log?.comments || "No comment"}</TableCell>
                <TableCell align="right">{getDateStringFullWithTimeSync(log.ts)}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} align="center">
                No Change Logs Available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Card>
  );
};
