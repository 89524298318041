import {
  Grid,
  Container,
  Typography,
  Stack,
  Card,
  CardMedia,
  CardActionArea,
  Divider,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";
import Label from "../crm/Label";

export default function ProductSearch({ onItemSelected }) {
  const [loadingData, setLoadingData] = useState(false);
  const [customerData, setCustomerData] = useState([]);

  const { activeFranchise, getAccessToken } = useAuth();

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/products/mgract/query`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
      console.log(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    handleSearch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          {(loadingData && (
            <Typography variant="h4">Loading...</Typography>
          )) || <AddProductCard />}
        </Grid>

        {customerData.map((data) => (
          <Grid item xs={12} md={12} lg={12} key={data.id}>
            <ProductCard data={data} onClick={() => onItemSelected(data)} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export function ProductCard({ data, onClick }) {
  return (
    <Card
      sx={{
        boxShadow: "3",
        display: "flex",
        backgroundColor: data.active === true ? "white" : "lightgrey",
      }}
    >
      <CardActionArea onClick={onClick}>
        <Stack direction={"row"}>
          <CardMedia
            sx={{ width: 150 }}
            image={data?.images[0]}
            title="product image"
          />
          <Divider orientation="vertical" flexItem />
          <Stack direction={"column"} p={2}>
            <Typography component="div" variant="h5">
              {data?.name}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {data?.bio}
            </Typography>
            <Stack direction={"row"} spacing={2} mt={1}>
              <Typography variant="subtitle1" color="black">
                ₹ {data?.disc_price}
              </Typography>
              {data.disc_price !== data.price && (
                <Typography variant="subtitle1" color="red">
                  <strike> ₹ {data?.price}</strike>
                </Typography>
              )}
            </Stack>
            {(data.stock_type === 0 && (
              <Stack direction={"row"} spacing={1} mt={1}>
                <Typography variant="subtitle1" color="blue">
                  Stock: {data?.stock}
                </Typography>
                <Typography variant="subtitle1" color="gray">
                  ( + {data?.proc_stock || 0} in order)
                </Typography>
              </Stack>
            )) || (
              <Stack direction={"row"} spacing={1} mt={1}>
                <Typography
                  variant="subtitle1"
                  color={data?.stock >= 1 ? "blue" : "red"}
                >
                  {data?.stock >= 1 ? "Available" : "Not Available"}
                </Typography>
              </Stack>
            )}
          </Stack>

          <Stack direction={"column"} py={2} pr={2} alignItems={"end"} flex={1}>
            {data.consumer_type == 0 ? (
              <Label variant="filled" color="primary">
                B2C
              </Label>
            ) : data.consumer_type === 1 ? (
              <Label variant="filled" color="secondary">
                B2B
              </Label>
            ) : (
              <Label variant="filled" color="info">
                ALL
              </Label>
            )}

            {data.order_type === 0 ? (
              <Label variant="filled" color="primary" sx={{ mt: 1 }}>
                ONE TIME
              </Label>
            ) : data.order_type === 1 ? (
              <Label variant="filled" color="secondary" sx={{ mt: 1 }}>
                SUB
              </Label>
            ) : (
              <Label variant="filled" color="info" sx={{ mt: 1 }}>
                ONETIME & SUB
              </Label>
            )}
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  );
}

export function AddProductCard({ onClick }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
    >
      <Typography variant="h4">Products</Typography>
    </Stack>
  );
}
