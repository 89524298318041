import PropTypes from "prop-types";

// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Card,
  Avatar,
  Divider,
} from "@mui/material";

// components
import { MHidden } from "../../components/@material-extend";

//
import AccountPopover from "./AccountPopover";

import { useAuth } from "../../contexts/AuthContext";

import {
  AdminPanelSettingsRounded,
  Fullscreen,
  FullscreenExitRounded,
  FullscreenRounded,
  Menu,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 64;

const RootStyle = styled(AppBar)(({ theme, drawerHidden }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: drawerHidden === false && `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 2),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({
  onOpenSidebar,
  drawerHidden,
  updateDrawerHidden,
}) {
  const { currentManager, activeFranchise, handleFranchiseChange } = useAuth();

  const navigate = useNavigate();

  return (
    <RootStyle drawerHidden={drawerHidden}>
      <ToolbarStyle>
        {(drawerHidden === true && (
          <IconButton
            onClick={onOpenSidebar}
            sx={{
              padding: 0,
              width: 44,
              height: 44,
            }}
          >
            <Menu />
          </IconButton>
        )) || (
          <MHidden width="lgUp">
            <IconButton
              onClick={onOpenSidebar}
              sx={{
                padding: 0,
                width: 44,
                height: 44,
              }}
            >
              <Menu />
            </IconButton>
          </MHidden>
        )}

        <Box
          sx={{
            overflow: "auto",
            scrollbarWidth: "none",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 0.5, sm: 1.5 }}
          >
            {currentManager.access &&
              currentManager.access.map((item, index) => {
                return (
                  <Box key={index}>
                    <Card
                      variant="outlined"
                      sx={{
                        boxShadow: "none",
                        py: 1,
                        px: 2,
                        background:
                          item.fr._id === activeFranchise ? "#003f5c" : "white",
                        color:
                          item.fr._id === activeFranchise
                            ? "white.main"
                            : "black.main",
                        fontSize: "75%",
                      }}
                      onClick={(event) => {
                        handleFranchiseChange(item.fr._id);
                      }}
                    >
                      {item.fr.name.includes(" ")
                        ? item.fr.name.split(" ")
                        : item.fr.name.includes("-")
                        ? item.fr.name.split("-").join("_")
                        : item.fr.name}
                    </Card>
                  </Box>
                );
              })}
          </Stack>
        </Box>

        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 0.5 }}
        >
          <IconButton onClick={updateDrawerHidden}>
            {(drawerHidden === true && <FullscreenExitRounded />) || (
              <FullscreenRounded />
            )}
          </IconButton>
          <IconButton
            onClick={() => {
              navigate("/admin");
            }}
          >
            <Avatar sx={{ color: "white", backgroundColor: "primary.dark" }}>
              <AdminPanelSettingsRounded />
            </Avatar>
          </IconButton>
          <AccountPopover />
        </Stack>
      </ToolbarStyle>

      <Divider/>
      
    </RootStyle>
  );
}
