import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";

import { dataFetch } from "../../utils/data-fetch";

import {
  CircleRounded,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
  RefreshRounded,
} from "@mui/icons-material";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Stack,
  IconButton,
  Modal,
} from "@mui/material";

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

import {
  TWENTY4HOURS,
  getDayStart,
  getTimeStringFullSync,
} from "src/utils/date-time-helper";
import { BulkOrderDetails } from "./BulkOrderDetails";
import { LoadingButton } from "@mui/lab";

const TABLE_HEAD = [
  { id: "party", label: "Party", alignRight: false },
  { id: "time", label: "Time", alignRight: false },
  { id: "item", label: "Item", alignRight: false },
  { id: "qty", label: "Qty", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
];

export default function OrdersToday({ onDataSelected }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [startDate, setStartDate] = useState(getDayStart(Date.now()));

  const [page, setPage] = useState(0);

  const { getAccessToken, activeFranchise } = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/extrasales/mgract/orders/query?date_gt=${startDate}&date_lt=${
      startDate + TWENTY4HOURS
    }&skip=${page * 5}&limit=5&sort=date&dir=d`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
      console.log(ret.data);
    }

    setLoadingData(false);
  };
  const totalLength = page * 5 + customerData.length;


  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [page, activeFranchise, startDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          mt={2}
          spacing={2}
        >
          <Typography variant="h4">Orders</Typography>

          <Stack direction="row" alignItems={"center"}>
            <IconButton
              sx={{ display: "inline-flex", mr: 1 }}
              onClick={() => {
                setStartDate(startDate - TWENTY4HOURS);
              }}
            >
              <KeyboardArrowLeftRounded />
            </IconButton>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={"en-in"}
            >
              <MobileDatePicker
                value={moment(startDate)}
                sx={{ maxWidth: "130px" }}
                onAccept={(newValue) => {
                  if (newValue) {
                    const d1 = getDayStart(newValue.valueOf());
                    if (startDate !== d1) setStartDate(d1);
                  }
                }}
                closeOnSelect={true}
                format="DD/MM/YYYY"
                loading={loadingData}
              />
            </LocalizationProvider>
            <IconButton
              sx={{ display: "inline-flex", ml: 1 }}
              onClick={() => {
                setStartDate(startDate + TWENTY4HOURS);
              }}
            >
              <KeyboardArrowRightRounded />
            </IconButton>
            {loadingData === true ? (
              <CircularProgress />
            ) : (
              <IconButton
                sx={{ display: "inline-flex", mr: 1 }}
                onClick={handleSearch}
              >
                <RefreshRounded />
              </IconButton>
            )}
          </Stack>
        </Stack>

        <div>
          <TableContainer sx={{ minHeight: 100 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.alignRight ? "right" : "left"}
                      sortDirection={false}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {customerData.map((item) => {
                  return (
                    <TableRow
                      hover
                      onClick={() => onDataSelected(item)}
                      style={{
                        backgroundColor: [
                          "cancelled",
                          "rejected",
                          "returned",
                        ].includes(item.status)
                          ? "#ddd"
                          : "white",
                      }}
                    >
                      <TableCell>
                        <b>{item?.account?.name || "-"}</b>
                      </TableCell>
                      <TableCell>
                        {getTimeStringFullSync(item?.date) || "-"}
                      </TableCell>
                      <TableCell>{item?.item?.toUpperCase() || "-"}</TableCell>
                      <TableCell>
                        {item?.quantity || "-"} {item?.unit || "Kg"}
                      </TableCell>
                      <TableCell>
                        <b>{item?.status?.toUpperCase() || "-"}</b>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={totalLength < (page + 1) * 5 ? totalLength : -1}
            rowsPerPage={5}
            rowsPerPageOptions={[5]}
            page={page}
            onPageChange={handleChangePage}
          />
        </div>
      </Container>
    </Card>
  );
}
