import { useState } from "react";
import { Container, Box, Tab, Stack, IconButton } from "@mui/material";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Page from "src/components/Page";
import MilkCartsDailySection from "./MilkCartsDailySection";

import { TWENTY4HOURS } from "src/utils/date-time-helper";

import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from "@mui/icons-material";
import MilkCartsComparisonSection from "./MilkCartsComparisonSection";
import MilkCartsWeeklySection from "./MilkCartsWeeklySection";
import MilkCartsMonthlySection from "./MilkCartsMonthlySection";
import { MilkCartsReportSection } from "../reports/MilkCartsReportSection";

import { DateSelector, MonthSelector } from "src/utils/date-selector";
import FullScreenMilkCartsDetails from "./det/FullScreenMilkCartDetails";
import { CartGlobalScheduleSection } from "./CartGlobalScheduleSection";
import Label from "../crm/Label";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MilkCartsPage() {
  const [currentMenu, setCurrentMenu] = useState(
    localStorage.getItem("milkcart_main_menu") || "daily"
  );

  const handleTabChange = (event, newValue) => {
    setCurrentMenu(newValue);
    localStorage.setItem("milkcart_main_menu", newValue);
  };

  const [prevStartDate, setPrevStartDate] = useState(Date.now() - TWENTY4HOURS);
  const [compStartDate, setCompStartDate] = useState(Date.now());

  const [startDate, setStartDate] = useState(Date.now());

  const [mth1, setMth1] = useState(
    moment(Date.now()).subtract(1, "month").valueOf()
  );
  const [mth2, setMth2] = useState(Date.now());

  const [wk1, setWk1] = useState(
    moment(Date.now()).subtract(1, "week").valueOf()
  );
  const [wk2, setWk2] = useState(Date.now());

  const [currCart, setCurrentCart] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleCartSelected = (cartData) => {
    setCurrentCart(cartData);
    setDrawerOpen(true);
  };

  const handleCloseCart = () => {
    setDrawerOpen(false);
  };

  return (
    <Page title="Milk Carts">
      <Container>
        <TabContext value={currentMenu}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <TabList onChange={handleTabChange}>
                <Tab label="Daily" value="daily" {...a11yProps(0)} />
                <Tab label="comparison" value="comparison" {...a11yProps(1)} />
                <Tab label="Weekly" value="weekly" {...a11yProps(2)} />
                <Tab label="Monthly" value="monthly" {...a11yProps(3)} />
                <Tab label="Report" value="report" {...a11yProps(4)} />
                <Tab label="Schedule" value="schedule" {...a11yProps(5)} />
                <Label variant="filled" color="error">
                  #
                </Label>
              </TabList>
            </Box>

            {(currentMenu === "daily" || currentMenu === "report") && (
              <DateSelector value={startDate} onChange={setStartDate} />
            )}

            {currentMenu === "comparison" && (
              <Stack direction={"row"} alignItems={"center"}>
                <DateSelector
                  value={prevStartDate}
                  onChange={setPrevStartDate}
                />
                <DateSelector
                  value={compStartDate}
                  onChange={setCompStartDate}
                />
              </Stack>
            )}

            {currentMenu === "weekly" && (
              <Stack direction={"row"} alignItems={"center"}>
                <IconButton
                  sx={{ display: "inline-flex" }}
                  onClick={() => {
                    const newD = moment(wk1).subtract(1, "week").valueOf();
                    setWk1(newD);
                  }}
                >
                  <KeyboardArrowLeftRounded />
                </IconButton>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale={"en-in"}
                >
                  <MobileDatePicker
                    views={["month", "day"]}
                    value={moment(wk1)}
                    onAccept={(newValue) => {
                      if (newValue) {
                        const d1 = newValue.valueOf();
                        if (wk1 !== d1) setWk1(d1);
                      }
                    }}
                    sx={{ maxWidth: "130px" }}
                    closeOnSelect={true}
                    format="[Week:] DD MMM"
                  />
                </LocalizationProvider>
                <IconButton
                  sx={{ display: "inline-flex" }}
                  onClick={() => {
                    const newD = moment(wk1).add(1, "week").valueOf();
                    setWk1(newD);
                  }}
                >
                  <KeyboardArrowRightRounded />
                </IconButton>

                <IconButton
                  sx={{ display: "inline-flex" }}
                  onClick={() => {
                    const newD = moment(wk2).subtract(1, "week").valueOf();
                    setWk2(newD);
                  }}
                >
                  <KeyboardArrowLeftRounded />
                </IconButton>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale={"en-in"}
                >
                  <MobileDatePicker
                    views={["day", "month"]}
                    value={moment(wk2)}
                    onAccept={(newValue) => {
                      if (newValue) {
                        const d1 = newValue.valueOf();
                        if (wk2 !== d1) setWk2(d1);
                      }
                    }}
                    sx={{ maxWidth: "130px" }}
                    closeOnSelect={true}
                    format="[Week:] DD MMM"
                  />
                </LocalizationProvider>
                <IconButton
                  sx={{ display: "inline-flex" }}
                  onClick={() => {
                    const newD = moment(wk2).add(1, "week").valueOf();
                    setWk2(newD);
                  }}
                >
                  <KeyboardArrowRightRounded />
                </IconButton>
              </Stack>
            )}

            {currentMenu === "monthly" && (
              <Stack direction={"row"} alignItems={"center"}>
                <MonthSelector value={mth1} onChange={setMth1} />
                <MonthSelector value={mth2} onChange={setMth2} />
              </Stack>
            )}
          </Stack>

          <TabPanel value="daily">
            <MilkCartsDailySection
              startDate={startDate}
              onCartSelected={handleCartSelected}
            />
          </TabPanel>
          <TabPanel value="comparison">
            <MilkCartsComparisonSection
              startDate={compStartDate}
              prevStartDate={prevStartDate}
              onCartSelected={handleCartSelected}
            />
          </TabPanel>
          <TabPanel value="weekly">
            <MilkCartsWeeklySection
              w1={wk1}
              w2={wk2}
              onCartSelected={handleCartSelected}
            />
          </TabPanel>
          <TabPanel value="monthly">
            <MilkCartsMonthlySection
              m1={mth1}
              m2={mth2}
              onCartSelected={handleCartSelected}
            />
          </TabPanel>
          <TabPanel value="report">
            <MilkCartsReportSection
              currentDate={startDate}
              onCartSelected={handleCartSelected}
            />
          </TabPanel>
          <TabPanel value="schedule">
            <CartGlobalScheduleSection onCartSelected={handleCartSelected} />
          </TabPanel>
        </TabContext>
      </Container>

      <FullScreenMilkCartsDetails
        cart={currCart}
        handleClose={handleCloseCart}
        drawerOpen={drawerOpen}
      />
    </Page>
  );
}
