import { Container, Stack, Typography, Grid, Button } from "@mui/material";

import React from "react";
import Page from "src/components/Page";
import ApartmentSearch from "./ApartmentSearch";

import { useState } from "react";
import { AddRounded } from "@mui/icons-material";

import ApartmentDetailsPage from "./det/ApartmentDetailsPage";
import AddZoneModal from "./addzone/AddZoneModal";
import AddAptModal from "./addapt/AddAptModal";

export default function ApartmentPage() {
  const [modalOpen, setModalOpen] = useState(false);

  const [counter, setCounter] = useState(0);

  const closeModalFn = () => {
    setModalOpen(false);
    setCounter(counter + 1);
  };

  const openModalFn = async () => {
    setModalOpen(true);
  };

  const [currApt, setCurrApt] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleAptSelected = (aptData) => {
    setCurrApt(aptData);
    setDrawerOpen(true);
  };

  const [addZoneModalState, setAddZoneModalState] = useState(false);
  const closeAddZoneModalFn = () => {
    setAddZoneModalState(false);
    setCounter(counter + 1);
  };

  return (
    <Page title="Zones Dashboard">
      <Container>
        <Stack
          direction="row"
          spacing={2}
          alignItems={"center"}
          justifyContent={"space-between"}
          mb={3}
        >
          <Typography variant="h4">Zone Dashboard</Typography>

          <Stack direction="row" spacing={2} alignItems={"center"}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddRounded />}
              onClick={openModalFn}
            >
              Add Apt
            </Button>

            <Button
              variant="contained"
              color="primary"
              startIcon={<AddRounded />}
              onClick={() => setAddZoneModalState(true)}
            >
              Add Zone
            </Button>
          </Stack>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <ApartmentSearch
              counter={counter}
              onAptSelected={handleAptSelected}
            />
          </Grid>
        </Grid>

        {addZoneModalState === true && (
          <AddZoneModal
            handleM1Close={closeAddZoneModalFn}
            drawerOpen={addZoneModalState}
          />
        )}

        {modalOpen === true && (
          <AddAptModal handleM1Close={closeModalFn} drawerOpen={modalOpen} />
        )}
      </Container>

      <ApartmentDetailsPage
        apartment={currApt}
        handleClose={() => setDrawerOpen(false)}
        drawerOpen={drawerOpen}
      />
    </Page>
  );
}
