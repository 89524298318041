import { useState, useEffect } from "react";
import { useAuth } from "../../../contexts/AuthContext";

import { dataFetch } from "../../../utils/data-fetch";
import ReactSpeedometer from "react-d3-speedometer/slim";
import { MobileDatePicker } from "@mui/x-date-pickers";

import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  Card,
  Typography,
  Alert,
  Stack,
  Grid,
  CircularProgress,
  Table,
  TableBody,
  TableRow,
  TableCell,
  LinearProgress,
  Box,
  IconButton,
} from "@mui/material";

import {
  TWENTY4HOURS,
  getDayStartAndEndSync,
  getTimeString,
  getTimeStringFullSync,
} from "src/utils/date-time-helper";
import {
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from "@mui/icons-material";

export default function EmployeeDailySummaryCard({ employee, current = true }) {
  const [error, setError] = useState();
  const [doc, setDoc] = useState({});
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const stDt = getDayStartAndEndSync(Date.now());
  const [currentDate, setCurrentDate] = useState(stDt.start);

  const loadData = async () => {
    let url = `/api/employees/mgract/employee/${employee.employee}/attendance?date=${currentDate}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setDoc(ret.data);

      console.log(ret.data);
    } else {
      setDoc(null);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, [currentDate, employee]);

  let wh = 0;
  if (doc?.sale_start_m > 0 && doc?.sale_end_m > 0) {
    wh += doc.sale_end_m - doc.sale_start_m;
  }
  if (doc?.sale_start_e > 0 && doc?.sale_end_e > 0) {
    wh += doc.sale_end_e - doc.sale_start_e;
  }
  wh = wh / 1000 / 60 / 60;

  return (
    <div>
      <Grid>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h4">{"Daily Summary"}</Typography>

          <Stack direction={"row"} alignItems={"center"}>
            <IconButton
              sx={{ display: "inline-flex" }}
              onClick={() => {
                setCurrentDate(currentDate - TWENTY4HOURS);
              }}
            >
              <KeyboardArrowLeftRounded />
            </IconButton>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={"en-in"}
            >
              <MobileDatePicker
                views={["year", "month", "day"]}
                value={moment(currentDate)}
                onAccept={(newValue) => {
                  if (newValue) {
                    const d1 = newValue.valueOf();
                    if (currentDate !== d1) setCurrentDate(d1);
                  }
                }}
                closeOnSelect={true}
                sx={{ maxWidth: "130px" }}
                format="DD MMM YYYY"
                loading={loadingData}
              />
            </LocalizationProvider>
            <IconButton
              sx={{ display: "inline-flex" }}
              onClick={() => {
                setCurrentDate(currentDate + TWENTY4HOURS);
              }}
            >
              <KeyboardArrowRightRounded />
            </IconButton>
          </Stack>
        </Stack>
        {loadingData === true && <CircularProgress sx={{ mb: 3, mx: 3 }} />}
        {loadingData === false && doc && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <WorkHrCard data={wh} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <AttnTimingCard data={doc?.punch_details} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <SummaryCard
                    session={"Morning"}
                    data1={doc?.morning_attn}
                    data2={doc?.sale_start_m}
                    data3={doc?.sale_end_m}
                    data4={doc?.sale_start_status_m}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <SummaryCard
                    session={"Evening"}
                    data1={doc?.evening_attn}
                    data2={doc?.sale_start_e}
                    data3={doc?.sale_end_e}
                    data4={doc?.sale_start_status_e}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <SalaryCard
                    data1={"₹ " + doc?.base_salary?.toFixed(1)}
                    data2={"₹ " + doc?.var_salary?.toFixed(1)}
                    data3={"₹ " + doc?.allowance?.toFixed(1)}
                    data4={"₹ " + doc?.hisaab_deduct?.toFixed(1)}
                    data5={
                      "₹ " +
                      (
                        doc?.wrong_entry_penalty + doc?.missed_delivery_penalty
                      )?.toFixed(1)
                    }
                    data6={
                      "₹ " + (doc?.product_bonus + doc?.feed_bonus)?.toFixed(1)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

const SummaryCard = ({ session, data1, data2, data3, data4 }) => {
  return (
    <Card sx={{ p: 3 }}>
      <TableBody>
        <TableRow>
          <b>SUMMARY ({session}) </b>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Attendance</b>
          </TableCell>
          <TableCell>
            <b>{data1 == 2 ? "ON TIME" : data1 == 1 ? "LATE" : "ABSENT"}</b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Delivery Status</b>
          </TableCell>
          <TableCell>
            <b>{data4 == 2 ? "ON TIME" : data4 == 1 ? "LATE" : "NO DATA"}</b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Delivery start</b>
          </TableCell>
          <TableCell>
            <b>{getTimeString(data2)}</b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Delivery end</b>
          </TableCell>
          <TableCell>
            <b>{getTimeString(data3)}</b>
          </TableCell>
        </TableRow>
      </TableBody>
    </Card>
  );
};

const SalaryCard = ({ data1, data2, data3, data4, data5, data6 }) => {
  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h6">SALARY DETAILS</Typography>
      <TableRow>
        <TableCell>
          <b>Base</b>
        </TableCell>
        <TableCell>
          <b>{data1 || "-"}</b>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <b>Sale Incentive</b>
        </TableCell>
        <TableCell>
          <b>{data2 || "-"}</b>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <b>Allowance</b>
        </TableCell>
        <TableCell>
          <b> {data3 || "-"} </b>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <b>Deduction</b>
        </TableCell>
        <TableCell>
          <b>{data4 || "-"}</b>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <b>Penalty</b>
        </TableCell>
        <TableCell>
          <b> {data5 || "-"}</b>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <b>Product Bonus</b>
        </TableCell>
        <TableCell>
          <b>{data6 || "-"}</b>
        </TableCell>
      </TableRow>
    </Card>
  );
};

const AttnTimingCard = ({ data }) => {
  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h6">Attendance Timing</Typography>

      <Typography variant="body1">
        {data
          ?.reverse()
          ?.map((item) => {
            return getTimeString(item.ts);
          })
          ?.join(", ")}
      </Typography>
    </Card>
  );
};

const WorkHrCard = ({ data }) => {
  return (
    <Card sx={{ p: 3 }}>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="space-around"
      >
        <ReactSpeedometer
          maxValue={10}
          value={Math.round(data)}
          needleColor="black"
          startColor="red"
          forceRender={true}
          endColor="green"
          height={200}
          textColor="black"
          customSegmentStops={[0, 2.5, 5, 7.5, 10]}
          segmentColors={["red", "red", "yellow", "green"]}
        />

        <Typography variant="h7">EFF WORK HOURS</Typography>
        <Typography variant="h6">{data?.toFixed(1)} / 7.5 Hrs</Typography>
      </Stack>
    </Card>
  );
};
