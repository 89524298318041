import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";
import {
  CloseRounded as CloseIcon,
  EditRounded as EditIcon,
  RotateLeftRounded,
  SaveRounded as SaveIcon,
} from "@mui/icons-material";

import {
  IconButton,
  Select,
  Chip,
  Drawer,
  MenuItem,
  Box,
  Card,
  Alert,
  Grid,
  Stack,
  Typography,
  CircularProgress,
  TableRow,
  Container,
  TextField,
  TableCell,
  TableBody,
  Avatar,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

import { dataFetch, dataPatch } from "src/utils/data-fetch";
import { getDateStringFullWithTimeSync } from "src/utils/date-time-helper";
import { useLocation } from "react-router-dom";
import Page from "src/components/Page";
import { confirm } from "material-ui-confirm";
import { LoadingButton } from "@mui/lab";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AdminFranchiseeDetailsPage({ handleM1Close }) {
  const location = useLocation();
  const franchisee = location.state.franchisee;

  const [error, setError] = useState();

  const [active, setActive] = useState();
  const [category, setCategory] = useState();
  const [farmer_sms, setFarmerSms] = useState();
  const [customer_sms, setCustomerSms] = useState();
  const [show_recharge_plan, setShowRechargePlan] = useState();
  const [cust_credit_limit_active, setCustomerCreditLimitStatus] = useState();
  const [reset_inactive_cust_limit, setInactiveCreditLimit] = useState();
  const [milk_can_default_status, setMilkCanStatus] = useState();
  const [crm_active, setCrmActive] = useState();
  const [payouts_enabled, setPayoutEnabled] = useState();
  const [tmd_active, setTmdActive] = useState();
  const [referral_active, setReferral] = useState();
  const [milk_flow_enabled, setMilkFlow] = useState();
  const [bypass_collection_entry, setBypass] = useState();
  const [auto_salary, setSalary] = useState();

  const [features, setFeatures] = useState([]);
  const [gst, setGst] = useState();
  const [fssai, setFssai] = useState("");
  const [morning_attn, setMorningAttn] = useState(0);
  const [evening_attn, setEveningAttn] = useState(0);
  const [default_cust_credit_limit, setCustomerCreditLimit] = useState(0);

  const [cow_milk_rate, setCowMilkRate] = useState(0);
  const [cow_milk_disc_rate, setCowMilkDiscRate] = useState(0);
  const [buff_milk_rate, setBuffMilkRate] = useState(0);
  const [buff_milk_disc_rate, setBuffMilkDiscRate] = useState(0);
  const [a2_milk_rate, setA2MilkRate] = useState(0);
  const [a2_milk_disc_rate, setA2MilkDiscRate] = useState(0);

  const [currentData, setCurrentData] = useState();
  const [loadingData, setLoadingData] = useState();

  const { getAccessToken } = useAuth();

  const load = async (values) => {
    setLoadingData(true);

    const ret = await dataFetch(
      `/api/franchisees/coreact/fr/${franchisee._id}`,
      await getAccessToken()
    );
    if (ret.success === true) {
      console.log(ret.data);
      setCurrentData(ret.data);
      if (ret.data.active) setActive(ret.data.active);
      if (ret.data.category) setCategory(ret.data.category);
      if (ret.data.farmer_sms) setFarmerSms(ret.data.farmer_sms);
      if (ret.data.customer_sms) setCustomerSms(ret.data.customer_sms);
      if (ret.data.show_recharge_plan)
        setShowRechargePlan(ret.data.show_recharge_plan);
      if (ret.data.cust_credit_limit_active)
        setCustomerCreditLimitStatus(ret.data.cust_credit_limit_active);
      if (ret.data.reset_inactive_cust_limit)
        setInactiveCreditLimit(ret.data.reset_inactive_cust_limit);
      if (ret.data.milk_can_default_status)
        setMilkCanStatus(ret.data.milk_can_default_status);
      setCrmActive(ret.data?.crm_active);
      if (ret.data.payouts_enabled) setPayoutEnabled(ret.data.payouts_enabled);
      setTmdActive(ret.data.tmd_active);
      if (ret.data.referral_active) setReferral(ret.data.referral_active);
      setMilkFlow(ret.data.milk_flow_enabled);
      if (ret.data.bypass_collection_entry)
        setBypass(ret.data.bypass_collection_entry);
      setSalary(ret.data.auto_salary);
      if (ret.data.features) setFeatures(ret.data.features);
      if (ret.data.gst) setGst(ret.data.gst);
      if (ret.data.fssai) setFssai(ret.data.fssai);
      if (ret.data.default_cust_credit_limit)
        setCustomerCreditLimit(ret.data.default_cust_credit_limit);
      if (ret.data.morning_attn) setMorningAttn(ret.data.morning_attn);
      if (ret.data.evening_attn) setEveningAttn(ret.data.evening_attn);
      if (ret.data.cow_milk_rate) setCowMilkRate(ret.data.cow_milk_rate);
      if (ret.data.cow_milk_disc_rate)
        setCowMilkDiscRate(ret.data.cow_milk_disc_rate);
      if (ret.data.buff_milk_rate) setBuffMilkRate(ret.data.buff_milk_rate);
      if (ret.data.buff_milk_disc_rate)
        setBuffMilkDiscRate(ret.data.buff_milk_disc_rate);
      if (ret.data.a2_milk_rate) setA2MilkRate(ret.data.a2_milk_rate);
      if (ret.data.a2_milk_disc_rate)
        setA2MilkDiscRate(ret.data.a2_milk_disc_rate);
    } else {
      console.log(ret.message);
      setError(ret.message + " - " + ret.code);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    load();
  }, []);
  const [edited, setEdited] = useState({
    features: false,
    active: false,
    category: false,
    farmer_sms: false,
    customer_sms: false,
    show_recharge_plan: false,
    cust_credit_limit_active: false,
    reset_inactive_cust_limit: false,
    milk_can_default_status: false,
    crm_active: false,
    payouts_enabled: false,
    tmd_active: false,
    referral_active: false,
    milk_flow_enabled: false,
    bypass_collection_entry: false,
    auto_salary: false,
    gst: false,
    fssai: false,
    morning_attn: false,
    evening_attn: false,
    default_cust_credit_limit: false,
    cow_milk_rate: false,
    cow_milk_disc_rate: false,
    buff_milk_rate: false,
    buff_milk_disc_rate: false,
    a2_milk_rate: false,
    a2_milk_disc_rate: false,
  });

  const patchFr = async () => {
    setLoadingData(true);

    let op = {};
    if (edited.category === true) op.category = category;
    if (edited.active === true) op.active = active;
    if (edited.features === true) op.features = features;
    if (edited.cow_milk_rate === true) op.cow_milk_rate = cow_milk_rate;
    if (edited.cow_milk_disc_rate === true)
      op.cow_milk_disc_rate = cow_milk_disc_rate;
    if (edited.buff_milk_rate === true) op.buff_milk_rate = buff_milk_rate;
    if (edited.buff_milk_disc_rate === true)
      op.buff_milk_disc_rate = buff_milk_disc_rate;
    if (edited.a2_milk_rate === true) op.cow_milk_rate = a2_milk_rate;
    if (edited.a2_milk_disc_rate === true)
      op.a2_milk_disc_rate = a2_milk_disc_rate;
    if (edited.farmer_sms === true) op.farmer_sms = farmer_sms;
    if (edited.customer_sms === true) op.customer_sms = customer_sms;
    if (edited.show_recharge_plan === true)
      op.show_recharge_plan = show_recharge_plan;
    if (edited.default_cust_credit_limit === true)
      op.default_cust_credit_limit = default_cust_credit_limit;
    if (edited.cust_credit_limit_active === true)
      op.cust_credit_limit_active = cust_credit_limit_active;
    if (edited.reset_inactive_cust_limit === true)
      op.reset_inactive_cust_limit = reset_inactive_cust_limit;
    if (edited.milk_can_default_status === true)
      op.milk_can_default_status = milk_can_default_status;
    if (edited.crm_active === true) op.crm_active = crm_active;
    if (edited.payouts_enabled === true) op.payouts_enabled = payouts_enabled;
    if (edited.referral_active === true) op.referral_active = referral_active;
    if (edited.milk_flow_enabled === true)
      op.milk_flow_enabled = milk_flow_enabled;
    if (edited.bypass_collection_entry === true)
      op.bypass_collection_entry = bypass_collection_entry;
    if (edited.fssai === true) op.fssai = fssai;
    if (edited.gst === true) op.gst = gst;
    if (edited.auto_salary === true) op.auto_salary = auto_salary;
    if (edited.morning_attn === true) op.morning_attn = morning_attn;
    if (edited.evening_attn === true) op.evening_attn = evening_attn;
    if (edited.features === true) op.features = features;

    const ret = await dataPatch(
      `/api/franchisees/coreact/fr/${franchisee._id}`,
      await getAccessToken(),
      op
    );

    if (ret.success === true) {
      console.log(ret.data);
      load();
      setEdited(false);
    } else {
      console.log(ret.message);
      setError(ret.message + " - " + ret.code);
      setLoadingData(false);
    }
  };

  const handleApprove = () => {
    confirm({
      description: "Please confirm!",
      confirmationButtonProps: { autoFocus: true },
    })
      .then(() => {
        patchFr();
      })
      .catch(() => {});
  };

  const [searchDrawerState, setSearchDrawerState] = useState(null);

  const drawerSearchInit = (type) => {
    setSearchDrawerState(type);
  };

  const drawerReturnSearch = (acc) => {
    console.log(acc);
    if (searchDrawerState === "gst") {
      setGst(acc);
    } else if (searchDrawerState === "fssai") {
      setFssai(acc);
    } else if (searchDrawerState === "morning_attn") {
      setMorningAttn(acc);
    } else if (searchDrawerState === "evening_attn") {
      setEveningAttn(acc);
    } else if (searchDrawerState === "default_cust_credit_limit") {
      setCustomerCreditLimit(acc);
    } else if (searchDrawerState === "cow_milk_rate") {
      setCowMilkRate(acc);
    } else if (searchDrawerState === "cow_milk_disc_rate") {
      setCowMilkDiscRate(acc);
    } else if (searchDrawerState === "buff_milk_rate") {
      setBuffMilkRate(acc);
    } else if (searchDrawerState === "buff_milk_disc_rate") {
      setBuffMilkDiscRate(acc);
    } else if (searchDrawerState === "a2_milk_rate") {
      setA2MilkRate(acc);
    } else if (searchDrawerState === "a2_milk_disc_rate") {
      setA2MilkDiscRate(acc);
    } else if (searchDrawerState === "features") {
      setFeatures(acc);
    }

    setEdited({ ...edited, [searchDrawerState]: true });
    setSearchDrawerState(null);
  };

  return (
    <Page title="Franchisee Details">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Typography variant="h5" gutterBottom>
          {(currentData && currentData.name) || "Franchisee Details"}
        </Typography>
      </Stack>
      {(loadingData === true && <CircularProgress />) ||
        (currentData && (
          <Grid container columnSpacing={1} sx={{ pl: 2, pr: 2 }}>
            <Grid item xs={12} md={6} lg={6}>
              <Card sx={{ p: 2 }}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>Features:</b>
                    </TableCell>
                    <TableCell>
                      {/* <b>
                      {" "}
                      <Select
                        id="features-select"
                        label="Features"
                        multiple
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={currentData.features[value]}
                              />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {Object.keys(currentData.features).map((key) => (
                          <MenuItem value={key}>
                            {currentData.features[key]}
                          </MenuItem>
                        ))}
                      </Select>
                    </b> */}
                      <b>{features ? features.join(", ") : "-"}</b>
                    </TableCell>
                    {(features !== currentData?.features && (
                      <TableCell
                        onClick={() => {
                          setFeatures(currentData.features);
                          setEdited({ ...edited, features: false });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )) || (
                      <TableCell onClick={() => drawerSearchInit("features")}>
                        <EditIcon />
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Sub Fee Monthly:</b>
                    </TableCell>
                    <TableCell>
                      ₹{" "}
                      {currentData?.sub_fee_monthly
                        ? currentData?.sub_fee_monthly
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Category:</b>
                    </TableCell>
                    <TableCell>
                      <Select
                        id="category-select"
                        value={category}
                        onChange={(event) => {
                          setCategory(event.target.value);
                          setEdited({ ...edited, category: true });
                        }}
                      >
                        <MenuItem value={"sale"}>Sale</MenuItem>
                        <MenuItem value={"collection"}>Collection</MenuItem>
                      </Select>
                    </TableCell>
                    {category !== currentData?.category && (
                      <TableCell
                        onClick={() => {
                          setCategory(currentData.category);
                          setEdited({ ...edited, category: false });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )}
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <b>Show Rechrage Plan:</b>
                    </TableCell>
                    <TableCell>
                      <Select
                        id="recharge-select"
                        value={show_recharge_plan}
                        onChange={(event) => {
                          setShowRechargePlan(event.target.value);
                          setEdited({ ...edited, show_recharge_plan: true });
                        }}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </TableCell>
                    {show_recharge_plan !== currentData?.show_recharge_plan && (
                      <TableCell
                        onClick={() => {
                          setShowRechargePlan(currentData.show_recharge_plan);
                          setEdited({ ...edited, show_recharge_plan: false });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Milk Can Status:</b>
                    </TableCell>
                    <TableCell>
                      <Select
                        id="milkcan-select"
                        value={milk_can_default_status}
                        onChange={(event) => {
                          setMilkCanStatus(event.target.value);
                          setEdited({
                            ...edited,
                            milk_can_default_status: true,
                          });
                        }}
                      >
                        <MenuItem value={"recharge-pending"}>
                          Recharge Pending
                        </MenuItem>
                        <MenuItem value={"false"}>False</MenuItem>
                      </Select>
                    </TableCell>
                    {milk_can_default_status !==
                      currentData?.milk_can_default_status && (
                      <TableCell
                        onClick={() => {
                          setMilkCanStatus(currentData.milk_can_default_status);
                          setEdited({
                            ...edited,
                            milk_can_default_status: false,
                          });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Card>
              <Card sx={{ p: 2 }}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b> Phone:</b>
                    </TableCell>
                    <TableCell>
                      {currentData?.phone ? currentData?.phone : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b> State:</b>
                    </TableCell>
                    <TableCell>
                      {currentData?.state ? currentData?.state : "-"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Card>
              <Card sx={{ p: 2 }}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>GST:</b>
                    </TableCell>
                    <TableCell>{gst ? gst : "-"}</TableCell>
                    {(gst !== currentData.gst && (
                      <TableCell
                        onClick={() => {
                          setGst(currentData.gst);
                          setEdited({ ...edited, gst: false });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )) || (
                      <TableCell onClick={() => drawerSearchInit("gst")}>
                        <EditIcon />
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>FSSAI:</b>
                    </TableCell>
                    <TableCell>{fssai ? fssai : "-"}</TableCell>
                    {(fssai !== currentData.fssai && (
                      <TableCell
                        onClick={() => {
                          setFssai(currentData.fssai);
                          setEdited({ ...edited, fssai: false });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )) || (
                      <TableCell onClick={() => drawerSearchInit("fssai")}>
                        <EditIcon />
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Card>
              <Card sx={{ p: 2 }}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b> Morning Attendance:</b>
                    </TableCell>
                    <TableCell>{morning_attn ? morning_attn : "-"}</TableCell>
                    {(morning_attn !== currentData.morning_attn && (
                      <TableCell
                        onClick={() => {
                          setMorningAttn(currentData.morning_attn);
                          setEdited({ ...edited, morning_attn: false });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )) || (
                      <TableCell
                        onClick={() => drawerSearchInit("morning_attn")}
                      >
                        <EditIcon />
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b> Evening Attendance:</b>
                    </TableCell>
                    <TableCell>{evening_attn ? evening_attn : "-"}</TableCell>
                    {(evening_attn !== currentData.evening_attn && (
                      <TableCell
                        onClick={() => {
                          setEveningAttn(currentData.evening_attn);
                          setEdited({ ...edited, evening_attn: false });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )) || (
                      <TableCell
                        onClick={() => drawerSearchInit("evening_attn")}
                      >
                        <EditIcon />
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Card>
              <Card sx={{ p: 2 }}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>Farmer SMS:</b>
                    </TableCell>
                    <TableCell>
                      <Select
                        id="farmer-select"
                        value={farmer_sms}
                        onChange={(event) => {
                          setFarmerSms(event.target.value);
                          setEdited({ ...edited, farmer_sms: true });
                        }}
                      >
                        <MenuItem value={currentData?.farmer_sms === true}>
                          Yes
                        </MenuItem>
                        <MenuItem value={currentData?.farmer_sms === false}>
                          No
                        </MenuItem>
                      </Select>
                    </TableCell>
                    {farmer_sms !== currentData?.farmer_sms && (
                      <TableCell
                        onClick={() => {
                          setFarmerSms(currentData.farmer_sms);
                          setEdited({ ...edited, farmer_sms: false });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Customer SMS:</b>
                    </TableCell>
                    <TableCell>
                      <Select
                        id="customer-select"
                        value={customer_sms}
                        onChange={(event) => {
                          setCustomerSms(event.target.value);
                          setEdited({ ...edited, customer_sms: true });
                        }}
                      >
                        <MenuItem value={currentData?.customer_sms === true}>
                          Yes
                        </MenuItem>
                        <MenuItem value={currentData?.customer_sms === false}>
                          No
                        </MenuItem>
                      </Select>
                    </TableCell>
                    {customer_sms !== currentData?.customer_sms && (
                      <TableCell
                        onClick={() => {
                          setCustomerSms(currentData.customer_sms);
                          setEdited({ ...edited, customer_sms: false });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Customer Credit Limit:</b>
                    </TableCell>
                    <TableCell>
                      ₹
                      {default_cust_credit_limit
                        ? default_cust_credit_limit
                        : "-"}
                    </TableCell>
                    {(default_cust_credit_limit !==
                      currentData.default_cust_credit_limit && (
                      <TableCell
                        onClick={() => {
                          setCustomerCreditLimit(
                            currentData.default_cust_credit_limit
                          );
                          setEdited({
                            ...edited,
                            default_cust_credit_limit: false,
                          });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )) || (
                      <TableCell
                        onClick={() =>
                          drawerSearchInit("default_cust_credit_limit")
                        }
                      >
                        <EditIcon />
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Cust Credit Limit Status :</b>
                    </TableCell>
                    <TableCell>
                      <Select
                        id="credit-limit-select"
                        value={cust_credit_limit_active}
                        onChange={(event) => {
                          setCustomerCreditLimitStatus(event.target.value);
                          setEdited({
                            ...edited,
                            cust_credit_limit_active: true,
                          });
                        }}
                      >
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Not Active</MenuItem>
                      </Select>
                    </TableCell>
                    {cust_credit_limit_active !==
                      currentData?.cust_credit_limit_active && (
                      <TableCell
                        onClick={() => {
                          setCustomerCreditLimitStatus(
                            currentData.cust_credit_limit_active
                          );
                          setEdited({
                            ...edited,
                            cust_credit_limit_active: false,
                          });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Reset Credit Limit Inactive:</b>
                    </TableCell>
                    <TableCell>
                      <Select
                        id="reset-credit-limit-select"
                        value={reset_inactive_cust_limit}
                        onChange={(event) => {
                          setInactiveCreditLimit(event.target.value);
                          setEdited({
                            ...edited,
                            reset_inactive_cust_limit: true,
                          });
                        }}
                      >
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Not Active</MenuItem>
                      </Select>
                    </TableCell>
                    {reset_inactive_cust_limit !==
                      currentData?.reset_inactive_cust_limit && (
                      <TableCell
                        onClick={() => {
                          setInactiveCreditLimit(
                            currentData.reset_inactive_cust_limit
                          );
                          setEdited({
                            ...edited,
                            reset_inactive_cust_limit: false,
                          });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Card sx={{ p: 2 }}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>Milk Royalty:</b>
                    </TableCell>
                    <TableCell>
                      {currentData?.milk_royalty
                        ? currentData?.milk_royalty
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Milk Capacity:</b>
                    </TableCell>
                    <TableCell>
                      {currentData?.milk_capacity
                        ? currentData?.milk_capacity
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Milk Balance:</b>
                    </TableCell>
                    <TableCell>₹ {currentData?.milk_balance || "0"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Milk Balance On:</b>
                    </TableCell>
                    <TableCell>
                      {getDateStringFullWithTimeSync(
                        currentData?.milk_balance_on
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Card>
              <Card sx={{ p: 2 }}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>Cow Milk Rate:</b>
                    </TableCell>
                    <TableCell>
                      ₹ {cow_milk_rate ? cow_milk_rate : "-"}
                    </TableCell>
                    {(cow_milk_rate !== currentData.cow_milk_rate && (
                      <TableCell
                        onClick={() => {
                          setCowMilkRate(currentData.cow_milk_rate);
                          setEdited({ ...edited, cow_milk_rate: false });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )) || (
                      <TableCell
                        onClick={() => drawerSearchInit("cow_milk_rate")}
                      >
                        <EditIcon />
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Cow Milk Disc Rate:</b>
                    </TableCell>
                    <TableCell>
                      ₹ {currentData ? cow_milk_disc_rate : "-"}
                    </TableCell>
                    {(cow_milk_disc_rate !== currentData.cow_milk_disc_rate && (
                      <TableCell
                        onClick={() => {
                          setCowMilkDiscRate(currentData.cow_milk_disc_rate);
                          setEdited({ ...edited, cow_milk_disc_rate: false });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )) || (
                      <TableCell
                        onClick={() => drawerSearchInit("cow_milk_disc_rate")}
                      >
                        <EditIcon />
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Buff Milk Rate:</b>
                    </TableCell>
                    <TableCell>
                      ₹ {buff_milk_rate ? buff_milk_rate : "-"}
                    </TableCell>
                    {(buff_milk_rate !== currentData.buff_milk_rate && (
                      <TableCell
                        onClick={() => {
                          setBuffMilkRate(currentData.buff_milk_rate);
                          setEdited({ ...edited, buff_milk_rate: false });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )) || (
                      <TableCell
                        onClick={() => drawerSearchInit("buff_milk_rate")}
                      >
                        <EditIcon />
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Buff Milk Disc Rate:</b>
                    </TableCell>
                    <TableCell>
                      ₹ {buff_milk_disc_rate ? buff_milk_disc_rate : "-"}
                    </TableCell>
                    {(buff_milk_disc_rate !==
                      currentData.buff_milk_disc_rate && (
                      <TableCell
                        onClick={() => {
                          setBuffMilkDiscRate(currentData.buff_milk_disc_rate);
                          setEdited({ ...edited, buff_milk_disc_rate: false });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )) || (
                      <TableCell
                        onClick={() => drawerSearchInit("buff_milk_disc_rate")}
                      >
                        <EditIcon />
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>A2 Milk Rate:</b>
                    </TableCell>
                    <TableCell>₹ {a2_milk_rate ? a2_milk_rate : "-"}</TableCell>
                    {(a2_milk_rate !== currentData.a2_milk_rate && (
                      <TableCell
                        onClick={() => {
                          setA2MilkRate(currentData.a2_milk_rate);
                          setEdited({ ...edited, a2_milk_rate: false });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )) || (
                      <TableCell
                        onClick={() => drawerSearchInit("a2_milk_rate")}
                      >
                        <EditIcon />
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>A2 Milk Disc Rate:</b>
                    </TableCell>
                    <TableCell>
                      ₹ {a2_milk_disc_rate ? a2_milk_disc_rate : "-"}
                    </TableCell>
                    {(a2_milk_disc_rate !== currentData.a2_milk_disc_rate && (
                      <TableCell
                        onClick={() => {
                          setA2MilkDiscRate(currentData.a2_milk_disc_rate);
                          setEdited({ ...edited, a2_milk_disc_rate: false });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )) || (
                      <TableCell
                        onClick={() => drawerSearchInit("a2_milk_disc_rate")}
                      >
                        <EditIcon />
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Card>
              <Card sx={{ p: 2 }}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>Active:</b>
                    </TableCell>
                    <TableCell>
                      <Select
                        id="active-select"
                        value={active}
                        onChange={(event) => {
                          setActive(event.target.value);
                          setEdited({ ...edited, active: true });
                        }}
                      >
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Not Active</MenuItem>
                      </Select>
                    </TableCell>
                    {active !== currentData.active && (
                      <TableCell
                        onClick={() => {
                          setActive(currentData.active);
                          setEdited({ ...edited, active: false });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Crm Active:</b>
                    </TableCell>
                    <TableCell>
                      <Select
                        id="crm-select"
                        value={crm_active}
                        onChange={(event) => {
                          setCrmActive(event.target.value);
                          setEdited({ ...edited, crm_active: true });
                        }}
                      >
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Not Active</MenuItem>
                      </Select>
                    </TableCell>
                    {crm_active !== currentData?.crm_active && (
                      <TableCell
                        onClick={() => {
                          setCrmActive(currentData.crm_active);
                          setEdited({
                            ...edited,
                            crm_active: false,
                          });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Tmd Active:</b>
                    </TableCell>
                    <TableCell>
                      <Select
                        id="tmd-select"
                        value={tmd_active}
                        onChange={(event) => {
                          setTmdActive(event.target.value);
                          setEdited({ ...edited, tmd_active: true });
                        }}
                      >
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Not Active</MenuItem>
                      </Select>
                    </TableCell>
                    {tmd_active !== currentData?.tmd_active && (
                      <TableCell
                        onClick={() => {
                          setTmdActive(currentData.tmd_active);
                          setEdited({
                            ...edited,
                            tmd_active: false,
                          });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Referral Active:</b>
                    </TableCell>
                    <TableCell>
                      <Select
                        id="referral-active-select"
                        value={referral_active}
                        onChange={(event) => {
                          setReferral(event.target.value);
                          setEdited({ ...edited, referral_active: true });
                        }}
                      >
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Not Active</MenuItem>
                      </Select>
                    </TableCell>
                    {referral_active !== currentData?.referral_active && (
                      <TableCell
                        onClick={() => {
                          setReferral(currentData.referral_active);
                          setEdited({
                            ...edited,
                            referral_active: false,
                          });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Card>
              <Card sx={{ p: 2 }}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>Payouts Enabled:</b>
                    </TableCell>
                    <TableCell>
                      <Select
                        id="payouts-select"
                        value={payouts_enabled}
                        onChange={(event) => {
                          setPayoutEnabled(event.target.value);
                          setEdited({ ...edited, payouts_enabled: true });
                        }}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </TableCell>
                    {payouts_enabled !== currentData?.payouts_enabled && (
                      <TableCell
                        onClick={() => {
                          setPayoutEnabled(currentData.payouts_enabled);
                          setEdited({
                            ...edited,
                            payouts_enabled: false,
                          });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )}
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <b>Milk Flow Enabled:</b>
                    </TableCell>
                    <TableCell>
                      <Select
                        id="milk-flow-enabled-select"
                        value={milk_flow_enabled}
                        onChange={(event) => {
                          setMilkFlow(event.target.value);
                          setEdited({ ...edited, milk_flow_enabled: true });
                        }}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </TableCell>
                    {milk_flow_enabled !== currentData?.milk_flow_enabled && (
                      <TableCell
                        onClick={() => {
                          setMilkFlow(currentData?.milk_flow_enabled);
                          setEdited({
                            ...edited,
                            milk_flow_enabled: false,
                          });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Bypass Collection Entry:</b>
                    </TableCell>
                    <TableCell>
                      <Select
                        id="bypass-collection-select"
                        value={bypass_collection_entry}
                        onChange={(event) => {
                          setBypass(event.target.value);
                          setEdited({
                            ...edited,
                            bypass_collection_entry: true,
                          });
                        }}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </TableCell>
                    {bypass_collection_entry !==
                      currentData?.bypass_collection_entry && (
                      <TableCell
                        onClick={() => {
                          setBypass(currentData.bypass_collection_entry);
                          setEdited({
                            ...edited,
                            bypass_collection_entry: false,
                          });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Auto Salary:</b>
                    </TableCell>
                    <TableCell>
                      <Select
                        id="auto-salary-select"
                        value={auto_salary}
                        onChange={(event) => {
                          setSalary(event.target.value);
                          setEdited({ ...edited, auto_salary: true });
                        }}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </TableCell>
                    {bypass_collection_entry !==
                      currentData?.bypass_collection_entry && (
                      <TableCell
                        onClick={() => {
                          setSalary(currentData.auto_salary);
                          setEdited({
                            ...edited,
                            auto_salary: false,
                          });
                        }}
                      >
                        <RotateLeftRounded />
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Card>
            </Grid>
            {Object.values(edited).includes(true) && (
              <Grid item xs={12} md={12} lg={12}>
                <Stack direction={"row"}>
                  <Box flexGrow={1} />
                  <IconButton onClick={handleM1Close}>
                    <Avatar
                      variant="circular"
                      sx={{ backgroundColor: "error.main", color: "white" }}
                    >
                      <CloseIcon />
                    </Avatar>
                  </IconButton>
                  <IconButton onClick={handleApprove}>
                    <Avatar
                      variant="circular"
                      sx={{
                        backgroundColor: "primary.main",
                        color: "white",
                      }}
                    >
                      <SaveIcon />
                    </Avatar>
                  </IconButton>
                </Stack>
              </Grid>
            )}
            <Drawer
              anchor={"right"}
              open={searchDrawerState ? true : false}
              onClose={() => setSearchDrawerState(null)}
              sx={{ zIndex: 1300, p: 3 }}
            >
              {searchDrawerState === "cow_milk_rate" && (
                <CowMilkRateDrawer
                  onAccSelected={drawerReturnSearch}
                  extsCowMilkRate={cow_milk_rate}
                />
              )}
              {searchDrawerState === "cow_milk_disc_rate" && (
                <CowMilkDiscRateDrawer
                  onAccSelected={drawerReturnSearch}
                  extsCowMilkDiscRate={cow_milk_disc_rate}
                />
              )}
              {searchDrawerState === "buff_milk_rate" && (
                <BuffMilkRateDrawer
                  onAccSelected={drawerReturnSearch}
                  extsBuffMilkRate={buff_milk_rate}
                />
              )}
              {searchDrawerState === "buff_milk_disc_rate" && (
                <BuffMilkDiscRateDrawer
                  onAccSelected={drawerReturnSearch}
                  extsBuffMilkDiscRate={buff_milk_disc_rate}
                />
              )}
              {searchDrawerState === "a2_milk_rate" && (
                <A2MilkRateDrawer
                  onAccSelected={drawerReturnSearch}
                  extsA2MilkRate={a2_milk_rate}
                />
              )}
              {searchDrawerState === "a2_milk_disc_rate" && (
                <A2MilkDiscRateDrawer
                  onAccSelected={drawerReturnSearch}
                  extsA2MilkDiscRate={a2_milk_disc_rate}
                />
              )}
              {searchDrawerState === "default_cust_credit_limit" && (
                <CustomerCredLimitDrawer
                  onAccSelected={drawerReturnSearch}
                  extsCustCreditLimit={default_cust_credit_limit}
                />
              )}
              {searchDrawerState === "morning_attn" && (
                <MorningAttnDrawer
                  onAccSelected={drawerReturnSearch}
                  extsMorningAttn={morning_attn}
                />
              )}
              {searchDrawerState === "evening_attn" && (
                <EveningAttnDrawer
                  onAccSelected={drawerReturnSearch}
                  extsEveningAttn={evening_attn}
                />
              )}
              {searchDrawerState === "gst" && (
                <GstDrawer onAccSelected={drawerReturnSearch} extsGst={gst} />
              )}
              {searchDrawerState === "fssai" && (
                <FssaiDrawer
                  onAccSelected={drawerReturnSearch}
                  extsFssai={fssai}
                />
              )}
              {searchDrawerState === "features" && (
                <FeaturesDrawer
                  onAccSelected={drawerReturnSearch}
                  extsFeatures={features}
                />
              )}
            </Drawer>
          </Grid>
        ))}
      {error && (
        <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Page>
  );
}

const CowMilkRateDrawer = ({ onAccSelected, extsCowMilkRate }) => {
  const DataSchema = Yup.object().shape({
    cow_milk_rate: Yup.number().optional(),
  });

  const formik = useFormik({
    initialValues: {
      cow_milk_rate: extsCowMilkRate ?? "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      onAccSelected(values.cow_milk_rate);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
          spacing={2}
        >
          <Typography variant="h5">Set Cow Milk Rate</Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack width={"400px"} mx={1}>
              <TextField
                fullWidth
                type="number"
                label="Cow Milk Rate"
                {...getFieldProps("cow_milk_rate")}
                error={Boolean(touched.cow_milk_rate && errors.cow_milk_rate)}
                helperText={touched.cow_milk_rate && errors.cow_milk_rate}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Save
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </div>
  );
};
const CowMilkDiscRateDrawer = ({ onAccSelected, extsCowMilkDiscRate }) => {
  const DataSchema = Yup.object().shape({
    cow_milk_disc_rate: Yup.number().optional(),
  });

  const formik = useFormik({
    initialValues: {
      cow_milk_disc_rate: extsCowMilkDiscRate ?? "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      onAccSelected(values.cow_milk_disc_rate);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
          spacing={2}
        >
          <Typography variant="h5">Set Cow Milk Disc Rate</Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack width={"400px"} mx={1}>
              <TextField
                fullWidth
                type="number"
                label="Cow Milk Disc Rate"
                {...getFieldProps("cow_milk_disc_rate")}
                error={Boolean(
                  touched.cow_milk_disc_rate && errors.cow_milk_disc_rate
                )}
                helperText={
                  touched.cow_milk_disc_rate && errors.cow_milk_disc_rate
                }
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Save
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </div>
  );
};

const BuffMilkRateDrawer = ({ onAccSelected, extsBuffMilkRate }) => {
  const DataSchema = Yup.object().shape({
    buff_milk_rate: Yup.number().optional(),
  });

  const formik = useFormik({
    initialValues: {
      buff_milk_rate: extsBuffMilkRate ?? "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      onAccSelected(values.buff_milk_rate);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
          spacing={2}
        >
          <Typography variant="h5">Set Buff Milk Rate</Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack width={"400px"} mx={1}>
              <TextField
                fullWidth
                type="number"
                label="Buff Milk Rate"
                {...getFieldProps("buff_milk_rate")}
                error={Boolean(touched.buff_milk_rate && errors.buff_milk_rate)}
                helperText={touched.buff_milk_rate && errors.buff_milk_rate}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Save
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </div>
  );
};
const BuffMilkDiscRateDrawer = ({ onAccSelected, extsBuffMilkDiscRate }) => {
  const DataSchema = Yup.object().shape({
    buff_milk_disc_rate: Yup.number().optional(),
  });

  const formik = useFormik({
    initialValues: {
      buff_milk_disc_rate: extsBuffMilkDiscRate ?? "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      onAccSelected(values.buff_milk_disc_rate);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
          spacing={2}
        >
          <Typography variant="h5">Set Buff Milk Disc Rate</Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack width={"400px"} mx={1}>
              <TextField
                fullWidth
                type="number"
                label="Buff Milk Disc Rate"
                {...getFieldProps("buff_milk_disc_rate")}
                error={Boolean(
                  touched.buff_milk_disc_rate && errors.buff_milk_disc_rate
                )}
                helperText={
                  touched.buff_milk_disc_rate && errors.buff_milk_disc_rate
                }
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Save
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </div>
  );
};
const A2MilkRateDrawer = ({ onAccSelected, extsA2MilkRate }) => {
  const DataSchema = Yup.object().shape({
    a2_milk_rate: Yup.number().optional(),
  });

  const formik = useFormik({
    initialValues: {
      a2_milk_rate: extsA2MilkRate ?? "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      onAccSelected(values.a2_milk_rate);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
          spacing={2}
        >
          <Typography variant="h5">Set A2 Milk Rate</Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack width={"400px"} mx={1}>
              <TextField
                fullWidth
                multiline
                type="number"
                label="A2 Milk Rate"
                {...getFieldProps("a2_milk_rate")}
                error={Boolean(touched.a2_milk_rate && errors.a2_milk_rate)}
                helperText={touched.a2_milk_rate && errors.a2_milk_rate}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Save
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </div>
  );
};
const A2MilkDiscRateDrawer = ({ onAccSelected, extsA2MilkDiscRate }) => {
  const DataSchema = Yup.object().shape({
    a2_milk_disc_rate: Yup.number().optional(),
  });

  const formik = useFormik({
    initialValues: {
      a2_milk_disc_rate: extsA2MilkDiscRate ?? "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      onAccSelected(values.a2_milk_disc_rate);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
          spacing={2}
        >
          <Typography variant="h5">Set A2 Milk Disc Rate</Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack width={"400px"} mx={1}>
              <TextField
                fullWidth
                type="number"
                label="A2 Milk Disc Rate"
                {...getFieldProps("a2_milk_disc_rate")}
                error={Boolean(
                  touched.a2_milk_disc_rate && errors.a2_milk_disc_rate
                )}
                helperText={
                  touched.a2_milk_disc_rate && errors.a2_milk_disc_rate
                }
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Save
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </div>
  );
};
const CustomerCredLimitDrawer = ({ onAccSelected, extsCustCreditLimit }) => {
  const DataSchema = Yup.object().shape({
    default_cust_credit_limit: Yup.number().optional(),
  });

  const formik = useFormik({
    initialValues: {
      default_cust_credit_limit: extsCustCreditLimit ?? "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      onAccSelected(values.default_cust_credit_limit);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
          spacing={2}
        >
          <Typography variant="h5">Set Customer Credit Limit</Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack width={"400px"} mx={1}>
              <TextField
                fullWidth
                type="number"
                label="Customer Credit Limit"
                {...getFieldProps("default_cust_credit_limit")}
                error={Boolean(
                  touched.default_cust_credit_limit &&
                    errors.default_cust_credit_limit
                )}
                helperText={
                  touched.default_cust_credit_limit &&
                  errors.default_cust_credit_limit
                }
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Save
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </div>
  );
};
const MorningAttnDrawer = ({ onAccSelected, extsMorningAttn }) => {
  const DataSchema = Yup.object().shape({
    morning_attn: Yup.number().optional(),
  });

  const formik = useFormik({
    initialValues: {
      morning_attn: extsMorningAttn ?? "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      onAccSelected(values.morning_attn);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
          spacing={2}
        >
          <Typography variant="h5">Set Morning Attendance</Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack width={"400px"} mx={1}>
              <TextField
                fullWidth
                multiline
                type="number"
                label="Morning Attendance"
                {...getFieldProps("morning_attn")}
                error={Boolean(touched.morning_attn && errors.morning_attn)}
                helperText={touched.morning_attn && errors.morning_attn}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Save
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </div>
  );
};
const EveningAttnDrawer = ({ onAccSelected, extsEveningAttn }) => {
  const DataSchema = Yup.object().shape({
    default_cust_credit_limit: Yup.number().optional(),
  });

  const formik = useFormik({
    initialValues: {
      evening_attn: extsEveningAttn ?? "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      onAccSelected(values.evening_attn);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
          spacing={2}
        >
          <Typography variant="h5">Set Evening Attendance</Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack width={"400px"} mx={1}>
              <TextField
                fullWidth
                type="number"
                label="Evening Attendance"
                {...getFieldProps("evening_attn")}
                error={Boolean(touched.evening_attn && errors.evening_attn)}
                helperText={touched.evening_attn && errors.evening_attn}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Save
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </div>
  );
};
const GstDrawer = ({ onAccSelected, extsGst }) => {
  const DataSchema = Yup.object().shape({
    gst: Yup.string().optional(),
  });

  const formik = useFormik({
    initialValues: {
      gst: extsGst ?? "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      onAccSelected(values.gst);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
          spacing={2}
        >
          <Typography variant="h5">Set Gst</Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack width={"400px"} mx={1}>
              <TextField
                fullWidth
                multiline
                type="text"
                label="Gst"
                {...getFieldProps("gst")}
                error={Boolean(touched.gst && errors.gst)}
                helperText={touched.gst && errors.gst}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Save
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </div>
  );
};
const FssaiDrawer = ({ onAccSelected, extsFssai }) => {
  const DataSchema = Yup.object().shape({
    fssai: Yup.string().optional(),
  });

  const formik = useFormik({
    initialValues: {
      fssai: extsFssai ?? "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      onAccSelected(values.fssai);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
          spacing={2}
        >
          <Typography variant="h5">Set Fssai</Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack width={"400px"} mx={1}>
              <TextField
                fullWidth
                type="text"
                label="Fssai"
                {...getFieldProps("fssai")}
                error={Boolean(touched.fssai && errors.fssai)}
                helperText={touched.fssai && errors.fssai}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Save
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </div>
  );
};
const FeaturesDrawer = ({ onAccSelected, extsFeatures }) => {
  const DataSchema = Yup.object().shape({
    features: Yup.array(Yup.string()),
  });

  const formik = useFormik({
    initialValues: {
      features: extsFeatures ?? [],
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      onAccSelected(values.features);
    },
  });

  const featuresCollection = {
    sale: "Sale",
    collection: "Collection",
    transport: "Transport",
    product: "Product",
    fproduct: "Fproduct",
    employee: "Employee",
    pms: "Pms",
    wa: "wa",
    track: "Track",
    dispenser: "Dispenser",
    notification: "Notification",
    m2p: "M2p",
    bulk: "Bulk",
    extra: "Extra",
  };
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
          spacing={2}
        >
          <Typography variant="h5">Set Features</Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack width={"400px"} mx={1}>
              <Stack spacing={3}>
                {/* <Select
                  id="features-select"
                  label="Features"
                  {...getFieldProps("features")}
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                      }}
                    >
                      {selected.map((value) => (
                        <Chip key={value} label={featuresCollection[value]} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {Object.keys(featuresCollection).map((key) => (
                    <MenuItem value={key}>{featuresCollection[key]}</MenuItem>
                  ))}
                </Select> */}
                <Select
                  id="features-select"
                  label="Features"
                  multiple
                  {...getFieldProps("features")}
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                      }}
                    >
                      {selected.map((value) => (
                        <Chip key={value} label={featuresCollection[value]} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {Object.keys(featuresCollection).map((key) => (
                    <MenuItem value={key}>{featuresCollection[key]}</MenuItem>
                  ))}
                </Select>
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Save
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </div>
  );
};
