import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";

import {
  Card,
  Typography,
  Stack,
  CircularProgress,
  CardActionArea,
} from "@mui/material";

export default function AccBalanceCard({ accId, counter }) {
  const { getAccessToken, activeFranchise } = useAuth();

  const [balanceData, setBalanceData] = useState();
  const [loadingBalance, setLoadingBalance] = useState(false);

  const loadBalanceData = async () => {
    if (!accId || accId.length == 0) return;
    setLoadingBalance(true);
    let url = `/api/internalaccs/coreact/acc/${accId}/balance`;

    const ret = await dataFetch(url, await getAccessToken());
    if (ret.success === true) {
      setBalanceData(ret.data);
    }
    setLoadingBalance(false);
  };

  useEffect(() => {
    loadBalanceData();
  }, [counter]); // eslint-disable-line react-hooks/exhaustive-deps

  const [recalcBal, setRecalcBal] = useState(false);

  const reCalcBalance = async () => {
    if (recalcBal === false) {
      setRecalcBal(true);
      let url = `/api/internalaccs/coreact/acc/${accId}/recalculate`;

      const ret = await dataFetch(url, await getAccessToken());
      setRecalcBal(false);
    }
  };

  return (
    <Card sx={{ backgroundColor: "primary.main", boxShadow: 2 }}>
      <CardActionArea onDoubleClick={reCalcBalance}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 3, mx: 3 }}
          color={"white.main"}
        >
          <Typography variant="h6" marginRight={1}>
            Balance
          </Typography>
          {((loadingBalance === true || recalcBal === true) && (
            <CircularProgress color="success" />
          )) || <>₹ {balanceData?.toFixed(2) || "-"}</>}
        </Stack>
      </CardActionArea>
    </Card>
  );
}
