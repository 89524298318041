import {
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  TableRow,
  IconButton,
  CircularProgress,
} from "@mui/material";

import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";
import Label from "./Label";

import {
  getDayStartAndEndSync,
  getTimeString,
} from "src/utils/date-time-helper";

import CustomerDetailsPage from "../home/det/CustomerDetailsPage";

import { RefreshRounded } from "@mui/icons-material";

import { DateSelector } from "src/utils/date-selector";

export default function NewCustomersSection({ selectMgr, selectCart }) {
  const { getAccessToken, activeFranchise } = useAuth();

  const [newRecharges, setNewRecharges] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [selectDate, setSelectDate] = useState(Date.now());

  const loadData = async () => {
    setLoadingData(true);

    const dts = getDayStartAndEndSync(selectDate);

    let url = `/api/users/mgract/query/new?date_gt=${dts.start}&date_lt=${dts.end}`;
    if (selectCart && selectCart !== "all") {
      url += `&cart=${selectCart}`;
    }

    if (selectMgr && selectMgr !== "all") {
      url += `&agent=${selectMgr}`;
    }

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setNewRecharges(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, [activeFranchise, selectDate, selectMgr, selectCart]);

  const [currCust, setCurrCust] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleCustSelected = (CustData) => {
    setCurrCust(CustData);
    setDrawerOpen(true);
  };

  const TABLE_HEAD = [
    { id: "index", label: "Id", alignRight: false },
    { id: "name", label: "Name", alignRight: false },
    { id: "apt", label: "Apt", alignRight: false },
    { id: "cart", label: "Cart", alignRight: false },
    { id: "bal", label: "Balance", alignRight: false },
    { id: "onb", label: "Onboarding", alignRight: false },
    { id: "fs", label: "Free Sample", alignRight: false },
    { id: "reg", label: "Created On", alignRight: false },
    { id: "agent", label: "Agent", alignRight: false },
  ];

  return (
    <div>
      <Card>
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            mt={2}
          >
            <Stack direction="row" alignItems={"center"}>
              <Typography variant="h5">New Customers</Typography>
            </Stack>
            <Stack direction="row" alignItems={"center"}>
              <DateSelector value={selectDate} onChange={setSelectDate} />
              {(loadingData === true && (
                <CircularProgress color="secondary" />
              )) || (
                <IconButton
                  onClick={() => {
                    loadData();
                  }}
                >
                  <RefreshRounded />
                </IconButton>
              )}
            </Stack>
          </Stack>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <TableCell key={headCell.id}>{headCell.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {newRecharges.map((item, index) => (
                  <TableRow
                    key={item._id}
                    hover
                    onClick={() => {
                      handleCustSelected(item);
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <b>{item?.name}</b>
                    </TableCell>
                    <TableCell>
                      <b>{item?.address?.apartment?.name || "-"}</b>
                    </TableCell>
                    <TableCell>
                      <Label
                        color={item?.cart ? "success" : "error"}
                        variant="filled"
                      >
                        {item?.cart?.name || "-"}
                      </Label>
                    </TableCell>
                    <TableCell>
                      <Label
                        variant="outlined"
                        color={
                          item?.wallet?.balance >= 200
                            ? "success"
                            : item?.wallet?.balance > 0
                            ? "warning"
                            : "error"
                        }
                      >
                        ₹ {item?.wallet?.balance || "0"}
                      </Label>
                    </TableCell>
                    <TableCell>
                      <Label
                        variant="ghost"
                        color={
                          item?.onboarding_status === "completed"
                            ? "success"
                            : item?.onboarding_status === "later"
                            ? "warning"
                            : item?.onboarding_status === "not-interested"
                            ? "info"
                            : item?.onboarding_status === "duplicate"
                            ? "secondary"
                            : item?.onboarding_status === "ongoing"
                            ? "primary"
                            : "error"
                        }
                      >
                        <b>{item?.onboarding_status?.toUpperCase() || "-"}</b>
                      </Label>
                    </TableCell>
                    <TableCell>
                      <Label
                        variant="outlined"
                        color={
                          item?.free_sample_status === "completed"
                            ? "success"
                            : item?.free_sample_status === "ongoing"
                            ? "info"
                            : item?.free_sample_status === "pending"
                            ? "info"
                            : "error"
                        }
                      >
                        <b>{item?.free_sample_status?.toUpperCase() || "-"}</b>
                      </Label>
                    </TableCell>
                    <TableCell>
                      {getTimeString(
                        parseInt(item?._id.substring(0, 8), 16) * 1000
                      )}
                    </TableCell>
                    <TableCell>
                      <b>{item?.created_by_2?.name || "-"}</b>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Card>

      <CustomerDetailsPage
        customer={currCust}
        handleClose={() => setDrawerOpen(false)}
        drawerOpen={drawerOpen}
      />
    </div>
  );
}
