import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import { Card, Alert, Stack, TextField, Typography } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPatch } from "src/utils/data-fetch";

export default function FarmerCreditLimitModal({ handleM1Close, farmer }) {
  const [error, setError] = useState();

  const { getAccessToken, activeFranchise } = useAuth();

  const saveData = async (values) => {
    const ret = await dataPatch(
      `/api/farmers/mgract/farmer/${farmer._id}/creditlimit`,
      await getAccessToken(),
      { credit_limit: values.credit_limit },
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    credit_limit: Yup.number().required(),
  });

  const formik = useFormik({
    initialValues: {
      credit_limit: farmer?.credit_limit || "0",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await saveData(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 400,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Credit Limit For Farmer
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <TextField
              fullWidth
              type="number"
              label="Credit Limit"
              {...getFieldProps("credit_limit")}
              error={Boolean(touched.credit_limit && errors.credit_limit)}
              helperText={touched.credit_limit && errors.credit_limit}
              autoFocus
            />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
