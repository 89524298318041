import React from "react";
import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch, dataPatch, dataPost } from "src/utils/data-fetch";

import {
  Card,
  TableContainer,
  TablePagination,
  Stack,
  IconButton,
  Grid,
  Typography,
  Button,
  MenuItem,
  Select,
  Box,
  Menu,
} from "@mui/material";

import {
  getDateStringFullWithMonthSync,
  getMonthStartAndEndSync,
  getTimeString,
} from "src/utils/date-time-helper";

import Label from "./Label";

import { MobileDatePicker } from "@mui/x-date-pickers";

import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  EditRounded,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
  LockRounded,
} from "@mui/icons-material";

export const EmployeeAttnSection = ({ employee }) => {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [leaveStatus, setLeaveStatus] = useState("false");
  const [page, setPage] = useState(0);

  const [currentDate, setCurrentDate] = useState(Date.now());

  const { getAccessToken, activeFranchise } = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = async () => {
    setLoadingData(true);

    const a = getMonthStartAndEndSync(currentDate);

    let url = `/api/employees/mgract/attendance/query?employee=${employee}&date_gt=${a.start}&date_lt=${a.end}&sort=date&dir=d`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [activeFranchise, currentDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h4">{"Monthly Summary"}</Typography>

        <Stack direction={"row"} alignItems={"center"}>
          <IconButton
            sx={{ display: "inline-flex" }}
            onClick={() => {
              const newD = moment(currentDate).subtract(1, "month").valueOf();
              setCurrentDate(newD);
            }}
          >
            <KeyboardArrowLeftRounded />
          </IconButton>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale={"en-in"}
          >
            <MobileDatePicker
              views={["year", "month"]}
              value={moment(currentDate)}
              onAccept={(newValue) => {
                if (newValue) {
                  const d1 = newValue.valueOf();
                  if (currentDate !== d1) setCurrentDate(d1);
                }
              }}
              sx={{ maxWidth: "130px" }}
              closeOnSelect={true}
              format="MMM YYYY"
              loading={loadingData}
            />
          </LocalizationProvider>
          <IconButton
            sx={{ display: "inline-flex" }}
            onClick={() => {
              const newD = moment(currentDate).add(1, "month").valueOf();
              setCurrentDate(newD);
            }}
          >
            <KeyboardArrowRightRounded />
          </IconButton>
        </Stack>
      </Stack>
      <Grid container>
        {customerData &&
          customerData.length > 0 &&
          customerData.map((item) => {
            let col = "white";
            if (!item.punch_details || item.punch_details.length === 0)
              col = "lightgrey";

            let hrs = 0;
            if (item.sale_start_m > 0 && item.sale_end_m > 0) {
              hrs += item.sale_end_m - item.sale_start_m;
            }
            if (item.sale_start_e > 0 && item.sale_end_e > 0) {
              hrs += item.sale_end_e - item.sale_start_e;
            }
            hrs = hrs / 1000 / 60 / 60;
            return (
              <Grid item xs={12} md={6} lg={6}>
                <Card sx={{ px: 2, mx: 2, my: 1, py: 1, backgroundColor: col }}>
                  <Grid container spacing={0.5}>
                    <Grid item xs={6} md={6} lg={6}>
                      <b>{getDateStringFullWithMonthSync(item.date)}</b>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} textAlign={"right"}>
                      {item.status?.toUpperCase()}
                    </Grid>
                    {(item.punch_details.length > 0 && (
                      <Grid item xs={6} md={6} lg={6}>
                        Punch:{" "}
                        {item.punch_details
                          .reverse()
                          .map((i) => getTimeString(i.ts) + ", ")}
                      </Grid>
                    )) || (
                      <Grid item xs={6} md={6} lg={6}>
                        Checkin: {getTimeString(item.checkin)}
                      </Grid>
                    )}

                    <LeaveStatusEdit
                      item={item}
                      onStatusChange={handleSearch}
                    />

                    <Grid item xs={12}>
                      <Stack spacing={1} direction="row" alignItems="center">
                        <Typography variant="body1">
                          <b>Manual Status:</b>
                        </Typography>
                        <Label variant="filled" color="info">
                          {item.leave_status.toUpperCase()}
                        </Label>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                      <Stack spacing={1} direction={"row"}>
                        {item.morning_attn === 0 && (
                          <Label variant="filled" color="error">
                            M: Absent
                          </Label>
                        )}
                        {item.morning_attn === 1 && (
                          <Label variant="filled" color="warning">
                            M: Late
                          </Label>
                        )}
                        {item.evening_attn === 0 && (
                          <Label variant="filled" color="error">
                            E: Absent
                          </Label>
                        )}
                        {item.evening_attn === 1 && (
                          <Label variant="filled" color="warning">
                            E: Late
                          </Label>
                        )}
                        {hrs > 0 && (
                          <Label variant="filled" color="info">
                            Work: {hrs.toFixed(1)} Hrs
                          </Label>
                        )}
                        {item.locked === true && (
                          <Label variant="filled" color="success">
                            <LockRounded sx={{ p: 0.5 }} />
                          </Label>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

const LeaveStatusEdit = ({ item, onStatusChange }) => {
  const [leaveStatus, setLeaveStatus] = useState(item.leave_status);
  
  const { getAccessToken, activeFranchise } = useAuth();

  const handleChange = async (event) => {
    setLeaveStatus(event.target.value);
    
    const response = await dataPatch(
      `/api/employees/mgract/employee/${item._id}/leavestatus`,
      await getAccessToken(),
      {
        date: item.date,
        leave_status: event.target.value,
      },
      activeFranchise
    );

    if (response.success) {
      onStatusChange();
    }
  };

  return (
    <Grid item xs={6} md={6} lg={6}>
      <Box display="flex" justifyContent="flex-end">
        <Select
          value={leaveStatus}
          onChange={handleChange}
          variant="outlined"
          size="small"
        >
          <MenuItem value="present">Present</MenuItem>
          <MenuItem value="half day">Half Day</MenuItem>
          <MenuItem value="leave">Leave</MenuItem>
          <MenuItem value="LOP">LOP</MenuItem>
          <MenuItem value="week off">Week Off</MenuItem>
          <MenuItem value="absent">Absent</MenuItem>
          <MenuItem value="warning">Warning</MenuItem>
        </Select>
      </Box>
    </Grid>
  );
};
