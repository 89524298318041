import { useState } from "react";
import { Grid, Container, Typography, Box, Switch, Stack } from "@mui/material";

import Page from "../../components/Page";

import Paginated from "./Paginated";
import DateWise from "./DateWise";
import CustomerDetailsPage from "../home/det/CustomerDetailsPage";

export default function OnlinePayments() {
  const handleEntryClick = (id) => {};

  const [type, setType] = useState(false);

  const handleTypeChange = (event) => {
    setType(event.target.checked);
  };

  const [userDetailsModalState, setUserDetailsModalState] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  const handleUserClick = (user) => {
    console.log(user);
    setUserDetails(user);
    setUserDetailsModalState(true);
  };

  return (
    <Page title="Farmer Dashboard">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography variant="h4">Online Payments</Typography>
          <Box>
            DateWise
            <Switch onChange={handleTypeChange} />
          </Box>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            {type === true ? (
              <DateWise
                onEntryClick={handleEntryClick}
                onUserClick={handleUserClick}
              />
            ) : (
              <Paginated
                onEntryClick={handleEntryClick}
                onUserClick={handleUserClick}
              />
            )}
          </Grid>
        </Grid>
      </Container>

      <CustomerDetailsPage
        customer={userDetails}
        handleClose={() => setUserDetailsModalState(false)}
        drawerOpen={userDetailsModalState}
      />
    </Page>
  );
}
