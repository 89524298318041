import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import {
  DoneRounded as DoneIcon,
  CloseRounded as CloseIcon,
} from "@mui/icons-material";

import {
  Card,
  Alert,
  Stack,
  Typography,
  Grid,
  Box,
  CircularProgress,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Avatar,
} from "@mui/material";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";

import { dataFetch, dataPost } from "src/utils/data-fetch";
import {
  getDateStringFullSync,
  getTimeStringFullSync,
} from "src/utils/date-time-helper";
import EmployeeBalanceCard from "../det/EmployeeBalanceCard";

export default function ApprovePayoutModal({ handleM1Close, poutId }) {
  const [error, setError] = useState();
  const [currentData, setCurrentData] = useState({});
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const load = async () => {
    setLoadingData(true);

    const ret = await dataFetch(
      `/api/employeetransactions/mgract/txn/${poutId}`,
      await getAccessToken(),
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      setCurrentData(ret.data);
    } else {
      console.log(ret.message);
      setError(ret.message + " - " + ret.code);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const approveNow = async () => {
    setLoadingData(true);

    const ret = await dataPost(
      `/api/onlinepayouts/mgract/e-pout/${poutId}/${
        currentData.status === "approved" ? "process" : "approve"
      }`,
      await getAccessToken(),
      { process: true },
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      load();
    } else {
      console.log(ret.message);
      setError(ret.message + " - " + ret.code);
      setLoadingData(false);
    }
  };

  const rejectNow = async () => {
    setLoadingData(true);

    const ret = await dataPost(
      `/api/employeetransactions/mgract/txn/${poutId}/reject`,
      await getAccessToken(),
      {},
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      load();
    } else {
      console.log(ret.message);
      setError(ret.message + " - " + ret.code);
      setLoadingData(false);
    }
  };

  const approvePenalty = async () => {
    setLoadingData(true);

    const ret = await dataPost(
      `/api/employeetransactions/mgract/txn/${poutId}/approvepenalty`,
      await getAccessToken(),
      { amount: currentData.amount },
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      load();
    } else {
      console.log(ret.message);
      setError(ret.message + " - " + ret.code);
      setLoadingData(false);
    }
  };

  const handleReject = async () => {
    rejectNow();
  };

  const handleApprove = async () => {
    if (currentData.entity == "writeoff") {
      approvePenalty();
    } else if (
      currentData.entity == "payroll" &&
      currentData.method == "razorpayx"
    ) {
      approveNow();
    }
  };

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            {(currentData.entity === "writeoff" && "Penalty Details") ||
              "Payout Details"}
          </Typography>

          <EmployeeBalanceCard
            employee={currentData?.to?._id}
            counter={currentData}
          />
        </Stack>

        {(loadingData === true && <CircularProgress />) ||
          (currentData && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>Status:</b>
                    </TableCell>
                    <TableCell>
                      <b>
                        {currentData.status &&
                          currentData?.status?.toUpperCase()}
                      </b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>From:</b>
                    </TableCell>
                    <TableCell>
                      {currentData?.from?.name} ({currentData?.from?.entity})
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>To:</b>
                    </TableCell>
                    <TableCell>
                      {currentData?.to?.name} ({currentData?.to?.entity})
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Entity:</b>
                    </TableCell>
                    <TableCell>{currentData?.entity?.toUpperCase()}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Amount:</b>
                    </TableCell>
                    <TableCell>₹ {currentData?.amount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Method:</b>
                    </TableCell>
                    <TableCell>{currentData?.method?.toUpperCase()}</TableCell>
                  </TableRow>

                  {currentData.fund_account &&
                    currentData?.fund_account?.number && (
                      <>
                        <TableRow>
                          <TableCell>
                            <b>Acc No:</b>
                          </TableCell>
                          <TableCell>
                            {currentData.fund_account?.number}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <b>IFSC:</b>
                          </TableCell>
                          <TableCell>
                            {currentData.fund_account?.ifsc}
                          </TableCell>
                        </TableRow>
                      </>
                    )}

                  <TableRow>
                    <TableCell>
                      <b>Comments:</b>
                    </TableCell>
                    <TableCell>{currentData?.comments || "-"}</TableCell>
                  </TableRow>
                </TableBody>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Timeline position="alternate">
                  {currentData?.timeline?.map((item) => (
                    <TimelineItem>
                      <TimelineOppositeContent color="text.secondary">
                        {getDateStringFullSync(item.ts)}
                        <br />
                        {getTimeStringFullSync(item.ts)}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot color="success" />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        {item?.status?.toUpperCase()}
                        <br />
                        {(item.by && item?.by?.name) || "Auto"}
                        <br />
                        {item.by && item?.by?.entity}
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </Grid>

              {["requested", "approved"].includes(currentData.status) && (
                <Grid item xs={12} md={12} lg={12}>
                  <Stack direction={"row"}>
                    <Box flexGrow={1} />
                    <IconButton onClick={handleReject}>
                      <Avatar
                        variant="circular"
                        sx={{ backgroundColor: "error.main", color: "white" }}
                      >
                        <CloseIcon />
                      </Avatar>
                    </IconButton>
                    <IconButton onClick={handleApprove}>
                      <Avatar
                        variant="rounded"
                        sx={{
                          backgroundColor: "primary.main",
                          color: "white",
                        }}
                      >
                        <DoneIcon />
                      </Avatar>
                    </IconButton>
                  </Stack>
                </Grid>
              )}
            </Grid>
          ))}

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
