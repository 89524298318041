import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPatch } from "src/utils/data-fetch";

import {
  getDateStringFullWithSlash,
  parseDateStringFull,
} from "src/utils/date-time-helper";

export default function EditEmployeePersonalDetailModal({
  handleM1Close,
  employee,
}) {
  const [error, setError] = useState();

  const { getAccessToken, activeFranchise } = useAuth();

  const approveNow = async (values) => {
    let up = {
      address: values.address,
      phone: `+91${values.phone}`,
      alt_phone: `+91${values.alt_phone}`,
      dob: parseDateStringFull(values.dob),
      personal_email: values.personal_email,
      email: values.email,
    };

    const ret = await dataPatch(
      `/api/employees/mgract/v2/employee/${employee?._id}`,
      await getAccessToken(),
      up,
      activeFranchise
    );
    if (ret.success === true) {
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    address: Yup.string().required("Address is required"),
    phone: Yup.number()
      .required("Enter 10 digit number")
      .max(9999999999)
      .min(1111111111),
    alt_phone: Yup.number()
      .required("Enter 10 digit number")
      .max(9999999999)
      .min(1111111111),
    dob: Yup.string()
      .matches(
        /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/,
        "Enter in format DD/MM/YYYY"
      )
      .required("Date of Birth is required"),
    personal_email: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
    email: Yup.string().email("Invalid email").optional(),
  });

  const formik = useFormik({
    initialValues: {
      address: employee?.employee_det?.address || "",
      phone: employee?.phone?.slice(3) || "",
      alt_phone: employee?.employee_det?.alt_phone?.slice(3) || "",
      dob: getDateStringFullWithSlash(employee?.employee_det?.dob) || "",
      personal_email: employee?.employee_det?.personal_email || "",
      email: employee?.employee_det?.email || "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Edit Employee
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                type="text"
                label="Address"
                {...getFieldProps("address")}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />

              <TextField
                fullWidth
                type="phone"
                contentEditable={"false"}
                label="Phone"
                {...getFieldProps("phone")}
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone && errors.phone}
              />

              <TextField
                fullWidth
                type="phone"
                contentEditable={"false"}
                label="Alternate Phone"
                {...getFieldProps("alt_phone")}
                error={Boolean(touched.alt_phone && errors.alt_phone)}
                helperText={touched.alt_phone && errors.alt_phone}
              />

              <TextField
                fullWidth
                type="text"
                label="Date of Birth (DD/MM/YYYY)"
                {...getFieldProps("dob")}
                error={Boolean(touched.dob && errors.dob)}
                helperText={touched.dob && errors.dob}
              />

              <TextField
                fullWidth
                type="email"
                label="Personal Email"
                {...getFieldProps("personal_email")}
                error={Boolean(touched.personal_email && errors.personal_email)}
                helperText={touched.personal_email && errors.personal_email}
              />

              <TextField
                fullWidth
                type="email"
                label="Official Email"
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
