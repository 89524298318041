import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";

import { RefreshRounded, UploadRounded } from "@mui/icons-material";

import {
  Card,
  Container,
  Typography,
  Stack,
  Select,
  MenuItem,
  InputAdornment,
  Box,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { MobileDatePicker } from "@mui/x-date-pickers";

import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}

export default function RecalculateLedgers() {
  const [loadingData, setLoadingData] = useState(false);

  const [selectedFr, setSelectedFr] = useState("all");

  const [entity, setEntity] = useState("ledger");

  const { getAccessToken, allFranchisees } = useAuth();

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/internalaccs/coreact/accs/recalculate-all`;

    if (entity !== "all") url += `?entity=${entity}`;
    else {
      setLoadingData(false);
      window.alert("Please select entity!");
      return;
    }

    if (selectedFr && selectedFr !== "all") url += `&franchisee=${selectedFr}`;

    const ret = await dataFetch(url, await getAccessToken());
    if (ret.success === true) {
      await timeout(1000);
      window.alert(
        ret.message ||
          "Recalculating, this will take considerable amount of time."
      );
    } else {
      window.alert(ret.message || "Export failed!");
    }

    setLoadingData(false);
  };

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h5" gutterBottom>
            Recalculate Ledger Balance
          </Typography>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          mt={1}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Select
              id="fr-select"
              value={selectedFr}
              startAdornment={
                <InputAdornment position="start">
                  <Box sx={{ color: "text.disabled" }}>Franchise</Box>
                </InputAdornment>
              }
              onChange={(event) => setSelectedFr(event.target.value)}
            >
              <MenuItem value={"all"}>All</MenuItem>
              {allFranchisees.map((item) => (
                <MenuItem value={item._id}>{item.name}</MenuItem>
              ))}
            </Select>

            <Select
              id="entity-select"
              value={entity}
              startAdornment={
                <InputAdornment position="start">
                  <Box sx={{ color: "text.disabled" }}>Entity</Box>
                </InputAdornment>
              }
              onChange={(event) => setEntity(event.target.value)}
            >
              <MenuItem value={"ledger"}>Ledger</MenuItem>
              <MenuItem value={"bankaccount"}>Bank Account</MenuItem>
              <MenuItem value={"expense"}>Expense</MenuItem>
              <MenuItem value={"employee"}>Employee</MenuItem>
              <MenuItem value={"center"}>Center</MenuItem>
              <MenuItem value={"manager"}>Manager</MenuItem>
              <MenuItem value={"bulksale"}>Bulk Sale</MenuItem>
              <MenuItem value={"income"}>Income</MenuItem>
              <MenuItem value={"asset"}>Asset</MenuItem>
            </Select>
          </Stack>

          <LoadingButton
            size="large"
            type="submit"
            startIcon={<RefreshRounded />}
            variant="contained"
            loading={loadingData}
            onClick={handleSearch}
          >
            ReCalculate
          </LoadingButton>
        </Stack>
      </Container>
    </Card>
  );
}
