import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Drawer,
  OutlinedInput,
  InputAdornment,
  Box,
  Container,
  Switch,
  MenuItem,
  Autocomplete,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";

import { useSnackbar } from "src/contexts/SnackbarContext";
import MakeFullScreen from "src/components/MakeFullScreen";
import DeptSearch from "../../employee/dept/DeptSearch";
import CategorySearch from "../CategorySearch";
import { commonItems } from "src/utils/constants";

export default function AddVendor({ open, onClose }) {
  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [deptDrawerState, setDeptDrawerState] = useState(false);
  const [selectDept, setSelectDept] = useState(null);

  const [categoryDrawerState, setCategoryDrawerState] = useState(false);
  const [selectCategory, setSelectCategory] = useState(null);

  const [items, setItems] = useState([]);

  const approveNow = async (values) => {
    if (items.length === 0) {
      showSnackbar("Please add items.", "error");
      return;
    }

    const upd = {
      name: values.name,
      reliability: values.reliability,
      items: items,
    };

    if (values.phone.length > 0) upd.phone = values.phone;
    if (values.email.length > 0) upd.email = values.email;
    if (values.contact.length > 0) upd.contact = values.contact;
    if (values.address.length > 0) upd.address = values.address;
    if (values.website.length > 0) upd.website = values.website;

    if (values.comments.length > 0) {
      upd.comments = values.comments;
    }

    const ret = await dataPost(
      `/api/inventory/mgract/vendors/add`,
      await getAccessToken(),
      upd,
      activeFranchise
    );
    if (ret.success === true) {
      showSnackbar(ret.message || "Vendor added.", "success");
      onClose();
    } else {
      showSnackbar(ret.message + " - " + ret.code, "error");
    }
  };

  const DataSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone: Yup.string().optional(),
    email: Yup.string().optional(),
    contact: Yup.string().optional(),
    address: Yup.string().optional(),
    website: Yup.string().optional(),
    reliability: Yup.number().optional(),
    price_rating: Yup.number().optional(),
    comments: Yup.string().optional(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      contact: "",
      address: "",
      website: "",
      reliability: 5,
      price_rating: 3,
      comments: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const [tempInput, setTempInput] = useState("");
  const handleKeyDown = (e) => {
    switch (e.key) {
      case ",": {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.value.length > 0) {
          if (!items.includes(e.target.value)) {
            setItems((v) => [...v, e.target.value]);
            setTempInput("");
          }
        }
        break;
      }
      default:
    }
  };

  return (
    <MakeFullScreen
      title="Add Vendor"
      open={open}
      onClose={onClose}
      maxWidth={"md"}
    >
      <Container sx={{ px: 2, pb: 2 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <Stack spacing={2} direction={"row"}>
                <TextField
                  fullWidth
                  type="text"
                  label="Name"
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  select
                  type="text"
                  label="Reliability"
                  {...getFieldProps("reliability")}
                  error={Boolean(touched.reliability && errors.reliability)}
                  helperText={touched.reliability && errors.reliability}
                  sx={{ minWidth: 100 }}
                >
                  <MenuItem value={1}>Bad</MenuItem>
                  <MenuItem value={2}>Low</MenuItem>
                  <MenuItem value={3}>Medium</MenuItem>
                  <MenuItem value={4}>Good</MenuItem>
                  <MenuItem value={5}>High</MenuItem>
                </TextField>
              </Stack>

              <Stack spacing={2} direction={"row"}>
                <TextField
                  fullWidth
                  type="text"
                  label="Contact Person"
                  {...getFieldProps("contact")}
                  error={Boolean(touched.contact && errors.contact)}
                  helperText={touched.contact && errors.contact}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="Phone"
                  {...getFieldProps("phone")}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Stack>
              <Stack spacing={2} direction={"row"}>
                <TextField
                  fullWidth
                  type="text"
                  label="Email"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="Website"
                  {...getFieldProps("website")}
                  error={Boolean(touched.website && errors.website)}
                  helperText={touched.website && errors.website}
                />
              </Stack>

              <TextField
                fullWidth
                multiline
                type="text"
                label="Address"
                {...getFieldProps("address")}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />

              <Autocomplete
                multiple
                freeSolo
                disableClearable
                id="tags-standard"
                options={commonItems}
                getOptionLabel={(option) => option}
                value={items}
                onChange={(e, v) => {
                  setItems(v);
                }}
                filterSelectedOptions
                renderInput={(params) => {
                  params.inputProps.onKeyDown = handleKeyDown;
                  return (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Items"
                      placeholder="Use comma or enter key to add multiple."
                      fullWidth
                    />
                  );
                }}
              />

              <TextField
                fullWidth
                multiline
                type="text"
                label="Comments"
                {...getFieldProps("comments")}
                error={Boolean(touched.comments && errors.comments)}
                helperText={touched.comments && errors.comments}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        <Drawer
          anchor={"right"}
          open={deptDrawerState}
          onClose={() => setDeptDrawerState(false)}
          sx={{ zIndex: 1300 }}
          PaperProps={{
            sx: { width: "30%" },
          }}
        >
          <DeptSearch
            onDeptSelected={(dept) => {
              setDeptDrawerState(false);
              setSelectDept(dept);
            }}
          />
        </Drawer>
        <Drawer
          anchor={"right"}
          open={categoryDrawerState}
          onClose={() => setCategoryDrawerState(false)}
          sx={{ zIndex: 1300 }}
          PaperProps={{
            sx: { width: "30%" },
          }}
        >
          <CategorySearch
            onCategorySelected={(dept) => {
              setCategoryDrawerState(false);
              setSelectCategory(dept);
            }}
          />
        </Drawer>
      </Container>
    </MakeFullScreen>
  );
}
