import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "src/contexts/AuthContext";

import { styled } from "@mui/material/styles";
import { dataFetch } from "src/utils/data-fetch";

import { SearchRounded } from "@mui/icons-material";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import {
  Card,
  Container,
  Table,
  Box,
  OutlinedInput,
  InputAdornment,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  CircularProgress,
  Stack,
} from "@mui/material";

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "category", label: "Category", alignRight: true },
];

export default function TemplateSelect({ onTemplateSelected }) {
  const [templateData, setTemplateData] = useState([]);
  const [error, setError] = useState();
  const { getAccessToken, activeFranchise } = useAuth();

  const getAllTemplate = async () => {
    const ret = await dataFetch(
      `/api/sendnotification/mgract/templates`,
      await getAccessToken(),
      activeFranchise
    );

    console.log(ret);

    if (ret.success === true) {
      setTemplateData(ret.data.results.templates);
    } else {
      setError(ret.message + " - " + ret.code);
      console.error("API Error:", ret.message + " - " + ret.code);
    }
  };

  useEffect(() => {
    getAllTemplate();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card title="Select Template">
      <Container>
        {templateData.length > 0 ? (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow
                    hover
                    onClick={() => onTemplateSelected({ _id: null })}
                    style={{ backgroundColor: "#ddd" }}
                  >
                    <TableCell>
                      <b>Remove Assigned Template</b>
                    </TableCell>
                    <TableCell>-</TableCell>
                  </TableRow>
                  {templateData.map((item) => (
                    <TableRow
                      hover
                      key={item._id}
                      onClick={() => onTemplateSelected(item)}
                      style={{
                        backgroundColor:
                          item.active === false ? "#ddd" : "white",
                      }}
                    >
                      <TableCell>
                        <b>{item.name || ""}</b>
                      </TableCell>
                      <TableCell>{item.category || "-"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        )}
        {error && (
          <Box color="error.main" textAlign="center" my={2}>
            {error}
          </Box>
        )}
      </Container>
    </Card>
  );
}
