import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import {
  Card,
  Alert,
  Grid,
  CircularProgress,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import { dataFetch } from "src/utils/data-fetch";
import { getDateStringFullWithTimeSync } from "src/utils/date-time-helper";

export default function AdminManagerDetailsCard({ manager }) {
  const [error, setError] = useState();

  const [currentData, setCurrentData] = useState();
  const [loadingData, setLoadingData] = useState();

  const { getAccessToken } = useAuth();
  const [fr, setFr] = useState("");

  const load = async (values) => {
    setLoadingData(true);

    const ret = await dataFetch(
      `/api/managers/mgract/manager/${manager}`,
      await getAccessToken()
    );
    if (ret.success === true) {
      console.log(ret.data);
      setCurrentData(ret.data);
      setFr(ret?.data?.franchisee?._id);
    } else {
      console.log(ret.message);
      setError(ret.message + " - " + ret.code);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <Grid container columnSpacing={1} sx={{ pl: 2, pr: 2 }}>
      <Grid item xs={12} md={8} lg={8}>
        <Card sx={{ p: 3 }}>
          {(loadingData === true && <CircularProgress />) ||
            (currentData && (
              <Grid container columnSpacing={1} sx={{ pl: 2, pr: 2 }}>
                <Grid item xs={12} md={6} lg={6}>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <b>Name:</b>
                      </TableCell>
                      <TableCell>
                        <b>{currentData?.name || "-"}</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Phone:</b>
                      </TableCell>
                      <TableCell>{currentData?.phone || "-"}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>Balance:</b>
                      </TableCell>
                      <TableCell>
                        ₹ {currentData?.open_balance || "-"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <b>Balance On:</b>
                      </TableCell>
                      <TableCell>
                        {getDateStringFullWithTimeSync(currentData?.balance_on)}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <b>Franchisee:</b>
                      </TableCell>
                      <TableCell>{currentData?.franchisee?.name}</TableCell>
                    </TableRow>
                  </TableBody>
                </Grid>
              </Grid>
            ))}

          {error && (
            <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </Card>
      </Grid>
    </Grid>
  );
}
