import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import {
  Stack,
  TextField,
  Typography,
  AppBar,
  Toolbar,
  Slide,
  Dialog,
  Button,
  Switch,
  IconButton,
  Modal,
  MenuItem,
  Drawer,
  OutlinedInput,
  InputAdornment,
  Box,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";
import { CloseRounded, UploadRounded } from "@mui/icons-material";

import { forwardRef } from "react";
import UploadNotifImage from "./UploadNotifImage";
import { DateTimeDoubleSelector } from "src/utils/date-selector";
import { getNextHourMillis } from "src/utils/date-time-helper";
import ProductSearch from "../products/ProductSearch";
import { onboardingStatus } from "src/utils/constants";
import ApartmentSearch from "../apartment/ApartmentSearch";
import MilkCartSearch from "../apartment/det/CartSearch";
import { useSnackbar } from "src/contexts/SnackbarContext";
import { isValidHttpUrl } from "src/utils/app-utils";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const notificationActionLinks = {
  recharge: "Recharge",
  products: "All Products",
  product: "Product",
  refer: "Refer",
  other: "Other",
};

export default function AddNotifModal({
  drawerOpen,
  handleM1Close,
  notification,
}) {
  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [imageAvailable, setImageAvailable] = useState(
    notification?.imageUrl ? true : false
  );

  const [actionButtonAvailable, setActionButtonAvailable] = useState(false);
  const [actionSelected, setActionSelected] = useState("other");

  const [scheduleAvailable, setScheduleAvailable] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    getNextHourMillis(Date.now())
  );

  const [uploadModalState, setUploadModalState] = useState(false);

  const approveNow = async (values) => {
    const payload = {
      title: values.title,
      body: values.body,
      segment: values.segment,
    };

    if (imageAvailable) {
      payload["imageUrl"] = values.imageUrl;

      if (!values.imageUrl || values.imageUrl.length === 0) {
        showSnackbar("Please upload an image", "error");
        return;
      }

      if (values.body.length > 30) {
        showSnackbar(
          "Image notification should have a body of max 30 characters",
          "error"
        );
        return;
      }
    }

    if (values.link && values.link.length > 0) {
      payload["link"] = values.link;
      payload["link_text"] = values.link_text || "Open";

      if (values.link_text.length > 15) {
        showSnackbar("Action button should be max 15 characters", "error");
        return;
      }

      if (isValidHttpUrl(values.link) === false) {
        showSnackbar("Invalid URL", "error");
        return;
      }
    }

    if (values.description && values.description.length > 0) {
      payload["description"] = values.description;
    }

    if (
      [
        "balance-gt",
        "balance-lt",
        "onboarding-status",
        "cart",
        "apartment",
        "not-ordered",
        "balance-inactive",
      ].includes(values.segment)
    ) {
      if (!values.segment2 || values.segment2 === "") {
        showSnackbar("Please select a valid segment data", "error");
        return;
      }
      payload["segment2"] = `${values.segment2}`;
    }

    if (scheduleAvailable) {
      payload.send_now = false;
      payload.date = selectedDate;
    } else {
      payload.send_now = true;
    }

    try {
      const ret = await dataPost(
        `/api/sendnotification/mgract/send/customers`,
        await getAccessToken(),
        payload,
        activeFranchise
      );

      if (ret.success === true) {
        showSnackbar(ret.message, "success");
        formik.resetForm();
        handleM1Close();
      } else {
        showSnackbar(ret.message + " - " + ret.code, "error");
        console.error("API Error:", ret.message + " - " + ret.code);
      }
    } catch (err) {
      showSnackbar("Unexpected error: " + err.message, "error");
      console.error("Unexpected error:", err);
    }
  };

  const DataSchema = Yup.object().shape({
    title: Yup.string().required("Title is required").max(40),
    body: Yup.string().required("Subtitle is required").max(200),
    description: Yup.string(),
    link: Yup.string().optional(),
    link_text: Yup.string().optional(),
    imageUrl: Yup.string().optional(),
    segment: Yup.string().required("Segment is required"),
    segment2: Yup.string().optional(),
  });

  const formik = useFormik({
    initialValues: {
      title: notification?.title || "",
      body: notification?.body || "",
      description: notification?.description || "",
      link: notification?.link || "",
      link_text: notification?.link_text || "",
      imageUrl: notification?.imageUrl || "",
      segment: notification?.segment || "all",
      segment2: notification?.segment2 || "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      try {
        await approveNow(values);
      } catch (error) {
        console.error("Submission error:", error);
      }
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  const [productDrawerOpen, setProductDrawerOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [apartmentDrawerOpen, setApartmentDrawerOpen] = useState(false);
  const [selectedApt, setSelectedApt] = useState(null);

  const [cartDrawerOpen, setCartDrawerOpen] = useState(false);
  const [selectedCart, setSelectedCart] = useState(null);

  const [product2DrawerOpen, setProduct2DrawerOpen] = useState(false);

  const onActionSelectChange = (e) => {
    setActionSelected(e.target.value);
    if (e.target.value === "recharge") {
      setFieldValue("link", "https://milkvilla.in/recharge");
    } else if (e.target.value === "products") {
      setFieldValue("link", "https://milkvilla.in/products");
    } else if (e.target.value === "product") {
      setActionSelected("other");
      setProduct2DrawerOpen(true);
    } else if (e.target.value === "refer") {
      setFieldValue("link", "https://milkvilla.in/referearn");
    } else {
      setFieldValue("link", "");
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="body"
      open={drawerOpen}
      onClose={handleM1Close}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            Add Notification Msg
          </Typography>
          <Button variant="contained" color="error" onClick={handleM1Close}>
            <CloseRounded />
          </Button>
        </Toolbar>
      </AppBar>

      <Stack direction="column" spacing={2} p={2}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextField
                autoComplete="off"
                fullWidth
                type="text"
                label="Notification Title"
                {...getFieldProps("title")}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
              <TextField
                fullWidth
                multiline
                type="text"
                label="Notification Subtitle"
                {...getFieldProps("body")}
                error={Boolean(touched.body && errors.body)}
                helperText={touched.body && errors.body}
              />

              <TextField
                fullWidth
                type="text"
                multiline
                label="Description (after opening notification)"
                {...getFieldProps("description")}
                error={Boolean(touched.link && errors.link)}
                helperText={touched.link && errors.link}
                inputProps={{ maxLength: 350 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {formik.values.description.length}/350
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                select
                label="Customer Segment"
                {...getFieldProps("segment")}
                error={Boolean(touched.segment && errors.segment)}
                helperText={touched.segment && errors.segment}
              >
                <MenuItem value="all">All Customers</MenuItem>
                <MenuItem value="balance-lt">Balance Less Than</MenuItem>
                <MenuItem value="balance-gt">Balance Greater Than</MenuItem>
                <MenuItem value="balance-inactive">
                  Balance(&gt;= 200) But Inactive
                </MenuItem>
                <MenuItem value="nosample">Free sample not claimed</MenuItem>
                <MenuItem value="allsample">
                  Free sample claimed, not recharged
                </MenuItem>
                <MenuItem value="not-ordered">Product not ordered</MenuItem>
                <MenuItem value="onboarding-status">Onboarding Status</MenuItem>
                <MenuItem value="apartment">Select Zone / Apartment</MenuItem>
                <MenuItem value="cart">Select Cart</MenuItem>
              </TextField>

              {getFieldProps("segment").value === "not-ordered" && (
                <OutlinedInput
                  fullWidth
                  type="text"
                  value={(selectedProduct && selectedProduct.name) || ""}
                  onClick={() => setProductDrawerOpen(true)}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box sx={{ color: "text.disabled" }}>Product</Box>
                    </InputAdornment>
                  }
                />
              )}

              {getFieldProps("segment").value === "apartment" && (
                <OutlinedInput
                  fullWidth
                  type="text"
                  value={(selectedApt && selectedApt.name) || ""}
                  onClick={() => setApartmentDrawerOpen(true)}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box sx={{ color: "text.disabled" }}>Zone / Apt</Box>
                    </InputAdornment>
                  }
                />
              )}

              {getFieldProps("segment").value === "cart" && (
                <OutlinedInput
                  fullWidth
                  type="text"
                  value={(selectedCart && selectedCart.name) || ""}
                  onClick={() => setCartDrawerOpen(true)}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box sx={{ color: "text.disabled" }}>Cart</Box>
                    </InputAdornment>
                  }
                />
              )}

              {getFieldProps("segment").value === "onboarding-status" && (
                <TextField
                  fullWidth
                  select
                  label="Onboarding Status"
                  {...getFieldProps("segment2")}
                  error={Boolean(touched.segment2 && errors.segment2)}
                  helperText={touched.segment2 && errors.segment2}
                >
                  {Object.keys(onboardingStatus).map((item) => (
                    <MenuItem value={item}>{onboardingStatus[item]}</MenuItem>
                  ))}
                </TextField>
              )}

              {(getFieldProps("segment").value === "balance-lt" ||
                getFieldProps("segment").value === "balance-gt") && (
                <OutlinedInput
                  fullWidth
                  type="number"
                  {...getFieldProps("segment2")}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box sx={{ color: "text.disabled" }}>
                        {getFieldProps("segment").value}
                      </Box>
                    </InputAdornment>
                  }
                />
              )}

              <Stack direction="row" spacing={2} alignItems={"center"}>
                Link?
                <Switch
                  checked={actionButtonAvailable}
                  onChange={(e, c) => setActionButtonAvailable(c)}
                />
                {actionButtonAvailable && (
                  <TextField
                    fullWidth
                    multiline
                    type="text"
                    label="Action Button Text"
                    {...getFieldProps("link_text")}
                    error={Boolean(touched.link_text && errors.link_text)}
                    helperText={touched.link_text && errors.link_text}
                  />
                )}
              </Stack>

              {actionButtonAvailable && (
                <Stack direction="row" spacing={2} alignItems={"center"}>
                  <TextField
                    select
                    label="Select Link"
                    value={actionSelected}
                    onChange={onActionSelectChange}
                    sx={{ width: "150px" }}
                  >
                    {Object.keys(notificationActionLinks).map((item) => (
                      <MenuItem value={item}>
                        {notificationActionLinks[item]}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    type="text"
                    label="Action Link"
                    {...getFieldProps("link")}
                    error={Boolean(touched.link && errors.link)}
                    helperText={touched.link && errors.link}
                  />
                </Stack>
              )}

              <Stack direction="row" spacing={2} alignItems={"center"}>
                Image?
                <Switch
                  checked={imageAvailable}
                  onChange={(e, c) => setImageAvailable(c)}
                />
                {imageAvailable && (
                  <>
                    <TextField
                      fullWidth
                      type="text"
                      label="Image URL or upload"
                      {...getFieldProps("imageUrl")}
                      error={Boolean(touched.imageUrl && errors.imageUrl)}
                      helperText={touched.imageUrl && errors.imageUrl}
                    />
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setUploadModalState(true);
                      }}
                    >
                      <UploadRounded />
                    </IconButton>
                  </>
                )}
              </Stack>

              <Stack direction="row" spacing={2} alignItems={"center"}>
                Schedule?
                <Switch
                  checked={scheduleAvailable}
                  onChange={(e, c) => setScheduleAvailable(c)}
                />
                {scheduleAvailable && (
                  <DateTimeDoubleSelector
                    value={selectedDate}
                    onChange={setSelectedDate}
                  />
                )}
              </Stack>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>
      </Stack>

      <Modal open={uploadModalState} onClose={() => setUploadModalState(false)}>
        <UploadNotifImage
          handleM1Close={() => setUploadModalState(false)}
          returnUrl={(url) => setFieldValue("imageUrl", url)}
        />
      </Modal>

      {getFieldProps("segment").value === "not-ordered" &&
        productDrawerOpen === true && (
          <Drawer
            open={productDrawerOpen}
            onClose={() => setProductDrawerOpen(false)}
            sx={{ zIndex: 1300 }}
            anchor="right"
            PaperProps={{
              sx: { width: "50%" },
            }}
          >
            <div style={{ padding: "10px" }}>
              <ProductSearch
                onItemSelected={(product) => {
                  setSelectedProduct(product);
                  setFieldValue("segment2", product._id);
                  setProductDrawerOpen(false);
                }}
              />
            </div>
          </Drawer>
        )}

      {actionButtonAvailable === true && product2DrawerOpen === true && (
        <Drawer
          open={product2DrawerOpen}
          onClose={() => setProduct2DrawerOpen(false)}
          sx={{ zIndex: 1300 }}
          anchor="right"
          PaperProps={{
            sx: { width: "50%" },
          }}
        >
          <div style={{ padding: "10px" }}>
            <ProductSearch
              onItemSelected={(product) => {
                setFieldValue(
                  "link",
                  `https://milkvilla.in/product/${product._id}`
                );
                setProduct2DrawerOpen(false);
              }}
            />
          </div>
        </Drawer>
      )}

      {getFieldProps("segment").value === "apartment" &&
        apartmentDrawerOpen === true && (
          <Drawer
            open={apartmentDrawerOpen}
            onClose={() => setApartmentDrawerOpen(false)}
            sx={{ zIndex: 1300 }}
            anchor="right"
            PaperProps={{
              sx: { width: "50%" },
            }}
          >
            <div style={{ padding: "10px" }}>
              <ApartmentSearch
                onAptSelected={(product) => {
                  setSelectedApt(product);
                  setFieldValue("segment2", product._id);
                  setApartmentDrawerOpen(false);
                }}
              />
            </div>
          </Drawer>
        )}

      {getFieldProps("segment").value === "cart" && cartDrawerOpen === true && (
        <Drawer
          open={cartDrawerOpen}
          onClose={() => setCartDrawerOpen(false)}
          sx={{ zIndex: 1300 }}
          anchor="right"
          PaperProps={{
            sx: { width: "50%" },
          }}
        >
          <div style={{ padding: "10px" }}>
            <MilkCartSearch
              onCartSelected={(product) => {
                setSelectedCart(product);
                setFieldValue("segment2", (product && product._id) || "null");
                setCartDrawerOpen(false);
              }}
            />
          </div>
        </Drawer>
      )}
    </Dialog>
  );
}
