import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import {
  Stack,
  Typography,
  Card,
  CircularProgress,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tooltip,
  IconButton,
  Modal,
} from "@mui/material";

import { dataFetch } from "src/utils/data-fetch";

import {
  getDayStartAndEndSync,
  getTimeStringFullSync,
} from "src/utils/date-time-helper";

import { DateSelector } from "src/utils/date-selector";
import { RefreshRounded } from "@mui/icons-material";
import ApproveRejectExpense from "./ApproveRejectExpense";

const TABLE_HEAD = [
  { id: "amount", label: "Amount", alignRight: false },
  { id: "category", label: "Category", alignRight: false },
  { id: "by", label: "By", alignRight: false },
  { id: "method", label: "Method", alignRight: false },
  { id: "date", label: "Date", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "comments", label: "Comments", alignRight: false },
];

export default function PendingExpenseApproval() {
  const { getAccessToken, activeFranchise } = useAuth();

  const [allData, setAllData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const load = async () => {
    setLoadingData(true);

    let url = `/api/expenses/expenses/mgract/pending`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setAllData(ret.data);
    } else {
      setAllData([]);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    load();
  }, [activeFranchise]);

  const [selectedExpense, setSelectedExpense] = useState(null);

  return (
    <Card sx={{ pb: 3, pt: 1, px: 2, boxShadow: 3 }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography sx={{ my: 2 }} variant="h5">
          Pending Expenses
        </Typography>

        {(loadingData && <CircularProgress />) || (
          <IconButton onClick={load}>
            <RefreshRounded />
          </IconButton>
        )}
      </Stack>

      {allData.length > 0 && (
        <TableContainer sx={{ minHeight: 100 }}>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.alignRight ? "right" : "left"}
                    sortDirection={false}
                  >
                    <b>{headCell.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {allData.map((item, index) => {
                const color =
                  item.status === "success"
                    ? "white"
                    : [
                        "pending",
                        "processing",
                        "initiated",
                        "approved",
                      ].includes(item.status)
                    ? "lightcyan"
                    : "lightsalmon";
                return (
                  <TableRow
                    key={index}
                    hover
                    style={{ backgroundColor: color }}
                    onClick={() => setSelectedExpense(item)}
                  >
                    <TableCell>
                      <b>₹ {item?.amount?.toFixed(2) || 0}</b>
                    </TableCell>
                    <TableCell sx={{ color: "primary.main" }}>
                      <b>{item?.to?.name}</b>
                    </TableCell>
                    <TableCell sx={{ color: "red" }}>
                      {item?.from?.name}
                    </TableCell>
                    <TableCell>{item?.method?.toUpperCase()}</TableCell>
                    <TableCell>{getTimeStringFullSync(item?.date)}</TableCell>
                    <TableCell>{item?.status?.toUpperCase()}</TableCell>
                    <Tooltip title={item?.comments} arrow>
                      <TableCell>{item?.comments?.substring(0, 15)}</TableCell>
                    </Tooltip>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {allData.length === 0 && (
        <Typography textAlign={"center"}>No data found!</Typography>
      )}

      {selectedExpense && (
        <Modal
          open={selectedExpense !== null}
          onClose={() => setSelectedExpense(null)}
        >
          <ApproveRejectExpense
            handleM1Close={() => {
              setSelectedExpense(null);
              load();
            }}
            txnDet={selectedExpense}
          />
        </Modal>
      )}
    </Card>
  );
}
