import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import {
  Alert,
  Stack,
  TextField,
  Typography,
  AppBar,
  Toolbar,
  Slide,
  Dialog,
  Button,
  MenuItem,
  Card,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

import { CloseRounded } from "@mui/icons-material";

import { forwardRef } from "react";
import { useSnackbar } from "src/contexts/SnackbarContext";
import { dataPost } from "./data-fetch-cro";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddUserAddress({
  drawerOpen,
  handleClose,
  data,
  selectedPos,
  selectedApt,
  onUserAdded,
}) {
  const [error, setError] = useState();

  const { getAccessToken } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [address, setAddress] = useState(selectedApt?.location?.detail || "");
  const [flat, setFlat] = useState("");
  const [tower, setTower] = useState(selectedApt?.towers[0] || "");

  const validateData = () => {
    if (!selectedApt && !selectedPos) {
      showSnackbar("Please select a location", "error");
      handleClose();
      return;
    }

    if (selectedApt && (!tower || tower.length === 0)) {
      showSnackbar("Please select a tower", "error");
      return;
    }

    if (!flat || flat.length === 0) {
      showSnackbar("Please enter flat details", "error");
      return;
    }

    if (!address || address.length === 0) {
      showSnackbar("Please enter address", "error");
      return;
    }

    uploadUser();
  };

  const uploadUser = async () => {
    let payload = data;
    payload["cart"] = data?.cart_det?._id;
    delete payload?.cart_det;

    let add = {
      detail: address,
      flat: flat,
    };
    if (selectedApt) {
      add["apartment"] = selectedApt._id;
      add["tower"] = tower;
      add["lat"] = selectedApt.location.coordinates[1];
      add["lng"] = selectedApt.location.coordinates[0];
    } else {
      add["lat"] = selectedPos.lat;
      add["lng"] = selectedPos.lng;
    }

    payload["address"] = add;

    const url = `/api/cro/mgract/customers/add`;

    try {
      const ret = await dataPost(url, await getAccessToken(), payload, null);

      if (ret.success === true) {
        showSnackbar("User added successfully", "success");
        onUserAdded();
      } else {
        showSnackbar(ret.message || "Failed to upload data", "error");
      }
    } catch (e) {
      showSnackbar("Unexpected error: " + e?.message, "error");
    }
  };

  return (
    <Dialog
      fullScreen
      maxWidth="sm"
      scroll="body"
      open={drawerOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 1 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            Select Address
          </Typography>
          <Button variant="contained" color="error" onClick={handleClose}>
            <CloseRounded />
          </Button>
        </Toolbar>
      </AppBar>

      <Stack direction="column" spacing={1} p={1}>
        <Stack spacing={2}>
          <Card sx={{ p: 1, boxShadow: 2 }}>
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>
                  <b>{data.name}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Phone</TableCell>
                <TableCell>
                  <b>{data.phone}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Free Sample</TableCell>

                <TableCell>
                  <b>{data.free_sample ? "Yes" : "No"}</b>
                </TableCell>
              </TableRow>
              {data.cart_det && (
                <TableRow>
                  <TableCell>Cart</TableCell>
                  <TableCell>
                    <b>{data?.cart_det?.name}</b>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Card>

          {selectedApt && <AptCard entry={selectedApt} />}
          {selectedPos && (
            <Card sx={{ p: 2, boxShadow: 1 }}>
              <Typography variant="h6" color="black">
                {selectedPos.lat}, {selectedPos.lng}
              </Typography>
            </Card>
          )}

          {selectedApt && (
            <TextField
              select
              fullWidth
              label="Tower / Block / Lane"
              value={tower}
              onChange={(e) => setTower(e.target.value)}
            >
              {selectedApt.towers.map((tower) => (
                <MenuItem key={tower} value={tower}>
                  {tower}
                </MenuItem>
              ))}
            </TextField>
          )}

          <TextField
            fullWidth
            value={flat}
            onChange={(e) => setFlat(e.target.value)}
            type="text"
            label="Flat / House Details"
          />

          <TextField
            fullWidth
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            type="text"
            label="Full Address"
            multiline
          />

          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{ mt: 5 }}
            onClick={() => {
              validateData();
            }}
          >
            Save
          </Button>
        </Stack>
      </Stack>

      {error && (
        <Alert severity="error" variant="filled" sx={{ mt: 2, mx: 1 }}>
          {error}
        </Alert>
      )}
    </Dialog>
  );
}

const AptCard = ({ entry }) => {
  return (
    <Card sx={{ boxShadow: 1 }}>
      <Stack direction="row" alignItems="center" justifyContent="start">
        <Card
          sx={{ minWidth: "100px", maxWidth: "100px", height: "100px", mr: 1 }}
        >
          <img
            alt="header"
            src={(entry && entry.image_url) || "/static/noapt.svg"}
            style={{ width: "100%", height: "100%" }}
          />
        </Card>

        <Stack direction="column">
          <Typography variant="h6" color="black">
            {(entry && entry.name) || "No Apartment"}
          </Typography>
          <Typography variant="body2" color="black">
            {(entry && entry?.location?.detail?.substring(0, 60)) || "-"}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
