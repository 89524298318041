import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Stack,
} from "@mui/material";

import {
  getDateStringFullWithMonthSync,
  getDateStringFullWithTimeSync,
} from "src/utils/date-time-helper";

const TABLE_HEAD = [
  { id: "datetime", label: "Date & Time", alignRight: false },
  { id: "product", label: "Product", alignRight: false },
  { id: "quantity", label: "Quantity", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "orderstatus", label: "Orderstatus", alignRight: false },
  { id: "deliverytime", label: "Deliverytime", alignRight: false },
  { id: "deliveredby", label: "Deliveredby", alignRight: false },
];

export default function FarmerOrders({ farmer, defaultList = 10 }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [page, setPage] = useState(0);

  const { getAccessToken, activeFranchise } = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const loadData = async () => {
    setLoadingData(true);

    let url = `/api/farmerorders/mgract/query?sort=_id&dir=d&farmer=${
      farmer._id
    }&skip=${page * defaultList}&limit=${defaultList}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, [page, farmer]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h5" gutterBottom>
            Orders Data
          </Typography>

          {loadingData === true && <CircularProgress color="secondary" />}
        </Stack>

        {customerData.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {customerData.map((item) => {
                    const color =
                      item.status === "white"
                        ? "white"
                        : [
                            "pending",
                            "processing",
                            "initiated",
                            "approved",
                          ].includes(item.status)
                        ? "white"
                        : "white";
                    return (
                      <TableRow hover style={{ backgroundColor: color }}>
                        <TableCell>
                          <b>
                            {" "}
                            {getDateStringFullWithMonthSync(
                              parseInt(item._id.substring(0, 8), 16) * 1000
                            )}
                          </b>
                        </TableCell>
                        <TableCell>
                          <b> {item.product.name}</b>
                        </TableCell>
                        <TableCell>
                          <b>{item.quantity}</b>{" "}
                        </TableCell>
                        <TableCell>₹{item.total_amount.toFixed(1)}</TableCell>
                        <TableCell>{item.order_status.toUpperCase()}</TableCell>
                        <TableCell>
                          {(item.order_status === "delivered" &&
                            getDateStringFullWithTimeSync(
                              item.delivery_timestamp
                            )) ||
                            "-"}
                        </TableCell>
                        <TableCell>
                          {(item.order_status === "delivered" &&
                            item.delivered_by &&
                            item.delivered_by.name) ||
                            "-"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={-1}
              rowsPerPage={defaultList}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[defaultList]}
            />
          </div>
        )}

        {customerData.length === 0 && loadingData === false && (
          <div>
            <Typography gutterBottom align="center" variant="subtitle1">
              Not found
            </Typography>
            <Typography variant="body2" align="center" sx={{ mb: 4 }}>
              No results here.
            </Typography>
          </div>
        )}
      </Container>
    </Card>
  );
}
