import * as XLSX from "xlsx";
import {
  getDateStringFullSync,
  getDateStringFullWithTimeSync,
} from "./date-time-helper";

export function exportExcel(headers, data, fileName) {
  /** headers: [{
   *     key, //to look in data
   *     name, //display in excel
   *     type //data type for conversion
   *     object_details: [{}] //if type is object, then details of object
   *   }]
   */

  let newjs = [];
  let newHeaders = headers;

  if (typeof headers === "string") {
    newHeaders = excelExportFormats[headers];
  }

  data.forEach((dt) => {
    let n = {};
    for (const header of newHeaders) {
      if (header.type === "date") {
        n[header.name] = getDateStringFullSync(dt[header.key]);
      } else if (header.type === "datetime") {
        n[header.name] = getDateStringFullWithTimeSync(dt[header.key]);
      } else if (header.type === "number") {
        n[header.name] = Number(dt[header.key]);
      } else if (header.type === "object") {
        if (dt[header.key]) {
          for (const h2 of header.object_details) {
            if (h2.type === "date") {
              n[h2.name] = getDateStringFullSync(dt[header.key][h2.key]) || "-";
            } else if (h2.type === "datetime") {
              n[h2.name] =
                getDateStringFullWithTimeSync(dt[header.key][h2.key]) || "-";
            } else if (h2.type === "number") {
              n[h2.name] = Number(dt[header.key][h2.key]) || 0;
            } else {
              n[h2.name] = dt[header.key][h2.key] || "-";
            }
          }
        } else {
          for (const h2 of header.object_details) {
            n[h2.name] = "-";
          }
        }
      } else {
        n[header.name] = dt[header.key] || "-";
      }
    }
    newjs.push(n);
  });

  const worksheet = XLSX.utils.json_to_sheet(newjs);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
}

export const excelExportFormats = {
  TxnsCatPageAdmin: [
    { key: "_id", name: "id", type: "text" },
    { key: "date", name: "Date", type: "datetime" },
    {
      key: "from",
      type: "object",
      object_details: [
        { key: "name", name: "CR Ledger", type: "text" },
        { key: "ext_code", name: "CR Code", type: "text" },
      ],
    },
    {
      key: "to",
      type: "object",
      object_details: [
        { key: "name", name: "DR Ledger", type: "text" },
        { key: "ext_code", name: "DR Code", type: "text" },
      ],
    },
    { key: "amount", name: "Amount", type: "number" },
    {
      key: "franchisee",
      type: "object",
      object_details: [{ key: "name", name: "Center", type: "text" }],
    },
    { key: "entity", name: "Category", type: "text" },
    { key: "comments", name: "Narration", type: "text" },
  ],
  ApartmentSummary: [
    { key: "_id", name: "id", type: "text" },
    { key: "name", name: "Name", type: "text" },
    { key: "phone", name: "Phone", type: "text" },
    { key: "active", name: "Active", type: "text" },
    {
      key: "address",
      type: "object",
      object_details: [
        { key: "details", name: "Address", type: "text" },
        { key: "flat", name: "Flat", type: "text" },
      ],
    },
    { key: "sub", name: "Subscription", type: "text" },
    { key: "rech", name: "Total Recharge", type: "number" },
    { key: "sample", name: "Sample", type: "number" },
    { key: "balance", name: "Balance", type: "number" },
  ],
  CRMCustomer: [
    { key: "_id", name: "id", type: "text" },
    {
      key: "user",
      type: "object",
      object_details: [
        { key: "name", name: "Name", type: "text" },
        { key: "phone", name: "Phone", type: "text" },
        { key: "active", name: "Active", type: "text" },
      ],
    },
    {
      key: "cart",
      type: "object",
      object_details: [{ key: "name", name: "Cart", type: "text" }],
    },
    { key: "onb_status", name: "Status", type: "text" },
  ],
  SubDelivery: [
    { key: "_id", name: "id", type: "text" },
    {
      key: "user",
      type: "object",
      object_details: [
        { key: "name", name: "Name", type: "text" },
        { key: "phone", name: "Phone", type: "text" },
        { key: "active", name: "Active", type: "text" },
      ],
    },
    { key: "product_string", name: "Product", type: "text" },
    {
      key: "cart",
      type: "object",
      object_details: [{ key: "name", name: "Cart", type: "text" }],
    },
    {
      key: "product",
      type: "object",
      object_details: [{ key: "price_unit", name: "Unit", type: "text" }],
    },
    { key: "quantity", name: "Quantity", type: "number" },
    { key: "rate", name: "Rate", type: "number" },
    { key: "status", name: "Status", type: "text" },
    { key: "date_string", name: "Date", type: "text" },
    { key: "date2", name: "Delivery Time", type: "datetime" },
    { key: "slot", name: "Slot", type: "number" },
    { key: "comments", name: "Comments", type: "text" },
  ],
};
