import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import Select from "@mui/material/Select";
import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Grid,
  Box,
  Switch,
  Chip,
  MenuItem,
  Drawer,
  OutlinedInput,
  InputAdornment,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";

import MilkCartSearch from "../apartment/det/CartSearch";
import { DateSelector } from "src/utils/date-selector";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const statusList = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

const channelList = [
  { value: "whatsapp", label: "Whatsapp" },
  { value: "email", label: "Email" },
  { value: "sms", label: "SMS" },
  { value: "phone", label: "Phone" },
];

export default function AddCampaignModal({ handleM1Close }) {
  const [error, setError] = useState();
  const [cartDrawerState, setCartDrawerState] = useState(false);
  const [selectedCartList, setSelectedCartList] = useState([]);
  const [selectedCart, setSelectedCart] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(
    parseInt(Date.now())
  );
  const [selectedEndDate, setSelectedEndDate] = useState();

  const { getAccessToken, activeFranchise } = useAuth();

  const approveNow = async (values) => {
    const ret = await dataPost(
      `/api/campaigns/mgract/campaigns/add`,
      await getAccessToken(),
      {
        name: values.name,
        description: values.description,
        carts: selectedCartList.map((cart) => cart._id),
        objective: values.objective,
        start_date: selectedStartDate,
        end_date: selectedEndDate,
        channel: values.channel,
        status: values.status,
      },
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string().optional(),
    objective: Yup.string().optional(),
    start_date: Yup.number().required("enter start date"),
    end_date: Yup.number().optional(),
    channel: Yup.array(Yup.string()).optional(),
    status: Yup.number().required().default(1),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      carts: [],
      objective: "",
      start_date: parseInt(Date.now()),
      channel: [],
      status: 1,
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Add Campaign
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Name"
                    {...getFieldProps("name")}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />

                  <DateSelector
                    value={selectedStartDate}
                    onChange={setSelectedStartDate}
                    title="Start date"
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Description"
                    {...getFieldProps("description")}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Objective"
                    {...getFieldProps("objective")}
                    error={Boolean(touched.objective && errors.objective)}
                    helperText={touched.objective && errors.objective}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={3}>
                  <TextField
                    select
                    fullWidth
                    label="Status"
                    {...getFieldProps("status")}
                    MenuProps={MenuProps}
                    error={Boolean(touched.status && errors.status)}
                    helperText={touched.status && errors.status}
                  >
                    {statusList.map((status) => (
                      <MenuItem key={status.value} value={status.value}>
                        {status.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <DateSelector
                    value={selectedEndDate}
                    onChange={setSelectedEndDate}
                    title="End date"
                  />

                  <OutlinedInput
                    fullWidth
                    type="text"
                    value={
                      selectedCartList.length > 0
                        ? selectedCartList.map((cart) => cart.name).join(", ")
                        : ""
                    }
                    onClick={() => setCartDrawerState(true)}
                    startAdornment={
                      <InputAdornment position="start">
                        <Box sx={{ color: "text.disabled" }}>Cart</Box>
                      </InputAdornment>
                    }
                  />

                  <FormControl
                    fullWidth
                    error={Boolean(touched.channel && errors.channel)}
                  >
                    <InputLabel>Channel</InputLabel>
                    <Select
                      multiple
                      {...getFieldProps("channel")}
                      MenuProps={MenuProps}
                    >
                      {channelList.map((channel) => (
                        <MenuItem key={channel.value} value={channel.value}>
                          {channel.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.channel && errors.channel && (
                      <FormHelperText>{errors.channel}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>

      <Drawer
        anchor={"right"}
        open={cartDrawerState}
        onClose={() => setCartDrawerState(false)}
        sx={{ zIndex: 1300, p: 3 }}
      >
        <MilkCartSearch
          onCartSelected={(cart) => {
            setCartDrawerState(false);
            setSelectedCart(cart);
            selectedCartList.push(cart);
            setSelectedCartList(selectedCartList);
          }}
        />
      </Drawer>
    </div>
  );
}
