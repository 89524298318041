import { useState, useEffect } from "react";
import { useAuth } from "../../../contexts/AuthContext";

import { dataFetch } from "../../../utils/data-fetch";

import Label from "./Label";

import {
  Card,
  Typography,
  Stack,
  CircularProgress,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

export default function BankAccountCard({ employee }) {
  const [doc, setDoc] = useState({});
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const loadData = async () => {
    let url = `/api/employees/mgract/v2/employee/${employee.employee}/bankaccount`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setDoc(ret.data);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {(loadingData === true && <CircularProgress />) ||
        (doc && (
          <Card>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 3, mx: 3 }}
            >
              <Typography variant="h5" gutterBottom>
                Bank Account Details
              </Typography>

              <Label
                variant="filled"
                color={(doc.approved === true && "info") || "error"}
                sx={{
                  zIndex: 9,
                  top: 16,
                  right: 16,
                  position: "absolute",
                  textTransform: "uppercase",
                }}
              >
                {(doc.approved === true && "APPROVED") || "APPROVAL PENDING"}
              </Label>
            </Stack>

            <Stack sx={{ my: 3, mx: 3 }}>
              <TableBody>
                <TableRow hover>
                  <TableCell>Acc Number:</TableCell>
                  <TableCell>{doc.number}</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell>IFSC:</TableCell>
                  <TableCell>{doc.ifsc}</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell>Beneficiary:</TableCell>
                  <TableCell>{doc.name}</TableCell>
                </TableRow>
              </TableBody>
            </Stack>
          </Card>
        ))}
    </>
  );
}
