import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import Select from "@mui/material/Select";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Grid,
  Box,
  InputAdornment,
  MenuItem,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPatch, dataPost } from "src/utils/data-fetch";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function EditAdminManager({ handleM1Close, manager }) {
  const [error, setError] = useState();

  const { getAccessToken } = useAuth();

  const editManager = async (values) => {
    console.log(values);

    const ret = await dataPatch(
      `/api/managers/coreact/manager/${manager._id}`,
      await getAccessToken(),
      {
        category: values.category,
        active: values.active === "yes" ? true : false,
        max_txn: values.max_txn,
        max_expense: values.max_expense,
      }
    );
    if (ret.success === true) {
      alert("Manager updated.");
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    category: Yup.string().required("Enter category!"),
    active: Yup.string(),
    max_txn: Yup.number(),
    max_expense: Yup.number(),
  });
  const formik = useFormik({
    initialValues: {
      category: manager?.manager?.category || "",
      active: manager.active === true ? "yes" : "no",
      max_txn: manager?.manager?.max_txn || 0,
      max_expense: manager?.manager?.max_expense || 0,
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await editManager(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Edit Manager
          </Typography>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Stack spacing={3}></Stack>
          </Grid>
        </Grid>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Select
                id="cat-select"
                {...getFieldProps("category")}
                MenuProps={MenuProps}
                startAdornment={
                  <InputAdornment position="start">
                    <Box sx={{ color: "text.disabled" }}>Category</Box>
                  </InputAdornment>
                }
              >
                <MenuItem value={"plant"}>Plant</MenuItem>
                <MenuItem value={"crm"}>CRO / CRM</MenuItem>
                <MenuItem value={"crme"}>CRO Manager</MenuItem>
                <MenuItem value={"transport"}>Transport</MenuItem>
                <MenuItem value={"other"}>Other</MenuItem>
              </Select>

              <Select
                id="active-select"
                {...getFieldProps("active")}
                MenuProps={MenuProps}
                startAdornment={
                  <InputAdornment position="start">
                    <Box sx={{ color: "text.disabled" }}>Active</Box>
                  </InputAdornment>
                }
              >
                <MenuItem value={"yes"}>YES</MenuItem>
                <MenuItem value={"no"}>NO</MenuItem>
              </Select>

              <TextField
                fullWidth
                type="text"
                label="Max TXN"
                {...getFieldProps("max_txn")}
                error={Boolean(touched.max_txn && errors.max_txn)}
                helperText={touched.max_txn && errors.max_txn}
              />

              <TextField
                fullWidth
                type="text"
                label="Max Expense"
                {...getFieldProps("max_expense")}
                error={Boolean(touched.max_expense && errors.max_expense)}
                helperText={touched.max_expense && errors.max_expense}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
