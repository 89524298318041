import { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  Drawer,
  Tooltip,
  Stack,
} from "@mui/material";

import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "./data-fetch-cro";
import { getTimeString } from "src/utils/date-time-helper";

import Animation from "src/theme/skeleton";

export default function CartList({ onCartSelected }) {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const { getAccessToken, activeFranchise } = useAuth();

  const initialLoad = async () => {
    setLoadingData(true);
    const ret = await dataFetch(
      `/api/cro/mgract/accessible-detailed?date=${Date.now()}`,
      await getAccessToken(),
      activeFranchise
    );

    if (ret.success === true) {
      setData(ret.data);
    } else {
      setData([]);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    initialLoad();
  }, [activeFranchise]); // eslint-disable-line react-hooks/exhaustive-deps

  const MOut = ({ data }) => {
    return (
      <Stack direction={"column"}>
        <Typography variant="subtitle" noWrap mb={2}>
          <Tooltip title={data?.driver_name}>
            {data.name} ({data?.driver_name})
          </Tooltip>
        </Typography>
        <Typography variant="subtitle2" noWrap>
          Mor: {((data.morning ? data.morning.milk : 0.0) / 1000).toFixed(1)} L
          ({data.morning ? data.morning.interrupt : 0})
        </Typography>
        <Typography variant="subtitle2" noWrap>
          Eve: {((data.evening ? data.evening.milk : 0.0) / 1000).toFixed(1)} L
          ({data.evening ? data.evening.interrupt : 0})
        </Typography>
        <Typography variant="body2" noWrap>
          {getTimeString(data.last_update)}
        </Typography>
      </Stack>
    );
  };

  return (
    <>
      <Container>
        {loadingData === true ? (
          <Animation count={2}/>
        ) : (
          <Grid container spacing={1}>
            {data.map((cart, index) => {
              return (
                <Grid
                  key={cart._id}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  onClick={() => onCartSelected(cart)}
                >
                  
                  <Card sx={{ px: 2, py: 2, boxShadow: 1 }}>
                    <Container>
                      <MOut data={cart} />
                    </Container>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Container>
    </>
  );
}
