import { Grid, Container, Typography, Button, Stack } from "@mui/material";

import Page from "../../components/Page";

import FarmerSearch from "./home/FarmerSearch";
import NegBalanceFarmers from "./home/NegBalanceFarmers";

import { useNavigate } from "react-router-dom";
import PendingAccApprovals from "./acc/PendingAccApprovals";
import PendingPayoutApprovals from "./payout/PendingPayoutApprovals";
import { useAuth } from "src/contexts/AuthContext";
import { useConfirm } from "material-ui-confirm";
import { useState } from "react";
import { dataPost } from "src/utils/data-fetch";

export default function Farmers() {
  const navigate = useNavigate();

  const { getAccessToken, activeFranchise } = useAuth();
  const confirm = useConfirm();

  const [reloadCounter, setReloadCounter] = useState(0);

  const openFarmerDetailsPage = (farmer) => {
    navigate("details", { state: { farmer: farmer } });
  };

  const initiateAllPayouts = async () => {
    const url = `/api/farmers/mgract/payout`;

    const ret = await dataPost(url, await getAccessToken(), {}, activeFranchise);
    if (ret.success === true) {
      setReloadCounter(reloadCounter + 1);
    } else {
      alert("Error initiating all payouts");
    }
  };

  const handlePayoutAll = async () => {
    confirm({
      description: "Please confirm!",
      confirmationButtonProps: { autoFocus: true },
    })
      .then(() => {
        initiateAllPayouts();
      })
      .catch(() => {});
  };

  return (
    <Page title="Farmer Dashboard">
      <Container>
        <Stack
          direction="row"
          spacing={2}
          alignItems={"center"}
          justifyContent={"space-between"}
          mb={3}
        >
          <Typography variant="h4">Farmer Dashboard</Typography>
          <Button
            variant="contained"
            onClick={handlePayoutAll}
            sx={{ mt: 2, mb: 1 }}
          >
            Initiate all payments
          </Button>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <FarmerSearch onFarmerClick={openFarmerDetailsPage} />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <PendingAccApprovals onFarmerClick={openFarmerDetailsPage} />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <PendingPayoutApprovals
                  onFarmerClick={openFarmerDetailsPage}
                  counter={reloadCounter}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12} lg={4}>
            <NegBalanceFarmers onFarmerClick={openFarmerDetailsPage} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
