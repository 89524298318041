import { useState, useEffect } from "react";
import { useAuth } from "../../../../contexts/AuthContext";

import { dataFetch } from "../../../../utils/data-fetch";

import {
  ControlPointDuplicateRounded,
  DownloadRounded,
  RefreshRounded,
} from "@mui/icons-material";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  CircularProgress,
  Stack,
  Modal,
  Tooltip,
  IconButton,
  Avatar,
} from "@mui/material";

import {
  getDayStart,
  getDayEnd,
  getDayStartAndEndSync,
  getDateStringFullSync,
  getTimeStringFullSync,
} from "src/utils/date-time-helper";
import InternalTxnDetailsModalAdmin from "../txns/InternalTxnDetailsModalAdmin";
import { MobileDatePicker } from "@mui/x-date-pickers";

import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AddTxnModal from "../txns/AddTxnModal";
import { exportExcel } from "src/utils/excel-export-utils";
import InternalAccDetailsPage from "./InternalAccDetailsPage";
import Label from "src/pages/crm/Label";
import { stringToColor, txnIcon } from "src/utils/constants";

const TABLE_HEAD = [
  { id: "im", label: "", align: "left" },
  { id: "date", label: "Date", align: "left" },
  { id: "amount", label: "Amount", align: "left" },
  { id: "from", label: "Txn", align: "left" },
  { id: "center", label: "Plant", align: "center" },
  { id: "comments", label: "Narration", align: "center" },
  { id: "bal", label: "Running Bal", align: "right" },
  { id: "act", label: "", align: "right" },
];

export default function InternalTxnsByAccount({ account, setPrefillDate }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken } = useAuth();

  const [startDate, setStartDate] = useState(getDayStart(Date.now()));
  const [endDate, setEndDate] = useState(getDayEnd(Date.now()));

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/internalaccs/coreact/txns/query?sort=date&dir=a&date_gt=${startDate}&date_lt=${endDate}&account=${account._id}`;

    const ret = await dataFetch(url, await getAccessToken());
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const [payoutModalOpen, setPayoutModalOpen] = useState(null);

  const closePayoutModal = () => {
    setPayoutModalOpen(null);
  };

  const openPayoutModal = async (id) => {
    setPayoutModalOpen(id);
  };

  const duplicateTxnClicked = () => {
    setDuplicateTxnModalOpen(true);
  };

  const [duplicateTxnModalOpen, setDuplicateTxnModalOpen] = useState(false);
  const [duplicateData, setDuplicateData] = useState();

  const closeDuplicateTxnModal = () => {
    setDuplicateTxnModalOpen(false);
  };

  const [accDrawerData, setAccDrawerData] = useState(null);

  const onDownloadExcel = () => {
    exportExcel(
      "TxnsCatPageAdmin",
      customerData,
      `TXNS-${account?.name || "acc"}-${Date.now()}`
    );
  };

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h5" gutterBottom>
            Txns Data
          </Typography>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={"en-in"}
            >
              <MobileDatePicker
                value={moment(startDate)}
                onChange={(newValue) => {
                  if (newValue) {
                    const d1 = getDayStartAndEndSync(newValue.valueOf());
                    if (startDate !== d1.start) {
                      setStartDate(d1.start);
                      setPrefillDate(d1.start);
                      setEndDate(d1.end);
                    }
                  }
                }}
                closeOnSelect={true}
                format="DD/MM/YYYY"
                loading={loadingData}
              />
            </LocalizationProvider>

            <b>-</b>

            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={"en-in"}
            >
              <MobileDatePicker
                value={moment(endDate)}
                onChange={(newValue) => {
                  if (newValue) {
                    const d1 = getDayEnd(newValue.valueOf());
                    if (endDate !== d1) setEndDate(d1);
                  }
                }}
                closeOnSelect={true}
                format="DD/MM/YYYY"
                loading={loadingData}
              />
            </LocalizationProvider>

            {customerData.length > 0 && (
              <IconButton onClick={() => onDownloadExcel()}>
                <DownloadRounded />
              </IconButton>
            )}

            {(loadingData === true && (
              <CircularProgress color="secondary" />
            )) || (
              <IconButton onClick={() => handleSearch()}>
                <RefreshRounded />
              </IconButton>
            )}
          </Stack>
        </Stack>

        {customerData.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {customerData.map((item) => {
                    const color =
                      item.status === "success"
                        ? "white"
                        : [
                            "pending",
                            "processing",
                            "initiated",
                            "approved",
                          ].includes(item.status)
                        ? "lightcyan"
                        : "lightsalmon";
                    return (
                      <TableRow
                        hover
                        onClick={() => openPayoutModal(item._id)}
                        style={{ backgroundColor: color }}
                      >
                        <TableCell
                          style={{ width: "1px", whiteSpace: "nowrap" }}
                          align="left"
                        >
                          <Avatar
                            variant="rounded"
                            sx={{ bgcolor: stringToColor(`${item?.amount}`) }}
                          >
                            {txnIcon(item?.entity)}
                          </Avatar>
                        </TableCell>
                        <TableCell sx={{ color: "primary.dark" }} align="left">
                          <b>{getDateStringFullSync(item.date)}</b>
                          <br />
                          <small>{getTimeStringFullSync(item.date)}</small>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="h6">
                            ₹ {item.amount?.toLocaleString("en-IN")}
                          </Typography>
                          <Label color="primary" variant="ghost">
                            {item?.entity?.toUpperCase() || "-"}
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          CR:{" "}
                          <span
                            style={{ color: "green" }}
                            onClick={(e) => {
                              setAccDrawerData(item?.from);
                              e.stopPropagation();
                            }}
                          >
                            {item.from ? item.from.name : "-"}
                          </span>
                          <br />
                          DR:{" "}
                          <span
                            style={{ color: "red" }}
                            onClick={(e) => {
                              setAccDrawerData(item?.to);
                              e.stopPropagation();
                            }}
                          >
                            {item.to ? item.to.name : "-"}
                          </span>
                        </TableCell>

                        <TableCell align="center">
                          {(item?.franchisee?.name && (
                            <Label color="primary">
                              {item.franchisee.name}
                            </Label>
                          )) ||
                            "-"}
                        </TableCell>
                        <Tooltip
                          title={item.comments || ""}
                          arrow
                          align="center"
                        >
                          <TableCell>
                            {item.comments
                              ? item.comments.substring(0, 15)
                              : "-"}
                          </TableCell>
                        </Tooltip>
                        <TableCell align="right">
                          ₹{" "}
                          {(item?.from?._id === account._id &&
                            item?.from_tb?.toLocaleString("en-IN")) ||
                            item?.to_tb?.toLocaleString("en-IN")}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ width: "1px", whiteSpace: "nowrap" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setDuplicateData(item);
                            duplicateTxnClicked();
                          }}
                        >
                          <ControlPointDuplicateRounded />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}

        {customerData.length === 0 && loadingData === false && (
          <div>
            <Typography gutterBottom align="center" variant="subtitle1">
              Not found
            </Typography>
            <Typography variant="body2" align="center" sx={{ mb: 4 }}>
              No results here.
            </Typography>
          </div>
        )}
      </Container>

      <InternalTxnDetailsModalAdmin
        handleM1Close={closePayoutModal}
        poutId={payoutModalOpen}
        drawerOpen={payoutModalOpen ? true : false}
      />

      <Modal open={duplicateTxnModalOpen} onClose={closeDuplicateTxnModal}>
        <AddTxnModal
          handleM1Close={closeDuplicateTxnModal}
          duplicateData={duplicateData}
        />
      </Modal>

      {accDrawerData && (
        <InternalAccDetailsPage
          inpAcc={accDrawerData}
          open={true}
          onClose={() => setAccDrawerData(null)}
        />
      )}
    </Card>
  );
}
