import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import {
  Alert,
  Stack,
  TextField,
  Slide,
  Drawer,
  OutlinedInput,
  InputAdornment,
  Box,
  MenuItem,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataFetch, dataPost } from "src/utils/data-fetch";

import { forwardRef } from "react";
import TemplateSelect from "./TemplateSelect";
import MakeFullScreen from "src/components/MakeFullScreen";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddWpCampaignModal({ drawerOpen, handleM1Close }) {
  const [error, setError] = useState();
  const { getAccessToken, activeFranchise } = useAuth();
  const [campaignDrawerOpen, setCampaignDrawerOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState();

  const approveNow = async (values) => {
    const payload = {
      campaignName: values.campaign,
      CampaignType: values.type,
      templateName: selectedTemplate.name,
    };

    try {
      const ret = await dataPost(
        `/api/sendnotification/mgract/wp/campaign/create`,
        await getAccessToken(),
        payload,
        activeFranchise
      );

      if (ret.success === true) {
        console.log("Success:", ret.data);
        formik.resetForm();
        handleM1Close();
      } else {
        setError(ret.message + " - " + ret.code);
        console.error("API Error:", ret.message + " - " + ret.code); // Debugging
      }
    } catch (err) {
      setError("Unexpected error: " + err.message);
      console.error("Unexpected error:", err); // Debugging
    }
  };

  const DataSchema = Yup.object().shape({
    campaign: Yup.string().required("Campaign name is required"),
    type: Yup.string().required("campaign type is required"),
  });

  const formik = useFormik({
    initialValues: {
      campaign: "",
      type: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      console.log("Submitting form...", values); // Debugging
      try {
        await approveNow(values);
      } catch (error) {
        console.error("Submission error:", error); // Debugging
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <MakeFullScreen
        title="Add Campaign"
        maxWidth="sm"
        open={drawerOpen}
        onClose={handleM1Close}
      >
        <Stack direction="column" spacing={2} p={2}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  type="text"
                  label="Campaign name"
                  {...getFieldProps("campaign")}
                  error={Boolean(touched.campaign && errors.campaign)}
                  helperText={touched.campaign && errors.campaign}
                />

                <TextField
                  fullWidth
                  select
                  type="text"
                  label="Campaign Type"
                  {...getFieldProps("type")}
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type}
                >
                    <MenuItem value={"PublicAPI"}>Public API</MenuItem>
                    <MenuItem value={"PrivateApi"}>Private API</MenuItem>
                </TextField>

                <OutlinedInput
                  fullWidth
                  type="text"
                  value={(selectedTemplate && selectedTemplate.name) || ""}
                  onClick={() => setCampaignDrawerOpen(true)}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box sx={{ color: "text.disabled" }}>Template</Box>
                    </InputAdornment>
                  }
                />
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Save
              </LoadingButton>
            </Form>
          </FormikProvider>
        </Stack>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </MakeFullScreen>

      <Drawer
        anchor="right"
        open={campaignDrawerOpen}
        onClose={() => setCampaignDrawerOpen(false)}
        sx={{ zIndex: 1300, p: 3 }}
      >
        <TemplateSelect
          onTemplateSelected={(item) => {
            setSelectedTemplate(item);
            setCampaignDrawerOpen(false);
          }}
        />
      </Drawer>
    </>
  );
}
