import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { styled } from "@mui/material/styles";
import { dataFetch, dataPatch } from "src/utils/data-fetch";

import {
  Card,
  Box,
  Grid,
  CircularProgress,
  Modal,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import UploadProductImage from "./UploadProductImage";
import { Delete } from "@mui/icons-material";

export default function ProductImagesSection({ product }) {
  const [currentData, setCurrentData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const loadData = async () => {
    setLoadingData(true);

    let url = `/api/products/mgract/product/${product._id}/image`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCurrentData(ret.data);
    }
    setLoadingData(false);
  };

  const [uploadImageModal, setUploadImageModal] = useState(false);
  const closeUploadImageModal = () => {
    setUploadImageModal(false);
    loadData();
  };

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {loadingData === true ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={3}>
          {currentData?.map(
            (doc, index) => (
              console.log(doc),
              (
                <Grid item xs={12} md={4} lg={4} key={index}>
                  <OneImageSection
                    doc={doc}
                    len={currentData.length}
                    id={product._id}
                    reload={loadData}
                  />
                </Grid>
              )
            )
          )}

          <Grid item xs={12} md={4} lg={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                border: "2px dashed",
                borderRadius: 1,
                borderColor: "primary.main",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => setUploadImageModal(true)}
              >
                Upload Image
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
      <Modal open={uploadImageModal} onClose={closeUploadImageModal}>
        <UploadProductImage
          handleM1Close={closeUploadImageModal}
          productId={product._id}
        />
      </Modal>
    </div>
  );
}

const OneImageSection = ({ doc, len, id, reload }) => {
  const { getAccessToken, activeFranchise } = useAuth();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmDelete = async () => {
    await handleDelete(doc);
    setOpen(false);
    reload();
  };

  const handleDelete = async (doc) => {
    const url = `/api/products/mgract/product/${id}/image`;

    const ret = await dataPatch(
      url,
      await getAccessToken(),
      {
        image: doc,
      },
      activeFranchise
    );

    if (ret.code === 1000) {
      console.log(ret.data);
    } else {
      console.log(ret.message);
    }
  };

  const ProductImgStyle = styled("img")({
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "fit-content",
    position: "absolute",
  });

  return (
    <Card>
      <Box sx={{ pt: "100%", position: "relative" }}>
        <ProductImgStyle alt={"Image"} src={doc} />
        {len > 1 && (
          <IconButton
            sx={{
              position: "absolute",
              bottom: 8,
              right: 8,
              bgcolor: "rgba(255, 255, 255, 0.8)",
              "&:hover": {
                bgcolor: "rgba(255, 255, 255, 1)",
              },
            }}
            onClick={handleClickOpen} // Corrected to call the function directly
          >
            <Delete color="error" />
          </IconButton>
        )}
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Image"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this image?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

{
  /* <Stack spacing={2} sx={{ p: 3 }} direction={"column"}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle1">
            <Typography component="span" variant="body1">
              {doc.data}
            </Typography>
          </Typography>
        </Stack>

        {doc.status !== "approved" && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              color="error"
              variant="outlined"
              onClick={() => rejectNow(category)}
            >
              Reject
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => approveNow(category)}
            >
              Approve
            </Button>
          </Stack>
        )}
      </Stack> */
}
