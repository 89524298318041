import React from "react";
import { useState, useEffect } from "react";

import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Modal,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
} from "@mui/material";

import { AddCircleOutlined, DeleteRounded } from "@mui/icons-material";

import { useAuth } from "src/contexts/AuthContext";
import { dataFetch, dataPatch } from "src/utils/data-fetch";
import AddTowerModal from "./AddTowerModal";

const TABLE_HEAD = [
  { id: "name", label: "Franchisee Name", alignRIght: false },
  { id: "role", label: "Roles", alignRIght: false },
];

export default function AptTowersSection({ apt }) {
  const [balanceData, setBalanceData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const { getAccessToken, activeFranchise, allFranchisees } = useAuth();

  const [addModal, setAddModal] = useState(false);

  const closeAddModal = () => {
    setAddModal(false);
    fetchTowerData();
  };

  useEffect(() => {
    fetchTowerData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchTowerData = async () => {
    setLoadingData(true);

    const ret = await dataFetch(
      `/api/apartments/mgract/appt/${apt._id}/towers`,
      await getAccessToken(),
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      setBalanceData(ret.data);
    }
    setLoadingData(false);
  };

  const handleDelete = (item) => {
    deleteTower(item);
  };

  const deleteTower = async (tower) => {
    setLoadingData(true);

    let url = `/api/apartments/mgract/appt/${apt._id}/towers`;

    const ret = await dataPatch(
      url,
      await getAccessToken(),
      {
        tower: tower,
      },
      activeFranchise
    );

    if (ret.success === true) {
    } else {
      alert(ret.message);
    }
    setLoadingData(false);

    fetchTowerData();
  }; // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card title="Manager Access Control" sx={{ pt: 2, pb: 2 }}>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h5" gutterBottom>
            Manage Towers
          </Typography>
          <Stack direction="row">
            <AddCircleOutlined
              variant="contained"
              onClick={() => setAddModal(true)}
            >
              Add Tower
            </AddCircleOutlined>
          </Stack>
        </Stack>

        {loadingData === true && <CircularProgress />}
        {loadingData === false && balanceData.length > 0 ? (
          <TableContainer>
            <Table>
              <TableBody>
                {balanceData.map((item) => {
                  return (
                    <TableRow hover>
                      <TableCell>{item || "-"}</TableCell>
                      <TableCell
                        onClick={() => handleDelete(item)}
                        align="right"
                      >
                        <DeleteRounded />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2" align="center" sx={{ mt: 3, mb: 2 }}>
            No Data found
          </Typography>
        )}
      </Container>
      <Modal open={addModal} onClose={closeAddModal}>
        <AddTowerModal handleM1Close={closeAddModal} apt={apt} />
      </Modal>
    </Card>
  );
}
