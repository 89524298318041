import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";
import * as React from "react";
import Badge from "@mui/material/Badge";
import moment from "moment";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import {
  Card,
  Container,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Grid,
} from "@mui/material";

import {
  getDateStringFullWithSlash,
  getMonthStartAndEndSync,
} from "src/utils/date-time-helper";

function ServerDay(props) {
  const { highlightedDays = {}, day, outsideCurrentMonth, ...other } = props;
  const dtStr = getDateStringFullWithSlash(props["data-timestamp"]);
  const isSelected = !props.outsideCurrentMonth && highlightedDays[dtStr];

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={isSelected ? highlightedDays[dtStr] : undefined}
      color="secondary"
      sx={{ color: "red" }}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}

export default function CustomerCalendarSection({ customer }) {
  const [customerData, setCustomerData] = useState({});
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const start = getMonthStartAndEndSync(Date.now());

  const [startDate, setStartDate] = useState(start.start);
  const [endDate, setEndDate] = useState(start.end);

  const [highlightedDays, setHighlightedDays] = useState({});

  const loadData = async () => {
    setLoadingData(true);

    let url = `/api/milkhistory/mgract/summary/user/${customer._id}/date/${startDate}/${endDate}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setCustomerData(ret.data);
      console.log(ret.data);
      setHighlightedDays(ret.data.calendar);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, [customer, startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMonthChange = (date) => {
    setLoadingData(true);
    setHighlightedDays([]);
    const dt = getMonthStartAndEndSync(date.valueOf());
    setStartDate(dt.start);
    setEndDate(dt.end);
  };

  return (
    <Grid container spacing={2}>
      <Grid item sx={12} md={12} lg={12}>
        <Card sx={{ p: 3 }}>
          <Container>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={"en-in"}
            >
              <DateCalendar
                defaultValue={moment(startDate)}
                loading={loadingData}
                onMonthChange={handleMonthChange}
                renderLoading={() => <DayCalendarSkeleton />}
                slots={{
                  day: ServerDay,
                }}
                slotProps={{
                  day: {
                    highlightedDays,
                  },
                }}
                sx={{
                  height: "560px !important",
                  maxHeight: "560px !important",
                  width: "80%",
                  "& .MuiBadge-badge": {
                    // Adjustment for recordMade badge
                    fontSize: "1em",
                    padding: "10px",
                  },
                  "& .MuiDayCalendar-header": {
                    // Needed for weekday (ie S M T W T F S )adjustments (and padding if wanted)
                    // Adjusts spacing between
                    justifyContent: "center",
                    width: "100%",
                    overflow: "hidden",
                    margin: "2px, 0",
                    // paddingTop: '1em',
                    // paddingBottom: "1em",
                  },
                  "& .MuiDayCalendar-weekContainer": {
                    // Adjusts spacing between days (ie 1, 2, 3.. 27, 28)
                    justifyContent: "center",
                    overflow: "hidden",
                    width: "100%",
                    margin: 0,
                  },
                  "& .MuiPickersDay-dayWithMargin": {
                    // Grows width/height of day buttons
                    width: "calc(100% - 4px)",
                    height: "calc(100% - 4px)",
                    aspectRatio: "1",
                    // height: 'auto',

                    fontSize: "1.0em",
                  },
                  "& .MuiBadge-root": {
                    // Parent of button management
                    aspectRatio: 1,
                    width: "10%",
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                  },
                  "& .MuiDayCalendar-weekDayLabel": {
                    // Manages size of weekday labels
                    aspectRatio: 1,
                    width: "calc(10% - 4px)", // deals with margin
                    fontSize: "1.0em",
                  },
                  "& .MuiPickersCalendarHeader-label": {
                    // Manages month/year size
                    fontSize: "1.3em",
                  },
                  "& .MuiDayCalendar-monthContainer": {
                    // Not sure if needed, currently works tho
                    width: "100%",
                  },
                  "& .MuiPickersFadeTransitionGroup-root-MuiDateCalendar-viewTransitionContainer":
                    {
                      // Handles size of week row parent, 1.6 aspect is good for now
                      aspectRatio: "1.6",
                      overflow: "hidden",
                    },
                  "& .MuiDayCalendar-slideTransition": {
                    // Handles size of week row parent, 1.6 aspect is good for now
                    aspectRatio: 1.6,
                    width: "100%",
                    overflow: "hidden",
                  },
                  "& .MuiDayCalendar-loadingContainer": {
                    width: "100%",
                    aspectRatio: 1.6,
                  },
                  "& .MuiDayCalendarSkeleton-root": {
                    width: "100%",
                  },
                  "& .MuiDayCalendarSkeleton-week": {
                    width: "100%",
                  },
                  "& .MuiDayCalendarSkeleton-daySkeleton": {
                    width: "calc(10% - 4px) !important", // Deals with the margin calcs
                    aspectRatio: "1 !important",
                    height: "auto !important",
                  },
                }}
              />
            </LocalizationProvider>
          </Container>
        </Card>
      </Grid>
      <Grid item sx={12} md={12} lg={12}>
        <Card>
          <Container>
            <TableContainer sx={{ minHeight: 100 }}>
              <TableBody>
                <TableRow>
                  <TableCell>Cow Milk</TableCell>
                  <TableCell>{customerData?.cow_milk || "0"} Lt.</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Buffalow Milk</TableCell>
                  <TableCell>{customerData?.buffalo_milk || "0"} Lt.</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Price</TableCell>
                  <TableCell>₹ {customerData?.price || "0"}</TableCell>
                </TableRow>
              </TableBody>
            </TableContainer>
          </Container>
        </Card>
      </Grid>
    </Grid>
  );
}
