import {
  Stack,
  Typography,
  TextField,
  Grid,
  Container,
  Card,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import Page from "src/components/Page";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ReactFlow from "reactflow";

import "reactflow/dist/style.css";

export default function MilkflowPage() {
  const [selectedDate, setSelectedDate] = useState(moment(Date.now()));
  const [milkflow, setMilkflow] = useState();

  const { getAccessToken, activeFranchisee } = useAuth();
  console.log(selectedDate);

  const initialLoad = async () => {
    const ret = await dataFetch(
      `/api/milkflow/mgract/flow/bydate?date=${selectedDate.valueOf()}`,
      await getAccessToken(),
      activeFranchisee
    );

    if (ret.success) {
      setMilkflow(ret.data);
    }
  };

  const load = async () => {
    const ret = await dataFetch(
      `/api/milkflow/mgract/flow/bydate?date=${selectedDate.valueOf()}`,
      await getAccessToken(),
      activeFranchisee
    );

    if (ret.success) {
      setMilkflow(ret.data);
      console.log(ret.data);
    }
  };

  useEffect(() => {
    initialLoad();
  }, [activeFranchisee]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    load();
    console.log("change");
  }, [selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const initialNodes = [
    {
      id: "1",
      position: { x: 250, y: 0 },
      data: {
        label:
          "Farmer Collection : " +
          (milkflow ? milkflow.farmer_coll.qty : "---"),
      },
    },
    {
      id: "2",
      position: { x: 250, y: 100 },
      data: {
        label:
          "Center Collection : " + (milkflow ? milkflow.center_coll.qty : "--"),
      },
    },

    milkflow?.transport.length > 0
        ? milkflow.transport.map((t, idx) => {
            if(t.flow_type === "out"){
            return {
              id: `t-${idx}`,
              position: { x: 0 + idx *100 },
              data: {
                label: `Transport ${idx + 1} : ${t.qty}`,
              },
            }
            };
          })
        : {
            id: "t-0",
            position: { x: 250, y: 200 },
            data: {
              label: "Transport 1 : 0",
            },
        },

        {
            id: "3",
            position: { x: 0, y: 300 },
            data: {
                label:
                "Cart Sale : " + (milkflow ? milkflow.cart_sale.qty : "--"),
            },
        },
            {
            id: "4",
            position: { x: 250, y: 300 },
            data: {
                label:
                "Bulk Sale : " + (milkflow ? milkflow.bulk_sale.qty : "--"),
            },
        },
            {
            id: "5",
            position: { x: 500, y: 300 },
            data: {
                label:
                "Total Error : " + (milkflow ? milkflow.error.qty : "--"),
            },
        }
  ];
  const initialEdges = [
    { id: "e1-2", source: "1", target: "2" },
    milkflow?.transport.length > 0
        ? milkflow.transport.map((t, idx) => {
            if(t.flow_type === "out"){
            return {
              id: `e2-t-${idx}`,
              source: "2",
              target: `t-${idx}`,
            }
            };
          })
        : {
            id: "e2-t-0",
            source: "2",
            target: "t-0",
        },
        { id: "e2-3", source: "t-0", target: "3" },
        { id: "e2-4", source: "t-0", target: "4" },
        { id: "e2-5", source: "t-0", target: "5" },
];

  return (
    <Page title="Milkflow">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 3, mb: 2, mx: 3 }}
      >
        <Typography variant="h5" gutterBottom>
          Milkflow Summary
        </Typography>
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale={"en-in"}
        >
          <DesktopDatePicker
            views={["year", "month", "day"]}
            value={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Stack>

      <Container>
        <div style={{ width: "100vw", height: "100vh" }}>
          <ReactFlow nodes={initialNodes} edges={initialEdges} />
        </div>
      </Container>
    </Page>
  );
}
