import React from "react";
import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch, dataPatch, dataPost } from "src/utils/data-fetch";

import {
  Edit as EditIcon,
  EditRounded,
  VisibilityRounded,
} from "@mui/icons-material";

import {
  Card,
  Typography,
  Grid,
  Stack,
  CircularProgress,
  Button,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Drawer,
  IconButton,
} from "@mui/material";
import { useSnackbar } from "src/contexts/SnackbarContext";
import MilkCartSearch from "src/pages/apartment/det/CartSearch";
import EmployeeSearch from "src/pages/employee/EmployeeSearch";
import EmpSearch from "./EmpSearch";

export const MilkCartsInfoCard = ({ cart }) => {
  const [currentData, setCurrentData] = useState();
  const [loadingData2, setLoadingData2] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const loadData = async () => {
    let url = `/api/milkcarts/mgract/v2/milkcart/${cart._id}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setCurrentData(ret.data);
    } else {
      showSnackbar(ret.message || "Failed to fetch data", "error");
    }

    setLoadingData2(false);
  };

  useEffect(() => {
    setLoadingData2(true);
    loadData();
  }, []);

  return (
    <>
      {loadingData2 === true ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={4}>
            <Stack direction="column" justifyContent="start" spacing={1}>
              <EmployeeCard
                data={currentData?.default_employee}
                pos={1}
                cartId={currentData?._id}
                onView={() => {}}
                reload={loadData}
              />
              <EmployeeCard
                data={currentData?.employee2}
                pos={2}
                cartId={currentData?._id}
                onView={() => {}}
                reload={loadData}
              />
              <EmployeeCard
                data={currentData?.employee3}
                pos={3}
                cartId={currentData?._id}
                onView={() => {}}
                reload={loadData}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={8} lg={8}>
            <MilkCartsDetailsCardSecondary
              loading={loadingData2}
              doc={currentData}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

const EmployeeCard = ({ data, pos, onView, cartId, reload }) => {
  const [drawerState, setDrawerState] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const updateEmployee = async (emp) => {
    setLoadingData(true);

    let url = `/api/milkcarts/mgract/milkcart/${cartId}/employee`;

    const ret = await dataPatch(
      url,
      await getAccessToken(),
      { employee: emp?.employee || null, position: pos },
      activeFranchise
    );

    if (ret.success === true) {
      reload();
      showSnackbar(ret.message || "Cart updated", "success");
    } else {
      showSnackbar(ret.message || "Failed to update cart", "error");
    }

    setLoadingData(false);
  };

  return (
    <Card
      sx={{
        flexGrow: 1,
        backgroundColor: data?.active === true ? "white.main" : "lightgray",
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="start">
        <Card
          sx={{ minWidth: "100px", maxWidth: "100px", height: "100px", mr: 1 }}
        >
          <img
            alt="header"
            src={"/static/driver.svg"}
            style={{ width: "100%", height: "100%", padding: "10px" }}
          />
        </Card>

        <Stack direction="column" flexGrow={1}>
          <Typography variant="h6" color={"black"}>
            {data?.name || "Not Assigned"}
          </Typography>
          <Typography variant="body2" color="black">
            {data?.phone || "-"}
          </Typography>
        </Stack>

        <IconButton
          color="primary"
          sx={{
            backgroundColor: "white",
            position: "absolute",
            right: "5px",
            top: "5px",
          }}
          onClick={onView}
        >
          <VisibilityRounded />
        </IconButton>

        <IconButton
          color="primary"
          sx={{
            backgroundColor: "white",
            position: "absolute",
            right: "5px",
            bottom: "5px",
          }}
          onClick={() => setDrawerState(true)}
        >
          <EditRounded />
        </IconButton>
        <Card
          sx={{
            backgroundColor: "primary.main",
            color: "white.main",
            position: "absolute",
            left: "5px",
            top: "5px",
            px: 1,
            boxShadow: 0,
          }}
        >
          {pos}
        </Card>
      </Stack>

      <Drawer
        anchor={"right"}
        open={drawerState}
        onClose={() => setDrawerState(false)}
        sx={{ zIndex: 1300, p: 3 }}
        PaperProps={{ sx: { width: "30%" } }}
      >
        <EmpSearch
          onEmpSelected={(e) => {
            setDrawerState(false);
            updateEmployee(e);
          }}
        />
      </Drawer>
    </Card>
  );
};

const MilkCartsDetailsCardSecondary = ({ loading, doc }) => {
  return (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 3, mx: 3 }}
      >
        <Typography variant="h5" gutterBottom>
          Details
        </Typography>

        {loading === true && <CircularProgress />}

        <Button variant="outlined" color="error" startIcon={<EditIcon />}>
          Edit
        </Button>
      </Stack>

      {doc && (
        <Grid container columnSpacing={1} sx={{ pl: 2, pr: 2 }}>
          <Grid item xs={12} md={6} lg={6}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>Name:</b>
                </TableCell>
                <TableCell>{doc.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Phone:</b>
                </TableCell>
                <TableCell>{doc.phone}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Driver:</b>
                </TableCell>
                <TableCell>{doc?.default_employee?.name || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Active:</b>
                </TableCell>
                <TableCell>{doc?.active === true ? "Yes" : "No"}</TableCell>
              </TableRow>
            </TableBody>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};
