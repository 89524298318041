import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";
import {
  Card,
  Typography,
  Stack,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";

import { useConfirm } from "material-ui-confirm";
import { CopyAllRounded, QrCode2Rounded } from "@mui/icons-material";

export default function CredentialsModal({ manager }) {
  const [currentData, setCurrentData] = useState(null);

  const [loadingData, setLoadingData] = useState(false);
  const [isQr, setIsQr] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const confirm = useConfirm();

  const generateCreds = async () => {
    setLoadingData(true);
    let url = `/api/managers/mgract/manager/${manager._id}/credentials/otid`;
    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    console.log(ret);
    if (ret.success === true) {
      setCurrentData(ret.data);
    } else {
      alert(ret.message);
    }
    setLoadingData(false);
  };

  const callLogoutFn = async () => {
    setLoadingData(true);
    let url = `/api/managers/mgract/manager/${manager._id}/credentials/logout`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      alert("Manager Logged out successfully.");
    } else {
      alert("Manager logout failed!!!");
    }
    setLoadingData(false);
  };

  const handleLogout = async () => {
    confirm({
      description: "Please confirm logout!",
      confirmationButtonProps: { autoFocus: true },
    })
      .then(() => {
        callLogoutFn();
      })
      .catch(() => {});
  };

  return (
    <Card
      sx={{
        px: 4,
        py: 4,
        minWidth: 400,
        height: "fit-content",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">Credentials</Typography>
        {loadingData === true && <CircularProgress color="secondary" />}
      </Stack>

      {currentData && (
        <Stack direction="row" spacing={2} alignItems={"center"} py={3}>
          <Typography variant="subtitle1">{currentData}</Typography>
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(currentData);
            }}
          >
            <CopyAllRounded />
          </IconButton>
          <IconButton
            onClick={() => {
              setIsQr(true);
            }}
          >
            <QrCode2Rounded />
          </IconButton>
        </Stack>
      )}

      {currentData && isQr === true && (
        <Stack direction="row" spacing={2} alignItems={"center"} py={3}>
          <img
            src={`https://api.qrserver.com/v1/create-qr-code/?size=500x500&data=${currentData}`}
            alt="QR"
          />
        </Stack>
      )}

      <Stack
        direction="row"
        justifyContent={"space-between"}
        spacing={2}
        mt={2}
      >
        <Button
          variant="contained"
          onClick={() => {
            setIsQr(false);
            generateCreds();
          }}
        >
          LINK
        </Button>
        <Button variant="contained" onClick={handleLogout}>
          LOGOUT
        </Button>
      </Stack>
    </Card>
  );
}
