import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch, dataPost } from "src/utils/data-fetch";

import {
  Card,
  Typography,
  Grid,
  Stack,
  Modal,
  CircularProgress,
  IconButton,
  CardActionArea,
  Drawer,
} from "@mui/material";

import CustomerInfoCard from "./CustomerInfoCard";
import CustomerImageCard from "./CustomerImageCard";
import {
  AppRegistrationRounded,
  EditRounded,
  NotificationsRounded,
  SearchRounded,
  WhatsApp,
} from "@mui/icons-material";
import CreditMinusLimitModal from "../CreditMinusLimitModal";
import { SingleOnboarding } from "src/pages/crm/SingleOnboarding";
import MilkCartSearch from "src/pages/apartment/det/CartSearch";
import { useSnackbar } from "src/contexts/SnackbarContext";

export default function CustomerProfileSection({ customer }) {
  const [currentData, setCurrentData] = useState();
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const [creditMinusLimitModal, setCreditMinusLimitModal] = useState(false);
  const closeCreditMinusLimitModal = () => {
    setCreditMinusLimitModal(false);
    loadData();
  };

  const loadData = async () => {
    setLoadingData(true);
    let url = `/api/users/mgract/user/${customer._id}?extended=true`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCurrentData(ret.data);
      console.log(ret.data);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [onbCustomer, setOnbCustomer] = useState();
  const [onbDrawerOpen, setOnbDrawerOpen] = useState(false);

  const handleSingleOnboarding = (customer) => {
    setOnbCustomer(customer);
    setOnbDrawerOpen(true);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Stack direction="column" spacing={2}>
            <CustomerImageCard
              loadingData={loadingData}
              customer={currentData}
            />
            <Card sx={{ p: 2 }}>
              <Stack
                direction="row"
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant="h6">Credit Limit</Typography>

                <Stack direction="row" alignItems={"center"} spacing={2}>
                  <Typography variant="subtitle1">
                    ₹ {currentData?.wallet?.minus_limit}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setCreditMinusLimitModal(true);
                    }}
                  >
                    <EditRounded />
                  </IconButton>
                </Stack>
              </Stack>
            </Card>
            <Card>
              <CardActionArea
                sx={{ p: 2 }}
                onClick={() => {
                  if (currentData.onboarding_details) {
                    handleSingleOnboarding({
                      _id: currentData.onboarding_details,
                    });
                  }
                }}
              >
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="h6">View Onboarding</Typography>
                  <AppRegistrationRounded />
                </Stack>
              </CardActionArea>
            </Card>

            <Card>
              <CardActionArea
                sx={{ p: 2 }}
                onClick={() => {
                  window.open(
                    `https://wa.me/${currentData.phone?.substring(1)}`
                  );
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <Typography variant="h6">Open Whatsapp</Typography>
                  <WhatsApp />
                </Stack>
              </CardActionArea>
            </Card>
          </Stack>
        </Grid>

        <Grid item xs={12} md={8} lg={8}>
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2}>
              <CartCard
                entry={currentData?.cart}
                reload={() => loadData()}
                mainId={currentData?._id}
              />

              <AptCard
                entry={currentData?.address?.apartment}
                updateClicked={() => {}}
              />
            </Stack>

            <CustomerInfoCard
              loadingData={loadingData}
              customer={currentData}
              reload={() => loadData()}
            />
          </Stack>
        </Grid>
      </Grid>

      <Modal open={creditMinusLimitModal} onClose={closeCreditMinusLimitModal}>
        <CreditMinusLimitModal
          handleM1Close={closeCreditMinusLimitModal}
          customer={currentData}
        />
      </Modal>

      <SingleOnboarding
        selectEntry={onbCustomer}
        handleClose={() => {
          setOnbDrawerOpen(false);
          setOnbCustomer(null);
        }}
        drawerOpen={onbDrawerOpen}
      />
    </>
  );
}

const CartCard = ({ entry, mainId, reload }) => {
  const [cartDrawerState, setCartDrawerState] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const updateAssignedCart = async (cart) => {
    setLoadingData(true);

    let url = `/api/users/mgract/user/${mainId}/cart`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      { cart: cart._id },
      activeFranchise
    );

    if (ret.success === true) {
      reload();
      showSnackbar(ret.message || "Cart updated", "success");
    } else {
      showSnackbar(ret.message || "Failed to update cart", "error");
    }

    setLoadingData(false);
  };

  return (
    <Card sx={{ flexGrow: 1 }}>
      <Stack direction="row" alignItems="center" justifyContent="start">
        <Card
          sx={{ minWidth: "100px", maxWidth: "100px", height: "100px", mr: 1 }}
        >
          <img
            alt="header"
            src={"/static/ev.png  "}
            style={{ width: "100%", height: "100%", padding: "10px" }}
          />
        </Card>

        <Stack direction="column" flexGrow={1}>
          {(loadingData && <CircularProgress />) || (
            <>
              <Typography variant="h6" color={(entry && "black") || "red"}>
                {(entry && entry.name) || "No Cart"}
              </Typography>
              <Typography variant="body2" color="black">
                {(entry && entry.driver_name) || "-"}
              </Typography>
            </>
          )}
        </Stack>

        <IconButton
          color="primary"
          sx={{
            backgroundColor: "white",
            position: "absolute",
            right: "5px",
            bottom: "5px",
          }}
          onClick={() => setCartDrawerState(true)}
        >
          <EditRounded />
        </IconButton>
      </Stack>

      <Drawer
        anchor={"right"}
        open={cartDrawerState}
        onClose={() => setCartDrawerState(false)}
        sx={{ zIndex: 1300, p: 3 }}
      >
        <MilkCartSearch
          onCartSelected={(cart) => {
            setCartDrawerState(false);
            updateAssignedCart(cart);
          }}
        />
      </Drawer>
    </Card>
  );
};

const AptCard = ({ entry, updateClicked }) => {
  const searchLatLng = (lat, lng) => () => {
    window.open(`https://maps.google.com/?q=${lat},${lng}`, "_blank");
  };

  return (
    <Card sx={{ flexGrow: 1 }}>
      <Stack direction="row" alignItems="center" justifyContent="start">
        <Card
          sx={{ minWidth: "100px", maxWidth: "100px", height: "100px", mr: 1 }}
        >
          {entry &&
            entry?.location?.coordinates &&
            entry?.location?.coordinates[1] !== 0 &&
            entry?.location?.coordinates[0] !== 0 && (
              <IconButton
                color="black"
                sx={{
                  backgroundColor: "white",
                  position: "absolute",
                  bottom: "5px",
                  right: "5px",
                }}
                onClick={searchLatLng(
                  entry?.location?.coordinates[1],
                  entry?.location?.coordinates[0]
                )}
              >
                <SearchRounded />
              </IconButton>
            )}
          <img
            alt="header"
            src={(entry && entry.image_url) || "/static/noapt.svg"}
            style={{ width: "100%", height: "100%" }}
          />
        </Card>

        <Stack direction="column">
          <Typography variant="h6" color="black">
            {(entry && entry.name) || "No Apartment"}
          </Typography>
          <Typography variant="body2" color="black">
            {(entry && entry?.location?.detail?.substring(0, 60)) || "-"}
          </Typography>
        </Stack>

        <IconButton
          color="primary"
          sx={{
            backgroundColor: "white",
            position: "absolute",
            right: "5px",
            bottom: "5px",
          }}
          onClick={updateClicked}
        >
          <EditRounded />
        </IconButton>
      </Stack>
    </Card>
  );
};
