import React, { useState, useEffect, useRef } from "react";
import { Stack, Button, Box, Typography, IconButton } from "@mui/material";
import {
  PlayArrow,
  Pause,
  Speed,
  FastForward,
  FastRewind,
} from "@mui/icons-material";

export const PlayContent = ({ songUrl }) => {
  const audioRef = useRef(new Audio(songUrl));
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);

  const playPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const increaseSpeed = () => {
    const newPlaybackRate = Math.min(playbackRate + 0.5, 3); // Increase speed, max 3x
    setPlaybackRate(newPlaybackRate);
    audioRef.current.playbackRate = newPlaybackRate;
  };

  const decreaseSpeed = () => {
    const newPlaybackRate = Math.max(playbackRate - 0.5, 0.5); // Decrease speed, min 0.5x
    setPlaybackRate(newPlaybackRate);
    audioRef.current.playbackRate = newPlaybackRate;
  };

  const updateProgress = () => {
    setCurrentTime(audioRef.current.currentTime);
    setDuration(audioRef.current.duration);
  };

  useEffect(() => {
    const audio = audioRef.current;
    audio.addEventListener('timeupdate', updateProgress);
    audio.addEventListener('loadedmetadata', updateProgress);

    return () => {
      audio.removeEventListener('timeupdate', updateProgress);
      audio.removeEventListener('loadedmetadata', updateProgress);
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);

  const progressBarStyles = {
    width: `${(currentTime / duration) * 100}%`,
  };

  return (
    <Box
      sx={{
        width: "100%",
        padding: "20px",
        borderRadius: "5px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        bgcolor: "background.paper",
      }}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <Button
          onClick={playPause}
          variant="contained"
          color="primary"
          startIcon={isPlaying ? <Pause /> : <PlayArrow />}
        >
          {isPlaying ? "Pause" : "Play"}
        </Button>
        <IconButton onClick={increaseSpeed} color="secondary" size="small">
          <FastForward fontSize="small" />
        </IconButton>

        <IconButton onClick={decreaseSpeed} color="secondary" size="small">
          <FastRewind fontSize="small" />
        </IconButton>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
      >
        <Typography variant="body2" color="textSecondary">
          Speed: {playbackRate}x
        </Typography>
        <Box sx={{ flexGrow: 1, mx: 2 }}>
          <Box
            className="progress-bar"
            sx={{
              height: "10px",
              backgroundColor: "#e0e0e0",
              borderRadius: "5px",
              overflow: "hidden",
            }}
          >
            <Box
              className="progress"
              sx={{
                ...progressBarStyles,
                height: "100%",
                backgroundColor: "#76c7c0",
              }}
            />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};
