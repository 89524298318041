import {
  AssignmentRounded,
  CallRounded,
  CloseRounded,
  DoneRounded,
  EditRounded,
  SearchRounded,
} from "@mui/icons-material";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Card,
  CardActionArea,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Slide,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import { useState, forwardRef, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch, dataPatch, dataPost } from "src/utils/data-fetch";
import {
  getDateStringCustom,
  getDateStringSync,
} from "src/utils/date-time-helper";

import Label from "./Label";
import MilkCartSearch from "../apartment/det/CartSearch";
import ApartmentSearch from "../apartment/ApartmentSearch";
import { useConfirm } from "material-ui-confirm";
import { LoadingButton } from "@mui/lab";
import ManagerSearch from "../manager/ManagerSearch";
import CallDetails from "../calls/CallDetails";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const SingleOnboarding = ({ selectEntry, handleClose, drawerOpen }) => {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const { getAccessToken, activeFranchise } = useAuth();

  const loadData = async () => {
    if (selectEntry != null) {
      setLoadingData(true);

      await new Promise((resolve) => setTimeout(resolve, 1000));

      let url = `/api/salesflags/mgract/onboarddetails/${selectEntry._id}`;

      const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

      if (ret.success === true) {
        setCustomerData(ret.data);
      }

      setLoadingData(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [selectEntry]);

  const initiateNewCall = async () => {
    setLoadingData(true);

    let url = `/api/salesflags/mgract/onboarddetails/${selectEntry._id}/call?forced=true`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      {},
      activeFranchise
    );

    if (ret.success === true) {
      alert(ret.message || "Call initiated...");
      loadData();
    } else {
      alert(ret.message || "Failed to initiate call");
    }

    setLoadingData(false);
  };

  const assignYourselfAPI = async () => {
    setLoadingData(true);

    let url = `/api/salesflags/mgract/onboarddetails/${selectEntry._id}/assignmanager`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      loadData();
    } else {
      alert(ret.message || "Failed to assign!");
    }

    setLoadingData(false);
  };

  const [cartDrawerState, setCartDrawerState] = useState(false);

  const updateAssignedCart = async (cart) => {
    setLoadingData(true);

    let url = `/api/salesflags/mgract/onboarddetails/${selectEntry._id}/cart`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      { cart: cart._id },
      activeFranchise
    );

    if (ret.success === true) {
      loadData();
    } else {
      alert(ret.message || "Failed to update cart");
    }

    setLoadingData(false);
  };

  const [aptDrawerState, setAptDrawerState] = useState(false);

  const updateAssignedApartment = async (apartment) => {
    setLoadingData(true);

    let url = `/api/salesflags/mgract/onboarddetails/${selectEntry._id}/apartment`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      { apartment: apartment._id },
      activeFranchise
    );

    if (ret.success === true) {
      loadData();
    } else {
      alert(ret.message || "Failed to update apartment");
    }

    setLoadingData(false);
  };

  const [addressModalState, setAddressModalState] = useState(false);

  const updateUserAddress = async (dt) => {
    setLoadingData(true);

    let url = `/api/salesflags/mgract/onboarddetails/${selectEntry._id}/address`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      dt,
      activeFranchise
    );

    if (ret.success === true) {
      loadData();
    } else {
      alert(ret.message || "Failed to update address");
    }

    setLoadingData(false);
  };

  const [appDownloadModal, setAppDownloadModal] = useState(false);

  const updateAppDownload = async (status) => {
    setLoadingData(true);

    let url = `/api/salesflags/mgract/onboarddetails/${selectEntry._id}/appdownload`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      { status: status },
      activeFranchise
    );

    if (ret.success === true) {
      loadData();
    } else {
      alert(ret.message || "Failed to update app download status");
    }

    setLoadingData(false);
  };

  const [subscriptionModal, setSubscriptionModal] = useState(false);

  const updateSubscriptionData = async (status) => {
    setLoadingData(true);

    let url = `/api/salesflags/mgract/onboarddetails/${selectEntry._id}/subscription`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      { status: status },
      activeFranchise
    );

    if (ret.success === true) {
      loadData();
    } else {
      alert(ret.message || "Failed to update subscription status");
    }

    setLoadingData(false);
  };

  const [freeSampleModal, setFreeSampleModal] = useState(false);

  const [userNameModal, setUserNameModal] = useState(false);

  const updateUserName = async (dt) => {
    setLoadingData(true);

    let url = `/api/salesflags/mgract/onboarddetails/${selectEntry._id}/userdetails`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      dt,
      activeFranchise
    );

    if (ret.success === true) {
      loadData();
    } else {
      alert(ret.message || "Failed to update name");
    }

    setLoadingData(false);
  };

  const [userPlanModal, setUserPlanModal] = useState(false);

  const [dbNotesModalState, setDbNotesModalState] = useState(false);

  const updateDBNotesAPI = async (dt) => {
    setLoadingData(true);

    let url = `/api/salesflags/mgract/onboarddetails/${selectEntry._id}/notes`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      dt,
      activeFranchise
    );

    if (ret.success === true) {
      loadData();
    } else {
      alert(ret.message || "Failed to update name");
    }

    setLoadingData(false);
  };

  const [assignSomeoneDrawer, setAssignSomeoneDrawer] = useState(false);

  const assignSomeoneAPI = async (manager) => {
    setLoadingData(true);

    let url = `/api/salesflags/mgract/onboarddetails/${selectEntry._id}/assignmanager`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      { manager: manager._id },
      activeFranchise
    );

    if (ret.success === true) {
      loadData();
    } else {
      alert(ret.message || "Failed to assign");
      setLoadingData(false);
    }
  };

  const [flagStatusUpdateModal, setFlagStatusUpdateModal] = useState(false);

  const [callDetailsModalState, setCallDetailsModalState] = useState(false);
  const [selectedCallDetails, setSelectedCallDetails] = useState(null);

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      scroll="body"
      open={drawerOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            Customer Details
          </Typography>
          <Button variant="contained" color="error" onClick={handleClose}>
            <CloseRounded />
          </Button>
        </Toolbar>
      </AppBar>

      <Container sx={{ p: 2, minHeight: "800px" }}>
        {(loadingData === true && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "60vh",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        )) || (
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <OnbCard
                    entry={customerData?.flag}
                    onEdit={() => setFlagStatusUpdateModal(true)}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <CartCard
                    entry={customerData?.flag?.cart}
                    onEdit={() => setCartDrawerState(true)}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <AppDownloadCard
                    entry={customerData?.flag?.onb_details}
                    updateClicked={() => setAppDownloadModal(true)}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <FreeSampleCard
                    entry={customerData?.flag?.onb_details}
                    updateClicked={() => setFreeSampleModal(true)}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <SubscriptionCard
                    entry={customerData?.flag?.onb_details}
                    updateClicked={() => setSubscriptionModal(true)}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <PlanCard
                    entry={customerData?.user?.plan}
                    updateClicked={() => setUserPlanModal(true)}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <UserDetailsCard
                    entry={customerData?.user}
                    onEdit={() => setUserNameModal(true)}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <UserWalletCard entry={customerData?.user} />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <AddressCard
                    entry={customerData?.user?.address}
                    onEdit={() => setAddressModalState(true)}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <AptCard
                    entry={customerData?.user?.address?.apartment}
                    updateClicked={() => setAptDrawerState(true)}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <MilkHistoryCard entry={customerData?.milk} />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <TxnsCard entry={customerData?.txns} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <CardAssignYourself cardClick={assignYourselfAPI} />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <CardAssignSomeone
                    cardClick={() => setAssignSomeoneDrawer(true)}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <CardNewCall cardClick={initiateNewCall} />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <CallHistoryCard
                    entry={customerData?.replies}
                    onCallSelect={(call) => {
                      setCallDetailsModalState(true);
                      setSelectedCallDetails(call);
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <DBNotesCard
                    entry={customerData?.user?.db_notes}
                    onEdit={() => setDbNotesModalState(true)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Drawer
          anchor={"right"}
          open={cartDrawerState}
          onClose={() => setCartDrawerState(false)}
          sx={{ zIndex: 1300, p: 3 }}
        >
          <MilkCartSearch
            onCartSelected={(cart) => {
              setCartDrawerState(false);
              updateAssignedCart(cart);
            }}
          />
        </Drawer>

        <Drawer
          anchor={"right"}
          open={aptDrawerState}
          onClose={() => setAptDrawerState(false)}
          sx={{ zIndex: 1300, p: 3 }}
        >
          <ApartmentSearch
            onAptSelected={(apt) => {
              setAptDrawerState(false);
              updateAssignedApartment(apt);
            }}
          />
        </Drawer>

        <Modal
          open={appDownloadModal}
          onClose={() => setAppDownloadModal(false)}
        >
          <CardUpdateAppDownload
            current={customerData?.flag?.onb_details?.app_download || false}
            onSave={(data) => {
              setAppDownloadModal(false);
              updateAppDownload(data);
            }}
          />
        </Modal>
        <Modal
          open={subscriptionModal}
          onClose={() => setSubscriptionModal(false)}
        >
          <CardUpdateSubscription
            current={customerData?.flag?.onb_details?.subscription || false}
            onSave={(data) => {
              setSubscriptionModal(false);
              updateSubscriptionData(data);
            }}
          />
        </Modal>

        <Modal
          open={addressModalState}
          onClose={() => setAddressModalState(false)}
        >
          <CardUpdateAddress
            current={customerData?.user?.address}
            onSave={(data) => {
              setAddressModalState(false);
              updateUserAddress(data);
            }}
          />
        </Modal>

        <Modal open={freeSampleModal} onClose={() => setFreeSampleModal(false)}>
          <FreeSampleUpdateModal
            sf={selectEntry?._id}
            current={customerData?.flag?.onb_details?.free_sample}
            onSave={() => {
              loadData();
            }}
            onClose={() => {
              setFreeSampleModal(false);
            }}
          />
        </Modal>

        <Modal open={userNameModal} onClose={() => setUserNameModal(false)}>
          <CardUpdateName
            current={customerData?.user?.name}
            onSave={(data) => {
              setUserNameModal(false);
              updateUserName(data);
            }}
          />
        </Modal>

        <Modal open={userPlanModal} onClose={() => setUserPlanModal(false)}>
          <UserPlanUpdateModal
            sf={selectEntry?._id}
            current={customerData?.user?.plan}
            onSave={() => {
              loadData();
            }}
            onClose={() => {
              setUserPlanModal(false);
            }}
          />
        </Modal>

        <Modal
          open={dbNotesModalState}
          onClose={() => setDbNotesModalState(false)}
        >
          <CardUpdateDBNotes
            current={customerData?.user?.db_notes}
            onSave={(data) => {
              setDbNotesModalState(false);
              updateDBNotesAPI(data);
            }}
          />
        </Modal>
        <Modal
          open={flagStatusUpdateModal}
          onClose={() => setFlagStatusUpdateModal(false)}
        >
          <FlagStatusUpdateModal
            sf={selectEntry?._id}
            current={customerData?.flag}
            onSave={(data) => {
              loadData();
            }}
            onClose={() => {
              setFlagStatusUpdateModal(false);
            }}
          />
        </Modal>

        <Drawer
          anchor={"right"}
          open={assignSomeoneDrawer}
          onClose={() => setAssignSomeoneDrawer(false)}
          sx={{ zIndex: 1300, p: 3 }}
        >
          <ManagerSearch
            onAccSelected={(acc) => {
              setAssignSomeoneDrawer(false);
              assignSomeoneAPI(acc);
            }}
          />
        </Drawer>

        <Modal
          open={callDetailsModalState}
          onClose={() => setCallDetailsModalState(false)}
        >
          <CallDetails
            callDet={selectedCallDetails}
            handleClose={() => setCallDetailsModalState(false)}
          />
        </Modal>
      </Container>
    </Dialog>
  );
};

const OnbCard = ({ entry, onEdit }) => {
  return (
    <Card>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <b>Status</b>
            </TableCell>

            <TableCell>
              <Label
                variant="filled"
                color={
                  entry?.onb_status === "completed"
                    ? "success"
                    : entry?.onb_status === "later"
                    ? "warning"
                    : entry?.onb_status === "not-interested"
                    ? "info"
                    : entry?.onb_status === "duplicate"
                    ? "secondary"
                    : entry?.onb_status === "ongoing"
                    ? "primary"
                    : "error"
                }
              >
                <b>{entry?.onb_status?.toUpperCase() || "-"}</b>
              </Label>
              <IconButton
                color="primary"
                sx={{
                  backgroundColor: "white",
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                }}
                onClick={onEdit}
              >
                <EditRounded />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Plant</b>
            </TableCell>
            <TableCell>
              <Label
                variant="outlined"
                color={entry?.franchisee ? "success" : "error"}
              >
                {(entry?.franchisee && entry?.franchisee?.name) || "No Plant"}
              </Label>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Source</b>
            </TableCell>
            <TableCell>
              <Label
                variant="outlined"
                color={entry?.user_source === "cart" ? "success" : "error"}
              >
                {entry?.user_source?.toUpperCase() || "-"}
              </Label>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <b>Comments</b>
            </TableCell>
            <TableCell>{entry?.comments || "-"}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

const UserDetailsCard = ({ entry, onEdit }) => {
  return (
    <Card>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <b>Name</b>
            </TableCell>
            <TableCell>
              <b>{entry?.name || "-"}</b>
              <IconButton
                color="primary"
                sx={{
                  backgroundColor: "white",
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                }}
                onClick={onEdit}
              >
                <EditRounded />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Phone</b>
            </TableCell>
            <TableCell>{entry?.phone || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Last Online</b>
            </TableCell>
            <TableCell>
              {(entry &&
                entry?.last_active > 0 &&
                getDateStringCustom(entry?.last_active, "DD/MM/YY hh:mm A")) ||
                "--"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Created</b>
            </TableCell>
            <TableCell>
              {new Date(entry?.createdAt).toLocaleString() || "-"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

const UserWalletCard = ({ entry }) => {
  return (
    <Card>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <b>Balance</b>
            </TableCell>
            <TableCell>
              <b>₹ {entry?.wallet?.balance || "0"}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Limit</b>
            </TableCell>
            <TableCell>₹ {entry?.wallet.minus_limit || "0"}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

const AddressCard = ({ entry, onEdit }) => {
  const searchLatLng = (lat, lng) => () => {
    window.open(`https://maps.google.com/?q=${lat},${lng}`, "_blank");
  };

  return (
    <Card>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <b>Address</b>
            </TableCell>
            <TableCell>
              {entry?.detail?.substring(0, 100) || "-"}{" "}
              <IconButton
                color="primary"
                sx={{
                  backgroundColor: "white",
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                }}
                onClick={onEdit}
              >
                <EditRounded />
              </IconButton>
            </TableCell>
          </TableRow>
          {entry?.apartment && (
            <TableRow>
              <TableCell>
                <b>Apartment</b>
              </TableCell>
              <TableCell>
                {entry?.apartment?.name || "-"} ({entry?.tower || "-"})
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>
              <b>Flat</b>
            </TableCell>
            <TableCell>{entry?.flat || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>LatLng</b>
            </TableCell>
            <TableCell>
              {entry?.lat || "-"}, {entry?.lng || "-"}
              {entry?.lat !== 0 && entry?.lng !== 0 && (
                <IconButton
                  color="primary"
                  sx={{
                    backgroundColor: "white",
                    position: "absolute",
                    right: "10px",
                    bottom: "10px",
                  }}
                  onClick={searchLatLng(entry?.lat, entry?.lng)}
                >
                  <SearchRounded />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

const AptCard = ({ entry, updateClicked }) => {
  const searchLatLng = (lat, lng) => () => {
    window.open(`https://maps.google.com/?q=${lat},${lng}`, "_blank");
  };

  return (
    <Card>
      <Stack direction="row" alignItems="center" justifyContent="start">
        <Card
          sx={{ minWidth: "120px", maxWidth: "120px", height: "120px", mr: 1 }}
        >
          {entry &&
            entry?.location?.coordinates &&
            entry?.location?.coordinates[1] !== 0 &&
            entry?.location?.coordinates[0] !== 0 && (
              <IconButton
                color="black"
                sx={{
                  backgroundColor: "white",
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                }}
                onClick={searchLatLng(
                  entry?.location?.coordinates[1],
                  entry?.location?.coordinates[0]
                )}
              >
                <SearchRounded />
              </IconButton>
            )}
          <img
            alt="header"
            src={(entry && entry.image_url) || "/static/noapt.svg"}
            style={{ width: "100%", height: "100%" }}
          />
        </Card>

        <Stack direction="column">
          <Typography variant="h6" color="black">
            {(entry && entry.name) || "No Apartment"}
          </Typography>
          <Typography variant="body2" color="black">
            {(entry && entry?.location?.detail?.substring(0, 60)) || "-"}
          </Typography>
        </Stack>

        <IconButton
          color="primary"
          sx={{
            backgroundColor: "white",
            position: "absolute",
            right: "10px",
            bottom: "10px",
          }}
          onClick={updateClicked}
        >
          <EditRounded />
        </IconButton>
      </Stack>
    </Card>
  );
};

const CartCard = ({ entry, onEdit }) => {
  return (
    <Card>
      <Stack direction="row" alignItems="center" justifyContent="start">
        <Card sx={{ minWidth: "80px", height: "80px", mr: 1 }}>
          <img
            alt="header"
            src={"/static/ev.png  "}
            style={{ width: "100%", height: "100%", padding: "10px" }}
          />
        </Card>

        <Stack direction="column">
          <Typography variant="h6" color={(entry && "black") || "red"}>
            {(entry && entry.name) || "No Cart"}
          </Typography>
          <Typography variant="body2" color="black">
            {(entry && entry.driver_name) || "-"}
          </Typography>
        </Stack>

        <IconButton
          color="primary"
          sx={{
            backgroundColor: "white",
            position: "absolute",
            right: "10px",
          }}
          onClick={onEdit}
        >
          <EditRounded />
        </IconButton>
      </Stack>
    </Card>
  );
};

const AppDownloadCard = ({ entry, updateClicked }) => {
  return (
    <Card>
      <Stack direction="row" alignItems="center" justifyContent="start">
        <Card sx={{ minWidth: "80px", height: "80px", mr: 1 }}>
          <img
            alt="header"
            src={"/static/appd.svg"}
            style={{ width: "100%", height: "100%", padding: "10px" }}
          />
        </Card>

        <Stack direction="column">
          <Typography
            variant="h6"
            color={entry?.app_download === true ? "green" : "red"}
          >
            {entry?.app_download === true ? "Downloaded" : "Not Downloaded"}
          </Typography>
          <Typography variant="body2" color="black">
            {entry?.app_download_message === true
              ? "Message sent"
              : "Message not sent"}
          </Typography>
        </Stack>

        <IconButton
          color="primary"
          sx={{
            backgroundColor: "white",
            position: "absolute",
            right: "10px",
          }}
          onClick={updateClicked}
        >
          <EditRounded />
        </IconButton>
      </Stack>
    </Card>
  );
};

const FreeSampleCard = ({ entry, updateClicked }) => {
  return (
    <Card sx={{ p: 1 }}>
      <Stack direction="column" alignItems="start">
        <Stack direction="row" alignItems={"center"}>
          <img
            src={"/static/fsample.svg"}
            alt="header"
            style={{ width: "40px", height: "40px", marginRight: "10px" }}
          />
          <Typography variant="h6">Free Sample</Typography>

          <IconButton
            color="primary"
            sx={{
              backgroundColor: "white",
              position: "absolute",
              right: "10px",
            }}
            onClick={updateClicked}
          >
            <EditRounded />
          </IconButton>
        </Stack>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <b>Credited</b>
              </TableCell>
              <TableCell
                sx={{
                  color:
                    entry?.free_sample?.credited === true ? "green" : "red",
                }}
              >
                <b>{entry?.free_sample?.credited === true ? "Yes" : "No"}</b>
              </TableCell>
            </TableRow>
            {(entry?.free_sample?.credited === true ||
              entry?.free_sample?.amount > 0) && (
              <TableRow>
                <TableCell>
                  <b>Amount</b>
                </TableCell>
                <TableCell>₹ {entry?.free_sample?.amount || "-"}</TableCell>
              </TableRow>
            )}
            {entry?.free_sample?.credited === true && (
              <TableRow>
                <TableCell>
                  <b>Day 1</b>
                </TableCell>
                <TableCell
                  sx={{
                    color: entry?.free_sample?.day1 === true ? "green" : "red",
                  }}
                >
                  {entry?.free_sample?.day1 === true ? (
                    <DoneRounded />
                  ) : (
                    <CloseRounded />
                  )}
                </TableCell>
              </TableRow>
            )}
            {entry?.free_sample?.credited === true && (
              <TableRow>
                <TableCell>
                  <b>Day 2</b>
                </TableCell>
                <TableCell
                  sx={{
                    color: entry?.free_sample?.day2 === true ? "green" : "red",
                  }}
                >
                  {entry?.free_sample?.day2 === true ? (
                    <DoneRounded />
                  ) : (
                    <CloseRounded />
                  )}
                </TableCell>
              </TableRow>
            )}
            {entry?.free_sample?.credited === true && (
              <TableRow>
                <TableCell>
                  <b>Day 3</b>
                </TableCell>
                <TableCell
                  sx={{
                    color: entry?.free_sample?.day3 === true ? "green" : "red",
                  }}
                >
                  {entry?.free_sample?.day3 === true ? (
                    <DoneRounded />
                  ) : (
                    <CloseRounded />
                  )}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                <b>Verified</b>
              </TableCell>
              <TableCell
                sx={{
                  color:
                    entry?.free_sample?.verified === true ? "green" : "red",
                }}
              >
                <b>{entry?.free_sample?.verified === true ? "Yes" : "No"}</b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    </Card>
  );
};

const SubscriptionCard = ({ entry, updateClicked }) => {
  return (
    <Card>
      <Stack direction="row" alignItems="center" justifyContent="start">
        <Card sx={{ minWidth: "80px", height: "80px", mr: 1 }}>
          <img
            src={"/static/subscription.svg"}
            alt="header"
            style={{ width: "100%", height: "100%", padding: "10px" }}
          />
        </Card>

        <Stack direction="column">
          <Typography
            variant="h6"
            color={entry?.subscription === true ? "green" : "red"}
          >
            {entry?.subscription === true
              ? "Subscription Done"
              : "Not Subscribed"}
          </Typography>
          <Typography variant="body2" color="black">
            ₹ {entry?.subscription_amount || "0"}
          </Typography>
        </Stack>

        <IconButton
          color="primary"
          sx={{
            backgroundColor: "white",
            position: "absolute",
            right: "10px",
          }}
          onClick={updateClicked}
        >
          <EditRounded />
        </IconButton>
      </Stack>
    </Card>
  );
};

const PlanCard = ({ entry, updateClicked }) => {
  return (
    <Card>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{ color: entry?.status === true ? "green" : "red" }}>
              <b>Subscription</b>
            </TableCell>
            <TableCell sx={{ color: entry?.status === true ? "green" : "red" }}>
              <b>{entry?.status === true ? "ON" : "OFF"}</b>

              <IconButton
                color="black"
                sx={{
                  backgroundColor: "white",
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                }}
                onClick={updateClicked}
              >
                <EditRounded />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Milk Qty</b>
            </TableCell>
            <TableCell>{entry?.milk || "0.0"} Kg</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Slot</b>
            </TableCell>
            <TableCell>
              {entry?.slot === 1
                ? "5AM - 7AM"
                : entry?.slot === 2
                ? "7AM - 9AM"
                : entry?.slot === 3
                ? "9AM - 11AM"
                : entry?.slot === 4
                ? "5PM - 7PM"
                : entry?.slot === 5
                ? "7PM - 9PM"
                : "ANYTIME"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Days</b>
            </TableCell>
            <TableCell>{entry?.days?.join(", ") || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Message</b>
            </TableCell>
            <TableCell>{entry?.message || "-"}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

const MilkHistoryCard = ({ entry }) => {
  return (
    <Card>
      <Stack direction="row" mt={2} mx={2}>
        <Typography variant="h6">Milk History</Typography>
      </Stack>
      {(entry?.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Date</b>
              </TableCell>
              <TableCell>
                <b>Qty</b>
              </TableCell>
              <TableCell>
                <b>Amt</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entry.map((item) => {
              return (
                <TableRow
                  hover
                  style={{
                    backgroundColor: item.deleted === true ? "#ddd" : "white",
                  }}
                >
                  <TableCell>
                    <b>{getDateStringSync(item?.date)}</b>
                  </TableCell>
                  <TableCell>{item?.quantity} L</TableCell>
                  <TableCell>₹ {item?.amount?.toFixed(2)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )) || (
        <Typography variant="body2" p={2}>
          No data available!
        </Typography>
      )}
    </Card>
  );
};

const TxnsCard = ({ entry }) => {
  return (
    <Card>
      <Stack direction="row" mt={2} mx={2}>
        <Typography variant="h6">Transactions</Typography>
      </Stack>
      {(entry?.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Date</b>
              </TableCell>
              <TableCell>
                <b>Amt</b>
              </TableCell>
              <TableCell>
                <b>Cat</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entry.map((item) => {
              return (
                <TableRow
                  hover
                  style={{
                    backgroundColor:
                      item.status === 1
                        ? "white"
                        : item.status === 0
                        ? "cyan"
                        : "#ddd",
                  }}
                >
                  <TableCell>
                    <b>
                      {getDateStringSync(
                        parseInt(item?._id.substring(0, 8), 16) * 1000
                      )}
                    </b>
                  </TableCell>
                  <TableCell>₹ {item?.amount?.toFixed(2)}</TableCell>
                  <TableCell>
                    {item?.category === 0
                      ? "ORDER"
                      : item?.category === 1
                      ? "ONLINE"
                      : item?.category === 2
                      ? "MILK"
                      : item?.category === 3
                      ? "RECHARGE"
                      : item?.category === 4
                      ? "REFUND"
                      : item?.category === 5
                      ? "CASHBACK"
                      : item?.category === 6
                      ? "DEBIT"
                      : "UNKNOWN"}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )) || (
        <Typography variant="body2" p={2}>
          No data available!
        </Typography>
      )}
    </Card>
  );
};

const CallHistoryCard = ({ entry, onCallSelect }) => {
  console.log(entry);
  return (
    <Card>
      <Stack direction="row" mt={2} mx={2}>
        <Typography variant="h6">Call History</Typography>
      </Stack>
      {(entry?.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Date</b>
              </TableCell>
              <TableCell>
                <b>Agent</b>
              </TableCell>
              <TableCell>
                <b>Status</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entry.map((item) => {
              return (
                <Tooltip title={item?.comments || ""}>
                  <TableRow hover onClick={() => onCallSelect(item)}>
                    <TableCell>
                      <b>{getDateStringCustom(item?.date, "DD MMM hh:mm A")}</b>
                    </TableCell>
                    <TableCell>{item?.agent?.name || "-"}</TableCell>
                    <TableCell>{item?.call_details?.status || "-"}</TableCell>
                  </TableRow>
                </Tooltip>
              );
            })}
          </TableBody>
        </Table>
      )) || (
        <Typography variant="body2" p={2}>
          No data available!
        </Typography>
      )}
    </Card>
  );
};

const CardNewCall = ({ cardClick }) => {
  return (
    <Card
      sx={{
        backgroundColor: "primary.main",
        color: "white.main",
      }}
    >
      <CardActionArea sx={{ p: 2 }} onClick={cardClick}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">New Call</Typography>
          <CallRounded />
        </Stack>
      </CardActionArea>
    </Card>
  );
};

const CardAssignYourself = ({ cardClick }) => {
  return (
    <Card
      sx={{
        backgroundColor: "primary.main",
        color: "white.main",
      }}
    >
      <CardActionArea sx={{ p: 2 }} onClick={cardClick}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">Assign Yourself</Typography>
          <AssignmentRounded />
        </Stack>
      </CardActionArea>
    </Card>
  );
};

const CardAssignSomeone = ({ cardClick }) => {
  return (
    <Card
      sx={{
        backgroundColor: "primary.main",
        color: "white.main",
      }}
    >
      <CardActionArea sx={{ p: 2 }} onClick={cardClick}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">Assign Other Agent</Typography>
          <AssignmentRounded />
        </Stack>
      </CardActionArea>
    </Card>
  );
};

const CardUpdateAppDownload = ({ current, onSave }) => {
  return (
    <Card
      sx={{
        px: 4,
        py: 4,
        minWidth: 400,
        height: "fit-content",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Typography variant="h5" gutterBottom>
          Update Onboarding
        </Typography>
      </Stack>

      <Box>
        App Downloaded?
        <Switch checked={current} onChange={(e) => onSave(e.target.checked)} />
      </Box>
    </Card>
  );
};

const CardUpdateSubscription = ({ current, onSave }) => {
  return (
    <Card
      sx={{
        px: 4,
        py: 4,
        minWidth: 400,
        height: "fit-content",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Typography variant="h5" gutterBottom>
          Update Onboarding
        </Typography>
      </Stack>

      <Box>
        Subscription Done?
        <Switch checked={current} onChange={(e) => onSave(e.target.checked)} />
      </Box>
    </Card>
  );
};

const CardUpdateAddress = ({ current, onSave }) => {
  const [address, setAddress] = useState(current.detail);
  const [flat, setFlat] = useState(current.flat);
  const [tower, setTower] = useState(current.tower);

  const [error, setError] = useState(null);

  const handleSave = () => {
    let upd = {};

    if (!address || address.length < 10) {
      setError("Invalid Address");
      return;
    } else {
      upd["detail"] = address;
    }

    if (!flat || flat.length < 1) {
      setError("Invalid Flat");
      return;
    } else {
      upd["flat"] = flat;
    }

    if (current.apartment) {
      if (!tower || tower.length < 1) {
        setError("Invalid Tower");
        return;
      } else {
        upd["tower"] = tower;
      }
    }

    setError(null);

    onSave(upd);
  };

  return (
    <Card
      sx={{
        px: 4,
        py: 4,
        minWidth: 400,
        height: "fit-content",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Typography variant="h5" gutterBottom>
          Update User Address
        </Typography>
      </Stack>

      {current.apartment && (
        <AptCard entry={current.apartment} updateClicked={() => {}} />
      )}
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={12} lg={12}>
          <TextField
            fullWidth
            label="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <TextField
            fullWidth
            label="Flat"
            value={flat}
            onChange={(e) => setFlat(e.target.value)}
          />
        </Grid>

        {current.apartment && (
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              select
              fullWidth
              label="Tower"
              value={tower}
              onChange={(e) => setTower(e.target.value)}
            >
              {current?.apartment?.towers?.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}

        <Grid item xs={12} md={12} lg={12}>
          <Button
            fullWidth
            size="large"
            onClick={handleSave}
            variant="contained"
          >
            Save
          </Button>
        </Grid>

        {error && (
          <Grid item xs={12} md={12} lg={12}>
            <Alert severity="error" variant="filled">
              {error}
            </Alert>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

const FreeSampleUpdateModal = ({ sf, current, onSave, onClose }) => {
  const [error, setError] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const confirm = useConfirm();

  const handleSave = async (data) => {
    setLoadingData(true);

    let url = `/api/salesflags/mgract/onboarddetails/${sf}/freesample`;

    const ret = await dataPatch(
      url,
      await getAccessToken(),
      data,
      activeFranchise
    );

    if (ret.success === true) {
      onSave();
      if (data.verified === true) {
        onClose();
      }
    } else {
      setError(ret.message || "Save failed!");
    }

    setLoadingData(false);
  };

  const creditFreeSampleAmount = async () => {
    if (loadingData === true) return;

    setLoadingData(true);

    const url = `/api/salesflags/mgract/onboarddetails/${sf}/freesample`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      {},
      activeFranchise
    );

    if (ret.success === true) {
      alert(ret.message || "Credit successful!");
      onSave();
    } else {
      setError(ret.message || "Credit failed!");
    }

    setLoadingData(false);
  };

  return (
    <Card
      sx={{
        px: 4,
        py: 4,
        minWidth: 400,
        height: "fit-content",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Typography variant="h5" gutterBottom>
          Update Free Sample
        </Typography>
      </Stack>

      {loadingData === true && <CircularProgress color="secondary" />}

      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <b>Credited</b>
            </TableCell>
            <TableCell
              sx={{
                color: current?.credited === true ? "green" : "red",
              }}
            >
              <b>{current?.credited === true ? "Yes" : "No"}</b>
            </TableCell>
            {current?.credited !== true && loadingData === false && (
              <TableCell
                onClick={() => {
                  confirm({
                    description: "Please confirm!",
                  })
                    .then(() => creditFreeSampleAmount())
                    .catch(() => {});
                }}
              >
                <Button variant="contained">Credit Now</Button>
              </TableCell>
            )}
          </TableRow>
          {(current?.credited === true || current?.amount > 0) && (
            <TableRow>
              <TableCell>
                <b>Amount</b>
              </TableCell>
              <TableCell>₹ {current?.amount || "-"}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>
              <b>Verified</b>
            </TableCell>
            <TableCell
              sx={{
                color: current?.verified === true ? "green" : "red",
              }}
            >
              <b>{current?.verified === true ? "Yes" : "No"}</b>
            </TableCell>
            {current?.day1 === true &&
              current?.day2 === true &&
              current?.day3 === true &&
              current?.verified !== true && (
                <TableCell
                  onClick={() => {
                    confirm({
                      description: "Please confirm!",
                    })
                      .then(() => handleSave({ verified: true }))
                      .catch(() => {});
                  }}
                >
                  <Button variant="contained">Verify Now</Button>
                </TableCell>
              )}
          </TableRow>

          <TableRow>
            <TableCell>
              <b>Day 1</b>
            </TableCell>
            <TableCell>
              <Switch
                checked={current?.day1 || false}
                onChange={(e) =>
                  e.target.checked === true && handleSave({ day1: true })
                }
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Day 2</b>
            </TableCell>
            <TableCell>
              <Switch
                checked={current?.day2 || false}
                onChange={(e) =>
                  e.target.checked === true && handleSave({ day2: true })
                }
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Day 3</b>
            </TableCell>
            <TableCell>
              <Switch
                checked={current?.day3 || false}
                onChange={(e) =>
                  e.target.checked === true && handleSave({ day3: true })
                }
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Grid container spacing={2} mt={2}>
        {error && (
          <Grid item xs={12} md={12} lg={12}>
            <Alert severity="error" variant="filled">
              {error}
            </Alert>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

const CardUpdateName = ({ current, onSave }) => {
  const [name, setName] = useState(current);

  const [error, setError] = useState(null);

  const handleSave = () => {
    let upd = {};

    if (!name || name.length < 3) {
      setError("Invalid Name");
      return;
    } else {
      upd["name"] = name;
    }

    setError(null);

    onSave(upd);
  };

  return (
    <Card
      sx={{
        px: 4,
        py: 4,
        minWidth: 400,
        height: "fit-content",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Typography variant="h5" gutterBottom>
          Update Name
        </Typography>
      </Stack>

      <TextField
        fullWidth
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <Button
        fullWidth
        size="large"
        onClick={handleSave}
        variant="contained"
        sx={{ mt: 2 }}
      >
        Save
      </Button>

      {error && (
        <Grid item xs={12} md={12} lg={12}>
          <Alert severity="error" variant="filled">
            {error}
          </Alert>
        </Grid>
      )}
    </Card>
  );
};

const UserPlanUpdateModal = ({ sf, current, onSave, onClose }) => {
  const [error, setError] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const handleSave = async (data) => {
    setLoadingData(true);

    let url = `/api/salesflags/mgract/onboarddetails/${sf}/plan`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      data,
      activeFranchise
    );

    if (ret.success === true) {
      onSave();
    } else {
      setError(ret.message || "Save failed!");
    }

    setLoadingData(false);
  };

  const onSaveClick = (data) => {
    let upd = {
      ...current,
      ...data,
    };

    // if (!name || name.length < 3) {
    //   setError("Invalid Name");
    //   return;
    // } else {
    //   upd["name"] = name;
    // }

    setError(null);

    handleSave(upd);
  };

  return (
    <Card
      sx={{
        px: 4,
        py: 4,
        minWidth: 400,
        height: "fit-content",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Typography variant="h5" gutterBottom>
          Update Plan
        </Typography>
      </Stack>

      {loadingData === true && <CircularProgress color="secondary" />}

      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <b>Status</b>
            </TableCell>
            <TableCell>
              <Switch
                checked={current?.status || false}
                onChange={(e) => onSaveClick({ status: e.target.checked })}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <b>Milk</b>
            </TableCell>
            <TableCell>
              <Select
                id="milk-select"
                value={current.milk}
                onChange={(e) => {
                  onSaveClick({ milk: e.target.value });
                }}
              >
                <MenuItem value={0.5}>0.5 Kg</MenuItem>
                <MenuItem value={1.0}>1.0 Kg</MenuItem>
                <MenuItem value={1.5}>1.5 Kg</MenuItem>
                <MenuItem value={2.0}>2.0 Kg</MenuItem>
                <MenuItem value={2.5}>2.5 Kg</MenuItem>
              </Select>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <b>Slot</b>
            </TableCell>
            <TableCell>
              <Select
                id="slot-select"
                value={current.slot}
                onChange={(e) => {
                  onSaveClick({ slot: e.target.value });
                }}
              >
                <MenuItem value={0}>ANYTIME</MenuItem>
                <MenuItem value={1}>5AM - 7AM</MenuItem>
                <MenuItem value={2}>7AM - 9AM</MenuItem>
                <MenuItem value={3}>9AM - 11AM</MenuItem>
                <MenuItem value={4}>5PM - 7PM</MenuItem>
                <MenuItem value={5}>7PM - 9PM</MenuItem>
              </Select>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <b>Days</b>
            </TableCell>
            <TableCell>
              <Select
                id="days-select"
                multiple
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250,
                    },
                  },
                }}
                onChange={(e) => {
                  onSaveClick({ days: e.target.value });
                }}
                value={current.days}
              >
                <MenuItem value={"MON"}>MON</MenuItem>
                <MenuItem value={"TUE"}>TUE</MenuItem>
                <MenuItem value={"WED"}>WED</MenuItem>
                <MenuItem value={"THU"}>THU</MenuItem>
                <MenuItem value={"FRI"}>FRI</MenuItem>
                <MenuItem value={"SAT"}>SAT</MenuItem>
                <MenuItem value={"SUN"}>SUN</MenuItem>
              </Select>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Grid container spacing={2} mt={2}>
        {error && (
          <Grid item xs={12} md={12} lg={12}>
            <Alert severity="error" variant="filled">
              {error}
            </Alert>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

const DBNotesCard = ({ entry, onEdit }) => {
  return (
    <Card>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <b>DB Notes</b>
            </TableCell>
            <TableCell>
              {entry || "-"}
              <IconButton
                color="primary"
                sx={{
                  backgroundColor: "white",
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                }}
                onClick={onEdit}
              >
                <EditRounded />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

const CardUpdateDBNotes = ({ current, onSave }) => {
  const [notes, setNotes] = useState(current);

  const [error, setError] = useState(null);

  const handleSave = () => {
    let upd = { notes: notes };
    setError(null);

    onSave(upd);
  };

  return (
    <Card
      sx={{
        px: 4,
        py: 4,
        minWidth: 400,
        height: "fit-content",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Typography variant="h5" gutterBottom>
          Update Delivery Boy Notes
        </Typography>
      </Stack>

      <TextField
        fullWidth
        multiline
        label="Notes"
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
      />

      <Button
        fullWidth
        size="large"
        onClick={handleSave}
        variant="contained"
        sx={{ mt: 2 }}
      >
        Save
      </Button>

      {error && (
        <Grid item xs={12} md={12} lg={12}>
          <Alert severity="error" variant="filled">
            {error}
          </Alert>
        </Grid>
      )}
    </Card>
  );
};

const FlagStatusUpdateModal = ({ sf, current, onSave, onClose }) => {
  const [error, setError] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const [comments, setComments] = useState(current.comments);
  const [status, setStatus] = useState(current.onb_status);
  const [concern, setConcern] = useState(current.concern);

  const { getAccessToken, activeFranchise } = useAuth();

  const handleSave = async (data) => {
    setLoadingData(true);

    let url = `/api/salesflags/mgract/onboarddetails/${sf}/status`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      data,
      activeFranchise
    );

    if (ret.success === true) {
      onSave();
      onClose();
    } else {
      setError(ret.message || "Save failed!");
    }

    setLoadingData(false);
  };

  const onSaveClick = () => {
    setError(null);

    let op = {
      status: status,
      comments: comments && comments.length > 0 ? comments : "-",
    };

    if (concern && concern.length > 0) {
      op["concern"] = concern;
    }

    handleSave(op);
  };

  return (
    <Card
      sx={{
        px: 4,
        py: 4,
        minWidth: 400,
        height: "fit-content",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Typography variant="h5" gutterBottom>
          Update Onboarding Status
        </Typography>
      </Stack>

      {loadingData === true && <CircularProgress color="secondary" />}

      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Select
            id="status-select"
            onChange={(e) => {
              setStatus(e.target.value);
            }}
            value={status}
            fullWidth
          >
            <MenuItem value={"hotlist"}>
              <Label variant="filled" color="error">
                Hotlist - probable conversion
              </Label>
            </MenuItem>
            <MenuItem value={"delivery"}>
              <Label variant="filled" color="warning">
                Delivery Lead - show in delivery app
              </Label>
            </MenuItem>
            <MenuItem value={"pending"}>
              <Label variant="filled" color="error">
                Pending
              </Label>
            </MenuItem>
            <MenuItem value={"ongoing"}>
              <Label variant="filled" color="primary">
                Ongoing - sample ongoing. need to move from here after
                completion
              </Label>
            </MenuItem>
            <MenuItem value={"later"}>
              <Label variant="filled" color="warning">
                Later - revisit after some time
              </Label>
            </MenuItem>
            <MenuItem value={"not-interested"}>
              <Label variant="filled" color="info">
                Not Interested
              </Label>
            </MenuItem>
            <MenuItem value={"duplicate"}>
              <Label variant="filled" color="secondary">
                Duplicate - already registered with other number
              </Label>
            </MenuItem>
            <MenuItem value={"completed"}>
              <Label variant="filled" color="success">
                Completed - subscription done
              </Label>
            </MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <TextField
            fullWidth
            label="Comments"
            multiline
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <TextField
            fullWidth
            label="Concern"
            value={concern}
            onChange={(e) => setConcern(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <LoadingButton
            variant="contained"
            loading={loadingData}
            onClick={onSaveClick}
            fullWidth
            size="large"
          >
            Save
          </LoadingButton>
        </Grid>

        {error && (
          <Grid item xs={12} md={12} lg={12}>
            <Alert severity="error" variant="filled">
              {error}
            </Alert>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};
