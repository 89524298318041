import React from "react";
import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  CircularProgress,
  Drawer,
  Stack,
} from "@mui/material";

import { getDateStringFullWithMonthSync } from "src/utils/date-time-helper";
import DispenseDetailsPage from "../../dispense/DispenseDetailsPage";

const TABLE_HEAD = [
  { id: "date", label: "Date", alignRight: false },
  { id: "driver_name", label: "Driver", alignRight: false },
  { id: "milktype", label: "Milk Type", alignRight: false },
  { id: "session", label: "Session", alignRight: false },
  { id: "milk", label: "Milk", alignRight: false },
  { id: "sale", label: "Sale", alignRight: false },
  { id: "cash", label: "Cash", alignRight: false },
  // { id: "online", label: "Online", alignRight: false },
  { id: "error", label: "Error", alignRight: false },
];

export const MilkCartsHisaab = ({ cart }) => {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [page, setPage] = useState(0);
  const [selectedDispense, setSelectedDispense] = useState(null);

  const { getAccessToken, activeFranchise } = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = async () => {
    let url = `/api/dispenses/mgract/query?cart=${cart._id}&skip=${
      page * 10
    }&limit=${10}&sort=date&dir=d`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
      console.log(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [page, activeFranchise]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card title="Milkcart Hisaab">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
          spacing={2}
        >
          {loadingData === true && <CircularProgress color="secondary" />}
        </Stack>
        {customerData.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        <b>{headCell.label}</b>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {customerData.map((item) => {
                    return (
                      <TableRow
                        hover
                        onClick={() => {
                          setSelectedDispense(item);
                        }}
                        style={{
                          backgroundColor:
                            item.deleted === true ? "#ddd" : "white",
                        }}
                      >
                        <TableCell>
                          <b>{getDateStringFullWithMonthSync(item?.date)}</b>
                        </TableCell>
                        <TableCell>
                          {item?.driver && item?.driver.name || "-"}
                        </TableCell>
                        <TableCell>
                          {(item?.milk_type === 1 && "BUFF") || "COW"}
                        </TableCell>
                        <TableCell>
                          {item?.session === 0
                            ? "MOR"
                            : item?.session === 1
                            ? "EVE"
                            : "M+E"}
                        </TableCell>
                        <TableCell>
                          {(item?.milk_sent - item?.milk_returned)?.toFixed(1)}{" "}
                          Lt.
                        </TableCell>
                        <TableCell>
                          <b>{item?.sale_quantity?.toFixed(1)} Lt.</b>
                        </TableCell>
                        <TableCell>₹ {item?.cash_in}</TableCell>
                        {/* <TableCell>₹ {item.online_money}</TableCell> */}
                        <TableCell
                          style={{
                            backgroundColor:
                              item.sale_error < 0
                                ? "lightsalmon"
                                : item.sale_error > 0
                                ? "aquamarine"
                                : "white",
                          }}
                        >
                          ₹ {item?.sale_error?.toFixed(1)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              component="div"
              count={-1}
              rowsPerPage={10}
              rowsPerPageOptions={[10]}
              page={page}
              onPageChange={handleChangePage}
            />
          </div>
        )}

        {customerData.length === 0 && loadingData === false && (
          <div>
            <Typography gutterBottom align="center" variant="subtitle1">
              Not found
            </Typography>
            <Typography variant="body2" align="center" sx={{ mb: 4 }}>
              No results here.
            </Typography>
          </div>
        )}
      </Container>

      <Drawer
        anchor={"right"}
        open={selectedDispense}
        onClose={() => setSelectedDispense(null)}
        sx={{ zIndex: 1300, p: 3 }}
      >
        <div style={{ padding: "20px" }}>
          <DispenseDetailsPage dispense={selectedDispense} />
        </div>
      </Drawer>
    </Card>
  );
};
