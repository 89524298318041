import { Button, Container } from "@mui/material";
import { Link } from "react-router-dom";
import Page from "src/components/Page";

export default function AdminMapSame() {
  return (
    <Page title="Map">
      <Container>
        <h1 style={{ marginBottom: "20px" }}>AdminMap</h1>

        <Link to="/map" target="_blank">
          <Button variant="contained" color="primary">
            Open Map in new tab
          </Button>
        </Link>
      </Container>
    </Page>
  );
}
