import { useState, useEffect } from "react";
import { useAuth } from "../../../contexts/AuthContext";

import { dataFetch } from "../../../utils/data-fetch";

import {
  Card,
  Typography,
  Stack,
  CircularProgress,
  CardActionArea,
} from "@mui/material";

export default function EmployeeBalanceCard({ employee, counter }) {
  const { getAccessToken, activeFranchise } = useAuth();

  const [balanceData, setBalanceData] = useState();
  const [loadingBalance, setLoadingBalance] = useState(false);

  const loadBalanceData = async () => {
    if (!employee || employee.length === 0) return;
    setLoadingBalance(true);
    let url = `/api/employees/mgract/employee/${employee}/balance`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setBalanceData(ret.data);
    }
    setLoadingBalance(false);
  };

  useEffect(() => {
    loadBalanceData();
  }, [counter, employee]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card sx={{ backgroundColor: "primary.main" }}>
      <CardActionArea>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2, mx: 3 }}
          color={"white.main"}
        >
          <Typography variant="h6" marginRight={1}>
            Balance
          </Typography>
          {(loadingBalance === true && (
            <CircularProgress color="success" />
          )) || <>₹ {balanceData?.toFixed(2) || "-"}</>}
        </Stack>
      </CardActionArea>
    </Card>
  );
}
