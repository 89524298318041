import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";

import {
  Card,
  Container,
  Typography,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
  Stack,
} from "@mui/material";

export default function ReferralCard({ customer }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const loadData = async () => {
    setLoadingData(true);

    let url = `/api/users/mgract/user/${customer._id}/referral`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, [customer]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h5" gutterBottom>
            Your Referral
          </Typography>

          {loadingData === true && <CircularProgress color="secondary" />}
        </Stack>

        <TableContainer sx={{ minHeight: 100 }}>
          <TableBody>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>{customerData?.name || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Referred By</TableCell>
              <TableCell>{customerData?.referral || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>My Referral Code</TableCell>
              <TableCell>{customerData?.my_referral || "-"}</TableCell>
            </TableRow>
          </TableBody>
        </TableContainer>
      </Container>
    </Card>
  );
}
