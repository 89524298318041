import {
  Card,
  Typography,
  Box,
  Grid,
  Stack,
  CircularProgress,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

import {
  getDateStringFullWithMonthSync,
  getDateStringFullWithTime,
  getDateStringFullWithTimeSync,
} from "src/utils/date-time-helper";

export default function ManagerInfoCard({ manager, loadingData, reload }) {
  return (
    <>
      <Card>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: 3, mx: 3 }}
        >
          <Typography variant="h5" gutterBottom>
            Details
          </Typography>

          {loadingData === true && <CircularProgress />}
        </Stack>

        {manager && (
          <Grid container columnSpacing={1} sx={{ pl: 2, pr: 2 }}>
            <Grid item xs={12} md={6} lg={6}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Name:</b>
                  </TableCell>
                  <TableCell>{manager?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Phone:</b>
                  </TableCell>
                  <TableCell>{manager?.phone || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Open Balance:</b>
                  </TableCell>
                  <TableCell> ₹{manager?.open_balance || "0"}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>Balance On:</b>
                  </TableCell>
                  <TableCell>
                    <TableCell>
                      {getDateStringFullWithTimeSync(manager?.balance_on)}
                    </TableCell>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Joining:</b>
                  </TableCell>
                  <TableCell>
                    {getDateStringFullWithMonthSync(
                      parseInt(manager?._id.substring(0, 8), 16) * 1000
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>Balance:</b>
                  </TableCell>
                  <TableCell>₹ {manager?.open_balance || "0"}</TableCell>
                </TableRow>
              </TableBody>
            </Grid>
          </Grid>
        )}

        <Stack direction="row">
          <Box sx={{ flexGrow: 1 }} />
        </Stack>
      </Card>
    </>
  );
}
