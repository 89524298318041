import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

// material
import { styled } from "@mui/material/styles";
import { Stack, Box, Link, Drawer, Typography, Avatar } from "@mui/material";

// components
import Logo from "../../components/Logo";
import NavSection from "../../components/NavSection";

import { MHidden } from "../../components/@material-extend";

import { getConfig } from "./SidebarConfig";
import { useAuth } from "../../contexts/AuthContext";

// ----------------------------------------------------------------------

const photoUrl = "/static/avatar_default.jpg";
const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme, drawerHidden }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: (drawerHidden === false && DRAWER_WIDTH) || 0,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  drawerHidden,
}) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const { currentManager, activeFranchise, currentAccess } = useAuth();

  const renderContent = (
    <Box>
      <Box sx={{ px: 2.5, py: 2 }}>
        <Stack direction="row" alignItems="center" color="black">
          <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
            <Logo />
          </Box>
          <Box mt={2.5} ml={1}>
            {(currentAccess && currentAccess.fr && currentAccess.fr.name) ||
              "Dashboard"}
          </Box>
        </Stack>
      </Box>

      {currentManager && (
        <Box sx={{ mb: 2.5, mx: 2.5 }}>
          <Link underline="none" component={RouterLink} to="#">
            <AccountStyle>
              <Avatar src={photoUrl} alt="photoURL" />
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                  {currentManager.name}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  ₹ {currentManager.balance}
                </Typography>
              </Box>
            </AccountStyle>
          </Link>
        </Box>
      )}
    </Box>
  );

  const FeatureList = (
    <NavSection navConfig={getConfig(currentManager, currentAccess)} />
  );

  return (
    <RootStyle drawerHidden={drawerHidden}>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
          {FeatureList}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        {(drawerHidden === true && (
          <Drawer
            open={isOpenSidebar}
            onClose={onCloseSidebar}
            PaperProps={{
              sx: { width: DRAWER_WIDTH },
            }}
          >
            {renderContent}
            {FeatureList}
          </Drawer>
        )) || (
          <Drawer
            open
            variant="persistent"
            PaperProps={{
              sx: {
                width: DRAWER_WIDTH,
                bgcolor: "background.default",
              },
            }}
          >
            {renderContent}
            {FeatureList}
          </Drawer>
        )}
      </MHidden>

      {/* <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}

          {FeatureList}
        </Drawer>
      </MHidden> */}
    </RootStyle>
  );
}
