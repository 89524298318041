import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";

// material
import { Stack, TextField, Alert, Modal } from "@mui/material";

import { LoadingButton } from "@mui/lab";

// ----------------------------------------------------------------------
import { dataFetch } from "src/utils/data-fetch";

import { useAuth } from "src/contexts/AuthContext";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import OtopValidationModal from "./otpValidationModal";
import app from "src/firebase";

export default function PhoneLoginForm() {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [openOTPModal, setOpenOTPModal] = useState(false);
  const { getAccessToken } = useAuth();

  const handleClose = () => {
    setOpenOTPModal(false);
  };

  const LoginSchema = Yup.object().shape({
    phone: Yup.string().required("Phone is required").min(10).max(10),
  });

  const [user, setUser] = useState(null);

  const auth = getAuth(app);
  const sendOTP = async (phone) => {
    console.log(phone);

    try {
      const recaptcha = new RecaptchaVerifier(
        "recaptcha",
        {
          size: "invisible", 
          callback: (response) => {
            console.log("prepared phone auth process");
          },
        },
        auth 
      );

      recaptcha.render();

      const confirmation = await signInWithPhoneNumber(auth, phone, recaptcha);
      setUser(confirmation);
    } catch (error) {
      console.log(error);
      setError(error.code);
    }
  };

  const handleOTPSubmit = async (otp) => {
    try {
      const data = await user.confirm(otp);
      const url = `/api/extrasales/b2b/validate-customer`;

      const ret = await dataFetch(url, await getAccessToken());

      if (ret.data) {
        navigate("/dashboard", { replace: true });
        handleClose();
      } else {
        setError(ret.message);
      }
    } catch (error) {
      console.log(error);
      setError(error.code);
    }
  };

  const verifyPhone = async (phone) => {
    let url = `/api/extrasales/b2bact/phonesearch/${phone}`;

    const ret = await dataFetch(url);
    console.log(ret);
    if (ret.success === true) {
      await sendOTP(phone);
      setOpenOTPModal(true);
    } else {
      setError(ret.message);
      return null;
    }
  };

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      try {
        setError(null);

        await verifyPhone(values.phone);
      } catch (error) {
        console.log(error);
        setError(error.code);
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              type="number"
              label="Phone Number"
              {...getFieldProps("phone")}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ mt: 3 }}
          >
            Login
          </LoadingButton>
        </Form>
      </FormikProvider>

      <OtopValidationModal
        open={openOTPModal}
        onClose={handleClose}
        handleOtpSubmit={handleOTPSubmit}
      />
      {error && (
        <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </div>
  );
}
