import { useState, useEffect } from "react";
import { useAuth } from "../../../../contexts/AuthContext";

import { styled } from "@mui/material/styles";
import { dataFetch, dataPatch, dataPost } from "../../../../utils/data-fetch";
import Label from "../Label";

import {
  Card,
  Typography,
  Box,
  Grid,
  Stack,
  CircularProgress,
  Button,
  Modal,
} from "@mui/material";

import ApproveAccPage from "./ApproveAccPage";
import UploadDocImage from "./UploadDocImage";

export default function AllDocumentSection({ employee }) {
  const [currentData, setCurrentData] = useState({});
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const loadData = async () => {
    setLoadingData(true);

    let url = `/api/employees/mgract/v2/employee/${employee.employee}/documents`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCurrentData(ret.data);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const approveNow = async (category) => {
    setLoadingData(true);

    let url = `/api/employees/mgract/v2/employee/${employee.employee}/documents`;

    const ret = await dataPatch(
      url,
      await getAccessToken(),
      { category: category },
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      await loadData();
    } else {
      console.log(ret.message);
    }
    setLoadingData(false);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const closeBankApproveModal = () => {
    setModalOpen(false);
    loadData();
  };

  const openBankApproveModal = async () => {
    setModalOpen(true);
  };

  const rejectNow = async (category) => {
    setLoadingData(true);

    let url = `/api/employees/mgract/v2/employee/${employee.employee}/rejectdoc`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      { category: category },
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      await loadData();
    } else {
      console.log(ret.message);
    }
    setLoadingData(false);
  };

  return (
    <div>
      {loadingData === true ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={4}>
            <OneDocSection
              doc={currentData?.aadhaar1}
              category={"aadhaar1"}
              approveNow={approveNow}
              rejectNow={rejectNow}
              title={"Aadhaar Front"}
              reload={loadData}
              empId={employee.employee}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <OneDocSection
              doc={currentData?.aadhaar2}
              category={"aadhaar2"}
              approveNow={approveNow}
              rejectNow={rejectNow}
              title={"Aadhaar Back"}
              reload={loadData}
              empId={employee.employee}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <OneDocSection
              doc={currentData?.pan}
              category={"pan"}
              approveNow={approveNow}
              rejectNow={rejectNow}
              title={"PAN Card"}
              reload={loadData}
              empId={employee.employee}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <OneDocSection
              doc={currentData?.passbook}
              category={"passbook"}
              rejectNow={rejectNow}
              approveNow={openBankApproveModal}
              title={"Bank Details"}
              reload={loadData}
              empId={employee.employee}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <OneDocSection
              doc={currentData?.picture}
              category={"picture"}
              approveNow={approveNow}
              rejectNow={rejectNow}
              title={"Picture"}
              reload={loadData}
              empId={employee.employee}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <OneDocSection
              doc={currentData?.electricity}
              category={"electricity"}
              approveNow={approveNow}
              rejectNow={rejectNow}
              title={"Electricity Bill"}
              reload={loadData}
              empId={employee.employee}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <OneDocSection
              doc={currentData?.guardian}
              category={"guardian"}
              approveNow={approveNow}
              rejectNow={rejectNow}
              title={"Parent/Guardian Id"}
              reload={loadData}
              empId={employee.employee}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <OneDocSection
              doc={currentData?.police_ver}
              category={"police_ver"}
              approveNow={approveNow}
              rejectNow={rejectNow}
              title={"Police Verification"}
              reload={loadData}
              empId={employee.employee}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <OneDocSection
              doc={currentData?.dl}
              category={"dl"}
              approveNow={approveNow}
              rejectNow={rejectNow}
              title={"Driving License"}
              reload={loadData}
              empId={employee.employee}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <OneDocSection
              doc={currentData?.last_offer}
              category={"last_offer"}
              approveNow={approveNow}
              rejectNow={rejectNow}
              title={"Last Offer"}
              empId={employee.employee}
              reload={loadData}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <OneDocSection
              doc={currentData?.last_salary_slip}
              category={"last_salary_slip"}
              approveNow={approveNow}
              rejectNow={rejectNow}
              title={"Last Salary Slip"}
              reload={loadData}
              empId={employee.employee}
            />
          </Grid>
        </Grid>
      )}

      {currentData && currentData.passbook && currentData.passbook.url && (
        <Modal open={modalOpen} onClose={closeBankApproveModal}>
          <ApproveAccPage
            handleM1Close={closeBankApproveModal}
            employee={employee}
            url={currentData.passbook?.url}
            acc={currentData.passbook?.data}
          />
        </Modal>
      )}
    </div>
  );
}

const OneDocSection = ({
  doc,
  category,
  approveNow,
  rejectNow,
  title,
  reload,
  empId,
}) => {
  const ProductImgStyle = styled("img")({
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "fit-content",
    position: "absolute",
  });

  const [uploadModalState, setUploadModalState] = useState(false);

  // ----------------------------------------------------------------------

  return (
    <Card>
      <Box sx={{ pt: "100%", position: "relative" }}>
        <Label
          variant="filled"
          color={"primary"}
          sx={{
            zIndex: 9,
            top: 16,
            left: 16,
            position: "absolute",
            textTransform: "uppercase",
          }}
        >
          {title}
        </Label>
        {doc && doc?.status && (
          <Label
            variant="filled"
            color={(doc?.status === "pending" && "error") || "info"}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: "absolute",
              textTransform: "uppercase",
            }}
          >
            {doc?.status}
          </Label>
        )}

        {doc?.url && <ProductImgStyle alt={"Image"} src={doc?.url} />}
        {!doc?.url && (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems={"center"}
            spacing={2}
            sx={{
              top: "100px",
              width: "100%",
              height: "100%",
              objectFit: "fit-content",
              position: "absolute",
            }}
          >
            <Typography variant="subtitle1" textAlign={"center"}>
              No data here!
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setUploadModalState(true)}
              sx={{ width: "50%" }}
            >
              Upload
            </Button>
          </Stack>
        )}
      </Box>

      <Stack spacing={2} sx={{ p: 3 }} direction={"column"}>
        {doc?.data && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body1">{doc?.data || "-"}</Typography>
          </Stack>
        )}
        {doc && doc.status && doc?.status !== "approved" && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              color="error"
              variant="outlined"
              onClick={() => rejectNow(category)}
            >
              Reject
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => approveNow(category)}
            >
              Approve
            </Button>
          </Stack>
        )}
      </Stack>

      <Modal open={uploadModalState} onClose={() => setUploadModalState(false)}>
        <UploadDocImage
          category={category}
          empId={empId}
          handleM1Close={() => {
            setUploadModalState(false);
            reload();
          }}
        />
      </Modal>
    </Card>
  );
};