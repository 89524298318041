import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";

export const SaleSummary = () => {
  const [data, setData] = useState(null);
  const { getAccessToken, activeFranchise } = useAuth();

  const loadData = async () => {
    const url = `/api/extrasales/mgract/b2b/summary`;
    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    console.log(ret);

    if (ret.success === true) {
      setData(ret.data);
    } else {
      console.log(ret.message);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Card sx={{ p: 2, elevation: 3 }}>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          mt={2}
          spacing={2}
        >
          <Typography variant="h4" color="primary">
            Today's Bulk Sale Summary
          </Typography>
        </Stack>
        <Box sx={{ my: 2 }}>
          {data ? (
            <Stack direction="column" spacing={2}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body1" noWrap>
                  <b>Outstanding Balance</b>
                </Typography>
                <Typography variant="subtitle2" color="red">
                  ₹ {data.outstandingBalance} /-
                </Typography>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body1" noWrap>
                  <b>Total Orders</b>
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  {data.totalOrders}
                </Typography>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body1" noWrap>
                  <b>Total Value</b>
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  ₹ {data.totalValue}/-
                </Typography>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body1" noWrap>
                  <b>Total Payments</b>
                </Typography>
                <Typography variant="subtitle2" color="green">
                  ₹ {data.totalPayments}/-
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100px"
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Container>
    </Card>
  );
};
