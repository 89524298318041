import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import {
  Card,
  Stack,
  Typography,
  Alert,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import { dataPost } from "src/utils/data-fetch";

export default function ApproveRejectExpense({ handleM1Close, txnDet }) {
  const [error, setError] = useState(null);

  const [loadingData, setLoadingData] = useState(false);
  const [comments, setComments] = useState("");

  const { getAccessToken, activeFranchise } = useAuth();

  const saveNow = async (reject) => {
    setError(null);
    setLoadingData(true);

    let op = {
      reject: reject,
    };

    if (comments && comments.length > 0) {
      op.comments = comments;
    }

    const ret = await dataPost(
      `/api/expenses/expenses/mgract/expense/${txnDet._id}/approve`,
      await getAccessToken(),
      op,
      activeFranchise
    );

    if (ret.success === true) {
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }

    setLoadingData(false);
  };

  return (
    <Card
      sx={{
        px: 4,
        py: 4,
        minWidth: "500px",
        height: "fit-content",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Typography variant="h5" gutterBottom>
          Approve Expense (₹ {txnDet?.amount})
        </Typography>

        {loadingData && <CircularProgress color="secondary" />}
      </Stack>

      

      <TextField
        fullWidth
        label="Comments (Optional)"
        value={comments}
        onChange={(e) => setComments(e.target.value)}
        sx={{ mb: 3 }}
      />

      {loadingData === false && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            color="error"
            variant="outlined"
            onClick={() => saveNow(true)}
          >
            Reject
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => saveNow(false)}
          >
            Approve
          </Button>
        </Stack>
      )}

      {error && (
        <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Card>
  );
}
