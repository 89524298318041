import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import {
  Card,
  Alert,
  Stack,
  Grid,
  Select,
  Box,
  MenuItem,
  Typography,
  CircularProgress,
  TableRow,
  TableCell,
  TableBody,
  InputAdornment,
} from "@mui/material";

import { dataFetch, dataPost } from "src/utils/data-fetch";
import {
  getDateStringFullWithTimeSync,
  getTimeStringFullSync,
} from "src/utils/date-time-helper";

export default function AdminVehiclesDetailsCard({ handleM1Close, vehicle }) {
  const [fr, setFr] = useState("");

  const [error, setError] = useState();

  const [currentData, setCurrentData] = useState();
  const [loadingData, setLoadingData] = useState();
  const [loadingData1, setLoadingData1] = useState();
  const { getAccessToken, allFranchisees, activeFranchise } = useAuth();

  const load = async (values) => {
    setLoadingData(true);

    const ret = await dataFetch(
      `/api/pms/vehicles/mgract/vehicle/${vehicle}`,
      await getAccessToken()
    );
    if (ret.success === true) {
      console.log(ret.data);
      setCurrentData(ret.data);
    } else {
      console.log(ret.message);
      setError(ret.message + " - " + ret.code);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    load();
  }, []);
  const updateStatusInServer = async (status) => {
    console.log(status);
    setLoadingData1(true);

    let url = `api/pms/coreact/vehicle/${vehicle}/fr`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      { franchisee: status },
      activeFranchise
    );
    if (ret.success === true) {
      setFr(status);
    } else {
      alert(ret.message);
    }
    setLoadingData1(false);
  };
  // eslint-disable-line react-hooks/exhaustive-deps
  const handleFrChange = (event) => {
    updateStatusInServer(event.target.value);
  };
  return (
    <div>
      <Grid container columnSpacing={1} sx={{ pl: 2, pr: 2 }}>
        <Grid item xs={12} md={8} lg={8}>
          <Card sx={{ p: 3 }}>
            {(loadingData === true && <CircularProgress />) ||
              (currentData && (
                <Grid container columnSpacing={1} sx={{ pl: 2, pr: 2 }}>
                  <Grid item xs={12} md={6} lg={6}>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <b>Name:</b>
                        </TableCell>
                        <TableCell>
                          <b>{currentData.name}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Category:</b>
                        </TableCell>
                        <TableCell>
                          {currentData.category == 0 ? "EV" : "-"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Status:</b>
                        </TableCell>
                        <TableCell>
                          {currentData.active == true ? "Active" : "Not Active"}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <b>Add Date:</b>
                        </TableCell>
                        <TableCell>
                          {getDateStringFullWithTimeSync(currentData.add_date)}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <b>Number:</b>
                        </TableCell>
                        <TableCell>{currentData.number}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <b>Model:</b>
                        </TableCell>
                        <TableCell>{currentData.model}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Base:</b>
                        </TableCell>
                        <TableCell>{currentData.base}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Battery:</b>
                        </TableCell>
                        <TableCell>{currentData.battery}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Voltage:</b>
                        </TableCell>
                        <TableCell>{currentData.voltage}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Grid>
                </Grid>
              ))}

            {error && (
              <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
          </Card>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Card sx={{ p: 2 }}>
            <Stack direction="row" alignItems="center" spacing={3}>
              <Select
                id="franchisee-select"
                value={fr}
                onChange={handleFrChange}
                placeholder="-"
                startAdornment={
                  <InputAdornment position="start">
                    <Box sx={{ color: "text.disabled" }}>Franchisee</Box>
                  </InputAdornment>
                }
              >
                {allFranchisees.map((item) => (
                  <MenuItem value={item._id}>{item.name}</MenuItem>
                ))}
              </Select>

              {loadingData1 === true && <CircularProgress />}
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
