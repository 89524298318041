import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Stack,
} from "@mui/material";

import { getDateStringFullWithTimeSync } from "src/utils/date-time-helper";
import Label from "src/pages/crm/Label";

const TABLE_HEAD = [
  { id: "datetime", label: "Date & Time", alignRight: false },
  { id: "amt", label: "Amount", alignRight: false },
  { id: "category", label: "Category", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "balance", label: "Balance", alignRight: false },
];

export default function CustomerTxnsPaginated({ customer, defaultList = 10 }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const loadData = async () => {
    setLoadingData(true);

    let url = `/api/wallet/mgract/transactions/query?sort=_id&dir=d&user=${
      customer._id
    }&skip=${page * defaultList}&limit=${defaultList}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setCustomerData(ret.data);
      console.log(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, [page, customer]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h5" gutterBottom>
            Txns Data
          </Typography>

          {loadingData === true && <CircularProgress color="secondary" />}
        </Stack>

        <div>
          <TableContainer sx={{ minHeight: 100 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.alignRight ? "right" : "left"}
                      sortDirection={false}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {customerData.map((item) => {
                  let color =
                    item.status === 1
                      ? "white"
                      : [-1, 2].includes(item.status)
                      ? "lightsalmon"
                      : "lightcyan";

                  if (item.deleted === true) color = "lightgray";

                  return (
                    <TableRow
                      hover
                      style={{
                        backgroundColor: color,
                        textDecoration:
                          item.deleted === true ? "line-through" : "none",
                      }}
                    >
                      <TableCell>
                        {getDateStringFullWithTimeSync(
                          parseInt(item?._id.substring(0, 8), 16) * 1000
                        )}
                      </TableCell>
                      <TableCell>
                        <b>₹ {item?.amount?.toFixed(1)}</b>
                      </TableCell>

                      <TableCell>
                        <Label
                          color={
                            [0, 2, 6, 8].includes(item.category)
                              ? "error"
                              : "success"
                          }
                        >
                          {item.category === 0
                            ? "SELF DEBIT"
                            : item.category === 1
                            ? "ONLINE RECHARGE"
                            : item.category === 2
                            ? "MILK PURCHASE"
                            : item.category === 3
                            ? "CART RECHARGE"
                            : item.category === 4
                            ? "REFUND"
                            : item.category === 5
                            ? "CASHBACK"
                            : item.category === 6
                            ? "ORDER PAYMENT"
                            : item.category === 7
                            ? "CASH REFUND"
                            : item.category === 8
                            ? "CASHBACK EXPIRED"
                            : "-"}
                        </Label>
                      </TableCell>

                      <TableCell>
                        <Label
                          color={
                            item.status === 0
                              ? "warning"
                              : item.status === 1
                              ? "success"
                              : "error"
                          }
                        >
                          {item.status === 0
                            ? "PENDING"
                            : item.status === 1
                            ? "SUCCESS"
                            : "FAILED"}
                        </Label>
                      </TableCell>

                      <TableCell>₹ {item?.temp_balance?.toFixed(1)}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={-1}
            rowsPerPage={defaultList}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[defaultList]}
          />
        </div>
      </Container>
    </Card>
  );
}
