import {
  Grid,
  Container,
  Typography,
  Stack,
  Button,
  Modal,
  Box,
} from "@mui/material";

import Page from "src/components/Page";

import { useNavigate } from "react-router-dom";
import DispenserSearch from "./DispensersSearch";
import { useState } from "react";
import AddDispenserModal from "./AddDispenserModal";

export default function Dispensers() {
  const navigate = useNavigate();
  const [dispenserModalOpen, setDispenserModal] = useState(false);

  const closeDispenserModal = () => {
    setDispenserModal(false);
  };

  return (
    <Page title="Dispenser Dashboard">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography variant="h4">Dispensers Dashboard</Typography>
          <Button variant="contained" onClick={() => setDispenserModal(true)}>
            Add Dispenser
          </Button>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <DispenserSearch />
          </Grid>
        </Grid>
      </Container>
      <Modal open={dispenserModalOpen} onClose={closeDispenserModal}>
        <AddDispenserModal handleM1Close={closeDispenserModal} />
      </Modal>
    </Page>
  );
}
