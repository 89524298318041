import { Card, Box, CircularProgress, Stack } from "@mui/material";

import { ChevronRightRounded } from "@mui/icons-material";

import { useAuth } from "src/contexts/AuthContext";

import { useEffect, useState } from "react";
import { dataFetch } from "src/utils/data-fetch";
import CustomerDetailsPage from "../home/det/CustomerDetailsPage";
import ApartmentDetailsPage from "../apartment/det/ApartmentDetailsPage";

export default function ApartmentPopup({ dataId }) {
  const { getAccessToken, activeFranchise } = useAuth();
  const [showApartmentDetails, setShowApartmentDetails] = useState(false);

  const [detail, setDetail] = useState();
  const [loading, setLoading] = useState(false);

  const loadCurrentData = async () => {
    setLoading(true);

    let url = `/api/apartments/mgract/appt/${dataId}`;
    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setDetail(ret.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (dataId) loadCurrentData();

    console.log(detail);
  }, [dataId]);

  return (
    <div style={{ padding: 2, minWidth: "300px" }}>
      {loading === true && <CircularProgress />}
      {loading === false && detail && (
        <div>
          <h2 class="firstHeading">
            <Stack direction="row" alignItems="center">
              <font color={detail.active === true ? "black" : "gray"}>
                {detail.name}
              </font>

              <Box sx={{ flexGrow: 1 }} />
              <ChevronRightRounded
                onClick={() => {
                  setShowApartmentDetails(true);
                }}
              />
            </Stack>
          </h2>
          <font color="blue">{detail?.cart?.phone}</font>

          <div style={{ marginTop: "10px", maxWidth: 400 }}>
            <b>Address: </b>
            <font color="blue">{detail?.location?.detail}</font>
          </div>

          {detail.end_time && (
            <div style={{ marginTop: "10px" }}>
              <b>End Time Morning:</b>
              <font color="blue">
                {" Around "}
                {Math.floor(detail.end_time / 1000 / 3600)}:
                {Math.floor(((detail.end_time / 1000) % 3600) / 60)}
              </font>
            </div>
          )}

          {detail.end_time_e && (
            <div style={{ marginTop: "10px" }}>
              <b>End Time Evening:</b>
              <font color="blue">
                {" Around "}
                {Math.floor(detail.end_time_e / 1000 / 3600)}:
                {Math.floor(((detail.end_time_e / 1000) % 3600) / 60)}
              </font>
            </div>
          )}

          {detail.cart && (
            <div style={{ marginTop: "10px" }}>
              <b>Cart: </b>
              <font color="blue">
                {detail.cart["name"]} ({detail.cart["phone"]})
              </font>
            </div>
          )}

          {<div style={{ marginTop: "10px" }}>
              <b>Flats: </b>
              <font color="green">{detail?.flats}</font>
            </div>
          }

          {detail.franchisee && (
            <div style={{ marginTop: "10px" }}>
              <b>Franchisee: </b>
              <font color="blue">
                {detail.franchisee["name"]} ({detail.franchisee["phone"]})
              </font>
            </div>
          )}
        </div>
      )}

      <ApartmentDetailsPage
        apartment={detail}
        handleClose={() => setShowApartmentDetails(false)}
        drawerOpen={showApartmentDetails}
      />
    </div>
  );
}
