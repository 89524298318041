import { Container, Typography, Grid, Stack } from "@mui/material";

import Page from "src/components/Page";
import EmployeePayrollList from "./EmployeePayrollList";
import { useState } from "react";
import MakeFullScreen from "src/components/MakeFullScreen";
import EmployeeDetailsPage from "../det/EmployeeDetailsPage";

export default function EmployeePayrollPage() {
  const [currEmp, setCurrEmp] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleEmpSelected = async (empData) => {
    setCurrEmp(empData);
    setDrawerOpen(true);
  };

  return (
    <Page title="Employee Dashboard">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography variant="h4">Payroll Dashboard</Typography>
        </Stack>

        <Grid container mb={4} spacing={4}>
          <Grid item xs={12} sm={12} md={9}>
            <EmployeePayrollList onEmpSelected={handleEmpSelected} />
          </Grid>
        </Grid>
      </Container>

      {drawerOpen && (
        <MakeFullScreen
          onClose={() => setDrawerOpen(false)}
          open={drawerOpen}
          title={currEmp?.name}
        >
          <EmployeeDetailsPage incEmployee={currEmp} />
        </MakeFullScreen>
      )}
    </Page>
  );
}
