import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import { Card, Alert, Stack, TextField, Typography } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";

export default function NewAdjustmentModal({ handleM1Close, employee }) {
  const [error, setError] = useState();

  const { getAccessToken, activeFranchise } = useAuth();

  const approveNow = async (values) => {
    const ret = await dataPost(
      `/api/employeetransactions/mgract/newadjustment`,
      await getAccessToken(),
      {
        amount: values.amount,
        comments: values.comments,
        employee: employee.employee
      },
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      handleM1Close();
      alert(ret.message);
    } else {
      console.log(ret.message);
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    amount: Yup.number().required("Amount is required!").not([0]),
    comments: Yup.string().optional(),
  });

  const formik = useFormik({
    initialValues: {
      amount: "",
      comments: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          height: "fit-content",
          minWidth: 400,
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          sx={{ mb: 2 }}
          spacing={2}
        >
          <Typography variant="h5" gutterBottom>
            Salary Adjustment ({employee.name})
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                type="number"
                label="Amount"
                {...getFieldProps("amount")}
                error={Boolean(touched.amount && errors.amount)}
                helperText={touched.amount && errors.amount}
              />

              <TextField
                fullWidth
                type="text"
                label="Comments"
                {...getFieldProps("comments")}
                error={Boolean(touched.comments && errors.comments)}
                helperText={touched.comments && errors.comments}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
