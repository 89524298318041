import {
  Home,
  Person3,
  BookRounded,
  LocalShipping,
  Shower,
  Person2,
  Factory,
  AccountTree,
} from "@mui/icons-material";
// ----------------------------------------------------------------------

export function getConfig(manager, activeFr) {
  const config = [];

  if (manager.access) {
    config.push({
      title: "Home",
      path: "/admin/home",
      icon: <Home />,
    });

    config.push({
      title: "Accounts",
      path: "/admin/accounts",
      icon: <BookRounded />,
    });

    // config.push({
    //   title: "Online Payments",
    //   path: "/admin/onlinepayments",
    //   icon: <Payment />,
    // });

    config.push({
      title: "Employees",
      path: "/admin/employees",
      icon: <Person3 />,
    });
    config.push({
      title: "Managers",
      path: "/admin/managers",
      icon: <Person2 />,
    });
    config.push({
      title: "Franchisee",
      path: "/admin/franchisee",
      icon: <Factory />,
    });
    config.push({
      title: "Dispensers",
      path: "/admin/dispensers",
      icon: <Shower />,
    });
    config.push({
      title: "Vehicles",
      path: "/admin/vehicles",
      icon: <LocalShipping />,
    });
    config.push({
      title: "Collection",
      path: "/admin/collection",
      icon: <Person2 />,
    });
    config.push({
      title: "Milkflow",
      path: "/admin/milkflow",
      icon: <AccountTree />,
    });
  }

  return [config];
}
