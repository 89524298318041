import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  Stack,
  CircularProgress,
} from "@mui/material";
import CustomerDetailsPage from "../CustomerDetailsPage";

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "referralstatus", label: "Referral Status", alignRight: false },
];

export default function GetReferredCustomer({ customer, defaultList = 10 }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [page, setPage] = useState(0);

  const { getAccessToken, activeFranchise } = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const loadData = async () => {
    setLoadingData(true);

    let url = `/api/users/mgract/user/${
      customer._id
    }/getreferredcustomers?skip=${page * defaultList}&limit=${defaultList}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, [page, customer]); // eslint-disable-line react-hooks/exhaustive-deps

  const [selectModalState, setSelectModalState] = useState(null);

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h5" gutterBottom>
            Referred By You
          </Typography>

          {loadingData === true && <CircularProgress />}
        </Stack>

        <TableContainer sx={{ minHeight: 100 }}>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.alignRight ? "right" : "left"}
                    sortDirection={false}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {customerData.map((item) => {
                return (
                  <TableRow onClick={() => setSelectModalState(item)}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      {item?.referral_status?.toUpperCase() || "-"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={-1}
          rowsPerPage={defaultList}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[defaultList]}
        />
      </Container>

      {selectModalState && (
        <CustomerDetailsPage
          customer={selectModalState}
          drawerOpen={true}
          handleClose={() => setSelectModalState(null)}
        />
      )}
    </Card>
  );
}
