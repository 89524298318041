import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";

import {
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
  LockRounded,
} from "@mui/icons-material";
import Page from "src/components/Page";

import {
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  CircularProgress,
  Stack,
  Modal,
  IconButton,
} from "@mui/material";

import {
  getDayStart,
  TWENTY4HOURS,
  getTimeString,
} from "src/utils/date-time-helper";

import { MobileDatePicker } from "@mui/x-date-pickers";

import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Label from "../det/Label";

const TABLE_HEAD = [
  { id: "name", label: "Amount", alignRight: false },
  { id: "cat", label: "Category", alignRight: false },
  { id: "punch", label: "Punch", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
];

export default function EmployeeAttnCard({ startDate1 }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const [startDate, setStartDate] = useState(
    getDayStart(startDate1 ? startDate1 : Date.now())
  );

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/employees/mgract/attendance/query?sort=date&dir=a&date_gt=${startDate}&date_lt=${
      startDate + TWENTY4HOURS - 1
    }`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [startDate, activeFranchise]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page title="Employee Dashboard">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography variant="h4">Attendance</Typography>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <IconButton
              sx={{ display: "inline-flex", mr: 1 }}
              onClick={() => {
                setStartDate(startDate - TWENTY4HOURS);
              }}
            >
              <KeyboardArrowLeftRounded />
            </IconButton>

            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={"en-in"}
            >
              <MobileDatePicker
                value={moment(startDate)}
                onAccept={(newValue) => {
                  if (newValue) {
                    const d1 = getDayStart(newValue.valueOf());
                    if (startDate !== d1) setStartDate(d1);
                  }
                }}
                closeOnSelect={true}
                format="DD/MM/YYYY"
                loading={loadingData}
              />
            </LocalizationProvider>
            <IconButton
              sx={{ display: "inline-flex", ml: 1 }}
              onClick={() => {
                setStartDate(startDate + TWENTY4HOURS);
              }}
            >
              <KeyboardArrowRightRounded />
            </IconButton>
          </Stack>
        </Stack>
        {loadingData === true && <CircularProgress color="secondary" />}
        {customerData.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {customerData.map((item) => {
                    let color =
                      item.status === "approved"
                        ? "white"
                        : item.status === "pending"
                        ? "lightcyan"
                        : "lightsalmon";

                    if (!item.punch_details || item.punch_details.length === 0)
                      color = "lightgrey";

                    let hrs = 0;
                    if (item.sale_start_m > 0 && item.sale_end_m > 0) {
                      hrs += item.sale_end_m - item.sale_start_m;
                    }
                    if (item.sale_start_e > 0 && item.sale_end_e > 0) {
                      hrs += item.sale_end_e - item.sale_start_e;
                    }
                    hrs = hrs / 1000 / 60 / 60;

                    return (
                      <TableRow hover style={{ backgroundColor: color }}>
                        <TableCell>
                          <b>{item.employee?.name}</b>
                        </TableCell>
                        <TableCell>{item.category?.toUpperCase()}</TableCell>
                        <TableCell>
                          {(item.punch_details.length > 0 && (
                            <>
                              {item.punch_details
                                .reverse()
                                .map((i) => getTimeString(i.ts) + ", ")}
                            </>
                          )) || <>{getTimeString(item.checkin)}</>}
                        </TableCell>

                        <TableCell>{item.status?.toUpperCase()}</TableCell>
                        <TableCell>
                          <Stack spacing={1} direction={"row"}>
                            {item.morning_attn === 0 && (
                              <Label variant="filled" color="error">
                                M: Absent
                              </Label>
                            )}
                            {item.morning_attn === 1 && (
                              <Label variant="filled" color="warning">
                                M: Late
                              </Label>
                            )}
                            {item.evening_attn === 0 && (
                              <Label variant="filled" color="error">
                                E: Absent
                              </Label>
                            )}
                            {item.evening_attn === 1 && (
                              <Label variant="filled" color="warning">
                                E: Late
                              </Label>
                            )}
                            {hrs > 0 && (
                              <Label variant="filled" color="info">
                                Work: {hrs.toFixed(1)} Hrs
                              </Label>
                            )}
                            {item.locked === true && (
                              <Label variant="filled" color="success">
                                <LockRounded sx={{ p: 0.5 }} />
                              </Label>
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </Container>
    </Page>
  );
}
