import {
  Grid,
  Container,
  Typography,
  Stack,
  Button,
  Modal,
} from "@mui/material";

import { useState } from "react";

import Page from "../../components/Page";
import PartySearch from "./PartySearch";
import TodayTxns from "./TodayTxns";
import OrdersToday from "./OrdersToday";
import AddPartyModal from "./AddPartyModal";
import { PartyDetailsModal } from "./PartyDetailsModal";
import BulkOrderDetails from "./BulkOrderDetails";
import { SaleSummary } from "./SaleSummary";
import TopCard from "./TopCard";
import { SearchRounded } from "@mui/icons-material";
import AllParty from "./AllParty";
import MakeFullScreen from "src/components/MakeFullScreen";
import BulkProductList from "./BulkProductList";
import ProductDetailsPage from "../products/det/ProductDetailsPage";

export default function BulkSale() {
  const [addModalState, setAddModalState] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productModalState, setProductModalState] = useState(false);

  const closeAddPartyModal = () => {
    setAddModalState(false);
  };

  const addPartyClicked = () => {
    setAddModalState(true);
  };

  const handleOrderSelected = (order) => {
    setSelectedOrder(order);
    setOrderDetailsModal(true);
  };

  const [orderDetailsModal, setOrderDetailsModal] = useState(false);

  const handleOrderDetailsModalClose = () => {
    setOrderDetailsModal(false);
  };

  const [currData, setCurrData] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handlePartySelected = (d) => {
    setCurrData(d);
    setDrawerOpen(true);
  };

  const [allPartyModalState, setAllPartyModalState] = useState(false);
  const [partySearchModalState, setPartySearchModalState] = useState(false);
  const [productListModalState, setProductListModalState] = useState(false);

  return (
    <Page title="B2B Sales Dashboard">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
          spacing={2}
        >
          <Typography variant="h4">B2B Sales Dashboard</Typography>

          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            justifyContent="space-between"
          >
            <Button variant="contained" onClick={addPartyClicked}>
              Add Party
            </Button>
          </Stack>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={6} md={3} lg={3}>
            <TopCard
              data="Customers"
              onClick={() => setPartySearchModalState(true)}
              icon={<SearchRounded />}
            />
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <TopCard
              data="All Customers"
              onClick={() => setAllPartyModalState(true)}
            />
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <TopCard
              data="View Products"
              onClick={() => setProductListModalState(true)}
              // onClick={() => setDaybookModalState(true)}
            />
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            {/* <TopCard data="Ledger List" onClick={() => navigate("ledgers")} /> */}
          </Grid>

          <Grid item xs={12} md={6} lg={7}>
            <Stack direction="column" spacing={2}>
              <SaleSummary />

              <OrdersToday onDataSelected={handleOrderSelected} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={5} lg={5}>
            <TodayTxns />
          </Grid>
        </Grid>
      </Container>

      <AddPartyModal
        drawerOpen={addModalState}
        handleM1Close={closeAddPartyModal}
      />

      {drawerOpen === true && (
        <PartyDetailsModal
          party={currData}
          handleClose={() => setDrawerOpen(false)}
          drawerOpen={drawerOpen}
        />
      )}

      {orderDetailsModal === true && (
        <Modal open={orderDetailsModal} onClose={handleOrderDetailsModalClose}>
          <BulkOrderDetails bulkOrder={selectedOrder} />
        </Modal>
      )}

      {/* 
      <FullScreenEmpDetailsDialog
        emp={currEmp}
        handleClose={() => setDrawerOpen(false)}
        drawerOpen={drawerOpen}
      />  */}

      {allPartyModalState === true && (
        <AllParty
          open={allPartyModalState}
          onClose={() => setAllPartyModalState(false)}
        />
      )}

      {partySearchModalState === true && (
        <MakeFullScreen
          open={partySearchModalState}
          onClose={() => setPartySearchModalState(false)}
          title={"Search Party"}
        >
          <PartySearch onDataSelected={handlePartySelected} />
        </MakeFullScreen>
      )}

      {productListModalState === true && (
        <MakeFullScreen
          open={productListModalState}
          onClose={() => setProductListModalState(false)}
          title={"Products"}
        >
          <BulkProductList
            onItemSelected={(data) => {
              setSelectedProduct(data);
              setProductModalState(true);
            }}
          />
        </MakeFullScreen>
      )}

      <ProductDetailsPage
        product={selectedProduct}
        handleClose={() => setProductModalState(false)}
        drawerOpen={productModalState}
      />
    </Page>
  );
}
