import {
  AppBar,
  Button,
  Container,
  Dialog,
  Slide,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";

import { useState, forwardRef } from "react";
import { CloseRounded } from "@mui/icons-material";
import NotificationProfile from "./NotificationProfile";
import AddInAppMsgModal from "../AddInAppMsgModal";
import AddNotifModal from "../AddNotifModal";
import { useAuth } from "src/contexts/AuthContext";
import { useSnackbar } from "src/contexts/SnackbarContext";
import { dataFetch } from "src/utils/data-fetch";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NotificationDetailsPage({
  notification,
  handleClose,
  drawerOpen,
}) {
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const onModalClose = () => {
    setModalOpen(false);
  };

  const onNotificationModalClose = () => {
    setNotificationModalOpen(false);
  };

  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const sendNotificationNow = async () => {
    setLoadingData(true);
    const response = await dataFetch(
      `/api/sendnotification/mgract/msg/${notification._id}/send`,
      await getAccessToken(),
      activeFranchise
    );

    if (response.success === true) {
      showSnackbar(
        response.message || "Notification sent successfully",
        "success"
      );
      handleClose();
    } else {
      showSnackbar(response.message || "Notification failed!", "error");
    }
    setLoadingData(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={drawerOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            {notification?.title || "Loading..."}
          </Typography>

          <Stack direction="row" spacing={2}>
            {notification?.entity === "notification" &&
              notification.sent === false && (
                <Button
                  variant="outlined"
                  color="success"
                  onClick={sendNotificationNow}
                >
                  SEND
                </Button>
              )}

            <Button
              variant="outlined"
              color="white"
              onClick={() =>
                notification?.entity === "inapp"
                  ? setModalOpen(true)
                  : setNotificationModalOpen(true)
              }
            >
              Duplicate
            </Button>

            <Button variant="contained" color="error" onClick={handleClose}>
              <CloseRounded />
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>

      <Container sx={{ minHeight: 500, pb: 2 }}>
        {notification ? (
          <NotificationProfile notification={notification} />
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Container>
      {modalOpen === true && (
        <AddInAppMsgModal
          drawerOpen={modalOpen}
          notification={notification}
          handleM1Close={onModalClose}
        />
      )}
      {notificationModalOpen && (
        <AddNotifModal
          drawerOpen={notificationModalOpen}
          handleM1Close={onNotificationModalClose}
          notification={notification}
        />
      )}
    </Dialog>
  );
}
