import React from "react";
import { useState, useEffect } from "react";

import {
  Card,
  Table,
  Stack,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
} from "@mui/material";
import { dataFetch } from "src/utils/data-fetch";
import { useAuth } from "src/contexts/AuthContext";

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "city", label: "City", alignRight: false },
  { id: "active", label: "Active Status", alignRight: false },
  { id: "type", label: "Type", alignRight: false },
];

export default function FranchiseeList({ onFranchiseeClick }) {
  const [balanceData, setBalanceData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const { getAccessToken, activeFranchise } = useAuth();

  useEffect(() => {
    fetchBalanceData();
  }, [activeFranchise]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchBalanceData = async () => {
    setLoadingData(true);

    const ret = await dataFetch(
      `/api/franchisees/coreact/query?&sort=name`,
      await getAccessToken(),
      activeFranchise
    );
    if (ret.success === true) {
      setBalanceData(ret.data);
    }
    setLoadingData(false);
  };

  return (
    <Card title="Manager List" sx={{ pt: 2, pb: 2 }}>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h5" gutterBottom>
            Franchisee
          </Typography>

          {loadingData === true && <CircularProgress color="secondary" />}
        </Stack>

        {balanceData.length > 0 ? (
          <div>
            <TableContainer sx={{ minHeight: 200 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {balanceData.map((item) => {
                    return (
                      <TableRow
                        hover
                        onClick={() => onFranchiseeClick(item)}
                        style={{
                          backgroundColor:
                            item.active === false ? "#ddd" : "white",
                        }}
                      >
                        <TableCell>
                          <b>{item ? item.name : "-"}</b>
                        </TableCell>
                        <TableCell>{item.city || "-"}</TableCell>
                        <TableCell>
                          {item.active == 1 ? "Active" : "Not Active"}
                        </TableCell>
                        <TableCell>{item ? item.type : "-"}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <Typography variant="body2" align="center" sx={{ mt: 3, mb: 2 }}>
            No Data found
          </Typography>
        )}
      </Container>
    </Card>
  );
}
