import { Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import DashboardNavbar from "./DashboardNavbar";
import { SocketProvider } from "src/contexts/SocketContext";

const APP_BAR_DESKTOP = 60;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
  paddingTop: APP_BAR_DESKTOP,
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: theme.spacing(1),
}));

// ----------------------------------------------------------------------

export default function MapLayout() {
  return (
    <RootStyle>
      <DashboardNavbar />
      <SocketProvider>
        <MainStyle>
          <Outlet />
        </MainStyle>
      </SocketProvider>
    </RootStyle>
  );
}
