import { SearchRounded, SendRounded } from "@mui/icons-material";
import {
  Grid,
  List,
  ListItem,
  Typography,
  Box,
  TextField,
  Button,
  Stack,
  InputAdornment,
  styled,
  OutlinedInput,
  Card,
  Container,
  Switch,
  Divider,
} from "@mui/material";

import { Avatar } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Page from "src/components/Page";
import { useAuth } from "src/contexts/AuthContext";
import { useSnackbar } from "src/contexts/SnackbarContext";
import { useSupportSocket } from "src/contexts/SupportSocketContext";

import CustomerDetailsPage from "src/pages/home/det/CustomerDetailsPage";

import { dataFetch } from "src/utils/data-fetch";
import { dataPost } from "src/utils/data-fetch-support";
import { getDateStringCustom } from "src/utils/date-time-helper";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),

  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export const ChatSystem = () => {
  const { conversations, messages, fetchMessages, sendTextMsg, newMsg, agent } =
    useSupportSocket();

  const { showSnackbar } = useSnackbar();
  const { getAccessToken, activeFranchise } = useAuth();

  const lastMsgRef = useRef(null);
  const [custmerProfile, setCustomerProfile] = useState(null);
  const [custDrawerOpen, setCustDrawerOpen] = useState(false);

  const getCustomerData = async (id) => {
    try {
      const ret = await dataFetch(
        `/api/users/mgract/user/${id}?extended=true`,
        await getAccessToken(),
        activeFranchise
      );

      console.log(ret);

      if (ret.success === true) {
        setCustomerProfile(ret.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [selectConv, setSelectConv] = useState(null);
  const [currentMsg, setCurrentMsg] = useState("");

  const sortedConvs = Object.values(conversations).sort((a, b) => {
    return b.last_message > a.last_message;
  });

  let currMsgs = Object.values(
    (selectConv && messages[selectConv._id]) || {}
  ).sort((a, b) => {
    return a.timestamp - b.timestamp;
  });

  const handleSendMessage = () => {
    if (currentMsg.trim() !== "") {
      sendTextMsg(currentMsg.trim(), selectConv._id);
      setCurrentMsg("");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    if (lastMsgRef.current) {
      lastMsgRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [newMsg, currMsgs.length]);

  //agent live status
  let currentAgentStatus = agent?.active_now || false;

  const updateAgentStatus = async (e) => {
    const url = `/agents/agentact/statusupdate`;

    const ret = await dataPost(url, await getAccessToken(), {
      status: e,
    });

    showSnackbar(ret.message || "??", ret.success ? "success" : "error");
  };

  return (
    <>
      <Page>
        <Stack
          direction="row"
          spacing={2}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ px: 2, py: 1 }}
          height="60px"
        >
          <Typography variant="h4" component="h1">
            Chat System
          </Typography>

          <Stack direction="row" spacing={2} alignItems={"center"}>
            Active
            <Switch
              checked={currentAgentStatus}
              onClick={(e) => updateAgentStatus(!currentAgentStatus)}
            />
          </Stack>
        </Stack>

        <Divider />

        <Grid container sx={{ flex: 1 }}>
          <Grid
            item
            xs={3}
            md={3}
            sx={{ borderRight: "1px solid lightgray", p: 2 }}
          >
            <SearchStyle
              placeholder="Search Customers......"
              //onChange={handleChangeQuery}
              startAdornment={
                <InputAdornment position="start">
                  <Box sx={{ color: "text.disabled" }}>
                    <SearchRounded />
                  </Box>
                </InputAdornment>
              }
              autoFocus={true}
            />
            <List>
              {sortedConvs.map((customer, index) => (
                <ListItem key={index} sx={{ padding: 0, marginBottom: 1 }}>
                  <Card
                    sx={{
                      p: 2,
                      borderRadius: 2,
                      width: "100%",
                      backgroundColor:
                        customer?.name === selectConv?.name
                          ? "lightgray"
                          : "white",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSelectConv(customer);
                      fetchMessages(customer._id);
                      getCustomerData(customer.user);
                    }}
                  >
                    <Stack direction="row" spacing={2}>
                      <Avatar>{customer?.name.charAt(0)}</Avatar>
                      <Typography variant="body1" sx={{ pt: 1 }}>
                        <b>{customer?.name}</b>
                      </Typography>
                    </Stack>
                  </Card>
                </ListItem>
              ))}
            </List>
          </Grid>

          <Grid
            item
            xs={6}
            md={6}
            sx={{
              borderRight: "1px solid lightgray",
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              justifyContent={"space-between"}
              alignItems={"center"}
              p={1}
              sx={{
                backgroundColor: "rgba(255,255,255,0.1)",
              }}
              height="60px"
            >
              <Typography variant="h6">
                {selectConv ? selectConv.name : "Select a customer"}
              </Typography>

              <Button variant="contained" color="primary">
                END CHAT
              </Button>
            </Stack>

            <Divider />

            <Box
              sx={{
                flexShrink: 1,
                height: "calc(100vh - 190px)",
                overflowY: "auto",
                p: 1,
              }}
            >
              {selectConv ? (
                currMsgs && currMsgs.length > 0 ? (
                  currMsgs.map((chat, index) => (
                    <Box
                      key={index}
                      ref={index === currMsgs.length - 1 ? lastMsgRef : null}
                      sx={{
                        display: "flex",
                        flexDirection: ["agent", "bot"].includes(chat.action)
                          ? "row-reverse"
                          : "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginBottom: 2,
                      }}
                    >
                      <Card
                        sx={{
                          minWidth: "5%",
                          borderRadius: 2,
                          padding: 1,
                          color: chat.action === "agent" ? "grey.200" : "black",
                          backgroundColor:
                            chat.action === "agent"
                              ? "primary.main"
                              : chat.action === "bot"
                              ? "success.main"
                              : "warning.main",
                          whiteSpace: "pre-wrap",
                          boxShadow: 1,
                        }}
                      >
                        <Stack direction="column" spacing={1} sx={{ px: 1 }}>
                          <Typography variant="body2" sx={{ flexGrow: 1 }}>
                            {chat.message}
                          </Typography>

                          {chat.entity === "options" && (
                            <Card
                              sx={{
                                px: 2,
                                py: 1,
                                boxShadow: 0,
                                backgroundColor: "lightgreen",
                              }}
                              variant="outlined"
                            >
                              {chat.options.map((option, index) => (
                                <Typography
                                  variant="body2"
                                  sx={{ flexGrow: 1 }}
                                >
                                  {option.message}
                                </Typography>
                              ))}
                            </Card>
                          )}

                          <Typography
                            variant="caption"
                            sx={{ alignSelf: "flex-end", pl: 8 }}
                          >
                            {getDateStringCustom(
                              chat.timestamp,
                              "D/M/YY  hh:mm a"
                            )}
                          </Typography>
                        </Stack>
                      </Card>
                    </Box>
                  ))
                ) : (
                  <Typography>No chats available for this customer.</Typography>
                )
              ) : (
                <Typography>Select a customer to start chatting</Typography>
              )}
            </Box>

            <Container>
              <Box
                sx={{
                  position: "sticky",
                  bottom: 0,
                  width: "100%",
                  height: "60px",
                }}
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <TextField
                    fullWidth
                    multiline
                    type="text"
                    maxRows={3}
                    label="Type a message"
                    value={currentMsg}
                    onChange={(e) => setCurrentMsg(e.target.value)}
                    onKeyDown={handleKeyPress}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ px: 2, height: 50 }}
                    onClick={() => {
                      sendTextMsg(currentMsg, selectConv._id);
                      setCurrentMsg("");
                    }}
                  >
                    <SendRounded />
                  </Button>
                </Stack>
              </Box>
            </Container>
          </Grid>

          <Grid item xs={3} md={3} sx={{ px: 2, py: 1 }}>
            <Typography variant="h4">Details</Typography>
            <CustDetCard
              prof={custmerProfile}
              open={() => setCustDrawerOpen(true)}
            />
          </Grid>
        </Grid>
      </Page>

      {custDrawerOpen === true && (
        <CustomerDetailsPage
          customer={custmerProfile}
          handleClose={() => setCustDrawerOpen(false)}
          drawerOpen={custDrawerOpen}
        />
      )}
    </>
  );
};

const CustDetCard = ({ prof, open }) => {
  return (
    <Box sx={{ height: "calc(100vh - 160px)", overflowY: "auto", mt: 4 }}>
      {prof == null ? (
        <Typography>Select a customer to view details</Typography>
      ) : (
        <Stack
          direction={"column"}
          spacing={2}
          alignItems="center"
          sx={{
            padding: 3,
            backgroundColor: "background.paper",
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              sx={{
                width: 100,
                height: 100,
                fontSize: 40,
                backgroundColor: "primary.main",
                color: "white",
              }}
            >
              {prof?.name.charAt(0)}
            </Avatar>
          </Box>

          <Typography variant="h6" align="center">
            {prof?.name}
          </Typography>

          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "grey.100",
              padding: 2,
              borderRadius: 1,
            }}
          >
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Typography variant="body2">
                <b>Phone Number:</b>
              </Typography>

              <Typography variant="body2">{prof?.phone || "N/A"}</Typography>
            </Stack>

            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Typography variant="body2">
                <b>Apartment:</b>{" "}
              </Typography>

              <Typography variant="body2">
                {prof?.address?.apartment?.name || "N/A"}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Typography variant="body2">
                <b>Cart:</b>
              </Typography>

              <Typography variant="body2">
                {prof?.cart?.name || "N/A"}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Typography variant="body2">
                <b>Flat:</b>
              </Typography>

              <Typography variant="body2">
                {prof?.address?.flat || "N/A"}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Typography variant="body2">
                <b>Tower:</b>
              </Typography>

              <Typography variant="body2">
                {prof?.address?.tower || "N/A"}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Typography variant="body2">
                <b>Balance:</b>
              </Typography>

              <Typography
                variant="body2"
                color={prof?.wallet?.balance > 0 ? "success" : "error"}
              >
                {prof?.wallet?.balance || "N/A"}
              </Typography>
            </Stack>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button variant="contained" color="primary" onClick={open}>
              View Profile
            </Button>
          </Box>
        </Stack>
      )}
    </Box>
  );
};
