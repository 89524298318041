import { useEffect, useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import Select from "@mui/material/Select";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Grid,
  Box,
  Chip,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  Drawer,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataFetch, dataPatch, rootUrl } from "src/utils/data-fetch";

export default function AddEmployeeDetailModal({ handleM1Close, details }) {
  const [error, setError] = useState();
  const { getAccessToken, activeFranchise } = useAuth();
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);

  const approveNow = async (values) => {
    let up = {
      name: values.name,
      position: values.position,
      joinDate: values.joinDate,
      address: values.address,
      phone: `+91${values.phone}`,
      details,
    };  

    const downloadUrl = `${rootUrl}/api/salary/${up.name}/offer`;

    const handleDownLoad = (up) => {
      setLoading(true);

      window.open(downloadUrl, "_blank");
      setLoading(false);
    };
  };

  const DataSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    position: Yup.string().required("Required"),
    joinDate: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
    phone: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      position: "",
      joinDate: "",
      address: "",
      phone: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
      handleM1Close();
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, values, getFieldProps } =
    formik;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Add Employee details
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Name"
                    {...getFieldProps("name")}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Position"
                    {...getFieldProps("position")}
                    error={Boolean(touched.position && errors.position)}
                    helperText={touched.position && errors.position}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Join Date"
                    {...getFieldProps("joinDate")}
                    error={Boolean(touched.joinDate && errors.joinDate)}
                    helperText={touched.joinDate && errors.joinDate}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Address"
                    {...getFieldProps("address")}
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                  />

                  <TextField
                    fullWidth
                    type="phone"
                    contentEditable={"false"}
                    label="Phone"
                    {...getFieldProps("phone")}
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                  />
                </Stack>
              </Grid>
            </Grid>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Generate
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
