import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import {
  DoneRounded as DoneIcon,
  CloseRounded as CloseIcon,
} from "@mui/icons-material";

import {
  Card,
  Alert,
  Stack,
  Typography,
  Grid,
  Box,
  CircularProgress,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Avatar,
} from "@mui/material";

import { dataFetch, dataPost } from "src/utils/data-fetch";
import FarmerBalanceCard from "../FarmerBalanceCard";

export default function ApprovePayoutModal({ handleM1Close, poutId }) {
  const [error, setError] = useState();
  const [currentData, setCurrentData] = useState({});
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const load = async () => {
    setLoadingData(true);

    const ret = await dataFetch(
      `/api/farmerwallet/mgract/transactions/txn/${poutId}`,
      await getAccessToken(),
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      setCurrentData(ret.data);
    } else {
      console.log(ret.message);
      setError(ret.message + " - " + ret.code);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const approveNow = async () => {
    setLoadingData(true);

    const ret = await dataFetch(
      `/api/onlinepayouts/mgract/pout/${poutId}/process`,
      await getAccessToken(),
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      load();
    } else {
      console.log(ret.message);
      setError(ret.message + " - " + ret.code);
      setLoadingData(false);
    }
  };

  const rejectNow = async () => {
    setLoadingData(true);

    const ret = await dataPost(
      `/api/onlinepayouts/mgract/pout/${poutId}/reject`,
      await getAccessToken(),
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      load();
    } else {
      console.log(ret.message);
      setError(ret.message + " - " + ret.code);
      setLoadingData(false);
    }
  };

  const handleReject = async () => {
    rejectNow();
  };

  const handleApprove = async () => {
    approveNow();
  };

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Payout Details
          </Typography>

          <FarmerBalanceCard
            farmerId={currentData?.farmer?._id}
            counter={currentData}
          />
        </Stack>

        {(loadingData === true && <CircularProgress />) ||
          (currentData && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>Status:</b>
                    </TableCell>
                    <TableCell>
                      <b>
                        {currentData.status &&
                          currentData?.status?.toUpperCase()}
                      </b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Farmer:</b>
                    </TableCell>
                    <TableCell>
                      {currentData?.farmer?.name} ({currentData?.farmer?.phone})
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Amount:</b>
                    </TableCell>
                    <TableCell>₹ {currentData?.amount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Category:</b>
                    </TableCell>
                    <TableCell>
                      {currentData?.category === 0
                        ? "Cash Credit"
                        : currentData?.category === 1
                        ? "Cash Debit"
                        : currentData?.category === 2
                        ? "Order Debit"
                        : currentData?.category === 3
                        ? "Order Refund"
                        : currentData?.category === 4
                        ? "Online Debit"
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Method:</b>
                    </TableCell>
                    <TableCell>
                      {currentData?.payout_method?.toUpperCase()}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <b>Mode:</b>
                    </TableCell>
                    <TableCell>{currentData?.mode?.toUpperCase()}</TableCell>
                  </TableRow>

                  {currentData.fund_account &&
                    currentData?.fund_account?.bank_account && (
                      <>
                        <TableRow>
                          <TableCell>
                            <b>Acc No:</b>
                          </TableCell>
                          <TableCell>
                            {
                              currentData.fund_account?.bank_account
                                ?.account_number
                            }
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            <b>IFSC:</b>
                          </TableCell>
                          <TableCell>
                            {currentData.fund_account?.bank_account?.ifsc}
                          </TableCell>
                        </TableRow>
                      </>
                    )}

                  {currentData.fund_account &&
                    currentData?.fund_account?.vpa && (
                      <>
                        <TableRow>
                          <TableCell>
                            <b>VPA:</b>
                          </TableCell>
                          <TableCell>{currentData.fund_account?.vpa}</TableCell>
                        </TableRow>
                      </>
                    )}

                  <TableRow>
                    <TableCell>
                      <b>Comments:</b>
                    </TableCell>
                    <TableCell>{currentData?.comments}</TableCell>
                  </TableRow>

                  {/* <TableRow>
                    <TableCell>
                      <b>Joining:</b>
                    </TableCell>
                    <TableCell>
                      {getDateStringFullWithMonthSync(doc.employee_det.join_date)}
                    </TableCell>
                  </TableRow> */}
                </TableBody>
              </Grid>

              {currentData.payout_method == "razorpayx" &&
                ["initiated", "approved"].includes(currentData.status) &&
                currentData.category == 4 && (
                  <Grid item xs={12} md={12} lg={12}>
                    <Stack direction={"row"}>
                      <Box flexGrow={1} />
                      <IconButton onClick={handleReject}>
                        <Avatar
                          variant="circular"
                          sx={{ backgroundColor: "error.main", color: "white" }}
                        >
                          <CloseIcon />
                        </Avatar>
                      </IconButton>
                      <IconButton onClick={handleApprove}>
                        <Avatar
                          variant="rounded"
                          sx={{
                            backgroundColor: "primary.main",
                            color: "white",
                          }}
                        >
                          <DoneIcon />
                        </Avatar>
                      </IconButton>
                    </Stack>
                  </Grid>
                )}
            </Grid>
          ))}

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
