import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Stack,
  Tooltip,
  Button,
  Modal,
} from "@mui/material";

import { getDateStringFullWithTimeSync } from "src/utils/date-time-helper";
import AddInventoryModal from "./AddInventoryModal";

const TABLE_HEAD = [
  { id: "cat", label: "Cat", alignRight: false },
  { id: "ts", label: "Date", alignRight: false },
  { id: "qty", label: "Qty", alignRight: false },
  { id: "price", label: "Price", alignRight: false },
  { id: "comments", label: "Comments", alignRight: false },
];

export default function ProductInventorySection({ product }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [page, setPage] = useState(0);

  const { getAccessToken, activeFranchise } = useAuth();

  const closeModalFn = () => {
    setModalOpen(false);
    handleSearch();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/products/mgract/product/${product._id}/inventory?skip=${
      page * 10
    }&limit=${10}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [product]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h5" gutterBottom>
            Inventory Data
          </Typography>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Add Inventory
          </Button>

          {loadingData === true && <CircularProgress color="secondary" />}
        </Stack>

        {customerData.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {customerData.map((item) => {
                    return (
                      <TableRow hover>
                        <TableCell>
                          {item?.cat === 0 ? "ADD" : "REMOVE"}
                        </TableCell>
                        <TableCell>
                          {getDateStringFullWithTimeSync(item?.ts)}
                        </TableCell>
                        <TableCell>
                          <b>{item?.qty}</b>
                        </TableCell>
                        <TableCell>₹ {item?.price?.toFixed(1)}</TableCell>
                        <Tooltip title={item.comments || ""} arrow>
                          <TableCell>
                            {item.comments
                              ? item.comments.substring(0, 15)
                              : "-"}
                          </TableCell>
                        </Tooltip>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={-1}
              rowsPerPage={10}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[10]}
            />
          </div>
        )}

        {customerData.length === 0 && loadingData === false && (
          <Typography variant="body2" align="center" sx={{ mb: 4 }}>
            No results here.
          </Typography>
        )}
      </Container>

      <Modal open={modalOpen} onClose={closeModalFn}>
        <AddInventoryModal
          handleM1Close={closeModalFn}
          productId={product._id}
        />
      </Modal>
    </Card>
  );
}
