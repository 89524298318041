import React from "react";
import { useState, useEffect } from "react";

import {
  Card,
  Table,
  Stack,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  TextField,
  MenuItem,
} from "@mui/material";

import { dataFetch } from "../../utils/data-fetch";
import { useAuth } from "../../contexts/AuthContext";
import CustomerDetailsPage from "./det/CustomerDetailsPage";

//-------------------------------------------------------------------------
const TABLE_HEAD = [
  { id: "name", label: "Name", alignRIght: false },
  { id: "balance", label: "Balance", alignRight: false },
];

export default function NegBalanceCustomers({ onUserClick }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [balanceData, setBalanceData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const [selectDir, setSelectDir] = useState("d");

  const { getAccessToken, activeFranchise } = useAuth();

  useEffect(() => {
    fetchBalanceData();
  }, [page, rowsPerPage, activeFranchise, selectDir]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchBalanceData = async () => {
    setLoadingData(true);

    const ret = await dataFetch(
      `/api/users/mgract/negativebalance?&skip=${
        page * rowsPerPage
      }&limit=${rowsPerPage}&sort=balance&dir=${selectDir}`,
      await getAccessToken(),
      activeFranchise
    );
    if (ret.success === true) {
      setBalanceData(ret.data);
    }
    setLoadingData(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Card title="Customers" sx={{ pt: 2, pb: 2 }}>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h5" gutterBottom>
            Balance Wise
          </Typography>

          {loadingData === true && <CircularProgress color="secondary" />}

          <TextField
            select
            id="dir-select"
            value={selectDir}
            onChange={(e) => setSelectDir(e.target.value)}
            label="Sort"
          >
            <MenuItem value={"a"}>ASC</MenuItem>
            <MenuItem value={"d"}>DESC</MenuItem>
          </TextField>
        </Stack>

        {balanceData.length > 0 ? (
          <div>
            <TableContainer sx={{ minHeight: 200 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {balanceData.map((bal) => {
                    return (
                      <TableRow
                        hover
                        onClick={() => {
                          onUserClick(bal?.user);
                        }}
                      >
                        <TableCell>{bal?.user?.name}</TableCell>
                        <TableCell>{bal?.balance.toFixed(2)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 50]}
              component="div"
              count={-1}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        ) : (
          <Typography variant="body2" align="center" sx={{ mt: 3, mb: 2 }}>
            No Data found
          </Typography>
        )}
      </Container>
    </Card>
  );
}
