import React from "react";
import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";

import { DownloadRounded, SearchRounded } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import {
  Card,
  Container,
  Typography,
  Table,
  OutlinedInput,
  InputAdornment,
  Box,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
  TableHead,
  Stack,
  TextField,
  IconButton,
  MenuItem,
} from "@mui/material";

import Label from "src/pages/crm/Label";
import {
  getDateStringSync,
  getDayStartAndEndSync,
  TWENTY4HOURS,
} from "src/utils/date-time-helper";
import { exportExcel } from "src/utils/excel-export-utils";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "index", label: "Index", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "flat", label: "Flat", alignRight: false },
  { id: "sample", label: "Sample", alignRight: false },
  { id: "rech", label: "Recharge", alignRight: false },
  { id: "sub", label: "Subscription", alignRight: false },
  { id: "balance", label: "Balance", alignRight: true },
];

export const ApartmentSummarySection = ({ apt, onCustomerSelected }) => {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [sampleStatus, setSampleStatus] = useState("all");
  const [subStatus, setSubStatus] = useState("all");

  const [activeStatus, setActiveStatus] = useState("all");
  const [balanceGt, setBalanceGt] = useState(1);
  const [createdStatus, setCreatedStatus] = useState("all");
  const [rechargeGt, setRechargeGt] = useState("all");

  const { getAccessToken, activeFranchise } = useAuth();

  const handleSearch = async () => {
    let url = `/api/apartments/mgract/appt/${apt._id}/summary`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [activeFranchise]); // eslint-disable-line react-hooks/exhaustive-deps

  let c1 = 0;
  let c2 = 0;
  if (createdStatus === "today") {
    const time = getDayStartAndEndSync(Date.now());
    c1 = time.start;
    c2 = time.end;
  } else if (createdStatus === "yesterday") {
    const time = getDayStartAndEndSync(Date.now() - 24 * 60 * 60 * 1000);
    c1 = time.start;
    c2 = time.end;
  } else if (createdStatus === "2days") {
    const time = getDayStartAndEndSync(Date.now() - 2 * 24 * 60 * 60 * 1000);
    c1 = time.start;
    c2 = time.end;
  } else if (createdStatus === "3days") {
    const time = getDayStartAndEndSync(Date.now() - 3 * 24 * 60 * 60 * 1000);
    c1 = time.start;
    c2 = time.end;
  } else if (createdStatus === "4days") {
    const time = getDayStartAndEndSync(Date.now() - 4 * 24 * 60 * 60 * 1000);
    c1 = time.start;
    c2 = time.end;
  } else if (createdStatus === "5days") {
    const time = getDayStartAndEndSync(Date.now() - 5 * 24 * 60 * 60 * 1000);
    c1 = time.start;
    c2 = time.end;
  } else if (createdStatus === "week") {
    const time = getDayStartAndEndSync(Date.now() - 7 * 24 * 60 * 60 * 1000);
    c1 = time.start;
    c2 = Date.now();
  } else if (createdStatus === "month") {
    const time = getDayStartAndEndSync(Date.now() - 30 * 24 * 60 * 60 * 1000);
    c1 = time.start;
    c2 = Date.now();
  } else if (createdStatus === "older") {
    const time = getDayStartAndEndSync(Date.now() - 30 * 24 * 60 * 60 * 1000);
    c1 = 0;
    c2 = time.start - 1;
  }

  const filteredData = customerData.filter((item) => {
    let val = true;
    if (sampleStatus !== "all") {
      val = val && item.sample == sampleStatus;
    }

    if (subStatus !== "all") {
      val = val && item.sub === subStatus;
    }

    if (activeStatus !== "all") {
      if (activeStatus === "true") {
        val = val && item.active === true;
      } else {
        val = val && item.active === false;
      }
    }

    val = val && item.balance >= balanceGt;

    if (createdStatus !== "all") {
      const tm = parseInt(item?._id.substring(0, 8), 16) * 1000;
      val = val && tm >= c1 && tm <= c2;
    }

    if (rechargeGt !== "all") {
      val = val && item.rech >= parseInt(rechargeGt);
    }

    return val;
  });

  // const summary = customerData.reduce(
  //   (acc, item) => {
  //     acc.sample += item.sample === true ? 1 : 0;
  //     acc.rech += item.rech === true ? 1 : 0;
  //     acc.sub += item.sub === true ? 1 : 0;
  //     acc.bal += item.balance || 0;
  //     return acc;
  //   },
  //   { sample: 0, rech: 0, sub: 0, bal: 0 }
  // );

  const bal = filteredData.reduce((acc, item) => {
    return acc + item?.balance;
  }, 0);

  const handleDownload = () => {
    exportExcel(
      "ApartmentSummary",
      customerData,
      `USERS-${apt?.name || "apt"}-${Date.now()}`
    );
  }

  return (
    <Card>
      <Container sx={{ pb: 3 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          mt={2}
          spacing={2}
        >
          <SearchStyle
            placeholder="Search Customer..."
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>
                  <SearchRounded />
                </Box>
              </InputAdornment>
            }
            endAdornment={
              loadingData === true && <CircularProgress color="secondary" />
            }
          />

          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <TextField
              select
              id="sample-select"
              value={sampleStatus}
              onChange={(e) => setSampleStatus(e.target.value)}
              label="Sample"
            >
              <MenuItem value={"all"}>ALL</MenuItem>
              <MenuItem value={"0"}>No</MenuItem>
              <MenuItem value={"1"}>One</MenuItem>
              <MenuItem value={"2"}>Two</MenuItem>
            </TextField>

            <TextField
              select
              id="sub-select"
              value={subStatus}
              onChange={(e) => setSubStatus(e.target.value)}
              label="Subscription"
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"active"}>Active</MenuItem>
              <MenuItem value={"paused"}>Paused</MenuItem>
              <MenuItem value={"none"}>None</MenuItem>
            </TextField>

            <TextField
              select
              id="balance-gt-select"
              value={balanceGt}
              onChange={(e) => setBalanceGt(e.target.value)}
              label="Balance"
            >
              <MenuItem value={-500}>&gt;= -500</MenuItem>
              <MenuItem value={0}>&gt;= 0</MenuItem>
              <MenuItem value={1}>&gt; 0</MenuItem>
              <MenuItem value={500}>&gt;= 500</MenuItem>
              <MenuItem value={1000}>&gt;= 1000</MenuItem>
              <MenuItem value={2000}>&gt;= 2000</MenuItem>
            </TextField>
            <TextField
              select
              id="rech-gt-select"
              value={rechargeGt}
              onChange={(e) => setRechargeGt(e.target.value)}
              label="Recharge"
            >
              <MenuItem value={"all"}>ALL</MenuItem>
              <MenuItem value={"1"}>&gt; 0</MenuItem>
              <MenuItem value={"500"}>&gt;= 500</MenuItem>
              <MenuItem value={"1000"}>&gt;= 1000</MenuItem>
              <MenuItem value={"2000"}>&gt;= 2000</MenuItem>
              <MenuItem value={"3000"}>&gt;= 3000</MenuItem>
              <MenuItem value={"5000"}>&gt;= 5000</MenuItem>
            </TextField>

            <TextField
              select
              id="active-select"
              value={activeStatus}
              onChange={(e) => setActiveStatus(e.target.value)}
              label="Activity"
            >
              <MenuItem value={"true"}>Active</MenuItem>
              <MenuItem value={"false"}>Inactive</MenuItem>
              <MenuItem value={"all"}>All</MenuItem>
            </TextField>
            <TextField
              select
              id="created-select"
              value={createdStatus}
              onChange={(e) => setCreatedStatus(e.target.value)}
              label="Created On"
            >
              <MenuItem value={"all"}>ALL</MenuItem>
              <MenuItem value={"today"}>Today</MenuItem>
              <MenuItem value={"yesterday"}>Yesterday</MenuItem>
              <MenuItem value={"2days"}>
                {getDateStringSync(Date.now() - 2 * TWENTY4HOURS)}
              </MenuItem>
              <MenuItem value={"3days"}>
                {getDateStringSync(Date.now() - 3 * TWENTY4HOURS)}
              </MenuItem>
              <MenuItem value={"4days"}>
                {getDateStringSync(Date.now() - 4 * TWENTY4HOURS)}
              </MenuItem>
              <MenuItem value={"5days"}>
                {getDateStringSync(Date.now() - 5 * TWENTY4HOURS)}
              </MenuItem>
              <MenuItem value={"week"}>Last 7 days</MenuItem>
              <MenuItem value={"month"}>Last One month</MenuItem>
              <MenuItem value={"older"}>Older</MenuItem>
            </TextField>
            <IconButton onClick={handleDownload} color="primary">
              <DownloadRounded />
            </IconButton>
          </Stack>
        </Stack>

        {filteredData.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        <b>{headCell.label}</b>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredData.map((item, index) => {
                    return (
                      <TableRow
                        hover
                        style={{
                          backgroundColor:
                            item.active === true ? "white" : "#ddd",
                        }}
                        onClick={() => onCustomerSelected(item)}
                      >
                        <TableCell>
                          <b>{index + 1}</b>
                        </TableCell>
                        <TableCell>
                          <b>{item.name || "-"}</b>
                        </TableCell>
                        <TableCell>{item?.address?.flat}</TableCell>

                        <TableCell>
                          <Label
                            variant="filled"
                            color={
                              item?.sample === 2
                                ? "success"
                                : item?.sample === 1
                                ? "warning"
                                : "error"
                            }
                          >
                            <b>{item?.sample}</b>
                          </Label>
                        </TableCell>

                        <TableCell>
                          <Label
                            variant="outlined"
                            color={item?.rech > 0 ? "success" : "error"}
                          >
                            <b>₹ {item?.rech}</b>
                          </Label>
                        </TableCell>

                        <TableCell>
                          <Label
                            variant="ghost"
                            color={
                              item?.sub === "active"
                                ? "success"
                                : item?.sub === "paused"
                                ? "warning"
                                : "error"
                            }
                          >
                            <b>{item?.sub?.toUpperCase()}</b>
                          </Label>
                        </TableCell>

                        <TableCell align="right">
                          <Label
                            variant="filled"
                            color={
                              item?.balance >= 150
                                ? "success"
                                : item?.balance > 0
                                ? "warning"
                                : "error"
                            }
                          >
                            ₹ {item?.balance || "0"}
                          </Label>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell colSpan={5}></TableCell>
                    <TableCell>
                      <b>Total</b>
                    </TableCell>
                    <TableCell align="right">
                      <b>₹ {bal}</b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}

        {customerData.length === 0 && loadingData === false && (
          <div>
            <Typography gutterBottom align="center" variant="subtitle1">
              No data here
            </Typography>
          </div>
        )}
      </Container>
    </Card>
  );
};
