import { Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { SupportSocketProvider } from "src/contexts/SupportSocketContext";

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
}));

// ----------------------------------------------------------------------

export default function SupportLayout() {
  return (
    <RootStyle>
      <SupportSocketProvider>
        <MainStyle>
          <Outlet />
        </MainStyle>
      </SupportSocketProvider>
    </RootStyle>
  );
}
