import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import Select from "@mui/material/Select";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Grid,
  Box,
  InputAdornment,
  MenuItem,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddAdminManager({ handleM1Close }) {
  const [error, setError] = useState();

  const { getAccessToken, allFranchisees } = useAuth();

  const addDispenser = async (values) => {
    const ret = await dataPost(
      `/api/managers/coreact/add`,
      await getAccessToken(),
      {
        franchisee: values.franchisee,
        category: values.category,
        name: values.name,
        phone: `+91${values.phone}`,
      }
    );
    if (ret.success === true) {
      alert("Manager added");
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    franchisee: Yup.string().required(),
    category: Yup.string().required("Enter category!"),
    name: Yup.string().required("Enter name!"),
    phone: Yup.number()
      .required("Enter 10 digit number")
      .max(9999999999)
      .min(1111111111),
  });
  const formik = useFormik({
    initialValues: {
      franchisee: "",
      category: "",
      name: "",
      phone: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await addDispenser(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Add Manager
          </Typography>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Stack spacing={3}></Stack>
          </Grid>
        </Grid>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={3}>
                  <Select
                    id="fr-select"
                    {...getFieldProps("franchisee")}
                    MenuProps={MenuProps}
                    startAdornment={
                      <InputAdornment position="start">
                        <Box sx={{ color: "text.disabled" }}>Franchise</Box>
                      </InputAdornment>
                    }
                  >
                    {allFranchisees.map((item) => (
                      <MenuItem value={item._id}>{item.name}</MenuItem>
                    ))}
                  </Select>

                  <Select
                    id="cat-select"
                    {...getFieldProps("category")}
                    MenuProps={MenuProps}
                    startAdornment={
                      <InputAdornment position="start">
                        <Box sx={{ color: "text.disabled" }}>Category</Box>
                      </InputAdornment>
                    }
                  >
                    <MenuItem value={"plant"}>Plant</MenuItem>
                    <MenuItem value={"crm"}>CRO / CRM</MenuItem>
                    <MenuItem value={"crme"}>CRO Manager</MenuItem>
                    <MenuItem value={"transport"}>Transport</MenuItem>
                    <MenuItem value={"other"}>Other</MenuItem>
                  </Select>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Name"
                    {...getFieldProps("name")}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Phone"
                    {...getFieldProps("phone")}
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                  />
                </Stack>
              </Grid>
            </Grid>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
