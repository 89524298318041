import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";

import { UploadRounded } from "@mui/icons-material";

import {
  Card,
  Container,
  Stack,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import {
  getDayStart,
  getDayEnd,
  getMonthStartAndEndSync,
} from "src/utils/date-time-helper";

import { MobileDatePicker } from "@mui/x-date-pickers";

import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay));
}

export default function ExportCustomerDataSection({ customer }) {
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const mmm = getMonthStartAndEndSync(Date.now());

  const [startDate, setStartDate] = useState(mmm.start);
  const [endDate, setEndDate] = useState(mmm.end);

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/users/mgract/milkdata/export/user/${customer._id}/date/${startDate}/${endDate}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      await timeout(5000);
      window.open(ret.data);
    } else {
      window.alert(ret.message || "Export failed!");
    }

    setLoadingData(false);
  };

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          mt={2}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={"en-in"}
            >
              <MobileDatePicker
                value={moment(startDate)}
                onChange={(newValue) => {
                  if (newValue) {
                    const d1 = getDayStart(newValue.valueOf());
                    if (startDate !== d1) setStartDate(d1);
                  }
                }}
                closeOnSelect={true}
                format="DD/MM/YYYY"
                loading={loadingData}
              />
            </LocalizationProvider>

            <b>-</b>

            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={"en-in"}
            >
              <MobileDatePicker
                value={moment(endDate)}
                onChange={(newValue) => {
                  if (newValue) {
                    const d1 = getDayEnd(newValue.valueOf());
                    if (endDate !== d1) setEndDate(d1);
                  }
                }}
                closeOnSelect={true}
                format="DD/MM/YYYY"
                loading={loadingData}
              />
            </LocalizationProvider>
          </Stack>
          <LoadingButton
            size="large"
            type="submit"
            startIcon={<UploadRounded />}
            variant="contained"
            loading={loadingData}
            onClick={handleSearch}
          >
            Export
          </LoadingButton>
        </Stack>
      </Container>
    </Card>
  );
}
