import React from "react";
import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  CircularProgress,
} from "@mui/material";

import { getDateStringFullWithTimeSync } from "src/utils/date-time-helper";

const TABLE_HEAD = [
  { id: "date time", label: "Date & Time", alignRight: false },
  { id: "quantity", label: "Quantity", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "milktype", label: "Milktype", alignRight: false },
  { id: "cart", label: "Cart", alignRight: false },
];

export const CustomerMilkHistorySection = ({ customer }) => {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [page, setPage] = useState(0);

  const { getAccessToken, activeFranchise } = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const loadData = async () => {
    let url = `/api/milkhistory/mgract/query?user=${customer._id}&skip=${
      page * 10
    }&limit=${10}&sort=_id&dir=d`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, [page, activeFranchise]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card title="Milk History" sx={{ p: 1 }}>
      <Container>
        {loadingData === true && <CircularProgress />}

        {customerData.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        <b>{headCell.label}</b>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {customerData.map((item) => {
                    return (
                      <TableRow
                        hover
                        style={{
                          backgroundColor:
                            item.deleted === true ? "#ddd" : "white",
                        }}
                      >
                        <TableCell>
                          <b>{getDateStringFullWithTimeSync(item?.date)}</b>
                        </TableCell>

                        <TableCell>{item?.quantity} L</TableCell>
                        <TableCell>₹{item?.amount?.toFixed(2)}</TableCell>

                        <TableCell>
                          {item?.milk_type === 1 ? "BUF" : "COW"}
                        </TableCell>
                        <TableCell> {item?.cart?.name || "-"}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={-1}
              rowsPerPage={10}
              rowsPerPageOptions={[10]}
              page={page}
              onPageChange={handleChangePage}
            />
          </div>
        )}

        {customerData.length === 0 && loadingData === false && (
          <div>
            <Typography gutterBottom align="center" variant="subtitle1">
              Not found
            </Typography>
            <Typography variant="body2" align="center" sx={{ mb: 4 }}>
              No results here.
            </Typography>
          </div>
        )}
      </Container>
    </Card>
  );
};
