import {
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from "@mui/icons-material";
import {
  Card,
  Container,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useState } from "react";
import Page from "src/components/Page";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";
import { getWeekStartAndEndSync } from "src/utils/date-time-helper";

const PLDashboard = () => {
  const [wk, setWk] = useState(
    moment(Date.now()).subtract(1, "week").valueOf()
  );

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  //const [week, setWeek] = useState(wk);

  const { getAccessToken, activeFranchise } = useAuth();

  const PLdataLoad = async () => {
    setLoading(true);
    const wse = getWeekStartAndEndSync(wk);

    const url = `/api/expenses/expenses/mgract/details/query?date_gt=${wse.start}&date_lt=${wse.end}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setData(ret.data);
      console.log("data", data);
    } else {
      setData([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    PLdataLoad();
  }, [activeFranchise, wk]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page title="P&L Dashboard">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack>
            <Typography variant="h4">P&L Dashboard</Typography>
          </Stack>

          <Stack direction="row" alignItems="center">
            <IconButton
              sx={{ display: "inline-flex" }}
              onClick={() => {
                const newD = moment(wk).subtract(1, "week").valueOf();
                setWk(newD);
              }}
            >
              <KeyboardArrowLeftRounded />
            </IconButton>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={"en-in"}
            >
              <MobileDatePicker
                views={["month", "day"]}
                value={moment(wk)}
                onAccept={(newValue) => {
                  if (newValue) {
                    const d1 = newValue.valueOf();
                    if (wk !== d1) setWk(d1);
                  }
                }}
                sx={{ maxWidth: "130px" }}
                closeOnSelect={true}
                format="[Week:] DD MMM"
              />
            </LocalizationProvider>
            <IconButton
              sx={{ display: "inline-flex" }}
              onClick={() => {
                const newD = moment(wk).add(1, "week").valueOf();
                setWk(newD);
              }}
            >
              <KeyboardArrowRightRounded />
            </IconButton>
          </Stack>
        </Stack>

        <Card sx={{ padding: "20px", margin: "20px 0" }}>
          <div style={{ width: "100%" }}>
            <TableContainer>
              <table style={{ width: "100%" }}>
                <TableHead
                  sx={{ backgroundColor: "lightyellow", fontWeight: "bold" }}
                >
                  <TableRow>
                    <TableCell sx={{ padding: "16px", fontWeight: "bold" }}>
                      p & L
                    </TableCell>
                    {Array.from({ length: 7 }).map((_, i) => (
                      <TableCell
                        key={i}
                        sx={{ padding: "20px", fontWeight: "bold" }}
                      >
                        {moment(wk).add(i, "day").format("DD MMM")}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody></TableBody>

                <TableBody>
                  <TableRow>
                    <TableCell>Open Stock</TableCell>
                    {Array.from({ length: 7 }).map((_, i) =>
                      data[i] && data[i].open_stock ? (
                        <TableCell key={i}>{data[i].open_stock}</TableCell>
                      ) : (
                        <TableCell key={i}>_</TableCell>
                      )
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>Milk Sale</TableCell>
                    {Array.from({ length: 7 }).map((_, i) =>
                      data[i] && data[i].cart_sale ? (
                        <TableCell key={i}>{data[i].cart_sale}</TableCell>
                      ) : (
                        <TableCell key={i}>_</TableCell>
                      )
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>Bulk Sale</TableCell>
                    {Array.from({ length: 7 }).map((_, i) =>
                      data[i] && data[i].bulk_sale ? (
                        <TableCell key={i}> {data[i].bulk_sale} </TableCell>
                      ) : (
                        <TableCell key={i}>_</TableCell>
                      )
                    )}
                  </TableRow>

                  <TableRow>
                    <TableCell>Sale Revenue</TableCell>
                    {Array.from({ length: 7 }).map((_, i) =>
                      data[i] && data[i].sale_revenue ? (
                        <TableCell key={i}>{data[i].sale_revenue}</TableCell>
                      ) : (
                        <TableCell key={i}>_</TableCell>
                      )
                    )}
                  </TableRow>

                  <TableRow>
                    <TableCell>Farmer Collection</TableCell>
                    {Array.from({ length: 7 }).map((_, i) =>
                      data[i] && data[i].farmer_coll ? (
                        <TableCell key={i}>{data[i].farmer_coll}</TableCell>
                      ) : (
                        <TableCell key={i}>_</TableCell>
                      )
                    )}
                  </TableRow>

                  <TableRow>
                    <TableCell>Center Collection</TableCell>
                    {Array.from({ length: 7 }).map((_, i) =>
                      data[i] && data[i].center_coll ? (
                        <TableCell key={i}>{data[i].center_coll}</TableCell>
                      ) : (
                        <TableCell key={i}>_</TableCell>
                      )
                    )}
                  </TableRow>
                </TableBody>

                <TableRow>
                  <TableCell>COGS</TableCell>
                  {Array.from({ length: 7 }).map((_, i) =>
                    data[i] && data[i].cogs ? (
                      <TableCell key={i}>{data[i].center_sale}</TableCell>
                    ) : (
                      <TableCell key={i}>_</TableCell>
                    )
                  )}
                </TableRow>

                <TableRow>
                  <TableCell>Milk Transport</TableCell>
                  {Array.from({ length: 7 }).map((_, i) =>
                    data[i] && data[i].total_transport ? (
                      <TableCell key={i}>{data[i].total_transport}</TableCell>
                    ) : (
                      <TableCell key={i}>_</TableCell>
                    )
                  )}
                </TableRow>

                <TableRow>
                  <TableCell>Error</TableCell>
                  {Array.from({ length: 7 }).map((_, i) =>
                    data[i] && data[i].total_error ? (
                      <TableCell key={i}>{data[i].total_error}</TableCell>
                    ) : (
                      <TableCell key={i}>_</TableCell>
                    )
                  )}
                </TableRow>

                <TableRow>
                  <TableCell>Close Stock</TableCell>
                  {Array.from({ length: 7 }).map((_, i) =>
                    data[i] && data[i].close_stock ? (
                      <TableCell key={i}>{data[i].close_stock}</TableCell>
                    ) : (
                      <TableCell key={i}>_</TableCell>
                    )
                  )}
                </TableRow>
                <TableRow>
                  <TableCell>Cash In</TableCell>
                  {Array.from({ length: 7 }).map((_, i) =>
                    data[i] && data[i].cash_in ? (
                      <TableCell key={i}>{data[i].cash_in}</TableCell>
                    ) : (
                      <TableCell key={i}>_</TableCell>
                    )
                  )}
                </TableRow>

                <TableRow>
                  <TableCell>Online In</TableCell>
                  {Array.from({ length: 7 }).map((_, i) =>
                    data[i] && data[i].online_money ? (
                      <TableCell key={i}>{data[i].online_money}</TableCell>
                    ) : (
                      <TableCell key={i}>_</TableCell>
                    )
                  )}
                </TableRow>

                <TableRow>
                  <TableCell>Write off</TableCell>
                  {Array.from({ length: 7 }).map((_, i) =>
                    data[i] && data[i].write_off ? (
                      <TableCell key={i}>{data[i].write_off}</TableCell>
                    ) : (
                      <TableCell key={i}>_</TableCell>
                    )
                  )}
                </TableRow>

                <TableRow>
                  <TableCell>Maintenance EXP</TableCell>
                  {Array.from({ length: 7 }).map((_, i) =>
                    data[i] &&
                    data[i].expense &&
                    data[i].expense.category === "maintenance" ? (
                      <TableCell key={i}>{data[i].expense.total}</TableCell>
                    ) : (
                      <TableCell key={i}>_</TableCell>
                    )
                  )}
                </TableRow>

                <TableRow>
                  <TableCell>Kitchen EXP</TableCell>
                  {Array.from({ length: 7 }).map((_, i) =>
                    data[i] &&
                    data[i].expense &&
                    data[i].expense.category === "kitchen" ? (
                      <TableCell key={i}>{data[i].expense.total}</TableCell>
                    ) : (
                      <TableCell key={i}>_</TableCell>
                    )
                  )}
                </TableRow>

                <TableRow>
                  <TableCell>MISC EXP</TableCell>
                  {Array.from({ length: 7 }).map((_, i) =>
                    data[i] &&
                    data[i].expense &&
                    data[i].expense.category !== "maintenance" &&
                    data[i].expense.category !== "kitchen" ? (
                      <TableCell key={i}>{data[i].expense.total}</TableCell>
                    ) : (
                      <TableCell key={i}>_</TableCell>
                    )
                  )}
                </TableRow>
              </table>
            </TableContainer>
          </div>
        </Card>
      </Container>
    </Page>
  );
};

export default PLDashboard;
