import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  CircularProgress,
  Stack,
  IconButton,
} from "@mui/material";

import {
  getDayStartAndEndSync,
  TWENTY4HOURS,
  getTimeStringFullSync,
  getHour,
} from "src/utils/date-time-helper";

import { MobileDatePicker } from "@mui/x-date-pickers";

import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
  LocationOnRounded,
} from "@mui/icons-material";

import CartMilkoutChartComp from "../CartMilkoutChartComp";

const TABLE_HEAD = [
  { id: "date", label: "Time", alignRight: false },
  { id: "event", label: "Event", alignRight: false },
  { id: "quantity", label: "Quantity", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "log", label: "Log", alignRight: false },
  { id: "loc", label: "Loc", alignRight: false },
];

export default function CartMilkOuts({ cart }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchisee } = useAuth();

  const dayStartEnd = getDayStartAndEndSync(Date.now());
  const [startDate, setStartDate] = useState(dayStartEnd.start);

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/cartmilkoutdet/mgract/query?sort=date&dir=d&date_gt=${startDate}&date_lt=${
      startDate + TWENTY4HOURS - 1
    }&cart=${cart._id}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchisee);
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [startDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const graphData = {};

  let avTime = 0;
  let avCount = 0;
  customerData.forEach((item) => {
    if (item.event === "dispense" && item.status == 1) {
      const hr1 = parseInt(getHour(item.time));
      let hr = "";
      if (hr1 <= 8) hr = `0${hr1} - 0${hr1 + 1}`;
      else if (hr1 === 9) hr = `0${hr1} - ${hr1 + 1}`;
      else hr = `${hr1} - ${hr1 + 1}`;

      if (graphData[hr]) graphData[hr] += item.quantity;
      else graphData[hr] = item.quantity;

      let av = (item.endtime - item.time) / item.quantity;
      console.log(av);
      if (av < 25) {
        avTime += item.endtime - item.time;
        avCount += item.quantity;
      }
    }
  });

  avTime = avTime / avCount;

  let lastTime = 0;

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h5" gutterBottom>
            Milk Dispense Data
          </Typography>

          {loadingData === true && <CircularProgress color="secondary" />}

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <IconButton
              sx={{ display: "inline-flex", mr: 1 }}
              onClick={() => {
                setStartDate(startDate - TWENTY4HOURS);
              }}
            >
              <KeyboardArrowLeftRounded />
            </IconButton>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={"en-in"}
            >
              <MobileDatePicker
                value={moment(startDate)}
                onChange={(newValue) => {
                  if (newValue) {
                    const d1 = getDayStartAndEndSync(newValue.valueOf()).start;
                    if (startDate !== d1) setStartDate(d1);
                  }
                }}
                sx={{ maxWidth: "130px" }}
                disableFuture
                closeOnSelect
                showDaysOutsideCurrentMonth
                format="DD/MM/YYYY"
                loading={loadingData}
              />
            </LocalizationProvider>

            <IconButton
              sx={{ display: "inline-flex", ml: 1 }}
              onClick={() => {
                setStartDate(startDate + TWENTY4HOURS);
              }}
            >
              <KeyboardArrowRightRounded />
            </IconButton>
          </Stack>
        </Stack>

        <CartMilkoutChartComp data={graphData} />

        {customerData.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {customerData.map((item) => {
                    const color =
                      (item.status === -1 || item.status === 2) &&
                      "lightsalmon";

                    let inVal = 0;
                    if (
                      lastTime !== 0 &&
                      item.time - lastTime > 15 * 60 * 1000
                    ) {
                      inVal = (item.time - lastTime) / 60000;
                    }

                    lastTime = item.time;

                    let diffTime = Math.abs(
                      (item.endtime - item.time) / item.quantity - avTime
                    );
                    return (
                      <>
                        {inVal > 0 && (
                          <TableRow style={{ backgroundColor: "indianred" }}>
                            <TableCell sx={{ color: "white.main" }}>
                              <b>+ {inVal.toFixed(0)} minutes</b>
                            </TableCell>
                            <TableCell sx={{ color: "white.main" }}>
                              <b>{inVal > 30 && "Major Issue"}</b>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        )}
                        <TableRow hover style={{ backgroundColor: color }}>
                          <TableCell sx={{ color: "black" }}>
                            <b>{getTimeStringFullSync(item.time)}</b>
                          </TableCell>
                          <TableCell>
                            {item.event && item.event.toUpperCase()}
                          </TableCell>
                          <TableCell>
                            {(item.event === "dispense" &&
                              `${item.quantity} mL (${item.interrupt} mL)`) ||
                              "-"}
                          </TableCell>
                          <TableCell>
                            {(item.status &&
                              (item.status === 0
                                ? "STARTED"
                                : item.status === 1
                                ? "COMPLETED"
                                : "INTERRUPTED")) ||
                              "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: diffTime > 1 ? "lightcoral" : "#fff",
                            }}
                          >
                            {(item.endtime &&
                              `${((item.endtime - item.time) / 1000).toFixed(
                                1
                              )} sec`) ||
                              item.log}
                          </TableCell>
                          {item.location &&
                            item.location.lat !== 0 &&
                            item.location.lng !== 0 && (
                              <TableCell
                                onClick={() => {
                                  const url = `https://maps.google.com/?q=${item.location.lat},${item.location.lng}`;
                                  window.open(url);
                                }}
                              >
                                <LocationOnRounded />
                              </TableCell>
                            )}
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </Container>
    </Card>
  );
}
