import {
  Grid,
  Container,
  Typography,
  Stack,
  Button,
  Modal,
  Card,
  Select,
  MenuItem,
  Box,
  InputAdornment,
  OutlinedInput,
  CircularProgress,
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  TablePagination,
  CardActionArea,
} from "@mui/material";

import Page from "../../components/Page";

import { useEffect, useState } from "react";
import { ArrowRightRounded, SearchRounded } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { dataFetch } from "src/utils/data-fetch";
import { useAuth } from "src/contexts/AuthContext";
import { getDateStringFullSync } from "src/utils/date-time-helper";
import AddCampaignModal from "./AddCampaign";
import CampaignDetailsPage from "./det/CampaignDetails";
import ShortLinksPage from "./sl/ShortLinksPage";
import AppBannersPage from "./banners/AppBannersPage";
import NotificationsPage from "../notif/NotificationsPage";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "objective", label: "Objective", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "start_date", label: "Start Date", alignRight: false },
  { id: "end_date", label: "End Date", alignRight: false },
];

export default function CampaignPage() {
  const [modalOpen, setModalOpen] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [selectedField, setSelectedField] = useState("name");
  const [cartData, setCartData] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [search, setSearch] = useState("");
  const { getAccessToken, activeFranchise } = useAuth();
  const [cart, setCart] = useState("all");
  const [page, setPage] = useState(0);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [bannersDialog, setBannersDialog] = useState(false);
  const [notificationDialog, setNotificationDialog] = useState(false);

  const cartDatafetch = async () => {
    setLoadingData(true);

    let url = `/api/milkcarts/mgract/query`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCartData(ret.data);
    } else {
      console.log("error in fetching data");
    }

    setLoadingData(false);
  };

  const campaignDatafetch = async () => {
    setLoadingData(true);

    let url = `/api/campaigns/mgract/campaigns/query?skip=${
      page * 5
    }&limit=${5}&sort=_id&dir=d`;

    if (cart !== "all") {
      url += `cart=${cart}`;
    }

    if (search && search.length > 0) {
      url += `&search=${search}&field=${selectedField}`;
    }

    console.log(url);

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCampaignData(ret.data);
      console.log(ret.data);
    } else {
      console.log("error in fetching data");
    }

    setLoadingData(false);
  };

  let timer = "";

  const handleChangeQuery = (event) => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      setCampaignData([]);
      setPage(0);
      setSearch(event.target.value);
    }, 1000);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleCartChange = (event) => {
    setCart(event.target.value);
  };

  const handleFieldChange = (event) => {
    setSelectedField(event.target.value);
  };

  const closeCampaignModal = () => {
    setModalOpen(false);
    campaignDatafetch();
  };

  const addCampaignClicked = () => {
    setModalOpen(true);
  };

  const handleCampaignSelected = (campaign) => {
    setSelectedCampaign(campaign);
    setDrawerOpen(true);
  };

  useEffect(() => {
    cartDatafetch();
    campaignDatafetch();
  }, [cart, search, selectedField, page]);

  const [shortLinksDialog, setShortLinksDialog] = useState(false);

  return (
    <Page title="Campaign Dashboard">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography variant="h4">Campaign Dashboard</Typography>
          <Button variant="contained" onClick={addCampaignClicked}>
            Add Campaign
          </Button>
        </Stack>

        <Grid container spacing={2} mb={2}>
          <Grid item xs={6} md={3} lg={3}>
            <Card
              sx={{
                backgroundColor: "primary.lighter",
                color: "white.main",
                boxShadow: "none",
              }}
            >
              <CardActionArea
                sx={{ p: 2 }}
                onClick={() => setShortLinksDialog(true)}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h6">Short Links</Typography>
                  <ArrowRightRounded />
                </Stack>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={6} md={3} lg={3}>
            <Card
              sx={{
                backgroundColor: "primary.lighter",
                color: "white.main",
                boxShadow: "none",
              }}
            >
              <CardActionArea
                sx={{ p: 2 }}
                onClick={() => setBannersDialog(true)}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h6">Banners</Typography>
                  <ArrowRightRounded />
                </Stack>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <Card
              sx={{
                backgroundColor: "primary.lighter",
                color: "white.main",
                boxShadow: "none",
              }}
            >
              <CardActionArea
                sx={{ p: 2 }}
                onClick={() => setNotificationDialog(true)}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h6">Notifications</Typography>
                  <ArrowRightRounded />
                </Stack>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>

        <Card title="Search People">
          <Container>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
              mt={2}
              spacing={2}
            >
              {/* Cart Select */}
              <Select
                id="cart-select"
                value={cart}
                onChange={handleCartChange}
                sx={{ minWidth: 200 }}
              >
                <MenuItem value={"all"}>All</MenuItem>
                {cartData.map((cart) => (
                  <MenuItem key={cart._id} value={cart._id}>
                    {cart.name}
                  </MenuItem>
                ))}
              </Select>
              <SearchStyle
                placeholder="Search Campaign..."
                onChange={handleChangeQuery}
                startAdornment={
                  <InputAdornment position="start">
                    <Box sx={{ color: "text.disabled" }}>
                      <SearchRounded />
                    </Box>
                  </InputAdornment>
                }
                endAdornment={
                  loadingData === true && <CircularProgress color="secondary" />
                }
                sx={{ width: "50%" }}
              />

              <Select
                id="field-select"
                value={selectedField}
                onChange={handleFieldChange}
                label="Search In"
                sx={{ minWidth: 200 }}
              >
                <MenuItem value={"name"}>Name</MenuItem>
                <MenuItem value={"objective"}>Objective</MenuItem>
              </Select>
            </Stack>

            {/* Campaign Table */}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TableContainer sx={{ minHeight: 100 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {TABLE_HEAD.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.alignRight ? "right" : "left"}
                            sortDirection={false}
                          >
                            {headCell.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {campaignData.map((item) => {
                        return (
                          <TableRow
                            hover
                            onClick={() => handleCampaignSelected(item)}
                            style={{
                              backgroundColor:
                                item.status === 0 ? "#ddd" : "white",
                            }}
                          >
                            <TableCell>
                              <b>{item ? item.name : "--"}</b>
                            </TableCell>
                            <TableCell>
                              {item ? item.objective : "--"}
                            </TableCell>
                            <TableCell>
                              {item.status == 0 ? "Inactive" : "Active"}
                            </TableCell>
                            <TableCell>
                              {item.start_date
                                ? getDateStringFullSync(item.start_date)
                                : "--"}
                            </TableCell>
                            <TableCell>
                              {item.end_date
                                ? getDateStringFullSync(item.end_date)
                                : "--"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* Pagination */}
                <TablePagination
                  component="div"
                  count={-1}
                  rowsPerPage={5}
                  rowsPerPageOptions={[5]}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </Grid>
            </Grid>
          </Container>
        </Card>
      </Container>

      <Modal open={modalOpen} onClose={closeCampaignModal}>
        <AddCampaignModal handleM1Close={closeCampaignModal} />
      </Modal>

      <CampaignDetailsPage
        campaign={selectedCampaign}
        handleClose={() => setDrawerOpen(false)}
        drawerOpen={drawerOpen}
      />

      {shortLinksDialog === true && (
        <ShortLinksPage
          drawerOpen={shortLinksDialog}
          handleClose={() => setShortLinksDialog(false)}
        />
      )}

      {bannersDialog === true && (
        <AppBannersPage
          drawerOpen={bannersDialog}
          handleClose={() => setBannersDialog(false)}
        />
      )}

      {notificationDialog === true && (
        <NotificationsPage
          open={notificationDialog}
          onClose={() => setNotificationDialog(false)}
        />
      )}

      {/* 
  
        <Modal
          open={birthdayModalOpen}
          onClose={() => setBirthdayModalOpen(false)}
        >
          <BirthdaysModal
            handleClose={() => setBirthdayModalOpen(false)}
            onEmpSelected={handleEmpSelected}
          />
        </Modal>
  
        <FullScreenEmpDetailsDialog
          emp={currEmp}
          handleClose={() => setDrawerOpen(false)}
          drawerOpen={drawerOpen}
        /> */}
    </Page>
  );
}
