import React from "react";
import { useState, useEffect } from "react";

import {
  Card,
  Table,
  Stack,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Modal,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
} from "@mui/material";

import { AddCircleOutlined, DeleteRounded } from "@mui/icons-material";

import { useAuth } from "src/contexts/AuthContext";
import { dataFetch, dataPatch } from "src/utils/data-fetch";
import AddAcessControlModal from "./AddAcessControlModal";

const TABLE_HEAD = [
  { id: "name", label: "Franchisee Name", alignRIght: false },
  { id: "role", label: "Roles", alignRIght: false },
];

export default function AdminAcessControl({ manager, handleM1Close }) {
  const [balanceData, setBalanceData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const { getAccessToken, activeFranchise, allFranchisees } = useAuth();

  const [accessControlModal, setAcessControlModal] = useState(false);

  const closeAcessControlModal = () => {
    setAcessControlModal(false);
    fetchAccessData();
  };

  useEffect(() => {
    fetchAccessData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAccessData = async () => {
    setLoadingData(true);

    const ret = await dataFetch(
      `/api/managers/coreact/manager/${manager._id}/access`,
      await getAccessToken(),
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      setBalanceData(ret.data);
    }
    setLoadingData(false);
  };

  const handleDelete = (item) => {
    deleteAccessControl(item.fr);
  };

  const deleteAccessControl = async (fr) => {
    setLoadingData(true);

    let url = `/api/managers/coreact/manager/${manager._id}/access`;

    const ret = await dataPatch(url, await getAccessToken(), {
      franchisee: fr,
    });

    if (ret.success === true) {
      alert(ret.message);
    } else {
      alert(ret.message);
    }
    setLoadingData(false);

    fetchAccessData();
  }; // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card title="Manager Access Control" sx={{ pt: 2, pb: 2 }}>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h5" gutterBottom>
            Managers Acess Control
          </Typography>
          <Stack direction="row">
            <AddCircleOutlined
              variant="contained"
              onClick={() => setAcessControlModal(true)}
            >
              Add Access Control
            </AddCircleOutlined>
          </Stack>
        </Stack>

        {loadingData === true && <CircularProgress />}
        {loadingData === false && balanceData.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.alignRIght ? "right" : "left"}
                      sortDirection={false}
                    >
                      <b>{headCell.label}</b>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {balanceData.map((item) => {
                  let fr;
                  for (const f of allFranchisees) {
                    if (f._id === item.fr) {
                      fr = f;
                      break;
                    }
                  }
                  return (
                    <TableRow hover>
                      <TableCell>{fr?.name || "-"}</TableCell>

                      <TableCell>{item?.roles?.join(",") || "-"}</TableCell>
                      <TableCell
                        onClick={() => handleDelete(item)}
                        align="right"
                      >
                        <DeleteRounded />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2" align="center" sx={{ mt: 3, mb: 2 }}>
            No Data found
          </Typography>
        )}
      </Container>
      <Modal open={accessControlModal} onClose={closeAcessControlModal}>
        <AddAcessControlModal
          handleM1Close={closeAcessControlModal}
          manager={manager}
        />
      </Modal>
    </Card>
  );
}
