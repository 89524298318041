import { Button, Container, Grid, Stack } from "@mui/material";
import React, { useState } from "react";

import Page from "src/components/Page";

import CustomerSearch from "./customer-search";
import CustomerDetailsPage from "src/pages/home/det/CustomerDetailsPage";
import CartList from "./carts-list";
import { AddCircleRounded, VisibilityRounded } from "@mui/icons-material";
import AddUserPage from "./add-user";
import MyCustomers from "./my-customers";

export default function CROHome() {
  const openCustomerDetailsPage = (customer) => {};

  const [selectedCust, setSelectedCust] = useState(null);
  const [detailsDrawerState, setDetailsDrawerState] = useState(false);

  const [addCustomerState, setAddCustomerState] = useState(false);
  const [myCustomersState, setMyCustomersState] = useState(false);

  return (
    <Page title="CRO Dashboard">
      <Container>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2} justifyContent={"space-between"}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<VisibilityRounded />}
              onClick={() => setMyCustomersState(true)}
            >
              My Customers
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircleRounded />}
              onClick={() => setAddCustomerState(true)}
            >
              Add Customer
            </Button>
          </Stack>

          <CustomerSearch onUserClick={openCustomerDetailsPage} />

          <CartList onCartSelected={() => {}} />
        </Stack>
      </Container>

      <CustomerDetailsPage
        customer={selectedCust}
        handleClose={() => setDetailsDrawerState(false)}
        drawerOpen={detailsDrawerState}
      />

      <MyCustomers
        handleClose={() => setMyCustomersState(false)}
        drawerOpen={myCustomersState}
        onUserClick={(customer) => {
          setSelectedCust(customer);
          setDetailsDrawerState(true);
        }}
      />

      {addCustomerState && (
        <AddUserPage
          handleClose={() => setAddCustomerState(false)}
          drawerOpen={addCustomerState}
        />
      )}
    </Page>
  );
}
