import React, { useState, useEffect } from "react";
import { dataFetch } from "src/utils/data-fetch";
import { useAuth } from "src/contexts/AuthContext";

import { Modal, Card } from "@mui/material";

import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tooltip,
  TablePagination,
} from "@mui/material";
import ComplaintDetails from "./ComplaintDetails";

const TABLE_HEAD = [
  { id: "employee", label: "Employee", alignRight: false },
  { id: "complaint", label: "Conplaint", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "date", label: "Date", alignRight: false },
];

export const formatDate = (date) => {
  const options = {
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return new Date(date).toLocaleDateString("en-US", options);
};

export default function ComplaintsList(date) {
  const [complaints, setComplaints] = useState([]);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { getAccessToken, activeFranchise } = useAuth();
  const [complaintModal, setComplaintModal] = useState(null);

  const closeComplaintModal = () => {
    setComplaintModal(null);
  };

  const openComplaintModal = (id) => {
    setComplaintModal(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const loadComplaints = async () => {
    setLoading(true);

    const url = `/api/complaints/mgract/query?date_gt=${
      date.startDate
    }&date_lt=${date.endDate}&limit=${rowsPerPage}&skip=${rowsPerPage * page}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setComplaints(ret.data);
    } else {
      setComplaints([]);
    }

    setLoading(false);
  };

  useEffect(() => {
    loadComplaints();
  }, [activeFranchise, date, page]);

  return (
    <Card>
      {complaints.length > 0 && (
        <div>
          <TableContainer sx={{ minHeight: 100 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.alignRight ? "right" : "left"}
                      sortDirection={false}
                    >
                      {headCell.label}{" "}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {complaints.map((item, index) => {
                  const color =
                    item.status === "resolved"
                      ? "white"
                      : ["pending", "under review"].includes(item.status)
                      ? "lightcyan"
                      : "lightsalmon";
                  return (
                    <TableRow
                      key={index}
                      hover
                      onClick={() => openComplaintModal(item._id)}
                      style={{ backgroundColor: color }}
                    >
                      <TableCell>
                        <b> {item.employee.name}</b>
                      </TableCell>

                      <TableCell>{item.complaint}</TableCell>

                      <TableCell>{item.status}</TableCell>

                      <TableCell>{formatDate(item.date)}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={-1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[5]}
          />
        </div>
      )}

      <Modal open={complaintModal ? true : false} onClose={closeComplaintModal}>
        <ComplaintDetails id={complaintModal} />
      </Modal>
    </Card>
  );
}
