import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch, dataPost } from "src/utils/data-fetch";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Stack,
  Modal,
  IconButton,
  Button,
} from "@mui/material";
import { getDateStringFullWithMonthSync } from "src/utils/date-time-helper";
import ApprovePayoutModal from "./ApprovePayoutModal";
import { RefreshRounded } from "@mui/icons-material";

const TABLE_HEAD = [
  { id: "date", label: "Date", alignRight: false },
  { id: "farmer", label: "Farmer", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "mode", label: "Mode", alignRight: false },
  { id: "by", label: "By", alignRight: false },
];

export default function PendingPayoutApprovals({ onFarmerClick, counter }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [page, setPage] = useState(0);

  const { getAccessToken, activeFranchise } = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = async () => {
    setLoadingData(true);
    let url = `/api/farmerwallet/mgract/transactions/query?skip=${
      page * 5
    }&limit=${5}&sort=_id&dir=a&status=initiated&category=4`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [page, activeFranchise, counter]); // eslint-disable-line react-hooks/exhaustive-deps

  const [selectedAcc, setSelectedAcc] = useState(null);

  const handleEntryClick = (fa) => {
    setSelectedAcc(fa);
  };

  const closeApproveModal = () => {
    setSelectedAcc(null);
  };

  return (
    <Card title="Pending Payout Approvals">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h5" gutterBottom>
            Pending Payout Approvals
          </Typography>

          {(loadingData === true && <CircularProgress color="secondary" />) || (
            <IconButton onClick={handleSearch}>
              <RefreshRounded />
            </IconButton>
          )}
        </Stack>
        <TableContainer sx={{ minHeight: 100 }}>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.alignRight ? "right" : "left"}
                    sortDirection={false}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {customerData.length > 0 && loadingData === false && (
              <TableBody>
                {customerData.map((item) => {
                  return (
                    <TableRow hover onClick={() => handleEntryClick(item)}>
                      <TableCell>
                        {getDateStringFullWithMonthSync(
                          parseInt(item._id.substring(0, 8), 16) * 1000
                        )}
                      </TableCell>
                      <TableCell
                        onClick={(e) => {
                          onFarmerClick(item?.farmer);
                          e.stopPropagation();
                        }}
                      >
                        <b>{(item.farmer && item.farmer.name) || "-"}</b>
                      </TableCell>
                      <TableCell>₹ {item.amount}</TableCell>
                      <TableCell>
                        {item.mode && item.mode.toUpperCase()}
                      </TableCell>
                      <TableCell>
                        {(item.transacted_by && item.transacted_by.name) || "-"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={-1}
          rowsPerPage={5}
          page={page}
          rowsPerPageOptions={[5]}
          onPageChange={handleChangePage}
        />
      </Container>

      <Modal open={selectedAcc != null} onClose={closeApproveModal}>
        <ApprovePayoutModal
          handleM1Close={closeApproveModal}
          poutId={selectedAcc?._id}
        />
      </Modal>
    </Card>
  );
}
