import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import {
  Card,
  CircularProgress,
  TablePagination,
  Container,
} from "@mui/material";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";

import { dataFetch } from "src/utils/data-fetch";
import { getDateStringFullWithTimeSync } from "src/utils/date-time-helper";

export default function TimelineCard({ vehicleId }) {
  const [error, setError] = useState();
  const [currentData, setCurrentData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [page, setPage] = useState(0);

  const { getAccessToken, activeFranchise } = useAuth();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const load = async () => {
    setLoadingData(true);
    const ret = await dataFetch(
      `/api/carttimeline/mgract/query?vehicle=${vehicleId}&skip=${
        page * 10
      }&limit=${10}&sort=_id&dir=d`,
      await getAccessToken(),
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      setCurrentData(ret.data);
    } else {
      console.log(ret.message);
      setError(ret.message + " - " + ret.code);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    load();
  }, [page, activeFranchise]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card sx={{ p: 4, width: "60%" }}>
      {loadingData === true && <CircularProgress />}
      <Container>
        <Timeline position="left">
          {currentData &&
            currentData.map((item) => (
              <TimelineItem>
                <TimelineOppositeContent>
                  {(() => {
                    if (item.update_type === "cleanliness") {
                      let tot = (item?.cleanliness?.length || 0) * 2;
                      let score = 0;
                      (item.cleanliness || []).forEach((element) => {
                        score += element.status;
                      });

                      return (
                        <>
                          Rating: {score}/{tot}
                        </>
                      );
                    } else if (item.update_type === "maintenance") {
                      return (
                        <>
                          {item?.maintenance?.description}
                          <br />
                          Maintenance Cost : ₹ {item?.maintenance?.cost}
                        </>
                      );
                    } else if (item.update_type === "employee") {
                      return (
                        <>
                          {item?.from_name}
                          <br />
                          {item?.to_name}
                        </>
                      );
                    } else if (item.update_type === "vehicle")
                      return (
                        <>
                          {item?.from_name}
                          <br />
                          {item?.to_name}
                        </>
                      );
                    else if (item.update_type === "name")
                      return (
                        <>
                          {item?.from_name}
                          <br />
                          {item?.to_name}
                        </>
                      );
                    else if (item.update_type === "logout")
                      return <>{"Logged out"}</>;
                    else if (item.update_type === "remarks")
                      return <>{item?.remarks}</>;
                    else {
                      return <>{"No Update Type"}</>;
                    }
                  })()}
                </TimelineOppositeContent>

                <TimelineSeparator>
                  <TimelineDot color="success" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  {getDateStringFullWithTimeSync(
                    parseInt(item?._id.substring(0, 8), 16) * 1000
                  )}
                  <br />
                  {item?.update_type.toUpperCase()}
                </TimelineContent>
              </TimelineItem>
            ))}
        </Timeline>

        {/* {error && (
            <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )} */}
      </Container>
      <TablePagination
        component="div"
        count={-1}
        rowsPerPage={10}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[10]}
      />
    </Card>
  );
}
