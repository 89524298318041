import {
  AddRounded,
  AddShoppingCartRounded,
  ChevronRightRounded,
  RefreshRounded,
  SearchRounded,
} from "@mui/icons-material";

import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import MakeFullScreen from "src/components/MakeFullScreen";

import { styled } from "@mui/material/styles";
import { dataFetch } from "src/utils/data-fetch";
import { useAuth } from "src/contexts/AuthContext";
import { useSnackbar } from "src/contexts/SnackbarContext";
import AddItem from "./AddItem";
import Label from "../../crm/Label";
import ItemsDetails from "./ItemDetails";
import IssueItems from "./IssueItems";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "check", label: "", alignRight: false },
  { id: "image", label: "", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "category", label: "Category", alignRight: false },
  { id: "stock", label: "Stock", alignRight: false },
  { id: "location", label: "Location", alignRight: false },
  { id: "action", label: "", alignRight: false },
];

export default function ItemsCatWise({ open, onClose, category }) {
  if (!category) onClose();

  const [items, setItems] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [search, setSearch] = useState("");

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/inventory/mgract/items/query?category=${category?.name}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setItems(ret.data);
    } else {
      showSnackbar(ret.message, "error");
    }

    setLoadingData(false);
  };

  useEffect(() => {
    handleSearch();
  }, []);

  let filtered = items.filter((item) => {
    if (search === "") return true;
    return (
      item.name.toLowerCase().includes(search) ||
      item.code.toLowerCase().includes(search) ||
      item.details.toLowerCase().includes(search)
    );
  });

  const [addItemModalState, setAddItemModalState] = useState(false);
  const [issueItemsModalState, setIssueItemsModalState] = useState(false);
  const [selectedItemModal, setSelectedItemModal] = useState(null);

  const [checkedItems, setCheckedItems] = useState({});

  return (
    <MakeFullScreen
      title={`${category?.name} (${category?.code})` || "Items"}
      open={open}
      onClose={onClose}
      header={[
        <Button
          variant="contained"
          color="white"
          onClick={() => setIssueItemsModalState(true)}
        >
          Issue Items
        </Button>,
        <Button
          variant="contained"
          color="white"
          onClick={() => setAddItemModalState(true)}
        >
          <AddRounded />
        </Button>,
      ]}
    >
      <Container sx={{ minHeight: 600 }}>
        <SearchStyle
          placeholder="Search Items..."
          onChange={(e) => setSearch(e.target.value?.toLowerCase())}
          startAdornment={
            <InputAdornment position="start">
              <Box sx={{ color: "text.disabled" }}>
                <SearchRounded />
              </Box>
            </InputAdornment>
          }
          endAdornment={
            (loadingData === true && (
              <CircularProgress color="secondary" />
            )) || (
              <IconButton onClick={handleSearch}>
                <RefreshRounded />
              </IconButton>
            )
          }
          autoFocus={true}
        />

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.alignRight ? "right" : "left"}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filtered.map((item) => (
                <SingleInventory
                  item={item}
                  onClick={() => setSelectedItemModal(item)}
                  checkedItems={checkedItems}
                  setCheckedItems={setCheckedItems}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {addItemModalState && (
          <AddItem
            open={addItemModalState}
            onClose={() => {
              setAddItemModalState(false);
              handleSearch();
            }}
            duplicate={{ category: category }}
          />
        )}
        {issueItemsModalState && (
          <IssueItems
            open={true}
            onClose={() => {
              setIssueItemsModalState(false);
              handleSearch();
            }}
            category={category}
            checkedItems={checkedItems}
          />
        )}

        {selectedItemModal && (
          <ItemsDetails
            open={true}
            onClose={() => setSelectedItemModal(null)}
            item={selectedItemModal}
          />
        )}
      </Container>
    </MakeFullScreen>
  );
}

const SingleInventory = ({ item, onClick, checkedItems, setCheckedItems }) => {
  return (
    <Tooltip title={item.details}>
      <TableRow
        sx={{ cursor: "pointer" }}
        hover
        onClick={onClick}
        key={item._id}
      >
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={Object.keys(checkedItems).includes(item._id)}
            onChange={(e) => {
              if (e.target.checked === true) {
                setCheckedItems((prev) => ({
                  ...prev,
                  [item._id]: {
                    _id: item._id,
                    name: item.name,
                    code: item.code,
                    unit: item.unit,
                  },
                }));
              } else {
                setCheckedItems((prev) => {
                  const newData = { ...prev };
                  delete newData[item._id];
                  return newData;
                });
              }
            }}
            onClick={(e) => e.stopPropagation()}
          />
        </TableCell>
        <TableCell style={{ width: "1px", whiteSpace: "nowrap" }}>
          <Card
            sx={{
              minWidth: "100px",
              maxWidth: "100px",
              height: "80px",
              boxShadow: 2,
            }}
          >
            <img
              alt="Item Image"
              src={item?.image || "/static/icon2.png"}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Card>
        </TableCell>
        <TableCell>
          <Typography variant="h6">{item?.name}</Typography>
          <br />
          {item?.code}
        </TableCell>
        <TableCell>
          <Label color="primary" variant="outlined">
            {item?.category}
          </Label>
        </TableCell>
        <TableCell>
          {item?.stock} {item?.unit}
        </TableCell>
        <TableCell>{item?.location}</TableCell>
        <TableCell align="right" style={{ width: "1px", whiteSpace: "nowrap" }}>
          <Button
            variant="contained"
            endIcon={<AddShoppingCartRounded />}
            onClick={(e) => {
              e.stopPropagation();
            }}
            sx={{ mb: 1 }}
          >
            Order
          </Button>
          <br />
          <Button
            variant="contained"
            endIcon={<ChevronRightRounded />}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            Issue
          </Button>
        </TableCell>
      </TableRow>
    </Tooltip>
  );
};
