import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Drawer,
  Grid,
  InputAdornment,
  Modal,
  OutlinedInput,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Label from "../crm/Label";
import { getDateStringFullWithTimeSync } from "src/utils/date-time-helper";
import { dataFetch, dataPatch } from "src/utils/data-fetch";
import { useEffect, useState } from "react";
import { useAuth } from "src/contexts/AuthContext";
import EmployeeSearch from "../employee/EmployeeSearch";

const TABLE_HEAD = [
  { id: "item", label: "Item" },
  { id: "quantity", label: "Quantity" },
  { id: "amount", label: "Amount" },
  { id: "rate", label: "Rate" },
  { id: "delivery", label: "Delivery Boy" },
];

export default function BulkOrderDetails({ bulkOrder }) {
  const { getAccessToken, activeFranchise } = useAuth();
  const [comment, setComment] = useState("");
  const [commentModal, setCommentModal] = useState(false);
  const [deliveryModal, setDeliveryModal] = useState(false);
  const [searchDrawerState, setSearchDrawerState] = useState(false);
  const [deliveryBoy, setDeliveryBoy] = useState({});
  const [deliveredModal, setDeliveredModal] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const handleDeliveredModal = () => {
    setDeliveredModal(true);
  };

  const handleCancelModal = () => {
    setCommentModal(true);
  };

  const [orders, setOrders] = useState(bulkOrder);

  const loadData = async () => {
    setLoadingData(true);
    const url = `/api/extrasales/mgract/orders/order/${bulkOrder._id}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setOrders(ret.data);
      console.log(ret.data);
    }
    setLoadingData(false);
  };

  const handleDeliveryModal = () => {
    setDeliveryModal(true);
  };

  const drawerSearchInit = () => {
    setSearchDrawerState(true);
  };

  const drawerReturnSearch = (acc) => {
    setDeliveryBoy(acc);
    console.log(acc);
    setSearchDrawerState(false);
  };

  const cancelOrder = async () => {
    const url = `/api/extrasales/mgract/orders/order/${orders._id}/cancel`;

    const ret = await dataPatch(url, await getAccessToken(), {
      status: "cancelled",
      comments: `${orders.comments}, ${comment} by ${orders.manager.name}`,
    });

    if (ret.success === true) {
      loadData();
    } else {
      console.log(ret.message);
    }
  };

  const updateOrder = async () => {
    const url = `/api/extrasales/mgract/orders/order/${orders._id}/update`;

    const ret = await dataPatch(url, await getAccessToken(), {
      status: "out for delivery",
      delivery: deliveryBoy.employee,
    });

    if (ret.success === true) {
      loadData();
    } else {
      console.log(ret.message);
    }
  };

  const deliverOrder = async () => {
    const url = `/api/extrasales/mgract/orders/order/${orders._id}/complete`;

    const ret = await dataPatch(url, await getAccessToken(), {
      status: "delivered",
    });

    if (ret.success === true) {
      loadData();
    } else {
      console.log(ret.message);
    }
  };

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container
      sx={{
        px: 4,
        py: 4,
        width: 800,
        height: "fit-content",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Card sx={{ minHeight: 400 }}>
        {loadingData === true ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 400,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <CardHeader
              title={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    py: 1,
                  }}
                >
                  <Typography variant="h4"> {orders?.account?.name}</Typography>

                  <Label
                    color={
                      orders?.status === "placed"
                        ? "success"
                        : orders?.status === "approved"
                        ? "success"
                        : orders?.status === "rejected"
                        ? "error"
                        : orders?.status === "cancelled"
                        ? "error"
                        : orders?.status === "out for delivery"
                        ? "warning"
                        : "info"
                    }
                    variant="filled"
                    sx={{ alignItems: "center", p: 2 }}
                  >
                    <Typography variant="subtitle1">
                      {orders?.status?.toUpperCase()}
                    </Typography>
                  </Label>
                </Box>
              }
              sx={{
                bgcolor: "primary.main",
                color: "primary.contrastText",
                py: 1,
                textAlign: "left",
              }}
            />

            <CardContent>
              <Box
                sx={{
                  mb: 1,
                }}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="subtitle2" noWrap>
                    <b>{orders?.account?.bulksale?.owner} </b> (
                    {orders?.account?.phone})
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "text.secondary",
                    }}
                  >
                    {getDateStringFullWithTimeSync(orders?.date)}
                  </Typography>
                </Stack>
              </Box>

              <Box
                sx={{
                  my: 1,
                }}
              >
                <Typography variant="subtitle2" noWrap>
                  {orders?.account?.bulksale?.address}
                </Typography>
              </Box>

              <TableContainer sx={{ minHeight: 100 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.alignRight ? "right" : "left"}
                          sortDirection={false}
                        >
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{orders?.item}</TableCell>
                      <TableCell>{orders?.quantity}</TableCell>
                      <TableCell>{orders?.amount}</TableCell>
                      <TableCell>{orders?.rate}</TableCell>
                      <TableCell>
                        {(orders.status === "delivered" &&
                          orders.delivery_boy &&
                          orders.delivery_boy.name) ||
                          "--"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Box
                sx={{
                  mt: 2,
                }}
              >
                <Typography variant="subtitle2" noWrap>
                  <b> {orders?.manager?.name} </b>({orders?.manager?.phone})
                </Typography>
              </Box>

              <Box>
                <Typography variant="subtitle2" noWrap>
                  {orders?.comments || "No Comments"}
                </Typography>
              </Box>
            </CardContent>
            {orders?.status !== "cancelled" &&
              orders?.status !== "delivered" && (
                <Container
                  sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}
                >
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleCancelModal}
                    >
                      Cancel
                    </Button>
                    {orders?.status !== "out for delivery" && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDeliveryModal}
                      >
                        Out for Delivery
                      </Button>
                    )}
                    {orders?.status === "out for delivery" && (
                      <Button
                        variant="contained"
                        color="success"
                        onClick={handleDeliveredModal}
                      >
                        Delivered
                      </Button>
                    )}
                  </Stack>
                </Container>
              )}
          </>
        )}
      </Card>

      <Modal open={deliveredModal} onClose={() => setDeliveredModal(false)}>
        <Container
          sx={{
            px: 4,
            py: 4,
            width: 400,
            height: "fit-content",
            top: "50%",
            left: "50%",
            position: "absolute",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: 2,
          }}
        >
          <Stack direction="column" spacing={2}>
            <Typography variant="h6">
              Confirm delivery status & generate bill
            </Typography>
            <Stack direction="row" spacing={1}>
              <Typography variant="subtitle1">
                <b>Ordered By:</b>
              </Typography>

              <Typography variant="body2" sx={{ mt: 1 }}>
                {orders?.account?.name}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography variant="subtitle1">
                <b>Phone Number:</b>
              </Typography>

              <Typography variant="body2" sx={{ mt: 1 }}>
                {orders?.account?.phone}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography variant="subtitle1">
                <b>Product:</b>
              </Typography>

              <Typography variant="body2" sx={{ mt: 1 }}>
                {orders?.item}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography variant="subtitle1">
                <b>Quantity:</b>
              </Typography>

              <Typography variant="body2" sx={{ mt: 1 }}>
                {orders?.quantity} {orders.unit}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography variant="subtitle1">
                <b>Rate :</b>
              </Typography>

              <Typography variant="body2" sx={{ mt: 1 }}>
                ₹ {orders?.rate}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography variant="subtitle1">
                <b>Net Amount:</b>
              </Typography>

              <Typography variant="body2" sx={{ mt: 1 }}>
                ₹ {orders?.amount}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{ mt: 2, justifyContent: "flex-end" }}
            display="flex"
          >
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setDeliveredModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                deliverOrder();
                setDeliveredModal(false);
              }}
            >
              Submit
            </Button>
          </Stack>
        </Container>
      </Modal>

      <Modal open={deliveryModal} onClose={() => setDeliveryModal(false)}>
        <Container
          sx={{
            px: 4,
            py: 4,
            width: 400,
            height: "fit-content",
            top: "50%",
            left: "50%",
            position: "absolute",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: 2,
          }}
        >
          <Stack direction="row" spacing={2}>
            <OutlinedInput
              fullWidth
              type="text"
              value={(deliveryBoy && deliveryBoy.name) || ""}
              onClick={() => drawerSearchInit("Delivery Boy")}
              startAdornment={
                <InputAdornment position="start">
                  <Box sx={{ color: "text.disabled" }}>Delivery Boy</Box>
                </InputAdornment>
              }
            />
          </Stack>
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setDeliveryModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updateOrder();
                setDeliveryModal(false);
              }}
            >
              Submit
            </Button>
          </Stack>
        </Container>
      </Modal>

      <Modal
        open={commentModal}
        onClose={() => {
          setCommentModal(false);
        }}
      >
        <Container
          sx={{
            px: 4,
            py: 4,
            width: 400,
            height: "fit-content",
            top: "50%",
            left: "50%",
            position: "absolute",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: 2,
          }}
        >
          <TextField
            label="Comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            multiline
            rows={4}
            fullWidth
          />

          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setCommentModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                cancelOrder();
                setCommentModal(false);
              }}
            >
              Submit
            </Button>
          </Stack>
        </Container>
      </Modal>

      <Drawer
        anchor={"right"}
        open={searchDrawerState}
        onClose={() => setSearchDrawerState(false)}
        sx={{ zIndex: 1300, p: 3 }}
      >
        <EmployeeSearch onEmpSelected={drawerReturnSearch} />
      </Drawer>
    </Container>
  );
}
