import { Card, CardActionArea, CircularProgress, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useAuth } from "src/contexts/AuthContext";
import { useEffect, useState } from "react";
import { dataFetch } from "src/utils/data-fetch";
import PartyInfoCard from "./PartyInfoCard";
import PartyImageCard from "./PartyImageCard";

export default function PartyProfileSection({ party }) {
    const [currentData, setCurrentData] = useState();
    const [loadingData, setLoadingData] = useState(false);
  
    const { getAccessToken, activeFranchise } = useAuth();
  
    const loadData = async () => {
      setLoadingData(true);
      let url = `/api/extrasales/mgract/users/user/${party._id}?extended=true`;
  
      const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
      if (ret.success === true) {
        setCurrentData(ret.data);
        console.log(ret.data);
      }
      setLoadingData(false);
    };
  
    useEffect(() => {
      setLoadingData(true);
      loadData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <PartyImageCard
                  loadingData={loadingData}
                  party={currentData}
                />
              </Grid>
            </Grid>
          </Grid>
  
          <Grid item xs={12} md={8} lg={8}>
            <PartyInfoCard
              loadingData={loadingData}
              party={currentData}
              reload={() => loadData()}
            />
          </Grid>
        </Grid>
      </>
    );
  }
  