import {
  Grid,
  Container,
  Typography,
  Stack,
  Modal,
  Button,
} from "@mui/material";

import Page from "../../components/Page";

import { useState } from "react";
import MyTasks from "./MyTasks";
import AllTasks from "./AllTasks";
import AddTaskModal from "./AddTaskModal";

export default function Tasks() {
  const [modalOpen, setModalOpen] = useState(false);

  const closeAddTaskModal = () => {
    setModalOpen(false);
  };

  const addTaskClicked = () => {
    setModalOpen(true);
  };

  return (
    <Page title="Tasks Dashboard">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography variant="h4">Tasks Dashboard</Typography>
          <Button variant="contained" onClick={addTaskClicked}>
            Add Task
          </Button>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <AllTasks />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MyTasks />
          </Grid>
        </Grid>
      </Container>

      <Modal open={modalOpen} onClose={closeAddTaskModal}>
        <AddTaskModal handleM1Close={closeAddTaskModal} />
      </Modal>
    </Page>
  );
}
