import { Grid, Container, Typography, Stack, Tab, Box } from "@mui/material";

import { useLocation } from "react-router-dom";
import { useState } from "react";
import Page from "src/components/Page";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import VehiclesDetailsCard from "./VehcilesDetailsCard";
import TimelineCard from "./TimelineCard";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function VehiclesDetailsPage({ vehicle }) {
  const location = useLocation();
  let account = location?.state?.account;
  if (!account) account = vehicle;

  const [currentMenu, setCurrentMenu] = useState("general");

  const handleTabChange = (event, newValue) => {
    setCurrentMenu(newValue);
  };

  return (
    <Page title="Vehicle Details">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography variant="h4">
            {(account && account.name) || "Dispenser Details"}
          </Typography>
        </Stack>
        <TabContext value={currentMenu}>
          <Box>
            <TabList onChange={handleTabChange}>
              <Tab label="Profile" value="general" {...a11yProps(0)} />
              <Tab label="Timeline" value="timeline" {...a11yProps(1)} />
            </TabList>
          </Box>
          <TabPanel value="general">
            <Grid container spacing={2}>
              <Grid item xs={12} md={8} lg={8}>
                <VehiclesDetailsCard vehicle={account._id} />
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value="timeline">
            <TimelineCard vehicleId={account._id} />
          </TabPanel>
        </TabContext>
      </Container>
    </Page>
  );
}
