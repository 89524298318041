import {
  AppBar,
  Button,
  Container,
  Dialog,
  Slide,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";

import { forwardRef } from "react";
import { CloseRounded } from "@mui/icons-material";
import BannerProfile from "./BannerProfile";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BannerDetails({ banner, handleClose, drawerOpen }) {
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={drawerOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            {banner?.title || "Banner"}
          </Typography>

          <Stack direction="row" spacing={2}>
            <Button variant="contained" color="error" onClick={handleClose}>
              <CloseRounded />
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>

      <Container sx={{ minHeight: 500 }}>
        {banner ? (
          <BannerProfile banner={banner} />
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Container>
    </Dialog>
  );
}
