import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import { Card, Alert, Stack, TextField, Typography, Grid } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddFranchiseeModal({
  handleM1Close,
  duplicateData,
  fr,
}) {
  const [error, setError] = useState();

  const { getAccessToken, activeFranchise, allFranchisees } = useAuth();

  const addFranchisee = async (values) => {
    const ret = await dataPost(
      `/api/franchisees/coreact/add`,
      await getAccessToken(),
      {
        name: values.name,
        city: values.city,
        type: values.type,
        active: values.active,
        category: values.category,
        milk_capacity: values.milk_capacity,
        sub_fee_monthly: values.sub_fee_monthly,
        milk_royalty: values.milk_royalty,
      }
    );
    if (ret.success === true) {
      alert("Franchisee Added Successfully..");
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    name: Yup.string().required("name is required"),
    city: Yup.string().required("city is required"),
    type: Yup.string().optional(),
    active: Yup.boolean().optional(),
    category: Yup.string().optional(),
    milk_capacity: Yup.string().optional(),
    milk_royalty: Yup.number().optional(),
    sub_fee_monthly: Yup.number().optional(),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      category: null,
      number: "",
      model: "",
      base: "",
      battery: "",
      voltage: null,
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await addFranchisee(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Add Franchisee
          </Typography>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Stack spacing={3}></Stack>
          </Grid>
        </Grid>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Name"
                    {...getFieldProps("name")}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="type"
                    {...getFieldProps("type")}
                    error={Boolean(touched.type && errors.type)}
                    helperText={touched.type && errors.type}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="Category"
                    {...getFieldProps("category")}
                    error={Boolean(touched.category && errors.category)}
                    helperText={touched.category && errors.category}
                  />

                  <TextField
                    fullWidth
                    type="number"
                    label="Sub Fee Monthly"
                    {...getFieldProps("sub_fee_monthly")}
                    error={Boolean(
                      touched.sub_fee_monthly && errors.sub_fee_monthly
                    )}
                    helperText={
                      touched.sub_fee_monthly && errors.sub_fee_monthly
                    }
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    type="text"
                    label="City"
                    {...getFieldProps("city")}
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                  />
                  <TextField
                    fullWidth
                    type="boolean"
                    label="Active"
                    {...getFieldProps("active")}
                    error={Boolean(touched.active && errors.active)}
                    helperText={touched.active && errors.active}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="Milk Capacity"
                    {...getFieldProps("milk_capacity")}
                    error={Boolean(
                      touched.milk_capacity && errors.milk_capacity
                    )}
                    helperText={touched.milk_capacity && errors.milk_capacity}
                  />
                  <TextField
                    fullWidth
                    type="number"
                    label="Milk Royalty"
                    {...getFieldProps("milk_royalty")}
                    error={Boolean(touched.milk_royalty && errors.milk_royalty)}
                    helperText={touched.milk_royalty && errors.milk_royalty}
                  />
                </Stack>
              </Grid>
            </Grid>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
