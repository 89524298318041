import {
  Card,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Grid,
  Stack,
  TableHead,
  Table,
  IconButton,
} from "@mui/material";

import { Edit as EditIcon, FileCopy } from "@mui/icons-material";

import { useEffect, useState } from "react";
import {
  getDateStringFullSync,
  getDateStringFullWithTimeSync,
  getDateStringSync,
} from "src/utils/date-time-helper";

import { dataFetch } from "src/utils/data-fetch";
import { useAuth } from "src/contexts/AuthContext";
import { useSnackbar } from "src/contexts/SnackbarContext";

export default function NotificationProfile({ notification }) {
  const [currentData, setCurrentData] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const loadData = async () => {
    setLoadingData(true);
    const response = await dataFetch(
      `/api/sendnotification/mgract/msg/${notification._id}`,
      await getAccessToken(),
      activeFranchise
    );

    if (response.success === true) {
      setCurrentData(response.data);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const copyToClipboard = () => {
    const textField = document.createElement("textarea");
    textField.innerText =
      currentData?.imageUrl || "https://milkvilla.in/images/milkvilla_text.svg";
    navigator.clipboard.writeText(textField.innerText);
    alert("Copied to clipboard");
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Stack direction={"column"} spacing={1}>
            <Card sx={{ p: 2 }}>
              <Stack direction="column" alignItems="start" spacing={2}>
                <b>Send Log</b>
                {currentData?.send_log || "-"}
              </Stack>
            </Card>
            <Card>
              <Stack direction="column" alignItems="center" spacing={1}>
                {currentData?.imageUrl && (
                  <div>
                    <img
                      src={
                        currentData?.imageUrl ||
                        "https://milkvilla.in/images/milkvilla_text.svg"
                      }
                      alt="Notification"
                    />
                  </div>
                )}

                <Typography variant="h6" p={2}>
                  {currentData?.title || ""}
                </Typography>
              </Stack>
              {currentData?.imageUrl && (
                <IconButton
                  sx={{ position: "absolute", top: 5, right: 5 }}
                  onClick={copyToClipboard}
                >
                  <FileCopy />
                </IconButton>
              )}
            </Card>

            <Card sx={{ p: 2 }}>
              <Stack direction="column" alignItems="start" spacing={1}>
                <b>Body</b>
                <Typography variant="subtitle1">
                  {currentData?.body || "-"}
                </Typography>
              </Stack>
            </Card>
            <Card sx={{ p: 2 }}>
              <Stack direction="column" alignItems="start" spacing={1}>
                <b>Description</b>
                <Typography variant="body1">
                  {currentData?.description || "-"}
                </Typography>
              </Stack>
            </Card>
          </Stack>
        </Grid>

        <Grid item xs={12} md={8} lg={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              {notification && (
                <NotificationDetailsCard
                  doc={currentData || notification}
                  reload={loadData}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const NotificationDetailsCard = ({ doc, reload }) => {
  const { showSnackbar } = useSnackbar();

  return (
    <>
      <Card sx={{ px: 3, py: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5" gutterBottom>
            Details
          </Typography>

          <Button variant="outlined" color="error" startIcon={<EditIcon />}>
            Edit
          </Button>
        </Stack>

        {doc && (
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={6}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>Entity:</b>
                    </TableCell>
                    <TableCell>{doc?.entity}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Sent:</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor:
                          doc?.sent === true ? "success.main" : "error.main",
                      }}
                    >
                      {doc?.sent === true ? "Yes" : "No"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>For:</b>
                    </TableCell>
                    <TableCell>{doc?.type || "-"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Segment:</b>
                    </TableCell>
                    <TableCell>
                      {doc?.segment || "-"} ({doc?.segment2 || "-"})
                    </TableCell>
                  </TableRow>
                  <TableRow
                    onClick={() => {
                      navigator.clipboard.writeText(doc?.link);
                      showSnackbar("Link copied to clipboard", "success");
                    }}
                  >
                    <TableCell>
                      <b>Link:</b>
                    </TableCell>
                    <TableCell>{doc?.link?.substring(0, 30)}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <b>Link Text:</b>
                    </TableCell>
                    <TableCell>{doc?.link_text || "No link text"}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>Date:</b>
                    </TableCell>
                    <TableCell>
                      {getDateStringFullWithTimeSync(doc?.date)}
                    </TableCell>
                  </TableRow>

                  {doc?.entity === "inapp" && (
                    <>
                      <TableRow>
                        <TableCell>
                          <b>In App Type:</b>
                        </TableCell>
                        <TableCell>
                          {doc?.inapp_type.toUpperCase() || "--"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Min Interval:</b>
                        </TableCell>
                        <TableCell>
                          {doc?.min_interval / (1000 * 60)} minutes
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Times:</b>
                        </TableCell>
                        <TableCell>{doc?.times}</TableCell>
                      </TableRow>
                    </>
                  )}

                  {["inapp", "recurring"].includes(doc?.entity) && (
                    <>
                      <TableRow>
                        <TableCell>
                          <b>Start Date:</b>
                        </TableCell>
                        <TableCell>{getDateStringSync(doc.start)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>End Date:</b>
                        </TableCell>
                        <TableCell>{getDateStringSync(doc.end)}</TableCell>
                      </TableRow>
                    </>
                  )}

                  {doc?.entity === "notification" && (
                    <TableRow>
                      <TableCell>
                        <b>Send Locked:</b>
                      </TableCell>
                      <TableCell>
                        {doc?.cron_locked === true ? "Locked" : "Not Locked"}
                      </TableCell>
                    </TableRow>
                  )}

                  {doc?.cron_locked === true && (
                    <TableRow>
                      <TableCell>
                        <b>Locked At:</b>
                      </TableCell>
                      <TableCell>
                        {getDateStringFullWithTimeSync(doc?.lock_ts)}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        )}
      </Card>
      <Card sx={{ px: 3, py: 2, my: 2 }}>
        <Stack direction="column">
          <Typography variant="h5" gutterBottom>
            Activity
          </Typography>

          <EventTable actions={doc?.actions || []} />
        </Stack>
      </Card>
    </>
  );
};

const EventTable = ({ actions }) => {
  const uniqueViews = [];

  const eventCounts = actions.reduce((acc, action) => {
    const eventType = action.event;
    if (!uniqueViews.includes(action.user)) uniqueViews.push(action.user);
    if (!acc[eventType]) {
      acc[eventType] = 1;
    } else {
      acc[eventType]++;
    }
    return acc;
  }, {});

  const viewsByDate = actions.reverse().reduce((acc, view) => {
    if (view.event === "view") {
      const viewDate = getDateStringFullSync(view.ts);
      acc[viewDate] = (acc[viewDate] || 0) + 1;
    }
    return acc;
  }, {});

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <b>Action</b>
          </TableCell>
          <TableCell align="right">
            <b>Count</b>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(eventCounts).map(([eventType, count]) => (
          <TableRow key={eventType}>
            <TableCell>{eventType}</TableCell>
            <TableCell align="right">{count}</TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell>
            <b>Unique</b>
          </TableCell>
          <TableCell align="right">
            <b>{uniqueViews.length}</b>
          </TableCell>
        </TableRow>
      </TableBody>

      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell align={"right"}>Views</TableCell>
        </TableRow>
      </TableHead>

      {Object.keys(viewsByDate).map((date) => (
        <TableRow key={date}>
          <TableCell>{date}</TableCell>
          <TableCell align={"right"}>{viewsByDate[date]}</TableCell>
        </TableRow>
      ))}
    </Table>
  );
};
