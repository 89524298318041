import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import { Card, Alert, Stack, TextField, Typography, Grid } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPatch } from "src/utils/data-fetch";

import {
  getDateStringFullWithSlash,
  parseDateStringFull,
} from "src/utils/date-time-helper";

export default function EditOtherDetailModal({ handleM1Close, employee }) {
  const [error, setError] = useState();

  const { getAccessToken, activeFranchise } = useAuth();

  const approveNow = async (values) => {
    let up = {
      tshirt_size: values.tshirt,
      blood_group: values.blood_group,
      join_date: parseDateStringFull(values.join_date),
      leaves_pm: values.leaves_pm,
    };

    const ret = await dataPatch(
      `/api/employees/mgract/v2/employee/${employee?._id}`,
      await getAccessToken(),
      up,
      activeFranchise
    );
    if (ret.success === true) {
      console.log("edit employee", ret.data);
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    tshirt: Yup.string().required("Tshirt Size is required"),
    blood_group: Yup.string().required("Blood Group is required"),
    join_date: Yup.string().required("Join Date is required"),
    leaves_pm: Yup.string().required("Leaves per month is required"),
  });

  const formik = useFormik({
    initialValues: {
      tshirt: employee?.employee_det?.tshirt_size || "",
      blood_group: employee?.employee_det?.blood_group || "",
      join_date:
        getDateStringFullWithSlash(employee?.employee_det?.join_date) || "",
      leaves_pm: employee?.employee_det?.leaves_pm || "0",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Edit Other Details
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Tshirt Size"
                    {...getFieldProps("tshirt")}
                    error={Boolean(touched.tshirt && errors.tshirt)}
                    helperText={touched.tshirt && errors.tshirt}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="Blood Group"
                    {...getFieldProps("blood_group")}
                    error={Boolean(touched.blood_group && errors.blood_group)}
                    helperText={touched.blood_group && errors.blood_group}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="Join Date"
                    {...getFieldProps("join_date")}
                    error={Boolean(touched.join_date && errors.join_date)}
                    helperText={touched.join_date && errors.join_date}
                  />
                  <TextField
                    fullWidth
                    type="text"
                    label="Leaves Per Month"
                    {...getFieldProps("leaves_pm")}
                    error={Boolean(touched.leaves_pm && errors.leaves_pm)}
                    helperText={touched.leaves_pm && errors.leaves_pm}
                  />
                </Stack>
              </Grid>
            </Grid>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
