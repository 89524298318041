import { Grid, Container, Typography } from "@mui/material";

import Page from "../../components/Page";

import ListManager from "./ListManager";
import { useNavigate } from "react-router-dom";

import { dataFetch } from "src/utils/data-fetch";
import { useAuth } from "src/contexts/AuthContext";
import { useEffect, useState } from "react";

export default function Managers() {
  const navigate = useNavigate();

  const openManagerDetailsPage = (item) => {
    navigate("details", { state: { manager: item } });
  };

  const [balanceData, setBalanceData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const { getAccessToken, activeFranchise } = useAuth();

  useEffect(() => {
    fetchBalanceData();
  }, [activeFranchise]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchBalanceData = async () => {
    setLoadingData(true);

    const ret = await dataFetch(
      `/api/managers/mgract/query?active=true&sort=name`,
      await getAccessToken(),
      activeFranchise
    );
    if (ret.success === true) {
      setBalanceData(ret.data);
    }
    setLoadingData(false);
  };

  const cros = [];
  const notCros = [];
  balanceData.forEach((element) => {
    if (["crm", "crme"].includes(element.manager.category)) {
      cros.push(element);
    } else {
      notCros.push(element);
    }
  });

  return (
    <Page title="Manager Dashboard">
      <Container>
        <Grid container mb={4} spacing={4}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h4">Manager Dashboard</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <ListManager
              title="Managers"
              managers={notCros}
              loadingData={loadingData}
              onManagerClick={openManagerDetailsPage}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <ListManager
              title="CROs"
              managers={cros}
              loadingData={loadingData}
              onManagerClick={openManagerDetailsPage}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
