import {
  Card,
  Alert,
  Stack,
  Typography,
  Grid,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch, dataPatch } from "src/utils/data-fetch";
import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import { formatDate } from "./ComplaintsList";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  select: {
    minWidth: 120,
  },
}));

export default function ComplaintDetails(comp) {
  const [complaint, setComplaint] = useState();

  const classes = useStyles();

  const { getAccessToken, activeFranchise } = useAuth();

  const [selectedStatus, setSelectedStatus] = useState(complaint?.status);

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleUpdateStatus = async () => {
    const url = `/api/complaints/mgract/complaint/${comp.id}`;

    const ret = await dataPatch(
      url,
      await getAccessToken(),
      {
        status: selectedStatus,
      },
      activeFranchise
    );

    if (ret.success === true) {
      loadComplaint();
    } else {
      return <Alert severity="error">Error updating complaint</Alert>;
    }
  };

  const loadComplaint = async () => {
    const url = `/api/complaints/mgract/complaint/${comp.id}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setComplaint(ret.data);
      setSelectedStatus(ret.data.status);
    } else {
      setComplaint(null);
    }
  };

  useEffect(() => {
    loadComplaint();
  }, [activeFranchise, comp]);

  return (
    <Card className={classes.card}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Typography variant="h5" gutterBottom>
          {"Complaint Details"}
        </Typography>
      </Stack>

      {complaint && (
        <Grid direction={"row"} container spacing={2}>
          <Grid item xs={12} md={6} lg={6} mt={3}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>ID:</b>
                </TableCell>
                <TableCell>{complaint._id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Made By:</b>
                </TableCell>
                <TableCell>{complaint.employee.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Description:</b>
                </TableCell>
                <TableCell>{complaint.complaint}</TableCell>
              </TableRow>
            </TableBody>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>Status:</b>
                </TableCell>
                <TableCell>
                  <Select
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    className={classes.select}
                    fullWidth
                  >
                    <MenuItem value="resolved">Resolved</MenuItem>
                    <MenuItem value="under review">Under Review</MenuItem>
                    <MenuItem value="pending">Pending</MenuItem>
                  </Select>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Date:</b>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {formatDate(complaint.date)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Resolved By:</b>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {complaint.resolved_by ? complaint.resolved_by.name : "__"}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Grid>
        </Grid>
      )}

      <Stack
        direction="row"
        alignContent={"end"}
        justifyContent="flex-end"
        sx={{ mr: 16, mt: 3 }}
      >
        <Button variant="contained" onClick={handleUpdateStatus}>
          Update Complaint
        </Button>
      </Stack>
    </Card>
  );
}
