import React from "react";
import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";

import { SearchRounded } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import MenuItem from "@mui/material/MenuItem";

import {
  Card,
  Container,
  Typography,
  Table,
  OutlinedInput,
  InputAdornment,
  Box,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
  TableHead,
  Stack,
  TextField,
} from "@mui/material";
import Label from "../../crm/Label";
import CustomerDetailsPage from "src/pages/home/det/CustomerDetailsPage";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "index", label: "Index", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "onbs", label: "Onb Status", alignRight: false },
  { id: "address", label: "Address", alignRight: false },
  { id: "zone", label: "Zone", alignRight: false },
  { id: "slot", label: "Slot", alignRight: false },
  { id: "balance", label: "Balance", alignRight: true },
];

export const MilkCartsCustomers = ({ cart }) => {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [activeStatus, setActiveStatus] = useState("true");
  const [balanceGt, setBalanceGt] = useState(1);

  const { getAccessToken, activeFranchise } = useAuth();

  const [selectedSearch, setSelectedSearch] = useState("");

  const handleSearch = async () => {
    let url = `/api/users/mgract/query?cart=${cart._id}&sort=_id&dir=a`;

    if (activeStatus !== "all") url += `&active=${activeStatus}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [activeFranchise, activeStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  let totalRech = 0;

  const filteredData = customerData
    .filter((v) => {
      if (selectedSearch.trim().length === 0) return true;
      else {
        return (
          v?.name?.toLowerCase()?.includes(selectedSearch) ||
          v?.phone?.includes(selectedSearch) ||
          v?.address?.detail?.toLowerCase()?.includes(selectedSearch) || v?.address?.flat?.toLowerCase()?.includes(selectedSearch)
        );
      }
    })
    .filter((value) => value?.wallet?.balance >= balanceGt)
    .sort((a, b) => {
      let sl1 = (a?.plan?.slot || 0) <= 3 ? 0 : 1;
      let sl2 = (b?.plan?.slot || 0) <= 3 ? 0 : 1;

      if (sl1 === sl2) {
        return a.wallet.balance > b.wallet.balance ? -1 : 1;
      } else {
        return sl1 < sl2 ? -1 : 1;
      }
      // return (a?.plan?.slot || 0) > (a?.plan?.slot || 0) || (a?.wallet?.balance || 0.0) < (b?.wallet?.balance || 0.0);
    });

  const [selectedCustomer, setSelectedCustomer] = useState(null);

  return (
    <Card title="Customers">
      <Container sx={{ pb: 3 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          mt={2}
          spacing={2}
        >
          <SearchStyle
            placeholder="Search Customer..."
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>
                  <SearchRounded />
                </Box>
              </InputAdornment>
            }
            sx={{ mt: 4, mb: 4 }}
            autoFocus
            endAdornment={
              loadingData === true && <CircularProgress color="secondary" />
            }
            onChange={(e) => setSelectedSearch(e.target.value?.toLowerCase())}
            value={selectedSearch}
          />

          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <TextField
              select
              id="balance-gt-select"
              value={balanceGt}
              onChange={(e) => setBalanceGt(e.target.value)}
              label="Balance"
            >
              <MenuItem value={-500}>&gt;= -500</MenuItem>
              <MenuItem value={0}>&gt;= 0</MenuItem>
              <MenuItem value={1}>&gt; 0</MenuItem>
              <MenuItem value={136}>&gt; 135</MenuItem>
              <MenuItem value={500}>&gt;= 500</MenuItem>
              <MenuItem value={1000}>&gt;= 1000</MenuItem>
              <MenuItem value={2000}>&gt;= 2000</MenuItem>
            </TextField>

            <TextField
              select
              id="active-select"
              value={activeStatus}
              onChange={(e) => setActiveStatus(e.target.value)}
              label="Activity"
            >
              <MenuItem value={"true"}>Active</MenuItem>
              <MenuItem value={"false"}>Inactive</MenuItem>
              <MenuItem value={"all"}>All</MenuItem>
            </TextField>
          </Stack>
        </Stack>

        {filteredData.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        <b>{headCell.label}</b>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredData.map((item, index) => {
                    totalRech += item?.wallet?.balance ?? 0;

                    return (
                      <TableRow
                        hover
                        style={{
                          backgroundColor:
                            (item?.wallet?.balance ?? 0) < 0
                              ? "lightsalmon"
                              : item.active === true
                              ? "white"
                              : "#ddd",
                        }}
                        onClick={() => setSelectedCustomer(item)}
                      >
                        <TableCell>
                          <b>{index + 1}</b>
                        </TableCell>
                        <TableCell>
                          <b>{item.name || "-"}</b>
                        </TableCell>
                        <TableCell>
                          <Label
                            variant="filled"
                            color={
                              item?.onboarding_status === "completed"
                                ? "success"
                                : item?.onboarding_status === "later"
                                ? "warning"
                                : item?.onboarding_status === "not-interested"
                                ? "info"
                                : item?.onboarding_status === "duplicate"
                                ? "secondary"
                                : item?.onboarding_status === "ongoing"
                                ? "primary"
                                : "error"
                            }
                          >
                            <b>
                              {item?.onboarding_status?.toUpperCase() || "-"}
                            </b>
                          </Label>
                        </TableCell>

                        <TableCell>
                          {item?.address?.detail?.substring(0, 30)}
                        </TableCell>

                        <TableCell>
                          {(item?.address?.apartment && (
                            <Label variant="outlined" color="info">
                              {item?.address?.apartment?.name}
                            </Label>
                          )) ||
                            item?.address?.apartment?.name}
                        </TableCell>
                        <TableCell>
                          {(item?.plan && item?.plan?.slot >= 4 && (
                            <Label variant="outlined" color="secondary">
                              {item?.plan?.slot === 4
                                ? "5PM - 7PM"
                                : item?.plan?.slot === 5
                                ? "7PM - 9PM"
                                : "OTHER"}
                            </Label>
                          )) || (
                            <Label variant="outlined" color="info">
                              {item?.plan?.slot === 1
                                ? "5AM - 7AM"
                                : item?.plan?.slot === 2
                                ? "7AM - 9AM"
                                : item?.plan?.slot === 3
                                ? "9AM - 11AM"
                                : "ANYTIME"}
                            </Label>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Label
                            variant="filled"
                            color={
                              item?.wallet?.balance >= 150
                                ? "success"
                                : item?.wallet?.balance > 0
                                ? "warning"
                                : "error"
                            }
                          >
                            ₹ {item?.wallet?.balance || "0"}
                          </Label>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <b>Total</b>
                    </TableCell>
                    <TableCell align="right">
                      <b>₹ {totalRech?.toFixed()}</b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}

        {customerData.length === 0 && loadingData === false && (
          <div>
            <Typography gutterBottom align="center" variant="subtitle1">
              No data here
            </Typography>
          </div>
        )}
      </Container>

      {selectedCustomer && (
        <CustomerDetailsPage
          customer={selectedCustomer}
          drawerOpen={selectedCustomer != null}
          handleClose={() => setSelectedCustomer(null)}
        />
      )}
    </Card>
  );
};
