import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";
import {
  Card,
  Typography,
  Grid,
  Stack,
  Modal,
  IconButton,
} from "@mui/material";

import FarmerInfoCard from "./FarmerInfoCard";
import FarmerImageCard from "./FarmerImageCard";
import { EditRounded } from "@mui/icons-material";

import FarmerCreditLimitModal from "./FarmerCreditLimitModal";

export default function FarmerProfileSection({ farmer }) {
  const [currentData, setCurrentData] = useState();
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const [creditLimitModal, setCreditLimitModal] = useState(false);
  const closeCreditLimitModal = () => {
    setCreditLimitModal(false);
    loadData();
  };

  const loadData = async () => {
    setLoadingData(true);
    let url = `/api/farmers/mgract/farmer/${farmer._id}?extended=true`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCurrentData(ret.data);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <FarmerImageCard loadingData={loadingData} farmer={currentData} />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Card sx={{ p: 2 }}>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="h6">Credit Limit</Typography>

                  <Stack direction="row" alignItems={"center"} spacing={2}>
                    <Typography variant="subtitle1">
                      ₹ {currentData?.credit_limit}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        setCreditLimitModal(true);
                      }}
                    >
                      <EditRounded />
                    </IconButton>
                  </Stack>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <FarmerInfoCard
            loadingData={loadingData}
            farmer={currentData}
            reload={() => loadData()}
          />
        </Grid>
      </Grid>
      <Modal open={creditLimitModal} onClose={closeCreditLimitModal}>
        <FarmerCreditLimitModal
          handleM1Close={closeCreditLimitModal}
          farmer={currentData}
        />
      </Modal>
    </>
  );
}
