import {
  AccountBalanceRounded,
  AddCardRounded,
  BusinessCenterRounded,
  CalculateRounded,
  CreditCardRounded,
  CurrencyRupeeRounded,
  DashboardRounded,
  FunctionsRounded,
  ManageAccountsRounded,
  PersonRounded,
  PetsRounded,
  PrecisionManufacturingRounded,
} from "@mui/icons-material";

export const onboardingStatus = {
  hotlist: "Hotlist",
  delivery: "Delivery Lead",
  pending: "Pending",
  ongoing: "Ongoing",
  later: "Later",
  duplicate: "Duplicate",
  completed: "Completed",
  "not-interested": "Not Interested",
};

export const states = {
  AP: "Andhra Pradesh",
  AR: "Arunachal Pradesh",
  AS: "Assam",
  BR: "Bihar",
  CT: "Chhattisgarh",
  GA: "Goa",
  GJ: "Gujarat",
  HR: "Haryana",
  HP: "Himachal Pradesh",
  JH: "Jharkhand",
  KA: "Karnataka",
  KL: "Kerala",
  MP: "Madhya Pradesh",
  MH: "Maharashtra",
  MN: "Manipur",
  ML: "Meghalaya",
  MZ: "Mizoram",
  NL: "Nagaland",
  OR: "Odisha",
  PB: "Punjab",
  RJ: "Rajasthan",
  SK: "Sikkim",
  TN: "Tamil Nadu",
  TG: "Telangana",
  TR: "Tripura",
  UP: "Uttar Pradesh",
  UK: "Uttarakhand",
  WB: "West Bengal",
  AN: "Andaman and Nicobar Islands",
  CH: "Chandigarh",
  DN: "Dadra and Nagar Haveli and Daman and Diu",
  DL: "Delhi",
  JK: "Jammu and Kashmir",
  LA: "Ladakh",
  LD: "Lakshadweep",
  PY: "Puducherry",
};

export const employeeRoles = {
  sales: "Delivery",
  tl: "Team Lead",
  fieldexec: "Field Sales",
  manager: "Manager",
  collection: "Collection",
  mechanic: "Mechanic",
  helper: "Helper",
  cook: "Cook",
  lab: "Lab Assistant",
  transport: "Transport",
  hr: "HR",
  crm: "CRM",
  account: "Accounts",
};

export const commonItems = [
  "Stationary",
  "Uniform",
  "ID Card",
  "Milkcan",
  "Clothes",
  "Construction",
  "Furniture",
  "Electronics",
  "Vehicle",
  "Electrical",
  "Plumbing",
  "Painting",
  "Carpentry",
  "Fabrication",
  "Welding",
  "Machining",
  "Packaging",
  "Cleaning",
  "Security",
  "Catering",
  "Event",
  "Photography",
  "Printing",
  "Advertising",
  "Marketing",
  "Consulting",
  "Training",
  "Education",
  "Healthcare",
  "Pharmacy",
  "Hospitality",
  "Travel",
  "Logistics",
  "Transport",
  "Courier",
  "Storage",
  "Warehousing",
  "Packers",
  "Movers",
  "Packaging",
  "Shipping",
  "Delivery",
  "Repair",
  "Vehicle Parts",
  "Cleaning",
  "Chemicals",
  "Lab",
  "Food",
  "Grocery",
  "Fruits",
  "Vegetables",
  "General Store",
  "Bakery",
  "Flower",
  "Inverter",
  "Generator",
  "Battery",
  "Safety Gear",
  "Mask",
  "Bedding",
  "Curtain",
  "Pest Control",
  "Aluminum Section",
  "Steel",
  "Welding",
  "Iron Scrap",
  "Power Tools",
  "Mirror",
  "Newspaper",
  "Musical Instruments",
  "Gym Equipments",
  "Seeds",
  "Fertilizers",
  "Insurance",
];

export const veryLightBkgColors = [
  "#E9F8E7",
  "#F3E8FD",
  "#FFF1E6",
  "#E0F7FA",
  "#FFE4E6",
  "#F5F5F5",
  "#FFF8DC",
  "#FFFBCC",
  "#EBF5FB",
  "#E6F7E6",
];

export const lightBkgColors = [
  "#C8E6C9",
  "#D7BDE2",
  "#FFDAB9",
  "#B2EBF2",
  "#F8BBD0",
  "#D6D6D6",
  "#FFE4B5",
  "#FFF176",
  "#AED6F1",
  "#A5D6A7",
];

export const darkBkgColors = [
  "#81C784",
  "#AF7AC5",
  "#FFB074",
  "#4DD0E1",
  "#F06292",
  "#B0B0B0",
  "#FFC107",
  "#FFEB3B",
  "#5DADE2",
  "#66BB6A",
];

export const darkerBkgColors = [
  "#388E3C",
  "#7D3C98",
  "#FB8C00",
  "#00838F",
  "#D81B60",
  "#616161",
  "#FF8F00",
  "#FBC02D",
  "#2E86C1",
  "#2E7D32",
];

export const darkestBkgColors = [
  "#1B5E20",
  "#4A235A",
  "#E65100",
  "#006064",
  "#880E4F",
  "#212121",
  "#FF6F00",
  "#F57F17",
  "#1A5276",
  "#1B5E20",
];

export const mgrAccessRoles = {
  delivery: {
    sm: "Delivery Mgr",
    smv: "Delivery Mgr View Only",
  },
  crm: {
    crm: "CRM Edit",
    crmv: "CRM View Only",
  },
  collection: {
    cm: "Collection Manager",
    cmv: "Collection Mgr View Only",
  },
  accounts: {
    acc: "Accounts",
    acce: "Accounts + Edit",
    acca: "Accounts + Approve",
  },
  hr: {
    hr: "HR",
    hre: "HR + Edit",
    hra: "HR + Approve",
  },
  maintenance: {
    disp: "Dispenser",
    pms: "Planned Maintenance",
    mn: "Maintenance",
  },
  important: {
    mt: "Manager Txn",
    cg: "Credentials Generate",
    dh: "Dispense Hisaab",
  },
  marketing: {
    mrk: "Marketing",
    mrke: "Marketing + Edit",
  },
  inventory: {
    inv: "Inventory",
    inve: "Inventory + Edit",
  },
  main: {
    hm: "Head Manager",
    fo: "Plant Owner",
    fov: "Plant Owner View Only",
  },
};

export const managerRolesAll = Object.values(mgrAccessRoles).reduce((p, n) => {
  return { ...p, ...n };
}, {});

export const ledgerIcon = (entity) => {
  switch (entity) {
    case "bankaccount":
      return <AccountBalanceRounded />;
    case "expense":
      return <CurrencyRupeeRounded />;
    case "income":
      return <AddCardRounded />;
    case "asset":
      return <PrecisionManufacturingRounded />;
    case "employee":
      return <PersonRounded />;
    case "manager":
      return <ManageAccountsRounded />;
    case "bulksale":
      return <BusinessCenterRounded />;
    default:
      return <DashboardRounded />;
  }
};

export const txnIcon = (entity) => {
  switch (entity) {
    case "expense":
      return <CurrencyRupeeRounded />;
    case "income":
      return <AddCardRounded />;
    case "salebill":
      return <BusinessCenterRounded />;
    case "dispensein":
      return <CalculateRounded />;
    case "farmerpay":
      return <PetsRounded />;
    case "payroll":
      return <FunctionsRounded />;
    default:
      return <CreditCardRounded />;
  }
};

export function stringToColor(string = "Name") {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
