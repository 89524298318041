import { styled } from "@mui/material/styles";
import { Card, CardActionArea, Typography, Stack } from "@mui/material";
import { ArrowRightRounded } from "@mui/icons-material";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  color: "black",
  border: "1px",
  borderStyle: "solid",
  borderColor: theme.palette.primary.main,
}));

export default function TopCard({ data, onClick }) {
  return (
    <RootStyle onClick={onClick}>
      <CardActionArea sx={{ py: 2, px: 2 }}>
        <Typography variant="h5" textAlign={"center"}>
          {data}
        </Typography>
      </CardActionArea>
    </RootStyle>
  );
}
