import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Radio,
  Switch,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPatch } from "src/utils/data-fetch";

export default function EditProductModal({ handleM1Close, product }) {
  const [error, setError] = useState();

  const { getAccessToken, activeFranchise } = useAuth();

  const approveNow = async (values) => {
    let up = {
      bio: values.bio,
      active: values.active,
      price: values.price,
      disc_price: values.disc_price,
      sub_price: values.sub_price,
      app_price: values.app_price,
      offer_text: values.offer_text,
    };

    const ret = await dataPatch(
      `/api/products/mgract/product/${product?._id}`,
      await getAccessToken(),
      up,
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    bio: Yup.string().optional(),
    active: Yup.boolean().optional(),
    price: Yup.number().optional(),
    disc_price: Yup.number().optional(),
    sub_price: Yup.number().optional(),
    app_price: Yup.number().optional(),
    offer_text: Yup.string().optional(),
  });

  const formik = useFormik({
    initialValues: {
      bio: product?.bio,
      active: product?.active,
      price: product?.price,
      disc_price: product?.disc_price,
      sub_price: product?.sub_price,
      app_price: product?.app_price,
      offer_text: product?.offer_text || "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Update product
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                type="text"
                label="Bio"
                {...getFieldProps("bio")}
                error={Boolean(touched.bio && errors.bio)}
                helperText={touched.bio && errors.bio}
              />

              <Stack direction="row" alignItems="center">
                <Typography variant="subtitle1" gutterBottom mr={2} mt={1}>
                  Active
                </Typography>
                <Switch
                  {...getFieldProps("active")}
                  checked={formik.values.active}
                  label="Active"
                  onChange={(e) =>
                    formik.setFieldValue("active", e.target.checked)
                  }
                />
              </Stack>

              <TextField
                fullWidth
                type="number"
                label="Price"
                {...getFieldProps("price")}
                error={Boolean(touched.price && errors.price)}
                helperText={touched.price && errors.price}
              />

              <TextField
                fullWidth
                type="number"
                label="Discounted Price"
                {...getFieldProps("disc_price")}
                error={Boolean(touched.disc_price && errors.disc_price)}
                helperText={touched.disc_price && errors.disc_price}
              />

              <TextField
                fullWidth
                type="number"
                label="Subscription Price"
                {...getFieldProps("sub_price")}
                error={Boolean(touched.sub_price && errors.sub_price)}
                helperText={touched.sub_price && errors.sub_price}
              />

              <TextField
                fullWidth
                type="number"
                label="App  Price"
                {...getFieldProps("app_price")}
                error={Boolean(touched.app_price && errors.app_price)}
                helperText={touched.app_price && errors.app_price}
              />

              <TextField
                fullWidth
                type="text"
                label="offer text"
                {...getFieldProps("offer_text")}
                error={Boolean(touched.offer_text && errors.offer_text)}
                helperText={touched.offer_text && errors.offer_text}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
