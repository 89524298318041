import React, { useRef, useState } from "react";
import { TileLayer, MapContainer, useMapEvent, Marker } from "react-leaflet";

import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";

import LeafletControlGeocoder from "./LeafletControlGeocoder";

export default function AddAptSelectMap({
  onCoordinateChange,
  onAddressUpdate,
}) {
  const mapRef = useRef(null);

  const [markerPos, setMarkerPos] = useState(null);

  const EventHandler = () => {
    useMapEvent("click", (e) => {
      setMarkerPos(e.latlng);
      onCoordinateChange([e.latlng.lng, e.latlng.lat]);
    });

    return null;
  };

  const onMarkerDrag = (e) => {
    setMarkerPos(e.target.getLatLng());
    onCoordinateChange([e.target.getLatLng().lng, e.target.getLatLng().lat]);
    onAddressUpdate(" ");
  };

  return (
    <div>
      <MapContainer
        center={[12.90152691, 77.69154668]}
        zoom={15}
        style={{ height: "90vh", width: "100%" }}
        ref={mapRef}
      >
        <LeafletControlGeocoder
          onLocationSelect={(latlng, name) => {
            onCoordinateChange([latlng.lng, latlng.lat]);
            onAddressUpdate(name);
          }}
        />
        <EventHandler />
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

        {markerPos && (
          <Marker
            position={markerPos}
            riseOnHover={true}
            title="Selected Position"
            draggable
            eventHandlers={{ drag: onMarkerDrag }}
          />
        )}
      </MapContainer>
    </div>
  );
}
