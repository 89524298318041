import { useState, useEffect } from "react";
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  IconButton,
  TableHead,
  CircularProgress,
  Box,
} from "@mui/material";

import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";
import {
  getDayStartAndEndSync,
  TWENTY4HOURS,
  getDayStart,
} from "src/utils/date-time-helper";

import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const TABLE_HEAD = [
  { id: "cart", label: "Cart", alignRight: false },
  { id: "session", label: "Session", alignRight: false },
  { id: "driver", label: "Driver", alignRight: false },
  { id: "area", label: "Area", alignRight: false },
  { id: "milktype", label: "Milk Type", alignRight: false },
  { id: "milk", label: "Milk", alignRight: false },
  { id: "sale", label: "Sale (Kg.)", alignRight: false },
  { id: "cash", label: "Cash", alignRight: false },
  { id: "online", label: "Online", alignRight: false },
  { id: "error", label: "Error", alignRight: false },
];

export default function DispenseToday({ onAccSelected }) {
  const [remoteData, setRemoteData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const [startDate, setStartDate] = useState(getDayStart(Date.now()));

  const { getAccessToken, activeFranchise } = useAuth();
  const navigate = useNavigate();

  const loadData = async () => {
    setLoadingData(true);
    const ret = await dataFetch(
      `/api/dispenses/mgract/query?date_gt=${startDate}&date_lt=${
        startDate + TWENTY4HOURS
      }`,
      await getAccessToken(),
      activeFranchise
    );

    setRemoteData(ret.data);
    setLoadingData(false);
  };

  useEffect(() => {
    loadData();
  }, [activeFranchise, startDate]); // eslint-disable-line react-hooks/exhaustive-deps

  let milkSent = 0,
    milkRet = 0,
    phoneSale = 0,
    totalCash = 0,
    totalOnline = 0,
    totalError = 0;

  return (
    <Card sx={{ pl: 2, pr: 2 }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 3, mb: 1, ml: 1, mr: 1 }}
      >
        <Typography variant="h5" gutterBottom>
          Hisaab By Date
        </Typography>

        {loadingData === true && <CircularProgress color="secondary" />}

        <Stack direction="row" alignItems={"center"}>
          <IconButton
            sx={{ display: "inline-flex", mr: 1 }}
            onClick={() => {
              setStartDate(startDate - TWENTY4HOURS);
            }}
          >
            <KeyboardArrowLeftRounded />
          </IconButton>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale={"en-in"}
          >
            <MobileDatePicker
              value={moment(startDate)}
              onAccept={(newValue) => {
                if (newValue) {
                  const d1 = getDayStart(newValue.valueOf());
                  if (startDate !== d1) setStartDate(d1);
                }
              }}
              closeOnSelect={true}
              format="DD/MM/YYYY"
              loading={loadingData}
            />
          </LocalizationProvider>
          <IconButton
            sx={{ display: "inline-flex", ml: 1 }}
            onClick={() => {
              setStartDate(startDate + TWENTY4HOURS);
            }}
          >
            <KeyboardArrowRightRounded />
          </IconButton>
        </Stack>
      </Stack>

      {remoteData.length > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.alignRight ? "right" : "left"}
                    sortDirection={false}
                  >
                    <b>{headCell.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {remoteData.map((row) => {
                if (row.deleted !== true) {
                  milkSent += row.milk_sent;
                  milkRet += row.milk_returned;
                  phoneSale += row.sale_quantity;
                  totalCash += row.cash_in;
                  totalOnline += row.online_money;
                  totalError += row.sale_error;
                }
                return (
                  <TableRow
                    hover
                    onClick={() =>
                      navigate("details", {
                        state: {
                          account: row,
                        },
                      })
                    }
                    style={{
                      backgroundColor:
                        row?.deleted === true ? "lightgray" : "white",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography variant="subtitle2" noWrap>
                        <b>{row?.cart?.name}</b>
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {row?.session === 0
                        ? "MOR"
                        : row?.session === 1
                        ? "EVE"
                        : "M+E"}
                    </TableCell>
                    <TableCell align="left">{row.driver?.name}</TableCell>
                    <TableCell align="left">{row.area?.name}</TableCell>
                    <TableCell align="left">
                      {row?.milk_type === 0 ? "COW" : "BUFF"}
                    </TableCell>
                    <TableCell align="left">
                      {row?.milk_sent.toFixed(1)} -{" "}
                      {row?.milk_returned.toFixed(1)}
                    </TableCell>
                    <TableCell align="left">
                      <b>{row.sale_quantity.toFixed(1)}</b>
                    </TableCell>
                    <TableCell align="left">₹ {row?.cash_in}</TableCell>
                    <TableCell align="left">₹ {row?.online_money}</TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        color:
                          row?.sale_error < 0 ? "error.main" : "success.main",
                      }}
                    >
                      <b>₹ {row?.sale_error.toFixed(1)}</b>
                    </TableCell>
                  </TableRow>
                );
              })}

              <TableRow>
                <TableCell component="th" scope="row"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left">
                  <b>Total</b>
                </TableCell>
                <TableCell align="left">
                  {milkSent.toFixed(1)} - {milkRet.toFixed(1)}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    color:
                      milkSent - milkRet > phoneSale
                        ? "error.main"
                        : "success.main",
                  }}
                >
                  <b>{phoneSale.toFixed(1)}</b>
                </TableCell>
                <TableCell align="left">₹ {totalCash}</TableCell>
                <TableCell align="left">₹ {totalOnline}</TableCell>
                <TableCell
                  align="left"
                  sx={{
                    color: totalError < 0 ? "error.main" : "success.main",
                  }}
                >
                  <b>₹ {totalError.toFixed(1)}</b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {remoteData.length === 0 && (
        <Typography variant="body2" align="center" sx={{ mt: 3, mb: 2 }}>
          {loadingData === true ? "Loading..." : "No data here!"}
        </Typography>
      )}
    </Card>
  );
}
