import {
  Card,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Grid,
  Stack,
  CircularProgress,
  Modal,
  Switch,
  Divider,
  Table,
  IconButton,
} from "@mui/material";

import { ConstructionOutlined, Edit as EditIcon, SaveRounded } from "@mui/icons-material";

import { useEffect, useState } from "react";
import { dataFetch, dataPost } from "src/utils/data-fetch";
import { useAuth } from "src/contexts/AuthContext";
import EditProductModal from "./EditProductModal";
import UploadProductImage from "./UploadProductImage";
import { DateSelector, DateTimeSelector } from "src/utils/date-selector";
import { useSnackbar } from "src/contexts/SnackbarContext";
import Label from "src/pages/crm/Label";

export default function ProductProfileSection({ product }) {
  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();  

  const [currentData, setCurrentData] = useState(product);
  const [loadingData, setLoadingData] = useState(false);
  const [backInStock, setBackInStock] = useState(product?.back_in_stock);

  const updateAvailability = async (status) => {
    setLoadingData(true);

    let url = `/api/products/mgract/product/${product._id}/availability/${status}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      showSnackbar(ret.message || "Data updated successfully", "success");
      loadData();
    } else {
      showSnackbar(ret.message || "Failed to update data", "error");
    }
    setLoadingData(false);
  };

  const updateBackInStock = async (status) => {
    setLoadingData(true);

    let url = `/api/products/mgract/product/${product._id}/back-in-stock`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      { date: status },
      activeFranchise
    );

    if (ret.success === true) {
      showSnackbar(ret.message || "Data updated successfully", "success");
      loadData();
    } else {
      showSnackbar(ret.message || "Failed to update data", "error");
    }
    setLoadingData(false);
  };

  const loadData = async () => {
    setLoadingData(true);
    const response = await dataFetch(
      `/api/products/mgract/product/${product._id}`,
      await getAccessToken(),
      activeFranchise
    );

    if (response.success === true) {
      setCurrentData(response.data);
      setBackInStock(response?.data?.back_in_stock);
    } else {
      showSnackbar(response.message || "Failed to fetch data", "error");
    }
    setLoadingData(false);
  };

  const [uploadImageModal, setUploadImageModal] = useState(false);
  const closeUploadImageModal = () => {
    setUploadImageModal(false);
    loadData();
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Stack direction={"column"} spacing={1}>
            <Card sx={{ p: 2 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6">Availability</Typography>
                {loadingData === true && <CircularProgress />}

                <Switch
                  onChange={(e, c) => {
                    updateAvailability(c === true ? "1" : "0");
                  }}
                  checked={currentData?.stock > 0 ? true : false}
                  disabled={loadingData}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6">Back on</Typography>

                <Button
                  variant="outlined"
                  onClick={() => setBackInStock(Date.now())}
                  disabled={
                    loadingData || currentData?.stock > 0 || backInStock > 0
                  }
                >
                  SET DATE
                </Button>
              </Stack>
              {backInStock > 0 && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <DateSelector
                    value={backInStock}
                    onChange={setBackInStock}
                  />
                  {loadingData === false &&
                    currentData?.back_in_stock !== backInStock && (
                      <IconButton
                        onClick={() => updateBackInStock(backInStock)}
                        disabled={loadingData}
                        color="black"
                      >
                        <SaveRounded />
                      </IconButton>
                    )}
                </Stack>
              )}
            </Card>
            <Card>
              <Stack direction="column" alignItems="center" spacing={2} pb={2}>
                <div>
                  <img
                    src={
                      (currentData &&
                        currentData?.images?.length > 0 &&
                        currentData?.images[0]) ||
                      "https://milkvilla.in/images/mv_new_logo.jpg"
                    }
                    alt="Product"
                  />
                </div>

                <Typography variant="h6">
                  {currentData ? currentData.name : ""}
                </Typography>
              </Stack>
            </Card>
            <Card>
              <Stack direction="column" alignItems="start" spacing={1} p={2}>
                <b>Bio</b>
                <Typography variant="body1">
                  {currentData?.bio || "-"}
                </Typography>
              </Stack>
            </Card>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Stack direction={"column"} spacing={1}>
            <ProductPriceCard doc={currentData} />
            <ProductStockCard doc={currentData} />
          </Stack>
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          <Stack direction={"column"} spacing={1}>
            <ProductOtherCard doc={currentData} reload={loadData} />
            <ProductGSTCard doc={currentData} />
          </Stack>
        </Grid>
      </Grid>

      <Modal open={uploadImageModal} onClose={closeUploadImageModal}>
        <UploadProductImage
          handleM1Close={closeUploadImageModal}
          productId={product._id}
        />
      </Modal>
    </>
  );
}

const ProductPriceCard = ({ doc }) => {
  return (
    <Card sx={{ px: 3, py: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" gutterBottom>
          Price
        </Typography>
      </Stack>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <b>MRP:</b>
            </TableCell>
            <TableCell>
              ₹ {doc?.price || "-"} {doc?.price_unit}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <b>Price:</b>
            </TableCell>
            <TableCell>
              ₹ {doc?.disc_price || "-"} {doc?.price_unit}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <b>App Price:</b>
            </TableCell>
            <TableCell>
              ₹ {doc?.app_price || "-"} {doc?.price_unit}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <b>Sub Price:</b>
            </TableCell>
            <TableCell>
              ₹ {doc?.sub_price || "-"} {doc?.price_unit}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

const ProductStockCard = ({ doc }) => {
  return (
    <Card sx={{ px: 3, py: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" gutterBottom>
          Stock
        </Typography>
      </Stack>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <b>Inventory:</b>
            </TableCell>
            <TableCell>
              {doc?.act_stock || "0"} {doc?.price_unit?.slice(1)}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <b>In Order:</b>
            </TableCell>
            <TableCell>
              {doc?.proc_stock || "0"} {doc?.price_unit?.slice(1)}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <b>Type:</b>
            </TableCell>
            <TableCell>
              {doc?.stock_type === 0 ? "Countable" : "Uncountable"}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <b>Order Before:</b>
            </TableCell>
            <TableCell>
              {doc?.order_before === 0
                ? "No Limit on"
                : (doc?.order_before || 0) / (1000 * 60 * 60)}{" "}
              hours
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

const ProductGSTCard = ({ doc }) => {
  return (
    <Card sx={{ px: 3, py: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" gutterBottom>
          GST
        </Typography>
      </Stack>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <b>Applicable:</b>
            </TableCell>
            <TableCell>{doc?.gst_applicable ? "Yes" : "No"}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <b>Amt:</b>
            </TableCell>
            <TableCell>{doc?.gst_rate || "0"} %</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <b>HSN:</b>
            </TableCell>
            <TableCell>{doc?.hsn_code || "-"}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

const ProductOtherCard = ({ doc, reload }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const closeModalFn = () => {
    setModalOpen(false);
    reload();
  };

  const openModalFn = async () => {
    setModalOpen(true);
  };

  return (
    <Card sx={{ px: 3, py: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" gutterBottom>
          Other
        </Typography>

        <Button
          variant="outlined"
          color="error"
          startIcon={<EditIcon />}
          onClick={openModalFn}
        >
          Edit
        </Button>
      </Stack>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <b>Quantum:</b>
            </TableCell>
            <TableCell>{doc?.quantum}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <b>Weight:</b>
            </TableCell>
            <TableCell>{doc?.weight} Kg</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <b>Display Order:</b>
            </TableCell>
            <TableCell>{doc?.disp_order || "-"}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <b>Category:</b>
            </TableCell>
            <TableCell>{doc?.category?.name || "-"}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <b>Max Order:</b>
            </TableCell>
            <TableCell>{doc?.max_order || 0}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <b>Consumer:</b>
            </TableCell>
            <TableCell>
              {doc.consumer_type == 0 ? (
                <Label variant="filled" color="primary">
                  B2C
                </Label>
              ) : doc.consumer_type === 1 ? (
                <Label variant="filled" color="secondary">
                  B2B
                </Label>
              ) : doc.consumer_type === 2 ? (
                <Label variant="filled" color="info">
                  B2B + B2C
                </Label>
              ) : (
                "Unknown"
              )}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <b>Order:</b>
            </TableCell>
            <TableCell>
              {doc.order_type == 0 ? (
                <Label variant="filled" color="primary">
                  ONE TIME
                </Label>
              ) : doc.order_type === 1 ? (
                <Label variant="filled" color="secondary">
                  SUB
                </Label>
              ) : doc.order_type === 2 ? (
                <Label variant="filled" color="info">
                  ONETIME & SUB
                </Label>
              ) : (
                "Unknown"
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Modal open={modalOpen} onClose={closeModalFn}>
        <EditProductModal handleM1Close={closeModalFn} product={doc} />
      </Modal>
    </Card>
  );
};
