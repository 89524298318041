import { forwardRef, useEffect, useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Grid,
  Autocomplete,
  Box,
  OutlinedInput,
  InputAdornment,
  Drawer,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  Select,
  MenuItem,
  Chip,
  Switch,
  Button,
} from "@mui/material";

import { parseTimeString } from "src/utils/date-time-helper";
import { dataPost } from "src/utils/data-fetch";

import { LoadingButton } from "@mui/lab";

import MilkCartSearch from "../det/CartSearch";
import { CloseRounded } from "@mui/icons-material";
import AddAptSelectMap from "./AddAptSelectMap";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function getSlotValue(slot) {
  switch (slot) {
    case 1:
      return "5AM - 7AM";
    case 2:
      return "7AM - 9AM";
    case 3:
      return "9AM - 11AM";
    case 4:
      return "5PM - 7PM";
    case 5:
      return "7PM - 9PM";
    default:
      return "5AM - 7AM";
  }
}

export const societyApps = {
  none: "None",
  mygate: "My Gate",
  anacity: "Anacity",
  apartmentadda: "Apartment Adda",
  nobroker: "No Broker",
  commonfloor: "Common Floor",
};

export const societyTypes = {
  "Default": "Default" ,
  "Massive Gated Apartment": "Massive Gated Apartment (>1000 units)",
  "Large Gated Apartment": "Large Gated Apartment (500-1000 units)",
  "Gated Apartment": "Gated Apartment (100-500 units)",
  "Small Apartment": "Small Apartment (<100 units)",
  "Gated Villa": "Gated Villa",
  "Small Villa": "Small Villa",
  "Independent House": "Independent House",
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddAptModal({ handleM1Close, drawerOpen }) {
  const [error, setError] = useState(null);
  const { getAccessToken, activeFranchise } = useAuth();

  const [selLocation, setSelLocation] = useState([]);

  const [selectedCart, setSelectedCart] = useState({});
  const [cartDrawerState, setCartDrawerState] = useState(false);

  //milkvilla API

  const AddData = async (values) => {
    setError(null);
    if (selLocation.length === 0) {
      setError("No location!!");
      return;
    }

    try {
      const up = {
        name: values.name.trim(),
        flats: values.flats,
        flats_occupied: values.flats_occupied,
        tower_count: values.tower_count,
        slots: values.slots,
        pincode: values.pincode,
        society_type: values.society_type,
        property_price: values.property_price,
        property_rent: values.property_rent,
        elevator: values.elevator,
        society_app: values.society_app,
        location: {
          detail: values.address,
          coordinates: selLocation,
        },
        cart: selectedCart?._id,
        guards: [],
        manager: {
          name: values.manager_name,
          phone: values.manager_phone,
        },
      };

      const response = await dataPost(
        "/api/apartments/mgract/add",
        await getAccessToken(),
        up,
        activeFranchise
      );

      if (response.success === true) {
        console.log(response.data);
        handleM1Close();
      } else {
        console.log(response.message + " - " + response.code);
        throw new Error(response.message + " - " + response.code); // Throw for error handling
      }
    } catch (error) {
      setError(error); // Set error message in state
    }
  };

  //end milkvilla API

  //formik schema

  const DataSchema = Yup.object().shape({
    name: Yup.string().required("Name is required").trim(),
    flats: Yup.number().required("Flats is required"),
    flats_occupied: Yup.number().required("Occupied Flats is required"),
    tower_count: Yup.number().required("Towers is required"),
    slots: Yup.array().required("Slots is required"),
    manager_name: Yup.string().required("Manager Name is required"),
    manager_phone: Yup.string().required("Manager Phone is required"),
    pincode: Yup.string().required("Pincode is required"),
    society_type: Yup.string().required("Society Type is required"),
    property_price: Yup.number().required("Property Price is required"),
    property_rent: Yup.number().required("Rent is required"),
    elevator: Yup.boolean().required("Elevator is required"),
    society_app: Yup.string().required("Society App is required"),
    address: Yup.string().required("Address is required!").trim(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      flats: "",
      flats_occupied: "",
      tower_count: "",
      slots: [1, 2, 3, 4, 5],
      manager_name: "",
      manager_phone: "",
      pincode: "",
      society_type: "Default",
      property_price: "",
      property_rent: "",
      elevator: true,
      society_app: "none",
      address: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await AddData(values);
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  //end formik schema

  return (
    <Dialog
      fullScreen
      open={drawerOpen}
      onClose={handleM1Close}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            Add Apartment
          </Typography>
          <Button
            edge="end"
            color="error"
            onClick={handleM1Close}
            variant="contained"
          >
            <CloseRounded />
          </Button>
        </Toolbar>
      </AppBar>

      <Container maxWidth>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Card
              sx={{
                p: 2,
                height: "fit-content",
              }}
            >
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                      <Stack spacing={2} direction={"column"}>
                        <TextField
                          fullWidth
                          label="Name"
                          type="text"
                          {...getFieldProps("name")}
                          helperText={touched.name && errors.name}
                          error={Boolean(touched.name && errors.name)}
                          inputProps={{
                            style: { textTransform: "capitalize" },
                          }}
                        />

                        <TextField
                          fullWidth
                          type="number"
                          label="Total Flats"
                          {...getFieldProps("flats")}
                          helperText={touched.flats && errors.flats}
                          error={Boolean(touched.flats && errors.flats)}
                        />

                        <TextField
                          fullWidth
                          type="number"
                          label="Occupied Flats"
                          {...getFieldProps("flats_occupied")}
                          helperText={
                            touched.flats_occupied && errors.flats_occupied
                          }
                          error={Boolean(
                            touched.flats_occupied && errors.flats_occupied
                          )}
                        />

                        <TextField
                          fullWidth
                          type="number"
                          label="No of Towers / Lanes"
                          {...getFieldProps("tower_count")}
                          helperText={touched.tower_count && errors.tower_count}
                          error={Boolean(
                            touched.tower_count && errors.tower_count
                          )}
                        />

                        <OutlinedInput
                          fullWidth
                          type="text"
                          value={
                            (selectedCart && selectedCart.name) ||
                            "None Selected"
                          }
                          onClick={() => setCartDrawerState(true)}
                          startAdornment={
                            <InputAdornment position="start">
                              <Box sx={{ color: "text.disabled" }}>CART</Box>
                            </InputAdornment>
                          }
                        />

                        <Select
                          fullWidth
                          multiple
                          {...getFieldProps("slots")}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                                alignItems: "center",
                              }}
                            >
                              Slots:
                              {selected.map((value) => (
                                <Chip key={value} label={getSlotValue(value)} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {[1, 2, 3, 4, 5].map((slot) => (
                            <MenuItem value={slot}>
                              {getSlotValue(slot)}
                            </MenuItem>
                          ))}
                        </Select>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <Stack spacing={2} direction={"column"}>
                        <TextField
                          fullWidth
                          type="text"
                          label="Manager Name"
                          {...getFieldProps("manager_name")}
                          helperText={
                            touched.manager_name && errors.manager_name
                          }
                          error={Boolean(
                            touched.manager_name && errors.manager_name
                          )}
                        />
                        <TextField
                          type="text"
                          fullWidth
                          label="Manager Phone"
                          {...getFieldProps("manager_phone")}
                          helperText={
                            touched.manager_phone && errors.manager_phone
                          }
                          error={Boolean(
                            touched.manager_phone && errors.manager_phone
                          )}
                        />

                        <TextField
                          fullWidth
                          type="text"
                          label="Pincode"
                          {...getFieldProps("pincode")}
                          helperText={touched.pincode && errors.pincode}
                          error={Boolean(touched.pincode && errors.pincode)}
                        />

                        <TextField
                          fullWidth
                          select
                          label="Society Type"
                          {...getFieldProps("society_type")}
                          helperText={
                            touched.society_type && errors.society_type
                          }
                          error={Boolean(
                            touched.society_type && errors.society_type
                          )}
                        >
                          {Object.keys(societyTypes).map((type) => (
                            <MenuItem value={type}>{societyTypes[type]}</MenuItem>
                          ))}
                        </TextField>

                        <Stack direction={"row"} spacing={2}>
                          <TextField
                            fullWidth
                            type="number"
                            label="Property Price"
                            {...getFieldProps("property_price")}
                            helperText={
                              touched.property_price && errors.property_price
                            }
                            error={Boolean(
                              touched.property_price && errors.property_price
                            )}
                          />

                          <TextField
                            fullWidth
                            type="number"
                            label="Rent"
                            {...getFieldProps("property_rent")}
                            helperText={
                              touched.property_rent && errors.property_rent
                            }
                            error={Boolean(
                              touched.property_rent && errors.property_rent
                            )}
                          />
                        </Stack>

                        <TextField
                          fullWidth
                          label="Elevator Available?"
                          value={getFieldProps("elevator").value ? "Yes" : "No"}
                          onChange={(e) => {
                            if (e.target.value?.includes("y")) {
                              setFieldValue("elevator", true);
                            } else if (e.target.value?.includes("n")) {
                              setFieldValue("elevator", false);
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Switch
                                  checked={getFieldProps("elevator").value}
                                  onChange={(e) =>
                                    setFieldValue("elevator", e.target.checked)
                                  }
                                />
                              </InputAdornment>
                            ),
                          }}
                        />

                        <TextField
                          fullWidth
                          select
                          label="Society App"
                          {...getFieldProps("society_app")}
                        >
                          {Object.keys(societyApps).map((app) => (
                            <MenuItem value={app}>{societyApps[app]}</MenuItem>
                          ))}
                        </TextField>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        fullWidth
                        multiline
                        label="Address"
                        {...getFieldProps("address")}
                        helperText={touched.address && errors.address}
                        error={Boolean(touched.address && errors.address)}
                      />
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                      <OutlinedInput
                        fullWidth
                        disabled
                        multiline
                        type="text"
                        startAdornment={
                          <InputAdornment position="start">
                            <Box sx={{ color: "text.primary" }}>LOCATION</Box>
                          </InputAdornment>
                        }
                        value={(selLocation && selLocation.join(", ")) || "-"}
                      />
                    </Grid>
                  </Grid>

                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    sx={{ mt: 3 }}
                  >
                    Save
                  </LoadingButton>
                </Form>
              </FormikProvider>

              {error && (
                <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
                  {console.log(error)}
                  {error}
                </Alert>
              )}
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <AddAptSelectMap
              onCoordinateChange={(dt) => setSelLocation(dt)}
              onAddressUpdate={(addr) => setFieldValue("address", addr)}
            />
          </Grid>
        </Grid>
      </Container>
      <Drawer
        anchor={"right"}
        open={cartDrawerState}
        onClose={() => setCartDrawerState(false)}
        sx={{ zIndex: 1300, p: 3 }}
      >
        <MilkCartSearch
          onCartSelected={(cart) => {
            setCartDrawerState(false);
            setSelectedCart(cart);
          }}
        />
      </Drawer>
    </Dialog>
  );
}
