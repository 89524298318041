import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Stack,
  Tooltip,
} from "@mui/material";

import {
  getDateStringFullWithTimeSync,
  getDayStartAndEndSync,
  TWENTY4HOURS,
} from "src/utils/date-time-helper";

import { MobileDatePicker } from "@mui/x-date-pickers";

import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import CustomerDetailsPage from "../../home/det/CustomerDetailsPage";

const TABLE_HEAD = [
  { id: "date", label: "Date", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "quantity", label: "Quantity", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "milk_type", label: "Milk Type", alignRight: false },
  { id: "cash_payment", label: "Cash Payment", alignRight: false },
  { id: "comments", label: "Comments", alignRight: false },
];

export default function WalletSaleSection({ cart, date1, defaultList = 10 }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken } = useAuth();

  const dayStartEnd = getDayStartAndEndSync(date1 ? date1 : Date.now());
  const [startDate, setStartDate] = useState(dayStartEnd.start);

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/milkhistory/mgract/query?sort=date&dir=d&date_gt=${startDate}&date_lt=${
      startDate + TWENTY4HOURS - 1
    }&cart=${cart._id}&sort=date&dir=d`;

    const ret = await dataFetch(url, await getAccessToken());
    if (ret.success === true) {
      console.log(ret.data);
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [cart, startDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const handleCustomerSelected = (item) => {
    setSelectedCustomer(item);
    setCustomerModalOpen(true);
  };

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h5" gutterBottom>
            Wallet Sale Data
          </Typography>

          {loadingData === true && <CircularProgress color="secondary" />}

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={"en-in"}
            >
              <MobileDatePicker
                value={moment(startDate)}
                onChange={(newValue) => {
                  if (newValue) {
                    const d1 = getDayStartAndEndSync(newValue.valueOf()).start;
                    if (startDate !== d1) setStartDate(d1);
                  }
                }}
                closeOnSelect={true}
                format="DD/MM/YYYY"
                loading={loadingData}
              />
            </LocalizationProvider>
          </Stack>
        </Stack>

        {customerData.length > 0 && (
          <TableContainer sx={{ minHeight: 100 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.alignRight ? "right" : "left"}
                      sortDirection={false}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {customerData.map((item) => {
                  const color = item.deleted === "true" ? "lightgrey" : "white";
                  return (
                    <TableRow hover style={{ backgroundColor: color }}>
                      <TableCell>
                        <b> {getDateStringFullWithTimeSync(item?.date)}</b>
                      </TableCell>
                      <TableCell onClick={() => handleCustomerSelected(item?.user)}>
                        {item?.user ? item?.user?.name : "-"}
                      </TableCell>
                      <TableCell style={{ color: "red" }}>
                        {item?.quantity || "0"} Lt.
                      </TableCell>
                      <TableCell>
                        <b>₹ {item?.amount.toFixed(1)}</b>
                      </TableCell>

                      <TableCell style={{ color: "red" }}>
                        {item?.type === 0 ? "COW" : "BUFF"}
                      </TableCell>

                      <TableCell>
                        {item.cash_payment == false ? "No" : "Yes"}
                      </TableCell>
                      <Tooltip title={item.comments || ""} arrow>
                        <TableCell>
                          {item.comments ? item.comments.substring(0, 15) : "-"}
                        </TableCell>
                      </Tooltip>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {customerData.length === 0 && loadingData === false && (
          <Typography variant="body2" align="center" sx={{ mb: 4 }}>
            No results here.
          </Typography>
        )}
      </Container>

      <CustomerDetailsPage
        customer={selectedCustomer}
        drawerOpen={customerModalOpen}
        handleClose={() => setCustomerModalOpen(false)}
      />
    </Card>
  );
}
