import { styled } from "@mui/material/styles";
import { Card, CardActionArea, Typography, Stack } from "@mui/material";
import { ArrowRightRounded } from "@mui/icons-material";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme, c }) => ({
  boxShadow: "none",
  color: "black",
  border: c ? "0px" : "1px",
  borderStyle: "solid",
  borderColor: theme.palette.primary.main,
}));

export default function TopCard({ data, onClick, icon, height, color }) {
  return (
    <RootStyle onClick={onClick} c={color}>
      <CardActionArea
        sx={{
          p: 1,
          height: height || 200,
          alignContent: "center",
          backgroundColor: color || "transparent",
        }}
      >
        <Stack direction="row" justifyContent="center" alignItems={"center"}>
          {icon}
          <Typography variant="h5" mx={1} textAlign={"center"}>
            {data}
          </Typography>
        </Stack>
      </CardActionArea>
    </RootStyle>
  );
}
