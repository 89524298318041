import moment from "moment";
import { useEffect } from "react";
import React, { useState } from "react";
import { Stack, Typography, Paper, TextField, Grid } from "@mui/material";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";

import Page from "src/components/Page";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";

import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";
import { getDateStringFullWithMonthSync } from "src/utils/date-time-helper";

const MilkCartTimeline = ({ cart }) => {
  const [currentDate, setCurrentDate] = useState(
    moment().startOf("month").valueOf()
  );
  const [timelinedata, setTimelineData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const { getAccessToken, activeFranchise } = useAuth();
  const [selectedMonth, setSelectedMonth] = useState(moment().startOf("month"));

  useEffect(() => {
    const initialLoad = async () => {
      setLoadingData(true);
      const startOfMonth = selectedMonth.startOf("month").valueOf();
      const endOfMonth = selectedMonth.endOf("month").valueOf();

      const url = `/api/carttimeline/mgract/query?cart=${cart._id}&date_gt=${startOfMonth}&date_lt=${endOfMonth}`;

      const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

      if (ret.success === true) {
        setTimelineData(ret.data);
        setLoadingData(false);
      } else {
        setTimelineData([]);
      }
    };

    initialLoad();
  }, [selectedMonth, cart._id, getAccessToken, activeFranchise]);

  return (
    <Page>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 3, mb: 2, mx: 3 }}
      >
        <Typography variant="h5" gutterBottom>
          Employee Summary
        </Typography>
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale={"en-in"}
        >
          <DesktopDatePicker
            views={["year", "month"]}
            value={selectedMonth}
            minDate={moment().subtract(5, "years")} // You can adjust the minDate as needed
            onChange={(date) => setSelectedMonth(date)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Stack>

      <Stack mb={1} mt={2} spacing={1}>
        {timelinedata.length > 0 && (
          <Timeline sx={{ maxWidth: "unset" }} position="alternate">
            {timelinedata.map((item, index) => (
              <TimelineItem key={index}>
                <TimelineOppositeContent
                  sx={{ m: "auto 0" }}
                  align="left"
                  variant="body2"
                  color="text.secondary"
                >
                  <Typography variant="body2" color="textSecondary">
                    {getDateStringFullWithMonthSync(item.date)}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot
                    color={item.deleted === true ? "secondary" : "primary"}
                  />
                  {index !== timelinedata.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} sx={{ p: 3 }}>
                    <Typography variant="subtitle1" component="div">
                      {item.employee_name}
                    </Typography>

                    <div>
                      <Typography variant="subtitle2" color="CaptionText">
                        {item.update_type}
                        {item.update_type === "cleanliness" && (
                          <span style={{ marginLeft: "8px" }}>
                            ({" "}
                            {item.cleanliness.reduce(
                              (acc, clean) => acc + clean.status,
                              0
                            )}{" "}
                            / {item.cleanliness.length * 2} )
                          </span>
                        )}
                      </Typography>
                    </div>

                    <Grid container justifyContent={"zspace-between"} xs={10}>
                      {item.cleanliness.map((clean, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                          <Stack>
                            <Typography variant="body2" component="div">
                              {clean.name}:{" "}
                              {clean.status === 0
                                ? "Dirty"
                                : clean.status === 1
                                ? "Average"
                                : "Clean"}
                            </Typography>
                          </Stack>
                        </Grid>
                      ))}
                    </Grid>
                    {item.maintenance && (
                      <Stack>
                        <Typography variant="body2" component="div">
                          Technician: {item.maintenance.technician}
                        </Typography>
                        <Typography variant="body2" component="div">
                          Description: {item.maintenance.description}
                        </Typography>
                        <Typography variant="body2" component="div">
                          Parts: {item.maintenance.parts}
                        </Typography>
                        <Typography variant="body2" component="div">
                          Cost: {item.maintenance.cost}
                        </Typography>
                      </Stack>
                    )}
                    <Typography variant="body2" component="div">
                      {item.remarks}
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        )}
      </Stack>
    </Page>
  );
};

export default MilkCartTimeline;
