import {
  Grid,
  Container,
  Typography,
  Stack,
  Button,
} from "@mui/material";

import Page from "../../components/Page";
import { useNavigate } from "react-router-dom";

import InternalAccSearch from "./InternalAccSearch";
import InternalTxnPaginated from "./InternalTxnPaginated";
import TopCard from "./TopCard";

export default function InternalAccPageAdmin() {
  // const [modalOpen, setModalOpen] = useState(false);

  // const closeEmployeeModal = () => {
  //   setModalOpen(false);
  // };

  const addEmployeeClicked = () => {
    // setModalOpen(true);
  };

  const navigate = useNavigate();

  const expenseCatClicked = () => {
    navigate("expensecat");
  };

  const txnsCatClicked = () => {
    navigate("txnscat");
  };

  return (
    <Page title="Accounts Dashboard">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography variant="h4">Accounts Dashboard</Typography>

          <Button variant="contained" onClick={addEmployeeClicked}>
            Add Account
          </Button>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={6} md={3} lg={3}>
            <TopCard
              data="Expenses"
              text="Categorized"
              onClick={expenseCatClicked}
            />
          </Grid>

          <Grid item xs={6} md={3} lg={3}>
            <TopCard
              data="Txns"
              text="Categorized"
              onClick={txnsCatClicked}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <InternalAccSearch />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <InternalTxnPaginated />
          </Grid>
        </Grid>
      </Container>

      {/* <Modal open={modalOpen} onClose={closeEmployeeModal}>
        <AddEmployeeModal handleM1Close={closeEmployeeModal} />
      </Modal> */}
    </Page>
  );
}
