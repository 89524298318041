import { AddRounded } from "@mui/icons-material";
import {
  Grid,
  Container,
  Typography,
  Stack,
  Button,
  Modal,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  IconButton,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";

export default function ProductCategory({ onItemSelected }) {
  const [loadingData, setLoadingData] = useState(false);
  const [customerData, setCustomerData] = useState([]);

  const { activeFranchise, getAccessToken } = useAuth();

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/categories/mgract/query`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
      console.log(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    onItemSelected(null);
    handleSearch();
  }, [activeFranchise]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          {(loadingData && (
            <Typography variant="h4">Loading...</Typography>
          )) || <AddCategoryCard />}
        </Grid>

        {customerData.map((data) => (
          <Grid item xs={12} md={12} lg={12} key={data.id}>
            <CategoryCard data={data} onClick={() => onItemSelected(data)} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export function CategoryCard({ data, onClick }) {
  return (
    <Card sx={{ boxShadow: "3" }}>
      <CardActionArea onClick={onClick}>
        <CardMedia
          sx={{ height: 140 }}
          image={data.image}
          title="category image"
          component="img"
        />
        <CardContent>
          <Typography variant="h5" component="div">
            {data.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {data.bio}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export function AddCategoryCard({}) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
    >
      <Typography variant="h4">Categories</Typography>
      <IconButton>
        <AddRounded />
      </IconButton>
    </Stack>
  );
}
