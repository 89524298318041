import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import {
  Alert,
  Stack,
  TextField,
  Typography,
  AppBar,
  Toolbar,
  Slide,
  Dialog,
  Button,
  Switch,
  IconButton,
  Modal,
  MenuItem,
  InputAdornment,
  Select,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";
import {
  AddCircleOutline,
  AddCommentRounded,
  CloseRounded,
  UploadRounded,
} from "@mui/icons-material";

import { forwardRef } from "react";
import { DateSelector, DateTimeSelector } from "src/utils/date-selector";
import UploadNotifImage from "src/pages/notif/UploadNotifImage";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddAppBannerModal({
  drawerOpen,
  handleM1Close,
  banner,
}) {
  const [error, setError] = useState();
  const { getAccessToken, activeFranchise } = useAuth();
  const [active, setActive] = useState(true);
  const [selectedFr, setSelectedFr] = useState(banner?.app_tag || "none");

  const [actionButtonAvailable, setActionButtonAvailable] = useState(
    banner?.link ? true : false
  );

  const [liveDate, setLiveDate] = useState(Date.now());

  const [uploadModalState, setUploadModalState] = useState(false);

  const approveNow = async (values) => {
    const payload = {
      title: values.title,
      image_url: values.image_url,
      app_tag: selectedFr,
      live_time: liveDate,
    };
    if (actionButtonAvailable && values.link.lenth > 0) {
      payload.link = values.link;
      payload.link_text = values.link_text;
    }

    try {
      const ret = await dataPost(
        `/api/banners/mgract/banners/add`,
        await getAccessToken(),
        payload,
        activeFranchise
      );

      if (ret.success === true) {
        console.log("Success:", ret.data);
        formik.resetForm();
        handleM1Close();
      } else {
        setError(ret.message + " - " + ret.code);
        console.error("API Error:", ret.message + " - " + ret.code);
      }
    } catch (err) {
      setError("Unexpected error: " + err.message);
      console.error("Unexpected error:", err);
    }
  };

  const DataSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string(),
    link: Yup.string(),
    link_text: Yup.string(),
    image_url: Yup.string().required("Image URL is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: banner?.title || "",
      description: banner?.description || "",
      link: banner?.link || "",
      link_text: banner?.link_text || "",
      image_url: banner?.image_url || "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      try {
        await approveNow(values);
      } catch (error) {
        console.error("Submission error:", error);
      }
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="body"
      open={drawerOpen}
      onClose={handleM1Close}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            Add Banner
          </Typography>
          <Button variant="contained" color="error" onClick={handleM1Close}>
            <CloseRounded />
          </Button>
        </Toolbar>
      </AppBar>

      <Stack direction="column" spacing={2} p={2}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                type="text"
                label="Title"
                {...getFieldProps("title")}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />

              <TextField
                fullWidth
                multiline
                type="text"
                label="Description"
                {...getFieldProps("description")}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />

              <Stack direction="row" spacing={2} alignItems={"center"}>
                Action Button?
                <Switch
                  checked={actionButtonAvailable}
                  onChange={() =>
                    setActionButtonAvailable(!actionButtonAvailable)
                  }
                />
                {actionButtonAvailable === true && (
                  <TextField
                    fullWidth
                    type="text"
                    label="Action Text"
                    {...getFieldProps("link_text")}
                    error={Boolean(touched.link_text && errors.link_text)}
                    helperText={touched.link_text && errors.link_text}
                  />
                )}
              </Stack>
              {actionButtonAvailable === true && (
                <TextField
                  fullWidth
                  multiline
                  type="link"
                  label="Link"
                  {...getFieldProps("link")}
                  error={Boolean(touched.link && errors.link)}
                  helperText={touched.link && errors.link}
                />
              )}

              <Stack direction="row" spacing={2} alignItems="center">
                <TextField
                  fullWidth
                  type="text"
                  label="Image URL or upload"
                  {...getFieldProps("image_url")}
                  error={Boolean(touched.image_url && errors.image_url)}
                  helperText={touched.image_url && errors.image_url}
                />
                <IconButton
                  color="primary"
                  onClick={() => setUploadModalState(true)}
                  sx={{ ml: 1 }}
                >
                  <UploadRounded />
                </IconButton>
              </Stack>

              <Stack direction="row" spacing={2} alignItems="center">
                <Typography variant="body1">App Tag</Typography>

                <Select
                  id="app-tag"
                  value={selectedFr}
                  onChange={(event) => setSelectedFr(event.target.value)}
                >
                  <MenuItem value={"none"}>None</MenuItem>
                  <MenuItem value={"refer"}>Refer</MenuItem>
                  <MenuItem value={"test"}>Test</MenuItem>
                </Select>
              </Stack>

              <Stack direction="row" spacing={2} alignItems="center">
                <Typography variant="body1">Live time</Typography>
                <DateTimeSelector value={liveDate} onChange={setLiveDate} />
              </Stack>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>
      </Stack>

      <Modal open={uploadModalState} onClose={() => setUploadModalState(false)}>
        <UploadNotifImage
          handleM1Close={() => setUploadModalState(false)}
          returnUrl={(url) => setFieldValue("imageUrl", url)}
        />
      </Modal>

      {error && (
        <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      {/* <Modal open={uploadModalState} onClose={() => setUploadModalState(false)}>
        <UploadNotifImage
          handleM1Close={() => setUploadModalState(false)}
          returnUrl={(url) => setFieldValue("imageUrl", url)}
        />
      </Modal> */}
    </Dialog>
  );
}
