import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";

import {
  Card,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Stack,
  Tooltip,
} from "@mui/material";

import {
  getDateStringFullWithTimeSync,
  getDayStartAndEndSync,
  getTimeStringFullSync,
  TWENTY4HOURS,
} from "src/utils/date-time-helper";

import { MobileDatePicker } from "@mui/x-date-pickers";

import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { LocationOnRounded } from "@mui/icons-material";

const TABLE_HEAD = [
  { id: "date", label: "Date", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "pos_id", label: "Pos Id", alignRight: false },
  { id: "order_id", label: "Order Id", alignRight: false },
  { id: "time", label: "Time", alignRight: false },
  { id: " merchant", label: "Merchant", alignRight: false },
  { id: "payment_mode", label: "Payment Mode", alignRight: false },
  { id: "verified", label: "Verified", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "comments", label: "Comments", alignRight: false },
];

export default function OnlineMoneyDrawer({ cart, date1, defaultList = 10 }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [page, setPage] = useState(0);

  const { getAccessToken } = useAuth();

  const dayStartEnd = getDayStartAndEndSync(date1 ? date1 : Date.now());
  const [startDate, setStartDate] = useState(dayStartEnd.start);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/onlinepayments/mgract/query?finish_time_gt=${startDate}&finish_time_lt=${
      startDate + TWENTY4HOURS - 1
    }&cart=${cart._id}&category=3&skip=${
      page * defaultList
    }&limit=${defaultList}&sort=date&dir=d`;

    const ret = await dataFetch(url, await getAccessToken());
    if (ret.success === true) {
      console.log(ret.data);
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [page, cart, startDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h5" gutterBottom>
            Online Money
          </Typography>

          {loadingData === true && <CircularProgress color="secondary" />}

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={"en-in"}
            >
              <MobileDatePicker
                value={moment(startDate)}
                onChange={(newValue) => {
                  if (newValue) {
                    const d1 = getDayStartAndEndSync(newValue.valueOf()).start;
                    if (startDate !== d1) setStartDate(d1);
                  }
                }}
                closeOnSelect={true}
                format="DD/MM/YYYY"
                loading={loadingData}
              />
            </LocalizationProvider>
          </Stack>
        </Stack>

        {customerData.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {customerData.map((item) => {
                    const color =
                      item.deleted === "true" ? "lightgrey" : "white";
                    return (
                      <TableRow hover style={{ backgroundColor: color }}>
                        <TableCell>
                          <b>
                            {" "}
                            {getDateStringFullWithTimeSync(
                              parseInt(item?._id.substring(0, 8), 16) * 1000
                            )}
                          </b>
                        </TableCell>
                        <TableCell>
                          <b>₹ {item.amount.toFixed(1)}</b>
                        </TableCell>
                        <TableCell style={{ color: "green" }}>
                          {item.user ? item?.user?.name : "-"}
                        </TableCell>
                        <TableCell style={{ color: "red" }}>
                          {item.pos_id ? item.pos_id : "-"}
                        </TableCell>
                        <TableCell style={{ color: "red" }}>
                          {item.order_id ? item.order_id : "-"}
                        </TableCell>
                        <TableCell style={{ color: "red" }}>
                          {getTimeStringFullSync(item?.time)}
                        </TableCell>
                        <TableCell style={{ color: "red" }}>
                          {item.merchant ? item.merchant : "-"}
                        </TableCell>
                        <TableCell style={{ color: "red" }}>
                          {item.payment_mode ? item.payment_mode : "-"}
                        </TableCell>
                        <TableCell style={{ color: "red" }}>
                          {" "}
                          {item.verified === 0
                            ? "Unverified"
                            : item.verified === 1
                            ? "Verified +ve"
                            : item.verified === -1
                            ? "Verified -ve"
                            : "Unverified"}
                        </TableCell>
                        <TableCell>
                          {" "}
                          {item.status === 0
                            ? "PENDING"
                            : item.status === 1
                            ? "SUCESS"
                            : item.status === -1
                            ? "FAILED"
                            : item.status === 2
                            ? "FAILED"
                            : "FAILED"}
                        </TableCell>
                        <Tooltip title={item.comments || ""} arrow>
                          <TableCell>
                            {item.comments
                              ? item.comments.substring(0, 15)
                              : "-"}
                          </TableCell>
                        </Tooltip>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={-1}
              rowsPerPage={10}
              rowsPerPageOptions={[10]}
              page={page}
              onPageChange={handleChangePage}
            />
          </div>
        )}

        {customerData.length === 0 && loadingData === false && (
          <div>
            <Typography gutterBottom align="center" variant="subtitle1">
              Not found
            </Typography>
            <Typography variant="body2" align="center" sx={{ mb: 4 }}>
              No results here.
            </Typography>
          </div>
        )}
      </Container>
    </Card>
  );
}
