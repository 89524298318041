import { useState, useEffect, useCallback } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch, dataPost } from "src/utils/data-fetch";
import {
  getDateStringFullWithTimeSync2,
  getDayStartAndEnd,
} from "src/utils/date-time-helper";

import {
  Card,
  Typography,
  Stack,
  Modal,
  IconButton,
  Box,
  Tooltip,
  CardActionArea,
} from "@mui/material";

import CallDetails from "../calls/CallDetails";

import {
  CallMadeRounded,
  CallMissedOutgoingRounded,
  CallReceivedRounded,
  CallRounded,
  MessageRounded,
  RefreshRounded,
} from "@mui/icons-material";

import CommentModal from "./CommentModal";
import CustomerDetailsPage from "../home/det/CustomerDetailsPage";

export default function CallHistoryView({ date }) {
  const [allCallData, setAllCallData] = useState([]);
  const [liveCallData, setLiveCallData] = useState(null);

  const [loadingData, setLoadingData] = useState(false);
  const [loadingLiveCall, setLoadingLiveCall] = useState(false);

  const [customerDetailsOpen, setCustomerDetailsOpen] = useState(false);
  const [customer, setCustomer] = useState({});

  const handleCustomerDetailsOpen = (cust) => {
    setCustomerDetailsOpen(true);
    console.log(customerDetailsOpen);
    setCustomer(cust);
  };

  const [page, setPage] = useState(0);

  const { getAccessToken, activeFranchise } = useAuth();

  const loadData = async () => {
    setLoadingData(true);
    const times = await getDayStartAndEnd(date);

    let url = `/api/salesflags/mgract/replies/query/my?sort=date&category=call&dir=d&date_gt=${times.start}&date_lt=${times.end}`;

    console.log(date);
    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setAllCallData(ret.data);
    }

    setLoadingData(false);
  };

  const liveCallDataFetch = async () => {
    setLoadingLiveCall(true);

    let url = `/api/salesflags/mgract/livecall2`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setLiveCallData(ret.data);
      console.log(ret.data);
    }

    setLoadingLiveCall(false);
  };

  const fetchData = useCallback(() => {
    loadData();
  }, [liveCallDataFetch]);

  useEffect(() => {
    loadData();
  }, [date]);

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, [page]);

  useEffect(() => {
    liveCallDataFetch();
  }, []);

  const [commentModalState, setCommentModalState] = useState(false);
  const [selectedCall, setSelectedCall] = useState(null);
  const [selCallModalState, setSelCallModalState] = useState(false);

  const handleCallDetailsSelected = (callData) => {
    setSelectedCall(callData);
    setSelCallModalState(true);
  };

  const handleCallDetailsClose = () => {
    setSelCallModalState(false);
    loadData();
  };

  const initiateCall = async (callData) => {
    let url = `/api/salesflags/mgract/sfr/${callData._id}/initiate`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      {},
      activeFranchise
    );

    if (ret.success === true) {
      alert("Call initiated successfully!");
    } else {
      console.log(ret.message);
      alert("Failed to initiate call");
    }
  };

  const handleCommentSelected = (callData) => {
    setSelectedCall(callData);
    setCommentModalState(true);
  };

  return (
    <div>
      <div>
        <Card
          sx={{
            backgroundColor: loadingLiveCall ? "#f0f0f0" : "#e6ffcc",
            borderLeft: "4px solid #00838f",
            margin: 1,
          }}
        >
          {loadingLiveCall === true ? (
            <Stack direction="row" justifyContent="space-between" p={1}>
              <Typography
                variant="body1"
                component="div"
                sx={{ margin: 1, color: "#ff5050", fontWeight: "bold" }}
              >
                Loading Data ...
              </Typography>
            </Stack>
          ) : liveCallData ? (
            <CardActionArea
              onClick={() => handleCustomerDetailsOpen(liveCallData)}
              sx={{ padding: 1 }}
            >
              <Stack direction="column">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row" spacing={1}>
                    {liveCallData.action === "user" ? (
                      <CallReceivedRounded />
                    ) : (
                      <CallMadeRounded />
                    )}

                    <Typography variant="subtitle1" color="text.secondary">
                      {liveCallData.phone}
                    </Typography>
                  </Stack>

                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      liveCallDataFetch();
                      fetchData();
                      //setPage(0);
                    }}
                  >
                    <RefreshRounded />
                  </IconButton>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {liveCallData.name}
                  </Typography>
                  {/* <Avatar
                    alt={liveCallData.name}
                    src={liveCallData.avatarUrl}
                  /> */}
                  <Typography variant="subtitle1" color="text.secondary">
                    {liveCallData.entity}
                  </Typography>
                </Stack>
              </Stack>
            </CardActionArea>
          ) : (
            <Stack direction="row" justifyContent="space-between" p={1}>
              <Typography
                variant="body1"
                component="div"
                sx={{ margin: 1, color: "#ff5050", fontWeight: "bold" }}
              >
                No ongoing call ...
              </Typography>
              <IconButton
                onClick={() => {
                  liveCallDataFetch();
                  setPage(0);
                }}
              >
                <RefreshRounded />
              </IconButton>
            </Stack>
          )}
        </Card>

        {/* Call history section */}
        {loadingData === true && <Typography>Loading...</Typography>}

        {loadingData === false &&
          allCallData.map((item) => {
            return (
              <Card
                key={item._id}
                sx={{
                  m: 1,
                  p: 1,
                  ...(item?.flag !== null && {
                    border: "1px solid rgba(0, 131, 143, 0.3)",
                    boxShadow: "none",
                  }),
                }}
              >
                <Tooltip title={item?.comments} arrow>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      onClick={(e) => {
                        handleCallDetailsSelected(item);
                      }}
                    >
                      <Stack direction="row">
                        {item?.call_details?.status === "missed" ? (
                          <CallMissedOutgoingRounded
                            color="error"
                            sx={{ mr: 1 }}
                          />
                        ) : item?.action === "user" ? (
                          <CallReceivedRounded sx={{ mr: 1 }} />
                        ) : (
                          <CallMadeRounded sx={{ mr: 1 }} />
                        )}

                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          gutterBottom
                        >
                          <Box component="span" sx={{ mr: 1 }}>
                            {item?.entity}
                          </Box>
                          -
                          <Box component="span" sx={{ ml: 1 }}>
                            {getDateStringFullWithTimeSync2(item?.date)}
                          </Box>
                        </Typography>
                      </Stack>

                      <Stack direction="row" justifyContent="start">
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          sx={{ mr: 2 }}
                        >
                          {item?.name} ({item?.phone})
                        </Typography>
                      </Stack>
                    </Stack>

                    {item.call_details.status === "pending" ? (
                      <IconButton
                        color="success"
                        onClick={(e) => {
                          initiateCall(item);
                          e.stopPropagation();
                        }}
                      >
                        <CallRounded />
                      </IconButton>
                    ) : (
                      item?.comments == null && (
                        <IconButton
                          color="error"
                          onClick={(e) => {
                            handleCommentSelected(item);
                            e.stopPropagation();
                          }}
                        >
                          <MessageRounded />
                        </IconButton>
                      )
                    )}
                  </Stack>
                </Tooltip>
              </Card>
            );
          })}
      </div>

      <CustomerDetailsPage
        customer={{ _id: customer.user }}
        handleClose={() => setCustomerDetailsOpen(false)}
        drawerOpen={customerDetailsOpen}
      />

      <Modal
        open={commentModalState}
        onClose={() => setCommentModalState(false)}
      >
        <CommentModal
          details={selectedCall}
          handleClose={() => {
            setCommentModalState(false);
            loadData();
          }}
        />
      </Modal>

      <Modal open={selCallModalState} onClose={handleCallDetailsClose}>
        <CallDetails
          callDet={selectedCall}
          handleClose={handleCallDetailsClose}
        />
      </Modal>
    </div>
  );
}
