import React, { useContext, useState, useEffect } from "react";
import fa from "../firebase";
import {
  signInWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signOut,
  getIdToken,
  signInWithCustomToken,
} from "firebase/auth";

import { CircularProgress } from "@mui/material";

import { dataFetch } from "../utils/data-fetch";

const auth = getAuth(fa);

const AuthContext = React.createContext();
export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [currentManager, setCurrentManager] = useState();
  const [loading, setLoading] = useState(true);

  const [activeFranchise, setActiveFranchise] = useState(
    localStorage.getItem("active_franchise") || null
  );
  const [allFranchisees, setAllFranchisees] = useState([]);

  const [currentAccess, setCurrentAccess] = useState(null);

  function handleFranchiseChange(fr, mgr) {
    setActiveFranchise(fr);
    localStorage.setItem("active_franchise", fr);

    for (const d of mgr?.access || currentManager?.access || []) {
      if (d?.fr?._id === fr) {
        setCurrentAccess(d);
        break;
      }
    }
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function linkLogin(token) {
    return signInWithCustomToken(auth, token);
  }

  function logout() {
    return signOut(auth);
  }

  function getAccessToken(refresh) {
    return currentUser ? getIdToken(currentUser, refresh) : null;
  }

  async function getUserDetails(token) {
    const ret = await dataFetch(`/api/managers/mgract/profile/v3`, token);

    if (ret.success === true) {
      setCurrentManager(ret.data);

      handleFranchiseChange(activeFranchise || ret.data?.franchisee, ret.data);

      loadAllFranchisees(token);
    } else {
      setCurrentManager(null);
      setActiveFranchise(null);
    }

    setLoading(false);
  }

  async function loadAllFranchisees(token) {
    const ret = await dataFetch(
      `/api/franchisees/coreact/query?active=true`,
      token
    );

    if (ret.success === true) {
      setAllFranchisees(ret.data);
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLoading(true);
      setCurrentUser(user);
      if (user) {
        getUserDetails(user.accessToken);
      } else setLoading(false);
    });
    return unsubscribe;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const value = {
    currentUser,
    currentManager,
    activeFranchise,
    currentAccess,
    allFranchisees,
    handleFranchiseChange,
    getAccessToken,
    login,
    linkLogin,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {(loading === false && children) || (
        <CircularProgress
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
    </AuthContext.Provider>
  );
}
