import { useState } from "react";
import { useLocation } from "react-router-dom";

import { Container, Typography, Box, Grid, Tab, Stack } from "@mui/material";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Page from "src/components/Page";
import AdminEmployeeProfile from "./AdminEmployeeProfile";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AdminEmployeeDetailsPage({ incEmployee }) {
  const location = useLocation();

  let employee = location.state?.employee;
  if (!employee) {
    employee = incEmployee;
  }

  const [currentMenu, setCurrentMenu] = useState("general");

  const handleTabChange = (event, newValue) => {
    setCurrentMenu(newValue);
  };

  return (
    <Page title="Employee Dashboard">
      <Container>
        <TabContext value={currentMenu}>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="h4">{employee.name}</Typography>
            <TabList onChange={handleTabChange}>
              <Tab label="Profile" value="general" {...a11yProps(0)} />
            </TabList>
          </Stack>
          <TabPanel value="general">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <AdminEmployeeProfile employee={employee} />
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Container>
    </Page>
  );
}
