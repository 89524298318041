import React, { forwardRef } from "react";
import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch, dataPatch } from "src/utils/data-fetch";

import {
  Card,
  Container,
  Typography,
  CircularProgress,
  Stack,
  CardActionArea,
  Grid,
  CardMedia,
  Divider,
  Modal,
  Button,
  Tooltip,
  Dialog,
  Slide,
} from "@mui/material";

import Label from "src/pages/crm/Label";
import { getDateStringFullWithSlash } from "src/utils/date-time-helper";
import ModifySubModal from "./ModifySubModal";
import { PauseCircleRounded, PlayCircleRounded } from "@mui/icons-material";
import { useSnackbar } from "src/contexts/SnackbarContext";

export const SubscriptionListSection = ({ customer }) => {
  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const loadData = async () => {
    setLoadingData(true);

    await new Promise((resolve) => setTimeout(resolve, 200));

    let url = `/api/products/mgract/subscriptions/query?user=${customer._id}&sort=start_date&dir=d`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [editModalState, setEditModalState] = useState(null);
  const closeEditModal = () => {
    setEditModalState(null);
    loadData();
  };

  const pauseResumeSub = async (id) => {
    setLoadingData(true);

    const ret = await dataPatch(
      `/api/products/mgract/subscriptions/sub/${id}`,
      await getAccessToken(),
      {},
      activeFranchise
    );

    if (ret.success === true) {
      showSnackbar("Subscription updated from tomorrow", "success");
      loadData();
    } else {
      showSnackbar(ret.message + " - " + ret.code, "error");
    }
  };

  return (
    <Container>
      <Grid container spacing={2}>
        {loadingData === true ? (
          <CircularProgress sx={{ m: 3 }} />
        ) : (
          (customerData?.length > 0 &&
            customerData.map((data, index) => (
              <Grid item xs={12} md={6} lg={6}>
                <CardProduct
                  data={data}
                  onClick={() => {
                    setEditModalState(data);
                  }}
                  pauseResume={() => {
                    pauseResumeSub(data._id);
                  }}
                />
              </Grid>
            ))) || (
            <Grid item xs={12}>
              <Typography variant="h6" align="center" mt={5}>
                No subscriptions!
              </Typography>
            </Grid>
          )
        )}
      </Grid>

      {editModalState && (
        <Dialog
          maxWidth="md"
          fullWidth
          open={editModalState !== null}
          onClose={closeEditModal}
          scroll="body"
          TransitionComponent={Transition}
        >
          <ModifySubModal
            handleM1Close={closeEditModal}
            subId={editModalState?._id}
          />
        </Dialog>
      )}
    </Container>
  );
};

const CardProduct = ({ data, onClick, pauseResume }) => {
  return (
    <Card
      sx={{
        boxShadow: "3",
        display: "flex",
        backgroundColor:
          data.status === "active" || data.status === "paused"
            ? "#fff"
            : "lightgrey",
      }}
    >
      <CardActionArea onClick={onClick}>
        <Tooltip title={data.message}>
          <Stack direction={"row"}>
            <CardMedia sx={{ width: 150 }} image={data?.product?.images[0]} />
            <Divider orientation="vertical" flexItem />

            <Stack direction={"column"} px={2} py={1}>
              <Typography component="div" variant="h5">
                {data?.product?.name}
              </Typography>

              <Stack direction={"row"} spacing={2} mt={1}>
                <Typography variant="subtitle1" color="black">
                  {data.quantity}{" "}
                  {data?.product?.priceUnit?.substring(1) || "Kg"}
                </Typography>
                {data?.product?.sub_price > 0 && (
                  <Typography variant="subtitle1" color="red">
                    {" @ ₹ "}
                    {(data.product.sub_price - (data?.discount || 0)) *
                      data.quantity}
                  </Typography>
                )}
                {data?.discount > 0 && (
                  <Typography variant="subtitle1" color="green">
                    {` (discount ₹ ${data?.discount * data.quantity})`}
                  </Typography>
                )}
              </Stack>

              <Typography variant="body1" color="black" mt={1}>
                {data?.slot === 1
                  ? "5AM - 7AM"
                  : data?.slot === 2
                  ? "7AM - 9AM"
                  : data?.slot === 3
                  ? "9AM - 11AM"
                  : data?.slot === 4
                  ? "5PM - 7PM"
                  : data?.slot === 5
                  ? "7PM - 9PM"
                  : "ANYTIME"}
                {" - "}
                <b>{data?.frequency?.toUpperCase()}</b>
                {data?.frequency === "weekly" && ` (${data?.weekly_days})`}
                {" -  starting "}
                {getDateStringFullWithSlash(data?.start_date)}
              </Typography>
              {data?.end_date > 0 && (
                <Typography variant="body1" color="black" mt={1}>
                  Ended: {getDateStringFullWithSlash(data?.start_date)}
                </Typography>
              )}
              <Label
                variant="filled"
                color={
                  data.status === "active"
                    ? "success"
                    : data.status === "paused"
                    ? "warning"
                    : "error"
                }
                children={data.status?.toUpperCase()}
                sx={{ width: "200px", mt: 2 }}
              />
            </Stack>
          </Stack>

          {["active", "paused"].includes(data?.status) && (
            <Button
              sx={{
                position: "absolute",
                right: "10px",
                bottom: "10px",
                boxShadow: 1,
                alignItems: "center",
              }}
              variant="contained"
              color={data?.status == "paused" ? "success" : "warning"}
              startIcon={
                data?.status == "paused" ? (
                  <PlayCircleRounded />
                ) : (
                  <PauseCircleRounded />
                )
              }
              onClick={(e) => {
                e.stopPropagation();
                pauseResume();
              }}
            >
              {data?.status == "paused" ? "Resume" : "Pause"}
            </Button>
          )}

          {data?.free_sample === true && (
            <Label
              variant="filled"
              color={"error"}
              children={"FREE SAMPLE"}
              sx={{ position: "absolute", right: "10px", top: "10px" }}
            />
          )}
        </Tooltip>
      </CardActionArea>
    </Card>
  );
};
