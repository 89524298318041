import {
  Card,
  Typography,
  Box,
  Grid,
  Stack,
  CircularProgress,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

import { getDateStringFullWithMonthSync } from "src/utils/date-time-helper";

export default function FarmerInfoCard({ farmer, loadingData, reload }) {
  return (
    <>
      <Card>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: 3, mx: 3 }}
        >
          <Typography variant="h5" gutterBottom>
            Details
          </Typography>

          {loadingData === true && <CircularProgress />}
        </Stack>

        {farmer && (
          <Grid container columnSpacing={1} sx={{ pl: 2, pr: 2 }}>
            <Grid item xs={12} md={6} lg={6}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Name:</b>
                  </TableCell>
                  <TableCell>{farmer.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Code:</b>
                  </TableCell>
                  <TableCell>{farmer.code || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Phone:</b>
                  </TableCell>
                  <TableCell>{farmer.phone || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Address:</b>
                  </TableCell>
                  <TableCell>{farmer.address_det}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>Milk Rate:</b>
                  </TableCell>
                  <TableCell>
                    {(farmer.milk_coll_rate_offset &&
                      ((farmer.milk_coll_rate_offset >= 0 &&
                        "+ ₹ " + farmer.milk_coll_rate_offset) ||
                        "- ₹ " + Math.abs(farmer.milk_coll_rate_offset))) ||
                      "-"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Joining:</b>
                  </TableCell>
                  <TableCell>
                    {getDateStringFullWithMonthSync(
                      parseInt(farmer._id.substring(0, 8), 16) * 1000
                    )}
                  </TableCell>
                </TableRow>
                {farmer.center && (
                  <TableRow>
                    <TableCell>
                      <b>Center:</b>
                    </TableCell>
                    <TableCell>{farmer.center.name}</TableCell>
                  </TableRow>
                )}
                {farmer.village && (
                  <TableRow>
                    <TableCell>
                      <b>Village:</b>
                    </TableCell>
                    <TableCell>{farmer.village.name}</TableCell>
                  </TableRow>
                )}

                {farmer.created_by && (
                  <TableRow>
                    <TableCell>
                      <b>Created:</b>
                    </TableCell>
                    <TableCell>{farmer.created_by.name}</TableCell>
                  </TableRow>
                )}

                {farmer.wallet && (
                  <TableRow>
                    <TableCell>
                      <b>Balance:</b>
                    </TableCell>
                    <TableCell>₹ {farmer.wallet.balance}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Grid>
          </Grid>
        )}

        <Stack direction="row">
          <Box sx={{ flexGrow: 1 }} />
        </Stack>
      </Card>
    </>
  );
}
