import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";

import { styled } from "@mui/material/styles";
import { dataFetch } from "../../utils/data-fetch";

import { AddRounded, RefreshRounded, SearchRounded } from "@mui/icons-material";

import MenuItem from "@mui/material/MenuItem";

import {
  Card,
  Container,
  Table,
  Box,
  OutlinedInput,
  InputAdornment,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  CircularProgress,
  Stack,
  IconButton,
  TextField,
  Modal,
} from "@mui/material";
import MakeFullScreen from "src/components/MakeFullScreen";
import AddSaleModal from "./AddSaleModal";
import { PartyDetailsModal } from "./PartyDetailsModal";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "address", label: "Address", alignRight: false },
  { id: "balance", label: "Balance", alignRight: false },
  { id: "actions", label: "Actions", alignRight: true },
];

export default function AllParty({ open, onClose }) {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("balance");
  const [selectedActive, setSelectedActive] = useState("true");

  const { getAccessToken, activeFranchise } = useAuth();

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/extrasales/mgract/users/query`;

    if (selectedActive !== "all") {
      url += `?active=${selectedActive}`;
    }

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
      console.log(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [activeFranchise, selectedActive]); // eslint-disable-line react-hooks/exhaustive-deps

  const filtered = customerData
    .filter((item) => {
      return (
        item.name.toLowerCase().includes(search) ||
        item?.bulksale?.address?.toLowerCase().includes(search)
      );
    })
    .sort((a, b) => {
      if (sort === "name") {
        return a.name.localeCompare(b.name);
      } else if (sort === "address") {
        return a?.bulksale?.address?.localeCompare(b?.bulksale?.address);
      } else if (sort === "balance") {
        return a.balance - b.balance;
      }
    });

  const [saleModalState, setSaleModalState] = useState(null);
  const [detailsModalState, setDetailsModalState] = useState(null);

  return (
    <MakeFullScreen open={open} onClose={onClose} title="Search Party">
      <>
        <Container sx={{ minHeight: 600 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
            mt={2}
            spacing={2}
          >
            <TextField
              select
              label="Active"
              value={selectedActive}
              onChange={(e) => setSelectedActive(e.target.value)}
              variant="outlined"
              sx={{ minWidth: 100 }}
            >
              <MenuItem value="true">Active</MenuItem>
              <MenuItem value="false">Inactive</MenuItem>
              <MenuItem value="all">All</MenuItem>
            </TextField>

            <SearchStyle
              placeholder="Search Customer..."
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
              startAdornment={
                <InputAdornment position="start">
                  <Box sx={{ color: "text.disabled" }}>
                    <SearchRounded />
                  </Box>
                </InputAdornment>
              }
              endAdornment={
                loadingData === true && <CircularProgress color="secondary" />
              }
              sx={{ mt: 4, mb: 4 }}
            />
            <TextField
              select
              label="Sort"
              value={sort}
              onChange={(e) => setSort(e.target.value)}
              variant="outlined"
              sx={{ minWidth: 100 }}
            >
              <MenuItem value="balance">Balance</MenuItem>
              <MenuItem value="name">Name</MenuItem>
              <MenuItem value="address">Address</MenuItem>
            </TextField>
            <IconButton onClick={handleSearch}>
              <RefreshRounded />
            </IconButton>
          </Stack>
          {filtered.length > 0 && (
            <div>
              <TableContainer sx={{ minHeight: 100 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.alignRight ? "right" : "left"}
                          sortDirection={false}
                        >
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filtered.map((item) => {
                      return (
                        <TableRow
                          hover
                          onClick={() => setDetailsModalState(item)}
                          style={{
                            backgroundColor:
                              item.status === "inactive" ? "#ddd" : "white",
                            alignItems: "center",
                          }}
                        >
                          <TableCell>
                            <b>{item?.name || "-"}</b>
                          </TableCell>
                          <TableCell>
                            {item?.bulksale?.address?.substring(0, 30) || "-"}
                          </TableCell>
                          <TableCell color="error">
                            <b>
                              ₹{" "}
                              {item?.balance
                                ? item.balance?.toLocaleString("en-IN")
                                : "0"}
                            </b>
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              sx={{ color: "success.dark" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setSaleModalState(item);
                              }}
                            >
                              <AddRounded />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </Container>

        {saleModalState && (
          <Modal open={true} onClose={() => setSaleModalState(false)}>
            <AddSaleModal
              party={saleModalState}
              handleM1Close={() => setSaleModalState(false)}
            />
          </Modal>
        )}

        {detailsModalState && (
          <PartyDetailsModal
            party={detailsModalState}
            handleClose={() => setDetailsModalState(false)}
            drawerOpen={true}
          />
        )}
      </>
    </MakeFullScreen>
  );
}
