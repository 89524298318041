import { useState, useEffect } from "react";
import { Typography, Card, Grid } from "@mui/material";

import { useAuth } from "../../contexts/AuthContext";
import { dataFetch } from "../../utils/data-fetch";

import WeekChartComponent from "src/components/week-chart-component";
import { getWeekStartAndEndSync } from "src/utils/date-time-helper";

export default function MilkCartsWeeklySection({ w1, w2 }) {
  const wse1 = getWeekStartAndEndSync(w1);
  const wse2 = getWeekStartAndEndSync(w2);

  let prevStartDate = wse1.start;
  let prevEndDate = wse1.end;
  let startDate = wse2.start;
  let endDate = wse2.end;

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);

  const [loadingData, setLoadingData] = useState(true);
  const [len, setLen] = useState(0);

  const { getAccessToken, activeFranchise } = useAuth();

  const week1Load = async () => {
    setLoadingData(true);

    const url = `/api/milkcarts/mgract/getdispense?start=${prevStartDate}&end=${prevEndDate}&type=monthly`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    console.log("token", getAccessToken());
    if (ret) {
      setData1(ret.data);
    } else {
      setData1([]);
    }

    if (ret.data.length > 0) {
      setLen(ret.data.length);
    }

    setLoadingData(false);
  };

  const week2Load = async () => {
    setLoadingData(true);

    const url = `/api/milkcarts/mgract/getdispense?start=${startDate}&end=${endDate}&type=monthly`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret) {
      setData2(ret.data);
    } else {
      setData2([]);
    }

    if (ret.data.length > 0) {
      setLen(ret.data.length);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    week1Load();
  }, [activeFranchise, w1]);

  useEffect(() => {
    week2Load();
  }, [activeFranchise, w2]);

  console.log(data1, data2, len)

  return (
    <Grid container spacing={3} mt={1}>
      {Array.from({ length: len }, (_, i) => (
        <Grid item xs={12} md={12} lg={6}>
          <Card sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>
              {data2[i]?.name} ({data2[i]?.driver_name})
            </Typography>

            <WeekChartComponent
              prevData={data1[i]}
              data={data2[i]}
              prevDate={w1}
              date={w2}
            />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
