import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { styled } from "@mui/material/styles";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import { Card, Alert, Stack, TextField, Typography } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPatch } from "src/utils/data-fetch";

export default function ApproveAccPage({ handleM1Close, employee, url, acc }) {
  const [error, setError] = useState();

  const { getAccessToken, activeFranchise } = useAuth();

  const approveNow = async (values) => {
    let url = `/api/employees/mgract/v2/employee/${employee.employee}/documents`;

    const ret = await dataPatch(
      url,
      await getAccessToken(),
      {
        category: "passbook",
        bank_account: {
          number: values.number,
          name: values.name,
          ifsc: values.ifsc,
        },
      },
      activeFranchise
    );
    if (ret.success === true) {
      console.log(ret.data);
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    number: Yup.string().required("Acc number is required"),
    ifsc: Yup.string().required("IFSC is required"),
    name: Yup.string().required("Beneficiary name is required"),
  });

  const formik = useFormik({
    initialValues: {
      number: acc,
      ifsc: "",
      name: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const ProductImgStyle = styled("img")({
    width: "70%",
    height: "100%",
  });

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Approve Account
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
          <ProductImgStyle alt={"Passbook Image"} src={url} />

          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  type="text"
                  label="Acc Number"
                  {...getFieldProps("number")}
                  error={Boolean(touched.number && errors.number)}
                  helperText={touched.number && errors.number}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="IFSC"
                  {...getFieldProps("ifsc")}
                  error={Boolean(touched.ifsc && errors.ifsc)}
                  helperText={touched.ifsc && errors.ifsc}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="Name"
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Stack>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Save
              </LoadingButton>
            </Form>
          </FormikProvider>
        </Stack>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
