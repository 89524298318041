import CallDetails from "./CallDetails";
import { dataFetch } from "src/utils/data-fetch";
import {
  getDateStringFullWithTimeSync,
  getDayStartAndEndSync,
} from "src/utils/date-time-helper";
import Label from "../crm/Label";
import {
  CallMadeRounded,
  CallReceivedRounded,
  RefreshRounded,
} from "@mui/icons-material";

import {
  Card,
  Container,
  Stack,
  TableHead,
  TableRow,
  Modal,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TablePagination,
  Typography,
  MenuItem,
  CircularProgress,
  IconButton,
  TextField,
} from "@mui/material";

import { DateSelector } from "src/utils/date-selector";

const TABLE_HEAD = [
  { id: "index", label: "ID", alignRight: false },
  { id: "type", label: "Type", alignRight: false },
  { id: "datetime", label: "Date & Time", alignRight: false },
  { id: "agent", label: "Agent", alignRight: false },
  { id: "name", label: "Customer", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "category", label: "Category", alignRight: false },
];

const { useState, useEffect } = require("react");
const { useAuth } = require("src/contexts/AuthContext");

export default function AllCalls({ selectMgr }) {
  const [callData, setCallData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [selectedDate, setSelectedDate] = useState(Date.now());
  const [category, setCategory] = useState("all");
  const [status, setStatus] = useState("all");

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };

  const handleChangeStatus = (event) => {
    if (event.target.value === "overdue") {
      setSelectedDate(null);
    }
    setStatus(event.target.value);
  };

  const handleChangeDate = (newValue) => {
    if (newValue) {
      if (status === "overdue") {
        setStatus("all");
      }
      setSelectedDate(newValue.valueOf());
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const loadData = async () => {
    setLoadingData(true);

    let url = `/api/salesflags/mgract/replies/query?skip=${
      page * rowsPerPage
    }&limit=${rowsPerPage}&sort=date&dir=d`;

    if (category !== "all") {
      url += `&entity=${category}`;
    }

    if (selectMgr !== "all") {
      url += `&agent=${selectMgr}`;
    }

    if (status !== "all") {
      if (status === "overdue") {
        url += `&pending=true`;
      } else {
        url += `&status=${status}`;
      }
    }

    if (selectedDate) {
      const dts = getDayStartAndEndSync(selectedDate);
      url += `&date_gt=${dts.start}&date_lt=${dts.end}`;
    } else {
      url += `&date_lt=${Date.now()}`;
    }

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success) {
      setCallData(ret.data);
      console.log(ret.data);
    } else {
      console.log(ret.message);
    }

    setLoadingData(false);
  };

  const [currCall, setCurrentCall] = useState(null);
  const [callModalState, setCallModalState] = useState(false);

  const handleEntrySelected = (CustData) => {
    setCurrentCall(CustData);
    setCallModalState(true);
  };

  useEffect(() => {
    loadData();
  }, [activeFranchise, page, category, selectMgr, status, selectedDate]);

  const totLength = page * rowsPerPage + callData.length;

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h5" gutterBottom>
            All Calls
          </Typography>

          <Stack direction="row" alignItems="center" spacing={2}>
            <DateSelector value={selectedDate} onChange={handleChangeDate} />

            <TextField
              select
              id="cat-select"
              value={category}
              onChange={handleChangeCategory}
              label="Category"
            >
              <MenuItem value="all">ALL</MenuItem>
              <MenuItem value="onboarding">Onboarding</MenuItem>
              <MenuItem value="crm">CRM</MenuItem>
              <MenuItem value="enquiry">Enquiry</MenuItem>
            </TextField>

            <TextField
              select
              id="status-select"
              value={status}
              onChange={handleChangeStatus}
              label="Status"
            >
              <MenuItem value="all">ALL</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="missed">Missed</MenuItem>
              <MenuItem value="live">Live</MenuItem>
              <MenuItem value="overdue">Overdue</MenuItem>
            </TextField>

            {(loadingData === true && (
              <CircularProgress color="secondary" />
            )) || (
              <IconButton
                onClick={() => {
                  if (page === 0) loadData();
                  else setPage(0);
                }}
              >
                <RefreshRounded />
              </IconButton>
            )}
          </Stack>
        </Stack>

        <TableContainer sx={{ minHeight: 100 }}>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.alignRight ? "right" : "left"}
                    sortDirection={false}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {callData.map((item, index) => {
                return (
                  <TableRow onClick={() => handleEntrySelected(item)}>
                    <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                    <TableCell>
                      {item?.action === "agent" ? (
                        <CallMadeRounded color="success" sx={{ mr: 1 }} />
                      ) : (
                        <CallReceivedRounded color="secondary" sx={{ mr: 1 }} />
                      )}
                    </TableCell>
                    <TableCell>
                      {getDateStringFullWithTimeSync(item?.date)}
                    </TableCell>
                    <TableCell>{item?.agent?.name || "-"}</TableCell>
                    <TableCell>{item?.name || "Unknown"}</TableCell>
                    <TableCell>
                      <Label
                        color={
                          item?.call_details?.status == "pending"
                            ? "warning"
                            : item?.call_details?.status === "live"
                            ? "info"
                            : item?.call_details?.status === "missed"
                            ? "error"
                            : item?.call_details?.status === "rejected"
                            ? "error"
                            : "success"
                        }
                      >
                        {item?.call_details?.status || "-"}
                      </Label>
                    </TableCell>
                    <TableCell>
                      <Label
                        color={
                          item?.entity == "onboarding"
                            ? "success"
                            : item?.entity === "crm"
                            ? "info"
                            : item?.entity === "enquiry"
                            ? "warning"
                            : "success"
                        }
                        variant="filled"
                      >
                        {item?.entity}
                      </Label>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totLength < (page + 1) * rowsPerPage ? totLength : -1}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
          rowsPerPageOptions={[10, 20, 50]}
          page={page}
          onPageChange={handleChangePage}
        />
      </Container>

      <Modal open={callModalState} onClose={() => setCallModalState(false)}>
        <CallDetails
          callDet={{ _id: currCall?._id }}
          handleClose={() => setCallModalState(false)}
        />
      </Modal>
    </Card>
  );
}
