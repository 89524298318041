import {
  AddShoppingCartRounded,
  CloseRounded,
  MonetizationOnRounded,
  SecurityRounded,
  ShoppingCartCheckoutRounded,
} from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  AppBar,
  Box,
  Container,
  Dialog,
  IconButton,
  Slide,
  Stack,
  Tab,
  Toolbar,
  Typography,
  Button,
  Modal,
} from "@mui/material";
import PartyProfileSection from "./PartyProfileSection";
import { forwardRef, useState } from "react";
import { BulkOrders } from "./BulkOrders";
import AddOrderModal from "./AddOrderModal";
import AddSaleModal from "./AddSaleModal";
import SaleTxns from "./SaleTxns";
import AddNewPaymentModal from "./AddNewPaymentModal";
import MatchPaymentModal from "./MatchPaymentModal";
import AddNewDealModal from "./AddDealsModal";
import B2bDealDetails from "./B2bDealDetails";
import MakeFullScreen from "src/components/MakeFullScreen";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const PartyDetailsModal = ({ party, handleClose, drawerOpen }) => {
  const [currentMenu, setCurrentMenu] = useState("profile");
  const [addModalState, setAddModalState] = useState(false);
  const [saleModalState, setSaleModalState] = useState(false);
  const [addPaymentModalState, setAddPaymentModalState] = useState(false);
  const [matchPaymentModalState, setMatchPaymentModalState] = useState(false);

  const handleTabChange = (event, newValue) => {
    setCurrentMenu(newValue);
  };

  const handleAddPaymentModalState = () => {
    setAddPaymentModalState(true);
  };

  const handleMmatchPaymentModalState = () => {
    setMatchPaymentModalState(true);
  };

  const handleAddSale = () => {
    setSaleModalState(true);
  };

  const handleAddorder = () => {
    setAddModalState(true);
  };

  const handleM1Close = () => {
    setAddModalState(false);
  };

  return (
    <MakeFullScreen
      title={party?.name}
      open={drawerOpen}
      onClose={handleClose}
      header={[
        <Button
          onClick={handleMmatchPaymentModalState}
          variant="contained"
          startIcon={<SecurityRounded />}
          color="white"
        >
          Match
        </Button>,
        <Button
          onClick={handleAddPaymentModalState}
          variant="contained"
          startIcon={<MonetizationOnRounded />}
          color="white"
        >
          Payment
        </Button>,
        <Button
          onClick={handleAddSale}
          variant="contained"
          startIcon={<ShoppingCartCheckoutRounded />}
          color="white"
        >
          Sale
        </Button>,
        <Button
          onClick={handleAddorder}
          variant="contained"
          startIcon={<AddShoppingCartRounded />}
          color="white"
        >
          Order
        </Button>,
      ]}
    >
      <Container sx={{ minHeight: 500 }}>
        <TabContext value={currentMenu}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleTabChange}
                aria-label="simple tabs example"
              >
                <Tab label="Profile" value="profile" {...a11yProps(0)} />
                <Tab label="Orders" value="orders" {...a11yProps(3)} />
                <Tab label="Txns" value="txns" {...a11yProps(4)} />
                <Tab label="Deals" value="deals" {...a11yProps(5)} />
              </TabList>
            </Box>
          </Stack>

          <TabPanel value="profile">
            <PartyProfileSection party={party} />
          </TabPanel>
          <TabPanel value="orders">
            <BulkOrders party={party} />
          </TabPanel>
          <TabPanel value="txns">
            <SaleTxns party={party} />
            {/* <CustomerTxnsPaginated customer={party} /> */}
          </TabPanel>
          <TabPanel value="deals">
            <B2bDealDetails party={party} />
          </TabPanel>
        </TabContext>
      </Container>

      <Modal
        open={addPaymentModalState}
        onClose={() => setAddPaymentModalState(false)}
      >
        <AddNewPaymentModal
          party={party}
          handleM1Close={() => setAddPaymentModalState(false)}
        />
      </Modal>

      <Modal
        open={matchPaymentModalState}
        onClose={() => setMatchPaymentModalState(false)}
      >
        <MatchPaymentModal
          account={party?._id}
          handleM1Close={() => setMatchPaymentModalState(false)}
        />
      </Modal>

      <Modal open={addModalState} onClose={handleM1Close}>
        <AddOrderModal party={party} handleM1Close={handleM1Close} />
      </Modal>

      <Modal open={saleModalState} onClose={() => setSaleModalState(false)}>
        <AddSaleModal
          party={party}
          handleM1Close={() => setSaleModalState(false)}
        />
      </Modal>
    </MakeFullScreen>
  );
};
