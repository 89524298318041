const moment = require('moment');

const utcOffset = 330 * 60 * 1000;




export function getDayStart(date) {
	const dt = new Date(date);
	dt.setHours(0);
	dt.setMinutes(0);
	dt.setSeconds(0);
	dt.setMilliseconds(0);
	return dt.getTime();
}

export function getDayEnd(date) {
	const dt = new Date(date + TWENTY4HOURS);
	dt.setHours(0);
	dt.setMinutes(0);
	dt.setSeconds(0);
	dt.setMilliseconds(0);
	return dt.getTime() - 1;
}

export const getDayStartAndEnd = async (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc().startOf('day').valueOf() - utcOffset;
	const end = moment(newTime).utc().endOf('day').valueOf() - utcOffset;

	return {
		start: st,
		end: end
	};
}

export const getDayStartAndEndSync = (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc().startOf('day').valueOf() - utcOffset;
	const end = moment(newTime).utc().endOf('day').valueOf() - utcOffset;

	return {
		start: st,
		end: end
	};
}

export const TWENTY4HOURS = 24 * 60 * 60 * 1000

export const getDateString = async (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc();
	const a = st.format("DD-MM-YY")

	return a;
}

export const getDateStringSync = (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc();
	const a = st.format("DD-MM-YY")

	return a;
}

export const getDateStringCWM = (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc();
	const a = st.format("DD/MM")

	return a;
}

export const getDateStringCustom = (time, format) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc();
	const a = st.format(format)

	return a;
}

export const getDateStringCWM2 = (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc();
	const a = st.format("DD/MM/YY hh:mm A")

	return a;
}

export const getTimeString = (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc();
	const a = st.format("hh:mm A")

	return a;
}

export const getTimeStringFull = async (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc();
	const a = st.format("hh:mm A")

	return a;
}

export const getTimeStringFullSync = (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc();
	const a = st.format("hh:mm:ss A")

	return a;
}

export const getHour = (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc();
	const a = st.format("HH")

	return a;
}

export const getDateOfMonth = (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc();

	return st.date;
}

export const getDateStringFull = async (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc();
	const a = st.format("DD-MM-YYYY")

	return a;
}

export const getDateStringFullSync = (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc();
	const a = st.format("DD-MM-YYYY")

	return a;
}

export const getDateStringFullWithMonth = async (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc();
	const a = st.format("DD MMM YYYY")

	return a;
}

export const getDateStringFullWithMonthSync = (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc();
	const a = st.format("DD MMM YYYY")

	return a;
}

export const getDateStringFullWithTime = async (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc();
	const a = st.format("DD-MM-YYYY hh:mm:ss A")

	return a;
}

export const getDateStringFullWithTimeSync = (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc();
	const a = st.format("DD-MM-YYYY hh:mm:ss A")

	return a;
}

export const getDateStringFullWithTimeSync2 = (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc();
	const a = st.format("DD-MM hh:mm A")

	return a;
}


export const parseDateStringFullWithTimeSync = (str) => {
	const st = moment(str, 'DD-MM-YYYY hh:mm:ss A').utc();
	return st.valueOf() + utcOffset;
}


export const getDateStringFullWithSlash = (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc();
	const a = st.format("DD/MM/YYYY")

	return a;
}

export const parseDateStringFull = (str) => {
	const st = moment(str, 'DD/MM/YYYY').utc();
	return st.valueOf() + utcOffset;
}


export const getDateStringDesc = async (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc();
	const a = st.format("YYYYMMDD")

	return a;
}

export const getMonthStartAndEnd = async (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc().startOf('month').valueOf() - utcOffset;
	const end = moment(newTime).utc().endOf('month').valueOf() - utcOffset;

	return {
		start: st,
		end: end
	};
}


export const getMonthStartAndEndSync = (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc().startOf('month').valueOf() - utcOffset;
	const end = moment(newTime).utc().endOf('month').valueOf() - utcOffset;

	return {
		start: st,
		end: end
	};
}

export const getDaySync = (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc().date();

	return st;
}

export const getWeekStartAndEndSync = (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc().startOf('week').valueOf() - utcOffset;
	const end = moment(newTime).utc().endOf('week').valueOf() - utcOffset;

	return {
		start: st,
		end: end
	};
}


export const parseDateStringCustom = (str) => {
	const st = moment(str, 'DD-MM-YYYY').utc();
	return st.valueOf() + utcOffset;
}

export const parseTimeString = (str) => {
	const [timePart, amPmPart] = str.split(' ');

    const [hoursStr, minutesStr] = timePart.split(':');
    let hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);

    if (amPmPart) {
        if (amPmPart.toLowerCase() === 'pm' && hours < 12) {
            hours += 12;
        } else if (amPmPart.toLowerCase() === 'am' && hours === 12) {
            hours += 0;
        }
    }

    const totalMilliseconds = (hours * 3600000) + (minutes * 60000);

    return totalMilliseconds;
};


export const getTimeStringNew = (time) => {
    const newTime = time / 3600000;
    const hours = Math.floor(newTime);
    const minutes = Math.floor((newTime - hours) * 60);

    const period = hours >= 12 ? 'PM' : 'AM';

    const displayHours = hours % 12 || 12;

    const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${displayHours}:${paddedMinutes} ${period}`;
}


export const getNextHourMillis = (time) => {
	const newTime = new Date(time + utcOffset)

	const st = moment(newTime).utc().add(1, 'hour').startOf('hour').valueOf() - utcOffset;

	return st;
}
