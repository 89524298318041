import { Container, Tab } from "@mui/material";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState } from "react";

import ProductProfileSection from "./ProductProfileSection";
import ProductImagesSection from "./ProductImagesSection";
import ProductInventorySection from "./ProductInventorySection";
import ProductDetailsSection from "./ProductDetailsSection";
import MakeFullScreen from "src/components/MakeFullScreen";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProductDetailsPage({
  product,
  handleClose,
  drawerOpen,
}) {
  const [currentMenu, setCurrentMenu] = useState("profile");

  const handleTabChange = (event, newValue) => {
    setCurrentMenu(newValue);
  };

  return (
    <MakeFullScreen
      open={drawerOpen}
      onClose={handleClose}
      maxWidth="lg"
      title={product?.name}
    >
      <Container sx={{ minHeight: "800px" }}>
        <TabContext value={currentMenu}>
          <TabList onChange={handleTabChange}>
            <Tab label="Home" value="profile" {...a11yProps(0)} />
            <Tab label="Images" value="images" {...a11yProps(1)} />
            {product?.stock_type === 0 && (
              <Tab label="Inventory" value="inventory" {...a11yProps(2)} />
            )}
            <Tab label="Description" value="details" {...a11yProps(3)} />
          </TabList>

          <TabPanel value="profile">
            <ProductProfileSection product={product} />
          </TabPanel>
          <TabPanel value="images">
            <ProductImagesSection product={product} />
          </TabPanel>
          <TabPanel value="inventory">
            <ProductInventorySection product={product} />
          </TabPanel>
          <TabPanel value="details">
            <ProductDetailsSection product={product} />
          </TabPanel>
        </TabContext>
      </Container>
    </MakeFullScreen>
  );
}
