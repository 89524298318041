import { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Card,
  CircularProgress,
  Stack,
  Divider,
} from "@mui/material";

import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";
import { getDateStringFullWithSlash } from "src/utils/date-time-helper";

import { blueGrey } from "@mui/material/colors";

export default function MilkCartsComparisonSection({
  startDate,
  prevStartDate,
  onCartSelected,
}) {
  const [data, setData] = useState([]);
  const [prevData, setPrevData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const { getAccessToken, activeFranchise } = useAuth();

  const initialLoad = async () => {
    setLoadingData(true);
    const ret = await dataFetch(
      `/api/milkcarts/mgract/all/detailed?date=${startDate}`,
      await getAccessToken(),
      activeFranchise
    );

    const prevRet = await dataFetch(
      `/api/milkcarts/mgract/all/detailed?date=${prevStartDate}`,
      await getAccessToken(),
      activeFranchise
    );

    if (ret.success === true) {
      setData(ret.data);
    } else {
      setData([]);
    }

    if (prevRet.success === true) {
      setPrevData(prevRet.data);
    } else {
      setPrevData([]);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    initialLoad();
  }, [activeFranchise, startDate, prevStartDate]); // eslint-disable-line react-hooks/exhaustive-deps

  let m = 0;
  let e = 0;

  let prevM = 0;
  let prevE = 0;

  for (const cart of data) {
    m += ((cart && cart.morning && cart.morning.milk) || 0.0) / 1000;
    e += ((cart && cart.evening && cart.evening.milk) || 0.0) / 1000;
  }

  for (const cart of prevData) {
    prevM += ((cart && cart.morning && cart.morning.milk) || 0.0) / 1000;
    prevE += ((cart && cart.evening && cart.evening.milk) || 0.0) / 1000;
  }

  let diffM = m - prevM;
  let diffE = e - prevE;
  let diffO = m + e - (prevM + prevE);

  return (
    <>
      {loadingData === true ? (
        <CircularProgress color="secondary" />
      ) : (
        <Grid container spacing={3}>
          <Grid key="pr" item xs={12} sm={12} md={6}>
            <Card sx={{ px: 3, py: 2 }}>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <b>TOTAL</b>
              </Typography>

              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack direction={"column"}>
                  <Typography variant="subtitle2" noWrap>
                    {getDateStringFullWithSlash(prevStartDate)}
                  </Typography>
                  <Divider orientation={"horizontal"} flexItem sx={{ my: 1 }} />

                  <Typography variant="body1" noWrap color={"success.main"}>
                    <b>Mor: {prevM.toFixed(1)} L</b>
                  </Typography>
                  <Typography variant="body1" noWrap color={"info.main"}>
                    <b>Eve: {prevE.toFixed(1)} L</b>
                  </Typography>

                  <Divider orientation={"horizontal"} flexItem sx={{ my: 1 }} />
                  <Typography variant="body1" noWrap mt={1}>
                    <b>Total: {(prevM + prevE).toFixed(0)} L</b>
                  </Typography>
                </Stack>

                <Divider orientation={"vertical"} flexItem />

                <Stack direction={"column"}>
                  <Typography variant="subtitle2" noWrap>
                    {getDateStringFullWithSlash(startDate)}
                  </Typography>
                  <Divider orientation={"horizontal"} flexItem sx={{ my: 1 }} />

                  <Typography variant="body1" noWrap color={"success.main"}>
                    <b>Mor: {m.toFixed(1)} L</b>
                  </Typography>
                  <Typography variant="body1" noWrap color={"info.main"}>
                    <b>Eve: {e.toFixed(1)} L</b>
                  </Typography>

                  <Divider orientation={"horizontal"} flexItem sx={{ my: 1 }} />
                  <Typography variant="body1" noWrap mt={1}>
                    <b>Total: {(m + e).toFixed(0)} L</b>
                  </Typography>
                </Stack>

                <Divider orientation={"vertical"} flexItem />

                <Stack direction={"column"}>
                  <Typography variant="subtitle2" noWrap>
                    Change
                  </Typography>
                  <Divider orientation={"horizontal"} flexItem sx={{ my: 1 }} />

                  <DiffText diff={diffM} variant="body1" />
                  <DiffText diff={diffE} variant="body1" />

                  <Divider orientation={"horizontal"} flexItem sx={{ my: 1 }} />
                  <DiffText diff={diffO} variant="body1" />
                </Stack>
              </Stack>
            </Card>
          </Grid>

          {data.map((cart, index) => {
            return (
              <Grid key={cart._id} item xs={12} sm={12} md={6}>
                <Card sx={{ px: 3, py: 2 }}>
                  <div onClick={() => onCartSelected(cart)}>
                    <Typography
                      variant="body1"
                      sx={{ mb: 2, color: blueGrey[800] }}
                    >
                      <b>{cart.name}</b> ({cart.driver_name})
                    </Typography>

                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <MOut
                        data={prevData[index]}
                        day={getDateStringFullWithSlash(prevStartDate)}
                      />

                      <Divider orientation={"vertical"} flexItem />

                      <MOut
                        data={cart}
                        day={getDateStringFullWithSlash(startDate)}
                      />

                      <Divider orientation={"vertical"} flexItem />

                      <Difference data={cart} prevData={prevData[index]} />
                    </Stack>
                  </div>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
}

const MOut = ({ data, day }) => {
  return (
    <Stack direction={"column"}>
      <Typography variant="subtitle2" noWrap>
        {day}
      </Typography>
      <Divider orientation={"horizontal"} flexItem sx={{ my: 1 }} />
      <Typography variant="subtitle2" noWrap>
        Mor: {((data.morning ? data.morning.milk : 0.0) / 1000).toFixed(1)} L (
        {data.morning ? data.morning.interrupt : 0})
      </Typography>
      <Typography variant="subtitle2" noWrap>
        Eve: {((data.evening ? data.evening.milk : 0.0) / 1000).toFixed(1)} L (
        {data.evening ? data.evening.interrupt : 0})
      </Typography>
      <Divider orientation={"horizontal"} flexItem sx={{ my: 1 }} />
      <Typography variant="subtitle2" noWrap>
        Total:{" "}
        {(
          (data.morning ? data.morning.milk : 0.0) / 1000 +
          (data.evening ? data.evening.milk : 0.0) / 1000
        ).toFixed(1)}{" "}
        L
      </Typography>
    </Stack>
  );
};

const Difference = ({ data, prevData }) => {
  let morningDiff =
    (data.morning ? data.morning.milk : 0.0) / 1000 -
    (prevData.morning ? prevData.morning.milk : 0.0) / 1000;
  let eveningDiff =
    (data.evening ? data.evening.milk : 0.0) / 1000 -
    (prevData.evening ? prevData.evening.milk : 0.0) / 1000;
  return (
    <Stack direction={"column"}>
      <Typography variant="subtitle2" noWrap>
        {"Difference"}
      </Typography>
      <Divider orientation={"horizontal"} flexItem sx={{ my: 1 }} />

      <DiffText diff={morningDiff} />
      <DiffText diff={eveningDiff} />

      <Divider orientation={"horizontal"} flexItem sx={{ my: 1 }} />

      <DiffText diff={morningDiff + eveningDiff} />
    </Stack>
  );
};

const DiffText = ({ diff, variant = "subtitle2" }) => {
  if (diff > 0) {
    return (
      <Typography variant={variant} noWrap style={{ color: "green" }}>
        + {diff.toFixed(1)} L
      </Typography>
    );
  } else if (diff === 0) {
    return (
      <Typography variant={variant} noWrap style={{ color: "black" }}>
        -----
      </Typography>
    );
  } else {
    return (
      <Typography variant={variant} noWrap style={{ color: "red" }}>
        - {Math.abs(diff).toFixed(1)} L
      </Typography>
    );
  }
};
