import {
  Card,
  CircularProgress,
  Container,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";
import { getDateStringFullWithTimeSync } from "src/utils/date-time-helper";
import BulkOrderDetails from "./BulkOrderDetails";
import SaleTxnDetails from "./SaleTxnDetails";
import Label from "../crm/Label";
import { RefreshRounded } from "@mui/icons-material";

const TABLE_HEAD = [
  { id: "datetime", label: "Date & Time", alignRight: false },
  { id: "entity", label: "Entity", alignRight: false },
  { id: "product", label: "Products", alignRight: false },
  { id: "qty", label: "Qty", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
];

export default function SaleTxns({ party, defaultList = 10 }) {
  const [saleTxns, setSaleTxns] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [txnModal, setTxnModal] = useState(false);
  const [page, setPage] = useState(0);
  const [txns, setTxns] = useState(null);
  const { getAccessToken, activeFranchise } = useAuth();

  const handleTxnModal = (txn) => {
    setTxnModal(true);
    setTxns(txn);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const loadData = async () => {
    setLoadingData(true);

    let url = `/api/extrasales/mgract/txns/query?sort=date&dir=d&user=${
      party._id
    }&skip=${page * defaultList}&limit=${defaultList}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setSaleTxns(ret.data);
      console.log(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    loadData();
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  let totalLength = defaultList * page + saleTxns.length;

  return (
    <>
      <Container>
        <Stack
          direction="row" /*  */
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          mt={2}
        >
          <Typography variant="h5" gutterBottom>
            Transaction Data
          </Typography>

          <Stack direction="row">
            {loadingData === false && (
              <IconButton onClick={loadData}>
                <RefreshRounded />
              </IconButton>
            )}

            {loadingData === true && <CircularProgress color="secondary" />}
          </Stack>
        </Stack>

        {saleTxns.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {saleTxns.map((item) => (
                    <Tooltip key={item?.id} title={item?.comments}>
                      <TableRow
                        onClick={() => {
                          console.log("Row clicked");
                          handleTxnModal(item);
                        }}
                        sx={{
                          backgroundColor:
                            item?.status === "success"
                              ? "white"
                              : "lightsalmon",
                        }}
                      >
                        <TableCell>
                          {getDateStringFullWithTimeSync(item?.date)}
                        </TableCell>
                        <TableCell>
                          <Label
                            color={
                              item?.entity === "cashflow" ? "success" : "info"
                            }
                          >
                            {item?.entity?.toUpperCase()}
                          </Label>
                        </TableCell>
                        <TableCell>
                          {item?.entity === "salebill"
                            ? item?.bill?.items
                                .map((billItem) => billItem?.name)
                                .join(",")
                            : "--"}
                        </TableCell>
                        <TableCell>
                          {item?.entity === "salebill"
                            ? item?.bill?.items
                                .map((billItem) => `${billItem?.quantity} ${billItem?.unit || "Kg"}`)
                                .join(",")
                            : "--"}
                        </TableCell>
                        <TableCell>
                          <b>₹ {item?.amount?.toFixed(1)}</b>
                        </TableCell>
                        <TableCell>{item?.status?.toUpperCase()}</TableCell>
                      </TableRow>
                    </Tooltip>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}

        {saleTxns.length === 0 && loadingData === false && (
          <div>
            <Typography gutterBottom align="center" variant="subtitle1">
              Not found
            </Typography>
            <Typography variant="body2" align="center" sx={{ mb: 4 }}>
              No results here.
            </Typography>
          </div>
        )}

        <TablePagination
          component="div"
          count={totalLength < (page + 1) * defaultList ? totalLength : -1}
          rowsPerPage={defaultList}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[defaultList]}
        />
      </Container>

      <Modal open={txnModal} onClose={() => setTxnModal(false)}>
        <SaleTxnDetails txn={txns} onClose={() => setTxnModal(false)} />
      </Modal>
    </>
  );
}
