import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";
import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";
import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";
import { useSnackbar } from "src/contexts/SnackbarContext";

export default function AddSalaryComponent({ handleM1Close }) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [category, setCategory] = useState("");
  const [valueOf, setValueOf] = useState("");
  const [partOf, setPartOf] = useState([]);
  const [appliesTo, setAppliesTo] = useState([]);
  const [periodicity, setPeriodicity] = useState("");

  const approveNow = async (values) => {
    setLoading(true);

    const upd = {
      name: values.name,
      value: values.value,
      category: category,
      value_of: valueOf,
      part_of: partOf,
      applies_to: appliesTo,
      periodicity: periodicity,
    };

    console.log("upd", upd);

    const ret = await dataPost(
      `/api/salary/mgract/create`,
      await getAccessToken(),
      upd,
      activeFranchise
    );

    if (ret.success === true) {
      showSnackbar(ret.message || "Salary Component added.", "success");
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }

    setLoading(false);
  };

  const DataSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    value: Yup.number()
      .required("Value is required")
      .typeError("Value must be a number"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      value: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, isSubmitting } = formik;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Add New Salary Component
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                type="text"
                label="Component Name"
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />

              <TextField
                fullWidth
                type="number"
                label="Value"
                {...getFieldProps("value")}
                error={Boolean(touched.value && errors.value)}
                helperText={touched.value && errors.value}
              />

              <Stack direction="row" spacing={2}>
                <TextField
                  select
                  fullWidth
                  value={category}
                  label="Category"
                  onChange={(e) => setCategory(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value={"amount"}>Amount</MenuItem>
                  <MenuItem value={"percentage"}>Percentage</MenuItem>
                </TextField>

                <TextField
                  select
                  fullWidth
                  label="Value Of"
                  value={valueOf}
                  onChange={(e) => setValueOf(e.target.value)}
                >
                  <MenuItem value={"basic"}>Basic</MenuItem>
                  <MenuItem value={"gross"}>Gross</MenuItem>
                  <MenuItem value={"ctc"}>CTC</MenuItem>
                </TextField>
              </Stack>

              <Stack direction="row" spacing={2}>
                <FormControl fullWidth>
                  <InputLabel id="part-of">Part Of</InputLabel>
                  <Select
                    labelId="part-of"
                    fullWidth
                    label="Part Of"
                    multiple
                    value={partOf}
                    onChange={(e) => setPartOf(e.target.value)}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    <MenuItem value={"gross"}>Gross</MenuItem>
                    <MenuItem value={"ctc"}>CTC</MenuItem>
                    <MenuItem value={"inhand"}>In Hand</MenuItem>
                    <MenuItem value={"deductions"}>Deductions</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="applies-to">Applies TO</InputLabel>

                  <Select
                    labelId="applies-to"
                    fullWidth
                    label="Applies To"
                    multiple
                    value={appliesTo}
                    onChange={(e) => setAppliesTo(e.target.value)}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    <MenuItem value={"contractor"}>Contractor</MenuItem>
                    <MenuItem value={"employee"}>Employee</MenuItem>
                    <MenuItem value={"intern"}>Intern</MenuItem>
                    <MenuItem value={"none"}>None</MenuItem>
                  </Select>
                </FormControl>
              </Stack>

              <TextField
                select
                fullWidth
                value={periodicity}
                label="Periodicity"
                onChange={(e) => setPeriodicity(e.target.value)}
                displayEmpty
              >
                <MenuItem value={"annually"}>Annually</MenuItem>
                <MenuItem value={"monthly"}>Monthly</MenuItem>
                <MenuItem value={"onetime"}>One Time</MenuItem>
                <MenuItem value={"quarterly"}>Quarterly</MenuItem>
                <MenuItem value={"halfyearly"}>Half Yearly</MenuItem>
              </TextField>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
}
