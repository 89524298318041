import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { styled } from "@mui/material/styles";
import { dataFetch } from "src/utils/data-fetch";
import { useSnackbar } from "src/contexts/SnackbarContext";

import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
  RefreshRounded,
  SearchRounded,
} from "@mui/icons-material";

import {
  Container,
  Table,
  Box,
  OutlinedInput,
  InputAdornment,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  CircularProgress,
  Stack,
  Avatar,
  TextField,
  Collapse,
  IconButton,
  Typography,
  MenuItem,
} from "@mui/material";

import Label from "src/pages/crm/Label";
import { ledgerIcon, stringToColor } from "src/utils/constants";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "image", label: "", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "entity", label: "Entity", alignRight: false },
  { id: "plant", label: "Plant", alignRight: false },
  { id: "balance", label: "Balance", alignRight: true },
];

export default function LedgerListCard({ onAccSelected, initFr }) {
  const { getAccessToken, activeFranchise, allFranchisees } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [ledgerGroups, setLedgerGroups] = useState([]);
  async function getAccGroups() {
    const ret = await dataFetch(
      `/api/internalaccs/coreact/groups/query`,
      await getAccessToken()
    );
    if (ret.success === true) {
      setLedgerGroups(ret.data);
    } else {
      showSnackbar(ret.message + " - " + ret.code, "error");
    }
  }

  useEffect(() => {
    getAccGroups();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [entity, setEntity] = useState(
    localStorage.getItem("coa_grp") || "Current Assets"
  );
  const [selectedFr, setSelectedFr] = useState(initFr ?? "all");

  const handleSearch = async () => {
    if (!entity || entity === "all") {
      showSnackbar("Select a group", "error");
      return;
    }

    setLoadingData(true);

    let url = `/api/internalaccs/coreact/accs/query?real_balance=true&acc_group=${entity}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
      console.log(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    handleSearch();
  }, [entity, selectedFr]); // eslint-disable-line react-hooks/exhaustive-deps

  const mainRows = customerData.filter((item) => {
    return !item?.under;
  });

  const [search, setSearch] = useState("");

  // const filteredData = customerData
  //   .filter((item) => {
  //     if (search === "") return true;
  //     return (
  //       (item.name && item.name.toLowerCase().includes(search.toLowerCase())) ||
  //       (item.phone && item.phone.includes(search))
  //     );
  //   })
  //   .sort((a, b) => {});

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
        mt={2}
        spacing={2}
      >
        <SearchStyle
          placeholder="Search Accs..."
          // onChange={(e) => handleChangeQuery(e)}
          // onKeyUp={(e) => e.key == "Enter" && handleChangeQuery(e, true)}
          startAdornment={
            <InputAdornment position="start">
              <Box sx={{ color: "text.disabled" }}>
                <SearchRounded />
              </Box>
            </InputAdornment>
          }
          sx={{ mt: 4, mb: 4, flex: 6 }}
          autoFocus={true}
        />
        <TextField
          select
          sx={{ flex: 3 }}
          label="Group"
          id="entity-select"
          value={entity}
          onChange={(event) => {
            setEntity(event.target.value);
            localStorage.setItem("coa_grp", event.target.value);
          }}
        >
          {ledgerGroups.map((item) => (
            <MenuItem value={item.name}>{item.name}</MenuItem>
          ))}
          <MenuItem value={"other"}>Others</MenuItem>
        </TextField>

        <TextField
          select
          sx={{ flex: 2 }}
          label="Plant"
          id="fr-select"
          value={selectedFr}
          onChange={(event) => setSelectedFr(event.target.value)}
        >
          <MenuItem value={"all"}>ALL</MenuItem>
          {allFranchisees.map((item) => (
            <MenuItem value={item._id}>{item.name}</MenuItem>
          ))}
        </TextField>

        {(loadingData === true && <CircularProgress color="secondary" />) || (
          <IconButton onClick={handleSearch}>
            <RefreshRounded />
          </IconButton>
        )}
      </Stack>
      <TableContainer sx={{ minHeight: 200 }}>
        <Table>
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.alignRight ? "right" : "left"}
                  sortDirection={false}
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {mainRows.map((item) => (
              <MainRow
                key={item._id}
                item={item}
                children={customerData.filter(
                  (sub) => sub?.under?._id === item._id
                )}
                onAccClick={onAccSelected}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

function MainRow({ item, children, onAccClick }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{
          backgroundColor: item?.active === false ? "#ddd" : "white",
        }}
        onClick={(e) => {
          if (children?.length > 0) setOpen(!open);
          else onAccClick(item);
        }}
        key={item._id}
      >
        <TableCell style={{ width: "1px", whiteSpace: "nowrap" }}>
          <Avatar variant="rounded" sx={{ bgcolor: stringToColor(item?.name) }}>
            {ledgerIcon(item.entity)}
          </Avatar>
        </TableCell>
        <TableCell
          onClick={(e) => {
            onAccClick(item);
            e.stopPropagation();
          }}
        >
          <Typography variant="h6">{item?.name || "-"}</Typography>
          {item?.ext_code || "-"}
        </TableCell>
        <TableCell>
          <Label color="primary" variant="outlined">
            {item?.entity?.toUpperCase() || "-"}
          </Label>
        </TableCell>
        <TableCell>
          {(item?.franchisee?.name && (
            <Label color="primary">{item.franchisee.name}</Label>
          )) ||
            "-"}
        </TableCell>
        <TableCell align="right">
          ₹ {item?.balance?.toLocaleString("en-IN") || "0"}
        </TableCell>
        <TableCell style={{ width: "1px", whiteSpace: "nowrap" }}>
          {(children?.length > 0 && (
            <IconButton aria-label="expand row" color="primary">
              {open ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
            </IconButton>
          )) || <IconButton></IconButton>}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ mx: 1, mt: 1, mb: 4 }}>
              <Table size="small">
                <TableBody>
                  {children?.map((historyRow) => (
                    <SubRow
                      item={historyRow}
                      onAccClick={() => onAccClick(historyRow)}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function SubRow({ item, onAccClick }) {
  return (
    <TableRow
      key={item._id}
      onClick={(e) => {
        onAccClick(item);
        e.stopPropagation();
      }}
      style={{
        backgroundColor: item?.active === false ? "#ddd" : "white",
      }}
    >
      <TableCell style={{ width: "1px", whiteSpace: "nowrap" }}>
        <Avatar variant="rounded" sx={{ bgcolor: stringToColor(item?.name) }}>
          {ledgerIcon(item.entity)}
        </Avatar>
      </TableCell>
      <TableCell>
        <Typography variant="h6">{item?.name || "-"}</Typography>
        {item?.ext_code || "-"}
      </TableCell>
      <TableCell>
        <Label color="primary" variant="outlined">
          {item?.entity?.toUpperCase() || "-"}
        </Label>
      </TableCell>
      <TableCell>
        {(item?.franchisee?.name && (
          <Label color="primary">{item.franchisee.name}</Label>
        )) ||
          "-"}
      </TableCell>
      <TableCell align="right">₹ {item?.balance?.toFixed(1) || "0"}</TableCell>
    </TableRow>
  );
}
