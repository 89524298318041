import {
  AddRounded,
  AddShoppingCartRounded,
  ChevronRightRounded,
  RefreshRounded,
  SearchRounded,
} from "@mui/icons-material";

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import MakeFullScreen from "src/components/MakeFullScreen";

import { styled } from "@mui/material/styles";
import { dataFetch } from "src/utils/data-fetch";
import { useAuth } from "src/contexts/AuthContext";
import { useSnackbar } from "src/contexts/SnackbarContext";
import AddItem from "../item/AddItem";
import Label from "../../crm/Label";
import AddVendor from "./AddVendor";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "image", label: "", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "contact", label: "Contact", alignRight: false },
  { id: "address", label: "Address", alignRight: false },
  { id: "reliability", label: "Reliability", alignRight: false },
  { id: "items", label: "Items", alignRight: true },
];

export default function Vendors({ open, onClose, onSelected }) {
  const [items, setItems] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rpp, setRpp] = useState(5);

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  let timer = "";

  const handleChangeQuery = (event) => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      setPage(0);
      setSearch(event.target.value?.toLowerCase());
    }, 300);
  };

  const handleChangeRowsPerPage = (event) => {
    setRpp(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/inventory/mgract/vendors/query`;

    if (search !== "") {
      url += `?search=${search}&skip=${page * rpp}&limit=${rpp}`;
    } else {
      url += `?skip=${page * rpp}&limit=${rpp}`;
    }

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setItems(ret.data);
      console.log(items);
    } else {
      showSnackbar(ret.message, "error");
    }

    setLoadingData(false);
  };

  useEffect(() => {
    handleSearch();
  }, [search]);

  const totalLength = page * rpp + items.length;

  const [addItemModalState, setAddItemModalState] = useState(false);

  return (
    <MakeFullScreen
      title="Vendors"
      open={open}
      onClose={onClose}
      header={
        <Button
          variant="contained"
          color="white"
          onClick={() => setAddItemModalState(true)}
        >
          <AddRounded />
        </Button>
      }
    >
      <Container sx={{ minHeight: 600 }}>
        <SearchStyle
          placeholder="Search Vendors..."
          onChange={handleChangeQuery}
          startAdornment={
            <InputAdornment position="start">
              <Box sx={{ color: "text.disabled" }}>
                <SearchRounded />
              </Box>
            </InputAdornment>
          }
          endAdornment={
            (loadingData === true && (
              <CircularProgress color="secondary" />
            )) || (
              <IconButton onClick={handleSearch}>
                <RefreshRounded />
              </IconButton>
            )
          }
          autoFocus={true}
        />

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.alignRight ? "right" : "left"}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <SingleVendorItem
                  item={item}
                  onClick={() => {
                    if (typeof onSelected === "function") {
                      onSelected(item);
                    }
                  }}
                  showItems
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50]}
          component="div"
          count={totalLength < (page + 1) * rpp ? totalLength : -1}
          rowsPerPage={rpp}
          page={page}
          onPageChange={(e, n) => setPage(n)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        {addItemModalState && (
          <AddVendor
            open={addItemModalState}
            onClose={() => {
              setAddItemModalState(false);
              handleSearch();
            }}
          />
        )}
      </Container>
    </MakeFullScreen>
  );
}

export const SingleVendorItem = ({ item, onClick, showItems }) => {
  return (
    <Tooltip title={item.comments}>
      <TableRow
        sx={{
          cursor: "pointer",
          backgroundColor: item?.active === true ? "white" : "#ddd",
        }}
        hover
        onClick={onClick}
      >
        <TableCell style={{ width: "1px", whiteSpace: "nowrap" }}>
          <Card
            sx={{
              minWidth: "100px",
              maxWidth: "100px",
              height: "80px",
              boxShadow: 2,
            }}
          >
            <img
              alt="Vendor Image"
              src={item?.image || "/static/icon2.png"}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Card>
        </TableCell>
        <TableCell>
          <Typography variant="h6">{item?.name}</Typography>
          <br />
          {item?.code}
        </TableCell>
        <TableCell>
          <Typography variant="body1">{item?.phone}</Typography>
          <br />
          {item?.email}
        </TableCell>
        <TableCell>{item?.address}</TableCell>
        <TableCell>
          <Label color="primary" variant="outlined">
            {item?.reliability}
          </Label>
        </TableCell>

        {showItems && (
          <TableCell align="right">
            <Typography>
              {item?.items?.map((i) => (
                <>
                  {i}
                  <br />
                </>
              ))}
            </Typography>
          </TableCell>
        )}
      </TableRow>
    </Tooltip>
  );
};
