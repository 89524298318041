import React, { useRef, useEffect, useState } from "react";
import { MapContainer, TileLayer, Polygon, FeatureGroup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import LeafletControlGeocoder from "../addapt/LeafletControlGeocoder";
import { EditControl } from "react-leaflet-draw";
import { dataFetch, dataPatch } from "src/utils/data-fetch";
import { useAuth } from "src/contexts/AuthContext";

function ZoneMap({ apartment }) {
  const mapRef = useRef(null);
  const [polygon, setPolygon] = useState(null);
  const { getAccessToken, activeFranchise } = useAuth();

  const fetchGeometry = async () => {
    const url = `/api/apartments/mgract/appt/${apartment._id}/geometry`;
    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    console.log(ret.data);
    if (ret.success === true) {
      setPolygon(ret.data.coordinates[0]);
    }
  };

  useEffect(() => {
    if (mapRef.current && apartment?.location?.coordinates) {
      const [lat, lng] = apartment.location.coordinates;
      mapRef.current.setView([lat, lng], 15);
    }

    fetchGeometry();
  }, [apartment]);

  const updateChanges = async (e) => {
    const layer = e.layers.getLayers()[0];
    const latlngs = layer.getLatLngs()[0];

    const updatedCoordinates = latlngs.map((latlng) => [
      parseFloat(latlng.lng),
      parseFloat(latlng.lat),
    ]);

    updatedCoordinates.push(updatedCoordinates[0]);

    const response = await dataPatch(
      `/api/apartments/mgract/appt/${apartment._id}/geometry`,
      await getAccessToken(),
      { geometry: updatedCoordinates },
      activeFranchise
    );

    if (response.success === true) {
      fetchGeometry();
    }
  };

  let polygonCoordinates = polygon || [];
  const pc = polygonCoordinates.map((item) => {
    return { lat: item[1], lng: item[0] };
  });

  const center = (apartment?.location?.coordinates && {
    lat: apartment?.location?.coordinates[1],
    lng: apartment?.location?.coordinates[0],
  }) || [12.90152691, 77.69154668];

  return (
    <div>
      <MapContainer
        center={center}
        zoom={17}
        style={{ height: "90vh", width: "100%" }}
        ref={mapRef}
      >
        <LeafletControlGeocoder onLocationSelect={(latlng, name) => {}} />

        <FeatureGroup>
          <EditControl
            position="topright"
            onEdited={updateChanges}
            draw={{
              rectangle: false,
              circle: false,
              circlemarker: false,
              marker: false,
              polyline: false,
              polygon: false,
            }}
            edit={{ edit: true }}
          />
          {polygonCoordinates.length > 0 && <Polygon positions={[pc]} />}
        </FeatureGroup>

        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    </div>
  );
}

export default ZoneMap;
