import { forwardRef, useEffect, useState } from "react";

import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import Select from "@mui/material/Select";

import {
  Alert,
  Stack,
  TextField,
  Grid,
  Box,
  Chip,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  Drawer,
  Container,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataFetch, dataPost } from "src/utils/data-fetch";

import {
  getDateStringFullWithSlash,
  parseDateStringFull,
} from "src/utils/date-time-helper";
import EmpSearch from "./EmpSearch";
import { states } from "src/utils/constants";
import { useSnackbar } from "src/contexts/SnackbarContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const employeeRoles = {
  sales: "Delivery",
  tl: "Team Lead",
  fieldexec: "Field Sales",
  collection: "Collection",
  mechanic: "Mechanic",
  helper: "Helper",
  cook: "Cook",
  lab: "Lab Assistant",
  manager: "Manager",
  transport: "Transport",
  hr: "HR",
  crm: "CRM",
  account: "Accounts",
};

export default function AddEmployeeModal({ handleClose }) {
  const [error, setError] = useState();

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [selectMgr, setSelectMgr] = useState();
  const [deptData, setDeptData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const getAllDept = async () => {
    setLoadingData(true);

    let url = `/api/departments/mgract/query?sort=name&dir=a`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setDeptData(ret.data);
    } else {
      showSnackbar(ret.message || "Department load failed!", "error");
    }

    setLoadingData(false);
  };

  useEffect(() => {
    getAllDept();
  }, []);

  const approveNow = async (values) => {
    setError(null);
    if (!selectMgr) {
      setError("Select Reporting Manager");
      return;
    }

    const ret = await dataPost(
      `/api/employees/mgract/v2/add`,
      await getAccessToken(),
      {
        name: values.name,
        address: values.address,
        phone: `+91${values.phone}`,
        alt_phone: `+91${values.alt_phone}`,
        reporting_manager: selectMgr?.employee,
        department: values.department,
        personal_email: values.personal_email,
        designation: values.designation,
        join_date: parseDateStringFull(values.join_date),
        roles: values.roles,
        dob: parseDateStringFull(values.dob),
        payroll_type: values.payroll_type,
        toff_code: values.toff_code,
        ctc: values.ctc,
        state: values.state,
      },
      activeFranchise
    );
    if (ret.success === true) {
      handleClose(ret.data);
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    address: Yup.string().required("Address is required"),
    phone: Yup.number()
      .required("Enter 10 digit number")
      .max(9999999999)
      .min(1111111111),
    alt_phone: Yup.number().optional().max(9999999999).min(1111111111).required(),
    personal_email: Yup.string().email("Invalid email").required(),
    designation: Yup.string().required("Designation is required"),
    department: Yup.string().required("Select department").required(),
    join_date: Yup.string()
      .required("Please select join date")
      .matches(
        /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/,
        "Enter in format DD/MM/YYYY"
      ),
    roles: Yup.array(Yup.string()).required("Select roles"),
    dob: Yup.string().matches(
      /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/,
      "Enter in format DD/MM/YYYY"
    ).required(),
    payroll_type: Yup.string().required("Select Payroll Type"),
    toff_code: Yup.string().required("TOFF code is required"),
    ctc: Yup.number().required(),
    state: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      phone: "",
      personal_email: "",
      designation: "",
      department: "",
      join_date: getDateStringFullWithSlash(Date.now()),
      roles: ["sales"],
      dob: "",
      alt_phone: "",
      payroll_type: "contractor",
      toff_code: "",
      ctc: "",
      state: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;

  const [mgrDrawerState, setMgrDrawerState] = useState(false);

  return (
    <>
      <Container>
        <Box sx={{ py: 2 }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <Stack spacing={3}>
                    <TextField
                      select
                      fullWidth
                      label="Pay Type"
                      {...getFieldProps("payroll_type")}
                      error={Boolean(
                        touched.payroll_type && errors.payroll_type
                      )}
                      helperText={touched.payroll_type && errors.payroll_type}
                    >
                      <MenuItem value="contractor">Contractor</MenuItem>
                      <MenuItem value="employee">Employee</MenuItem>
                      <MenuItem value="intern">Intern</MenuItem>
                    </TextField>

                    <TextField
                      fullWidth
                      type="text"
                      label="Name"
                      inputProps={{
                        style: { textTransform: "capitalize" },
                      }}
                      {...getFieldProps("name")}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />

                    <TextField
                      select
                      fullWidth
                      label="Department"
                      {...getFieldProps("department")}
                      error={Boolean(touched.department && errors.department)}
                      helperText={touched.department && errors.department}
                    >
                      {deptData.map((dept) => (
                        <MenuItem value={dept._id}>{dept.name}</MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      fullWidth
                      type="text"
                      label="Designation"
                      {...getFieldProps("designation")}
                      value={values.designation.toUpperCase()}
                      onChange={(e) =>
                        setFieldValue(
                          "designation",
                          e.target.value.toUpperCase()
                        )
                      }
                      error={Boolean(touched.designation && errors.designation)}
                      helperText={touched.designation && errors.designation}
                    />

                    <OutlinedInput
                      fullWidth
                      type="text"
                      value={(selectMgr?.name) || "None Selected"}
                      onClick={() => setMgrDrawerState(true)}
                      startAdornment={
                        <InputAdornment position="start">
                          <Box sx={{ color: "text.disabled" }}>
                            Reporting Mgr
                          </Box>
                        </InputAdornment>
                      }
                    />

                    <TextField
                      fullWidth
                      type="number"
                      label="CTC"
                      {...getFieldProps("ctc")}
                      error={Boolean(touched.ctc && errors.ctc)}
                      helperText={touched.ctc && errors.ctc}
                    />

                    <TextField
                      fullWidth
                      select
                      type="string"
                      label="State of Work"
                      {...getFieldProps("state")}
                      error={Boolean(touched.state && errors.state)}
                      helperText={touched.state && errors.state}
                    >
                      {Object.keys(states).map((state) => (
                        <MenuItem value={state}>{states[state]}</MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      fullWidth
                      type="text"
                      label="TOFF code / Fingerprint"
                      {...getFieldProps("toff_code")}
                      error={Boolean(touched.toff_code && errors.toff_code)}
                      helperText={touched.toff_code && errors.toff_code}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <Stack spacing={3}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Join Date"
                      {...getFieldProps("join_date")}
                      error={Boolean(touched.join_date && errors.join_date)}
                      helperText={touched.join_date && errors.join_date}
                    />

                    <Select
                      id="roles-select"
                      label="Roles"
                      multiple
                      {...getFieldProps("roles")}
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={employeeRoles[value]} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {Object.keys(employeeRoles).map((key) => (
                        <MenuItem value={key}>{employeeRoles[key]}</MenuItem>
                      ))}
                    </Select>

                    <TextField
                      fullWidth
                      type="text"
                      label="Personal Email"
                      {...getFieldProps("personal_email")}
                      error={Boolean(
                        touched.personal_email && errors.personal_email
                      )}
                      helperText={
                        touched.personal_email && errors.personal_email
                      }
                    />

                    <TextField
                      fullWidth
                      type="number"
                      contentEditable={"false"}
                      label="Phone"
                      {...getFieldProps("phone")}
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone}
                    />

                    <TextField
                      fullWidth
                      type="number"
                      contentEditable={"false"}
                      label="Alternate Phone"
                      {...getFieldProps("alt_phone")}
                      error={Boolean(touched.alt_phone && errors.alt_phone)}
                      helperText={touched.alt_phone && errors.alt_phone}
                    />

                    <TextField
                      fullWidth
                      type="text"
                      label="Address"
                      {...getFieldProps("address")}
                      error={Boolean(touched.address && errors.address)}
                      helperText={touched.address && errors.address}
                    />

                    <TextField
                      fullWidth
                      type="text"
                      label="Date of Birth (DD/MM/YYYY)"
                      {...getFieldProps("dob")}
                      error={Boolean(touched.dob && errors.dob)}
                      helperText={touched.dob && errors.dob}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Save
              </LoadingButton>
            </Form>
          </FormikProvider>

          {error && (
            <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </Box>
      </Container>

      <Drawer
        anchor={"right"}
        open={mgrDrawerState}
        onClose={() => setMgrDrawerState(false)}
        sx={{ zIndex: 1300, p: 3 }}
      >
        <EmpSearch
          onMgrSelected={(dept) => {
            setMgrDrawerState(false);
            setSelectMgr(dept);
          }}
        />
      </Drawer>
    </>
  );
}
