import { useEffect, useState } from "react";

import {
  Container,
  Typography,
  Box,
  Grid,
  IconButton,
  Card,
  Divider,
  CardHeader,
  CardContent,
  Stack,
  Button,
  CircularProgress,
} from "@mui/material";

import {
  CallMadeRounded,
  CallOutlined,
  CallReceivedRounded,
  CallRounded,
  FlagRounded,
  PersonRounded,
} from "@mui/icons-material";

import { getDateStringFullWithTimeSync } from "src/utils/date-time-helper";
import { PlayContent } from "./PlayContent";
import CustomerDetailsPage from "../home/det/CustomerDetailsPage";
import { SingleOnboarding } from "../crm/SingleOnboarding";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch, dataPost } from "src/utils/data-fetch";
import Label from "../crm/Label";

export default function CallDetails({ callDet, handleClose }) {
  const [callDetails, setCallDetails] = useState(callDet);
  const [loadingCall, setLoadingCall] = useState(false);

  const minutes = Math.floor(callDetails?.call_details?.duration / 60);
  const seconds = callDetails?.call_details?.duration % 60;

  const { getAccessToken, activeFranchise } = useAuth();

  const loadCallDetails = async () => {
    setLoadingCall(true);

    const url = `/api/salesflags/mgract/sfr/${callDet._id}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);

    if (ret.success === true) {
      setCallDetails(ret.data);
      console.log(ret.data);
    } else {
      alert(ret.message || "Failed to load call details");
    }

    setLoadingCall(false);
  };

  useEffect(() => {
    if (!callDet?.entity) {
      if (callDet?._id) {
        loadCallDetails();
      } else return;
    }
  }, [callDet]);

  const makeCall = async () => {
    setLoadingCall(true);
    const url = `/api/salesflags/mgract/sfr/${callDetails._id}/newcall`;

    const ret = await dataPost(
      url,
      await getAccessToken(),
      {},
      activeFranchise
    );

    if (ret.success === true) {
      handleClose();
    } else {
      alert(ret.message || "Failed to make call");
      setLoadingCall(false);
    }
  };

  const [onbDrawerOpen, setOnbDrawerOpen] = useState(false);
  const handleSingleOnboarding = () => {
    setOnbDrawerOpen(true);
  };

  const [custDrawerOpen, setCustDrawerOpen] = useState(false);
  const handleCustomerOpen = () => {
    setCustDrawerOpen(true);
  };

  return (
    <Container
      sx={{
        px: 4,
        py: 4,
        width: 800,
        height: "fit-content",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Card
        sx={{
          bgcolor: "background.paper",
          boxShadow: 3,
          borderRadius: 2,
        }}
      >
        <CardHeader
          title={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6">Call Details</Typography>

              <Stack direction="row" spacing={1}>
                {callDetails?.flag && (
                  <IconButton onClick={handleSingleOnboarding}>
                    <FlagRounded sx={{ color: "whitesmoke" }} />
                  </IconButton>
                )}
                {callDetails?.user && (
                  <IconButton onClick={handleCustomerOpen}>
                    <PersonRounded sx={{ mr: 1, color: "whitesmoke" }} />
                  </IconButton>
                )}
              </Stack>
            </Box>
          }
          sx={{
            bgcolor: "primary.main",
            color: "primary.contrastText",
            py: 2,
            textAlign: "left",
          }}
        />

        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography variant="subtitle1">
                  <b>Name:</b>
                </Typography>
                <Typography variant="body2">{callDetails?.name}</Typography>
              </Box>
              <Divider sx={{ my: 1 }} />
              <Box>
                <Typography variant="subtitle1">
                  <b>Phone Number:</b>
                </Typography>
                <Typography variant="body2">{callDetails?.phone}</Typography>
              </Box>
              <Divider sx={{ my: 1 }} />
              <Box>
                <Typography variant="subtitle1">
                  <b>Entity:</b>
                </Typography>
                <Typography variant="body2">{callDetails?.entity}</Typography>
              </Box>
              <Divider sx={{ my: 1 }} />
              <Box>
                <Typography variant="subtitle1">
                  <b>Status:</b>
                </Typography>
                <Label
                  color={
                    callDetails?.call_details?.status === "initiated"
                      ? "success"
                      : callDetails?.call_details?.status === "pending"
                      ? "error"
                      : callDetails?.call_details?.status === "missed"
                      ? "warning"
                      : "info"
                  }
                  variant="filled"
                  sx={{ alignItems: "center", p: 2 }}
                >
                  <CallOutlined sx={{ mr: 1 }} />
                  <Typography variant="subtitle1">
                    {callDetails?.call_details?.status.toUpperCase()}
                  </Typography>
                </Label>
              </Box>

              {callDetails?.call_details?.status !== "missed" &&
                !isNaN(minutes) && (
                  <>
                    <Divider sx={{ my: 1 }} />
                    <Box alignItems="center" mb={2}>
                      <Typography
                        variant="subtitle1"
                        component="span"
                        sx={{ fontWeight: "bold", mr: 1 }}
                      >
                        Type:
                      </Typography>
                      <div>
                        {callDetails?.action === "user" ? (
                          <Label
                            color="success"
                            variant="filled"
                            sx={{ alignItems: "center", p: 1 }}
                          >
                            <CallReceivedRounded
                              color="inherit"
                              sx={{ mr: 1 }}
                            />
                            <Typography variant="subtitle3" sx={{ p: 1 }}>
                              INCOMING
                            </Typography>
                          </Label>
                        ) : (
                          <Label
                            color="info"
                            variant="filled"
                            sx={{ alignItems: "center", p: 2 }}
                          >
                            <CallMadeRounded color="inherit" sx={{ mr: 1 }} />
                            <Typography variant="subtitle1">
                              OUTGOING
                            </Typography>
                          </Label>
                        )}
                      </div>
                    </Box>
                  </>
                )}
            </Grid>

            <Grid item xs={12} md={6}>
              <>
                <Box>
                  <Typography variant="subtitle1">
                    <b>Time:</b>
                  </Typography>
                  <Typography variant="body2">
                    {getDateStringFullWithTimeSync(callDetails?.date)}
                  </Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
              </>

              <Box>
                <Typography variant="subtitle1">
                  <b>Call Duration:</b>
                </Typography>
                <Typography variant="body2">
                  {callDetails?.call_details?.status !== "missed" &&
                  !isNaN(minutes)
                    ? `${minutes} minutes ${seconds} seconds`
                    : "NA"}
                </Typography>
              </Box>
              <Divider sx={{ my: 1 }} />
              <Box>
                <Typography sx={{ mb: 1 }} variant="subtitle1">
                  <b>Recording:</b>
                </Typography>
                {callDetails?.call_details?.status !== "missed" && minutes ? (
                  <PlayContent songUrl={callDetails?.call_details?.recording} />
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    Recording not available
                  </Typography>
                )}
              </Box>

              {(callDetails?.call_details?.status === "missed" || !minutes) && (
                <>
                  <Divider sx={{ my: 1 }} />
                  <Box alignItems="center" mb={2}>
                    <Typography
                      variant="subtitle1"
                      component="span"
                      sx={{ fontWeight: "bold", mr: 1 }}
                    >
                      Type:
                    </Typography>
                    <div>
                      {callDetails?.action === "user" ? (
                        <Label
                          color="success"
                          variant="ghost"
                          sx={{ alignItems: "center", p: 1 }}
                        >
                          <CallReceivedRounded color="inherit" sx={{ mr: 1 }} />
                          <Typography variant="subtitle3" sx={{ p: 1 }}>
                            INCOMING
                          </Typography>
                        </Label>
                      ) : (
                        <Label
                          color="info"
                          variant="ghost"
                          sx={{ alignItems: "center", p: 2 }}
                        >
                          <CallMadeRounded color="inherit" sx={{ mr: 1 }} />
                          <Typography variant="subtitle1">OUTGOING</Typography>
                        </Label>
                      )}
                    </div>
                  </Box>
                </>
              )}
              {callDetails?.comments && (
                <>
                  <Divider sx={{ my: 1 }} />
                  <Box>
                    <Typography variant="subtitle1">
                      <b>Comments:</b>
                    </Typography>
                    <Typography variant="body2">
                      {callDetails?.comments}
                    </Typography>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>

          {["missed", "completed"].includes(
            callDetails?.call_details?.status
          ) && (
            <Stack
              direction="row"
              spacing={2}
              sx={{ mt: 2 }}
              justifyContent="right"
            >
              {loadingCall === true ? (
                <CircularProgress size={24} />
              ) : (
                <Button variant="contained" onClick={makeCall}>
                  Call Again
                  <CallRounded color="success" sx={{ m: 1 }} />
                </Button>
              )}
            </Stack>
          )}
        </CardContent>
      </Card>

      <SingleOnboarding
        selectEntry={{ _id: callDetails?.flag?._id }}
        handleClose={() => {
          setOnbDrawerOpen(false);
        }}
        drawerOpen={onbDrawerOpen}
      />

      <CustomerDetailsPage
        customer={{ _id: callDetails?.user, name: callDet?.name }}
        handleClose={() => setCustDrawerOpen(false)}
        drawerOpen={custDrawerOpen}
      />
    </Container>
  );
}
