import { useRef, useState } from "react";

import { Home, Person, Settings, AccountCircle } from "@mui/icons-material";

import { Link as RouterLink, useNavigate } from "react-router-dom";

// material
import { alpha } from "@mui/material/styles";
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";
// components

import MenuPopover from "../../components/MenuPopover";
import { useAuth } from "../../contexts/AuthContext";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Home",
    icon: <Home />,
    linkTo: "/",
  },
  {
    label: "Profile",
    icon: <Person />,
    linkTo: "#",
  },
  {
    label: "Settings",
    icon: <Settings />,
    linkTo: "#",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  // const [logoutError, setLogoutError] = useState();

  const navigate = useNavigate();
  const { currentManager, logout } = useAuth();

  const handleLogout = async () => {
    try {
      // setLogoutError(null);
      await logout();
      navigate("/login", {
        replace: true,
      });
    } catch (error) {
      console.log(error);
      // setLogoutError(error.code);
      alert(error.code);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.5),
            },
          }),
        }}
      >
        <Avatar
          alt="photoURL"
          sx={{ color: "white", backgroundColor: "primary.dark" }}
        >
          <AccountCircle />
        </Avatar>
      </IconButton>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          width: 220,
        }}
      >
        {currentManager && (
          <Box
            sx={{
              my: 1.5,
              px: 2.5,
            }}
          >
            {currentManager.name && (
              <Typography variant="subtitle1" noWrap>
                <b>{currentManager.name}</b>
              </Typography>
            )}
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
              }}
              noWrap
            >
              {currentManager.phone}
            </Typography>
          </Box>
        )}
        <Divider
          sx={{
            my: 1,
          }}
        />
        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{
              typography: "body2",
              py: 1,
              px: 2.5,
            }}
          >
            <Box
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            >
              {option.icon}
            </Box>
            {option.label}
          </MenuItem>
        ))}
        <Box
          sx={{
            p: 2,
            pt: 1.5,
          }}
        >
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
