import React from "react";
import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "src/utils/data-fetch";

import {
  Card,
  Container,
  Typography,
  CircularProgress,
  Stack,
  CardActionArea,
  Grid,
  CardMedia,
  Divider,
  Button,
  IconButton,
  Modal,
  Tooltip,
} from "@mui/material";

import {
  getDateStringCustom,
  getDateStringFullWithSlash,
  getDayStartAndEndSync,
  TWENTY4HOURS,
} from "src/utils/date-time-helper";
import Label from "src/pages/crm/Label";

import {
  EditRounded,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from "@mui/icons-material";
import ModifySubDModal from "./ModifySubDModal";

const TABLE_HEAD = [
  { id: "date time", label: "Date & Time", alignRight: false },
  { id: "quantity", label: "Quantity", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "milktype", label: "Milktype", alignRight: false },
  { id: "cart", label: "Cart", alignRight: false },
];

export const SubDateWiseSection = ({ customer }) => {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  let today = Date.now();
  let todayString = getDateStringFullWithSlash(today);

  let [selectedDate, setSelectedDate] = useState(today);
  let selectedDateStr = getDateStringFullWithSlash(selectedDate);

  const [dateListStart, setDateListStart] = useState(today - 2 * TWENTY4HOURS);
  let dates = [];

  for (let i = 0; i < 8; i++) {
    let d = dateListStart + i * TWENTY4HOURS;
    dates.push({
      date: getDateStringFullWithSlash(d),
      day: getDateStringCustom(d, "ddd")?.toUpperCase(),
      millis: d,
    });
  }

  const loadData = async () => {
    setLoadingData(true);

    await new Promise((resolve) => setTimeout(resolve, 200));

    let url = `/api/products/mgract/user/${customer._id}/schedule?date=${selectedDate}`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    loadData();
  }, [selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const [modifyModalState, setModifyModalState] = useState(null);

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={1.5}
        sx={{ mb: 2 }}
      >
        <IconButton
          size="large"
          color="black"
          onClick={() => setDateListStart((prev) => prev - 3 * TWENTY4HOURS)}
        >
          <KeyboardArrowLeftRounded />
        </IconButton>
        {dates.map((singleDay, index) => (
          <CardDate
            singleDay={singleDay}
            selectedDay={selectedDateStr}
            selectNew={(d) => {
              setSelectedDate(d);
            }}
            todayString={todayString}
          />
        ))}
        <IconButton
          size="large"
          color="black"
          onClick={() => setDateListStart((prev) => prev + 3 * TWENTY4HOURS)}
        >
          <KeyboardArrowRightRounded />
        </IconButton>
      </Stack>

      <Grid container spacing={2}>
        {loadingData === true ? (
          <CircularProgress sx={{ m: 3 }} />
        ) : (
          (customerData?.length > 0 &&
            customerData.map((data, index) => (
              <Grid item xs={12} md={6} lg={6}>
                <CardProduct
                  data={data}
                  onClick={() => {
                    setModifyModalState(data);
                  }}
                />
              </Grid>
            ))) || (
            <Grid item xs={12}>
              <Typography variant="h6" align="center" mt={5}>
                No scheduled deliveries for {selectedDateStr}
              </Typography>
            </Grid>
          )
        )}
      </Grid>

      {modifyModalState && (
        <Modal open={true} onClose={() => setModifyModalState(null)}>
          <ModifySubDModal
            currentSub={modifyModalState}
            handleM1Close={() => {
              setModifyModalState(null);
              loadData();
            }}
          />
        </Modal>
      )}
    </Container>
  );
};

const CardDate = ({ singleDay, selectedDay, selectNew, todayString }) => {
  return (
    <Card
      sx={{
        backgroundColor:
          selectedDay === singleDay?.date ? "primary.main" : "#fff",
        color: selectedDay === singleDay?.date ? "#fff" : "#000",
        flexGrow: 1,
      }}
    >
      <CardActionArea sx={{ p: 1 }} onClick={() => selectNew(singleDay.millis)}>
        <Typography gutterBottom align="center" variant="subtitle1">
          <b>{singleDay?.date?.substring(0, 5)}</b>
        </Typography>
        <Typography variant="body2" align="center">
          {singleDay?.date === todayString ? (
            <span style={{ color: "red" }}>
              <b>TODAY</b>
            </span>
          ) : (
            singleDay?.day
          )}
        </Typography>
      </CardActionArea>
    </Card>
  );
};

const CardProduct = ({ data, onClick }) => {
  const todayStart = getDayStartAndEndSync(Date.now()).start;
  return (
    <Card
      sx={{
        boxShadow: "3",
        display: "flex",
        backgroundColor: data.status !== "cancelled" ? "white" : "lightgrey",
        mt: 1,
      }}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <Tooltip title={data.comments}>
        <Stack direction={"row"}>
          <CardMedia sx={{ width: 150 }} image={data?.product?.images[0]} />
          <Divider orientation="vertical" flexItem />

          <Stack direction={"column"} p={2}>
            <Typography component="div" variant="h5">
              {data?.product_string}
            </Typography>

            <Stack direction={"row"} spacing={2} mt={1}>
              <Typography variant="subtitle1" color="black">
                {data.quantity}{" "}
                {data?.product?.price_unit?.substring(1) || "Kg"}
              </Typography>
              {data?.rate > 0 && (
                <Typography variant="subtitle1" color="red">
                  - ₹ {data.rate * data.quantity}
                </Typography>
              )}

              <Typography variant="subtitle1" color="black">
                {data?.slot === 1
                  ? "(5AM - 7AM)"
                  : data?.slot === 2
                  ? "(7AM - 9AM)"
                  : data?.slot === 3
                  ? "(9AM - 11AM)"
                  : data?.slot === 4
                  ? "(5PM - 7PM)"
                  : data?.slot === 5
                  ? "(7PM - 9PM)"
                  : "(ANYTIME)"}
              </Typography>
            </Stack>

            <Label
              variant="filled"
              color={(data.subscription && "success") || "info"}
              sx={{ mt: 1, maxWidth: 120 }}
            >
              {(data.subscription && "SUBSCRIPTION") || "ORDER"}
            </Label>

            <Label
              variant="filled"
              color={
                data.status === "pending"
                  ? "warning"
                  : data.status === "delivered"
                  ? "success"
                  : data.status === "paused"
                  ? "info"
                  : "error"
              }
              sx={{
                position: "absolute",
                right: "10px",
              }}
            >
              {data.status?.toUpperCase()}
            </Label>

            {data.cart && (
              <Label
                variant="filled"
                color={"primary"}
                sx={{
                  position: "absolute",
                  right: "10px",
                  bottom: "10px",
                }}
              >
                {data.cart?.name || "-"}
              </Label>
            )}
          </Stack>
        </Stack>
      </Tooltip>
    </Card>
  );
};
