import { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  Drawer,
  Tooltip,
  Stack,
} from "@mui/material";

import { useAuth } from "../../contexts/AuthContext";
import { dataFetch } from "../../utils/data-fetch";
import { getTimeString } from "../../utils/date-time-helper";

import Animation from "src/theme/skeleton";
import MakeFullScreen from "src/components/MakeFullScreen";
import EmployeeDetailsPage from "../employee/det/EmployeeDetailsPage";

export default function MilkCartsDailySection({ startDate, onCartSelected }) {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const { getAccessToken, activeFranchise } = useAuth();

  const initialLoad = async () => {
    setLoadingData(true);
    const ret = await dataFetch(
      `/api/milkcarts/mgract/all/detailed?date=${startDate}`,
      await getAccessToken(),
      activeFranchise
    );

    if (ret.success === true) {
      setData(ret.data);
    } else {
      setData([]);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    initialLoad();
  }, [activeFranchise, startDate]); // eslint-disable-line react-hooks/exhaustive-deps

  let m = 0;
  let e = 0;

  for (const cart of data) {
    m += ((cart && cart.morning && cart.morning.milk) || 0.0) / 1000;
    e += ((cart && cart.evening && cart.evening.milk) || 0.0) / 1000;
  }

  const [currEmp, setCurrEmp] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleEmpSelected = async (empData, name) => {
    setCurrEmp({ employee: empData, name: name });
    setDrawerOpen(true);
  };

  const MOut = ({ data }) => {
    return (
      <Stack direction={"column"}>
        <Typography variant="subtitle" noWrap mb={2}>
          <Tooltip title={data?.driver_name}>
            <div
              onClick={(e) => {
                handleEmpSelected(data?.default_employee, data.driver_name);
                e.stopPropagation();
              }}
            >
              {data.name} ({data?.driver_name})
            </div>
          </Tooltip>
        </Typography>
        <Typography variant="subtitle2" noWrap>
          Mor: {((data.morning ? data.morning.milk : 0.0) / 1000).toFixed(1)} L
          ({data.morning ? data.morning.interrupt : 0})
        </Typography>
        <Typography variant="subtitle2" noWrap>
          Eve: {((data.evening ? data.evening.milk : 0.0) / 1000).toFixed(1)} L
          ({data.evening ? data.evening.interrupt : 0})
        </Typography>
        <Typography variant="body2" noWrap>
          {getTimeString(data.last_update)}
        </Typography>
      </Stack>
    );
  };

  return (
    <>
      <Container>
        {loadingData === true ? (
          <Animation />
        ) : (
          <Grid container spacing={3}>
            <Grid key="pr" item xs={12} sm={6} md={4}>
              <Card sx={{ px: 2, py: 3 }}>
                <Container>
                  <Stack direction={"column"}>
                    <Typography variant="h6" noWrap color={"success.main"}>
                      Mor: {m.toFixed(1)} Lt.
                    </Typography>
                    <Typography variant="h6" noWrap color={"info.main"}>
                      Eve: {e.toFixed(1)} Lt.
                    </Typography>
                    <Typography variant="h6" noWrap mt={1}>
                      Total: {(m + e).toFixed(0)} Lt.
                    </Typography>
                  </Stack>
                </Container>
              </Card>
            </Grid>

            {data.map((cart, index) => {
              return (
                <Grid
                  key={cart._id}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  onClick={() => onCartSelected(cart)}
                >
                  <Card sx={{ px: 2, py: 2 }}>
                    <Container>
                      <MOut data={cart} />
                    </Container>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Container>

      {drawerOpen && (
        <MakeFullScreen
          onClose={() => setDrawerOpen(false)}
          open={drawerOpen}
          title={currEmp?.name}
        >
          <EmployeeDetailsPage incEmployee={currEmp} />
        </MakeFullScreen>
      )}
    </>
  );
}
