import {
  Card,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Grid,
  Modal,
  Stack,
  TableContainer,
  TableHead,
  Table,
  CircularProgress,
  Drawer,
  CardActionArea,
} from "@mui/material";

import { Edit as EditIcon } from "@mui/icons-material";

import { useEffect, useState } from "react";
import { getDateString, getDateStringFullSync, getTimeStringNew } from "src/utils/date-time-helper";
import { dataFetch, dataPost } from "src/utils/data-fetch";
import { useAuth } from "src/contexts/AuthContext";

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "phone", label: "Phone Number", alignRight: true },
  { id: "time", label: "Timing", alignRight: true },
];

export default function CampaignProfile({ campaign }) {
  const [currentData, setCurrentData] = useState();
  const [loadingData, setLoadingData] = useState(false);
  console.log(campaign);

  const { getAccessToken, activeFranchise } = useAuth();

  const loadData = async () => {
    setLoadingData(true);
    const response = await dataFetch(
      `/api/campaigns/mgract/campaign/${campaign._id}`,
      await getAccessToken(),
      activeFranchise
    );

    if (response.success === true) {
      setCurrentData(response.data);
    }
    setLoadingData(false);
  };

  // const updateAssignedCart = async (cart) => {
  //   setLoadingData(true);
  //   const response = await dataPost(
  //     `/api/apartments/mgract/appt/${apartment._id}/cart`,
  //     await getAccessToken(),
  //     { cart: cart },
  //     activeFranchise
  //   );

  //   if (response.success === true) {
  //     loadData();
  //   }

  //   setLoadingData(false);
  // };

  const [searchDrawerState, setSearchDrawerState] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  // const [uploadImageModal, setUploadImageModal] = useState(false);
  const closeUploadImageModal = () => {
    //setUploadImageModal(false);
    loadData();
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Stack direction={"column"} spacing={2}>
            <Card>
              <Stack direction="column" alignItems="center" spacing={2} pb={2}>
                <div>
                  <img
                    src={
                      (currentData && currentData.image[0]) ||
                      "https://milkvilla.in/images/mv_new_logo.jpg"
                    }
                    alt="Apartment"
                  />
                </div>

                <Typography variant="h6">
                  {currentData ? currentData.name : ""}
                </Typography>

                <Button
                  variant="outlined"
                  color="primary"
                  //onClick={() => setUploadImageModal(true)}
                >
                  Update Image
                </Button>
              </Stack>
            </Card>

            <Card>
              <CardActionArea
                sx={{ p: 2 }}
                // onClick={() => setSearchDrawerState(true)}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <Typography variant="h6">Cart:</Typography>
                  {loadingData === true && <CircularProgress />}

                  <Typography variant="subtitle1">
                    {currentData?.carts
                      ? currentData.carts.map((cart) => {
                          return cart.name;
                        })
                      : "Not Assigned"}
                  </Typography>
                </Stack>
              </CardActionArea>
            </Card>
          </Stack>
        </Grid>

        <Grid item xs={12} md={8} lg={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              {campaign && (
                <CampaignDetailsCard
                  doc={currentData ? currentData : campaign}
                  reload={loadData}
                />
              )}
            </Grid>
            {/* <Grid item xs={12} md={12} lg={12}>
              {apartment && (
                <ManagerDetailsCard
                  doc={currentData ? currentData : apartment}
                  reload={loadData}
                />
              )}
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>

      {/* <Drawer
        anchor={"right"}
        open={searchDrawerState ? true : false}
        onClose={() => setSearchDrawerState(null)}
        sx={{ zIndex: 1300, p: 3 }}
      >
        <MilkCartSearch
          onCartSelected={(cart) => {
            setSearchDrawerState(null);
            updateAssignedCart(cart._id);
          }}
        />
      </Drawer>

      <Modal open={uploadImageModal} onClose={closeUploadImageModal}>
        <UploadAptImage
          handleM1Close={closeUploadImageModal}
          aptId={apartment._id}
        />
      </Modal> */}
    </>
  );
}

const CampaignDetailsCard = ({ doc, reload }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const closeModalFn = () => {
    setModalOpen(false);
    reload();
  };

  const openModalFn = async () => {
    setModalOpen(true);
  };

  return (
    <Card sx={{ px: 3, py: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" gutterBottom>
          Details
        </Typography>

        <Button
          variant="outlined"
          color="error"
          startIcon={<EditIcon />}
          onClick={openModalFn}
        >
          Edit
        </Button>
      </Stack>

      {doc && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={6}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>Active:</b>
                </TableCell>
                <TableCell>{doc.status ? "Yes" : "No"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Description:</b>
                </TableCell>
                <TableCell>{doc.description}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b> Start Date:</b>
                </TableCell>
                <TableCell>{getDateStringFullSync(doc.start_date)}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <b>End Date:</b>
                </TableCell>
                <TableCell>{getDateStringFullSync(doc.end_date)}</TableCell>
              </TableRow>
            </TableBody>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>Cart:</b>
                </TableCell>
                <TableCell>
                  {doc.cart
                    ? doc.cart.map((cart) => {
                        return cart.name;
                      })
                    : "Not Assigned"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Objective:</b>
                </TableCell>
                <TableCell>{doc.objective}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <b>Channel:</b>
                </TableCell>
                <TableCell>
                  {doc.channel
                    ? doc.channel.map((channel) => {
                        return channel;
                      })
                    : "No channel"}
                </TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell>
                  <b>Eve End Time:</b>
                </TableCell>
                <TableCell>{getTimeStringNew(doc.end_time_e)}</TableCell>
              </TableRow> */}
            </TableBody>
          </Grid>
        </Grid>
      )}

      {/* <Modal open={modalOpen} onClose={closeModalFn}>
        <EditApartmentModal handleM1Close={closeModalFn} apartment={doc} />
      </Modal> */}
    </Card>
  );
};
