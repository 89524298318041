import React from "react";
import { useState, useEffect } from "react";

import {
  Card,
  Stack,
  Container,
  Typography,
  CircularProgress,
  TextField,
  Box,
  InputAdornment,
  Autocomplete,
} from "@mui/material";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import { useLoadScript } from "@react-google-maps/api";

import { useAuth } from "src/contexts/AuthContext";
import { dataFetch, dataPatch } from "src/utils/data-fetch";
import { LoadingButton } from "@mui/lab";

const TABLE_HEAD = [
  { id: "name", label: "Guard Name", alignRIght: false },
  { id: "phone", label: "Phone", alignRIght: false },
  { id: "timing", label: "Timing", alignRIght: false },
];

export default function AptLocationSection({ apt }) {
  const [balanceData, setBalanceData] = useState({});
  const [loadingData, setLoadingData] = useState(true);

  const { getAccessToken, activeFranchise, allFranchisees } = useAuth();

  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [detail, setDetail] = useState("");

  useEffect(() => {
    getLocationData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getLocationData = async () => {
    setLoadingData(true);

    const ret = await dataFetch(
      `/api/apartments/mgract/appt/${apt._id}/location`,
      await getAccessToken(),
      activeFranchise
    );
    if (ret.success === true) {
      setBalanceData(ret.data);
      setLat(ret.data.coordinates[1]);
      setLng(ret.data.coordinates[0]);
      setDetail(ret.data.detail);
    }
    setLoadingData(false);
  };

  const handleUpdate = (item) => {
    updateLocation(item);
  };

  const updateLocation = async () => {
    setLoadingData(true);

    let url = `/api/apartments/mgract/appt/${apt._id}/location`;

    const ret = await dataPatch(
      url,
      await getAccessToken(),
      {
        lat: lat,
        lng: lng,
        detail: detail,
      },
      activeFranchise
    );

    if (ret.success === true) {
    } else {
      alert(ret.message);
    }
    setLoadingData(false);

    getLocationData();
  }; // eslint-disable-line react-hooks/exhaustive-deps

  //map api
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCh-7YN5PIJTvSNHmqRC7FxvMOOdnr5joI",
    libraries: ["places"],
  });

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({ debounce: 1000 });

  //end map api

  //geocoding

  const onSelectedAddrChange = async (description) => {
    console.log("Geocoding starting...");
    setValue(description, false);
    clearSuggestions();
    const result = await getGeocode({ address: description }); //get geocoding object
    const { lat, lng } = getLatLng(result[0]);

    setLat(lat);
    setLng(lng);
  };

  useEffect(() => {
    if (detail && detail !== "" && detail !== balanceData.detail) {
      onSelectedAddrChange(detail);
    }
  }, [detail]);

  //end geocoding

  return (
    <Card title="Apartment Guards" sx={{ pt: 2, pb: 2 }}>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h5" gutterBottom>
            Apartment Location
          </Typography>
        </Stack>

        {loadingData === true && <CircularProgress />}
        {loadingData === false && (
          <Stack spacing={3} direction={"column"}>
            <TextField
              fullWidth
              value={lat}
              disabled
              onChange={(e) => setLat(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box sx={{ color: "text.disabled" }}>Latitude</Box>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              value={lng}
              disabled
              onChange={(e) => setLng(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box sx={{ color: "text.disabled" }}>Longitude</Box>
                  </InputAdornment>
                ),
              }}
            />

            <Autocomplete
              id="address-autocomplete"
              fullWidth
              autoHighlight
              disabled={!ready}
              inputValue={value}
              onInputChange={(event, newInputValue) => {
                setValue(newInputValue);
              }}
              value={detail}
              onChange={(event, newValue) => {
                setDetail(newValue);
              }}
              options={data.map((suggestion) => suggestion.description)}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField {...params} label="Address" />
              )}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loadingData}
              sx={{ mt: 3 }}
              onClick={() => handleUpdate()}
            >
              Save
            </LoadingButton>
          </Stack>
        )}
      </Container>
    </Card>
  );
}
