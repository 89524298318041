import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { forwardRef } from "react";
import MilkCartDetails from "./MilkCartDetails";
import { CloseRounded } from "@mui/icons-material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenMilkCartsDetails({
  cart,
  handleClose,
  drawerOpen,
}) {
  return (
    <Dialog
      maxWidth="lg"
      scroll="body"
      fullWidth
      open={drawerOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            {cart?.name} ({cart?.driver_name || "-"})
          </Typography>
          <Button variant="contained" color="error" onClick={handleClose}>
            <CloseRounded />
          </Button>
        </Toolbar>
      </AppBar>
      <MilkCartDetails incCart={cart} />
    </Dialog>
  );
}
