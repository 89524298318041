import { useState, useEffect, forwardRef } from "react";
import { useAuth } from "src/contexts/AuthContext";

import { dataFetch } from "./data-fetch-cro";

import { CloseRounded } from "@mui/icons-material";

import {
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  Button,
  Toolbar,
  AppBar,
  Dialog,
  Slide,
} from "@mui/material";
import { DateSelector } from "src/utils/date-selector";
import { getDayStartAndEndSync } from "src/utils/date-time-helper";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "phone", label: "Phone", alignRight: false },
  { id: "address", label: "Address", alignRight: false },
];

export default function MyCustomers({ onUserClick, handleClose, drawerOpen }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedDate, setSelectedDate] = useState(Date.now());

  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken } = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = async () => {
    setLoadingData(true);
    const times = getDayStartAndEndSync(selectedDate);

    let url = `/api/cro/mgract/customers/addedbyme?date_gt=${times.start}&date_lt=${times.end}`;

    const ret = await dataFetch(url, await getAccessToken(), null);

    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    handleSearch();
  }, [page, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog
      fullScreen
      scroll="body"
      open={drawerOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="white">
        <Toolbar>
          <div style={{ marginRight: 2, flex: 1 }}>
            <DateSelector value={selectedDate} onChange={setSelectedDate} />
          </div>
          <Button variant="contained" color="error" onClick={handleClose}>
            <CloseRounded />
          </Button>
        </Toolbar>
      </AppBar>
      <Container>
        {customerData.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {customerData.map((user) => {
                    return (
                      <TableRow hover onClick={() => onUserClick(user)}>
                        <TableCell>{user?.name || "-"}</TableCell>
                        <TableCell>xx{user.phone?.substring(9)}</TableCell>

                        <TableCell>
                          {user?.address?.detail?.substring(0, 20) || "NA"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5]}
              component="div"
              count={-1}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </Container>
    </Dialog>
  );
}
