import {
  Card,
  Typography,
  Stack,
  CircularProgress,
  Avatar,
} from "@mui/material";

export default function ManagerImageCard({ manager, loadingData }) {
  return (
    <>
      <Card sx={{ pl: 2, pr: 2, minHeight: "350px" }}>
        <Stack direction="column" alignItems="center" pt={5}>
          <Avatar
            sx={{
              width: "200px",
              height: "200px",
            }}
          >
            {manager && manager.image && <img src={manager.image} alt="Manager" />}
          </Avatar>

          <Typography variant="h6" sx={{ mt: 3 }}>
            {(manager && ((manager.active === true && "Active") || "Inactive")) ||
              "-"}
          </Typography>

          {loadingData === true && <CircularProgress />}
        </Stack>
      </Card>
    </>
  );
}
