import {
  Card,
  Stack,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Divider,
  TextField,
  Modal,
  Button,
  Alert,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataDelete, dataFetch } from "src/utils/data-fetch";
import {
  DeleteOutline,
  EditAttributes,
  EditCalendar,
  EditRounded,
} from "@mui/icons-material";
import { EditEmployeeSalaryCompModal } from "./EditEmployeeSalaryCompModal";

export default function EmployeeSalaryStructureCard({ employee }) {
  const [salaryData, setSalaryData] = useState([]);
  const [ctc, setCtc] = useState(0);
  const [totalMonthly, setTotalMonthly] = useState(0);
  const [totalAnnual, setTotalAnnual] = useState(0);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedSalaryComp, setSelectedSalaryComp] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { getAccessToken, activeFranchise } = useAuth();
  const [error, setError] = useState();
  const [isDeleted, setIsDeleted] = useState(false);

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const fetchSalaryData = async () => {
    const url = `/api/employees/mgract/v2/employee/${employee.employee}/salary`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success) {
      const salaryStructure = ret.data.employee_det.salary_structure;
      const totalYearly = salaryStructure.reduce(
        (acc, item) =>
          !item.cid.part_of.includes("deductions")
            ? acc + item.actual_amount
            : acc,
        0
      );

      setTotalAnnual(totalYearly);
      setTotalMonthly(totalYearly / 12);
      setSalaryData(salaryStructure);
      setCtc(ret.data.employee_det.ctc);
    }
  };

  const handleDeleteSalaryComp = async () => {
    const url = `/api/employees/mgract/v2/employee/${employee._id}/salarycomp/${selectedSalaryComp?.cid?._id}`;

    const ret = await dataDelete(url, await getAccessToken(), activeFranchise);

    if (ret.success) {
      console.log(ret.data);
      setIsDeleted(true);
      handleCloseDeleteModal();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  useEffect(() => {
    fetchSalaryData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Card sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Salary Structure
        </Typography>

        <Stack direction="row" alignItems="center" my={2}>
          <Card
            sx={{
              px: 3,
              py: 2,
              backgroundColor: "primary.dark",
              color: "white",
              borderRadius: 2,
              boxShadow: 5,
              width: "100%",
              maxWidth: 400,
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ my: 2 }}
              color="white.main"
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                CTC:
              </Typography>
              <Typography variant="h6" sx={{ fontSize: "1.5rem" }}>
                ₹ {ctc} /-
              </Typography>
            </Stack>
          </Card>
        </Stack>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                Component
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: "white" }}
                align="right"
              >
                Amount (₹)
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: "white" }}
                align="right"
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {salaryData.map(
              (item) =>
                !item.cid.part_of.includes("deductions") && (
                  <TableRow key={item.cid}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="right">
                      ₹ {item.actual_amount} /-
                    </TableCell>
                    <TableCell align="right">
                      <Stack direction="row-reverse" spacing={1}>
                        <IconButton
                          onClick={() => {
                            setSelectedSalaryComp(item);
                            setOpenEditModal(true);
                          }}
                        >
                          <EditRounded />
                        </IconButton>

                        <IconButton
                          onClick={() => {
                            setSelectedSalaryComp(item);
                            setOpenDeleteModal(true);
                          }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>

        <Divider sx={{ my: 2, borderColor: "white" }} />

        {/* Total Monthly and Annual Salary */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ my: 2 }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Total Monthly Salary:
          </Typography>
          <Typography variant="h6" sx={{ fontSize: "1.5rem" }}>
            ₹ {totalMonthly} /-
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ my: 2 }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Total Annual Salary:
          </Typography>
          <Typography variant="h6" sx={{ fontSize: "1.5rem" }}>
            ₹ {totalAnnual} /-
          </Typography>
        </Stack>

        <Divider sx={{ my: 2, borderColor: "white" }} />
        <Table>
          <TableBody>
            {salaryData.map(
              (item) =>
                item.cid.part_of.includes("deductions") && (
                  <TableRow key={item.cid}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="right">
                      ₹ {item.actual_amount} /-
                    </TableCell>
                    <TableCell align="right">
                      <Stack direction="row-reverse" spacing={1}>
                        <IconButton
                          onClick={() => {
                            setSelectedSalaryComp(item);
                            setOpenEditModal(true);
                          }}
                        >
                          <EditRounded />
                        </IconButton>

                        <IconButton
                          onClick={() => {
                            setSelectedSalaryComp(item);
                            setOpenDeleteModal(true);
                          }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
      </Card>

      <Modal
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
      >
        <EditEmployeeSalaryCompModal
          handleM1Close={() => {
            setOpenEditModal(false);
          }}
          empid={employee._id}
          salary={selectedSalaryComp}
        />
      </Modal>

      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <div>
          <Card
            sx={{
              px: 4,
              py: 4,
              minWidth: 400, // Reduce width to make it more compact
              top: "50%",
              left: "50%",
              position: "absolute",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24, // Better shadow effect
              borderRadius: 2, // Rounded corners
            }}
          >
            {!isDeleted ? (
              <>
                <Typography variant="h6" gutterBottom>
                  Are you sure you want to delete this component?
                </Typography>

                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="flex-end"
                  mt={2}
                >
                  <Button variant="contained" onClick={handleCloseDeleteModal}>
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteSalaryComp}
                  >
                    Confirm
                  </Button>
                </Stack>

                {error && (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    {error}
                  </Alert>
                )}
              </>
            ) : (
              <Alert severity="success" variant="filled">
                Component has been successfully deleted.
              </Alert>
            )}
          </Card>
        </div>
      </Modal>
    </>
  );
}
