import { Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";

const APP_BAR_DESKTOP = 0;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
  paddingTop: APP_BAR_DESKTOP,
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: theme.spacing(1),
}));

// ----------------------------------------------------------------------

export default function CROLayout() {
  return (
    <RootStyle>
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
