import {
  CloseRounded,
  DeleteRounded,
  DownloadRounded,
  WhatsApp,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { useSnackbar } from "src/contexts/SnackbarContext";
import { dataDelete, rootUrl } from "src/utils/data-fetch";
import {
  getDateStringCWM2,
  getDateStringFullWithTimeSync,
} from "src/utils/date-time-helper";

const TABLE_HEAD = [
  { id: "timeline", label: "Timeline", alignRight: false },
  { id: "item", label: "Item", alignRight: false },
  { id: "qty", label: "Qty", alignRight: false },
  { id: "rate", label: "Rate", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
];

const TABLE_HEAD_CASHFLOW = [
  { id: "timeline", label: "Timeline", alignRight: false },
  { id: "method", label: "Method", alignRight: false },
  { id: "from", label: "From", alignRight: false },
  { id: "to", label: "To", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
];

export default function SaleTxnDetails({ txn, onClose }) {
  const [loading, setLoading] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const downloadUrl = `${rootUrl}/api/extrasales/openact/txns/txn/${txn._id}/bill`;

  const handleDownload = () => {
    setLoading(true);

    window.open(downloadUrl, "_blank");
    setLoading(false);
  };

  const whatsappText = `Click here to download the Invoice PDF:

${downloadUrl}

Total Amount: ₹${txn?.amount}
Date: ${getDateStringCWM2(txn?.date)}
`;

  const handleWpShare = () => {
    setLoading(true);

    window.open(
      `https://wa.me/${txn?.to?.phone}?text=${encodeURIComponent(
        whatsappText
      )}`,
      "_blank"
    );
    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);

    const ret = await dataDelete(
      `/api/extrasales/mgract/txns/txn/${txn._id}`,
      await getAccessToken(),
      activeFranchise
    );

    if (ret.success) {
      showSnackbar(ret.message || "Transaction Deleted!", "success");
      onClose();
    } else {
      showSnackbar(ret.message || "Transaction Delete Failed!", "error");
    }

    setLoading(false);
  };

  return (
    <Container
      sx={{
        px: 4,
        py: 4,
        width: 800,
        height: "fit-content",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
      }}
    >
      {txn && txn.entity === "cashflow" ? (
        <Card>
          <CardHeader
            title={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  py: 1,
                }}
              >
                <Typography variant="body1">{txn && txn?.to?.name}</Typography>
              </Box>
            }
            sx={{
              bgcolor: "primary.main",
              color: "primary.contrastText",
              py: 1,
              textAlign: "left",
            }}
          />

          <CardContent>
            <Box
              sx={{
                m: 2,
              }}
            >
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body1" noWrap>
                  <b>{txn?.entity.toUpperCase()} </b>
                </Typography>

                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "text.secondary",
                  }}
                >
                  {getDateStringFullWithTimeSync(txn?.date)}
                </Typography>
              </Stack>
            </Box>

            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD_CASHFLOW.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {getDateStringFullWithTimeSync(txn?.date)}
                    </TableCell>
                    <TableCell>{txn?.method}</TableCell>
                    <TableCell>{txn?.from?.name}</TableCell>
                    <TableCell>{txn?.to?.name}</TableCell>
                    <TableCell>{txn?.amount}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>

          <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
            <Box sx={{ p: 2, ml: 4 }}>
              <Typography variant="body2" noWrap>
                {txn?.comments}
              </Typography>
            </Box>
            <Box sx={{ p: 2, mr: 4 }}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                sx={{ width: "100%" }}
              >
                <Typography variant="body2" noWrap>
                  Total Amount:{" "}
                </Typography>
                <Typography variant="subtitle2" noWrap sx={{ ml: 1 }}>
                  ₹{txn?.amount}
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Card>
      ) : (
        <Card>
          <CardHeader
            title={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  py: 1,
                }}
              >
                <Typography variant="body1">{txn && txn?.to?.name}</Typography>

                <Stack direction="row" justifyContent="flex-end" spacing={1}>
                  <Button
                    onClick={handleWpShare}
                    variant="contained"
                    color="success"
                  >
                    {loading ? <CircularProgress /> : <WhatsApp />}
                  </Button>

                  <Button
                    onClick={handleDownload}
                    variant="contained"
                    color="white"
                  >
                    {loading ? <CircularProgress /> : <DownloadRounded />}
                  </Button>

                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => {
                      confirm({ description: "Are you sure?" }).then(() => {
                        handleDelete();
                      });
                    }}
                  >
                    {loading ? <CircularProgress /> : <DeleteRounded />}
                  </Button>
                  <Button variant="contained" color="error" onClick={onClose}>
                    <CloseRounded />
                  </Button>
                </Stack>
              </Box>
            }
            sx={{
              bgcolor: "primary.main",
              color: "primary.contrastText",
              py: 1,
              textAlign: "left",
            }}
          />

          <CardContent>
            <Box
              sx={{
                m: 2,
              }}
            >
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body1" noWrap>
                  <b>{txn?.bill?.category.toUpperCase()} </b>
                </Typography>

                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "text.secondary",
                  }}
                >
                  {getDateStringFullWithTimeSync(txn?.bill?.date)}
                </Typography>
              </Stack>
            </Box>

            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {txn?.bill?.items.map((orders, i) => {
                    return (
                      <TableRow>
                        <TableCell>
                          {getDateStringFullWithTimeSync(txn?.timeline[i]?.ts)}
                        </TableCell>
                        <TableCell>{orders?.name}</TableCell>
                        <TableCell>{orders?.quantity}</TableCell>
                        <TableCell>{orders?.rate}</TableCell>
                        <TableCell>{orders?.quantity * orders?.rate}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>

          <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
            <Box sx={{ p: 2, ml: 4 }}>
              <Typography variant="body2" noWrap>
                {txn?.comments}
              </Typography>
            </Box>
            <Box sx={{ p: 2, mr: 4 }}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                sx={{ width: "100%" }}
              >
                <Typography variant="body2" noWrap>
                  Total Amount:{" "}
                </Typography>
                <Typography variant="subtitle2" noWrap sx={{ ml: 1 }}>
                  ₹{txn?.amount}
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Card>
      )}
    </Container>
  );
}
