import { LoadingButton } from "@mui/lab";
import { Alert, Card, Grid, Stack, TextField, Typography } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataPatch } from "src/utils/data-fetch";
import * as Yup from "yup";

export const EditEmployeeSalaryCompModal = ({
  handleM1Close,
  salary,
  empid,
}) => {
  const [error, setError] = useState();

  const { getAccessToken, activeFranchise } = useAuth();
  console.log("emp", empid);

  const approveNow = async (values) => {
    let up = {
      actual_amount: values.amount,
      value: values.percentage,
      comment: values.comment,
    };

    const url = `/api/employees/mgract/v2/employee/${empid}/salarycomp/${salary?.cid?._id}`;

    const ret = await dataPatch(
      url,
      await getAccessToken(),
      up,
      activeFranchise
    );

    if (ret.success) {
      console.log(ret.data);
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }
  };

  const DataSchema = Yup.object().shape({
    amount: Yup.number().required("Required"),
    percentage: Yup.number(),
    comment: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      amount: salary?.actual_amount,
      percentage: salary?.value,
      comment: "",
    },

    validationSchema: DataSchema,

    onSubmit: (values) => {
      approveNow(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 600,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Edit Employee
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                label="Amount"
                {...getFieldProps("amount")}
                error={Boolean(touched.amount && errors.amount)}
                helperText={touched.amount && errors.amount}
              />
              <TextField
                fullWidth
                label="Percentage"
                {...getFieldProps("percentage")}
                error={Boolean(touched.percentage && errors.percentage)}
                helperText={touched.percentage && errors.percentage}
              />
              <TextField
                fullWidth
                label="Comment"
                {...getFieldProps("comment")}
                error={Boolean(touched.comment && errors.comment)}
                helperText={touched.comment && errors.comment}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Update Component
            </LoadingButton>
          </Form>
        </FormikProvider>
        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>
    </div>
  );
};
