import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { styled } from "@mui/material/styles";
import { dataFetch } from "src/utils/data-fetch";
import { useSnackbar } from "src/contexts/SnackbarContext";

import { SearchRounded } from "@mui/icons-material";

import {
  Container,
  Table,
  Box,
  OutlinedInput,
  InputAdornment,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  CircularProgress,
  Stack,
  Tooltip,
} from "@mui/material";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "code", label: "Code", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
];

export default function CategorySearch({ onCategorySelected }) {
  const [deptData, setDeptData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();

  const [query, setQuery] = useState("");

  const handleSearch = async () => {
    setLoadingData(true);

    let url = `/api/inventory/mgract/categories`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setDeptData(ret.data);
    } else {
      showSnackbar(ret.message || "Load failed!", "error");
    }

    setLoadingData(false);
  };

  useEffect(() => {
    handleSearch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filtered = deptData.filter((item) => {
    return item.name.toLowerCase().includes(query);
  });

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
        mt={2}
        spacing={2}
      >
        <SearchStyle
          placeholder="Search Inventory Categories..."
          onChange={(e) => setQuery(e.target.value?.toLowerCase())}
          value={query}
          startAdornment={
            <InputAdornment position="start">
              <Box sx={{ color: "text.disabled" }}>
                <SearchRounded />
              </Box>
            </InputAdornment>
          }
          endAdornment={
            loadingData === true && <CircularProgress color="secondary" />
          }
          sx={{ mt: 4, mb: 4 }}
          autoFocus={true}
        />
      </Stack>
      {filtered.length > 0 && (
        <div>
          <TableContainer sx={{ minHeight: 100 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.alignRight ? "right" : "left"}
                      sortDirection={false}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {filtered
                  .sort((a, b) => a?.name?.localeCompare(b?.name))
                  .map((item) => (
                    <SingleItem
                      item={item}
                      onClick={() => onCategorySelected(item)}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </Container>
  );
}

const SingleItem = ({ item, onClick }) => {
  return (
    <Tooltip key={item?.code} title={item?.details || ""} arrow>
      <TableRow hover onClick={onClick} sx={{ cursor: "pointer" }}>
        <TableCell>
          <b>{item?.code}</b>
        </TableCell>
        <TableCell>{item?.name}</TableCell>
      </TableRow>
    </Tooltip>
  );
};
