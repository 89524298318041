import React from "react";
import { useState, useEffect } from "react";

import {
  Card,
  Table,
  Stack,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
} from "@mui/material";

import { dataFetch } from "../../utils/data-fetch";
import { useAuth } from "../../contexts/AuthContext";
import {
  TWENTY4HOURS,
  getDayStartAndEndSync,
} from "src/utils/date-time-helper";

import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";

const TABLE_HEAD = [
  { id: "date", label: "Date", alignRIght: false },
  { id: "customer", label: "Customer", alignRIght: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "amount", label: "Amount", alignRight: true },
];

export default function DateWise({ onEntryClick, onUserClick }) {
  const [balanceData, setBalanceData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const [startDate, setStartDate] = useState(
    getDayStartAndEndSync(Date.now()).start
  );

  const { getAccessToken, activeFranchise } = useAuth();

  useEffect(() => {
    fetchBalanceData();
  }, [activeFranchise, startDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchBalanceData = async () => {
    setLoadingData(true);

    const ret = await dataFetch(
      `/api/onlinepayments/mgract/query?sort=_id&dir=d&date_gt=${startDate}&date_lt=${
        startDate + TWENTY4HOURS - 1
      }`,
      await getAccessToken(),
      activeFranchise
    );
    console.log(ret);
    if (ret.success === true) {
      setBalanceData(ret.data);
    }
    setLoadingData(false);
  };

  return (
    <Card title="Online Payments" sx={{ pt: 2, pb: 2 }}>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h5" gutterBottom>
            DateWise
          </Typography>

          {loadingData === true && <CircularProgress color="secondary" />}

          <Stack justifyContent={"flex-end"} direction={"row"}>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={"en-in"}
            >
              <DatePicker
                value={moment(startDate)}
                onChange={(newValue) => {
                  console.log(newValue);
                  if (newValue && startDate !== newValue.valueOf()) {
                    setStartDate(newValue.valueOf());
                  }
                }}
                disableCloseOnSelect={false}
                format="DD/MM/YYYY"
                inputFormat="DD/MMM/YYYY"
                loading={loadingData}
              />
            </LocalizationProvider>
          </Stack>
        </Stack>

        {balanceData.length > 0 ? (
          <div>
            <TableContainer sx={{ minHeight: 200 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {balanceData.map((bal) => {
                    const color =
                      bal.status === 0
                        ? "lightcyan"
                        : bal.status === 1
                        ? "palegreen"
                        : "lightsalmon";
                    return (
                      <TableRow
                        hover
                        onClick={() => onEntryClick(bal._id)}
                        style={{ backgroundColor: color }}
                      >
                        <TableCell>{bal?.time}</TableCell>
                        <TableCell
                          onClick={(e) => {
                            if (bal?.user) {
                              onUserClick(bal?.user);
                              e.stopPropagation();
                            }
                          }}
                        >
                          {bal?.user?.name || bal?.pos_id?.toUpperCase() || "-"}
                        </TableCell>
                        <TableCell>
                          {bal.status === 0
                            ? "PENDING"
                            : bal.status === 1
                            ? "SUCCESS"
                            : "FAILED"}
                        </TableCell>
                        <TableCell align="right">
                          ₹ {bal?.txn_amount?.toFixed(1)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <Typography variant="body2" align="center" sx={{ mt: 3, mb: 2 }}>
            No Data found
          </Typography>
        )}
      </Container>
    </Card>
  );
}
