import { forwardRef, useEffect, useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Grid,
  Autocomplete,
  Box,
  OutlinedInput,
  InputAdornment,
  Drawer,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  Button,
} from "@mui/material";

import { parseTimeString } from "src/utils/date-time-helper";
import { dataPost } from "src/utils/data-fetch";

import { LoadingButton } from "@mui/lab";

import MilkCartSearch from "../det/CartSearch";
import AddZoneSelectMap from "./AddZoneSelectMap";
import { CloseRounded } from "@mui/icons-material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddZoneModal({ handleM1Close, drawerOpen, polygon }) {
  const [error, setError] = useState(null);
  const { getAccessToken, activeFranchise } = useAuth();

  const [selLocation, setSelLocation] = useState([]);
  const [selCenter, setSelCenter] = useState([]);

  const [selectedCart, setSelectedCart] = useState({});
  const [cartDrawerState, setCartDrawerState] = useState(false);

  const op = [];
  let centerCoords = [];

  if (polygon) {
    polygon.getLatLngs()[0].forEach((latLng) => {
      op.push([latLng.lng, latLng.lat]);
    });

    const c = polygon.getCenter();
    centerCoords = [c.lng, c.lat];
  }
  //milkvilla API

  const AddData = async (values) => {
    try {
      const up = {
        name: values.name.trim(),
        start_time: parseTimeString(values.start_time),
        start_time_e: parseTimeString(values.start_time_e),
        end_time: parseTimeString(values.end_time),
        end_time_e: parseTimeString(values.end_time_e),
        geometry: selLocation,
        location: {
          coordinates: selCenter,
          detail: values.address,
        },
        cart: selectedCart._id,
      };

      up.geometry.push(selLocation[0]);

      const response = await dataPost(
        "/api/apartments/mgract/addzone",
        await getAccessToken(),
        up,
        activeFranchise
      );

      if (response.success === true) {
        console.log(response.data);
        handleM1Close();
      } else {
        console.log(response.message + " - " + response.code);
        throw new Error(response.message + " - " + response.code); // Throw for error handling
      }
    } catch (error) {
      setError(error); // Set error message in state
    }
  };

  //end milkvilla API

  //formik schema

  const DataSchema = Yup.object().shape({
    name: Yup.string().required("Name is required!").trim(),
    address: Yup.string().required("Address is required!").trim(),
    start_time: Yup.string().required("Start Time is required"),
    start_time_e: Yup.string().required("Start Time is required"),
    end_time: Yup.string().required("End Time is required"),
    end_time_e: Yup.string().required("End Time is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      start_time: "05:00 AM",
      start_time_e: "05:00 PM",
      end_time: "11:00 AM",
      end_time_e: "09:00 PM",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await AddData(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  //end formik schema

  return (
    <Dialog
      fullScreen
      open={drawerOpen}
      onClose={handleM1Close}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} color="primary">
        <Toolbar>
          <Typography
            sx={{ mr: 2, flex: 1 }}
            variant="h6"
            component="div"
            color={"black"}
          >
            Add Zone
          </Typography>
          <Button
            edge="end"
            color="error"
            onClick={handleM1Close}
            variant="contained"
          >
            <CloseRounded />
          </Button>
        </Toolbar>
      </AppBar>

      <Container maxWidth>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={4}>
            <Card
              sx={{
                px: 4,
                py: 4,
                height: "fit-content",
              }}
            >
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        fullWidth
                        label="Name"
                        {...getFieldProps("name")}
                        helperText={touched.name && errors.name}
                        error={Boolean(touched.name && errors.name)}
                        inputProps={{
                          style: { textTransform: "capitalize" },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        fullWidth
                        label="Address"
                        {...getFieldProps("address")}
                        helperText={touched.address && errors.address}
                        error={Boolean(touched.address && errors.address)}
                        inputProps={{
                          style: { textTransform: "capitalize" },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                      <OutlinedInput
                        fullWidth
                        type="text"
                        value={(selectedCart && selectedCart.name) || ""}
                        onClick={() => setCartDrawerState(true)}
                        startAdornment={
                          <InputAdornment position="start">
                            <Box sx={{ color: "text.disabled" }}>CART</Box>
                          </InputAdornment>
                        }
                      />
                    </Grid>

                    <Grid item xs={6} md={6} lg={6}>
                      <TextField
                        fullWidth
                        label="Start Time"
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "^([0-9]|0[0-9]|1[0-2]):[0-5][0-9] [AP]M$",
                        }}
                        {...getFieldProps("start_time")}
                        helperText={touched.start_time && errors.start_time}
                        error={Boolean(touched.start_time && errors.start_time)}
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <TextField
                        fullWidth
                        label="End Time"
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "^([0-9]|0[0-9]|1[0-2]):[0-5][0-9] [AP]M$",
                        }}
                        {...getFieldProps("end_time")}
                        helperText={touched.end_time && errors.end_time}
                        error={Boolean(touched.end_time && errors.end_time)}
                      />
                    </Grid>

                    <Grid item xs={6} md={6} lg={6}>
                      <TextField
                        fullWidth
                        label="Start Time (Evening)"
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "^([0-9]|0[0-9]|1[0-2]):[0-5][0-9] [AP]M$",
                        }}
                        {...getFieldProps("start_time_e")}
                        helperText={touched.start_time_e && errors.start_time_e}
                        error={Boolean(
                          touched.start_time_e && errors.start_time_e
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <TextField
                        fullWidth
                        label="End Time (Evening)"
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "^([0-9]|0[0-9]|1[0-2]):[0-5][0-9] [AP]M$",
                        }}
                        {...getFieldProps("end_time_e")}
                        helperText={touched.end_time_e && errors.end_time_e}
                        error={Boolean(touched.end_time_e && errors.end_time_e)}
                      />
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                      <OutlinedInput
                        fullWidth
                        disabled
                        multiline
                        type="text"
                        startAdornment={
                          <InputAdornment position="start">
                            <Box sx={{ color: "text.primary" }}>CENTER</Box>
                          </InputAdornment>
                        }
                        value={
                          (selCenter && selCenter.join(", ")) ||
                          (centerCoords.length != 0 && centerCoords) ||
                          "-"
                        }
                      />
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                      <OutlinedInput
                        fullWidth
                        disabled
                        multiline
                        type="text"
                        startAdornment={
                          <InputAdornment position="start">
                            <Box sx={{ color: "text.primary" }}>COORDS</Box>
                          </InputAdornment>
                        }
                        value={
                          (selLocation &&
                            selLocation.map((dt) => `${dt}`).join("\n")) ||
                          (op.length != 0 &&
                            op.map((dt) => `${dt}`).join("\n")) ||
                          "-"
                        }
                      />
                    </Grid>
                  </Grid>

                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    sx={{ mt: 3 }}
                  >
                    Save
                  </LoadingButton>
                </Form>
              </FormikProvider>

              {error && (
                <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
                  {console.log(error)}
                  {error}
                </Alert>
              )}
            </Card>
          </Grid>

          <Grid item xs={12} md={8} lg={8}>
            <AddZoneSelectMap
              onCoordinateChange={(dt, center) => {
                setSelLocation(dt);
                setSelCenter(center);
              }}
              polygon1={polygon}
            />
          </Grid>
        </Grid>
      </Container>
      <Drawer
        anchor={"right"}
        open={cartDrawerState}
        onClose={() => setCartDrawerState(false)}
        sx={{ zIndex: 1300, p: 3 }}
      >
        <MilkCartSearch
          onCartSelected={(cart) => {
            setCartDrawerState(false);
            setSelectedCart(cart);
          }}
        />
      </Drawer>
    </Dialog>
  );
}
