import {
  Grid,
  Container,
  Typography,
  Stack,
  Button,
  Tab,
  Box,
  Modal,
} from "@mui/material";

import { useLocation } from "react-router-dom";
import { useState } from "react";
import Page from "src/components/Page";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AdminVehiclesDetailsCard from "./AdminVehcilesDetailsCard";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AdminVehiclesDetailsPage({ vehicle }) {
  const location = useLocation();
  let account = location?.state?.account;
  if (!account) account = vehicle;

  const [currentMenu, setCurrentMenu] = useState("general");

  const handleTabChange = (event, newValue) => {
    setCurrentMenu(newValue);
  };


  return (
    <Page title="Dispenser Details">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h4">
            {(account && account.name) || "Dispenser Details"}
          </Typography>
        </Stack>
        <TabContext value={currentMenu}>
          <Box>
            <TabList onChange={handleTabChange}>
              <Tab label="Profile" value="general" {...a11yProps(0)} />
            </TabList>
          </Box>
          <TabPanel value="general">
            
              <AdminVehiclesDetailsCard vehicle={account._id} />
          
          </TabPanel>
        </TabContext>
      </Container>


    </Page>
  );
}
