import { useState, useEffect } from "react";
import { useAuth } from "../../../contexts/AuthContext";

import { styled } from "@mui/material/styles";
import { dataFetch } from "../../../utils/data-fetch";

import { SearchRounded } from "@mui/icons-material";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import {
  Card,
  Container,
  Table,
  Box,
  OutlinedInput,
  InputAdornment,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Stack,
  TablePagination,
  TableHead,
  CircularProgress,
} from "@mui/material";

import FarmerDetailPopover from "./FarmerDetailPopover";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "phone", label: "Phone", alignRight: false },
  { id: "village", label: "Village", alignRight: false },
  { id: "address", label: "Address", alignRight: false },
];

export default function FarmerSearch({ onFarmerClick }) {
  const [page, setPage] = useState(0);

  const [query, setQuery] = useState("");
  const [field, setField] = useState("name");
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  let timer = "";

  const handleChangeQuery = (event) => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      setCustomerData([]);
      setPage(0);
      setQuery(event.target.value);
    }, 1000);
  };

  useEffect(() => {
    if (query && query.length > 0) {
      setLoadingData(true);
      handleSearch();
    }
  }, [query, page, activeFranchise, field]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = async () => {
    let url = `/api/farmers/mgract/search?f=${field}&skip=${page * 5}&limit=5`;

    if (query && query.length > 0) url += `&q=${query}`;
    else {
      setCustomerData([]);
      setLoadingData(false);
      return;
    }

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  const [currentId, setCurrentId] = useState();
  const [popoverRef, setPopoverRef] = useState();

  return (
    <Card>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          mt={2}
          spacing={2}
        >
          <SearchStyle
            placeholder="Search Farmer..."
            onChange={handleChangeQuery}
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>
                  <SearchRounded />
                </Box>
              </InputAdornment>
            }
            endAdornment={
              loadingData === true && <CircularProgress color="secondary" />
            }
          />

          <Select
            id="field-select"
            value={field}
            onChange={(e) => setField(e.target.value)}
            label="Search In"
          >
            <MenuItem value={"name"}>Name</MenuItem>
            <MenuItem value={"phone"}>Phone</MenuItem>
            <MenuItem value={"code"}>Code</MenuItem>
            <MenuItem value={"address_det"}>Address</MenuItem>
          </Select>
        </Stack>

        {customerData.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {customerData.map((item) => {
                    return (
                      <TableRow
                        hover
                        onClick={() => onFarmerClick(item)}
                        onMouseEnter={(e) => {
                          setCurrentId(item._id);
                          setPopoverRef(e.currentTarget);
                        }}
                        onMouseLeave={(e) => setPopoverRef(null)}
                        style={{
                          backgroundColor:
                            item.active === false ? "#ddd" : "white",
                        }}
                      >
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.phone}</TableCell>
                        <TableCell>
                          {item.village && item.village.name}
                        </TableCell>
                        <TableCell>{item.address_det && item.address_det.substring(0,25)}</TableCell>
                       
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5]}
              component="div"
              count={-1}
              rowsPerPage={5}
              page={page}
              onPageChange={handleChangePage}
            />
          </div>
        )}

        <FarmerDetailPopover dataId={currentId} anchor={popoverRef} />
      </Container>
    </Card>
  );
}
