import {
  Grid,
  Container,
  Typography,
  Stack,
  Button,
  Tab,
  Box,
  Modal,
} from "@mui/material";

import { useLocation } from "react-router-dom";
import { useState } from "react";
import Page from "src/components/Page";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AdminManagerDetailsCard from "./AdminManagerDetailsCard";
import AdminManagerTransactions from "./AdminManagerTransactions";
import AdminCredentialsModal from "./AdminCredentialsModal";
import AdminAcessControl from "../acc/AdminAcessControl";
import AdminCartControl from "../cacc/AdminCartControl";
import EditAdminManager from "../edit/EditAdminManager";
import { useSnackbar } from "src/contexts/SnackbarContext";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AdminManagerDetailsPage({ manager }) {
  const [credentialModalOpen, setCredentialModal] = useState(false);

  const closeCredentialModal = () => {
    setCredentialModal(false);
  };

  const location = useLocation();
  let account = location?.state?.account;
  if (!account) account = manager;

  const [currentMenu, setCurrentMenu] = useState("general");

  const handleTabChange = (event, newValue) => {
    setCurrentMenu(newValue);
  };

  const [editModal, setEditModal] = useState(false);

  const closeEditModal = () => {
    setEditModal(false);
  };

  const { showSnackbar } = useSnackbar();

  return (
    <Page title="Manager Details">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h4">
            {(account && account.name) || "Manager Details"}
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              onClick={() => {
                navigator.clipboard.writeText(account._id);
                showSnackbar("Copied ID", "success");
              }}
            >
              COPY ID
            </Button>
            <Button variant="contained" onClick={() => setEditModal(true)}>
              Edit
            </Button>
            <Button
              variant="contained"
              onClick={() => setCredentialModal(true)}
            >
              Credentials
            </Button>
          </Stack>
        </Stack>
        <TabContext value={currentMenu}>
          <Box>
            <TabList onChange={handleTabChange}>
              <Tab label="Profile" value="general" {...a11yProps(0)} />
              <Tab label="Transactions" value="txn" {...a11yProps(1)} />
              <Tab label="Access Control" value="access" {...a11yProps(2)} />
              <Tab label="Cart Access" value="caccess" {...a11yProps(2)} />
            </TabList>
          </Box>
          <TabPanel value="general">
            <AdminManagerDetailsCard manager={account._id} />
          </TabPanel>
          <TabPanel value="txn">
            <AdminManagerTransactions manager={account} />
          </TabPanel>
          <TabPanel value="access">
            <AdminAcessControl manager={account} />
          </TabPanel>
          <TabPanel value="caccess">
            <AdminCartControl manager={account} />
          </TabPanel>
        </TabContext>
      </Container>
      <Modal open={credentialModalOpen} onClose={closeCredentialModal}>
        <AdminCredentialsModal
          handleM1Close={closeCredentialModal}
          manager={account}
        />
      </Modal>
      <Modal open={editModal} onClose={closeEditModal}>
        <EditAdminManager handleM1Close={closeEditModal} manager={account} />
      </Modal>
    </Page>
  );
}
