import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";

import {
  Card,
  Alert,
  Stack,
  TextField,
  Typography,
  Drawer,
  OutlinedInput,
  InputAdornment,
  Box,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { dataPost } from "src/utils/data-fetch";

import { useSnackbar } from "src/contexts/SnackbarContext";
import DeptSearch from "./DeptSearch";

export default function AddDept({ handleM1Close }) {
  const [error, setError] = useState();

  const [loading, setLoading] = useState(false);

  const { getAccessToken, activeFranchise } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [deptDrawerState, setDeptDrawerState] = useState(false);
  const [selectAptList, setSelectAptList] = useState({});
  const [selectDept, setSelectDept] = useState("");

  const approveNow = async (values) => {
    setLoading(true);

    const upd = {
      name: values.name,
    };

    if (values.description && values.description.length > 0) {
      upd.description = values.description;
    }
    if (selectDept && selectDept._id) {
      upd.parent = selectDept._id;
    }

    const ret = await dataPost(
      `/api/departments/mgract/add`,
      await getAccessToken(),
      upd,
      activeFranchise
    );
    if (ret.success === true) {
      showSnackbar(ret.message || "Department added.", "success");
      handleM1Close();
    } else {
      setError(ret.message + " - " + ret.code);
    }

    setLoading(false);
  };

  const DataSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: DataSchema,
    onSubmit: async (values) => {
      await approveNow(values);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Card
        sx={{
          px: 4,
          py: 4,
          minWidth: 800,
          height: "fit-content",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Typography variant="h5" gutterBottom>
            Add New Department
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                type="text"
                label="Department Title (required)"
                {...getFieldProps("name")}
                error={Boolean(touched.campaign && errors.campaign)}
                helperText={touched.campaign && errors.campaign}
              />

              <TextField
                fullWidth
                type="text"
                multiline
                label="Department description (optional)"
                {...getFieldProps("description")}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />

              <OutlinedInput
                fullWidth
                type="text"
                value={(selectDept && selectDept.name) || "None Selected"}
                onClick={() => setDeptDrawerState(true)}
                startAdornment={
                  <InputAdornment position="start">
                    <Box sx={{ color: "text.disabled" }}>Parent</Box>
                  </InputAdornment>
                }
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ mt: 3 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>

        {error && (
          <Alert severity="error" variant="filled" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Card>

      <Drawer
        anchor={"right"}
        open={deptDrawerState}
        onClose={() => setDeptDrawerState(false)}
        sx={{ zIndex: 1300, p: 3, scroll: "body" }}
        PaperProps={{
          overflow: "auto",
        }}
      >
        <DeptSearch
          onDeptSelected={(dept) => {
            setDeptDrawerState(false);
            setSelectDept(dept);
          }}
        />
      </Drawer>
    </div>
  );
}
