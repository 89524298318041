import React from "react";
import { useState, useEffect } from "react";

import {
  Card,
  Table,
  Stack,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
} from "@mui/material";

import { dataFetch } from "../../../utils/data-fetch";
import { useAuth } from "../../../contexts/AuthContext";

//-------------------------------------------------------------------------
const TABLE_HEAD = [
  { id: "name", label: "Name", alignRIght: false },
  { id: "balance", label: "Balance", alignRight: false },
];

export default function NegBalanceFarmers({ onFarmerClick }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [balanceData, setBalanceData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const { getAccessToken, activeFranchise } = useAuth();

  useEffect(() => {
    fetchBalanceData();
  }, [page, rowsPerPage, activeFranchise]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchBalanceData = async () => {
    setLoadingData(true);

    const ret = await dataFetch(
      `/api/farmers/mgract/negativebalance?&skip=${
        page * rowsPerPage
      }&limit=${rowsPerPage}&dir=d`,
      await getAccessToken(),
      activeFranchise
    );
    console.log(ret);
    if (ret.success === true) {
      setBalanceData(ret.data);
    }
    setLoadingData(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Card title="Customers" sx={{ pt: 2, pb: 2 }}>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="h5" gutterBottom>
            Negative Balance
          </Typography>

          {loadingData === true && <CircularProgress color="secondary" />}
        </Stack>

        {balanceData.length > 0 ? (
          <div>
            <TableContainer sx={{ minHeight: 200 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {balanceData.map((bal) => {
                    return (
                      <TableRow
                        hover
                        onClick={() => onFarmerClick(bal.farmer)}
                      >
                        <TableCell>{bal.farmer.name}</TableCell>
                        <TableCell>{bal.balance.toFixed(2)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={-1}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        ) : (
          <Typography variant="body2" align="center" sx={{ mt: 3, mb: 2 }}>
            No Data found
          </Typography>
        )}
      </Container>
    </Card>
  );
}
