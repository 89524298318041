import React from "react";
import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthContext";
import { dataFetch } from "src/utils/data-fetch";

import { SearchRounded } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import {
  Card,
  Container,
  Typography,
  Table,
  OutlinedInput,
  InputAdornment,
  Box,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
  TableHead,
  Stack,
} from "@mui/material";
import {
  TWENTY4HOURS,
  getDateStringCWM,
  getDayStartAndEndSync,
  getTimeString,
} from "src/utils/date-time-helper";

import CustomerCallsPopover from "../../home/CustomerCallsPopover";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { width: "100%", boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export const MilkCartsCustomersWithMilk = ({ cart }) => {
  const [customerData, setCustomerData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [days, setDays] = useState("4");

  const { getAccessToken, activeFranchise } = useAuth();

  const handleSearch = async () => {
    let url = `/api/milkcarts/mgract/milkcart/${cart._id}/customerwithmilk`;

    if (days) url += `?days=${days}&active=true`;

    const ret = await dataFetch(url, await getAccessToken(), activeFranchise);
    if (ret.success === true) {
      setCustomerData(ret.data);
    }

    setLoadingData(false);
  };

  useEffect(() => {
    setLoadingData(true);
    handleSearch();
  }, [days]); // eslint-disable-line react-hooks/exhaustive-deps

  let TABLE_HEAD = [
    { id: "name", label: "Name", alignRight: false },
    { id: "balance", label: "Balance", alignRight: false },
    { id: "time", label: "Time", alignRight: false },
  ];

  let dates = [];
  for (let i = 0; i < days; i++) {
    const dt = getDateStringCWM(Date.now() - i * TWENTY4HOURS);
    dates.push(dt);
    TABLE_HEAD.push({ id: dt, label: dt, alignRight: false });
  }

  const today = dates[0];
  const totalByDate = {};
  const dayStartEnd = getDayStartAndEndSync(Date.now());

  const [currentId, setCurrentId] = useState();
  const [popoverRef, setPopoverRef] = useState();

  return (
    <Card title="Customers">
      <Container sx={{ pb: 3 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          mt={2}
          spacing={2}
        >
          <SearchStyle
            placeholder="Search Customer..."
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>
                  <SearchRounded />
                </Box>
              </InputAdornment>
            }
            endAdornment={
              loadingData === true && <CircularProgress color="secondary" />
            }
          />

          <Select
            id="days-select"
            value={days}
            onChange={(e) => setDays(e.target.value)}
            label="Days"
          >
            <MenuItem value={"1"}>1</MenuItem>
            <MenuItem value={"2"}>2</MenuItem>
            <MenuItem value={"3"}>3</MenuItem>
            <MenuItem value={"4"}>4</MenuItem>
            <MenuItem value={"5"}>5</MenuItem>
            <MenuItem value={"6"}>6</MenuItem>
            <MenuItem value={"7"}>7</MenuItem>
            <MenuItem value={"8"}>8</MenuItem>
            <MenuItem value={"9"}>9</MenuItem>
          </Select>
        </Stack>

        {customerData.length > 0 && (
          <div>
            <TableContainer sx={{ minHeight: 100 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? "right" : "left"}
                        sortDirection={false}
                      >
                        <b>{headCell.label}</b>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {customerData.map((item) => {
                    let positions = {};
                    for (const m of item.milks) {
                      const dt = getDateStringCWM(m.date);
                      positions[dt] = m;
                      totalByDate[dt] = (totalByDate[dt] ?? 0) + m.quantity;
                    }

                    return (
                      <TableRow hover>
                        <TableCell
                          onMouseEnter={(e) => {
                            if (!positions[today]) {
                              setCurrentId(item._id);
                              setPopoverRef(e.currentTarget);
                            }
                          }}
                          onMouseLeave={(e) => setPopoverRef(null)}
                          sx={{
                            background: item.active === true ? "white" : "grey",
                          }}
                        >
                          <b>{item.name || "-"}</b>
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor:
                              item.balance >= 0 ? "white" : "lightsalmon",
                          }}
                        >
                          ₹ {item.balance || "0"}
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor:
                              item.time > Date.now() - dayStartEnd.start
                                ? "aquamarine"
                                : "aqua",
                          }}
                        >
                          {(item?.time > 0 &&
                            getTimeString(dayStartEnd.start + item.time)) ||
                            "-"}
                        </TableCell>

                        {dates.map((d) => {
                          if (d === today && !positions[d] && item?.time > 0) {
                            if (
                              Date.now() - dayStartEnd.start >
                              item.time + 30 * 60 * 1000
                            ) {
                              return (
                                <TableCell
                                  style={{
                                    backgroundColor: "coral",
                                  }}
                                >
                                  -
                                </TableCell>
                              );
                            }
                          }
                          return (
                            <TableCell>
                              {(positions[d] && positions[d].quantity) || "-"}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}

                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <b>Total</b>
                    </TableCell>
                    {dates.map((d) => {
                      if (!totalByDate[d]) {
                        return (
                          <TableCell>
                            <b>-</b>
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell>
                          <b>{totalByDate[d]}</b>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}

        {customerData.length === 0 && loadingData === false && (
          <div>
            <Typography gutterBottom align="center" variant="subtitle1">
              No data here
            </Typography>
          </div>
        )}

        <CustomerCallsPopover dataId={currentId} anchor={popoverRef} />
      </Container>
    </Card>
  );
};
